import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ExportToExcel } from "../utils/utilFuctions";

const TableWithPagination = ({ data, columns }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (bank[key].toLowerCase().includes(searchText.toLowerCase())) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (bank[key].toLowerCase().includes(searchText.toLowerCase())) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, []);
  console.log(pages);

  useEffect(() => {
    for (let key in data[0]) {
      console.table(key, data[0][key]);
    }
  }, [data]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Search"
            onChange={(e) => {
              console.log(e.target.value);
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
        </Form.Group>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            ExportToExcel("Bank_Details", dataToDisplay);
          }}
        >
          <img
            src={require("../../assets/images/excel.png")}
            style={{ width: "1.6rem" }}
          />
          <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <tr>
          {columns.map((heading) => (
            <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
          ))}
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr>
            {columns.map((cell) => (
              <td style={{ fontSize: "0.8rem" }}>{e[cell.dataField]}</td>
            ))}
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default TableWithPagination;
