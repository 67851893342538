import React, { useState, useContext, useEffect } from "react";
import VirtualReportTable from "../virtual report/VirtualReportTable";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";

const VirtualReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                background: "#30363e",
                color: "#FFF",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <strong>Virtual Report</strong>
            </div>
            <VirtualReportTable />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default VirtualReport;
