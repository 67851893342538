import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form } from "react-bootstrap";
import DashSidebar from "./DashSidebar";
import { writeFile, utils } from "xlsx";
import { useHistory } from "react-router";
import {
  BASE_URL_AGENT,
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { Link } from "react-router-dom";
import { decryptAES, encryptAES } from "../../utils/utilFuctions";
import DashboardReports from "./DashboardReports";
import { DROP_SHADOW, MONTHS } from "../../utils/CommonConfig";
import { basicAuth } from "../../config";
import { Chart } from "chart.js";
import { useRef } from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


import { Line, Doughnut } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);
const cardStyles = [
  {
    background: `#FFF`,
    boxShadow: DROP_SHADOW,
    color: "#000",
  },
  {
    background: `#FFF`,
    boxShadow: DROP_SHADOW,
    color: "#000",
  },
  {
    background: `#FFF`,
    boxShadow: DROP_SHADOW,
    color: "#000",
  },
  {
    background: `#FFF`,
    boxShadow: DROP_SHADOW,
    color: "#000",
  },
];

const dashFilter = [
  { label: "Today", value: "1" },
  { label: "Last 7 Days", value: "7" },
  { label: "Last 15 Days", value: "15" },
  { label: "Last 1 Month", value: "30" },
  { label: "Last 3 Month", value: "90" },
];

export const Dashboard = () => {
  const [myAccountInfo, setMyAccountInfo] = useState(null);
  const { username, password } = basicAuth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [dashboard, setDashboard] = useState({
    dmt: {},
    aeps: {},
    kyc: {},
    recharge: {},
  });
  const [days, setDays] = useState("1");
  const [dashboardChartData, setDashboardChartData] = useState([]);

  const data = "PR1012";
  const encryptedData = encryptAES(data);
  const decryptedData = decryptAES(encryptedData);

  const getDashboardData = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        interval: days,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_dashboard_tranx_data_admin.aspx`,
        requestOptions
      );

      const data = await res.json();

      if (data.status == "0") {
        setDashboard({
          ...dashboard,
          kyc: data.Kyc,
          aeps: data.Aeps,
          recharge: data.Recharge,
          dmt: data.Dmt,
          ServiceWise: data.ServiceWise,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const getDashboardData2 = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      interval: days,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllDashboardChartDataAdmin`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == "0") {
        // setData(data.data)
        setDashboardChartData(data.data)
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const getDaysLabel = (dayValue) => {
    return dashFilter.find((d) => {
      return d.value == dayValue;
    }).label;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  // get my account details
  const getAccountInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    console.log("called user id", userId);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Profile/GetUserProfileInfo`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      setMyAccountInfo(data.data);
    } catch (error) { }
  };

  //
  const generateToken = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
    );

    var raw = JSON.stringify({
      email: username,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const result = await fetch(
        `${BASE_URL_PAYNOW}/api/Account/Login`,
        requestOptions
      );
      if (result.ok) {
        const body = await result.text();
        const data = JSON.parse(body);

        if (data.accessToken != undefined) {
          // setToken(data.accessToken);
          sessionStorage.setItem("accessToken", data.accessToken);
          getAccountInfo();
        }
        if (data.refreshToken != undefined) {
          sessionStorage.setItem("refreshToken", data.refreshToken);
        }
      } else {
        // setInvalid(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getDashboardData2();
  }, [days]);
  useEffect(() => {
    generateToken();

  }, []);
  console.log(myAccountInfo);

  const chartData = {
    labels: ["", "", "", "", "", ""],
    values: [10, 20, 15, 25, 18, 30],
  };


  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ backgroundColor: "#F2F6FC" }}>
          <div
            style={{
              marginBottom: "00px",
              padding: "0px",
              background: "#0958ef",
              color: "#FFF",
              fontWeight: "",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                marginLeft: "17%",
                width: "1512px",
                height: "130px",
                top: "92px",
                backgroundColor: "#0958EF",
              }}
            >
              <h3 className="py-2 p-0 m-0  fontSize: '32px' ">Hello,</h3>
              {"\n"} Your Dashboard is ready to go!!
            </div>
          </div>

          <div
            className="row d-flex justify-content-center m-0 p-0  "
            style={{ translate: "0% -50%" }}
          >
            <div className="row d-flex justify-content-center col-11 m-0 p-0">
              <DashboardCard
                cardStyle={cardStyles[2]}
                label={"AEPS Balance"}
                data={myAccountInfo?.aepsBalance}
              />

              <DashboardCard
                cardStyle={cardStyles[0]}
                label={"DMT Balance"}
                data={myAccountInfo?.paymentAPIBalance}
              />
              <DashboardCard
                cardStyle={cardStyles[0]}
                label={"BBPS Hybrid Balance"}
                data={myAccountInfo?.bbpsOfflineBalance}
              />
            </div>
          </div>

          <div class="row  m-0 p-0 px-2 d-flex justify-content-center ">
            <div class=" row col-sm-8  p-0 m-0 d-flex justify-content-center">

              {dashboardChartData.map((chart) => (
                <div className="  p-0 m-0 ">
                  <div className=" col-sm align-self p-2">
                    <div
                      className="card border-light p-0 m-0"
                      style={{
                        borderRadius: "10px",
                        width: "420px",
                        height: "260px",
                      }}
                    >
                      <DashboardCard1
                        label={chart.service}
                        label1={`${chart.service} Success`}
                        data1={` ${chart.success}`}
                        data21={`${twoDecimalPlaces(
                          chart.success_amount
                        )}`}
                        label2={`${chart.service} Pending`}
                        data2={` ${chart.pending}`}
                        data22={`${twoDecimalPlaces(
                          chart.pending_amount
                        )}`}
                        label3={`${chart.service} Fail`}
                        data3={` ${chart.failed}`}
                        data23={`${twoDecimalPlaces(
                          chart.failed_amount
                        )}`}
                        label4={`${chart.service} Refund`}
                        data4={` ${chart.refund}`}
                        data24={`${twoDecimalPlaces(
                          chart.refund_amount
                        )}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              class="col-sm-4 pb-3 "
              style={{
                borderRadius: "10px",
                width: "100%",
                fontFamily: "Poppins",
              }}
            >
              <div
                style={{ borderRadius: "11px" }}
                className="card border-light m-0 p-0 pb-4 pl-2 "
              >
                <div className="row justify-content-between m-0 p-0 pr-2">
                  <strong
                    className="card-title p-2 text-dark m-0 p-0"
                    style={{ fontSize: "24px" }}
                  >
                    Services
                  </strong>

                  <select
                    style={{ borderRadius: "7px" }}
                    className="  rounded-100 m-2 px-2"
                    aria-label="offset"
                    onChange={(e) => {
                      setDays(e.target.value);
                    }}
                  >
                    {dashFilter.map((offset) => (
                      <option value={offset.value}>{offset.label}</option>
                    ))}
                  </select>
                </div>

                <div className="pr-3">
                  <LineChart data={chartData} />
                </div>

                <div className="card-body p-2">
                  <ServicesCard
                    icon={true}
                    label={"Services"}
                    label2={"Amount"}
                  />

                  <ServicesCard
                    label={"DMT"}
                    tranx={twoDecimalPlaces(dashboard.dmt.SuccessDMT)}
                  />
                  <ServicesCard
                    label={"AEPS"}
                    tranx={twoDecimalPlaces(dashboard.aeps.SuccessRecharge)}
                  />
                  <ServicesCard
                    label={"BBPS"}
                    tranx={twoDecimalPlaces(dashboard.recharge.PendingRecharge)}
                  />
                  {dashboard.ServiceWise &&
                    dashboard.ServiceWise.length > 0 &&
                    dashboard.ServiceWise.map((service) => (
                      <ServicesCard
                        label={service.rch_service}
                        tranx={twoDecimalPlaces(
                          service.SuccessCount
                        )}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          <DashboardReports />
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

const ServicesCard = ({ icon, label, tranx, label2 }) => {
  return (
    <div
      style={{ borderRadius: "1px" }}
      className=" m-0 py-0 px-0 border-primary"
    >
      <div className="card-body p-0 m-0">
        <div className="row col-12 align-items-center p-0 m-0">
          <div className=" col-sm-8 p-0 m-0">
            {" "}
            <div className="row justify-content-start   align-items-center p-1 m-0 ">
              {icon ? (
                <strong className="text-dark" style={{ fontSize: "16px" }}>
                  {" "}
                  {label}
                </strong>
              ) : (
                <SquareColoredIcon color="#D9D9D9" iconName="heart-fill" />
              )}
              {icon ? "" : <span className="text-secondary"> {label} </span>}
            </div>
          </div>
          <div className=" col-sm-4 p-0 m-0">
            <strong className="text-dark" style={{ fontSize: "16px" }}>
              {" "}
              {label2}
            </strong>

            <div className="row justify-content-start   align-items-center p-1 m-0 ">
              {icon ? (
                <strong className="text-dark" style={{ fontSize: "24px" }}>
                  {" "}
                  {tranx}
                </strong>
              ) : (
                <h6
                  className="text-secondary"
                  style={{ fontFamily: "Poppins" }}
                >
                  ₹ {tranx}
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard1 = ({
  label,
  data1,
  data21,
  data2,
  data22,
  data3,
  data23,
  data4,
  data24,
}) => {
  return (
    <>
      <strong
        className="p-3 m-0"
        style={{ fontFamily: "Poppins", fontSize: "24px" }}
      >
        {label}
      </strong>

      <div
        className="row justify-content-between p-0 m-0"
        style={{ fontFamily: "Poppins" }}
      >
        <div className="col-4 p-1 m-0 py-0">
          <div className="col py-2">
            <SquareColoredIcon
              color="#0ACF97"
              iconName="heart-fill"
              text=" Success"
            />
          </div>
          <div className="col py-2">
            <SquareColoredIcon
              color="#FFBC00"
              iconName="heart-fill"
              text="Pending"
            />
          </div>
          <div className="col  py-2">
            <SquareColoredIcon
              color="#FA5C7C"
              iconName="heart-fill"
              text="Fail"
            />
          </div>
          <div className="col  py-2">
            <SquareColoredIcon
              color="#727CF4"
              iconName="heart-fill"
              text=" Refund"
            />
          </div>
        </div>
        <div className="col-8 m-0 p-0">
          <DoughnutChart
            data1={data1}
            data21={data21}
            data2={data2}
            data22={data22}
            data3={data3}
            data23={data23}
            data4={data4}
            data24={data24}
          />
        </div>
      </div>

      <div class="container " style={{ fontFamily: "Poppins" }}>
        <div class="row justify-content-between m-0 p-0">
          <div class="col">Count</div>
          <div class="col">Amount </div>
          <div class="w-100"></div>
          <div class="col">{data1}</div>
          <div class="col">{data21}</div>
        </div>
      </div>
    </>
  );
};

const DashboardServiceCard = ({
  label,
  data1,
  data21,
  data2,
  data22,
  data3,
  data23,
  data4,
  data24,
}) => {
  return (
    <>
      <strong
        className="p-3 m-0"
        style={{ fontFamily: "Poppins", fontSize: "24px" }}
      >
        {label}
      </strong>

      <div
        className="row justify-content-between p-0 m-0"
        style={{ fontFamily: "Poppins" }}
      >
        <div className="col-4 p-1 m-0 py-0">
          <div className="col py-2">
            <SquareColoredIcon
              color="#0ACF97"
              iconName="heart-fill"
              text=" Success"
            />
          </div>
          <div className="col py-2">
            <SquareColoredIcon
              color="#FFBC00"
              iconName="heart-fill"
              text="Pending"
            />
          </div>
          <div className="col  py-2">
            <SquareColoredIcon
              color="#FA5C7C"
              iconName="heart-fill"
              text="Fail"
            />
          </div>
          <div className="col  py-2">
            <SquareColoredIcon
              color="#727CF4"
              iconName="heart-fill"
              text=" Refund"
            />
          </div>
        </div>
        <div className="col-8 m-0 p-0">
          <DoughnutChart
            data1={data1}
            data21={data21}
            data2={data2}
            data22={data22}
            data3={data3}
            data23={data23}
            data4={data4}
            data24={data24}
          />
        </div>
      </div>

      <div class="container " style={{ fontFamily: "Poppins" }}>
        <div class="row justify-content-between m-0 p-0">
          <div class="col">Count</div>
          <div class="col">Amount </div>
          <div class="w-100"></div>
          <div class="col">{data1}</div>
          <div class="col">{data21}</div>
        </div>
      </div>
    </>
  );
};


const DashboardCardAEPS = ({
  label,
  label1,
  data31,
  data41,
  data21,

  label2,
  data32,
  data42,

  data22,

  label3,
  data33,
  data23,
}) => {
  return (
    <>
      <strong
        className="card-title  text-dark fw-bold p-3 m-0"
        style={{ fontFamily: "Poppins", fontSize: "24px" }}
      >
        {label}
      </strong>

      <div className="col m-0 p-0" style={{ fontFamily: "Poppins" }}>
        <div className="col py-0">
          <div className="row p-0 m-0  ml-4 mr-4 ">
            <div className="col p-0 m-0 ">
              <SquareColoredIcon
                color="#0ACF97"
                iconName="heart-fill"
                text="Success"
              />
            </div>
            <div className="col p-0 m-0 ">
              <ProgressBar value={data31} colour={"#0ACF97"} />
            </div>
          </div>
        </div>

        <div className="col py-0">
          <div className="row p-0 m-0 ml-4 mr-4">
            <div className="col p-0 m-0 ">
              <SquareColoredIcon
                color="#FA5C7C"
                iconName="heart-fill"
                text="Fail"
              />
            </div>
            <div className="col p-0 m-0">
              <ProgressBar value={data32} colour={"#FA5C7C"} />
            </div>
          </div>
        </div>

        <div className="col py-0">
          <div className="row m-0 p-0 ml-4 mr-4">
            <div className="col  p-0 m-0  ">
              <SquareColoredIcon
                color="#727CF4"
                iconName="heart-fill"
                text=" Refund"
              />
            </div>
            <div className="col m-0 p-0">
              <ProgressBar value={data33} colour={"#727CF4"} />
            </div>
          </div>
        </div>
      </div>
      <div className=" col m-0 p-0">
        <h6 className="text-center m-0 pb-2" style={{ fontFamily: "Poppins" }}>
          Success Status{" "}
        </h6>

        <div class="container " style={{ fontFamily: "Poppins" }}>
          <div class="row justify-content-between m-0 p-0">
            <div class="col">BE</div>
            <div class="col">CW</div>
            <div class="col">Amount </div>
            <div class="w-100"></div>
            <div class="col">{data31}</div>
            <div class="col">{data41}</div>
            <div class="col">{data21}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardCardKYC = ({
  label,
  label1,
  label2,
  label3,
  data1,
  data2,
  data3,
}) => {
  return (
    <>
      <strong
        className="card-title p-3 text-dark fw-bold  m-0 "
        style={{ fontFamily: "Poppins", fontSize: "24px" }}
      >
        {label}
      </strong>

      <div className="m-0 p-0">
        <div className="col py-0">
          <div className="row p-0 ml-4 mr-4 ">
            <div className="col p-0 ml-0">
              <SquareColoredIcon
                color="#0ACF97"
                iconName="heart-fill"
                text="Approved"
              />
            </div>
            <div className="col p-0 m-0">
              <ProgressBar value={data1} colour={"#0ACF97"} />
            </div>
          </div>
        </div>

        <div className="col py-0">
          <div className="row p-0 m-0 ml-4 mr-4">
            <div className="col p-0 m-0">
              <SquareColoredIcon
                color="#FFBC00"
                iconName="heart-fill"
                text="Pending"
              />
            </div>
            <div className="col p-0 m-0">
              <ProgressBar value={data2} colour={"#FFBC00"} />
            </div>
          </div>
        </div>

        <div className="col py-0">
          <div className="row p-0 m-0 ml-4 mr-4">
            <div className="col p-0 m-0">
              <SquareColoredIcon
                color="#FA5C7C"
                iconName="heart-fill"
                text="Not_done"
              />
            </div>
            <div className="col p-0 m-0">
              <ProgressBar value={data3} colour={"#FA5C7C"} />
            </div>
          </div>
        </div>
      </div>

      <h6 className="text-center m-0 pb-3" style={{ fontFamily: "Poppins" }}>
        Success Status{" "}
      </h6>
      <div class="container ">
        <div class="row justify-content-between m-0 p-0">
          <div class="col">
            {" "}
            <SquareColoredIcon
              color="#0ACF97"
              iconName="heart-fill "
              text=" Approved"
            />
          </div>
          <div class="col">
            {" "}
            <SquareColoredIcon
              color="#FFBC00"
              iconName="heart-fill"
              text=" Pending"
            />
          </div>
          <div class="col">
            {" "}
            <SquareColoredIcon
              color="#FA5C7C"
              iconName="heart-fill "
              text=" Not_done"
            />
          </div>
          <div class="w-100 py-1"></div>
          <div class="col">{data1}</div>
          <div class="col">{data2}</div>
          <div class="col">{data3}</div>
        </div>
      </div>
    </>
  );
};

const DashboardCard = ({ data, data2, label, cardStyle, daysLabel }) => {
  return (
    <div className=" col-sm-3 col-md-3 col-lg-3  ">
      <div className="card  m-0 p-0">
        <div className="row m-0 p-2">
          <div className="col-12 d-flex text-secondary">
            <div className="" style={{ fontFamily: "Poppins" }}>
              {label}
            </div>
          </div>
          <div className="col-12 d-flex text-dark">
            <div className="" style={{ fontFamily: "Poppins" }}>
              ₹ {data}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ result, value, colour }) => {
  return (
    <div className=" progress bg mb-3">
      <div
        //className={`progress mb-4 ${colour}`}
        className="rounded-0"
        role="progressbar"
        style={{
          width: `${value}%`,
          backgroundColor: `${colour}`,
          // borderRadius: "10px",
        }}
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax={result}
      />
    </div>
  );
};

export const DoughnutChart = ({ bool, data1, data2, data3, data4 }) => {
  const defaultData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#D9D9D9"], // Use gray color for the chart
      },
    ],
  };

  const doughnutPieData = {
  //  labels :['Success','Pending','Fail','Refund'],
    datasets: [
      {
        data: [data1, data2, data3, data4],
       // label: '# of Transactions',
        backgroundColor: ["#0ACF97", "#FFBC00", "#727CF4", "#FA5C7C"],
        // hoverBackgroundColor: ["#0ACF97", "#FFBC00", "#727CF4", "#FA5C7C"],
      },
    ],
  };

  const chartData =
    data1.toString().trim() == "" ? defaultData : doughnutPieData;

  const doughnutPieOptions = {
    paddingAngle: 0,
    cutoutPercentage: 70,
    responsive: true,
    // animation: {
    //   animateScale: true,
    //   animateRotate: true,
    // },

    arc: {
      spacing: 0, // Set spacing to 0 to remove the space between colors
    },
  };

  return (
    <div className="">
      <div className="">
        <Doughnut data={chartData} options={doughnutPieOptions} />
      </div>
    </div>
  );
};

const LineChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: data.labels,
          datasets: [
            {
              label: "",
              data: data.values,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
            {
              label: "",
              data: data.values,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
            {
              label: "",
              data: data.values,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
              },
            },
          },
        },
      });
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

const SquareColoredIcon = ({ color, iconName, text }) => {
  const iconStyle = {
    backgroundColor: color,
    width: "11px",
    height: "11px",
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    borderRadius: "2px",
    marginright: "2px",
    fontFamily: "Poppins",
  };

  return (
    <div className=" mr-2" style={iconStyle}>
      <div className="mr-2">
        <i
          className={`bi-${iconName}`}
          style={{ color: "white", fontSize: "24px" }}
        ></i>
      </div>

      <div className="ml-2 " style={{ fontSize: "15px" }}>
        {text}
      </div>
    </div>
  );
};

export default Dashboard;
