import React, { useState } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { MdCurrencyRupee } from "react-icons/md";
import swal from "sweetalert";
import { Link } from "react-router-dom";
const ItDeclaration = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    panNo: "",
    department: "",
    taxRegime: "",
    totalAnnualSalary: "",
    rentPaid: "",
    interestHousingLoan: "",
    ppfGpf: "",
    lic: "",
    sukanyaDeposit: "",
    gisNsc: "",
    tuitionFee: "",
    nps: "",
    postOfficeTimeDeposits: "",
    repaymentHousingLoan: "",
    anyOtherInvestment: "",
    additionalDeduction: "",
    contributionMutualFund: "",
    taxSaverFD: "",
    medicalInsurancePremium: "",
    preventiveHealthCheckUp: "",
    maintenanceHandicapped: "",
    interestHigherEducationLoan: "",
    additionalDeductionInterestHomeLoan: "",
    deductionInterestElectricVehicle: "",
    donation: "",
    rentPaidExcess: "",
    physicalDisability: "",
    deductionInterestSavingBank: "",
    deductionInterestDeposits: "",
    deductionsTaxpayerDisability: "",
    totalDeduction: "",
    netSalary: "",
    incomeHouseProperty: "",
    incomeOtherSources: "",
    declaration: false,
    hra: "",
    travel: "",
    medical: "",
    other: "",
    total: "",
  });
  const [errors, setErrors] = useState({
    panNo: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const updatedValue = name === "panNo" ? value.toUpperCase() : value;

    setFormValues({
      ...formValues,
      [name]: updatedValue,
    });

    if (name === "panNo") {
      validatePan(updatedValue);
    }
    if (["hra", "travel", "medical", "other"].includes(name)) {
      const hra = name === "hra" ? Number(value) : Number(formValues.hra);
      const travel = name === "travel" ? Number(value) : Number(formValues.travel);
      const medical = name === "medical" ? Number(value) : Number(formValues.medical);
      const other = name === "other" ? Number(value) : Number(formValues.other);

      setFormValues((prevState) => ({
        ...prevState,
        total: hra + travel + medical + other,
      }));
    }
  };
  const validatePan = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!panRegex.test(pan)) {
      setErrors({
        ...errors,
        panNo: "Invalid PAN number. It should be in the format AAAAA9999A.",
      });
    } else {
      setErrors({
        ...errors,
        panNo: "",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with values:", formValues);
    swal(
      "Form Submitted!",
      "Your IT declaration has been submitted successfully.",
      "success"
    );
  };
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
  };

  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
    height: "30px",
  };

  return (
    <div className="p-3">
      <div className=" row ">
          <h5 className="col px-3">IT Declaration</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Investment">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-0" controlId="name">
          <Form.Label column sm={4}>
            NAME:
          </Form.Label>
          <Col sm={4}>
            <div className="form-fillable-blank">
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
                style={{
                  border: "none",
                  borderBottom: "1px solid black",
                  width: "100%",  fontSize: ".9rem",
                }}
              />
            </div>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-0" controlId="panNo">
          <Form.Label column sm={4}>
            PAN NO.:
          </Form.Label>
          <Col sm={4}>
            <input
              type="text"
              name="panNo"
              value={formValues.panNo}
              onChange={handleInputChange}
              placeholder="Enter your PAN number"
              style={{
                border: "none",
                borderBottom: "1px solid black",
                width: "100%", fontSize: ".9rem",
              }}
            />
                        {errors.panNo && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.panNo}
              </div>
            )}

          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-0" controlId="department">
          <Form.Label column sm={4}>
            DEPARTMENT:
          </Form.Label>
          <Col sm={4}>
            <input
              type="text"
              name="department"
              value={formValues.department}
              onChange={handleInputChange}
              placeholder="Enter your department"
              style={{
                border: "none",
                borderBottom: "1px solid black",
                width: "100%", fontSize: ".9rem",
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-0" controlId="taxCalculation">
          <Form.Label column sm={4}>
            Tax Calculation:
          </Form.Label>
          <Col sm={6}>
            <Form.Check
              type="radio"
              id="taxRegimeOld"
              label="Old Tax Regime"
              name="taxRegime"
              value="Old Tax Regime"
              onChange={handleInputChange}
            />
            <Form.Check
              type="radio"
              id="taxRegimeNew"
              label="New Tax Regime"
              name="taxRegime"
              value="New Tax Regime"
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <div className="container">
          {formValues.taxRegime === "Old Tax Regime" && (
            <>
              <h6 className=" text-center">
                Particulars of Investments/Deductions:
              </h6>

              <Table>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "50%" }} />
                  <col style={{ width: "30%" }} />
                </colgroup>
                <thead>
                  <tr
                    className="borderless-table text-center border-bottom"
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    <th
                      className=" text-center  "
                      style={{
                        background: "white",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Section
                    </th>
                    <th
                      className=" text-center  "
                      style={{
                        background: "white",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Particular
                    </th>
                    <th
                      className=" text-center  "
                      style={{
                        background: "white",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Investment Amount in Rs.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10(13A)</td>
                    <td style={{ borderRight: "none" }}>
                      Rent Paid (For HRA exemption, employees should provide
                      self-attested copy of rent Agreement and rent
                      receipts/Bank statement for all months i.e. from April
                      2024 to March 2025).
                      <b>
                        Enclose photocopy of PAN of Landlord where Rent per
                        month is above Rs. 8333/-
                      </b>
                    </td>
                    <td style={{ borderLeft: "none" }}>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="rentPaid"
                        value={formValues.rentPaid}
                        onChange={handleInputChange}
                        placeholder="Enter details"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>24(1)(b)</td>
                    <td style={{ borderRight: "none" }}>
                      Interest on Housing Loan up to Rs.2,00,000/- (Rs.3,00,000
                      for senior citizen)
                    </td>
                    <td style={{ borderLeft: "none" }}>
                      <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        />
                        <Form.Control
                          type="number"
                          name="interestHousingLoan"
                          value={formValues.interestHousingLoan}
                          onChange={handleInputChange}
                          placeholder="Enter amount in Rs"
                          style={inputStyle}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>80C</td>
                    <td style={{ borderRight: "none" }}>PPF/GPF</td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="ppfGpf"
                        value={formValues.ppfGpf}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                      /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      LIC (Certify that the policy/policies is/are paid by self
                      only)
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="lic"
                        value={formValues.lic}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>Sukanya deposit</td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        />  <Form.Control
                        type="number"
                        name="sukanyaDeposit"
                        value={formValues.sukanyaDeposit}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>GIS/NSC</td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="gisNsc"
                        value={formValues.gisNsc}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Tuition Fee (other than reimbursed by office)
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="tuitionFee"
                        value={formValues.tuitionFee}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      NPS (other than deducted by office)
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="nps"
                        value={formValues.nps}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Post office time deposits
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="postOfficeTimeDeposits"
                        value={formValues.postOfficeTimeDeposits}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr> */}
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Repayment of principal amount of housing loan/HBA
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="repaymentHousingLoan"
                        value={formValues.repaymentHousingLoan}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Any other allowed Investment if any, specify
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="anyOtherInvestment"
                        value={formValues.anyOtherInvestment}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Additional Deduction u/s. 80CCD(1B)
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="additionalDeduction"
                        value={formValues.additionalDeduction}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Contribution to tax saving Mutual fund scheme
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="contributionMutualFund"
                        value={formValues.contributionMutualFund}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ borderRight: "none" }}>
                      Tax saver FD for more than 5 Years
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="taxSaverFD"
                        value={formValues.taxSaverFD}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80D</td>
                    <td style={{ borderRight: "none" }}>
                      Medical Insurance Premium
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="medicalInsurancePremium"
                        value={formValues.medicalInsurancePremium}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80D</td>
                    <td style={{ borderRight: "none" }}>
                      Payment for Preventive Health Check-up
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="preventiveHealthCheckUp"
                        value={formValues.preventiveHealthCheckUp}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80DD</td>
                    <td style={{ borderRight: "none" }}>
                      Maintenance / Treatment of Handicapped dependent
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="maintenanceHandicapped"
                        value={formValues.maintenanceHandicapped}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80E</td>
                    <td style={{ borderRight: "none" }}>
                      Interest on a loan taken for higher education
                    </td>
                    <td style={{ borderLeft: "none" }}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="interestHigherEducationLoan"
                        value={formValues.interestHigherEducationLoan}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80EEA</td>
                    <td style={{ borderRight: "none" }}>
                      Additional Deduction for Interest on Home Loan
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="additionalDeductionInterestHomeLoan"
                        value={formValues.additionalDeductionInterestHomeLoan}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80EEB</td>
                    <td style={{ borderRight: "none" }}>
                      Deduction for Interest on Loan taken to Buy Electrical
                      Vehicle
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="deductionInterestElectricVehicle"
                        value={formValues.deductionInterestElectricVehicle}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80G</td>
                    <td style={{ borderRight: "none" }}>Donation</td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="donation"
                        value={formValues.donation}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80GG</td>
                    <td style={{ borderRight: "none" }}>
                      Rent paid in excess of 10% of total income
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="rentPaidExcess"
                        value={formValues.rentPaidExcess}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>80U</td>
                    <td style={{ borderRight: "none" }}>Physical Disability</td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /><Form.Control
                        type="number"
                        name="physicalDisability"
                        value={formValues.physicalDisability}
                        onChange={handleInputChange}
                        placeholder="Enter amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Part B</td>
                    <td style={{ color: "#000", borderRight: "none" }}>
                      <b>Total deduction:</b>
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="totalDeduction"
                        value={formValues.totalDeduction}
                        onChange={handleInputChange}
                        placeholder="Enter total deduction amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Part C</td>
                    <td style={{ color: "#000", borderRight: "none" }}>
                      <b> Net Salary:</b>
                    </td>
                    <td style={{ borderLeft: "none" }}>
                    <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        /> <Form.Control
                        type="number"
                        name="netSalary"
                        value={formValues.netSalary}
                        onChange={handleInputChange}
                        placeholder="Enter net salary amount in Rs"
                        style={inputStyle}
                        /></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Part D</td>
                    <td style={{ borderRight: "none" }}>
                     <b>Any other income:</b> 
                      <br />
                      Income from House Property (If let out):
                      <br />
                      Income from other sources (Professional income from other
                      Institutes):
                    </td>
                    <td style={{ borderLeft: "none" }}>
                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="incomeHouseProperty"
                      >
                        <Col sm={12}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        />  <Form.Control
                            type="number"
                            name="incomeHouseProperty"
                            value={formValues.incomeHouseProperty}
                            onChange={handleInputChange}
                            placeholder="Enter income from house property amount in Rs"
                            style={inputStyle}
                            /></div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="incomeOtherSources"
                      >
                        <Col sm={12}>
                        <div style={searchBarStyle} className="">
                        <MdCurrencyRupee
                          style={{
                            marginLeft: "8px",
                            marginRight: "4px",
                            fontSize: "18px",
                          }}
                        />  <Form.Control
                            type="number"
                            name="incomeOtherSources"
                            value={formValues.incomeOtherSources}
                            onChange={handleInputChange}
                            placeholder="Enter income from other sources amount in Rs"
                            style={inputStyle}
                            /></div>
                        </Col>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Form.Group controlId="declaration" className="mt-4">
                <Form.Check
                  type="checkbox"
                  label="I hereby declare that particulars given above are correct and complete"
                  name="declaration"
                  checked={formValues.declaration}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              <div className=" mt-4">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </>
          )}

{formValues.taxRegime === "New Tax Regime" && (
            <>
              <h6 className=" text-center">New Tax Regime Deductions</h6>
              <Form.Group as={Row} className="mb-0" controlId="hra">
                <Form.Label column sm={4}>
                  HRA:
                </Form.Label>
                <Col sm={4}>
                  <div style={searchBarStyle}>
                    <MdCurrencyRupee
                      style={{
                        marginLeft: "8px",
                        marginRight: "4px",
                        fontSize: "18px",
                      }}
                    />
                    <Form.Control
                      type="number"
                      name="hra"
                      value={formValues.hra}
                      onChange={handleInputChange}
                      placeholder="Enter HRA amount in Rs"
                      style={inputStyle}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="travel">
                <Form.Label column sm={4}>
                  Travel:
                </Form.Label>
                <Col sm={4}>
                  <div style={searchBarStyle}>
                    <MdCurrencyRupee
                      style={{
                        marginLeft: "8px",
                        marginRight: "4px",
                        fontSize: "18px",
                      }}
                    />
                    <Form.Control
                      type="number"
                      name="travel"
                      value={formValues.travel}
                      onChange={handleInputChange}
                      placeholder="Enter Travel amount in Rs"
                      style={inputStyle}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="medical">
                <Form.Label column sm={4}>
                  Medical:
                </Form.Label>
                <Col sm={4}>
                  <div style={searchBarStyle}>
                    <MdCurrencyRupee
                      style={{
                        marginLeft: "8px",
                        marginRight: "4px",
                        fontSize: "18px",
                      }}
                    />
                    <Form.Control
                      type="number"
                      name="medical"
                      value={formValues.medical}
                      onChange={handleInputChange}
                      placeholder="Enter Medical amount in Rs"
                      style={inputStyle}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="other">
                <Form.Label column sm={4}>
                  Other:
                </Form.Label>
                <Col sm={4}>
                  <div style={searchBarStyle}>
                    <MdCurrencyRupee
                      style={{
                        marginLeft: "8px",
                        marginRight: "4px",
                        fontSize: "18px",
                      }}
                    />
                    <Form.Control
                      type="number"
                      name="other"
                      value={formValues.other}
                      onChange={handleInputChange}
                      placeholder="Enter Other amount in Rs"
                      style={inputStyle}
                    />
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="" controlId="total">
                <Form.Label column sm={4}>
                  Total:
                </Form.Label>
                <Col sm={4}>
                  <div style={searchBarStyle}>
                    <MdCurrencyRupee
                      style={{
                        marginLeft: "8px",
                        marginRight: "4px",
                        fontSize: "18px",
                      }}
                    />
                    <Form.Control
                      type="number"
                      name="total"
                      value={formValues.total}
                      readOnly
                      style={inputStyle}
                    />
                  </div>
                </Col>
              </Form.Group>

              <div className="mt-4">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </>
          )}

        </div>
      </Form>
    </div>
  );
};

export default ItDeclaration;
