import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { MdAssignmentInd, MdPeople } from "react-icons/md";
import { GlobalContext } from "../../Context/GlobalState";
import {
  FaMoneyBill,
  FaUser,
  FaBirthdayCake,
  FaCheckCircle,
  FaSpinner,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Pie, Bar } from "react-chartjs-2";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import {
  FaPlusCircle,
  FaDesktop,
  FaChartLine,
  FaHeadset,
  FaCog,
  FaEllipsisH,
  FaBullhorn,
  FaUsers,
  FaMoneyBillAlt,
  FaUserEdit,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import { AdddepartmentHrmodal } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
const WorkDashboard = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [showcategorymodal, setshowcategorymodal] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [workspacedata, setworkspacedata] = useState([]);
  const [employee, setemployee] = useState([]);
  const { password , username } = billpunchbasicauth;
 
  const [employeeCounts, setEmployeeCounts] = useState([]);
  const [employeebirth, setemployeebirth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("January");

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
  };
  const categoryIcons = {
    IT: <FaDesktop />,
    Sales: <FaChartLine />,
    Support: <FaHeadset />,
    Operations: <FaCog />,
    Other: <FaEllipsisH />,
    "Digital Marketing": <FaBullhorn />,
    "Customer Support": <FaUsers />,
    Accounts: <FaMoneyBillAlt />,
    "UI & UX": <FaUserEdit />,
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid:  user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getWorkspacedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers:myHeaders,
        body: JSON.stringify({
         
          empid: user.userid,
          firmid: user.firm_id,
          ownerid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetYourWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setworkspacedata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeCount`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployee(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployeebirthday = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeebirth(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployeeCount = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers:myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeCountDash`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeeCounts(data.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getDepartmentList();
    getWorkspacedata();
    getEmployee();
    getEmployeebirthday();
    getEmployeeCount();
  }, []);
  useEffect(() => {
    const fetchedData = [
      { cat1: "IT" },
      { cat1: "Sales" },
      { cat1: "Support" },
      { cat1: "Operations" },
      { cat1: "Other" },
      { cat1: "Digital Marketing" },
      { cat1: "Customer Support" },
      { cat1: "Accounts" },
      { cat1: "UI & UX" },
      // Add more data objects as needed
    ];
    setDepartmentData(fetchedData);
  }, []);
  const openaddcategorymodal = () => {
    setshowcategorymodal(true);
  };
  const closeaddcategorymodal = () => {
    setshowcategorymodal(false);
  };

  const labelsMap = {
    it_employees: "IT",
    sales_employees: "Sales",
    digital_employees: "Digital",
    customer_support: "Customer Support",
    accounts: "Accounts",
    other: "Other",
  };

  const chartLabels = Object.keys(employeeCounts).map((key) => labelsMap[key]);
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: Object.values(employeeCounts),
        backgroundColor: [
          "#9AD0C2",
          "#41C9E2",
          "#FEC7B4",
          "#B0C5A4",
          "#BFEA7C",
          "#FFF67E",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
    },
  };

  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        data: [65, 59, 80, 81, 56, 55, 40, 75, 45, 70, 90, 60],
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Operation",
        data: [50, 45, 60, 70, 55, 45, 35, 65, 40, 55, 75, 50],
        backgroundColor: "rgba(54, 233, 235, 19)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const currentMonth = new Date().getMonth() + 1;
  const filteredBirthdays = employeebirth.filter((birth) => {
    const birthMonth = new Date(birth.dob).getMonth() + 1;
    return birthMonth === currentMonth;
  });
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <div className="m-3">
      <AdddepartmentHrmodal
        show={showcategorymodal}
        hide={closeaddcategorymodal}
        getDepartmentList={getDepartmentList}
      />
      <div className="row m-0 p-0 " style={{ background: "RGB(93,136,228)" }}>
        <div className="col">
          <h4 className="mt-1" style={{ color: "#fff" }}>
            Hi , {user.username}
          </h4>
          <p style={{ color: "#fff", fontSize: "1rem" }}>
            Track and manage your progress here!
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-6">
          <div
            className="card m-0 p-2 text-center"
            style={{ border: "1px dotted grey" }}
          >
            <div>
              {" "}
              <MdAssignmentInd style={{ color: "teal", fontSize: "2rem" }} />
            </div>
            Assigned
            <br />
            <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
              {employee.length > 0 && employee[0].internCount}
            </span>
            <hr />
            <div>
              {" "}
              <FaSpinner style={{ color: "teal", fontSize: "2rem" }} />
            </div>
            In Progress
            <br />
            <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
              {employee.length > 0 && employee[0].regularEmployeeCount}
            </span>
            <hr />
            <div>
              {" "}
              <FaCheckCircle style={{ color: "teal", fontSize: "2rem" }} />
            </div>
            Completed
            <br />
            <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
              {employee.length > 0 && employee[0].totalCount}
            </span>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className=" m-0 p-2">
            <h5 className="d-flex justify-content-center">Employees</h5>
            <div style={{ width: "100%", height: "350px" }}>
              <Pie data={chartData} options={options} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div
            className="table-responsive"
            style={{
              borderRadius: ".4em",
              border: "1px solid lightgrey",
              height: "56vh",
            }}
          >
            <table className="table table-bordered m-0 p-0 ">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Work Space
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Description
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Person
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Team Size
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {workspacedata.map((item, index) => (
                  <tr
                    style={{ borderBottom: "1px solid lightgrey" }}
                    key={index}
                  >
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.workspace_name}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.workspce_desc}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{
                        border: "none",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        className="p-2 align-middle"
                        style={{
                          background: item.colourcode,
                          borderRadius: "50%",
                          width: "2rem",
                        }}
                      >
                        {" "}
                        {item.firstletter}
                      </div>
                    </td>

                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.teamsize}
                    </td>

                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      <Link to="/retailer/Employeeworkspace">
                        <img
                          onClick={() => {
                            sessionStorage.setItem(
                              "property",
                              JSON.stringify(item)
                            );
                          }}
                          src={require("../../../assets/images/arrow.png")}
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkDashboard;
