import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Button, InputGroup, Overlay, Popover } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdDownload } from "react-icons/md";

const Employeeproofinvestment = () => {
  const [activeTab, setActiveTab] = useState("tab2");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "none" } : {}}
              onClick={() => handleTabClick("tab2")}
            >
              IT Declaration
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Proof Of Investment
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === "tab1" && <Proofofinvestment />}
          {activeTab === "tab2" && <Itdeclaration />}
        </div>
      </div>
    </>
  );
};
const Proofofinvestment = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  return (
    <>
      <div className="m-2">
        <div className="d-flex justify-content-center">
          <img
            alt=""
            style={{
              height: "5rem",
            }}
            src={require("../../../assets/images/submit.gif")}
          />{" "}
        </div>
        <div className=" m-3 d-flex justify-content-center">
          <Link to="/retailer/Investment_Form">
            <Button className="btn-sm">Submit Proof</Button>
          </Link>
        </div>
      </div>
    </>
  );
};
const Itdeclaration = () => {
  const [selectedYear, setSelectedYear] = useState("2020-2021");

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const investmentDetails = [
    {
      title: "House Rent Paid details",
      period: "April 2024- Dec 2024",
      amount: "₹74,899",
    },
    {
      title: "80C Investment (Max Limit: ₹5676576)",
      details: [{ name: "Public Provident Fund", amount: "₹74,899" }],
    },
    {
      title: "80D Investment (Max Limit: ₹5676576)",
      details: [
        {
          name: "Medi Claim Policy for self, spouse, children",
          amount: "₹74,899",
        },
      ],
    },
    {
      title: "Other Investment",
      details: [
        {
          name: "Medical expenditure for self or dependent",
          amount: "₹74,899",
        },
      ],
    },
  ];

  return (
    <>
     <div className=" mb-2 ">
          <Link to="/retailer/It_declaration">
            <Button className="btn-sm">Submit It Declaration</Button>
          </Link>
        </div>
      <p
        className="m-0 p-2 triangle-paragraph"
        style={{ background: "#EEF7FF" }}
      >
        <IoIosInformationCircleOutline /> You've submitted your IT Declaration
        to your payroll admin. However, if you need to, you can still make
        changes to the IT Declaration and submit it on or before 28/09/2024.
      </p>
      <div className="mt-3 d-flex align-items-center">
        <h6 className="col-3 m-0">Overall tax Summary</h6>
        <div className="ml-2 d-flex align-items-center">
          <span>Financial Year:</span>
          <select
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              padding: "5px",
              border: "none",
              marginLeft: "10px",
            }}
          >
            <option value="2020-2021">2020-2021</option>
            <option value="2021-2022">2021-2022</option>
            <option value="2022-2023">2022-2023</option>
            <option value="2023-2024">2023-2024</option>
            <option value="2024-2025">2024-2025</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>Net taxable Income</p>
          <p>₹42,322</p>
        </div>
        <div className="col">
          <p>Net taxable Income</p>
          <p>₹42,322</p>
        </div>
        <div className="col">
          <p>Net taxable Income</p>
          <p>₹42,322</p>
        </div>
        <div className="col">
          <p>Status</p>
          <p
            className="bg-success p-1"
            style={{ color: "#fff", width: "5rem" }}
          >
            Declared
          </p>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="col">
        Tax Regime : Old tax Regime{" "}
        <span style={{ color: "blue" }}>(Change)</span>
      </div>
      <hr className="mb-0" />
      <div className="row">
        <h6 className="col">Particulars</h6>
        <h6 className="col d-flex justify-content-end">Declared Amount</h6>
      </div>
      <hr className="mt-1" />
      {investmentDetails.map((investment, index) => (
        <div key={index}>
          <h6 className="col">{investment.title}</h6>
          {investment.period && (
            <div className="row mx-2">
              <p className="col p-0 m-0">{investment.period}</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                {investment.amount}
              </p>
            </div>
          )}
          {investment.details &&
            investment.details.map((detail, detailIndex) => (
              <div key={detailIndex} className="row mx-2">
                <p className="col p-0 m-0">{detail.name}</p>
                <p className="col p-0 m-0 d-flex justify-content-end">
                  {detail.amount}
                </p>
              </div>
            ))}
          <hr className="m-1" />
        </div>
      ))}
      <hr className="m-1" />
      <p
        className="m-0 p-2 triangle-paragraph"
        style={{ background: "#EEF7FF" }}
      >
        Form 12BB - Employee Investment Declaration{" "}
        <span>
          <Link>
            <MdDownload />
            Download
          </Link>
        </span>
      </p>
    </>
  );
};

export default Employeeproofinvestment;
