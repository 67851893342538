import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalState";
import crypto from "crypto";
import { ContextMenuTrigger } from "react-contextmenu";
import { generateCaptcha } from "../utils/GenerateCaptcha";
import {
  generateHashKey2,
  getDiviceInfo,
  getMyIPAddress,
  onlyAlpha,
  onlyAlphaNumeric,
  onlyNumeric,
} from "../utils/utilFuctions";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../utils/Constants";
import Error404 from "../error-pages/Error404";
import FoodIcon from "../../assets/svg/food.svg";
import PaymentIcon from "../../assets/svg/payment.svg";
import TravelIcon from "../../assets/svg/travel.svg";
import CompanyLogo from "../../assets/svg/logo.svg";
import MediaLogo from "../../assets/svg/media.svg";
import swal from "sweetalert";

export default function Register() {
  const history = useHistory();
  const [error, setError] = useState("");
  const [textOtp, setTextOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const { setUserInfo } = useContext(GlobalContext);

  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
    mobile: "",
    bussinessName: "",
    website: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    name: "",
    mobile: "",
    bussinessName: "",
    website: "",
  });

  const register = async () => {
    setError("");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      retailername: state.name,
      retaileremail: state.email,
      retailershopaddress: "",
      retailershopname: state.bussinessName,
      retailershoppin: "",
      retaileraadhar: "",
      retailercretedby: "1",
      retailerpassword: state.password,
      retailertype: "1",
      retailerloginid: state.mobile,
      retailerpancard: "",
      retailergst: "",
      Tpin: "654321",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/create_newuser.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        // swal(" Success", "User Registration Successfull", "success");
        setOtpSent(true);
        //  loginWithOtp();
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      swal("Failed", error.toString(), "error");
    }
  };

  // email validator
  function validateEmail(email) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return "";
    } else {
      return "Please enter valid email address";
    }
  }
  const validate = () => {
    let errors = {};
    if (state.mobile.length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    if (state.name.length < 5) {
      errors.name = "please enter valid name";
    }
    if (state.password.length < 8) {
      errors.password = "password should be 8 character long";
    }
    if (state.bussinessName.length < 3) {
      errors.bussinessName = "Bussiness name should be 3 character long";
    }

    let err = validateEmail(state.email);
    if (err) {
      errors.email = err;
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      login();
    } else {
      setFormErrors(errors);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    validate();
  };

  const createHash = () => {
    return crypto.createHash("md5").update(state.password).digest("hex");
  };

  //
  const login = async () => {
    setError("");
    const raw = "";
    const hash_key = await generateHashKey2(state.mobile);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=loginmobile&mobile=${state.mobile}&password=AAAAAA`,
        requestOptions
      );
      const data = await res.json();
      if (data.UserType) {
        setError("User Already Exists with given number");
      } else {
        register();
      }
    } catch (error) {
      setError("Error From Server");
    }
  };

  const login2 = async (userType, callback) => {
    setError("");
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    try {
      const hash = createHash();
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=login&mobile=${state.mobile}&password=${hash}&Captcha=${textOtp}&UserType=${userType}`,
        requestOptions
      );
      const data = await res.json();
      if (data.Id === "Y") {
        // loginForAccessToken();
        callback(data);
      } else {
        setError("Invalid Credentials.Please check your credentials again.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const login3 = async (userType, callback) => {
    setError("");
    const raw = "";
    const hash_key = await generateHashKey2(state.mobile);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const hash = createHash();
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=login2&mobile=${state.mobile}&password=${hash}`,
        requestOptions
      );
      const data = await res.json();
      //const user = { ...data, UserType: "1" };
      const user = data;
      if (user.Id === "Y") {
        // loginForAccessToken();
        setUserInfo(user);
        callback(user);
      } else {
        setError("Invalid Credentials.Please check your credentials again.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginWithOtp = async (callback) => {
    setError("");
    try {
      const getDeviceData = await getMyIPAddress();

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        os_name: getDeviceData.os_name,
        os_version: getDeviceData.os_version,
        browser_name: getDeviceData.os_name,
        browser_version: getDeviceData.browser_version,
        user_agent: getDeviceData.user_agent,
        app_version: getDeviceData.app_version,
        platform: getDeviceData.platform,
        vendor: getDeviceData.vendor,
        ip_address: getDeviceData.ip_address,
        mac_address: getDeviceData.mac_address,
        user_name: state.mobile,
        password: state.password,
        device_type: "WEB",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/otpsend.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        setOtpSent(true);
        // login2("", callback);
      } else {
        login3("", callback);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const goToForgetPassword = () => history.push("/forget-password");

  const showPasswordClick = () => {
    setPassShow(!passShow);
  };

  useEffect(() => {
    try {
    } catch (error) {}
  }, []);

  return (
    <div
      className="m-0 p-0"
      style={{
        height: "100vh",
        background: "linear-gradient(to right, #EBEEF1, #EBEEF1)",
      }}
    >
      <div className="row justify-content-center">
        <img
          src={CompanyLogo}
          style={{ height: "3rem", width: "auto", color: "#EC5665" }}
          className="mx-1"
        />
      </div>

      <div className="row">
        <div className="row col-lg-4 col-md-8 col-sm-10 mx-auto justify-content-center align-items-center">
          <div className="d-flex px-0 py-0 align-items-center">
            <img
              src={PaymentIcon}
              style={{
                height: "1.8rem",
                width: "1.8rem",
                color: "#EC5665",
                background: "#04A5A4",
                padding: "4px",
                borderRadius: "1rem",
              }}
              className="mx-1"
            />
            <span
              style={{
                fontSize: ".8rem",
              }}
            >
              {" "}
              Payments
            </span>
          </div>
          <div className="d-flex px-3 py-0 align-items-center">
            <img
              src={TravelIcon}
              style={{
                height: "1.8rem",
                width: "1.8rem",
                color: "#EC5665",
                background: "#2AA01C",
                padding: "4px",
                borderRadius: "1rem",
              }}
              className="mx-1"
            />
            <span
              style={{
                fontSize: ".8rem",
              }}
            >
              {" "}
              Travel
            </span>
          </div>
          <div className="d-flex px-3 py-0 align-items-center">
            <img
              src={FoodIcon}
              style={{
                height: "1.8rem",
                width: "1.8rem",
                color: "#EC5665",
                background: "#D52A1E",
                padding: "4px",
                borderRadius: "1rem",
              }}
              className="mx-1"
            />
            <span
              style={{
                fontSize: ".8rem",
              }}
            >
              {" "}
              Food
            </span>
          </div>
          <div className="d-flex px-3 py-0 align-items-center">
            <img
              src={MediaLogo}
              style={{
                height: "1.8rem",
                width: "1.8rem",
                color: "#EC5665",
                background: "#2AA01C",
                padding: "4px",
                borderRadius: "1rem",
              }}
              className="mx-1"
            />
            <span
              style={{
                fontSize: ".8rem",
              }}
            >
              {" "}
              Media
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-lg-4 col-md-8 col-sm-10 mx-auto"
          style={{ maxWidth: "30rem" }}
        >
          <div
            className="card text-left p-0"
            style={{ border: "1px solid #c7c7c7" }}
          >
            <h5 className="font-weight-bold mx-4 my-2 text-center">
              Account Deletion Form
            </h5>

            <form onSubmit={submitHandler} className="px-4 py-2">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Full Name"
                  className="form-control"
                  value={state.name}
                  onChange={(e) => {
                    setState({ ...state, name: e.target.value });
                  }}
                />
                <p className="text-danger text-left p-0">{formErrors.name}</p>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Enter Mobile Number"
                  className="form-control"
                  value={state.mobile}
                  onChange={(e) => {
                    setState({ ...state, mobile: e.target.value });
                  }}
                />
                <p className="text-danger text-left p-0">{formErrors.mobile}</p>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email address"
                  className="form-control"
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                />
                <p className="text-danger text-left p-0">{formErrors.email}</p>
              </div>

              <button className="btn btn-primary btn-block" onClick={() => {}}>
                Submit
              </button>
            </form>
            <div
              className="row justify-content-center p-2 m-0"
              style={{ background: "#EBEEF1", fontSize: ".7rem" }}
            >
              Invisible reCAPTCHA by Google &nbsp;
              <a href="/terms-and-conditions">
                Privacy Policy and Terms of Use.
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="" style={{ fontSize: ".7rem" }}>
          <a href="https://www.bumppy.com/privacy-policy/" className="mx-1">
            Legal
          </a>
          <a href="https://www.bumppy.com/privacy-policy/" className="mx-1">
            Privacy
          </a>
          <a href="/terms-and-conditions" className="mx-1">
            Security
          </a>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
          <p className="text-center" style={{ fontSize: ".7rem" }}>
            © 2023 Bumppy. All rights reserved. Bumppy, Payments, Chaafo,
            Travel, Media are registered trademarks of Bumppy Inc.
          </p>
          <p className="text-center" style={{ fontSize: ".7rem" }}>
            Terms and conditions, features, support, pricing, and service
            options subject to change without notice.
          </p>
        </div>
      </div>
    </div>
  );
}

const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger my-1">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};
