import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalState";
import { urls } from "../URLs";
import { BASE_URL_API_TRANXT } from "../utils/Constants";
import crypto from "crypto";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { ContextMenuTrigger } from "react-contextmenu";
import {
  COMPANY_LOGIN_NAME,
  COMPANY_NAME,
  SUPPORT_MOBILE_NUMBER,
} from "../utils/StringConstants";
import { COLOR_LOGIN_SCREEN_STRIPE } from "../utils/Themer";

export default function Login2() {
  const { login: loginUrl } = urls;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const { setUserInfo } = useContext(GlobalContext);
  // const [userType, setUserType] = useState("");
  console.log(setUserInfo);
  const createHash = () => {
    return crypto.createHash("md5").update(password).digest("hex");
  };

  //
  const login2 = async (userType, callback) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    try {
      const hash = createHash();
      console.log({ hash, userName, captcha });
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=login&mobile=${userName}&password=${hash}&Captcha=${captcha}&UserType=${userType}`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.Id === "Y") {
        callback(data);
        // callback({ ...data, UserType: "3" });
      } else {
        setError("Invalid Credentials.Please check your credentials again.");
        loadCaptchaEnginge(4);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const login = async (callback) => {
    if (validateCaptcha(captcha) == true) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      try {
        const res = await fetch(
          `${BASE_URL_API_TRANXT}/apiget.aspx?method=loginmobile&mobile=${userName}&password=${captcha}`,
          requestOptions
        );
        const raw = await res.text();
        const data = JSON.parse(raw);
        console.log(data);
        if (data.UserType) {
          // setUserType(data.UserType);
          login2(data.UserType, callback);
        } else {
          setError("Invalid Credentials.Please check your credentials again.");
          loadCaptchaEnginge(4);
        }
      } catch (error) {
        setError("Invalid Credentials");
        loadCaptchaEnginge(4);
        console.log("error", error);
      }
    } else {
      setError("Invalid Captcha");
      loadCaptchaEnginge(4);
    }
  };

  useEffect(() => {
    try {
      loadCaptchaEnginge(4);
    } catch (error) {}
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.Id !== "Y" ? (
          <div >
            <div className="py-3 px-4">
              <img
                src={require("../../assets/images/company/logo_square.png")}
                style={{ height: "10vh" }}
              />
            </div>
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{
                backgroundColor: COLOR_LOGIN_SCREEN_STRIPE,
                borderTop: "4px solid #EE343A",
              }}
            >
              <div className="row m-0">
                <a href="/" className="font-weight-bold px-3 login-hover-link">
                  {`ABOUT ${COMPANY_NAME}`}
                </a>
                <a
                  href="/terms-and-conditions"
                  className="font-weight-bold px-3 login-hover-link"
                >
                  {"Terms and Conditions"}
                </a>
                <a
                  href="/privacypolicy"
                  className="font-weight-bold px-3 login-hover-link"
                >
                  {"Privacy Policy"}
                </a>
              </div>

              <div>
                <span
                  style={{ color: "#FFF" }}
                  className="font-weight-bold mr-4"
                >
                  <i className="mdi mdi-phone text-primary mr-1"></i>
                  {SUPPORT_MOBILE_NUMBER}
                </span>
              </div>
            </div>
            <div style={{ maxHeight: "470px" }} className="row m-0 p-0">
              <div className="col-12 col-md-8 m-0 p-0">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={require("../../assets/images/login_banner.png")}
                />
              </div>
              <div
                className="col-12 col-md-4 m-0 p-0"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    background: "#F6F7FC",
                  }}
                  className="card auth-form-transparent text-left px-3 py-1 m-0 h-100"
                >
                  <div className="brand-logo" style={{}}>
                    <strong>
                      {/* <img
                        src={require("../../assets/images/company/logo_square.png")}
                        style={{ width: "2rem", margin: "0 1rem 1rem 0" }}
                      /> */}
                      <span style={{ fontSize: "1.3rem" }}>
                        Login to <strong>{COMPANY_LOGIN_NAME}</strong>
                      </span>
                    </strong>
                    <p>India's Best Online Money transfer Platform.</p>
                  </div>
                  <form className="pt-1">
                    <div className="form-group">
                      <label>Username</label>
                      <div className="input-group">
                        {/* <div className="input-group-prepend bg-transparent">
                          <span
                            style={{ border: "1px solid #ced4da" }}
                            className="input-group-text  bg-transparent border-right-0"
                          >
                            <i className="mdi mdi-account-outline text-primary"></i>
                          </span>
                        </div> */}
                        <input
                          type="text"
                          className="form-control form-control-lg no-glow"
                          id="exampleInputEmail"
                          placeholder="Username"
                          onChange={(e) => {
                            console.log(context);
                            setError("");
                            setUserName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        {/* <div className="input-group-prepend bg-transparent">
                          <span
                            style={{ border: "1px solid #ced4da" }}
                            className="input-group-text bg-transparent border-right-0"
                          >
                            <i className="mdi mdi-lock-outline text-primary"></i>
                          </span>
                        </div> */}
                        <input
                          type="password"
                          className="form-control form-control-lg no-glow"
                          id="exampleInputPassword"
                          placeholder="Password"
                          onChange={(e) => {
                            setError("");
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <LoadCanvasTemplateNoReload />
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control form-control-lg no-glow"
                          id="exampleInputPassword"
                          placeholder="captcha"
                          onChange={(e) => {
                            setError("");
                            setCaptcha(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          <i className="input-helper"></i>
                          Keep me signed in
                        </label>
                      </div>
                      <a
                        href="!#"
                        onClick={(event) => event.preventDefault()}
                        className="auth-link text-muted"
                      >
                        Forgot password?
                      </a>
                    </div>
                    {error && <Invalid text={error} />}
                    <div className="mt-3">
                      <div
                        style={{ backgroundColor: "#30B0D5" }}
                        className="rounded-0 btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={() => {
                          login((user) => {
                            localStorage.setItem("PdrId", user.ReferId);
                            context.setUserInfo(user);
                          });
                        }}
                      >
                        Sign In
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              className="px-2 py-3 d-flex flex-row justify-content-between"
              style={{
                backgroundColor: COLOR_LOGIN_SCREEN_STRIPE,
                borderTop: "4px solid #EE343A",
              }}
            ></div>
          </div>
        ) : context.user && context.user.UserType === "2" ? (
          <Redirect to="/retailer/dashboard" />
        ) : context.user && context.user.UserType === "3" ? (
          <Redirect to="/distributor/dashboard" />
        ) : context.user && context.user.UserType === "4" ? (
          <Redirect to="/master-distributor/dashboard" />
        ) : (
     null
        )
      }
    </GlobalContext.Consumer>
  );
}

const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};
