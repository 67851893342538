import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col, InputGroup, Modal } from "react-bootstrap";
import { HiOutlineDocumentText } from "react-icons/hi";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { GlobalContext } from "../../Context/GlobalState";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MdCurrencyRupee } from "react-icons/md";
const Itdeclarationform = (onClose) => {
  const selectedEmployee = JSON.parse(
    sessionStorage.getItem("selectedEmployee")
  );
  console.log(selectedEmployee.value);
  const [isRented, setIsRented] = useState(false);
  const { password, username } = billpunchbasicauth;
  const [isLetoutproperty, setIsLetoutproperty] = useState(false);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [isRepayingselfHomeLoan, setIsRepayingselfHomeLoan] = useState(false);
  const [isRepayingHomeLoan, setIsRepayingHomeLoan] = useState(false);
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    amount: "",
    address: "",
    landlordName: "",
    urbanization: "",
    landlordPan: "",
    principalPaid: "",
    interestPaid: "",
    lenderName: "",
    lenderPan: "",
    providentFund: "",
    investmentType: "",
    investmentAmount: "",
    investmentType2: "",
    investmentAmount2: "",
    investmentType3: "",
    investmentAmount3: "",
    Income_After_Exemptions: "",
    Income_Tax: "",
    Professional_Tax: "",
    Employee_Provident_Fund: "",
    Leave_Encashment: "",
    annualRent: "",
    municipalTaxesPaid: "",
    netAnnualValue: "",
    standardDeduction: "",
    netIncomeLoss: "",
    principalPaidOnHomeLoan: "",
    interestPaidOnHomeLoan: "",
    lenderName: "",
    lenderPan: "",
    Othersource: "",
    SavingDeposit: "",
    FixedDeposit: "",
    NationalSaving: "",
  });

  const handleSwitchChange = (name) => {
    if (name === "rentedHouse") {
      setIsRented(!isRented);
    } else if (name === "repayingHomeLoan") {
      setIsRepayingHomeLoan(!isRepayingHomeLoan);
    } else if (name === "Letoutproperty") {
      setIsLetoutproperty(!isLetoutproperty);
    } else if (name === "RepayselfHomeLoan") {
      setIsRepayingselfHomeLoan(!isRepayingselfHomeLoan);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalAmount = formValues.amount * 12;

    const requestBody = {
      empid: selectedEmployee.value,
      firmid: user.firm_id,
      tax_type: activeTab === "oldRegime" ? "old" : "new",
      rented_house: isRented ? "Y" : "N",
      repaying_home: isRepayingHomeLoan ? "Y" : "N",
      letoutProp: isLetoutproperty ? "Y" : "N",
      invest_80c: formValues.investmentType !== "" ? "Y" : "N",
      invest_80d: formValues.investmentType2 !== "" ? "Y" : "N",
      other_invest: formValues.investmentType3 !== "" ? "Y" : "N",
      other_income: formValues.other_source !== "" ? "Y" : "N",
      status: "N",
      houseRentDetails: [
        {
          fromDate: formValues.fromDate,
          toDate: formValues.toDate,
          perMonthAmount: formValues.amount,
          totalAmount: totalAmount,
          address: formValues.address,
          landlordName: formValues.landlordName,
          urbanization: formValues.urbanization,
          landlordPan: formValues.landlordPan,
        },
      ],
      homeLoanDetails: isRepayingHomeLoan
        ? [
            {
              principal: formValues.principalPaidOnHomeLoan,
              intrestPaid: formValues.interestPaidOnHomeLoan,
              lenderName: formValues.lenderName,
              lenderPan: formValues.lenderPan,
            },
          ]
        : [],
      letoutProperty: isLetoutproperty
        ? [
            {
              annualRent: formValues.annualRent,
              municipalTaxes: formValues.municipalTaxesPaid,
              netValue: formValues.netAnnualValue,
              standardDeduction: formValues.standardDeduction,
              repayingHomeLoan: isRepayingHomeLoan ? "Y" : "N",
              netIncomeLoss: formValues.netIncomeLoss,
            },
          ]
        : [],
      invest80CDetails: [
        {
          investName: formValues.investmentType,
          investAmount: formValues.investmentAmount,
          type: "80C",
        },
      ],
      invest80DDetails: [
        {
          investName: formValues.investmentType2,
          investAmount: formValues.investmentAmount2,
          type: "80D",
        },
      ],
      otherInvestDetails: [
        {
          investName: formValues.investmentType3,
          investAmount: formValues.investmentAmount3,
          type: "other",
        },
      ],
      otherIncomeDetails: [
        {
          other_source: formValues.Othersource,
          saving_deposit: formValues.SavingDeposit,
          fixed_deposit: formValues.FixedDeposit,
          national: formValues.NationalSaving,
        },
      ],
      homeLoanForLetProp: isRepayingHomeLoan
        ? [
            {
              principalAmountPaid: formValues.principalPaidOnHomeLoan,
              interestPaid: formValues.interestPaidOnHomeLoan,
              lenderName: formValues.lenderName,
              lenderPan: formValues.lenderPan,
            },
          ]
        : [],
      previousDetails: [
        {
          incomeAfterExemption: formValues.Income_After_Exemptions,
          incomeTax: formValues.Income_Tax,
          professionalTax: formValues.Professional_Tax,
          epf: formValues.Employee_Provident_Fund,
          leaveEncashment: formValues.Leave_Encashment,
        },
      ],
    };

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/SubmitItDeclaration`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form");
    }
  };

  const [expandedSection1, setExpandedSection1] = useState(false);
  const [expandedSection2, setExpandedSection2] = useState(false);
  const [expandedSection3, setExpandedSection3] = useState(false);
  const [expandedSection4, setExpandedSection4] = useState(false);
  const [expandedSection5, setExpandedSection5] = useState(false);

  const toggleSection = (sectionNumber) => {
    switch (sectionNumber) {
      case 1:
        setExpandedSection1(!expandedSection1);
        break;
      case 2:
        setExpandedSection2(!expandedSection2);
        break;
      case 3:
        setExpandedSection3(!expandedSection3);
        break;
      case 4:
        setExpandedSection4(!expandedSection4);
        break;
      case 5:
        setExpandedSection5(!expandedSection5);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = () => {
    setIsRepayingHomeLoan(!isRepayingHomeLoan);
    if (!isRepayingHomeLoan) {
      setFormValues({
        ...formValues,
        principalPaidOnHomeLoan: "",
        interestPaidOnHomeLoan: "",
        lenderName: "",
        lenderPan: "",
      });
    }
  };

  const [activeTab, setActiveTab] = useState("oldRegime");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    const annualRent = parseFloat(formValues.annualRent) || 0;
    const municipalTaxesPaid = parseFloat(formValues.municipalTaxesPaid) || 0;
    const netAnnualValue = annualRent - municipalTaxesPaid;
    const standardDeduction = (netAnnualValue * 0.3).toFixed(2);
    const netIncomeLossBeforeInterest = (
      netAnnualValue - standardDeduction
    ).toFixed(2);
    const interestPaidOnHomeLoan =
      parseFloat(formValues.interestPaidOnHomeLoan) || 0;
    const netIncomeLoss = (
      netIncomeLossBeforeInterest - interestPaidOnHomeLoan
    ).toFixed(2);

    setFormValues((prevValues) => ({
      ...prevValues,
      netAnnualValue: netAnnualValue || "",
      standardDeduction: netAnnualValue ? standardDeduction : "",
      netIncomeLoss: netAnnualValue ? netIncomeLoss : "",
    }));
  }, [
    formValues.annualRent,
    formValues.municipalTaxesPaid,
    formValues.interestPaidOnHomeLoan,
  ]);

  return (
    <>
      {" "}
      <div className="p-4">
        <div className=" row ">
          <h5 className="col mb-0 px-3">
            {selectedEmployee
              ? selectedEmployee.label + "'s It Declaration"
              : "NA"}
          </h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Admin_Investment">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr />
        <div className="">
          <p>Select Tax Type</p>
          <div className="d-flex  mb-3">
            <Button
              style={{
                backgroundColor:
                  activeTab === "oldRegime" ? "#007bff" : "white",
                color: activeTab === "oldRegime" ? "white" : "darkblue",
              }}
              onClick={() => handleTabClick("oldRegime")}
            >
              Old Tax Regime
            </Button>
            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Button
              style={{
                backgroundColor:
                  activeTab === "newRegime" ? "#007bff" : "white",
                color: activeTab === "newRegime" ? "white" : "darkblue",
              }}
              onClick={() => handleTabClick("newRegime")}
            >
              New Tax Regime
            </Button>
          </div>
          <div className="row sticky-top bg-light">
            <div className="col-7">PARTICULARS</div>
            <div className="col">DECLARED AMOUNT</div>
          </div>

          <Form className="col-md-9 p-0 col-12">
            <Form.Group
              controlId="rentedHouseSwitch"
              style={{ background: "#EEF7FF" }}
            >
              <Row className="align-items-center">
                <Col className="align-items-center d-flex">
                  <Form.Label className="m-0" style={{ color: "#000" }}>
                    Are you staying in a rented house?
                  </Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Form.Check
                    type="switch"
                    id="custom-switch-rented"
                    label={isRented ? "Yes" : "No"}
                    checked={isRented}
                    onChange={() => handleSwitchChange("rentedHouse")}
                  />
                </Col>
              </Row>
            </Form.Group>

            {isRented && (
              <>
                <div style={{ background: "#fff" }}>
                  <div className="row m-0 p-0">
                    <Form.Group className="col-8" controlId="rentalPeriod">
                      <Form.Label>Rental Period</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="date"
                          name="fromDate"
                          value={formValues.fromDate}
                          onChange={handleInputChange}
                          placeholder="From Date"
                        />
                        <Form.Control
                          type="date"
                          name="toDate"
                          value={formValues.toDate}
                          onChange={handleInputChange}
                          placeholder="To Date"
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="col" controlId="amount">
                      <Form.Label>Amount / Month</Form.Label>
                      <Form.Control
                        type="text"
                        name="amount"
                        value={formValues.amount}
                        onChange={handleInputChange}
                        placeholder="Enter amount"
                      />
                    </Form.Group>
                  </div>

                  <div className="row m-0 p-0">
                    <Form.Group className="col-8" controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        placeholder="Enter address"
                      />
                    </Form.Group>
                  </div>

                  <div className="row m-0 p-0">
                    <Form.Group className="col-8" controlId="landlordName">
                      <Form.Label>Landlord Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="landlordName"
                        value={formValues.landlordName}
                        onChange={handleInputChange}
                        placeholder=" Landlord's name"
                      />
                    </Form.Group>
                  </div>
                  <div className="row m-0 p-0">
                    <Form.Group className="col-4" controlId="urbanization">
                      <Form.Label>Urbanization</Form.Label>
                      <Form.Control
                        type="text"
                        name="urbanization"
                        value={formValues.urbanization}
                        onChange={handleInputChange}
                        placeholder=" Urbanization"
                      />
                    </Form.Group>
                    <Form.Group className="col-4" controlId="landlordPan">
                      <Form.Label>Landlord PAN</Form.Label>
                      <Form.Control
                        type="text"
                        name="landlordPan"
                        value={formValues.landlordPan}
                        onChange={handleInputChange}
                        placeholder=" Landlord's PAN"
                      />
                    </Form.Group>
                  </div>
                </div>{" "}
              </>
            )}

            <Form.Group
              controlId="repayingHomeLoanSwitch"
              style={{ background: "#EEF7FF" }}
            >
              <Row className="align-items-center">
                <Col className="align-items-center d-flex col-8">
                  <Form.Label className="m-0" style={{ color: "#000" }}>
                    Are you repaying home for self-occupied house property?
                  </Form.Label>
                </Col>
                <Col className="d-flex justify-content-end col-4">
                  <Form.Check
                    type="switch"
                    id="custom-switch-home-loan"
                    label={isRepayingselfHomeLoan ? "Yes" : "No"}
                    checked={isRepayingselfHomeLoan}
                    onChange={() => handleSwitchChange("RepayselfHomeLoan")}
                  />
                </Col>
              </Row>
            </Form.Group>

            {isRepayingselfHomeLoan && (
              <>
                <div style={{ background: "#fff" }}>
                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="principalPaid"
                  >
                    <Form.Label
                      style={{ color: "#000" }}
                      column
                      className="col-8"
                    >
                      Principal Paid on Home Loan
                      <br />
                      <span style={{ color: "grey" }}>
                        This will be automatically included in the 80C section
                      </span>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="principalPaid"
                        value={formValues.principalPaid}
                        onChange={handleInputChange}
                        placeholder="Enter principal amount paid"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="interestPaid"
                  >
                    <Form.Label
                      style={{ color: "#000" }}
                      column
                      className="col-8"
                    >
                      Interest Paid on Home Loan
                      <br />{" "}
                      <span style={{ color: "grey" }}>
                        This Will be automatically included in Section 24
                      </span>
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="interestPaid"
                        value={formValues.interestPaid}
                        onChange={handleInputChange}
                        placeholder="Enter interest amount paid"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-0" controlId="lenderName">
                    <Form.Label
                      style={{ color: "#000" }}
                      column
                      className="col-8"
                    >
                      Name of Lender
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="lenderName"
                        value={formValues.lenderName}
                        onChange={handleInputChange}
                        placeholder="Enter name of lender"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-0" controlId="lenderPan">
                    <Form.Label
                      style={{ color: "#000" }}
                      column
                      className="col-8"
                    >
                      Lender PAN
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="lenderPan"
                        value={formValues.lenderPan}
                        onChange={handleInputChange}
                        placeholder="Enter lender PAN"
                      />
                    </Col>
                  </Form.Group>
                </div>
              </>
            )}
            <Form.Group
              controlId="Letoutproperty"
              style={{ background: "#EEF7FF" }}
            >
              <Row className="align-items-center">
                <Col className="align-items-center d-flex">
                  <Form.Label className="m-0" style={{ color: "#000" }}>
                    Is the employee receiving rental income from let out
                    property?
                  </Form.Label>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Form.Check
                    type="switch"
                    id="Let-out-property"
                    label={isLetoutproperty ? "Yes" : "No"}
                    checked={isLetoutproperty}
                    onChange={() => handleSwitchChange("Letoutproperty")}
                  />
                </Col>
              </Row>
            </Form.Group>

            {isLetoutproperty && (
              <>
                <div
                  style={{
                    background: "#fff",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h5>Let Out Property Details</h5>

                  {/* Annual Rent Received */}
                  <Form.Group as={Row} className="mb-0" controlId="annualRent">
                    <Form.Label column sm={8}>
                      Annual Rent Received
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="annualRent"
                          value={formValues.annualRent}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="municipalTaxesPaid"
                  >
                    <Form.Label column sm={8}>
                      Municipal Taxes Paid
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="municipalTaxesPaid"
                          value={formValues.municipalTaxesPaid}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="netAnnualValue"
                  >
                    <Form.Label column sm={8}>
                      Net Annual Value
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="netAnnualValue"
                          value={formValues.netAnnualValue}
                          readOnly
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="standardDeduction"
                  >
                    <Form.Label column sm={8}>
                      Standard Deduction (@ 30% of Net Annual Value)
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="standardDeduction"
                          value={formValues.standardDeduction}
                          readOnly
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-0">
                    <Col>
                      <Form.Check
                        className="m-0"
                        type="checkbox"
                        label="Repaying Home Loan for This Property"
                        checked={isRepayingHomeLoan}
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Form.Group>

                  {isRepayingHomeLoan && (
                    <>
                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="principalPaidOnHomeLoan"
                      >
                        <Form.Label column sm={8}>
                          Principal Paid On Home Loan
                        </Form.Label>
                        <Col sm={4}>
                          <InputGroup>
                            <InputGroup.Text>
                              <MdCurrencyRupee />
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="principalPaidOnHomeLoan"
                              value={formValues.principalPaidOnHomeLoan}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="interestPaidOnHomeLoan"
                      >
                        <Form.Label column sm={8}>
                          Interest Paid on Home Loan
                        </Form.Label>
                        <Col sm={4}>
                          <InputGroup>
                            <InputGroup.Text>
                              <MdCurrencyRupee />
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              name="interestPaidOnHomeLoan"
                              value={formValues.interestPaidOnHomeLoan}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="lenderName"
                      >
                        <Form.Label column sm={8}>
                          Name of the Lender
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            name="lenderName"
                            value={formValues.lenderName}
                            onChange={handleInputChange}
                            placeholder="Enter lender name"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-0"
                        controlId="lenderPan"
                      >
                        <Form.Label column sm={8}>
                          Lender PAN
                        </Form.Label>
                        <Col sm={4}>
                          <Form.Control
                            type="text"
                            name="lenderPan"
                            value={formValues.lenderPan}
                            onChange={handleInputChange}
                            placeholder="Enter lender PAN"
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="netIncomeLoss"
                  >
                    <Form.Label column sm={8}>
                      Net Income/Loss from House Property
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="netIncomeLoss"
                          value={formValues.netIncomeLoss}
                          readOnly
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </div>
              </>
            )}
            {/* Section 5 */}
            <div
              onClick={() => toggleSection(5)}
              className="p-3 mt-2 d-flex justify-content-between align-items-center"
              style={{ background: "#EEF7FF", cursor: "pointer" }}
            >
              <div>
                <HiOutlineDocumentText />{" "}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  Other Sources of Income
                </span>
              </div>
              {expandedSection5 ? <FaAngleUp /> : <FaAngleDown />}
            </div>

            {expandedSection5 && (
              <>
                <div
                  style={{
                    background: "#fff",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Form.Group as={Row} className="mb-0" controlId="Othersource">
                    <Form.Label column sm={8}>
                      Income from other sources
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="Othersource"
                          value={formValues.Othersource}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="SavingDeposit"
                  >
                    <Form.Label column sm={8}>
                      Interest Earned from Savings Deposit
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="SavingDeposit"
                          value={formValues.SavingDeposit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="FixedDeposit"
                  >
                    <Form.Label column sm={8}>
                      Interest Earned from Fixed Deposit
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="FixedDeposit"
                          value={formValues.FixedDeposit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-0"
                    controlId="NationalSaving"
                    
                  >
                    <Form.Label column sm={8}>
                      Interest Earned from National Savings Certificates{" "}
                    </Form.Label>
                    <Col sm={4}>
                      <InputGroup>
                        <InputGroup.Text>
                          <MdCurrencyRupee />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="NationalSaving"
                          value={formValues.NationalSaving}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </div>
              </>
            )}
            {/* Section 1 */}
            <div
              onClick={() => toggleSection(1)}
              className="p-3 mt-2 d-flex justify-content-between align-items-center"
              style={{ background: "#EEF7FF", cursor: "pointer" }}
            >
              <div>
                <HiOutlineDocumentText />{" "}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  80C Investment
                </span>
              </div>
              {expandedSection1 ? <FaAngleUp /> : <FaAngleDown />}
            </div>

            {expandedSection1 && (
              <>
                <p className="p-1" style={{ fontSize: ".8rem" }}>
                  <b>Note:</b> Declare investments such as LIC premiums, mutual
                  funds and PPF under this section. The maximum tax saving
                  investment you can make under 80C is ₹150,000
                </p>

                <Form.Group as={Row} className="" controlId="investmentType">
                  <Col className="col-6">
                    <Form.Control
                      as="select"
                      name="investmentType"
                      value={formValues.investmentType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Investment Type</option>
                      <option value="LIC Premiums">LIC Premiums</option>
                      <option value="Mutual Funds">Mutual Funds</option>
                      <option value="PPF">PPF</option>
                    </Form.Control>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      name="investmentAmount"
                      value={formValues.investmentAmount}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                      style={{ width: "12rem" }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            {/* Section 2 */}
            <div
              onClick={() => toggleSection(2)}
              className="p-3 mt-2 d-flex justify-content-between align-items-center"
              style={{ background: "#EEF7FF", cursor: "pointer" }}
            >
              <div>
                <HiOutlineDocumentText />{" "}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  80D Exemptions
                </span>
              </div>
              {expandedSection2 ? <FaAngleUp /> : <FaAngleDown />}
            </div>

            {expandedSection2 && (
              <>
                <p className="p-1" style={{ fontSize: ".8rem" }}>
                  <b>Note:</b> Declare the mediclaim insurance policies for
                  yourself, spouse, childer and parents. The maximum tax saving
                  investment you can make under 80D is ₹150,000
                </p>
                <Form.Group as={Row} className="" controlId="investmentType2">
                  <Col className="col-6">
                    <Form.Control
                      as="select"
                      name="investmentType2"
                      value={formValues.investmentType2}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Investment Type</option>
                      <option value="Health Insurance">Health Insurance</option>
                      <option value="Mediclaim Policy">Mediclaim Policy</option>
                      <option value="Critical Illness Policy">
                        Critical Illness Policy
                      </option>
                    </Form.Control>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      name="investmentAmount2"
                      value={formValues.investmentAmount2}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                      style={{ width: "12rem" }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            {/* Section 3 */}
            <div
              onClick={() => toggleSection(3)}
              className="p-3 mt-2 d-flex justify-content-between align-items-center"
              style={{ background: "#EEF7FF", cursor: "pointer" }}
            >
              <div>
                <HiOutlineDocumentText />{" "}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  Other Investment & Exemptions
                </span>
              </div>
              {expandedSection3 ? <FaAngleUp /> : <FaAngleDown />}
            </div>

            {expandedSection3 && (
              <>
                <p className="p-1" style={{ fontSize: ".8rem" }}>
                  <b>Note:</b> Declare Other investment & exemptions such as
                  Voluntary NPS, Interest paid on Education Loan and Medical
                  Expenditure under this section
                </p>
                <Form.Group as={Row} className="" controlId="investmentType3">
                  <Col className="col-6">
                    <Form.Control
                      as="select"
                      name="investmentType3"
                      value={formValues.investmentType3}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Investment Type</option>
                      <option value="Voluntary NPS">Voluntary NPS</option>
                      <option value="Interest on Education Loan">
                        Interest on Education Loan
                      </option>
                      <option value="Medical Expenditure">
                        Medical Expenditure
                      </option>
                    </Form.Control>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      name="investmentAmount3"
                      value={formValues.investmentAmount3}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                      style={{ width: "12rem" }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            {/* Section 4 */}
            <div
              onClick={() => toggleSection(4)}
              className="p-3 mt-2 d-flex justify-content-between align-items-center"
              style={{ background: "#EEF7FF", cursor: "pointer" }}
            >
              <div>
                <HiOutlineDocumentText />{" "}
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  Previous Employment
                </span>
              </div>
              {expandedSection4 ? <FaAngleUp /> : <FaAngleDown />}
            </div>

            {expandedSection4 && (
              <>
                <Form.Group
                  as={Row}
                  className="mb-0"
                  controlId="Income_After_Exemptions"
                >
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Income After Exemptions
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="Income_After_Exemptions"
                      value={formValues.Income_After_Exemptions}
                      onChange={handleInputChange}
                      placeholder="Enter source"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-0" controlId="Income_Tax">
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Income Tax
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="Income_Tax"
                      value={formValues.Income_Tax}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-0"
                  controlId="Professional_Tax"
                >
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Professional Tax
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="Professional_Tax"
                      value={formValues.Professional_Tax}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-0"
                  controlId="Employee_Provident_Fund"
                >
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Employee Provident Fund
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="Employee_Provident_Fund"
                      value={formValues.Employee_Provident_Fund}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-0"
                  controlId="Leave_Encashment"
                >
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Leave Encashment Exemptions
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="Leave_Encashment"
                      value={formValues.Leave_Encashment}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            <div className="mt-3">
              {" "}
              <Button
                onClick={handleSubmit}
                className="btn-sm"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>{" "}
              <Button
                style={{ background: "#fff", border: "none" }}
                disabled
              ></Button>
              <Link to="/retailer/Admin_Investment">
                <Button className="btn-sm" variant="light">
                  Cancel
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Itdeclarationform;
