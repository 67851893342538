import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Admindeduction,
  Adminearnings,
  Adminreimbursements,
} from "./SalaryTable";
import { Button } from "react-bootstrap";

const Salarytemplateadmin = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [data, setData] = useState([
    {
      name: "John Doe",
      deductionType: "Health Insurance",
      deductionFrequency: "Monthly",
      status: "Active",
    },
    {
      name: "Jane Smith",
      deductionType: "Retirement Fund",
      deductionFrequency: "Yearly",
      status: "Inactive",
    },
    {
      name: "Alice Johnson",
      deductionType: "Loan Repayment",
      deductionFrequency: "Monthly",
      status: "Active",
    },
  ]);

  return (
    <>
      <div className="p-3">
        <div className=" row ">
          <h5 className="px-2">Salary Templates </h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/New_Salary_template">
              <Button className="btn-sm" onClick={toggleDropdown}>
                Create New
              </Button>
            </Link>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered m-0 p-0 ">
            <thead>
              <tr style={{ borderBottom: "1px solid lightgrey" }}>
                <th
                  style={{
                    color: "#0074D9",
                    background: "#eff4fa",
                    border: "none",
                  }}
                >
                  TEMPLATE NAME
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    background: "#eff4fa",
                    border: "none",
                  }}
                >
                  DESCRIPTION
                </th>

                <th
                  style={{
                    color: "#0074D9",
                    background: "#eff4fa",
                    border: "none",
                  }}
                >
                  STATUS
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    background: "#eff4fa",
                    border: "none",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "700",
                      }}
                    >
                      {item.name}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {item.deductionType}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        color: item.status === "Active" ? "green" : "red",
                      }}
                    >
                      {item.status}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Salarytemplateadmin;
