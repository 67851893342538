import React from "react";
import { Button } from "react-bootstrap";
const BBPSPrintSlip = () => {

  const billDetails = JSON.parse(localStorage.getItem('print'));
  const printReceipt = () => {
    window.print();
  };
  const NOTE =
    "This is Computer Generated receipt and does not require physical signature.";

  return (
    <div
      style={{
        margin: "5vw",
        border: "1px solid #000",
        borderRadius: "5px",
        padding: "1rem",
      }}
    >
      <div>
        <div style={{ margin: "0px 10px", padding: "0px 10px" }}>
          <img src={require("../../assets/images/BBPSSidebarLogo.png")} style={{ width: 'auto', height: '1.4rem', backgroundColor: 'whitesmoke', }} />

        </div>
        <div>
          {billDetails && (
            <div className="card" style={{ margin: "10px", padding: "10px" }}>
              <table style={{ border: "none", fontFamily: '-webkit-body' }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {"₹ "}
                    {billDetails.billerResponse.amount}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Bill date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billDate}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Due Date.
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.dueDate}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    Status
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.paid ? "Paid" : "Unpaid"}
                  </td>

                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: '50%',
                      fontSize: '1rem'
                    }}
                  >
                    {" "}
                    {billDetails.refId}
                  </td>
                </tr>

              </table>
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", fontSize: ".8rem" }}>
        <strong> {`NOTE : `}</strong>
        {NOTE}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }} />
        <button
          style={{ marginLeft: "auto" }}
          className="hide-on-print"
          onClick={printReceipt}
        >
          {"Print"}
        </button>
      </div>
    </div>
  );
};

export default BBPSPrintSlip;
