import React, { useState } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Slider = () => {
  const [filterState, setFilterState] = useState(0);

  const handleTabClick = (state) => {
    setFilterState(state);
  };

  const images = {
    0: {
      src: require("../../../assets/images/Slideradmin.png"),
      alt: "Admin dashboard",
    },
    1: {
      src: require("../../../assets/images/Sliderhrdash.jpeg"),
      alt: "HR dashboard",
    },
    2: {
      src: require("../../../assets/images/Slideremp.png"),
      alt: "Employee dashboard",
    },
  };

  return (
    <div id="slider" className="p-5">
      <style>
        {`
          @media (max-width: 768px) {
            #slider {
              padding: 1rem;
            }

            .nav-pills-custom {
              flex-wrap: nowrap;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              width: 100%;
              white-space: nowrap;
              justify-content: flex-start; 
            }

            .nav-pills-custom .nav-item {
              flex: 0 0 auto;
              text-align: center;
              padding: 0.5rem;
            }

            .tab-content-custom-pill {
              overflow-x: auto;
            }

            .tab-content-custom-pill img {
              width: 100%;
              height: auto;
            }
          }

          @media (min-width: 769px) {
            .nav-pills-custom {
              justify-content: center;
              width:46%;
            }
          }
        `}
      </style>
      <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
        <div className="p-0 mt-0" style={{ borderRadius: ".4rem" }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tab-0">
            <Row className="p-0 m-0 justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <Nav
                  className="p-md-2 nav-pills-custom align-items-center d-flex flex-nowrap"
                  style={{
                    background: "rgba(0, 133, 255, 0.1)",
                    borderRadius: "2rem",
                  }}
                >
                  {[
                    "Admin Dashboard",
                    "HR Dashboard",
                    "Employee Dashboard",
                  ].map((text, index) => (
                    <Nav.Item key={index} className="nav-item mx-1 pr-1 mb-1">
                      <Nav.Link
                        className={`btn ${
                          filterState === index
                            ? "btn-primary active"
                            : "btn-outline-primary"
                        }`}
                        onClick={() => handleTabClick(index)}
                        eventKey={`tab-${index}`}
                        style={{
                          fontSize: ".9rem",
                          border: "none",
                          padding: "10px",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                          borderRadius: "2rem",
                          whiteSpace: "normal",
                          background: filterState === index ? "#fff" : "#0085FF",
                          color: filterState === index ? "#0085FF" : "#fff",
                        }}
                        onMouseLeave={(e) => {
                          if (filterState !== index) {
                            e.currentTarget.style.background = "#0085FF";
                            e.currentTarget.style.color = "#fff";
                          }
                        }}
                        onMouseEnter={(e) => {
                          if (filterState !== index) {
                            e.currentTarget.style.background = "#fff";
                            e.currentTarget.style.color = "#0085FF";
                          }
                        }}
                      >
                        <div>
                          <img
                            className="rounded-circle"
                            alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/icon1.jpg")}
                          />{" "}
                          &nbsp;{text}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col
                xs={12}
                sm={6}
                className="mt-3 p- d-flex justify-content-center"
              >
                <div
                  className="card tab-content-custom-pill pb-0"
                  style={{ width: "100%" }}
                >
                  <Tab.Content>
                    {Object.keys(images).map((key, index) => (
                      <Tab.Pane eventKey={`tab-${index}`} key={index}>
                        <img
                          alt={images[key].alt}
                          style={{ width: "100%", height: "auto" }}
                          src={images[key].src}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Slider;
