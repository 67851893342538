import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";
var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function inWords(num) {
  let n;
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return capitalizeFirstLetter(str);
}
const PrintSlip2 = () => {
  let printData = localStorage.getItem("print");
  if (printData) {
    printData = JSON.parse(printData);
  } else {
    printData = {};
  }
  let query = useQuery();
  const receiptNo = query.get("id");
  const Mode = query.get("type");
  const printReceipt = () => {
    window.print();
  };

  const [data, setData] = useState({});

  const GetData = () => {
    setData({
      shopName: printData.shopName ? printData.shopName : "N/A",
      // mobileNo: printData.rch_mobile,
      mode: Mode,
      bankName: printData.rch_bank_name,
      AccountNo: printData.rch_mobile,
      orderNo: printData.tid,
      date: printData.rch_indate,
      UtrNo: printData.rch_transaction_id,
      Status: printData.rch_status_desc,
      amountPaid: printData.rch_amount,
      InWords: `${inWords(Number(printData.rch_amount))}`,
    });
  };

  useEffect(() => {
    GetData();
  }, [Mode]);

  return (
    <>
      <div
        style={{
          margin: "5vw",
          border: "1px solid #000",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <table style={{ padding: "70px", border: "none" }}>
              <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Receipt No :
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {receiptNo}
                  </td>
                </tr>
                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Shop Name
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {data.shopName}
                  </td>
                </tr>
                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                ></tr>
                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Bank Name
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {data.bankName}
                  </td>
                </tr>

                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      border: "1px solid #000",
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Account No
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {" "}
                    {data.AccountNo}
                  </td>
                </tr>
                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Date :
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {data.date}
                  </td>
                </tr>
                <tr
                  style={{ padding: "0px", border: "none", background: "none" }}
                >
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    Mode
                  </td>
                  <td
                    style={{
                      fontSize: ".9rem",
                      padding: "0px 12px 0px 0px",
                      border: "none",
                    }}
                  >
                    {data.mode}
                  </td>
                </tr>
              </table>
            </div>
            <div style={{}}>
              <img
                src={require("../../assets/images/company/logo_square.png")}
                style={{ width: "16rem", marginLeft: "auto" }}
              />
            </div>
          </div>

        <div style={{ display: "flex" }}>
          <table style={{ width: "100%", fontSize: "0.9rem" }}>
            <tr style={{ padding: "0px", border: "none", background: "none" }}>
              <td style={{ fontSize: ".9rem" }}> Order No</td>
              <td style={{ fontSize: ".9rem" }}> UTR No</td>
              <td style={{ fontSize: ".9rem" }}> Status</td>
              <td style={{ fontSize: ".9rem" }}> Amount Paid</td>
            </tr>
            <tr style={{ padding: "0px", border: "none", background: "none" }}>
              <td style={{ fontSize: ".9rem" }}>{data.orderNo} </td>
              <td style={{ fontSize: ".9rem" }}> {data.UtrNo}</td>
              <td style={{ fontSize: ".9rem" }}>{data.Status}</td>
              <td style={{ fontSize: ".9rem" }}>{data.amountPaid} </td>
            </tr>
            {/* <tr style={{ padding: "0px", border: "none", background: "none" }}>
            <td style={{ fontSize: ".9rem" }}> </td>
            <td style={{ fontSize: ".9rem" }}></td>
            <td style={{ fontSize: ".9rem" }}></td>
            <td style={{ fontSize: ".9rem" }}> {data.amountPaid}</td>
          </tr> */}
          </table>
        </div>
        <div style={{ display: "flex", fontSize: ".8rem" }}>
          {`In words : `}

          <strong>{data.InWords}</strong>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1" }} />
          <button
            style={{ marginLeft: "auto" }}
            className="hide-on-print"
            onClick={printReceipt}
          >
            {"Print"}
          </button>
        </div>
      </div>
    </>
  );
};

export default PrintSlip2;
