import React, { useState,useContext } from "react";
import BBPSReportTable from "./BBPSReportTable";
import './MyCss.css'; 
import { GlobalContext } from "../../Context/GlobalState";
const BBPSReport2 = () => {
  const { user } = useContext(GlobalContext);
  return (
    <div style={{ margin: "10px" }}>
 
      <BBPSReportTable agentId={user.ReferId} />
    </div>
  );
};

export default BBPSReport2;
