import React, { useState } from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Admindeduction, Adminearnings, Adminreimbursements,  } from "./SalaryTable";
import { Button } from "react-bootstrap";

const Salarycomponentsadmin = () => {
    const [activeTab, setActiveTab] = useState('tab1');
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <>
      <div className="p-3">
      <div className=" row m-0 p-0">
        <h5 className="px-2">Salary Components </h5>
          <div className="col d-flex justify-content-end">
          <div className="dropdown">
              <Button
                className="btn-sm"
                onClick={toggleDropdown}
              >
                Add Components
              </Button>
              {dropdownVisible && (
                <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                  <Link to="/retailer/Admin_Earning_form" className="dropdown-item p-2" style={{fontSize:".9rem"}}>Earning</Link>
                  <Link  to="/retailer/Admin_Deduction" className="dropdown-item p-2" style={{fontSize:".9rem"}}> Deduction</Link>
                  <Link to="/retailer/Admin_Reimbursement" className="dropdown-item p-2" style={{fontSize:".9rem"}}>Reimbursement</Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
              style={activeTab === 'tab1' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab1')}
            >
              Earnings
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
              style={activeTab === 'tab2' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab2')}
            >
              Deduction
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
              style={activeTab === 'tab3' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab3')}
            >
              Reimbursement 
            </button>
          </li>
         
        
        </ul>
        <div className="tab-content p-3">
          {activeTab === 'tab1' && <Adminearnings/>}
          {activeTab === 'tab2' && <Admindeduction/>}
          {activeTab === 'tab3' && <Adminreimbursements/>}
        </div>
      </div>
    </>
  );
};

export default Salarycomponentsadmin;
