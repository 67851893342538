import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import CryptoJS from "crypto-js";
import { createHash } from "../../utils/utilFuctions";

const CMS = () => {
    const [token, setToken] = useState("");
    const [data, setData] = useState("");
    const [data2, setData2] = useState("");
    const { user } = useContext(GlobalContext);

    const validate = (event) => {
        event.preventDefault();
        let errors = {};
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            openInNewTab(`https://fpuat.tapits.in/UberCMSBC/#/login?data=${data}&skey=${data2}`)
        }
        return false;
    };

    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getUrl = () => {
        const hash = createHash(user.ReferId);
        let loginrequestdata = {
            "additionalParams": null,
            latitude: 13.0641367,
            longitude: 80.2480973,
            "loginType": '2',
            "supermerchantId": 1179,
            "merchantId": user.ReferId,
            "merchantPin": hash,
            "mobileNumber": '9168805979',
            "amount": '100',
            "superMerchantSkey": "9db3f8c9dbe3fa2f0986ad8a17541d6671e2fba0a406753a1909d91164d3d5f4"
        }

        console.log(loginrequestdata);
        let parsedBase64Key = CryptoJS.enc.Base64.parse(loginrequestdata.superMerchantSkey);

        //  console.log(parsedBase64Key);
        const encryptInfo = window.btoa(
            CryptoJS.AES.encrypt(JSON.stringify(loginrequestdata), parsedBase64Key,
                {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                }
            )
        );
        setData(encryptInfo);
        setData2(parsedBase64Key);
    }

    useEffect(() => {
        getUrl();
    }, []);
    console.log(token);
    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-4">
                <div
                    style={{
                        width: "100%",
                        background: "white",
                    }}
                >
                    <div className="row">
                        <div className="col-7 p-md-5 p-sm-4">
                            {" "}
                            <h4 className="d-flex align-items-center">E-PAN Service</h4>
                            <p
                                className="d-flex align-items-center"
                                style={{ color: "black", fontSize: "1rem" }}
                            >
                                E-PAN offers the convenience of easy and secure access to your
                                PAN details online, making it simpler to carry out financial
                                transactions and tax-related activities.
                            </p>
                            <h6>Apply now for E-PAN</h6>
                            <form
                            //    action={`https://fpuat.tapits.in/UberCMSBC/#/login?data=${data}&skey=${data2}`}
                                method="Post"
                                onSubmit={validate}
                            >
                                <input type="hidden" name="authentication" value={token} />{" "}
                                <button
                                    className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                                    type="submit"
                                    style={{
                                        borderRadius: ".2rem",
                                        fontWeight: "bold",
                                        width: "10rem",
                                        border: "none",
                                        color: "#ffff",
                                        fontSize: "1rem",
                                        height: "2em",
                                        display: "flex",
                                    }}
                                >
                                    {" "}
                                    Apply
                                </button>
                            </form>
                        </div>
                        <div className="col-5 d-flex justify-content-center align-items-center">
                            <img
                                alt=""
                                style={{ height: "20rem" }}
                                src={require("../../../assets/images/epan.png")}
                            />
                        </div>
                    </div>
                    <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
                        <h5>Information</h5>
                        <p style={{ color: "#000", fontSize: "1rem" }}>
                            <b> E-PAN:</b> it simplifies the process of obtaining and managing
                            their Permanent Account Number (PAN), eliminating the need for
                            physical cards and paperwork. This digital version provides easy
                            access to PAN details, allowing customers to conveniently verify
                            their identity for various financial and tax-related transactions.
                        </p>
                        <p style={{ color: "#000", fontSize: "1rem" }}>
                            <b> Physical PAN: </b> Physical PAN (Permanent Account Number)
                            card serves as an essential government-issued identification
                            document in India. It plays a pivotal role in various financial
                            and tax-related transactions. Customers appreciate the tangible
                            nature of the card, as it provides a physical proof of their
                            identity and PAN details.
                        </p>
                        <p style={{ color: "#000", fontSize: "1rem" }}>
                            <b> Verify with OTP:</b> Receive a one-time password on your
                            registered mobile number and enter it for verification. If you
                            select OTP, proceed with OTP only.
                        </p>
                        <p style={{ color: "#000", fontSize: "1rem" }}>
                            <b> Verify with Biometric:</b> Use biometric authentication to
                            verify your identity. If you select biometric, proceed with
                            biometric verification only.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CMS;
