import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Form } from "react-bootstrap";
import { IoSettings } from "react-icons/io5";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import { Manageloanmodaladmin } from "./Modal";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";

const Recordloanadmin = () => {
  const history = useHistory();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [perquisiteRate, setPerquisiteRate] = useState(null);
  const [employeesData, setEmployeesData] = useState([]);

  const handleEmployeeChange = (option) => {
    setSelectedEmployee(option);
    console.log(`Selected Employee: ${option.label}`);
  };

  const [loancategorydata, setloancategorydata] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [installments, setInstallments] = useState(null);

  const [formData, setFormData] = useState({
    loanName: "",
    employeeName: "",
    loanAmount: "",
    disbursementDate: "",
    reason: "",
    exemptLoan: false,
    emiDeductionStartDate: "",
    instalmentAmount: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      exemptLoan: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        firmid: user.firm_id,
        loan_name: formData.loanName.label,
        loan_id: formData.loanName.value,
        perquiste_rate: perquisiteRate,
        loan_amount: formData.loanAmount,
        disbursment_date: formData.disbursementDate,
        reason: formData.reason,
        expempt_loan: formData.exemptLoan ? "Y" : "N",
        empid: selectedEmployee ? selectedEmployee.value : "",
        emp_name: selectedEmployee ? selectedEmployee.label : "",
        emi_start_date: formData.emiDeductionStartDate,
        installment_amount: formData.instalmentAmount,
        number_of_installment: installments,
        completion_date: formData.emiDeductionStartDate,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddLoanRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        history.push("/retailer/Loan");
      } else {
        swal("Success", data.msg, "success");
        history.push("/retailer/Loan");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getloancategory = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetLoanRecordCategory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const loanOptions = data.data.map((loan) => ({
          value: loan.pk_cat_id,
          label: loan.loan_name,
          perquisiteRate: loan.perquiste_rate,
        }));
        setloancategorydata([
          ...loanOptions,
          {
            value: "addLoan",
            label: (
              <>
                <IoSettings style={{ color: "blue" }} />
                <span style={{ color: "blue" }}>Manage Loan</span>
              </>
            ),
          },
        ]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log(error);
      setTransactionLoaderState(false);
    }
  };

  const GetAllEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        firmId: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const employeeOptions = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setEmployeesData(employeeOptions);
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Getloancategory();
    GetAllEmployee();
  }, []);
  const calculateInstallments = () => {
    const loanAmount = parseFloat(formData.loanAmount);
    const interestRate = parseFloat(perquisiteRate) / 100;
    const numberOfInstallments = Math.ceil(loanAmount / parseFloat(formData.instalmentAmount));
    setInstallments(numberOfInstallments);
  };

  useEffect(() => {
    if (formData.loanAmount && perquisiteRate && formData.instalmentAmount) {
      calculateInstallments();
    }
  }, [formData.loanAmount, perquisiteRate, formData.instalmentAmount]);

  return (
    <>
      <div className="px-2 mt-4" style={{ overflow: "hidden" }}>
        <Manageloanmodaladmin show={showModal} handleClose={handleCloseModal} />
        <div className=" row ">
          <h4 className="col px-3">Record Loan</h4>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Loan">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr className="m-2" />
        <div className="col-md-6 col-11">
          <Form onSubmit={handleSubmit}>
            <div className="row p-2">
              <Form.Group className="col mb-0 " controlId="loanName">
                <Form.Label style={{ color: "#000" }}>Loan Name</Form.Label>
                <Select
                  options={loancategorydata}
                  value={formData.loanName}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "addLoan") {
                      handleShowModal();
                    } else {
                      setFormData({ ...formData, loanName: selectedOption });
                      setPerquisiteRate(selectedOption.perquisiteRate);
                    }
                  }}
                />
                <p className="pt-1">
                  Perquisite Rate:{" "}
                  {perquisiteRate ? `${perquisiteRate}%` : "N/A"}
                </p>
              </Form.Group>
            </div>

            <div className="row p-1">
              <Form.Group className="col" controlId="employeeName">
                <Form.Label style={{ color: "#000" }}>Employee Name</Form.Label>
                <Select
                  value={selectedEmployee}
                  onChange={handleEmployeeChange}
                  options={employeesData}
                />
              </Form.Group>

              <Form.Group className="col" controlId="loanAmount">
                <Form.Label style={{ color: "#000" }}>Loan Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Loan Amount"
                  name="loanAmount"
                  value={formData.loanAmount}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            <div className="row p-1">
              <Form.Group className="col-6" controlId="disbursementDate">
                <Form.Label style={{ color: "#000" }}>
                  Disbursement Date
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter Disbursement Date"
                  name="disbursementDate"
                  value={formData.disbursementDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            <Form.Group className="px-2" controlId="reason">
              <Form.Label style={{ color: "#000" }}>Reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Reason"
                name="reason"
                value={formData.reason}
                onChange={handleInputChange}
              />
            </Form.Group>

            <div className="row ">
              <div className="col-1 p-2 d-flex justify-content-center align-items-start">
                <input
                  className="px-1"
                  type="checkbox"
                  id="exemptLoanCheckbox"
                  onChange={handleCheckboxChange}
                  checked={formData.exemptLoan}
                />
              </div>
              <div className="col p-0">
                <label htmlFor="exemptLoanCheckbox">
                  Exempt this loan from perquisite calculation. According to
                  Rule 3(A), employees availing medical loan or any loan below
                  ₹20,000 can be exempted from perquisite calculation.
                </label>
              </div>
            </div>
          </Form>
        </div>
        <hr className="" />

        {formData.disbursementDate && (
          <>
            <h5 className="px-md-4 px-2">Repayments</h5>
            <div className="p-3 col-6">
              <div className="row p-1">
                <Form.Group className="col-6" controlId="emiDeductionStartDate">
                  <Form.Label style={{ color: "#000" }}>
                    EMI Deduction Start Date*
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="dd/MM/yyyy"
                    name="emiDeductionStartDate"
                    value={formData.emiDeductionStartDate}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="col-6" controlId="instalmentAmount">
                  <Form.Label style={{ color: "#000" }}>
                    Instalment Amount*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Instalment Amount"
                    name="instalmentAmount"
                    value={formData.instalmentAmount}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
              {installments && formData.emiDeductionStartDate && (
  <p>
    This loan will be fully paid off in {installments} instalment(s). The first deduction for this loan will be on {formData.emiDeductionStartDate}.
  </p>
)}

            </div>
            <hr className="mt-0" />
          </>
        )}
        <div className="px-md-4 px-2 pb-5 ">
          <Button type="submit" className=" btn-sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            style={{ background: "#fff", border: "none" }}
            disabled
          ></Button>
          <Link to="/retailer/Loan">
            <Button
              className="btn-light btn-sm mx-2"
              style={{ border: "1px solid" }}
            >
              Cancel
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Recordloanadmin;
