import React, { useState, useRef } from "react";
import { FaSync } from "react-icons/fa";

const SimpleCaptcha = ({ onCaptchaSuccess }) => {
  const [inputValue, setInputValue] = useState("");
  const [generatedCaptcha, setGeneratedCaptcha] = useState(generateCaptcha());
  const [actualCaptcha, setActualCaptcha] = useState(generatedCaptcha);
  const [error, setError] = useState(false);
  const inputRef = useRef(null);

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return captcha;
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setError(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const enteredValue = inputRef.current.value;
    if (enteredValue.toUpperCase() === actualCaptcha.toUpperCase()) {
      onCaptchaSuccess();
    } else {
      setError(true);
      setInputValue("");
      inputRef.current.focus();
    }
  };

  const handleRefresh = () => {
    setInputValue("");
    const newCaptcha = generateCaptcha();
    setGeneratedCaptcha(newCaptcha);
    setActualCaptcha(newCaptcha);
    inputRef.current.focus();
  };

  return (
    <div className="">
      <p>
        {generatedCaptcha} &nbsp;&nbsp;
        <FaSync onClick={handleRefresh} />
      </p>
      <form onSubmit={handleSubmit}>
        <input
          className="form-control"
          type="text"
          value={inputValue}
          onChange={handleChange}
          ref={inputRef}
          placeholder="Enter Captcha"
        />
        {error && (
          <p style={{ color: "red" }}>Incorrect captcha. Please try again.</p>
        )}
      </form>
    </div>
  );
};

export default SimpleCaptcha;
