import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoInformationCircleOutline } from "react-icons/io5";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Admintax = () => {
  const history = useHistory();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [focusedField, setFocusedField] = useState("");
  const [employees, setEmployees] = useState([]);
  const [taxdetails, settaxdetails] = useState([]);
  const { password, username } = billpunchbasicauth;
  const Initialfield = {
    PAN: "",
    TAN: "",
    TDS_Circle_AO_Code: "",
    Tax_Payment_Frequency: "",
    Deductors_Type: "Employee",
    Deductors_Name: "",
    Deductors_Fathers_Name: "",
    Deductors_Designation: "",
  };
  const [taxDetails, setTaxDetails] = useState(Initialfield);
  const [errors, setErrors] = useState(Initialfield);
  const validate = (e) => {
    e.preventDefault();
    console.log(" validate function");
    console.log(taxDetails);
    let errors = {};
    if (taxDetails.PAN.trim() == "") {
      errors.PAN = "*Please enter PAN";
    }
    if (taxDetails.TAN.trim() == "") {
      errors.TAN = "*Please enter PAN";
    }
    if (taxDetails.TDS_Circle_AO_Code.trim() == "") {
      errors.TDS_Circle_AO_Code =
        "*Please enter Area code/ AO type/ Range code/ AO number/";
    }
    if (taxDetails.Deductors_Type.trim() == "") {
      errors.Deductors_Type = "*Please select Deductor type";
    }
    if (taxDetails.Deductors_Name.trim() == "") {
      errors.Deductors_Name = "*Please select Deductor Name ";
    }

    setErrors(errors);
    console.log("Validation Errors:", errors);
    console.log("handle change button 1 ");
    if (Object.keys(errors).length == 0) {
      handleSubmit();
      console.log("handle change button n ");
    }
  };
  const handleFocus = (name) => {
    setFocusedField(name);
  };

  const handleBlur = () => {
    setFocusedField("");
  };
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setTaxDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedValue = value.toUpperCase();

    const regex1 = /^[A-Z]{0,3}$/;
    const regex2 = /^[A-Z]{0,2}$/;
    const regex3 = /^\d{0,3}$/;
    const regex4 = /^\d{0,2}$/;

    switch (name) {
      case "TDS_Circle_AO_Code_1":
        if (regex1.test(updatedValue)) {
          setTaxDetails((prevState) => ({
            ...prevState,
            TDS_Circle_AO_Code: `${updatedValue}/${
              taxDetails.TDS_Circle_AO_Code.split("/")[1] || ""
            }/${taxDetails.TDS_Circle_AO_Code.split("/")[2] || ""}/${
              taxDetails.TDS_Circle_AO_Code.split("/")[3] || ""
            }`,
          }));
        }
        break;
      case "TDS_Circle_AO_Code_2":
        if (regex2.test(updatedValue)) {
          setTaxDetails((prevState) => ({
            ...prevState,
            TDS_Circle_AO_Code: `${
              taxDetails.TDS_Circle_AO_Code.split("/")[0] || ""
            }/${updatedValue}/${
              taxDetails.TDS_Circle_AO_Code.split("/")[2] || ""
            }/${taxDetails.TDS_Circle_AO_Code.split("/")[3] || ""}`,
          }));
        }
        break;
      case "TDS_Circle_AO_Code_3":
        if (regex3.test(updatedValue)) {
          setTaxDetails((prevState) => ({
            ...prevState,
            TDS_Circle_AO_Code: `${
              taxDetails.TDS_Circle_AO_Code.split("/")[0] || ""
            }/${
              taxDetails.TDS_Circle_AO_Code.split("/")[1] || ""
            }/${updatedValue}/${
              taxDetails.TDS_Circle_AO_Code.split("/")[3] || ""
            }`,
          }));
        }
        break;
      case "TDS_Circle_AO_Code_4":
        if (regex4.test(updatedValue)) {
          setTaxDetails((prevState) => ({
            ...prevState,
            TDS_Circle_AO_Code: `${
              taxDetails.TDS_Circle_AO_Code.split("/")[0] || ""
            }/${taxDetails.TDS_Circle_AO_Code.split("/")[1] || ""}/${
              taxDetails.TDS_Circle_AO_Code.split("/")[2] || ""
            }/${updatedValue}`,
          }));
        }
        break;
      default:
        setTaxDetails((prevState) => ({
          ...prevState,
          [name]: updatedValue,
        }));
        break;
    }
  };

  const handleSubmit = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        pan_card: taxDetails.PAN,
        tan_detail: taxDetails.TAN,
        area_code: taxDetails.TDS_Circle_AO_Code.split("/")[0],
        ao_type: taxDetails.TDS_Circle_AO_Code.split("/")[1],
        range_code: taxDetails.TDS_Circle_AO_Code.split("/")[2],
        ao_number: taxDetails.TDS_Circle_AO_Code.split("/")[3],
        pay_frequency: "Monthly",
        deductor_type: taxDetails.Deductors_Type,
        deductor_empid: taxDetails.deductor_empid,
        deductor_name: taxDetails.Deductors_Name,
        deductor_father_name: taxDetails.Deductors_Fathers_Name,
        deductor_designation: taxDetails.Deductors_Designation,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddTaxDetails`,
        requestOptions
      );

      const data = await res.json();

      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        history.push("/retailer/Form16");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const GetFirmEmployee = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setEmployees(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const Gettaxdetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetTaxDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const fetchedDetails = {
          PAN: data.data[1].pan_card,
          TAN: data.data[1].tan_detail,
          TDS_Circle_AO_Code: `${data.data[1].area_code}/${data.data[1].ao_type}/${data.data[1].range_code}/${data.data[1].ao_number}`,
          Tax_Payment_Frequency: "Monthly",
          Deductors_Type: data.data[1].deductor_type,
          Deductors_Name: data.data[1].deductor_name,
          Deductors_Fathers_Name: data.data[1].deductor_father_name,
          Deductors_Designation: data.data[1].deductor_designation,
        };

        setTaxDetails(fetchedDetails);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetFirmEmployee();
    Gettaxdetails();
  }, []);

  const handleDropdownChange = (selectedOption) => {
    setTaxDetails((prevState) => ({
      ...prevState,
      Deductors_Name: selectedOption.label,
      deductor_empid: selectedOption.value,
    }));
  };

  return (
    <>
      <div className="row px-2 mt-3">
        <div className="col-md-1"></div>
        <div className="col-md-7 col-11">
          <h4>Taxes</h4>
          <hr className="" />
          <h6 className="px-2">Organisation Tax Details</h6>
          <Form className=" m-0 p-0 ">
            <div className="row">
              <Form.Group className="col" controlId="PAN">
                <Form.Label style={{ color: "#000" }}>PAN</Form.Label>
                <Form.Control
                  placeholder="XXXXX0000X"
                  type="text"
                  name="PAN"
                  value={taxDetails.PAN}
                  onChange={handleChange}
                  onFocus={() => handleFocus("PAN")}
                  onBlur={handleBlur}
                />
                {focusedField === "PAN" && (
                  <div style={{ fontSize: ".8rem", color: "grey" }}>
                    Format: XXXXX0000X
                  </div>
                )}
                {errors.PAN && (
                  <div className="text-danger" style={{ fontSize: ".7rem" }}>
                    {errors.PAN}
                  </div>
                )}
              </Form.Group>
              <Form.Group className="col" controlId="TAN">
                <Form.Label style={{ color: "#000" }}>TAN</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="XXXXX0000X"
                  name="TAN"
                  value={taxDetails.TAN}
                  onChange={handleChange}
                  onFocus={() => handleFocus("TAN")}
                  onBlur={handleBlur}
                />
                {focusedField === "TAN" && (
                  <div style={{ fontSize: ".8rem", color: "grey" }}>
                    Format: XXXXX0000X
                  </div>
                )}
                {errors.TAN && (
                  <div className="text-danger" style={{ fontSize: ".7rem" }}>
                    {errors.TAN}
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="row pt-md-0 pt-1">
              <Form.Group className="col" controlId="TDS_Circle_AO_Code">
                <Form.Label style={{ color: "#000" }}>
                  TDS Circle / AO Code{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        The number can be obtained from income tax office or you
                        can login intp your income tax account.
                      </Tooltip>
                    }
                  >
                    <IoInformationCircleOutline style={{ fontSize: "1rem" }} />
                  </OverlayTrigger>
                </Form.Label>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      name="TDS_Circle_AO_Code_1"
                      value={taxDetails.TDS_Circle_AO_Code.split("/")[0] || ""}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "TDS_Circle_AO_Code_1",
                            value: e.target.value,
                          },
                        })
                      }
                      onFocus={() => handleFocus("TDS_Circle_AO_Code")}
                      onBlur={handleBlur}
                      placeholder="XXX"
                    />
                  </div>
                  <div className="flex-grow-0 px-2 d-flex align-items-center">
                    /
                  </div>

                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      name="TDS_Circle_AO_Code_2"
                      value={taxDetails.TDS_Circle_AO_Code.split("/")[1] || ""}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "TDS_Circle_AO_Code_2",
                            value: e.target.value,
                          },
                        })
                      }
                      onFocus={() => handleFocus("TDS_Circle_AO_Code")}
                      onBlur={handleBlur}
                      placeholder="XX"
                    />
                  </div>

                  <div className="flex-grow-0 px-2 d-flex align-items-center">
                    /
                  </div>

                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      name="TDS_Circle_AO_Code_3"
                      value={taxDetails.TDS_Circle_AO_Code.split("/")[2] || ""}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "TDS_Circle_AO_Code_3",
                            value: e.target.value,
                          },
                        })
                      }
                      onFocus={() => handleFocus("TDS_Circle_AO_Code")}
                      onBlur={handleBlur}
                      placeholder="000"
                    />
                  </div>

                  <div className="flex-grow-0 px-2 d-flex align-items-center">
                    /
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      name="TDS_Circle_AO_Code_4"
                      value={taxDetails.TDS_Circle_AO_Code.split("/")[3] || ""}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "TDS_Circle_AO_Code_4",
                            value: e.target.value,
                          },
                        })
                      }
                      onFocus={() => handleFocus("TDS_Circle_AO_Code")}
                      onBlur={handleBlur}
                      placeholder="00"
                    />
                  </div>
                </div>
                {focusedField === "TDS_Circle_AO_Code" && (
                  <div style={{ fontSize: ".8rem", color: "grey" }}>
                    Format: area code / ao type / range code / ao number
                  </div>
                )}
                {errors.TDS_Circle_AO_Code && (
                  <div className="text-danger" style={{ fontSize: ".7rem" }}>
                    {errors.TDS_Circle_AO_Code}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="col" controlId="Tax_Payment_Frequency">
                <Form.Label style={{ color: "#000" }}>
                  Tax Payment Frequency{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        The Tax Deducted at Source (TDS) for each month is due
                        for payment to the Income Tax Department on or before
                        the 7th of the following month.
                      </Tooltip>
                    }
                  >
                    <IoInformationCircleOutline style={{ fontSize: "1rem" }} />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Tax_Payment_Frequency"
                  value="Monthly"
                  disabled
                  onChange={handleChange}
                  onFocus={() => handleFocus("Tax_Payment_Frequency")}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </div>
            <h6 className="px-2"> Tax Deductor Details</h6>
            <div className="row">
              <Form.Group className="col m-0">
                <Form.Label style={{ color: "#000" }}>
                  Deductor's Type
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="Employee"
                    name="Deductors_Type"
                    value="Employee"
                    checked={taxDetails.Deductors_Type === "Employee"}
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Non-Employee"
                    name="Deductors_Type"
                    value="Non-Employee"
                    checked={taxDetails.Deductors_Type === "Non-Employee"}
                    onChange={handleRadioChange}
                  />
                </div>
              </Form.Group>
            </div>

            {taxDetails.Deductors_Type === "Employee" ? (
              <>
                <div className="row pt-md-0 pt-1">
                  <Form.Group className="col" controlId="Deductors_Name">
                    <Form.Label style={{ color: "#000" }}>
                      Deductor's Name
                    </Form.Label>
                    <Select
                      onChange={handleDropdownChange}
                      onFocus={() => handleFocus("Deductors_Name")}
                      onBlur={handleBlur}
                      options={employees}
                    />
                    {errors.Deductors_Name && (
                      <div
                        className="text-danger"
                        style={{ fontSize: ".7rem" }}
                      >
                        {errors.Deductors_Name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col"
                    controlId="Deductors_Fathers_Name"
                  >
                    <Form.Label style={{ color: "#000" }}>
                      Deductor's Father's Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Deductors_Fathers_Name"
                      value={taxDetails.Deductors_Fathers_Name}
                      onChange={handleChange}
                      onFocus={() => handleFocus("Deductors_Fathers_Name")}
                      onBlur={handleBlur}
                      disabled
                    />
                  </Form.Group>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <Form.Group className="col" controlId="Deductors_Name">
                    <Form.Label style={{ color: "#000" }}>
                      Deductor's Name
                    </Form.Label>
                    <Select
                      onChange={handleDropdownChange}
                      onFocus={() => handleFocus("Deductors_Name")}
                      onBlur={handleBlur}
                      options={employees}
                    />
                    {errors.Deductors_Name && (
                      <div
                        className="text-danger"
                        style={{ fontSize: ".7rem" }}
                      >
                        {errors.Deductors_Name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="col"
                    controlId="Deductors_Fathers_Name"
                  >
                    <Form.Label>Deductor's Father's Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="Deductors_Fathers_Name"
                      value={taxDetails.Deductors_Fathers_Name}
                      onChange={handleChange}
                      onFocus={() => handleFocus("Deductors_Fathers_Name")}
                      onBlur={handleBlur}
                    />
                    {errors.Deductors_Fathers_Name && (
                      <div
                        className="text-danger"
                        style={{ fontSize: ".7rem" }}
                      >
                        {errors.Deductors_Fathers_Name}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <div className="row pt-md-0 pt-1">
                  <Form.Group
                    className="col-6"
                    controlId="Deductors_Designation"
                  >
                    <Form.Label style={{ color: "#000" }}>
                      Deductor's Designation
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Deductors_Designation"
                      value={taxDetails.Deductors_Designation}
                      onChange={handleChange}
                      onFocus={() => handleFocus("Deductors_Designation")}
                      onBlur={handleBlur}
                    />
                    {errors.Deductors_Designation && (
                      <div
                        className="text-danger"
                        style={{ fontSize: ".7rem" }}
                      >
                        {errors.Deductors_Designation}
                      </div>
                    )}
                  </Form.Group>
                </div>
              </>
            )}
            <hr />
            <Form.Group className="col">
              <Button variant="success" onClick={validate}>
                Save
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Admintax;
