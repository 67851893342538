import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

const IndemnityBond = () => {
    return (
        <Container>

            <Row>
                <div className='card' style={{ width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h3>INDEMNITY BOND</h3>
                    </div>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <h5><strong>Date:-  </strong>18/01/2023 07:13:59 PM</h5>
                        </div>

                        <div className='d-flex mt-2'>
                            <h5><strong>From:-  </strong></h5>
                        </div>

                        <table style={{ border: '1px solid black', width: '100%' }}>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Merchant/Agent name</strong>
                                </td>
                                <td>
                                    JP communication
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>BC Agent Id</strong>
                                </td>
                                <td>
                                    SPR12571
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Registered Mobile number</strong>
                                </td>
                                <td>
                                    9871049676
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>PAN number</strong>
                                </td>
                                <td>
                                    GDJPS5650A
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Request No.</strong>
                                </td>
                                <td>
                                    18012023419638
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Amount</strong>
                                </td>
                                <td>
                                    Rs. 6500
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Amount (In Words)</strong>
                                </td>
                                <td>
                                    Six Thousand Five Hundred Only
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Bank Account </strong>
                                </td>
                                <td>
                                    Punjab National Bank -: 0629002100147530
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Deposit Date</strong>
                                </td>
                                <td>
                                    18/1/2023
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Payment Type</strong>
                                </td>
                                <td>
                                    IMPS Transfer
                                </td>
                            </tr>
                            <tr style={{background:'white'}}>
                                <td>
                                    <strong>Remark </strong>
                                </td>
                                <td>
                                    UTR301803919498
                                </td>
                            </tr>
                        </table>

                        <div className='my-4'>
                            <strong>To,<br></br>
                                The Board of Directors,<br></br>
                                Spay Technology Private Limited<br></br>
                                H-87, Ist Floor Sector 63 Noida,<br></br>
                                Gautam Buddha Nagar,<br></br>
                                Uttar Pradesh-201301<br></br>
                            </strong>
                        </div>

                        <div>
                            <p className='mb-2' style={{ fontSize: '16px' }}>Dear Sir/Madam,</p>

                            <p style={{ fontSize: '16px' }}>I, <strong>JP communication</strong>, as a Merchant/Agent hereby undertake and explicitly agree to indemnify Spay
                                Technology Private Limited towards the following points:
                            </p>

                            <div className='p-4'>
                                <ul style={{ fontSize: '16px' }}>
                                    <li>
                                        Spay Technology Private Limited is providing us with a platform as an enabler through which we
                                        can transfer/receive/top up the money through various methods likeUPI/ IMPS/ RTGS/ Payment
                                        Gateway etc. from one person to another(P2P and P2M) against a separate consideration;
                                    </li>
                                    <li>
                                        I am wholly and solely responsible for the collection of KYC/ meeting the Statutory requirements
                                        and other mandatory documents from the sender or receiver or both and also the reasons of such
                                        transactions;
                                    </li>
                                    <li>
                                        I am responsible and abide to provide the KYC and other mandatory documents and reasons of each
                                        and every transactions with end customers to the spay technology at Spay’s discretion;

                                    </li>
                                    <li>
                                        I am wholly and solely responsible for those transactions which was wrongly debited or credited by
                                        me to another party or any incorrect entry/entries while using the platform;and

                                    </li>
                                    <li>
                                        After obtaining a proper understanding of the transaction patterns of this Company, I am giving my
                                        consent to use this platform with all the terms and conditions as provided by the Spay Technology
                                        and also assuring that every sender or receiver or both only after giving their full consent using this
                                        platform for transfer / receive / topup the money through various methods like CASH / UPI / IMPS
                                        / NEFT / RTGS / Payment Gateway etc.
                                    </li>
                                </ul>
                            </div>

                            <p className='mt-4' style={{ fontSize: '16px' }}>Thanking you,</p>

                            <p className='mt-4' style={{ fontSize: '16px' }}><strong>JP communication<br></br>
                                (Merchant’s/Agent’s Name)<br></br>
                                Seal and Signature<br></br>
                            </strong>
                            </p>

                            <p className='mt-2' style={{ fontSize: '16px' }}>
                                <strong>Timestamp : 18/01/2023 07:13:59 PM</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </Row>

        </Container>
    )
}

export default IndemnityBond