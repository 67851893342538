
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import Select from "react-select"; 

const Salarymanagement = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { accessData } = useContext(GlobalContext);
  const [department, setDepartment] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50); 

  const paginationOptions = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];


  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((item) => {
    if (department === "") {
      return true;
    } else {
      return item.department === department;
    }
  }).filter((item) => {
    const fullName = `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} ${item.department}`.toLowerCase();
    return !searchQuery || fullName.includes(searchQuery.toLowerCase());
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);


  const handleItemsPerPageChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Options for react-select dropdown
  const selectOptions = paginationOptions.map(option => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col-md-6 col-3">
            <h5 className="d-flex align-items-center" style={{ color: "#0074D9" }}>
              Employee List
            </h5>
          </div>
          <div className="col d-flex justify-content-end">
            <input
              className="mr-3"
              style={{
                background: "#fff",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
                paddingLeft: "10px",
                height: "2rem",
              }}
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
           
            {/* Department filter select */}
            <select
              style={{
                width: "9rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
            >
              <option value="">All Department</option>
              {departmentData.map((dept) => (
                <option key={dept.id} value={dept.cat1}>
                  {dept.cat1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="tab-custom-pills-horizontal">
          <div className="card p-3 mt-2" style={{ borderRadius: ".4rem" }}>
            <table className="m-md-2 mb-5 pb-5" style={{ textAlign: "center" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Emp Code</th>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Name</th>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Designation</th>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Department</th>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Mobile</th>
                  <th className="text-center" style={{ color: "#000", background: "#fff", border: "none" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item) => (
                  <tr key={item.id} style={{ borderBottom: "1px solid #ced4da" }}>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>{item.empcode}</td>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>{item.firstname} {item.lastname}</td>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>{item.designation}</td>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>{item.department}</td>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>{item.mobile}</td>
                    <td className="text-center" style={{ border: "none", fontSize: ".8rem", fontWeight: "700" }}>
                      <Link to="/retailer/Salary_data" className="tooltip-container" onClick={() => {
                        sessionStorage.setItem("currentEmpId", item.empid);
                        sessionStorage.setItem("currentempname", `${item.firstname} ${item.lastname}`);
                      }}>
                        <MdOutlineNavigateNext style={{ fontSize: "1rem" }} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="mt-3 mb-3 ">
                <label>Items per page:</label>
                <div style={{ width: "6rem" }}>
                  <Select
                    options={selectOptions}
                    value={{ value: itemsPerPage, label: `${itemsPerPage}` }}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "4rem" }}
                  />
                </div>
              </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Salarymanagement;
