import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import { BASE_URL_DOC_KYC } from "../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import EKYC from "../retailer/Agent EKYC/EKYC";

const BiometricKYC = () => {
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_DOC_KYC}/api/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          history.push("/kyc/user-kyc-view");
        }
        setKycData(data.data[0]);
        // setFormValues({
        //   ...formValues,
        //   PAN: data.data[0].pancardno,
        //   ADHAR: data.data[0].aadharcardno,
        //   GST: data.data[0].gstnumber,
        // });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUserKycData();
  }, []);
  const redirect = () => {
    history.push("/kyc/user-kyc-view");
  };

  const Back = () => {
    history.push("/kyc/nominee-details");
  };
  const [formValues, setFormValues] = useState({
    PAN: "",
    AdharFront: "",
    AdharBack: "",
    GstDoc: "",
    Userimage: "",
    UserSign: "",
    UserImageWithAdhar: "",
    ShopImage: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const MN = sessionStorage.getItem("MN");
  const steps = localStorage.getItem("steps");

  const convertToBiteArray = (file, callback) => {
    const reader = new FileReader();
    const fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        var binary = "";
        var len = array.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(array[i]);
        }
        callback(window.btoa(binary));
      }
    };
  };

  const ImageUpload = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const type = e.target.id;

    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 256000) {
        swal("Invalid", "Image Size should be less than 256 kb", "error");
        return;
      }
      convertToBiteArray(e.target.files[0], (ByteArray) => {
        var raw = JSON.stringify({
          imagename: `${MN}_${type}`,
          imagebytedata: ByteArray,
        });

        var requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
        };

        try {
          fetch(`${BASE_URL_DOC_KYC}/api/uploadimages.aspx`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if (result.status == "0") {
                cogoToast.success(result.msg);
              } else {
                cogoToast.error(result.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Doc();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // if (!values.PAN) {
    //   errors.PAN = "*Pan is required!";
    // }
    // if (!values.AdharBack) {
    //   errors.AdharBack = "*Adhar Front is required";
    // }
    // if (!values.AdharBack) {
    //   errors.AdharBack = "*Adhar Back is required!";
    // }
    // if (values.GstDoc) {
    //   errors.GstDoc = "*Gst image is required";
    // }
    // if (values.Userimage) {
    //   errors.Userimage = "*User image is required";
    // }
    // if (values.UserSign) {
    //   errors.UserSign = "*User Sign is required";
    // }
    return errors;
  };

  const Doc = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      steps: "5",
      fkuserid: userId.pk_user_id,
      imgpancard: `${MN}_POI`,
      imgaadharfront: `${MN}_POA_Front`,
      imgaadharback: `${MN}_POA_Back`,
      gstdoc: `${MN}_gst`,
      imguser: `${MN}_profile`,
      signature: `${MN}_sign`,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(`${BASE_URL_DOC_KYC}/api/submitkycfinal.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            redirect();
            localStorage.setItem("steps", 5);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      <div
        className="card"
        style={{ backgroundColor: "aliceblue", textAlign: "center" }}
      >
        <Button
          onClick={() => {
            history.push("/");
          }}
          style={{ maxWidth: "10rem", margin: "1rem 0rem" }}
        >
          Back To Home{" "}
        </Button>
        <div
          style={{
            backgroundColor: "#0097C5",
            height: "8vh",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        ></div>
        <Stepper activeStep={steps} style={{ padding: "15px" }}>
          <Step label="Business Details" />
          <Step label="Personal Details" />
          {/* <Step label="Document Details" />
          <Step label="Nominee Details" /> */}
          <Step label="Document Upload" />
          <Step label="Biometric KYC" />
        </Stepper>
        <EKYC />
      </div>
    </div>
  );
};

export default BiometricKYC;
