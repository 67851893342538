import React, { useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
const ScheduleDemo = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="d-flex justify-content-center m-md-4 m-2 largee">
        <div
          className="mt-md-5 mt-3 pt-5 fot position-relative service_laptop"
          style={{
            // height: "27rem",
            backgroundImage: `url(${require("../../../assets/images/billpunchback.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "1rem",
          }}
        >
          <div className="row h-100 p-0">
            <div
              className="col-md-4 h-100 col-12 d-flex justify-content-center  mt-md-0 order-md-first order-last"
              style={{ alignItems: "end" }}
            >
              <img
                className="Womenonly"
                alt="Contact support"
                // style={{ height: "22rem", }}
                src={require("../../../assets/images/Womenonly.png")}
              />
            </div>
            <div className="col d-flex justify-content-center mx-md-5 px-md-5 order-first order-md-last ">
              <div className="m-md-5 m-1">
                <h2
                  className="womenhead"
                  style={{
                    color: "#fff",
                  }}
                >
                  Looking for a comprehensive
                  <br />
                  Human Resource Management Solution?
                </h2>
                <Link to="/contact_us">
                  <Button
                    style={{
                      borderRadius: "0",
                      color: isHovered ? "#0B3960" : "#0B3960",
                      background: isHovered ? "rgb(218, 221, 230)" : "#fff",
                      height: "2.5rem",
                      transition: "background 0.3s, color 0.3s",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Schedule a Demo
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>



      
      <div className="m-2">
        <div
          className="mt- h-md-75 h-50 service_phone"
          style={{
            // height: "27rem",
            backgroundImage: `url(${require("../../../assets/images/billpunchback.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "1rem",
          }}
        >
          <div className="row m-0 p-0">
            <div
              className="col-4 mt-md-0 mt-2 d-flex"
              style={{ alignItems: "end" }}
            >
              <img
                className="solutionpic"
                alt="Contact support"
                style={{ height: "18rem" }}
                src={require("../../../assets/images/Womenonly.png")}
              />
            </div>
            <div className="col d-flex align-items-center">
              <div className="m-md-5 mx-3 m-1">
                <p
                  className="womenhead"
                  style={{
                    color: "#fff",
                  }}
                >
                  Looking for a comprehensive
                  <br />
                  Human Resource Management Solution?
                </p>

                <Button
                  style={{
                    borderRadius: "0",
                    color: isHovered ? "#0B3960" : "#0B3960",
                    background: isHovered ? "rgb(218, 221, 230)" : "#fff",
                    height: "2.5rem",
                    transition: "background 0.3s, color 0.3s",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Schedule a call Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleDemo;
