import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form } from "react-bootstrap";
import { Line, Doughnut } from "react-chartjs-2";
import C3Chart from "react-c3js";
import "c3/c3.css";
import Bbps from "../bbps/Bbps";
import DashboardReport from "../dashoard-reports/DashboardReport";
import Transactions from "../dashboard-transactions/Transactions";
import { Redirect } from "react-router";
import swal from "sweetalert";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import RecentActivity from "../recent-activity/RecentActivity";
import FundTransfer from "../../retailer/fund-transfer/FundTransfer";
import DirectFundTransfer from "../direct-fund-transfer/DirectFundTransfer";
import { dateFormatter } from "../../utils/utilFuctions";
import { DROP_SHADOW, MONTHS } from "../../utils/CommonConfig";
import { useHistory } from "react-router";
import {
  AEPSReports,
  BBPSReports,
  DashboardEarnCard,
  DashboardPredictCard,
  DashboardWalletCard,
  DMTReports,
  NotificationBanner,
} from "../../retailer/dashboard/Dashboard";
// Services icon

import AddFunds from "../../../assets/svg/add_fund.svg";
import Pay_Bill from "../../../assets/svg/pay_bill.svg";
import Send_Money from "../../../assets/svg/send_money.svg";
import Upcomming_Transactions from "../../../assets/svg/upcomming_transactions.svg";

import WaterBill_2 from "../../../assets/svg/water_bill_2.svg";
import PipedGas_2 from "../../../assets/svg/piped_gas_2.svg";
import Broadband_2 from "../../../assets/svg/broadband_2.svg";
import BusTicketIcon_2 from "../../../assets/svg/bus_ticket_2.svg";
import EducationIcon_2 from "../../../assets/svg/education_2.svg";
import FastagIcon_2 from "../../../assets/svg/fastag_2.svg";
import FlightTicketIcon_2 from "../../../assets/svg/flight_ticket_2.svg";
import GasCylinderIcon_2 from "../../../assets/svg/gas_cylinder_2.svg";
import HospitalIcon_2 from "../../../assets/svg/hospital_2.svg";
import HotelIcon_2 from "../../../assets/svg/hotel_2.svg";
import HousingSocietyIcon_2 from "../../../assets/svg/housing_society_2.svg";
import InsuranceIcon_2 from "../../../assets/svg/insurance_2.svg";
import LandlineIcon_2 from "../../../assets/svg/landline_2.svg";
import LoanIcon_2 from "../../../assets/svg/load_repayment_2.svg";
import MuncipalTaxIcon_2 from "../../../assets/svg/muncipal_tax_2.svg";
import TrainTicketIcon_2 from "../../../assets/svg/train_ticket_2.svg";
import Cable from "../../../assets/svg/cable.svg";
import Electricity from "../../../assets/svg/electricity.svg";
import Dth from "../../../assets/svg/dth.svg";
import PrepaidRecharge from "../../../assets/svg/prepaid_recharge.svg";
import WaterBill from "../../../assets/svg/water_bill.svg";
import PipedGas from "../../../assets/svg/piped_gas.svg";
import Broadband from "../../../assets/svg/broadband.svg";
import BusTicketIcon from "../../../assets/svg/bus_ticket.svg";
import EducationIcon from "../../../assets/svg/education.svg";
import FastagIcon from "../../../assets/svg/fastag.svg";
import FlightTicketIcon from "../../../assets/svg/flight_ticket.svg";
import GasCylinderIcon from "../../../assets/svg/gas_cylinder.svg";
import HospitalIcon from "../../../assets/svg/hospital.svg";
import HotelIcon from "../../../assets/svg/hotel.svg";
import HousingSocietyIcon from "../../../assets/svg/housing_society.svg";
import InsuranceIcon from "../../../assets/svg/insurance.svg";
import LandlineIcon from "../../../assets/svg/landline.svg";
import LoanIcon from "../../../assets/svg/load_repayment.svg";
import MuncipalTaxIcon from "../../../assets/svg/muncipal_tax.svg";
import TrainTicketIcon from "../../../assets/svg/train_ticket.svg";

export const dropShadow = DROP_SHADOW;

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

export const Dashboard2 = () => {
  const history = useHistory();
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;
  const [lineChartData, setLineChartData] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
    dmtTotal: 0,
    aepsTotal: 0,
    bbpsTotal: 0,
    settlementTotal: 0,
  });
  const [lineChartData2, setLineChartData2] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
  });
  const [filterState, setFilterState] = useState(0);
  const [filterState2, setFilterState2] = useState("1");
  const [notifications, setNotifications] = useState([]);
  const { token, myAccountInfo, user } = useContext(GlobalContext);

  const getLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        const { aeps, dmt, bbps, settlement } = data;
        let dmtTotal = 0,
          aepsTotal = 0,
          bbpsTotal = 0,
          settlementTotal = 0;
        dmt.forEach((tranx) => {
          dmtTotal += Number(tranx.amount);
        });
        aeps.forEach((tranx) => {
          aepsTotal += Number(tranx.amount);
        });
        bbps.forEach((tranx) => {
          bbpsTotal += Number(tranx.amount);
        });
        settlement.forEach((tranx) => {
          settlementTotal += Number(tranx.amount);
        });

        setLineChartData({
          aeps,
          bbps,
          dmt,
          settlement,
          dmtTotal,
          aepsTotal,
          bbpsTotal,
          settlementTotal,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getHourlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        date: dateFormatter(new Date()),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_hourly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDailyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getWeeklyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_weekly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMonthlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_monthly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserNotifications = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_user_notifications.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setNotifications(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFilterLabel = () => {
    if (filterState == 0) {
      return "Daily";
    } else if (filterState == 1) {
      return "Weekly";
    } else if (filterState == 2) {
      return "Monthly";
    } else if (filterState == 3) {
      return "Annual";
    }
    return "";
  };

  useEffect(() => {
    if (filterState == 0) {
      getHourlyLineChartData();
    } else if (filterState == 1) {
      getDailyLineChartData();
    } else if (filterState == 2) {
      getWeeklyLineChartData();
    } else if (filterState == 3) {
      getMonthlyLineChartData();
    }
  }, [filterState]);

  useEffect(() => {
    getLineChartData();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div className="bg-white-theme p-0">
            <div className="row m-0 p-2">
              {notifications.map((notification) => (
                <NotificationBanner notification={notification} />
              ))}
            </div>

            <div className="row m-0">
              <QuickAccess />
            </div>

            {/* <ServicesNavbar /> */}

            <div className="row m-0">
              <DashboardWalletCard />
              <DashboardEarnCard />
              <DashboardPredictCard />
              {/* <DashboardProsperCard /> */}
            </div>

            {lineChartData.dmt.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"DMT"}
                  amount={lineChartData.dmtTotal}
                  data={lineChartData.dmt}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <DMTReports />
                </div>
              </div>
            )}

            {lineChartData.aeps.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"AEPS"}
                  amount={lineChartData.aepsTotal}
                  data={lineChartData.aeps}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <AEPSReports />
                </div>
              </div>
            )}
            {lineChartData.bbps.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"BBPS"}
                  amount={lineChartData.bbpsTotal}
                  data={lineChartData.bbps}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <BBPSReports />
                </div>
              </div>
            )}

            <div
              className="m-1 mx-4 p-1"
              style={{
                boxShadow: dropShadow,
                borderRadius: "1rem",
                background: "#FFF",
              }}
            >
              <div className="col-12 d-flex align-items-center">
                <div className="col-12 col-md-4 d-flex flex-column p-0">
                  <strong>{getFilterLabel()}</strong>
                  {/* <strong>₹ 0 (204.17%)</strong>
                  <span> Compared to 01 Feb 2023 - 28 Feb 2023</span> */}
                </div>
                <div className="col-12 col-md-8 p-0 d-flex">
                  <div className="col-12">
                    <div className="d-flex justify-content-end my-2">
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Basic outlined"
                      >
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 0
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(0);
                          }}
                        >
                          Daily
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 1
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(1);
                          }}
                        >
                          Weekly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 2
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(2);
                          }}
                        >
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 3
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(3);
                          }}
                        >
                          Annual
                        </button>
                      </div>
                      <div
                        className="rounded-5 px-2 py-0 mx-1"
                        style={{
                          border: "1px solid #DDDDDD",
                          borderRadius: "5px",
                        }}
                      >
                        <i className="mdi mdi-menu text-primary"></i>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            padding: "6px",
                            background: "transparent",
                            fontSize: ".8rem",
                          }}
                          onChange={(e) => {
                            setFilterState2(e.target.value);
                          }}
                        >
                          <option value={"1"}>By Total Transactions</option>
                          <option value={"2"}>By Total Volume</option>
                        </select>
                      </div>

                      {/* <div
                        className="rounded-2 px-2 py-1 ml-1 align-items-center"
                        style={{
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <i className="mdi mdi-download text-primary"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <LineChart
                aeps={lineChartData2.aeps}
                dmt={lineChartData2.dmt}
                bbps={lineChartData2.bbps}
                settlement={lineChartData2.settlement}
                filter={filterState2}
              />
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export const Dashboard = () => {
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;

  const [moneyTransferState, setMoneyTransferState] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "IMPS",
    emailid: "",
  });
  const [moneyTransferErrors, setMoneyTransferErrors] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "",
    emailid: "",
  });
  const [lineChartData, setLineChartData] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
    dmtTotal: 0,
    aepsTotal: 0,
    bbpsTotal: 0,
    settlementTotal: 0,
  });
  const [lineChartData2, setLineChartData2] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
  });
  const [filterState, setFilterState] = useState(0);
  const [filterState2, setFilterState2] = useState("1");
  const { token, myAccountInfo, user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [dmtTransactions, setDmtTransactions] = useState([]);
  const [AEPSTransactions, setAEPSTransactions] = useState([]);
  const [bbpsTransactions, setBBPSTransactions] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  const Last5DMT = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setDmtTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const Last5AEPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_aeps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setAEPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const Last5BBPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_bbps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setBBPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const fundDirectPay = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0wWBysqFJUXY9_KyZSIo52m1Go_ebppTSv50dwkDW_h0k8Vd0zzB5ioe8_IW3RvEeH3WWnDgJfmr5sMuH9V1TZjIsAIZYHz7XbTqmUKZiANcX9if2m6MzKI2earJBZwv_jPtUa1VxDrMC_mK15vCpoAHjcEFc4nGyEAWLeDf2k35z8Lr7ScmnunwYQE29h3lNr_MIx5fhyeiCpSNo4gb9AAfPU2TMlvyj-XL6TBgcbYNNiwKG8pFV_dpgRCIKVA6VNLMejucMHf9mzv6EKDxU6PeoPMF11SBNt60lnv78l53-U0bt0vafsHp-o4niHqo1VVxvabLPQwgwNGg2AekETdQ1qtSppaygelVbKh3NjuuR_k2YMzqtqEkkrjSpAtE60UnVF7iOuIByzFm5gVyhDCODlGmyWfAi8XX_7QISCEpnv_PmoXcYr_DfgOBKxk4Czl4FFJMgtRo-mHcXWpc62pj2r45O4L3uFkQGB6-0SAB5Xm70NjY1vKC-LcxsztN6IicbtvffH1kZZWCp1f0jfsY5bcHMi21EkOr_lFhg4w9Z6mzXfMbWriUQs_uNafIn4sXvx0n2u5Ov3emee9Xm1FcULyyv5vwJ7iGkRHvjMBlEDw1p7iF1L_LE-mlrtiv2acPkuPHQViXnaKaXogkrO"
    );

    const {
      amount,
      bankaccount,
      bankifsccode,
      customerMob,
      customername,
      emailid,
      transactionType,
    } = moneyTransferState;

    var raw = JSON.stringify({
      refrenceid: "string",
      customername: customername,
      customerMob: user.Mobile,
      amount: amount,
      bankaccount: bankaccount,
      bankifsccode: bankifsccode,
      transactionType: transactionType,
      emailid: emailid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Payments/FundInstanttransfer`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMsg ? data.responseMsg : "", "success");
      } else {
        swal(
          "Transaction Failed",
          data.responseMsg ? data.responseMsg : "",
          "error"
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validate = () => {
    let check = true;
    let errors = {};
    if (!moneyTransferState.customername) {
      errors.customername = "Customer number is mandatory";
    }
    if (!moneyTransferState.bankaccount) {
      errors.bankaccount = "Account number is mandatory";
    }
    if (!moneyTransferState.bankifsccode) {
      errors.bankifsccode = "Bank IFSC Code is mandatory";
    }
    if (!moneyTransferState.amount) {
      errors.amount = "Amount is mandatory";
    }

    setMoneyTransferErrors(errors);
    if (Object.keys(errors).length == 0) {
      fundDirectPay();
    }
  };

  const moneyTransfer = () => {
    validate();
  };

  const getLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        const { aeps, dmt, bbps, settlement } = data;
        let dmtTotal = 0,
          aepsTotal = 0,
          bbpsTotal = 0,
          settlementTotal = 0;
        dmt.forEach((tranx) => {
          dmtTotal += Number(tranx.amount);
        });
        aeps.forEach((tranx) => {
          aepsTotal += Number(tranx.amount);
        });
        bbps.forEach((tranx) => {
          bbpsTotal += Number(tranx.amount);
        });
        settlement.forEach((tranx) => {
          settlementTotal += Number(tranx.amount);
        });

        setLineChartData({
          aeps,
          bbps,
          dmt,
          settlement,
          dmtTotal,
          aepsTotal,
          bbpsTotal,
          settlementTotal,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getHourlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        date: dateFormatter(new Date()),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_hourly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDailyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getWeeklyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_weekly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMonthlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_monthly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFilterLabel = () => {
    if (filterState == 0) {
      return "Daily";
    } else if (filterState == 1) {
      return "Weekly";
    } else if (filterState == 2) {
      return "Monthly";
    } else if (filterState == 3) {
      return "Annual";
    }
    return "";
  };

  useEffect(() => {
    if (filterState == 0) {
      getHourlyLineChartData();
    } else if (filterState == 1) {
      getDailyLineChartData();
    } else if (filterState == 2) {
      getWeeklyLineChartData();
    } else if (filterState == 3) {
      getMonthlyLineChartData();
    }
  }, [filterState]);

  useEffect(() => {
    getLineChartData();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div className="bg-white-theme">
            <DirectFundTransfer />
            <div className="row">
              <DmtChart
                title={"DMT"}
                amount={lineChartData.dmtTotal}
                data={lineChartData.dmt}
              />
              <DmtChart
                title={"AEPS"}
                amount={lineChartData.aepsTotal}
                data={lineChartData.aeps}
              />
              <DmtChart
                title={"BBPS"}
                amount={lineChartData.bbpsTotal}
                data={lineChartData.bbps}
              />
            </div>

            <div
              className="m-1 p-1"
              style={{
                boxShadow: dropShadow,
                borderRadius: "1rem",
                background: "#FFF",
              }}
            >
              <div className="col-12 d-flex align-items-center">
                <div className="col-12 col-md-4 d-flex flex-column p-0">
                  <strong>{getFilterLabel()}</strong>
                  {/* <strong>₹ 0 (204.17%)</strong>
                  <span> Compared to 01 Feb 2023 - 28 Feb 2023</span> */}
                </div>
                <div className="col-12 col-md-8 p-0 d-flex">
                  <div className="col-12">
                    <div className="d-flex justify-content-end my-2">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic outlined"
                      >
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 0
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => {
                            setFilterState(0);
                          }}
                        >
                          Daily
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 1
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => {
                            setFilterState(1);
                          }}
                        >
                          Weekly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 2
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => {
                            setFilterState(2);
                          }}
                        >
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 3
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => {
                            setFilterState(3);
                          }}
                        >
                          Annual
                        </button>
                      </div>
                      <div
                        className="rounded-2 px-2 py-0 mx-1"
                        style={{
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <i className="mdi mdi-menu text-primary"></i>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            padding: "6px",
                            background: "transparent",
                            fontSize: "0.8rem",
                          }}
                          onChange={(e) => {
                            setFilterState2(e.target.value);
                          }}
                        >
                          <option value={"1"}>By Total Transactions</option>
                          <option value={"2"}>By Total Volume</option>
                        </select>
                      </div>

                      {/* <div
                        className="rounded-2 px-2 py-1 ml-1 align-items-center"
                        style={{
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <i className="mdi mdi-download text-primary"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <LineChart
                aeps={lineChartData2.aeps}
                dmt={lineChartData2.dmt}
                bbps={lineChartData2.bbps}
                settlement={lineChartData2.settlement}
                filter={filterState2}
              />
            </div>
            {/* 
            <div
              className="row m-2 rounded-2"
              style={{ boxShadow: dropShadow, background: "#FFF" }}
            >
              <div className="col-12 col-md-6">
                <div className="card border-0" style={{ background: "#FFF" }}>
                  <h6>Traffic split on platforms</h6>
                  <DoughnutChart />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card border-0" style={{ background: "#FFF" }}>
                  <h5>Recent Activity</h5>
                  <RecentActivity />
                </div>
              </div>
            </div>
            <Transactions /> */}
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export const DmtChart = ({
  title,
  amount,
  data: dataX,
  dailyCount,
  dailyAmount,
}) => {
  const [label, setLabel] = useState([]);
  const [dataToPlot, setDataToPlot] = useState([]);
  const [filter1, setFilter1] = useState(2);
  const data = {
    labels: label,
    datasets: [
      {
        label: "Transactions",
        data: dataToPlot,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            color: "rgba(204, 204, 204,0.1)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(204, 204, 204,0.1)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const dateFormatter = (date) => {
    const d = new Date(date);
    console.log(d);
    return `${d.getDate()} ${MONTHS[d.getMonth()]}`;
  };
  useEffect(() => {
    if (filter1 == 1) {
      const ll = dataX.map((e) => dateFormatter(e.Date));
      const dd = dataX.map((e) => e.count);
      setLabel(ll.reverse());
      setDataToPlot(dd.reverse());
    } else {
      const ll = dataX.map((e) => dateFormatter(e.Date));
      const dd = dataX.map((e) => e.amount);
      setLabel(ll.reverse());
      setDataToPlot(dd.reverse());
    }
  }, [filter1, dataX]);

  console.table({ title, dataX });

  return (
    <div
      style={{ borderRadius: "1rem" }}
      className="col-md-4 col-sm-6 col-12 border"
    >
      <div className="p-2 m-0">
        <div className="row m-0 p-0 w-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-4 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1.2rem", fontWeight: "800" }}
              >
                {title}
              </span>
            </div>
            <div className="col-8 px-2 d-flex justify-content-end">
              <select
                className="border-4"
                style={{
                  border: "1px solid #EEEEEE",
                  outline: "none",
                  padding: "6px",
                  background: "transparent",
                  fontSize: "0.8rem",
                }}
                value={filter1}
                onChange={(e) => {
                  setFilter1(e.target.value);
                }}
              >
                <option value={1}>By Total Transactions</option>
                <option value={2}>By Total Volume</option>
              </select>
            </div>
          </div>

          <Line data={data} options={options} />

          <div className="row col-12 m-0 p-0">
            <div className="col-12 p-0">
              <div className="col-12 m-0 p-0">
                {" "}
                Daily Txn Amount{" "}
                <span className="text-success">
                  ₹ {dailyAmount ? dailyAmount : "0"}
                </span>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="col-12 m-0 p-0">
                {" "}
                Monthly Txn Amount{" "}
                <span className="text-success">₹ {amount ? amount : "0"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LineChart = ({ dmt, aeps, bbps, settlement, filter }) => {
  const [data, setData] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
  });
  const lineData = {
    columns: [
      ["DMT", ...data.dmt],
      ["AePS", ...data.aeps],
      ["Bbps", ...data.bbps],
      ["Settlement", ...data.settlement],
    ],
  };
  useEffect(() => {
    if (filter && filter == 2) {
      let dd = dmt?.map((e) => e.amount);
      let aa = aeps?.map((e) => e.amount);
      let bb = bbps?.map((e) => e.amount);
      let ss = settlement?.map((e) => e.amount);
      setData({ dmt: dd, aeps: aa, bbps: bb, settlement: ss });
    } else {
      let dd = dmt?.map((e) => e.count);
      let aa = aeps?.map((e) => e.count);
      let bb = bbps?.map((e) => e.count);
      let ss = settlement?.map((e) => e.count);
      setData({ dmt: dd, aeps: aa, bbps: bb, settlement: ss });
    }
  }, [dmt, aeps, bbps, filter]);
  console.log(filter);
  return (
    <div className="col grid-margin stretch-card">
      <div className="card ">
        <div className="card-body p-0">
          <C3Chart data={lineData} />
        </div>
      </div>
    </div>
  );
};

const QuickAccess = ({ discription }) => {
  let basicAuth = JSON.parse(sessionStorage.getItem("basic_auth"));
  const [formErrors, setFormErrors] = useState({});
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [Tpin, setTpin] = useState("");
  const [serviceSelected, setServiceSelected] = useState(5);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const {
    token,
    myAccountInfo,
    user,
    setTransactionLoaderState,
    checkWalletBeforeBBPSBillPay,
    Profileinfo,
  } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  if (!basicAuth) {
    basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  }
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  // get all operators

  useEffect(() => {}, [serviceSelected]);
  console.log(state.provider);
  return (
    <div className="bg-white-theme w-100">
      <div
        style={{
          background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
          borderRadius: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="m-3 rouded-3 p-4"
      >
        <div className="row">
          <h3 className="text-dark">Quick Access</h3>
        </div>
        <div
          className="row rouded-3 justify-content-start m-0 rounded-3"
          style={{ background: "#EAF0F7" }}
        >
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(1);
            }}
            redirect={"/user/topup_request"}
            image={AddFunds}
            title={"Add Funds"}
            selected={serviceSelected == 1}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(16);
            }}
            redirect={"/user/bbps"}
            image={Pay_Bill}
            title={"Pay Bills"}
            selected={serviceSelected == 2}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(3);
            }}
            redirect={"/user/money-transfer"}
            image={Send_Money}
            title={"Send Money"}
            selected={serviceSelected == 2}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(3);
            }}
            image={Upcomming_Transactions}
            title={"Upcomming Transaction"}
            selected={serviceSelected == 3}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(4);
            }}
            image={Electricity}
            title={"Electricity Bill"}
            selected={serviceSelected == 4}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(5);
            }}
            image={PrepaidRecharge}
            title={"Prepaid Recharge"}
            selected={serviceSelected == 5}
          />
        </div>
      </div>
    </div>
  );
};

const BBPSIconCard = ({ image, title, selected, onClick, redirect }) => {
  const history = useHistory();

  const redirectTo = () => {
    if (redirect) history.push(redirect);
  };
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
      onClick={() => {
        redirectTo();
        onClick();
      }}
    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
         
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

const BBPSServiceCard = ({ image, title }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
    >
      <div
        style={{
          borderRadius: "3rem",
          background: "#C42F47",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{
            height: "2rem",
            width: "2rem",
            color: "#EC5665",
            fill: "#FFF",
            stroke: "#FFF",
          }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default Dashboard2;
