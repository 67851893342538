import React, { useContext, useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";

const Appidsecret = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const location = useLocation();
  const { firmId, empId, queryId, user_phone } = location.state || {};
  const [keyId, setKeyId] = useState("");
  const [keySecret, setKeySecret] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showKeySecret, setShowKeySecret] = useState(false); 

  const handleGenerateKeys = async () => {
    setTransactionLoaderState(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: firmId,
        empid: empId,
        mobile: user_phone || "",
        query_id: queryId,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GenerateKeys`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal({
          text: data.result,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willConfirm) => {
          if (willConfirm) {
            setShowForm(true);
          }
        });
      } else {
        setShowForm(true);
        // swal({
        //   text: data.result,
        //   icon: "error",
        //   buttons: {
        //     confirm: {
        //       text: "OK",
        //       value: true,
        //       visible: true,
        //       className: "",
        //       closeModal: true,
        //     },
        //   },
        // });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleLogin = () => {
    console.log("Logging in...");
  };

  const Getkey = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: firmId,
        empid: empId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetGeneratedKeys`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const { keyid, keysecret } = data.data[0];
        setKeyId(keyid);
        setKeySecret(keysecret);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkey();
  }, []);

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,Key ID,Key Secret\n${keyId},${keySecret}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "key_id_and_key_secret.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const login = () => {
    const callbackUrl = `${window.location.origin}/identity`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
    window.location.href = redirectUrl; 
  };
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div
        className="card p-3 col-md-4 col-10"
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", height: "63%" }}
      >
        <div style={{ width: "100%", textAlign: "center", paddingTop: "20px" }}>
          <h3 className="" style={{ color: "#000" }}>
            Key ID & Key Secret
          </h3>
        </div>
        <div className="d-flex justify-content-center mt-md-3 mt-4">
          <div>
            <div className="d-flex justify-content-center">
              <img
                alt="Billpunch"
                src={require("../../../assets/images/logoblack.png")}
                style={{ height: "2rem" }}
              />
            </div>
            {!showForm && (
              <>
                <p className="text-center mt-3" style={{ fontSize: "1.1rem" }}>
                  To proceed further, please generate your Key ID and Key
                  Secret:
                </p>
                <div className="mt-3 d-flex justify-content-center">
                  <img
                    alt="Billpunch"
                    src={require("../../../assets/images/appid.gif")}
                  />
                </div>
                <div className="mt-3 d-flex justify-content-center">
                  <Button className="btn-sm" onClick={handleGenerateKeys}>
                    Generate Your Key
                  </Button>
                </div>
              </>
            )}

            {showForm && (
              <Form className="mt-4">
                <Form.Group controlId="formKeyId">
                  <Form.Label>Key ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Key ID"
                    value={keyId}
                    onChange={(e) => setKeyId(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formKeySecret">
                  <Form.Label>Key Secret</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showKeySecret ? "text" : "password"}
                      placeholder="Enter Key Secret"
                      value={keySecret}
                      onChange={(e) => setKeySecret(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowKeySecret(!showKeySecret)}
                    >
                      {showKeySecret ? "Hide" : "Show"}
                    </Button>
                  </InputGroup>
                  <Link
                  variant="secondary"
                  onClick={downloadCSV}
                  className="mt-2"
                >
                  Download ID & Secret
                </Link>
                </Form.Group>
               
            <Button  onClick={login} variant="primary" >
                  Login
                </Button>

               
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appidsecret;
