import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

const LoanUI = () => {
  const [Basic, setBasic] = useState(0);
  const [Age, setAge] = useState(18);
  const [Months, setMonths] = useState(30);
  const [Interest, setInterest] = useState(0);
  const [Contribution, setContribution] = useState(0);
  const [epfContribution, setEpfContribution] = useState(0);
  const history = useHistory();

  const redirect = () => {
    history.push("/retailer/loanenquiry");
  };

  const doughnutPieData = {
    datasets: [
      {
        data: [Basic, epfContribution], 
        backgroundColor: ["#3C79F5", "#FF8085"],
      },
    ],
    labels: ["Basic", "EPF Contribution"], 
  };

  const doughnutPieOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const InterestAmount = () => {
    let contribution = 0;
    if (Age >= 18 && Age <= 58) {
      contribution = Basic * (Contribution / 100);
    }

    setEpfContribution(contribution);
    const basicSalary = Basic - contribution;
    const interest = basicSalary * (Age / 100);
    const decint = interest.toFixed(2);
    setInterest(decint);
  };

  useEffect(() => {
    InterestAmount();
  }, [Age, Basic, Contribution]);

  return (
    <Container style={{ backgroundColor: "#FFFFFF55" }}>
      <div
        className="row"
        style={{
          borderRadius: "10px",
          border: "none",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Col md={6} lg={6} sm={12} className="mt-4">
          <strong>EPF Calculator</strong>

          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange1">
              <strong>Monthly Salary (basic):</strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong> ₹ {Basic} </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={5000}
              max={75000}
              defaultValue={Basic}
              onChange={(e) => setBasic(e.target.value)}
              class="form-range"
              id="customRange1"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>5k</small>
            </span>
            <span>
              <small>75k</small>
            </span>
          </div>

          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange2">
              <strong>Your Age:</strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong>{Age} </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={18}
              max={58}
              defaultValue={Age}
              onChange={(e) => setAge(e.target.value)}
              class="form-range"
              id="customRange2"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>18</small>
            </span>
            <span>
              <small>58</small>
            </span>
          </div>
          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange3">
              <strong>Your Contribution to EPF:</strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong>{Contribution}% </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={0}
              max={20}
              defaultValue={Contribution}
              onChange={(e) => setContribution(e.target.value)}
              class="form-range"
              id="customRange3"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>0%</small>
            </span>
            <span>
              <small>20%</small>
            </span>
          </div>
        </Col>

        <Col md={6} lg={6} sm={12} className="mt-4">
          <div
            className="card"
            style={{
              border: "none",
              backgroundColor: "whitesmoke",
              borderRadius: "20px",
            }}
          >
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div>
                    <div>
                      <small>EPF</small>
                    </div>
                    <h3>
                      <strong>₹ {Number(epfContribution).toFixed(0)}</strong>
                    </h3>
                  </div>
                </div>
                <div>
                  <Doughnut
                    data={doughnutPieData}
                    options={doughnutPieOptions}
                  />
                </div>
              </div>

              <div className="mt-3">
                <p>
                  <small>
                    This is an indicative tool to help calculate EPF Amount.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default LoanUI;
