import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import swal from "sweetalert";
import { Addholidaymodal } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import { IoIosRemoveCircle } from "react-icons/io";
const EmployeeLeave = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Add Leave
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab2")}
            >
              Add WFH
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab3" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab3")}
            >
              Review WFH
            </button>
          </li>
        </ul>
        <div className="tab-content ">
          {activeTab === "tab1" && <Leave />}
          {activeTab === "tab2" && <Addwfh />}
          {activeTab === "tab3" && <WFHWORk />}
        </div>
      </div>
    </>
  );
};

const Leave = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [formData, setFormData] = useState({
    category: "",
    fromDate: "",
    toDate: "",
    discription: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!formData.category) formErrors.category = "Category is required.";
    if (!formData.fromDate) formErrors.fromDate = " Date is required.";
    if (!formData.toDate) formErrors.toDate = "To date is required.";

    if (!formData.discription)
      formErrors.discription = "Description is required.";
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      handleSubmit();
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };

  const { password, username } = billpunchbasicauth;
  const getHolidayReport = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeLeaveV2`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const fromDateMonth = new Date(formData.fromDate).getMonth() + 1;

      var raw = JSON.stringify({
        empid: user.userid,
        name:user.username,
        category: formData.category,
        fromdate: formData.fromDate,
        todate: formData.toDate,
        discription: formData.discription,
        status: "P",
        firmid: user.firm_id,
        month: fromDateMonth.toString(),
      });

      console.log("Request sending:", Employeeid + "kol");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddLeaveEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        closeModal();
        getHolidayReport();
      } else {
        swal("Success", data.msg, "success");
        closeModal();
        getHolidayReport();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getHolidayReport();
  }, [Employeeid]);
  const resetForm = () => {
    setFormData({
      category: "",
      fromDate: "",
      toDate: "",
      discription: "",
    });
    setErrors({});
  };

  return (
    <>
      <div className="">
        <Addholidaymodal
          showModal={showModal}
          closeModal={closeModal}
          formData={formData}
          handleFormChange={handleFormChange}
          handleSubmit={validateForm}
          setFormData={setFormData}
          errors={errors}
        />
        <div className="row ">
          <div className="col d-flex align-items-center">
            <h5 className="">Add Leave </h5>
          </div>

          <div className="justify-content-end">
            <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-sm d-flex justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openModal}
              >
                Add Leave
              </button>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "#0074D9" }}>Category</th>
                <th style={{ color: "#0074D9" }}>Reason</th>
                <th style={{ color: "#0074D9" }}>From</th>
                <th style={{ color: "#0074D9" }}> toDate </th>
                <th style={{ color: "#0074D9" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {data1.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                data1.map((item) => (
                  <tr key={item.empid}>
                    <td>{item.leave_category}</td>
                    <td>{item.leave_description}</td>
                    <td>{new Date(item.form_date).toLocaleDateString()}</td>
                    <td>{new Date(item.to_date).toLocaleDateString()}</td>
                    <td>
                      {item.status === "R" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Rejected
                        </span>
                      )}
                      {item.status === "P" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: " #f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "A" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Approved
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const Addwfh = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [formData, setFormData] = useState({
    Reportingperson: "",
    fromDate: "",
    discription: "",
    tasks: [{ task: "" }],
  });
  const [errors, setErrors] = useState({});

  const validateForm = (e) => {
    e.preventDefault();
    let formErrors = {};
    if (!formData.fromDate) formErrors.fromDate = " Date is required.";
    if (!formData.Reportingperson)
      formErrors.Reportingperson = "Select Reporting Person";

    if (!formData.discription)
      formErrors.discription = "Description is required.";
    formData.tasks.forEach((task, index) => {
      if (!task.task) formErrors[`task-${index}`] = "Task is required.";
    });
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      handleSubmit();
    }
  };
  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    resetForm();
  };
  const resetForm = () => {
    setFormData({
      fromDate: "",
      Reportingperson: "",
      discription: "",
      tasks: [{ task: "" }],
    });
    setErrors({});
  };
  const { password, username } = billpunchbasicauth;
  const getwfh = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
        reporting_id: "string",
        type: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWfhV1`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleTaskChange = (index, e) => {
    const newTasks = [...formData.tasks];
    newTasks[index][e.target.name] = e.target.value;
    setFormData({ ...formData, tasks: newTasks });
  };

  const addTask = () => {
    setFormData({ ...formData, tasks: [...formData.tasks, { task: "" }] });
  };

  const removeTask = (index) => {
    const newTasks = formData.tasks.filter((_, idx) => idx !== index);
    setFormData({ ...formData, tasks: newTasks });
  };
  const getEmployees = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(
          data.data.map((employee) => ({
            value: employee.empid,
            label: `${employee.firstname} ${employee.lastname}`,
          }))
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleSubmit = async () => {
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
  
      const tasksPayload = formData.tasks.map((task) => ({ task: task.task }));
  
      const requestsPayload = tasksPayload.map((taskPayload) => ({
        firmid: user.firm_id,
        empid: user.userid,
        name:user.username,
        date: formData.fromDate,
        reason: formData.discription,
        reporting_id: formData.reporting_id,
        reporting_name: formData.reporting_name,
        ...taskPayload, 
      }));
      var raw = JSON.stringify({
        requests: requestsPayload,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      console.log("sending data to API:", raw);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddWfhV1`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        closeModal();
        getwfh();
      } else {
        swal("Success", data.result, "success");
        closeModal();
        getwfh();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  
  const handleLogin = async (item) => {
    try {
      setTransactionLoaderState(true);
      const { username, password } = billpunchbasicauth;
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const raw = JSON.stringify({
            home_id: item.pk_id,
            firmid: user.firm_id,
            empid: item.empid,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            imageurl: "string",
            imagename: "string",
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/Wfhv1Login`,
            requestOptions
          );
          const data = await response.json();
          if (data.status === "0") {
            swal("Success", data.result, "success");
            getwfh();
          } else {
            swal("Error", data.msg, "error");
          }
          setTransactionLoaderState(false);
        },
        (error) => {
          console.error("Error fetching geolocation:", error);
          setTransactionLoaderState(false);
        }
      );
    } catch (error) {
      console.error("Error logging in:", error);
      setTransactionLoaderState(false);
    }
  };
  const handleLogout = async (item) => {
    try {
      setTransactionLoaderState(true);
      const { username, password } = billpunchbasicauth;
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const raw = JSON.stringify({
            home_id: item.pk_id,
            firmid: user.firm_id,
            empid: item.empid,
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/Wfhv1logout`,
            requestOptions
          );
          const data = await response.json();
          if (data.status === "0") {
            swal("Success", data.result, "success");
            getwfh();
          } else {
            swal("Error", data.result, "error");
          }
          setTransactionLoaderState(false);
        },
        (error) => {
          console.error("Error fetching geolocation:", error);
          setTransactionLoaderState(false);
        }
      );
    } catch (error) {
      console.error("Error logging in:", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getwfh();
  }, [Employeeid]);
  useEffect(() => {
    getEmployees();
  }, []);
  return (
    <>
      <div className="">
        <div className="row ">
          <div className="col d-flex align-items-center">
            <h5 className="">Add WFH </h5>
          </div>

          <div className="justify-content-end">
            <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-sm d-flex justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openModal}
              >
                Add WFH
              </button>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  {" "}
                  Reason{" "}
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Date
                </th>

                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Reporting Person
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Login status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {data1.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                data1.map((item, index) => (
                  <tr key={item.empid}>
                    <td
                      style={{
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reason}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.date}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reporting_name}
                    </td>

                    <td style={{ padding: "8px" }}>
                      {item.approval_status === "R" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Rejected
                        </span>
                      )}
                      {item.approval_status === "N" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: "#f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.approval_status === "Y" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Approved
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        color: "#0074D9",
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.login_status === "N" &&
                      item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background
                            color: "#ff0000", // Red text
                            border: "1px solid #ff0000", // Red border
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Yet to Login
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(0, 123, 255, 0.1)", // Light blue background
                            color: "#007bff", // Blue text
                            border: "1px solid #007bff", // Blue border
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Logged In
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "Y" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(255, 193, 7, 0.1)", // Light yellow background
                            color: "#ffc107", // Yellow text
                            border: "1px solid #ffc107", // Yellow border
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Approval Pending
                        </Badge>
                      ) : (
                        "N/A"
                      )}
                    </td>

                    <td
                      style={{
                        color: "#0074D9",
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.login_status === "N" &&
                      item.logout_status === "N" ? (
                        <Button
                          className="btn-sm"
                          variant="info"
                          onClick={() => handleLogin(item)}
                          disabled={
                            item.approval_status === "R" ||
                            item.approval_status === "N"
                          }
                        >
                          CheckIn
                        </Button>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "N" ? (
                        <>
                          <Button
                            className="btn-sm"
                            variant="warning"
                            onClick={() => handleLogout(item)}
                            disabled={
                              item.approval_status === "R" ||
                              item.approval_status === "N"
                            }
                          >
                            CheckOut
                          </Button>
                          &nbsp; &nbsp;
                          <Link to="/retailer/Wfh_Work_Update">
                            <FaArrowRight
                              variant="info"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "WFHwork",
                                  JSON.stringify(item)
                                );
                              }}
                            />
                          </Link>
                        </>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "Y" ? (
                        <Button className="btn-sm" variant="success">
                          completed
                        </Button>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Work From Home </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="col" controlId="discription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name="discription"
                value={formData.discription}
                placeholder="Reason for leave"
                onChange={handleFormChange}
              />{" "}
              {errors.discription && (
                <div className="text-danger">{errors.discription}</div>
              )}
            </Form.Group>
            <div className="row m-0 p-0 ">
              <Form.Group className="col-5" controlId="fromDate">
                <Form.Label> Date</Form.Label>
                <Form.Control
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleFormChange}
                />{" "}
                {errors.fromDate && (
                  <div className="text-danger">{errors.fromDate}</div>
                )}
              </Form.Group>
              <Form.Group className="col" controlId="toDate">
                <Form.Label>Reporting Person</Form.Label>
                <Select
                  options={employees}
                  value={employees.find(
                    (emp) => emp.value === formData.Reportingperson
                  )}
                  onChange={(selectedOption) => {
                    setFormData({
                      ...formData,
                      Reportingperson: selectedOption.value,
                      reporting_id: selectedOption.value,
                      reporting_name: selectedOption.label,
                    });
                  }}
                  placeholder="Select reporting person"
                  isClearable
                  isSearchable
                />
              </Form.Group>
            </div>
  {formData.tasks.map((task, index) => (
              <Form.Group
                className="col  "
                key={index}
                controlId={`formTask-${index}`}
              >
                <Form.Label>Task {index + 1}</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="task"
                    value={task.task}
                    onChange={(e) => handleTaskChange(index, e)}
                    isInvalid={!!errors[`task-${index}`]}
                    className="mr-2"
                  />
                  {index > 0 && (
                    <IoIosRemoveCircle
                      onClick={() => removeTask(index)}
                      style={{ fontSize: "1.3rem", color: "red" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors[`task-${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            ))}

            <div className="d-flex col  justify-content-center align-items-center ">
              <IoAddCircle
                onClick={addTask}
                style={{ fontSize: "1.3rem", color: "green" }}
              />
              <Badge onClick={addTask}>Add New</Badge>
            </div>
           
            <div className="mx-md-2">
              <Button
                className="d-flex align-items-center"
                type="submit"
                style={{
                  height: "2rem",
                  borderRadius: ".3rem",
                  fontSize: ".9rem",
                }}
                onClick={validateForm}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
const WFHWORk = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);

  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const { password, username } = billpunchbasicauth;
  const getwfh = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: "string",
        firmid: user.firm_id,
        reporting_id: user.userid,
        type: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWfhV1`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getwfh();
  }, []);
  return (
    <>
      <div className="">
        <div className="row ">
          <div className="col d-flex align-items-center">
            <h5 className="">Add WFH </h5>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Employee
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  {" "}
                  Reason{" "}
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Date
                </th>

                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Reporting Person
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Login status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {data1.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                data1.map((item, index) => (
                  <tr key={item.empid}>
                    <td
                      style={{
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.fullname}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reason}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.date}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reporting_name}
                    </td>

                    <td style={{ padding: "8px" }}>
                      {item.status === "R" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Rejected
                        </span>
                      )}
                      {item.status === "N" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: "#f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "Y" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Approved
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        color: "#0074D9",
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.login_status === "N" &&
                      item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Yet to Login
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Logged In
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "Y" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Approval Pending
                        </Badge>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      <Link to="/retailer/Review_Wfh_Work_Update">
                        <FaArrowRight
                          variant="info"
                          onClick={() => {
                            sessionStorage.setItem(
                              "WFHwork",
                              JSON.stringify(item)
                            );
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default EmployeeLeave;
