

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../custom-hooks/useQuery";
import {  ReviewBillTableAdmin } from "./SalaryTable";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ExpensesAdmin from "./ExpenseAdmin";
import { GlobalContext } from "../../Context/GlobalState";

const ExpenseDashboardAdmin = () => {
  const query = useQuery();
  const id = query.get("id");
  const CompanyName = sessionStorage.getItem("CompanyName");
  const [activeLink, setActiveLink] = useState("expenses");
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  const renderContent = () => {
    switch (activeLink) {
      case "expenses":
        return <ExpensesAdmin />;
      case "bills":
        return <ReviewBillTableAdmin />;
      default:
        return null;
    }
  };

  return (
    <>
    <div className="m-5">
    <nav className=" navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse mx-md-4 mx-sm-2"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              
              <Link
                className={`nav-link green-link ${
                  activeLink === "expenses" && "active"
                }`}
                onClick={() => setActiveLink("expenses")}
              >
                Expenses
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "bills" && "active"
                }`}
                onClick={() => setActiveLink("bills")}
              >
                Bills
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {renderContent()}
    </div>
     
    </>
  );
};

export default ExpenseDashboardAdmin;
