import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { COLOR_LOGIN_SCREEN_STRIPE } from "../utils/Themer";
import { formatDateDDMMYY } from "../utils/utilFuctions";

const UserKycView = () => {
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "R" || data.data[0].kyc_status == "N") {
        //  localStorage.setItem("steps", "1");
          history.push("/kyc/bussiness-details");
        }
        setKycData(data.data[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUserKycData();
  }, []);

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      <div
        className="card"
        style={{ backgroundColor: "white", textAlign: "center" }}
      >
        <div style={{ display: "flex" }}>
          <button
            className="btn btn-primary ml-2 mb-2"
            style={{ display: "inline-block" }}
            onClick={() => {
              history.push("/");
            }}
          >
            Back
          </button>
          <div style={{ flex: "1" }} />
          <button
            className="btn btn-primary ml-2 mb-2"
            style={{ display: "inline-block" }}
            onClick={() => {
              history.push("/agent-kyc");
            }}
          >
            Do Your Biometric KYC
          </button>
        </div>

        <div
          style={{
            backgroundColor: kycData.kyc_status == "A" ? "#3EC70B" : "#FFB200",
            height: "6vh",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
          className="p-2 row m-0 px-3 py-2 justify-content-center"
        >
          <b className="" style={{ color: "white" }}>
            {kycData.kyc_status == "A" ? "KYC Approved" : "KYC Pending"}
          </b>
        </div>
        <div className="card-body">
          <Col md={12} lg={12} sm={12}>
            <small>
              <b>Business Details</b>
            </small>
            <div style={{ borderTop: "2px solid #00ffb8" }}></div>
            <div className="mt-1 p-1">
              <b>Shop/Business Name: </b>
              <span>{kycData.shopname}</span>
            </div>
            <div className="mt-1 p-1">
              <b>Shop Address: </b>
              <span>{kycData.shopaddress}</span>
            </div>
          </Col>

          <Row>
            <Col md={12} lg={12} sm={12}>
              <small>
                <b>Personal Details</b>
              </small>
              <div style={{ borderTop: "2px solid #00ffb8" }}></div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>First Name: </b>
                <span>{kycData.userfname} </span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Last Name: </b>
                <span>{kycData.userlname}</span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Gender: </b>
                <span>{kycData.gender}</span>
              </div>
            </Col>

            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Mobile: </b>
                <span>{kycData.usermobile}</span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>DOB: </b>
                <span>{formatDateDDMMYY(kycData.dateofbirth)}</span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Email: </b>
                <span>{kycData.emailid}</span>
              </div>
            </Col>

            <Col md={12} lg={12} sm={12}>
              <div className="mt-1 p-1">
                <b>Home Address: </b>
                <span>{kycData.useraddress}</span>
              </div>
            </Col>
          </Row>

          <Row className="w-100">
            <Col md={12} lg={12} sm={12}>
              <small>
                <b>Document Details</b>
              </small>
              <div style={{ borderTop: "2px solid #00ffb8" }}></div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Pan Card: </b>
                <span>{kycData.pancardno}</span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>Adhar Card: </b>
                <span>{kycData.aadharcardno}</span>
              </div>
            </Col>
            <Col md={4} lg={4} sm={12}>
              <div className="mt-1 p-1">
                <b>GST Number: </b>
                <span>{kycData.gstnumber}</span>
              </div>
            </Col>
          </Row>
       
       
          <Row>
            <Col md={12} lg={12} sm={12}>
              <small>
                <b>Uploaded Images</b>
              </small>
              <div style={{ borderTop: "2px solid #00ffb8" }}></div>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                PanCard
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.imgpancard}
              ></Image>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                Aadhar Front
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.imgaadharfront}
              ></Image>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                Aadhar Back
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.imgaadharback}
              ></Image>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                GST Doc
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.gstdoc}
              ></Image>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                Agent Photo
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.imguser}
              ></Image>
            </Col>
            <Col md={4} lg={4} sm={12} className="p-2">
              <strong style={{ color: COLOR_LOGIN_SCREEN_STRIPE }}>
                {" "}
                Signature
              </strong>
              <Image
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "30vh",
                  maxWidth: "30vw",
                }}
                src={kycData.signature}
              ></Image>
            </Col>
            {/* <Col md={4} lg={4} sm={12} className="p-2">
                <Image src={kycData.imgpancard}></Image>
              </Col> */}
          </Row>
        </div>
     
     
      </div>
    </div>
  );
};

export default UserKycView;
