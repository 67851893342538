import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { useQuery } from "../../custom-hooks/useQuery";
import Select from "react-select";
import { SiAdblock } from "react-icons/si";
import { CiEdit, CiReceipt } from "react-icons/ci";
import { AiFillEye } from "react-icons/ai";
import { GrEdit } from "react-icons/gr";
import deleteIcon from "../../../assets/images/pms/Delete_icon.svg";
import editIcon from "../../../assets/images/pms/Edit_icon.svg";
import {
  BASE_URL_API_TRANXT_UAT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import swal from "sweetalert";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import {
  FaMoneyBill,
  FaUser,
  FaEllipsisV,
  FaFlag,
  FaBan,
  FaIcons,
  FaTimes,
  FaRegArrowAltCircleRight,
  FaEye,
  FaCheck,
  FaAngleUp,
  FaAngleDown,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  AddBillFormAdmin,
  AddBillFormEmployee,
  AddBillFormHR,
  AddResourceAdminModal,
  AddTaskemployeeModal,
  Addholidaymodal,
  AddsubTask1employeeModal,
  AddtaskAdminModal,
  Assignemployee,
  DependencyModal,
  Dependencytable,
  EditHolidayModal,
  EditTaskModal,
  FetchBillModalAdmin,
  FetchBillModalEmployee,
  FetchBillModalHR,
  ViewModalAdmin,
  ViewModalHR,
  ViewResourceAdminModal,
  Viewprviewepfcalcilation,
  Viewsepfspilit,
} from "./Modal";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  PiDotsThreeCircleLight,
  PiDotsThreeOutlineVerticalBold,
} from "react-icons/pi";
import { IoIosSend, IoMdPersonAdd } from "react-icons/io";
import { FcNext, FcPrevious } from "react-icons/fc";
import { billpunchbasicauth } from "../../utils/Credentials";
import {
  MdDeleteForever,
  MdDeleteOutline,
  MdDriveFileRenameOutline,
  MdOutlineEdit,
} from "react-icons/md";
import {
  IoCheckmarkDoneCircle,
  IoInformationCircleOutline,
} from "react-icons/io5";

// Admin

export const ViewProjectadmindash = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const { password, username } = billpunchbasicauth;
  const [projectdata, setprojectdata] = useState([]);
  const getProjects = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic" + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        created_by: "Admin",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmProjects`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setprojectdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  useEffect(() => {
    getProjects();
  }, []);
  return (
    <>
      <div className="row">
        <h5 className="col">Project</h5>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/Createproject">
            <h6 className="px-1">
              View More <FaRegArrowAltCircleRight />
            </h6>
          </Link>
        </div>
      </div>
      <table
        className="table table-bordered m-0 p-0 "
        style={{ border: "none" }}
      >
        <thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: "#fff",
          }}
        >
          <tr style={{ borderBottom: "1px solid lightgrey" }}>
            <th
              className="text-center"
              style={{
                color: "#0074D9",
                background: "#fff",
                border: "none",
              }}
            >
              Name
            </th>

            <th
              className="text-center"
              style={{
                color: "#0074D9",
                background: "#fff",
                border: "none",
              }}
            >
              Ownership
            </th>

            <th
              className="text-center"
              style={{
                color: "#0074D9",
                background: "#fff",
                border: "none",
              }}
            >
              Progress
            </th>
          </tr>
        </thead>
        <tbody>
          {projectdata.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No data available
              </td>
            </tr>
          ) : (
            projectdata.slice(0, 3).map((item, index) => (
              <tr style={{ borderBottom: "1px solid lightgrey" }} key={index}>
                <td
                  className="text-center align-middle"
                  style={{
                    border: "none",
                    filter: index === 2 ? "blur(2px)" : "none",
                    background: "#fff",
                  }}
                >
                  {item.project_name || "NA"}
                </td>
                <td
                  className="text-center align-middle"
                  style={{
                    border: "none",
                    filter: index === 2 ? "blur(2px)" : "none",
                    background: "#fff",
                  }}
                >
                  {item.project_ownership || "NA"}
                </td>
                <td
                  className="text-center align-middle"
                  style={{
                    border: "none",
                    filter: index === 2 ? "blur(2px)" : "none",
                    background: "#fff",
                  }}
                >
                  <progress
                    value={item.progress}
                    max="100"
                    style={{ width: "100px" }}
                  >
                    {item.progress}%
                  </progress>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export const ViewProjectadmin = ({ data, getProjects, searchTerm }) => {
  const [showviewresourceModal, setshowviewresourceModal] = useState(false);
  const [showaddresourceModal, setshowaddresourceModal] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const openaddresourceModal = (pk_project_id) => {
    setshowaddresourceModal(true);
    setProjectId(pk_project_id);
  };

  const closeaddresourceModal = () => {
    setshowaddresourceModal(false);
  };
  const openviewresourceModal = (pk_project_id) => {
    setProjectId(pk_project_id);
    setshowviewresourceModal(true);
  };

  const closeviewresourceModal = () => {
    setshowviewresourceModal(false);
  };
  const filteredData = data.filter(
    (item) =>
      item.project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.project_description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <ViewResourceAdminModal
        show={showviewresourceModal}
        closeModal={closeviewresourceModal}
        projectId={projectId}
      />
      <AddResourceAdminModal
        show={showaddresourceModal}
        closeModal={closeaddresourceModal}
        getProjects={getProjects}
        projectId={projectId}
      />
      <div className=" m-0 ">
        <div className="row">
          <div className="col-12">
            <div
              className="table-responsive"
              style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
            >
              <table className="table table-bordered m-0 p-0 ">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      ID
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Start Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      End Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Ownership
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length === 0 ? (
                    <tr>
                      <td
                        colSpan="7"
                        className="text-center"
                        style={{ padding: "8px" }}
                      >
                        No data found
                      </td>
                    </tr>
                  ) : (
                    filteredData.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid lightgrey" }}
                        key={index}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.pk_project_id || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.project_name || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.project_discription || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {new Date(
                            item.project_startdate
                          ).toLocaleDateString() || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {new Date(
                            item.project_enddate
                          ).toLocaleDateString() || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.project_ownership || "NA"}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              type="button"
                              className="btn btn-sm d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "rgba(43, 193, 85, 1)",
                                borderColor: "rgba(43, 193, 85, 1)",
                                borderRadius: ".4rem",
                                border: "1px solid",
                                // height: "1.5rem",
                                fontSize: ".8rem",
                              }}
                              onClick={() =>
                                openaddresourceModal(item.pk_project_id)
                              }
                            >
                              Add Resources
                            </button>{" "}
                            <Button
                              style={{ background: "#fff", border: "none" }}
                              disabled
                            ></Button>
                            <button
                              type="button"
                              className="btn btn-sm d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "rgba(43, 193, 85, 1)",
                                borderColor: "rgba(43, 193, 85, 1)",
                                borderRadius: ".4rem",
                                border: "1px solid",
                                // height: "1.5rem",
                                fontSize: ".8rem",
                              }}
                              onClick={() =>
                                openviewresourceModal(item.pk_project_id)
                              }
                            >
                              View Resources
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const ViewWorkspaceadmin = ({ data, Getworkspace }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);

  const [workspacedata, setworkspacedata] = useState(false);
  const handleAddEmployeeModalOpen = () => {
    setShowAddEmployeeModal(true);
  };

  const handleAddEmployeeModalClose = () => {
    setShowAddEmployeeModal(false);
  };
  const getWorkspacedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          ownerid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetYourWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setworkspacedata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getWorkspacedata();
  }, []);
  return (
    <>
      <Assignemployee
        isOpen={showAddEmployeeModal}
        onClose={handleAddEmployeeModalClose}
        selectedWorkspaceId={selectedWorkspaceId}
        getWorkspacedata={getWorkspacedata}
      />
      <div className="m-0">
        <div className="row">
          <div className="col-12">
            <div
              className="table-responsive"
              style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
            >
              <table className="table table-bordered m-0 p-0">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      ID
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Owner
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Team Size
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          textAlign: "center",
                          padding: "8px",
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        No data found
                      </td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid lightgrey" }}
                        key={index}
                      >
                        <td
                          className="text-center align-middle"
                          style={{
                            border: "none",
                            background: "#fff",
                            fontWeight: "500",
                          }}
                        >
                          {item.workspace_name}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            border: "none",
                            background: "#fff",
                            fontWeight: "500",
                          }}
                        >
                          {item.workspce_desc}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            border: "none",
                            display: "flex",
                            background: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <div className="p-2 align-start initial-container">
                            {item.lettersdata.map((employee, index) => (
                              <div
                                key={index}
                                className="initial"
                                style={{
                                  marginLeft: "0.1rem",
                                  background: item.colourcode,
                                }}
                              >
                                {employee.employee_first_letter}
                                <span className="employee-name m-0 p-1">
                                  {employee.emp_name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            border: "none",
                            background: "#fff",
                            fontWeight: "500",
                          }}
                        >
                          {item.ownername}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", background: "#fff" }}
                        >
                          {item.teamsize}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          <div className="d-flex justify-content-around align-items-center">
                            <Link
                              style={{ fontSize: ".8rem" }}
                              onClick={handleAddEmployeeModalOpen}
                            >
                              Assign Employee
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ViewBalanceleave = ({ data }) => {
  return (
    <div className="m-0">
      <div className="row">
        <div className="col-12">
          <div
            className="table-responsive"
            style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
          >
            {data.length > 0 ? (
              <table className="table table-bordered m-0 p-0">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Month
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Present
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Absent
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Earned PL
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Earned SL
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      PL Balance
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      SL Balance
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Total PL
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Total SL
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Availed Leave
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.empname || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.month || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.present_days || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.absent_days || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.earned_pl || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.earned_sl || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.pl_bal || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.sl_bal || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.total_pl || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.total_sl || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.availed_leave || "NA"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center p-3">No data found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewExpenseTableAdmin = ({ data, getPropertyExpense }) => {
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const ApproveRejectLeave = async (expId, action) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        exp_id: expId,
        approved_byhr: action === "approve" ? "P" : "R",
        approved_byadmin: action === "approve" ? "A" : "R",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ApproveRejectExpense`,
        requestOptions
      );

      const responseData = await res.json();
      if (responseData.status === "0") {
        swal("Success", responseData.msg, "success");
        getPropertyExpense();
      } else {
        swal("Error", responseData.msg, "error");
        getPropertyExpense();
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const sortedData = [...data].sort(
    (a, b) => new Date(b.expense_date) - new Date(a.expense_date)
  );
  return (
    <>
      <div className=" m-0 ">
        <div className="row">
          <div className="col-12">
            <div
              className="table-responsive"
              style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
            >
              <table className="table table-bordered m-0 p-0 ">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      ID
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Type
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Description
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(HR)
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(Admin)
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Action
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Receipt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.length > 0 ? (
                    sortedData.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid lightgrey" }}
                        key={index}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.pk_expense_id}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.fullname}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.expense_type}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {new Date(item.expense_date).toLocaleDateString()}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {twoDecimalPlaces(item.expense_amount)}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.description}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_hr === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_hr === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_hr === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_admin === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_admin === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_admin === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_hr === "P" ||
                          item.approved_by_admin === "P" ? (
                            <div className="d-flex justify-content-around align-items-center">
                              <span className="px-2">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                  style={{
                                    height: "1.5rem",
                                    borderRadius: ".3rem",
                                    fontSize: ".8rem",
                                  }}
                                  onClick={() =>
                                    ApproveRejectLeave(
                                      item.pk_expense_id,
                                      "approve"
                                    )
                                  }
                                >
                                  Approve
                                </button>
                              </span>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                }}
                                onClick={() =>
                                  ApproveRejectLeave(
                                    item.pk_expense_id,
                                    "reject"
                                  )
                                }
                              >
                                Reject
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-around align-items-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                  filter: "grayscale(100%)",
                                }}
                                disabled
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                  filter: "grayscale(100%)",
                                }}
                                disabled
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          <a
                            href={item.baseurl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CiReceipt style={{ fontSize: "2rem" }} />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const ReviewBillTableAdmin = ({}) => {
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [amount, setAmount] = useState("");
  const { Profileinfo } = useContext(GlobalContext);
  const query = useQuery();
  const id = query.get("id");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [billerResponse, setBillerResponse] = useState({});
  const [state, setState] = useState({});
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billPayResponse, setBillPayResponse] = useState(null);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch bill");
  const [username, setUsername] = useState("Rajneesh@example.com");
  const [password, setPassword] = useState("Test@12345");
  const CustP = sessionStorage.getItem("params")
    ? JSON.parse(sessionStorage.getItem("params"))
    : [];

  const [selectedItems, setSelectedItems] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState({});

  const [showFetchModal, setShowFetchModal] = useState(false);
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    category: "",
    amount: "",
    minAmount: "",
    maxAmount: "",
    dateOption: "All Dates",
  });
  const handleShowFetchModal = (item) => {
    setSelectedItem(item);
    setShowFetchModal(true);
  };

  const handleCloseFetchModal = () => {
    setShowFetchModal(false);
  };
  const fetchBill = async (biller, custParams, item) => {
    console.log("hua kuch");
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);
    // const biller = state.provider;
    // if (biller.length == 0) {
    //   return;
    // }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A_uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    custParams.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });
    console.log(params);

    var raw = JSON.stringify({
      customerMobileNo: "9971407960",
      billDetails: {
        billerId: biller,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      console.log("hua");
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
        // updatePropertyBill(item);
      } else {
        swal("Success", "Successfull", "success");
        // updatePropertyBill(item);
      }
      console.log(
        "Before updatePropertyBill call" + data.response.billerResponse
      );
      //  updatePropertyBill(data.response,data1);

      console.log("After updatePropertyBill call");
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getAllPropertyBills = async () => {
    try {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getfirmBills`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        console.log(data.data);
        setData(data.data);
        setData1(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const updatePropertyBill = async (item, data12) => {
    console.log(data1 + "123");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        b_id: data12.id,
        firmid: user.firm_id,
        service: data12.service,
        provider: data12.provider,
        consumerid: data12.consumerid,
        mobile: Profileinfo?.mobile,
        month: item.billerResponse.billDate,
        amount: item.billerResponse.amount,
        refno: item.refId,
        paramslist: JSON.stringify(data12.paramsList),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryBill`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getAllPropertyBills();
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getAllPropertyBills();
  }, [id]);

  const handleItemSelection = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };
  return (
    <>
      <FetchBillModalAdmin
        fetchBillModal={fetchBillModal}
        setFetchBillModal={setFetchBillModal}
        billDetails={billDetails}
        billerResponse={billerResponse}
        callback={() => {}}
        billPayResponse={billPayResponse}
        setAmount={() => {}}
        amount={amount}
        setTpin={() => {}}
        Tpin={"0"}
        config={{ amountEditable: false }}
      />
      <AddBillFormAdmin
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getAllPropertyBills={getAllPropertyBills}
      />
      <div className="row ">
        <div className="col d-flex align-items-center">
          <h4
            className="mx-md-4 mx-sm-2"
            // style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            Bills
          </h4>
        </div>

        <div className="input-group-append justify-content-end">
          {/* <div className="px-md-1 px-sm-1 d-flex justify-content-center align-items-center">
              <a href="#" style={{ fontSize: "1.2rem" }}>
                Give Feedback
              </a>
            </div> */}
          {/* <div className="d-flex align-items-center">
            <Link
            // to={"/retailer/BillTransactionHistory"}
            >
              <button
                type="button"
                className="btn btn-sm btn-success d-flex align-items-center"
                style={{
                  borderRadius: ".4em",
                  height: "2rem",
                  color: "#fff",
                  fontSize: ".9rem",
                }}
              >
                Transaction History
              </button>
            </Link>
          </div> */}
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
            <Link>
              {" "}
              <button
                type="button"
                className="btn btn-sm  d-flex justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2rem",
                  width: "7rem",
                  border: "None",
                  color: "#fff",
                }}
                onClick={handleShowModal}
              >
                Add Biller
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table m-1"
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Services</th>
              <th style={{ color: "#0074D9" }}>Provider</th>
              <th style={{ color: "#0074D9" }}>Bill Date</th>
              <th style={{ color: "#0074D9" }}> Consumer ID </th>
              <th style={{ color: "#0074D9" }}>Amount</th>
              <th
                style={{
                  color: "#0074D9",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.service}</td>
                  <td>{item.provider}</td>
                  <td>{new Date(item.month).toLocaleDateString()}</td>
                  <td>{item.consumerid}</td>
                  <td>{twoDecimalPlaces(item.amount)}</td>
                  <td className="d-flex justify-content-center">
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          console.log("ni hua");
                          fetchBill(item.provider, item.paramsList);
                        }}
                      >
                        Fetch & Pay
                      </Badge>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          updatePropertyBill(billDetails, item);
                        }}
                      >
                        Update
                      </Badge>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

// HR

export const ViewExpenseTableHR = ({ data, getPropertyExpense }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const ApproveRejectLeave = async (expId, action) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        exp_id: expId,
        approved_byhr: action === "approve" ? "A" : "R",
        approved_byadmin: action === "approve" ? "P" : "R",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ApproveRejectExpense`,
        requestOptions
      );

      const responseData = await res.json();
      if (responseData.status === "0") {
        swal("Success", responseData.msg, "success");
        getPropertyExpense();
      } else {
        swal("Error", responseData.msg, "error");
        getPropertyExpense();
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  return (
    <>
      <div className=" m-0 ">
        <div className="row">
          <div className="col-12">
            <div
              className="table-responsive"
              style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
            >
              <table className="table table-bordered m-0 p-0 ">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      ID
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Name
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Type
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Description
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(HR)
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(Admin)
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Action
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Receipt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid lightgrey" }}
                        key={index}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.pk_expense_id}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.fullname}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.expense_type}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {new Date(item.expense_date).toLocaleDateString()}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {twoDecimalPlaces(item.expense_amount)}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.description}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_hr === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_hr === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_hr === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_admin === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_admin === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_admin === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_hr === "P" ||
                          item.approved_by_admin === "P" ? (
                            <div className="d-flex justify-content-around align-items-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                }}
                                onClick={() =>
                                  ApproveRejectLeave(
                                    item.pk_expense_id,
                                    "approve"
                                  )
                                }
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                }}
                                onClick={
                                  () =>
                                    ApproveRejectLeave(
                                      item.pk_expense_id,
                                      "reject"
                                    ) // Pass "reject" as action
                                }
                              >
                                Reject
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-around align-items-center">
                              <button
                                type="button"
                                className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  fontSize: ".8rem",
                                  filter: "grayscale(100%)",
                                }}
                                disabled
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                style={{
                                  height: "1.5rem",
                                  borderRadius: ".3rem",
                                  filter: "grayscale(100%)",
                                  fontSize: ".8rem",
                                }}
                                disabled
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          <a
                            href={item.baseurl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CiReceipt style={{ fontSize: "2rem" }} />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReviewBillTableHR = ({}) => {
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [amount, setAmount] = useState("");
  const { Profileinfo } = useContext(GlobalContext);
  const query = useQuery();
  const id = query.get("id");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [billerResponse, setBillerResponse] = useState({});
  const [state, setState] = useState({});
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billPayResponse, setBillPayResponse] = useState(null);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch bill");
  const [username, setUsername] = useState("Rajneesh@example.com");
  const [password, setPassword] = useState("Test@12345");
  const CustP = sessionStorage.getItem("params")
    ? JSON.parse(sessionStorage.getItem("params"))
    : [];
  const [selectedItems, setSelectedItems] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState({});

  const [showFetchModal, setShowFetchModal] = useState(false);
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    category: "",
    amount: "",
    minAmount: "",
    maxAmount: "",
    dateOption: "All Dates",
  });
  const handleShowFetchModal = (item) => {
    setSelectedItem(item);
    setShowFetchModal(true);
  };

  const handleCloseFetchModal = () => {
    setShowFetchModal(false);
  };
  const fetchBill = async (biller, custParams, item) => {
    console.log("hua kuch");
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);
    // const biller = state.provider;
    // if (biller.length == 0) {
    //   return;
    // }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A_uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    custParams.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });
    console.log(params);

    var raw = JSON.stringify({
      customerMobileNo: "9971407960",
      billDetails: {
        billerId: biller,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      console.log("hua");
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
        // updatePropertyBill(item);
      } else {
        swal("Success", "Successfull", "success");
        // updatePropertyBill(item);
      }
      console.log(
        "Before updatePropertyBill call" + data.response.billerResponse
      );
      //  updatePropertyBill(data.response,data1);

      console.log("After updatePropertyBill call");
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getAllPropertyBills = async () => {
    try {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getfirmBills`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        console.log(data.data);
        setData(data.data);
        setData1(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const updatePropertyBill = async (item, data12) => {
    console.log(data1 + "123");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        b_id: data12.id,
        firmid: user.firm_id,
        service: data12.service,
        provider: data12.provider,
        consumerid: data12.consumerid,
        mobile: Profileinfo?.mobile,
        month: item.billerResponse.billDate,
        amount: item.billerResponse.amount,
        refno: item.refId,
        paramslist: JSON.stringify(data12.paramsList),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryBill`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getAllPropertyBills();
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getAllPropertyBills();
  }, [id]);

  const handleItemSelection = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };
  return (
    <>
      <FetchBillModalHR
        fetchBillModal={fetchBillModal}
        setFetchBillModal={setFetchBillModal}
        billDetails={billDetails}
        billerResponse={billerResponse}
        callback={() => {}}
        billPayResponse={billPayResponse}
        setAmount={() => {}}
        amount={amount}
        setTpin={() => {}}
        Tpin={"0"}
        config={{ amountEditable: false }}
      />
      <AddBillFormHR
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getAllPropertyBills={getAllPropertyBills}
      />
      <div className="row ">
        <div className="col d-flex align-items-center">
          <h4
            className="mx-md-4 mx-sm-2"
            // style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            Bills
          </h4>
        </div>

        <div className="input-group-append justify-content-end">
          {/* <div className="px-md-1 px-sm-1 d-flex justify-content-center align-items-center">
              <a href="#" style={{ fontSize: "1.2rem" }}>
                Give Feedback
              </a>
            </div> */}
          <div className="d-flex align-items-center">
            <Link
            // to={"/retailer/BillTransactionHistory"}
            >
              <button
                type="button"
                className="btn btn-sm btn-success d-flex align-items-center"
                style={{
                  borderRadius: ".4em",
                  height: "2rem",
                  color: "#fff",
                  fontSize: ".9rem",
                }}
              >
                Transaction History
              </button>
            </Link>
          </div>
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
            <Link>
              {" "}
              <button
                type="button"
                className="btn btn-sm  d-flex justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2rem",
                  width: "7rem",
                  border: "None",
                  color: "#fff",
                }}
                onClick={handleShowModal}
              >
                Add Biller
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table m-1"
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Services</th>
              <th style={{ color: "#0074D9" }}>Provider</th>
              <th style={{ color: "#0074D9" }}>Bill Date</th>
              <th style={{ color: "#0074D9" }}> Consumer ID </th>
              <th style={{ color: "#0074D9" }}>Amount</th>
              <th
                style={{
                  color: "#0074D9",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.service}</td>
                  <td>{item.provider}</td>
                  <td>{new Date(item.month).toLocaleDateString()}</td>
                  <td>{item.consumerid}</td>
                  <td>{twoDecimalPlaces(item.amount)}</td>
                  <td className="d-flex justify-content-center">
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          console.log("ni hua");
                          fetchBill(item.provider, item.paramsList);
                        }}
                      >
                        Fetch & Pay
                      </Badge>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          updatePropertyBill(billDetails, item);
                        }}
                      >
                        Update
                      </Badge>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const DashboardHRtable = ({}) => {
  const dummyData = [
    {
      name: "Rahul Sharma",
      employeeID: "EMP101",
      updates: "leave",
      date: "2023-01-01",
      action: "View",
    },
    {
      name: "Priya Verma",
      employeeID: "EMP102",
      updates: "Leave ",
      date: "2023-01-02",
      action: "Edit",
    },
    {
      name: "Suresh Kumar",
      employeeID: "EMP103",
      updates: " leave",
      date: "2023-01-03",
      action: "Delete",
    },
    {
      name: "Anjali Gupta",
      employeeID: "EMP104",
      updates: "Leave ",
      date: "2023-01-04",
      action: "Approve",
    },
    {
      name: "Priya Verma",
      employeeID: "EMP102",
      updates: "Leave ",
      date: "2023-01-02",
      action: "Edit",
    },
    {
      name: "Suresh Kumar",
      employeeID: "EMP103",
      updates: " leave",
      date: "2023-01-03",
      action: "Delete",
    },
    {
      name: "Anjali Gupta",
      employeeID: "EMP104",
      updates: "Leave ",
      date: "2023-01-04",
      action: "Approve",
    },
  ];

  return (
    <div style={{ background: "#fff" }}>
      <div className="row">
        <h6 className="col">Recent Activities</h6>
        <h6 className="col d-flex justify-content-end">View All</h6>
      </div>
      <div
        className="table-responsive"
        style={{
          borderRadius: ".4em",
          border: "1px solid lightgrey",
          borderRadius: ".3rem",
          overflowX: "auto",
        }}
      >
        <table className="table table-bordered m-0 p-0 ">
          <thead>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Name
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Employee ID
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Updates
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Date
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((data, index) => (
              <tr key={index}>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.name}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.employeeID}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.updates}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.date}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.action}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Employee
export const DashboardEmployeetable = ({}) => {
  const dummyData = [
    {
      name: "Rahul Sharma",
      employeeID: "EMP101",
      updates: "leave",
      date: "2023-01-01",
      action: "View",
    },
    {
      name: "Priya Verma",
      employeeID: "EMP102",
      updates: "Leave ",
      date: "2023-01-02",
      action: "Edit",
    },
    {
      name: "Suresh Kumar",
      employeeID: "EMP103",
      updates: " leave",
      date: "2023-01-03",
      action: "Delete",
    },
    {
      name: "Anjali Gupta",
      employeeID: "EMP104",
      updates: "Leave ",
      date: "2023-01-04",
      action: "Approve",
    },
  ];

  return (
    <div style={{ background: "#fff" }}>
      <div className="row">
        <h6 className="col">Recent Activities</h6>
        <h6 className="col d-flex justify-content-end">View All</h6>
      </div>
      <div
        className="table-responsive"
        style={{
          borderRadius: ".4em",
          border: "1px solid lightgrey",
          borderRadius: ".3rem",
          overflowX: "auto",
        }}
      >
        <table className="table table-bordered m-0 p-0 ">
          <thead>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Name
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Employee ID
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Updates
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Date
              </th>
              <th
                className="text-center"
                style={{
                  color: "#0074D9",
                  background: "#eff4fa",
                  border: "none",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((data, index) => (
              <tr key={index}>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.name}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.employeeID}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.updates}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.date}
                </td>
                <td
                  className="text-center"
                  style={{
                    border: "none",
                  }}
                >
                  {data.action}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const ViewExpenseTableEmployee = ({ data }) => {
  return (
    <>
      <div className=" m-0 ">
        <div className="row">
          <div className="col-12">
            <div
              className="table-responsive"
              style={{ borderRadius: ".4em", border: "1px solid lightgrey" }}
            >
              <table className="table table-bordered m-0 p-0 ">
                <thead>
                  <tr style={{ borderBottom: "1px solid lightgrey" }}>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      ID
                    </th>

                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Type
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Date
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(HR)
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Approve(Admin)
                    </th>
                    <th
                      className="text-center"
                      style={{
                        color: "#0074D9",
                        background: "#fff",
                        border: "none",
                      }}
                    >
                      Receipt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr
                        style={{ borderBottom: "1px solid lightgrey" }}
                        key={index}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.pk_expense_id}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.expense_type}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {new Date(item.expense_date).toLocaleDateString()}
                        </td>

                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {twoDecimalPlaces(item.expense_amount)}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.description}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_hr === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_hr === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_hr === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          {item.approved_by_admin === "R" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                color: "#ff2e2e",
                                border: "1px solid #ff2e2e",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Rejected
                            </span>
                          )}
                          {item.approved_by_admin === "P" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(255, 255, 0, 0.1)",
                                color: " #f7b12a",
                                border: "1px solid #f7b12a",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Pending
                            </span>
                          )}
                          {item.approved_by_admin === "A" && (
                            <span
                              className=" p-1 "
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "#2bc155",
                                border: "1px solid #2bc155",
                                borderRadius: ".3rem",
                                fontSize: ".8rem",
                              }}
                            >
                              Approved
                            </span>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none" }}
                        >
                          <a
                            href={item.baseurl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CiReceipt style={{ fontSize: "2rem" }} />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReviewBillTableEmployee = ({}) => {
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [amount, setAmount] = useState("");
  const { Profileinfo } = useContext(GlobalContext);
  const query = useQuery();

  const id = query.get("id");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [billerResponse, setBillerResponse] = useState({});
  const [state, setState] = useState({});
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billPayResponse, setBillPayResponse] = useState(null);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch bill");
  const [username, setUsername] = useState("Rajneesh@example.com");
  const [password, setPassword] = useState("Test@12345");
  const CustP = sessionStorage.getItem("params")
    ? JSON.parse(sessionStorage.getItem("params"))
    : [];
  const [selectedItems, setSelectedItems] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState({});

  const [showFetchModal, setShowFetchModal] = useState(false);
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    category: "",
    amount: "",
    minAmount: "",
    maxAmount: "",
    dateOption: "All Dates",
  });
  const handleShowFetchModal = (item) => {
    setSelectedItem(item);
    setShowFetchModal(true);
  };

  const handleCloseFetchModal = () => {
    setShowFetchModal(false);
  };
  const fetchBill = async (biller, custParams, item) => {
    console.log("hua kuch");
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);

    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A_uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    custParams.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });
    console.log(params);

    var raw = JSON.stringify({
      customerMobileNo: "9971407960",
      billDetails: {
        billerId: biller,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      console.log("hua");
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
        // updatePropertyBill(item);
      } else {
        swal("Success", "Successfull", "success");
        // updatePropertyBill(item);
      }
      console.log(
        "Before updatePropertyBill call" + data.response.billerResponse
      );
      //  updatePropertyBill(data.response,data1);

      console.log("After updatePropertyBill call");
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getAllPropertyBills = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getfirmBills`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        console.log(data.data);
        setData(data.data);
        setData1(data.data);
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const updatePropertyBill = async (item, data12) => {
    console.log(data1 + "123");
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        b_id: data12.id,
        firmid: user.firm_id,
        service: data12.service,
        provider: data12.provider,
        consumerid: data12.consumerid,
        mobile: Profileinfo?.mobile,
        month: item.billerResponse.billDate,
        amount: item.billerResponse.amount,
        refno: item.refId,
        paramslist: JSON.stringify(data12.paramsList),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryBill`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getAllPropertyBills();
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getAllPropertyBills();
  }, [id]);

  const handleItemSelection = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };
  const { accessDataemp } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessDataemp);
  }, [accessDataemp]);

  return (
    <>
      <FetchBillModalEmployee
        fetchBillModal={fetchBillModal}
        setFetchBillModal={setFetchBillModal}
        billDetails={billDetails}
        billerResponse={billerResponse}
        callback={() => {}}
        billPayResponse={billPayResponse}
        setAmount={() => {}}
        amount={amount}
        setTpin={() => {}}
        Tpin={"0"}
        config={{ amountEditable: false }}
      />
      <AddBillFormEmployee
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getAllPropertyBills={getAllPropertyBills}
      />
      <div className="row ">
        <div className="col d-flex align-items-center">
          <h4
            className="mx-md-4 mx-sm-2"
            // style={{ fontSize: "1.1rem", fontWeight: "500" }}
          >
            Bills
          </h4>
        </div>

        <div className="input-group-append justify-content-end">
          {/* <div className="px-md-1 px-sm-1 d-flex justify-content-center align-items-center">
              <a href="#" style={{ fontSize: "1.2rem" }}>
                Give Feedback
              </a>
            </div> */}
          <div className="d-flex align-items-center">
            <Link
            // to={"/retailer/BillTransactionHistory"}
            >
              <button
                type="button"
                className="btn btn-sm btn-success d-flex align-items-center"
                style={{
                  borderRadius: ".4em",
                  height: "2rem",
                  color: "#fff",
                  fontSize: ".9rem",
                }}
              >
                Transaction History
              </button>
            </Link>
          </div>
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
            {accessDataemp &&
              accessDataemp.map(
                (module) =>
                  module.data &&
                  module.data.map((role) =>
                    role.role_name === "Add Bills" ? (
                      role.access === "Y" ? (
                        <span key={role.pk_role_id}>
                          <Link>
                            {" "}
                            <button
                              type="button"
                              className="btn btn-sm  d-flex justify-content-center align-items-center"
                              style={{
                                background: "#2ca01c",
                                borderRadius: ".4em",
                                height: "2rem",
                                width: "7rem",
                                border: "None",
                                color: "#fff",
                              }}
                              onClick={handleShowModal}
                            >
                              Add Biller
                            </button>
                          </Link>
                        </span>
                      ) : (
                        <span key={role.pk_role_id}>
                          <button
                            className="btn btn-sm btn-primary d-flex justify-content-center"
                            style={{
                              color: "#fff",
                              height: "2rem",
                              border: "none",
                              borderRadius: ".4rem",
                              display: "flex",
                              alignItems: "center",
                              fontSize: ".8rem",
                              backgroundColor: "gray",
                              cursor: "not-allowed",
                            }}
                            disabled
                          >
                            Add Biller
                          </button>
                        </span>
                      )
                    ) : null
                  )
              )}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table m-1"
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Services</th>
              <th style={{ color: "#0074D9" }}>Provider</th>
              <th style={{ color: "#0074D9" }}>Bill Date</th>
              <th style={{ color: "#0074D9" }}> Consumer ID </th>
              <th style={{ color: "#0074D9" }}>Amount</th>
              <th
                style={{
                  color: "#0074D9",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  <td>{item.service}</td>
                  <td>{item.provider}</td>
                  <td>{new Date(item.month).toLocaleDateString()}</td>
                  <td>{item.consumerid}</td>
                  <td>{twoDecimalPlaces(item.amount)}</td>
                  <td className="d-flex justify-content-center">
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          console.log("ni hua");
                          fetchBill(item.provider, item.paramsList);
                        }}
                      >
                        Fetch & Pay
                      </Badge>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Badge
                        type="button"
                        className="btn btn-sm"
                        style={{
                          background: "white",
                          color: "#0074D9",
                          border: "1px solid #0074D9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "2rem",
                          width: "6rem",
                        }}
                        onClick={() => {
                          updatePropertyBill(billDetails, item);
                        }}
                      >
                        Update
                      </Badge>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const HolidayReport = ({}) => {
  const query = useQuery();
  const { password, username } = billpunchbasicauth;

  const id = query.get("id");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data1, setData1] = useState([]);
  const [department, setDepartment] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [Employeeid, setEmployeeid] = useState(-1);
  const [Member, setMember] = useState([]);
  const CompanyName = sessionStorage.getItem("CompanyName");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedEmpid, setSelectedEmpid] = useState("");
  const [formData, setFormData] = useState({
    empName: "",
    department: "",
    fromDate: "",
    toDate: "",
    reason: "",
  });

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openEditModal = (holiday) => {
    setSelectedHoliday(holiday);
    setIsEditModalOpen(true);
    setSelectedId(holiday.id);
    setSelectedEmpid(holiday.empid);
  };
  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
        if (data.data.length > 0) {
          setEmployeeid(data.data[0].empid);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      console.log("shivani ", formData);
      var raw = JSON.stringify({
        empid: Employeeid,
        name: selectedEmployee,
        department: department,
        fromdate: formData.fromDate,
        todate: formData.toDate,
        reason: formData.reason,
      });

      console.log("Request sending:", Employeeid + "kol");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddEmployeeLeaves`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
        closeModal();
        getHolidayReport();
      } else {
        swal("Success", data.result, "success");
        closeModal();
        getHolidayReport();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const getHolidayReport = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeLeave`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const deleteHoliday = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Detail!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        handleDeleteHoliday(id);
      }
    });
  };
  const handleDeleteHoliday = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/deleteLeave `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getEmployeeList();
        swal("Success", data.result, "success");
        getHolidayReport();
      } else {
        getHolidayReport();
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "An error occurred while deleting the Holiday.", "error");
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setDepartment(selectedDepartment);
    const updatedFilteredData = data1.filter(
      (item) =>
        item.department === selectedDepartment || selectedDepartment === ""
    );
    setFilteredData(updatedFilteredData);
  };

  useEffect(() => {
    getEmployeeList(Employeeid);
    getDepartmentList();
    getHolidayReport();
  }, [Employeeid]);

  useEffect(() => {
    setFilteredData(data1);
  }, [data1]);

  return (
    <>
      <Addholidaymodal
        department={department}
        setDepartment={setDepartment}
        getEmployeeList={getEmployeeList}
        departmentData={departmentData}
        showModal={showModal}
        closeModal={closeModal}
        formData={formData}
        handleFormChange={handleFormChange}
        handleSubmit={handleSubmit}
        data={data}
        setFormData={setFormData}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setselectedEmployee}
        setData
      />
      <div className="row ">
        <div className="col d-flex align-items-center">
          <h4 className="mx-md-4 mx-sm-2">Holiday Report</h4>
        </div>

        <div className="input-group-append justify-content-end">
          <div className="col">
            <select
              style={{
                width: "9rem",
                height: "2rem",
                borderRadius: ".4rem",
                border: "1px #ced4da solid",
              }}
              value={department}
              onChange={handleDepartmentChange}
            >
              <option value="">All Department</option>
              {departmentData.map((dept) => (
                <option key={dept.id} value={dept.cat1}>
                  {dept.cat1}
                </option>
              ))}
            </select>
          </div>
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-center align-items-center">
            <Link>
              {" "}
              <button
                type="button"
                className="btn btn-sm d-flex justify-content-center align-items-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2rem",
                  width: "8rem",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openModal}
              >
                Add Holiday
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table m-1"
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Department</th>
              <th style={{ color: "#0074D9" }}>Name</th>
              <th style={{ color: "#0074D9" }}>From</th>
              <th style={{ color: "#0074D9" }}> toDate </th>
              <th style={{ color: "#0074D9" }}>Reason</th>
              <th style={{ color: "#0074D9" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td>{item.department}</td>
                <td>{item.name}</td>
                <td>{item.fromdate}</td>
                <td>{item.todate}</td>
                <td>{item.reason}</td>
                <td>
                  <Link style={{ marginRight: "5px" }}>
                    <img
                      alt=""
                      src={require("../../../assets/images/pms/Edit_icon.svg")}
                      style={{ height: "2.5rem" }}
                      onClick={() => openEditModal(item)}
                    />
                  </Link>
                  <Link>
                    <img
                      alt=""
                      style={{ height: "2.5rem" }}
                      src={require("../../../assets/images/pms/Delete_icon.svg")}
                      onClick={() => deleteHoliday(item.id)}
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isEditModalOpen && (
          <EditHolidayModal
            show={isEditModalOpen}
            onHide={() => setIsEditModalOpen(false)}
            holiday={selectedHoliday}
            selectedEmpid={selectedEmpid}
            selectedId={selectedId}
            getHolidayReport={getHolidayReport}
          />
        )}
      </div>
    </>
  );
};
export const BoardComponent = ({}) => {
  const [showaddtaskModal, setshowaddtaskModal] = useState(false);

  const query = useQuery();
  const id = query.get("id");
  const cardData = [
    {
      title: "Bill Punch",
      description: "Salary management",
      userColor: "red",
      userIcon: <FaUser />,
      time: "Jan 23, 9AM - Feb 3, 10PM",
    },
    {
      title: "PMS",
      description: "Property management",
      userColor: "red",
      userIcon: <FaUser />,
      time: "Jan 23, 9AM - Feb 3, 10PM",
    },
    {
      title: "Dashboard",
      description: "Dashboard management",
      userColor: "red",
      userIcon: <FaUser />,
      time: "Jan 23, 9AM - Feb 3, 10PM",
    },
  ];
  const openaddtaskModal = () => {
    setshowaddtaskModal(true);
  };

  const closeaddtaskModal = () => {
    setshowaddtaskModal(false);
  };

  return (
    <>
      <AddtaskAdminModal
        show={showaddtaskModal}
        closeModal={closeaddtaskModal}
      />
      <div className="row">
        <div className="col">
          <div
            className="card p-2 m-0"
            style={{ borderTop: "2px solid green" }}
          >
            To Do
          </div>
          <div
            className="mt-md-4 mt-2"
            style={{ height: "50vh", overflow: "auto" }}
          >
            {cardData.map((card, index) => (
              <div className="card p-2 m-0 mt-2" key={index}>
                <h6>{card.title}</h6>
                <div className="row m-0 p-0">
                  <p style={{ fontSize: "1.2rem" }} className="col m-0 p-0">
                    {card.description}
                  </p>
                  <FaUser style={{ color: card.userColor, fontSize: "2rem" }} />
                </div>
                <p className="pt-2" style={{ fontSize: ".9rem" }}>
                  {card.time}
                </p>
                <p
                  className="p-0 m-0"
                  style={{ color: "#430A5D" }}
                  onClick={openaddtaskModal}
                >
                  + ADD SUBTASK
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col">
          <div
            className="card p-2 m-0"
            style={{ borderTop: "2px solid #401F71" }}
          >
            In Progress
          </div>
          <div
            className="mt-md-4 mt-2"
            style={{ height: "50vh", overflow: "auto" }}
          >
            {cardData.map((card, index) => (
              <div className="card p-2 m-0 mt-2" key={index}>
                <h6>{card.title}</h6>
                <div className="row m-0 p-0">
                  <p style={{ fontSize: "1.2rem" }} className="col m-0 p-0">
                    {card.description}
                  </p>
                  <FaUser style={{ color: "#f2df0cd9", fontSize: "2rem" }} />
                </div>
                <p className="pt-2" style={{ fontSize: ".9rem" }}>
                  {card.time}
                </p>
                <p
                  className="p-0 m-0"
                  style={{ color: "#430A5D" }}
                  onClick={openaddtaskModal}
                >
                  + ADD SUBTASK
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col">
          <div
            className="card p-2 m-0"
            style={{ borderTop: "2px solid #FF204E" }}
          >
            Complete
          </div>
          <div
            className="mt-md-4 mt-2"
            style={{ height: "50vh", overflow: "auto" }}
          >
            {cardData.map((card, index) => (
              <div className="card p-2 m-0 mt-2" key={index}>
                <h6>{card.title}</h6>
                <div className="row m-0 p-0">
                  <p style={{ fontSize: "1.2rem" }} className="col m-0 p-0">
                    {card.description}
                  </p>
                  <FaUser style={{ color: "green", fontSize: "2rem" }} />
                </div>
                <p className="pt-2" style={{ fontSize: ".9rem" }}>
                  {card.time}
                </p>
                <p
                  className="p-0 m-0"
                  style={{ color: "#430A5D" }}
                  onClick={openaddtaskModal}
                >
                  + ADD SUBTASK
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const CalendarComponent = ({}) => {
  const [events, setEvents] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const calendarRef = useRef(null);
  const { password, username } = billpunchbasicauth;

  const getHolidaydata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmHoliday`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedHolidays = data.data.map((item) => ({
          title: item.holiday,
          start: new Date(new Date().getFullYear() + " " + item.date),
          allDay: true,
          backgroundColor: "#ffcc01",
        }));
        setEvents((prevEvents) => [...prevEvents, ...formattedHolidays]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const getAttendanceData = async (calendarApi) => {
    setTransactionLoaderState(true);
    try {
      const startDate = calendarApi.view.currentStart;
      const endDate = calendarApi.view.currentEnd;
      const startMonth = startDate.getMonth() + 1;
      const endMonth = endDate.getMonth() + 1;
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          month: startMonth,
          firmid: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeAttendanceDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const formattedEvents = data.data.map((item) => ({
          title: item.attendance_status === "Y" ? "Present" : "Absent",
          start: new Date(item.attendance_date),
          allDay: true,
          backgroundColor: item.attendance_status === "N" ? "red" : "green",
        }));
        setEvents((prevEvents) => [...prevEvents, ...formattedEvents]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getHolidaydata();
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      getAttendanceData(calendarApi);
    }
  }, []);

  return (
    <>
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <FullCalendar
          className="empcalender"
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          datesSet={(info) => getAttendanceData(info.view.calendar)}
          style={{ marginTop: 0, "margin-top": "0 !important" }}
        />
      </div>
    </>
  );
};

export const DocsComponent = ({}) => {
  const [content, setContent] = useState("");
  const { password, username } = billpunchbasicauth;

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["link", { image: "Upload Image" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["clean"],
      [{ save: "Save" }],
    ],
  };

  let quillRef;
  const handleSaveAsPDF = () => {
    console.log(handleSaveAsPDF, "doc");
    const element = document.getElementById("quill-content");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("document.pdf");
    });
  };
  const handleToolbarClick = (value) => {
    if (value === "save") {
      handleSaveAsPDF();
    }
  };

  return (
    <>
      <ReactQuill
        id="quill-content"
        ref={(el) => {
          if (el) quillRef = el;
        }}
        theme="snow"
        value={content}
        onChange={setContent}
        modules={modules}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "color",
          "background",
          "script",
          "link",
          "image",
          "list",
          "bullet",
          "indent",
          "align",
        ]}
        toolbarHandlers={{
          save: handleToolbarClick,
        }}
      />{" "}
      <div style={{ marginTop: "10px" }}>
        <button onClick={handleSaveAsPDF}>Save As PDF</button>
      </div>
    </>
  );
};
export const ListComponent = () => {
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [taskdata, setTaskData] = useState([]);
  const { password, username } = billpunchbasicauth;

  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        spaceid: propertyobject.pk_work_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];

  useEffect(() => {
    getTask();
  }, []);

  const [showaddtaskModal, setshowaddtaskModal] = useState(false);

  const openaddtaskModal = () => {
    setshowaddtaskModal(true);
  };

  const closeaddtaskModal = () => {
    setshowaddtaskModal(false);
  };

  return (
    <div className="" style={{ background: "white" }}>
      <AddTaskemployeeModal
        show={showaddtaskModal}
        closeModal={closeaddtaskModal}
        getTask={getTask}
      />

      <div className="row m-0 p-0">
        <div className="col">
          <h5>List</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <Badge
            className="d-flex align-items-center"
            style={{ border: "1px solid blue", background: "azure" }}
            onClick={openaddtaskModal}
          >
            Add Task
          </Badge>
        </div>
      </div>
      <div className="d-flex">
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Task Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Assign
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Start date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                End date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Priority
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Status
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {taskdata.map((item) => (
              <React.Fragment key={item.pk_task_id}>
                <tr style={{ border: "none", cursor: "pointer" }}>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                    // onClick={() => handleTaskClick(item.pk_task_id)}
                  >
                    {/* {selectedTaskId === item.pk_task_id ? (
                      <FaCaretDown />
                    ) : (
                      <FaCaretRight />
                    )} */}
                    {item.taskname}
                  </td>

                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.task_assignee}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {new Date(item.task_startdate).toLocaleDateString()}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {new Date(item.task_enddate).toLocaleDateString()}
                  </td>
                  <td
                    style={{
                      // padding: "8px",
                      textAlign: "start",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    <Select
                      defaultValue={options.find(
                        (option) => option.value === item.task_priority
                      )}
                      options={options}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          width: "8rem",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          width: "8rem",
                        }),
                      }}
                    />
                  </td>
                  {/* <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.status === "O" && <span>Overdue</span>}
                    {item.status === "P" && <span>Pending</span>}
                    {item.status === "C" && <span>Complete</span>}
                  </td> */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {" "}
                    {item.status === "O" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Overdue
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "C" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", background: "#fff" }}
                  >
                    <Link to="/retailer/Subtask">
                      <img
                        onClick={() => {
                          sessionStorage.setItem(
                            "taskdata",
                            JSON.stringify(item)
                          );
                        }}
                        style={{ height: "2rem" }}
                        src={require("../../../assets/images/arrow.png")}
                      />
                    </Link>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const Tasklist = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [taskdata, setTaskData] = useState([]);
  const [PriorityFilter, setPriorityFilter] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [platformFilter, setPlatformFilter] = useState(null);
  const { password, username } = billpunchbasicauth;

  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [showaddsubtaskModal, setshowaddsubtaskModal] = useState(false);
  const [showdependency, setshowdependency] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [Dependencymodal, setDependencymodal] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [dependencyTaskId, setDependencyTaskId] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  const opendependency = () => {
    setDependencymodal(true);
  };

  const closedependency = () => {
    setDependencymodal(false);
  };
  const openaddsubtaskModal = () => {
    setshowaddsubtaskModal(true);
  };
  const closeaddsubtaskModal = () => {
    setshowaddsubtaskModal(false);
  };
  const opendependencymodal = (taskId) => {
    setDependencyTaskId(taskId);
    setshowdependency(true);
    setCurrentTaskId(taskId);
  };

  const closedependencymodal = () => {
    setshowdependency(false);
  };

  const taskdataobject = JSON.parse(sessionStorage.getItem("taskdata"));
  console.log(taskdataobject, "taskdataobject");
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const fetchCategories = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ taskid: "string" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedCategories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategories(fetchedCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setCategoryFilter(selectedOption);
  };
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        spaceid: propertyobject.pk_work_id,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const [status, setstatus] = useState();
  const Updatestatus = async (spaceID, taskId) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        spaceid: spaceID,
        projectid: propertyobject.pk_project_id,
        taskid: taskId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ChangeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setstatus(data.data);
        getTask();
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchPlatforms = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ taskid: "string" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedPlatforms = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setPlatforms(fetchedPlatforms);
      }
    } catch (error) {
      console.error("Error fetching platforms:", error);
    }
  };
  const handlePlatformChange = (selectedOption) => {
    setPlatformFilter(selectedOption);
  };

  useEffect(() => {
    getTask();
    fetchPlatforms();
    fetchCategories();
  }, []);
  const { accessDataemp } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessDataemp);
  }, [accessDataemp]);
  const popoverStyle = {
    background: "#fff",
    width: "15vw",
    position: "absolute",
    zIndex: 1,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  };

  const filteredTaskData = taskdata
    .filter((item) => {
      const priorityMatch =
        !PriorityFilter ||
        PriorityFilter.value === "Clear" ||
        item.task_priority === PriorityFilter.value;
      const platformMatch =
        !platformFilter ||
        platformFilter.value === "clear" ||
        item.platform === platformFilter.label;
      const categoryMatch =
        !categoryFilter ||
        categoryFilter.value === "clear" ||
        item.category === categoryFilter.label;
      const searchMatch =
        searchQuery === "" ||
        item.taskname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.task_description
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.task_startdate.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.task_enddate.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.platform.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.task_assignee.toLowerCase().includes(searchQuery.toLowerCase());
      return priorityMatch && platformMatch && searchMatch && categoryMatch;
    })
    .sort((a, b) => new Date(b.task_startdate) - new Date(a.task_startdate));
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleEditClick = (item) => {
    setSelectedTask(item);
    setShowModal(true);
    console.log(item);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handledelete = async (taskId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this task!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");
  
          const raw = JSON.stringify({
            taskid: taskId,
            projectid: propertyobject.pk_project_id,
          });
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
  
          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DeleteTaskEmployee`,
            requestOptions
          );
          const data = await res.json();
          if (data.status === "1") {
            swal("Error", data.msg, "error");
          } else {
            swal("Success", data.msg, "success");
            setTransactionLoaderState(false);
            getTask();
          }
          handleClose();
        } catch (error) {
          console.log("Error:", error);
        }
      } else {
        swal("Your task is safe!");
      }
    });
  };
  const formatDescription = (description) => {
    return description.split('\n').map((point, index) => (
      <li key={index}>{point}</li>
    ));
  };
  const [startDateFilter, setStartDateFilter] = useState('');
const [endDateFilter, setEndDateFilter] = useState('');

  return (
    <div className="m-3" style={{ background: "" }}>
      <AddsubTask1employeeModal
        show={showaddsubtaskModal}
        closeModal={closeaddsubtaskModal}
        getSubsubTask={getTask}
        pk_work_id={propertyobject.pk_work_id}
        pk_project_id={propertyobject.pk_project_id}
      />
      <DependencyModal
        show={Dependencymodal}
        closeModal={closedependency}
        getTask={getTask}
      />
      <Dependencytable
        taskId={currentTaskId}
        show={showdependency}
        closeModal={closedependencymodal}
        dependencyTaskId={dependencyTaskId}
      />
      <div className="row">
        <Select
          placeholder="Priority"
          className="px-1"
          options={options}
          value={PriorityFilter}
          onChange={(selectedOption) => setPriorityFilter(selectedOption)}
        />
        <Select
          className="px-1"
          placeholder="Platform"
          options={[{ value: "clear", label: "Clear" }, ...platforms]}
          value={platformFilter}
          onChange={handlePlatformChange}
        />

        <Select
          className="px-1"
          placeholder="Category"
          options={[{ value: "clear", label: "Clear" }, ...categories]}
          value={categoryFilter}
          onChange={handleCategoryChange}
        />

        <div className="col">
          <input
            className="form-control"
            type="text"
            placeholder="Search Task..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        
        <div className="col-md-5 col-3 d-flex justify-content-end">
          {" "}
          {accessDataemp &&
            accessDataemp.map(
              (module) =>
                module.data &&
                module.data.map((role) =>
                  role.role_name === "Create Task" ? (
                    role.access === "Y" ? (
                      <span key={role.pk_role_id}>
                        <Badge
                          className="d-flex  align-items-center"
                          style={{
                            border: "1px solid blue",
                            background: "azure",
                            height: "2rem",
                          }}
                          onClick={openaddsubtaskModal}
                        >
                          Add Task
                        </Badge>
                      </span>
                    ) : (
                      <span key={role.pk_role_id}>
                        <button
                          className="btn btn-sm btn-primary d-flex justify-content-center"
                          style={{
                            color: "#fff",
                            height: "2rem",
                            border: "none",
                            borderRadius: ".4rem",
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                          disabled
                        >
                          Add Task
                        </button>
                      </span>
                    )
                  ) : null
                )
            )}
        </div>
      </div>

      <div
        className="table-responsive "
        style={{
          borderRadius: ".4em",
          border: "1px solid lightgrey",
        }}
      >
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, background: "#fff" }}>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                  width: "45%",
                }}
              >
                Description
              </th>

              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Date
              </th>

              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Assignee
              </th>

              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Status
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTaskData.length === 0 ? (
              <tr>
                <td
                  colSpan="6"
                  className="text-center"
                  style={{ padding: "8px" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              filteredTaskData.map((item) => (
                <React.Fragment key={item.pk_task_id}>
                  <tr
                    style={{
                      border: "none",
                      cursor: "pointer",
                      background: "#fff",
                    }}
                  >
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {item.task_priority === "High" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "#f4c430" }}
                        />
                      )}
                      {item.task_priority === "Urgent" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "maroon" }}
                        />
                      )}
                      {item.task_priority === "Normal" && (
                        <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                      )}
                      {item.task_priority === "Low" && (
                        <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                      )}
                      {item.task_priority === "Clear" && (
                        <img
                          src={require("../../../assets/images/flag_1.png")}
                          style={{ marginRight: "5px", width: ".8rem" }}
                          alt="Clear Flag"
                        />
                      )}
                      {item.taskname} &nbsp;
                      {item.dependency_status === "Y" && (
                        <span
                          className="p-1"
                          style={{
                            color: "#135D66",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SiAdblock
                            style={{ fontSize: "1.4rem" }}
                            onClick={() => {
                              console.log(item.pk_taskid, "dependencytask id");
                              opendependencymodal(item.pk_taskid);
                            }}
                          />
                        </span>
                      )}
                      ({item.platform})
                      <br />
                      <br />
                      <Link
                        to="/retailer/Subtask"
                        onClick={() => {
                          sessionStorage.setItem(
                            "taskdetails",
                            JSON.stringify(item)
                          );
                        }}
                      >
                        <ul>
                    {formatDescription(item.task_description)}
                  </ul>
                      </Link>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {new Date(item.task_startdate).getDate()}{" "}
                      {new Date(item.task_startdate).toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      - {new Date(item.task_enddate).getDate()}{" "}
                      {new Date(item.task_enddate).toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      {new Date(item.task_enddate).getFullYear()}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {item.task_assignee}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.status === "O" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Overdue
                        </span>
                      )}
                      {item.status === "P" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: "#f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "C" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Complete
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                        onClick={() =>
                          Updatestatus(item.fk_spaceid, item.pk_taskid)
                        }
                      >
                        <IoCheckmarkDoneCircle
                          style={{ fontSize: "1.5rem", color: "green" }}
                        />
                        <span className="adminemp-tooltip-text">
                          Mark Complete
                        </span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                      >
                        <GrEdit
                          style={{ fontSize: "1.2rem", color: "#6482AD" }}
                          onClick={() => handleEditClick(item)}
                        />
                        <span className="adminemp-tooltip-text">Edit</span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                      >
                        <MdDeleteForever
                          style={{ fontSize: "1.5rem", color: "red" }}
                          onClick={() =>
                            handledelete( item.pk_taskid)
                          }
                        />
                        <span className="adminemp-tooltip-text">Delete</span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                        onClick={() => {
                          sessionStorage.setItem(
                            "dependencytaskid",
                            JSON.stringify(item)
                          );
                          opendependency();
                        }}
                      >
                        <IoMdPersonAdd
                          style={{ fontSize: "1.5rem", color: "green" }}
                        />
                        <span className="adminemp-tooltip-text">
                          Add
                          <br /> Dependency
                        </span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                        className="adminemp-tooltip-container"
                        onClick={() => opendependencymodal(item.pk_taskid)}
                      >
                        <AiFillEye
                          style={{ fontSize: "1.5rem", color: "#6482AD" }}
                        />
                        <span className="adminemp-tooltip-text">
                          View <br />
                          Dependency
                        </span>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      <EditTaskModal
        showModal={showModal}
        handleClose={handleClose}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        pk_project_id={propertyobject.pk_project_id}
        pk_work_id={propertyobject.pk_work_id}
        getTask={getTask}
      />
    </div>
  );
};
export const SubTasklist = () => {
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [taskdata, setTaskData] = useState([]);
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        taskid: taskdetails.pk_taskid,
        platformid: "1",
        categoryid: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetSubTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getTask();
  }, []);

  return (
    <div>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        {" "}
        <thead
          style={{
            position: "sticky",
            top: 0,
            // zIndex: 1,
            background: "#fff",
          }}
        >
          <tr>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "left",
                background: "#fff",
              }}
            >
              Task Name
            </th>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "left",
                background: "#fff",
              }}
            >
              Assignee
            </th>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "center",
                background: "#fff",
              }}
            >
              Status
            </th>
          </tr>
        </thead>
        {taskdata.length === 0 ? (
          <tr>
            <td colSpan="3" style={{ textAlign: "center", padding: "8px" }}>
              No data found
            </td>
          </tr>
        ) : (
          <tbody>
            {taskdata.slice(0, 5).map((item, index) => (
              <React.Fragment key={item.pk_task_id}>
                <tr key={item.pk_task_id}>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {" "}
                    {item.priority === "High" && (
                      <FaFlag
                        style={{ marginRight: "5px", color: "#f4c430" }}
                      />
                    )}
                    {item.priority === "Urgent" && (
                      <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
                    )}
                    {item.priority === "Normal" && (
                      <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                    )}
                    {item.priority === "Low" && (
                      <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                    )}
                    {item.priority === "Clear" && (
                      <img
                        src={require("../../../assets/images/flag_1.png")}
                        style={{ marginRight: "5px", width: ".8rem" }}
                      />
                    )}
                    {item.sub_name} &nbsp;
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {item.sub_empname} &nbsp;
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {" "}
                    {item.status === "O" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Overdue
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "C" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      <div className="d-flex justify-content-center">
        <Link to="/retailer/Subtasks">
          <FcPrevious />
          View More
          <FcNext />
        </Link>
      </div>
    </div>
  );
};
export const SubTasklistAdmin = () => {
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [taskdata, setTaskData] = useState([]);
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        taskid: taskdetails.pk_taskid,
        platformid: "1",
        categoryid: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetSubTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getTask();
  }, []);

  return (
    <div>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        {" "}
        <thead
          style={{
            position: "sticky",
            top: 0,
            // zIndex: 1,
            background: "#fff",
          }}
        >
          <tr>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "left",
                background: "#fff",
              }}
            >
              Task Name
            </th>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "left",
                background: "#fff",
              }}
            >
              Assignee
            </th>
            <th
              style={{
                borderBottom: "1px solid #f2f4f2",
                color: "#000",
                padding: "8px",
                textAlign: "center",
                background: "#fff",
              }}
            >
              Status
            </th>
          </tr>
        </thead>
        {taskdata.length === 0 ? (
          <div className="d-flex justify-content-center">
            <h6>No data found</h6>
          </div>
        ) : (
          <tbody>
            {taskdata.slice(0, 5).map((item, index) => (
              <React.Fragment key={item.pk_task_id}>
                <tr key={item.pk_task_id}>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {" "}
                    {item.priority === "High" && (
                      <FaFlag
                        style={{ marginRight: "5px", color: "#f4c430" }}
                      />
                    )}
                    {item.priority === "Urgent" && (
                      <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
                    )}
                    {item.priority === "Normal" && (
                      <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                    )}
                    {item.priority === "Low" && (
                      <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                    )}
                    {item.priority === "Clear" && (
                      <img
                        src={require("../../../assets/images/flag_1.png")}
                        style={{ marginRight: "5px", width: ".8rem" }}
                      />
                    )}
                    {item.sub_name} &nbsp;
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {item.sub_empname} &nbsp;
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontSize: ".9rem",
                      filter: index === 4 ? "blur(2px)" : "none",
                    }}
                  >
                    {" "}
                    {item.status === "O" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Overdue
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "C" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      <div className="d-flex justify-content-center">
        {/* <Link to="/retailer/Subtasks"> */}
        <FcPrevious />
        View More
        <FcNext />
        {/* </Link> */}
      </div>
    </div>
  );
};
// Old Flow
export const Leaveapproval = ({}) => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Employeeid, setEmployeeid] = useState(-1);
  const { password, username } = billpunchbasicauth;

  const getHolidayReport = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeLeave`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleApprove = (id) => {
    // Add logic to handle approval
    console.log(`Leave with ID ${id} approved`);
  };

  const handleReject = (id) => {
    // Add logic to handle rejection
    console.log(`Leave with ID ${id} rejected`);
  };

  useEffect(() => {
    getHolidayReport();
  }, [Employeeid]);

  return (
    <>
      <div className="row ">
        <div className="col d-flex align-items-center">
          <h5 className="mx-md-4 mx-sm-2">Leave Approval Report</h5>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table m-1"
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Category</th>
              <th style={{ color: "#0074D9" }}>Name</th>
              <th style={{ color: "#0074D9" }}>From</th>
              <th style={{ color: "#0074D9" }}> ToDate </th>
              <th style={{ color: "#0074D9" }}>Reason</th>
              <th style={{ color: "#0074D9" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data1.map((item) => (
              <tr key={item.id}>
                <td>{item.department}</td>
                <td>{item.name}</td>
                <td>{item.fromdate}</td>
                <td>{item.todate}</td>
                <td>{item.reason}</td>
                <td
                  className="text-center align-middle"
                  style={{ border: "none", fontSize: "1rem" }}
                >
                  <div className="d-flex justify-content-around align-items-center">
                    <button
                      type="button"
                      className="  btn btn-sm btn-success d-flex justify-content-center align-items-center "
                      style={{ height: "2rem", borderRadius: ".3rem" }}
                      onClick={() => handleApprove(item.id)}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                      style={{ height: "2rem", borderRadius: ".3rem" }}
                      onClick={() => handleReject(item.id)}
                    >
                      Reject
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
// Admin old flow
export const SalaryGenerateAdmin = () => {
  const [data, setData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Details, setDetails] = useState(null);
  const [department, setDepartment] = useState("");
  const [selectedMonths, setSelectedMonths] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [searchQuery, setSearchQuery] = useState("");
  const [AttandenceModalShow, setAttandenceModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const { password, username } = billpunchbasicauth;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleGenerateButtonClick = async (id) => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/generateEmployeeSalary`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }

    setTransactionLoaderState(false);
  };

  // const handleAttendanceButtonClick = (empid) => {
  //   setDetails(empid);
  //   setAttandenceModalShow(true);
  // };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleViewButtonClick = (id) => {
    setDetails({ id });
    setViewModalShow(true);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        // Set the department data received from the API in the state
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  function getCurrentMonth() {
    const now = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[now.getMonth()];
  }
  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
  }, []);
  const filteredData = data.filter((item) => {
    if (department === "") {
      return true;
    } else {
      return item.department === department;
    }
  });
  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row mt-md-1 mt-sm-1 mx-sm-2 p-0">
              <div className="col-6 col-sm-4">
                <h5
                  className="d-flex align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Employee Salary
                </h5>
              </div>
              <div className="col-6 col-sm-8 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    width: "30% ",
                    border: "1px #ced4da solid",
                    paddingLeft: "10px",
                    height: "2rem",
                  }}
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />

                <div className="px-2">
                  <select
                    style={{
                      width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="px-2">
                  <select
                    style={{
                      width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      console.log("Selected Department:", e.target.value); // Add this line to debug
                      getEmployeeList(e.target.value);
                    }}
                  >
                    <option value="">All Department</option>
                    {departmentData.map((dept) => (
                      <option key={dept.id} value={dept.cat1}>
                        {dept.cat1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <hr className="p-0 mx-md-1" />
            <table
              className="m-md-2"
              style={{
                textAlign: "center",
                width: "-webkit-fill-available",
                overflow: "hidden",
              }}
            >
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Designation
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Department
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Mobile
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {filteredData.map((item, index) => {
                  const fullName =
                    `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} }`.toLowerCase();
                  if (
                    !searchQuery ||
                    fullName.includes(searchQuery.toLowerCase())
                  ) {
                    return (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #ced4da" }}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.empcode}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.firstname} {item.lastname}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.designation}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.department}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.mobile}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          <div className="d-flex justify-content-around align-items-center">
                            {/* <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                                fontSize: ".8rem",
                              }}
                              onClick={() =>
                                handleAttendanceButtonClick(item.empid)
                              }
                            >
                              Attendance
                            </Badge> */}
                            <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                              }}
                              onClick={() => handleGenerateButtonClick(item.id)}
                            >
                              Generate
                            </Badge>
                            <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                              }}
                              onClick={() => handleViewButtonClick(item.id)}
                            >
                              View
                            </Badge>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>

            <ViewModalAdmin
              show={viewModalShow}
              handleClose={() => setViewModalShow(false)}
              Details={Details}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export const SalaryProceedAdmin = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [employees, setEmployees] = useState([
    {
      id: 1,
      EmpCode: "E001",
      Name: "Deepak",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 2,
      EmpCode: "E002",
      Name: "Simran",
      Grosssalary: 5000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 3,
      EmpCode: "E003",
      Name: "Hemant",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 4,
      EmpCode: "E004",
      Name: "Shweta",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 5,
      EmpCode: "E005",
      Name: "Sapna",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
  ]);
  const handleRowCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAllCheckboxChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allRowIds = employees.map((employee) => employee.id);
      setSelectedRows(allRowIds);
    }
    setSelectAll(!selectAll);
  };
  const calculateFinalSalary = (salary, leave) => {
    const salaryPerDay = salary / 30;
    const deduction = salaryPerDay * leave;
    return salary - deduction.toFixed(2);
  };
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredEmployees = employees.filter((employee) =>
    employee.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    const isAllSelected =
      employees.length > 0 && selectedRows.length === employees.length;
    setSelectAll(isAllSelected);
  }, [selectedRows, employees]);
  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <div className="col-6">
                <h5
                  className="d-flex ml-md-3 align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Proceed to Pay
                </h5>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    height: "2.4rem",
                    width: "60% ",
                    border: "1px #ced4da solid",
                  }}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
                <span style={{ marginLeft: "10px" }}>
                  <Badge
                    type="button"
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2.4rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "8rem",
                    }}
                  >
                    Proceed All
                  </Badge>
                </span>
              </div>
            </div>

            <hr className="p-0 mx-md-1" />
            <table
              className="m-md-2"
              style={{
                textAlign: "center",
                width: "-webkit-fill-available",
                overflow: "hidden",
              }}
            >
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Gross salary
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Leaves
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    TotalDays
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Absent
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    FinalSalary
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Salary Proceed
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {filteredEmployees.map((item, index) => (
                  <tr key={index} style={{ borderBottom: "1px solid #ced4da" }}>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowCheckboxChange(item.id)}
                      />
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.EmpCode}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Name}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Grosssalary}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Leaves}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.TotalDays}
                    </td>{" "}
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Absent}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {calculateFinalSalary(item.Grosssalary, item.Leaves)}
                    </td>
                    <td
                      className="text-center align-middle d-flex justify-content-center"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      <Badge
                        type="button"
                        className="btn btn-sm  d-flex align-items-center justify-content-center"
                        style={{
                          background: " rgba(43, 193, 85, 0.10)",
                          color: "#2bc155",
                          border: "1px solid rgba(43, 193, 85, 0.50)",
                          width: "7rem",
                          height: "2rem",
                          borderRadius: ".4rem",
                        }}
                      >
                        Proceed
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export const AdminSend = ({}) => {
  const query = useQuery();
  const id = query.get("id");
  const dummyData = [
    {
      id: 1,
      name: " Abhijeet",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 2,
      name: " Sumit",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
    {
      id: 3,
      name: "John ",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 4,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
  ];

  return (
    <>
      <div className="" style={{ background: "white" }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Name
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Employee ID
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  {" "}
                  Subject{" "}
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Date
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dummyData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.employeeId}</td>
                  <td>{item.subject}</td>
                  <td>{item.date}</td>
                  <td>
                    <Link>
                      <img
                        alt=""
                        style={{ height: "2.5rem" }}
                        src={require("../../../assets/images/pms/Delete_icon.svg")}
                        // onClick={() => deleteHoliday(item.pk_leave_id)}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export const AdminReceive = ({}) => {
  const query = useQuery();
  const id = query.get("id");
  const dummyData = [
    {
      id: 1,
      name: "John Doe",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 2,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
    {
      id: 3,
      name: "John Doe",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 4,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
  ];

  return (
    <>
      <div className="" style={{ background: "white" }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <thead>
              <tr>
                {" "}
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Name
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Employee ID
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  {" "}
                  Subject{" "}
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Date
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dummyData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.employeeId}</td>
                  <td>{item.subject}</td>
                  <td>{item.date}</td>
                  <td>
                    <Link>
                      <img
                        alt=""
                        style={{ height: "2.5rem" }}
                        src={require("../../../assets/images/pms/Delete_icon.svg")}
                        // onClick={() => deleteHoliday(item.pk_leave_id)}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

// HR old flow
export const HRReceive = ({}) => {
  const query = useQuery();
  const id = query.get("id");
  const dummyData = [
    {
      id: 1,
      name: "John Doe",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 2,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
    {
      id: 3,
      name: "John Doe",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 4,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
  ];

  return (
    <>
      <div className="" style={{ background: "white" }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <thead>
              <tr>
                {" "}
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Name
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Employee ID
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  {" "}
                  Subject{" "}
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Date
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dummyData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.employeeId}</td>
                  <td>{item.subject}</td>
                  <td>{item.date}</td>
                  <td>
                    <Link>
                      <img
                        alt=""
                        style={{ height: "2.5rem" }}
                        src={require("../../../assets/images/pms/Delete_icon.svg")}
                        // onClick={() => deleteHoliday(item.pk_leave_id)}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export const HRSend = ({}) => {
  const query = useQuery();
  const id = query.get("id");
  const dummyData = [
    {
      id: 1,
      name: " Abhijeet",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 2,
      name: " Sumit",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
    {
      id: 3,
      name: "John ",
      employeeId: "EMP001",
      subject: "Regarding Project Update",
      date: "2023-01-01",
    },
    {
      id: 4,
      name: "Jane Smith",
      employeeId: "EMP002",
      subject: "Leave Request",
      date: "2023-02-15",
    },
  ];

  return (
    <>
      <div className="" style={{ background: "white" }}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
              borderRadius: ".4rem",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Name
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Employee ID
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  {" "}
                  Subject{" "}
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Date
                </th>
                <th style={{ color: "#0074D9", background: "aliceblue" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dummyData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.employeeId}</td>
                  <td>{item.subject}</td>
                  <td>{item.date}</td>
                  <td>
                    <Link>
                      <img
                        alt=""
                        style={{ height: "2.5rem" }}
                        src={require("../../../assets/images/pms/Delete_icon.svg")}
                        // onClick={() => deleteHoliday(item.pk_leave_id)}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export const SalaryGenerateHR = () => {
  const [data, setData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Details, setDetails] = useState(null);
  const [department, setDepartment] = useState("");
  const [selectedMonths, setSelectedMonths] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [searchQuery, setSearchQuery] = useState("");
  const [AttandenceModalShow, setAttandenceModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const { password, username } = billpunchbasicauth;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const handleGenerateButtonClick = async (empid) => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/generateEmployeeSalary`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }

    setTransactionLoaderState(false);
  };

  // const handleAttendanceButtonClick = (empid) => {
  //   setDetails(empid);
  //   setAttandenceModalShow(true);
  // };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleViewButtonClick = (id) => {
    setDetails({ id });
    setViewModalShow(true);
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        // Set the department data received from the API in the state
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  function getCurrentMonth() {
    const now = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[now.getMonth()];
  }
  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
  }, []);
  const filteredData = data.filter((item) => {
    if (department === "") {
      return true;
    } else {
      return item.department === department;
    }
  });
  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row mt-md-1 mt-sm-1 mx-sm-2 p-0">
              <div className="col-6 col-sm-4">
                <h5
                  className="d-flex align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Employee Salary
                </h5>
              </div>
              <div className="col-6 col-sm-8 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    width: "30% ",
                    border: "1px #ced4da solid",
                    paddingLeft: "10px",
                    height: "2rem",
                  }}
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />

                <div className="px-2">
                  <select
                    style={{
                      width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="px-2">
                  <select
                    style={{
                      width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      console.log("Selected Department:", e.target.value); // Add this line to debug
                      getEmployeeList(e.target.value);
                    }}
                  >
                    <option value="">All Department</option>
                    {departmentData.map((dept) => (
                      <option key={dept.id} value={dept.cat1}>
                        {dept.cat1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <hr className="p-0 mx-md-1" />
            <table
              className="m-md-2"
              style={{
                textAlign: "center",
                width: "-webkit-fill-available",
                overflow: "hidden",
              }}
            >
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Designation
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Department
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Mobile
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {filteredData.map((item, index) => {
                  const fullName =
                    `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} }`.toLowerCase();
                  if (
                    !searchQuery ||
                    fullName.includes(searchQuery.toLowerCase())
                  ) {
                    return (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #ced4da" }}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.empcode}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.firstname} {item.lastname}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.designation}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.department}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.mobile}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          <div className="d-flex justify-content-around align-items-center">
                            {/* <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                                fontSize: ".8rem",
                              }}
                              onClick={() =>
                                handleAttendanceButtonClick(item.empid)
                              }
                            >
                              Attendance
                            </Badge> */}
                            <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                              }}
                              onClick={() =>
                                handleGenerateButtonClick(item.empid)
                              }
                            >
                              Generate
                            </Badge>
                            <Badge
                              type="button"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                              style={{
                                color: "#fff",
                                width: "7rem",
                                height: "2rem",
                                borderRadius: ".4rem",
                              }}
                              onClick={() => handleViewButtonClick(item.id)}
                            >
                              View
                            </Badge>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>

            <ViewModalHR
              show={viewModalShow}
              handleClose={() => setViewModalShow(false)}
              Details={Details}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export const SalaryProceedHR = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [employees, setEmployees] = useState([
    {
      id: 1,
      EmpCode: "E001",
      Name: "Deepak",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 2,
      EmpCode: "E002",
      Name: "Simran",
      Grosssalary: 5000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 3,
      EmpCode: "E003",
      Name: "Hemant",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 4,
      EmpCode: "E004",
      Name: "Shweta",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
    {
      id: 5,
      EmpCode: "E005",
      Name: "Sapna",
      Grosssalary: 80000,
      Leaves: 2,
      TotalDays: 12,
      Absent: 20,
      FinalSalary: 20000,
    },
  ]);
  const handleRowCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAllCheckboxChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allRowIds = employees.map((employee) => employee.id);
      setSelectedRows(allRowIds);
    }
    setSelectAll(!selectAll);
  };
  const calculateFinalSalary = (salary, leave) => {
    const salaryPerDay = salary / 30;
    const deduction = salaryPerDay * leave;
    return salary - deduction.toFixed(2);
  };
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredEmployees = employees.filter((employee) =>
    employee.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    const isAllSelected =
      employees.length > 0 && selectedRows.length === employees.length;
    setSelectAll(isAllSelected);
  }, [selectedRows, employees]);
  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <div className="col-6">
                <h5
                  className="d-flex ml-md-3 align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Proceed to Pay
                </h5>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    height: "2.4rem",
                    width: "60% ",
                    border: "1px #ced4da solid",
                  }}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
                <span style={{ marginLeft: "10px" }}>
                  <Badge
                    type="button"
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2.4rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "8rem",
                    }}
                  >
                    Proceed All
                  </Badge>
                </span>
              </div>
            </div>

            <hr className="p-0 mx-md-1" />
            <table
              className="m-md-2"
              style={{
                textAlign: "center",
                width: "-webkit-fill-available",
                overflow: "hidden",
              }}
            >
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Gross salary
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Leaves
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    TotalDays
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Absent
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    FinalSalary
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Salary Proceed
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {filteredEmployees.map((item, index) => (
                  <tr key={index} style={{ borderBottom: "1px solid #ced4da" }}>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowCheckboxChange(item.id)}
                      />
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.EmpCode}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Name}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Grosssalary}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Leaves}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.TotalDays}
                    </td>{" "}
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {item.Absent}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      {calculateFinalSalary(item.Grosssalary, item.Leaves)}
                    </td>
                    <td
                      className="text-center align-middle d-flex justify-content-center"
                      style={{ border: "none", fontSize: "1rem" }}
                    >
                      <Badge
                        type="button"
                        className="btn btn-sm  d-flex align-items-center justify-content-center"
                        style={{
                          background: " rgba(43, 193, 85, 0.10)",
                          color: "#2bc155",
                          border: "1px solid rgba(43, 193, 85, 0.50)",
                          width: "7rem",
                          height: "2rem",
                          borderRadius: ".4rem",
                        }}
                      >
                        Proceed
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const Epfadmin = () => {
  const history = useHistory();
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [epfdata, setepfdata] = useState("");
  const Getepf = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEpfDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setepfdata(data.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getepf();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowprevModal = () => setShowPreviewModal(true);
  const handleCloseprevModal = () => setShowPreviewModal(false);

  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const handleDisableEPFClick = () => {
    setShowPopover(!showPopover);
  };

  const handleCancel = () => {
    setShowPopover(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DisableEpfData`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        swal("Success", data.msg, "success");
        Getepf();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const blurStyle = showPopover
    ? { filter: "blur(5px)", transition: "filter 0.3s ease-in-out" }
    : {};

  return (
    <>
      <Viewsepfspilit show={showModal} handleClose={handleCloseModal} />
      <Viewprviewepfcalcilation
        show={showPreviewModal}
        handleClose={handleCloseprevModal}
      />
      {epfdata.status === "Y" ? (
        <div className="row" style={blurStyle}>
          <div className="col-md-7 col-12 p-0">
            <h5 className="px-2">
              Employees' Provident Fund &nbsp; &nbsp;
              <CiEdit
                onClick={() => {
                  history.push("/retailer/Epf_form_admin");
                }}
              />
            </h5>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  EPF Number
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  <b>{epfdata?.epf_no}</b>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Deduction Cycle
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {epfdata?.deduction_cycle}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Employee Contribution Rate
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {epfdata?.employee_contribution_rate}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Employer Contribution Rate
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {epfdata?.employer_contribution_rate}{" "}
                  <Link onClick={handleShowModal}>View split</Link>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  CTC Inclusions
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {epfdata?.include_employer_ctc === "Y" ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}{" "}
                  Employer's contribution is included in the CTC <br />
                  {epfdata?.include_employer_edli === "Y" ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}{" "}
                  Employer's EDLI contribution not included in CTC <br />
                  {epfdata?.include_admin_charges_ctc === "Y" ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}{" "}
                  Admin charges are not included in the CTC
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Allow Employee level Override
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {" "}
                  {epfdata?.override_pf === "Y" ? (
                    <span style={{ color: "green" }}>Yes</span>
                  ) : (
                    <span style={{ color: "red" }}>NO</span>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Pro-rate Restricted PF Wage
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {" "}
                  {epfdata?.pro_rate_pf === "Y" ? (
                    <span style={{ color: "green" }}>Yes</span>
                  ) : (
                    <span style={{ color: "red" }}>NO</span>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Consider applicable salary <br /> components based on LOP
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {" "}
                  {epfdata?.pf_wage_less_15000 === "Y" ? (
                    <span style={{ color: "green" }}>Yes</span>
                  ) : (
                    <span style={{ color: "red" }}>NO</span>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Eligible for ABRY Scheme
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  {" "}
                  {epfdata?.arby_scheme === "Y" ? (
                    <span style={{ color: "green" }}>Yes</span>
                  ) : (
                    <span style={{ color: "red" }}>NO</span>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <span
                style={{ color: "cornflowerblue", cursor: "pointer" }}
                ref={target}
                onClick={handleDisableEPFClick}
              >
                {" "}
                <MdDeleteOutline /> &nbsp;Disable EPF
              </span>
            </div>

            <Overlay target={target.current} show={showPopover} placement="top">
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  className=""
                  {...props}
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: 3,
                    boxShadow: "0 0 0.5rem rgba(0,0,0,0.1)",
                    ...props.style,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "27%",
                  }}
                >
                  <p>
                    If your organisation has 20 or more employees, it is
                    necessary that you register for the EPF scheme. Are you sure
                    you want to disable EPF for this organisation?
                  </p>
                  <Button
                    className="btn-sm"
                    onClick={handleSave}
                    style={{ background: "cornflower" }}
                  >
                    Yes, Proceed
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="secondary"
                    onClick={handleCancel}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Overlay>
          </div>
          <div className="col-md-5 col-12">
            <div
              className="card p-3"
              style={{ background: "#FBFCFF", borderTop: "2px solid #4E98FF" }}
            >
              <h6>Sample EPF Calculation</h6>
              <p>
                Let's assume the PF wage is ₹ 20,000. The breakup of
                contribution will be:
              </p>
              <div className="card p-3">
                <p>
                  <b>Employee's Contribution</b>
                </p>
                {epfdata.employee_contribution_rate ===
                "12% of Actual PF Wage" ? (
                  <>
                    <div className="row p-0">
                      <div className="col">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 20000)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹2400</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row p-0">
                      <div className="col">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 15000)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1800</p>
                      </div>
                    </div>
                  </>
                )}
                <hr className="mt-2 mb-2" />
                <p>
                  <b>Employer Contribution</b>
                </p>
                {epfdata.employer_contribution_rate ===
                "12% of Actual PF Wage" ? (
                  <>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPS (8.33% of 20000 (Max of ₹ 15,000))
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1250</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 20000 - EPS)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1150</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPS (8.33% of 15000 (Max of ₹ 15,000))
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1250</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 15000 - EPS)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹550</p>
                      </div>
                    </div>
                  </>
                )}

                <hr className="m-1" />
                <div className="row p-0">
                  <div className="col-9">
                    <div style={{ fontSize: "1rem" }}>
                      <b>Total</b>{" "}
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <p>
                      {(() => {
                        let total = 0;
                        if (
                          epfdata.employee_contribution_rate ===
                          "12% of Actual PF Wage"
                        ) {
                          total += 2400;
                        } else {
                          total += 1800;
                        }

                        if (
                          epfdata.employer_contribution_rate ===
                          "12% of Actual PF Wage"
                        ) {
                          total += 2400;
                        } else {
                          total += 1800;
                        }
                        return `₹${total}`;
                      })()}
                    </p>
                  </div>
                </div>
              </div>
              <p className="pt-2 pb-2">
                Do you want to preview EPF calculation for multiple cases, based
                on the preferences you have configured ?
              </p>
              <Link onClick={handleShowprevModal}>
                <FaEye /> &nbsp;Preview EPF Calculation
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-7">
            <h5 className="text-center">Are you registered for EPF?</h5>
            <p className="text-center">
              Any organisation with 20 or more employees must register for the
              Employee Provident Fund (EPF) scheme, a retirement benefit plan
              for all salaried employees.
            </p>
            <div d-flex className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  history.push("/retailer/Epf_form_admin");
                }}
                style={{ background: "cornflower" }}
              >
                Enable EPF
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Esiadmin = ({}) => {
  const history = useHistory();
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [esidata, setesidata] = useState("");
  const Getesi = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEsicDetail`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setesidata(data.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getesi();
  }, []);

  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const handleDisableEPFClick = () => {
    setShowPopover(!showPopover);
  };

  const handleCancel = () => {
    setShowPopover(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DisableEsicData`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        swal("Success", data.msg, "success");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const blurStyle = showPopover
    ? { filter: "blur(5px)", transition: "filter 0.3s ease-in-out" }
    : {};
  return (
    <>
      {esidata.status === "Y" ? (
        <div className="row" style={blurStyle}>
          <div className="col-md-7 col-12 p-0">
            <h5 className="px-2">
              Employees' State Insurance &nbsp; &nbsp;
              <CiEdit
                onClick={() => {
                  history.push("/retailer/Esi_form_admin");
                }}
              />
            </h5>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  ESI Number
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem" }}>
                  <b>{esidata?.esic_number}</b>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Deduction Cycle
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem", fontWeight: "400" }}>
                  {esidata?.deduction_cycle}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Employees' Contribution
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem", fontWeight: "400" }}>
                  {esidata?.employee_contribution}
                </div>
              </div>
            </div>

            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Employer's Contribution
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem", fontWeight: "400" }}>
                  {esidata?.employer_contribution}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-5 col-8 p-0">
                <div style={{ fontSize: ".9rem", color: "#666" }}>
                  Other Details
                </div>
              </div>
              <div className="col-md-7 col-8 p-0">
                <div style={{ fontSize: ".9rem", fontWeight: "400" }}>
                  {esidata?.include_in_ctc === "Y"
                    ? "Employer's contribution is included in the CTC"
                    : ""}
                </div>
              </div>
              <div className="row">
                <span
                  style={{ color: "cornflowerblue", cursor: "pointer" }}
                  ref={target}
                  onClick={handleDisableEPFClick}
                >
                  {" "}
                  <MdDeleteOutline /> &nbsp;Disable ESI
                </span>
              </div>
              <Overlay
                target={target.current}
                show={showPopover}
                placement="top"
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <div
                    className=""
                    {...props}
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      padding: "1rem",
                      borderRadius: 3,
                      boxShadow: "0 0 0.5rem rgba(0,0,0,0.1)",
                      ...props.style,
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      height: "25%",
                    }}
                  >
                    <p>
                      If your organisation has 10 or more employees, it is
                      necessary that you register for the ESI scheme. Are you
                      sure you want to disable ESI for this organisation?
                    </p>
                    <Button
                      className="btn-sm"
                      onClick={handleSave}
                      style={{ background: "cornflower" }}
                    >
                      Yes, Proceed
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="secondary"
                      onClick={handleCancel}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Overlay>
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-7">
            <h5 className="text-center">Are you registered for EPF?</h5>
            <p className="text-center">
              Organisations having 10 or more employees must register for
              Employee State Insurance (ESI). This scheme provides cash
              allowances and medical benefits for employees whose monthly salary
              is less than ₹21,000.
            </p>
            <div d-flex className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  history.push("/retailer/Esi_form_admin");
                }}
                style={{ background: "cornflower" }}
              >
                Enable ESI
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export const Professionaltaxadmin = ({}) => {
  return (
    <>
      <div className="row d-flex justify-content-start">
        <div className="col-8">
          <h5 className="">Professional Tax</h5>
          <p className="">
            This tax is levied on an employee’s income by the State Government.
            Tax slabs differ in each state.
          </p>
          <div
            className="card col-7 p-2"
            style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1) " }}
          >
            <h6>Head Office</h6>
            <div className="m-2 p-2" style={{ border: "2px dashed #cccccc" }}>
              <div className="row">
                <div className="col-1">
                  <FaBan style={{ color: "#999", fontSize: "1.5rem" }} />
                </div>
                <div className="col">
                  <p style={{ color: "#999" }}>
                    {" "}
                    Professional Tax is not applicable for your work location in
                    Uttar Pradesh
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Labourwelfarefund = ({}) => {
  return (
    <>
      <div className="row d-flex justify-content-start">
        <div className="col-8">
          <h5 className="">Labour Welfare Fund</h5>
          <p className="">
            Labour Welfare Fund act ensures social security and improves working
            conditions for employees.
          </p>
          <div
            className="card col-7 p-2"
            style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1) " }}
          >
            <h6>Uttar Pradesh</h6>
            <div className="m-2 p-2" style={{ border: "2px dashed #cccccc" }}>
              <div className="row">
                <div className="col-1">
                  <FaBan style={{ color: "#999", fontSize: "1.5rem" }} />
                </div>
                <div className="col">
                  <p style={{ color: "#999" }}>
                    {" "}
                    Labour Welfare Fund is not applicable for Uttar Pradesh.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Statutorybonus = ({}) => {
  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-7">
          <h5 className="text-center">
            Are your employees eligible to receive statutory bonus?
          </h5>
          <p className="text-center">
            According to the Payment of Bonus Act, 1965, an eligible employee
            can receive a statutory bonus of 8.33% (min) to 20% (max) of their
            salary earned during a financial year. Configure statutory bonus of
            your organisation and start paying your employees.
          </p>
          <div d-flex className="d-flex justify-content-center">
            <Button
              // onClick={() => {
              //   history.push("/retailer/Epf_form_admin");
              // }}
              style={{ background: "cornflower" }}
            >
              Enable Statutory Bonus
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export const Adminearnings = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [Earningdata, setEarningdata] = useState([]);
  const history = useHistory();
  const Getearningsalarycomponent = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        salary_id: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSalaryComponents`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEarningdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getearningsalarycomponent();
  }, []);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const updateSalaryComponentStatus = async (salaryId, active) => {
    const result = await swal({
      title: `Are you sure to mark this as ${
        active === "Y" ? "active" : "inactive"
      }?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (result) {
      setTransactionLoaderState(true);
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              Buffer.from(username + ":" + password).toString("base64"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firmid: user.firm_id,
            salaryid: salaryId,
            active: active,
          }),
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/UpdateSalaryComponentsStatus`,
          requestOptions
        );
        const data = await res.json();
        console.log(data);
        Getearningsalarycomponent();
        if (data.status === "1") {
          swal("Error", data.result, "error");
        } else {
          swal("Success", data.result, "success");
        }
      } catch (error) {
        console.log("error", error);
        swal("Error", "Something went wrong!", "error");
      }
    }
  };
  const handleDelete = async (pk_salary_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover it!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setTransactionLoaderState(true);
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              firmid: user.firm_id,
              salaryid: pk_salary_id,
            }),
            redirect: "follow",
          };

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DeleteSalaryComponents`,
            requestOptions
          );
          const data = await res.json();
          if (data.status === "1") {
            swal("Error", data.result, "error");
          } else {
            swal("Success", data.result, "success");
            Getearningsalarycomponent();
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTransactionLoaderState(false);
        }
      }
    });
  };
  return (
    <div
      className="table-responsive"
      style={{
        borderRadius: ".4em",
        border: "1px solid lightgrey",
        overflowX: "auto",
        height: "100%",
      }}
    >
      <table className="table table-bordered m-0 p-0 ">
        <thead>
          <tr style={{ borderBottom: "1px solid lightgrey" }}>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Name
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              EARNING TYPE
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              CALCULATION TYPE
            </th>
            <th
              className="text-center"
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              CONSIDER FOR EPF
            </th>
            <th
              className="text-center"
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              CONSIDER FOR ESI
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              STATUS
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {Earningdata.length === 0 ? (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", border: "none" }}>
                No data found
              </td>
            </tr>
          ) : (
            Earningdata.map((data, index) => (
              <tr key={index}>
                <td
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontSize: ".8rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    fontWeight: "700",
                  }}
                >
                  {data.earning_name}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {data.earning_type}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {data.calculation_type}
                </td>
                <td
                  className="text-center"
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    color: data.epf_contri === "Y" ? "green" : "red",
                  }}
                >
                  {data.epf_contri === "Y" ? "Yes" : "No"}
                </td>
                <td
                  className="text-center"
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    color: data.esi_contri === "Y" ? "green" : "red",
                  }}
                >
                  {data.esi_contri === "Y" ? "Yes" : "No"}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    color: data.active === "Y" ? "green" : "red",
                  }}
                >
                  {data.active === "Y" ? "Active" : "Inactive"}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <PiDotsThreeCircleLight
                      onClick={() => toggleDropdown(index)}
                    />
                    {openDropdownIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: "0",
                          background: "white",
                          border: "1px solid lightgrey",
                          padding: "5px",
                          borderRadius: "4px",
                          zIndex: "999",
                          width: "9rem",
                        }}
                      >
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          <li
                            onClick={() => {
                              sessionStorage.setItem(
                                "pk_salary_id_earningcomponent",
                                data.pk_salary_id
                              );
                              history.push("/retailer/Admin_Edit_Earning_form");
                            }}
                          >
                            Edit
                          </li>

                          <li
                            onClick={() =>
                              updateSalaryComponentStatus(
                                data.pk_salary_id,
                                data.active === "Y" ? "N" : "Y"
                              )
                            }
                          >
                            {data.active === "Y"
                              ? "Mark as Inactive"
                              : "Mark as Active"}
                          </li>
                          <li onClick={() => handleDelete(data.pk_salary_id)}>
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export const Admindeduction = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const history = useHistory();

  const [data, setData] = useState([
    {
      name: "John Doe",
      deductionType: "Health Insurance",
      deductionFrequency: "Monthly",
      status: "Active",
    },
    {
      name: "Jane Smith",
      deductionType: "Retirement Fund",
      deductionFrequency: "Yearly",
      status: "Inactive",
    },
    {
      name: "Alice Johnson",
      deductionType: "Loan Repayment",
      deductionFrequency: "Monthly",
      status: "Active",
    },
  ]);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  return (
    <div
      className="table-responsive"
      style={{
        borderRadius: ".4em",
        border: "1px solid lightgrey",
        overflowX: "auto",
        height: "100%",
      }}
    >
      <table className="table table-bordered m-0 p-0 ">
        <thead>
          <tr style={{ borderBottom: "1px solid lightgrey" }}>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Name
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              DEDUCTION TYPE
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              DEDUCTION FREQUENCY
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              STATUS
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", border: "none" }}>
                No data found
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontSize: ".8rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    fontWeight: "700",
                  }}
                >
                  {item.name}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {item.deductionType}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {item.deductionFrequency}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    color: item.status === "Active" ? "green" : "red",
                  }}
                >
                  {item.status}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <PiDotsThreeCircleLight
                      onClick={() => toggleDropdown(index)}
                    />
                    {openDropdownIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: "0",
                          background: "white",
                          border: "1px solid lightgrey",
                          padding: "5px",
                          borderRadius: "4px",
                          zIndex: "999",
                          width: "9rem",
                        }}
                      >
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          <li onClick={() => alert(`Edit ${item.name}`)}>
                            Edit
                          </li>
                          <li onClick={() => alert(`Delete ${item.name}`)}>
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export const Adminreimbursements = ({}) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [data, setdata] = useState([]);
  const history = useHistory();
  const Getearningsalarycomponent = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFirmReimburement`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getearningsalarycomponent();
  }, []);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const updateSalaryComponentStatus = async (remb_id, active) => {
    const result = await swal({
      title: `Are you sure to mark this as ${
        active === "Y" ? "active" : "inactive"
      }?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (result) {
      setTransactionLoaderState(true);
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              Buffer.from(username + ":" + password).toString("base64"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firmid: user.firm_id,
            remb_id: remb_id,
            active: active,
          }),
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/UpdateReimbursementStatus`,
          requestOptions
        );
        const data = await res.json();
        console.log(data);
        Getearningsalarycomponent();
        if (data.status === "1") {
          swal("Error", data.result, "error");
        } else {
          swal("Success", data.result, "success");
        }
      } catch (error) {
        console.log("error", error);
        swal("Error", "Something went wrong!", "error");
      }
    }
  };
  const handleDelete = async (rembid) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover it!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setTransactionLoaderState(true);
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              firmid: user.firm_id,
              rembid: rembid,
            }),
            redirect: "follow",
          };

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DeleteSalaryReimbursement`,
            requestOptions
          );
          const data = await res.json();
          if (data.status === "1") {
            swal("Error", data.result, "error");
          } else {
            swal("Success", data.result, "success");
            Getearningsalarycomponent();
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTransactionLoaderState(false);
        }
      }
    });
  };
  return (
    <div
      className="table-responsive"
      style={{
        borderRadius: ".4em",
        border: "1px solid lightgrey",
        overflowX: "auto",
        height: "100%",
      }}
    >
      <table className="table table-bordered m-0 p-0 ">
        <thead>
          <tr style={{ borderBottom: "1px solid lightgrey" }}>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Name
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              REIMBURSEMENT TYPE
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              MAXIMUM REIMBURSABLE AMOUNT
            </th>

            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              STATUS
            </th>
            <th
              style={{
                color: "#0074D9",
                background: "#eff4fa",
                border: "none",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", border: "none" }}>
                No data found
              </td>
            </tr>
          ) : (
            data.map((data, index) => (
              <tr key={index}>
                <td
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontSize: ".8rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    fontWeight: "700",
                  }}
                >
                  {data.payslip_name}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {data.rem_type}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  {data.claim_monthly === "N"
                    ? `${data.amount} per Year`
                    : `${data.amount} per Month`}
                </td>

                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                    color: data.active === "Y" ? "green" : "red",
                  }}
                >
                  {data.active === "Y" ? "Active" : "Inactive"}
                </td>
                <td
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    border: "none",
                    fontSize: ".9rem",
                    borderBottom: "1px solid #f2f4f2",
                    borderRight: "1px solid #f2f4f2",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <PiDotsThreeCircleLight
                      onClick={() => toggleDropdown(index)}
                    />
                    {openDropdownIndex === index && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: "0",
                          background: "white",
                          border: "1px solid lightgrey",
                          padding: "5px",
                          borderRadius: "4px",
                          zIndex: "999",
                          width: "9rem",
                        }}
                      >
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          {/* <li
                            onClick={() => {
                              sessionStorage.setItem(
                                "pk_salary_id_earningcomponent",
                                data.pk_salary_id
                              );
                              history.push("/retailer/Admin_Edit_Earning_form");
                            }}
                          >
                            Edit
                          </li> */}

                          <li
                            onClick={() =>
                              updateSalaryComponentStatus(
                                data.pk_remb_id,
                                data.active === "Y" ? "N" : "Y"
                              )
                            }
                          >
                            {data.active === "Y"
                              ? "Mark as Inactive"
                              : "Mark as Active"}
                          </li>
                          <li onClick={() => handleDelete(data.pk_remb_id)}>
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
