import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  BoardComponent,
  CalendarComponent,
  DocsComponent,
  ListComponent,
  Tasklist,
} from "./SalaryTable";

const Employeeworkspace = () => {
  const [activeTab, setActiveTab] = useState("List");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  return (
    <>
      <div className="pt-4 px-4">
        <div className="row m-0 p-0 ">
          <h5 className="col m-0 p-0"> {propertyobject.workspace_name}</h5>
          <div className="col d-flex justify-content-end">
            <Link  to="/retailer/WorkDashboardnew" style={{}} >Back</Link>
          </div>
        </div>

        <ul className="nav nav-pills pt-3">
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "List" && "active"}`}
              onClick={() => handleTabChange("List")}
              to="#"
            >
              List
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "Board" && "active"}`}
              onClick={() => handleTabChange("Board")}
              to="#"
            >
              Board
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "Calendar" && "active"}`}
              onClick={() => handleTabChange("Calendar")}
              to="#"
            >
              Calendar
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className={`nav-link ${activeTab === "Docs" && "active"}`}
              onClick={() => handleTabChange("Docs")}
              to="#"
            >
              Docs
            </Link>
          </li>
        </ul>
      </div>

      <div className="w-100  ">
        {activeTab === "List" && <Tasklist />}
        {/* {activeTab === "Board" && <BoardComponent />}
        {activeTab === "Calendar" && <CalendarComponent />} */}
        {activeTab === "Docs" && <DocsComponent />}
      </div>
    </>
  );
};

export default Employeeworkspace;
