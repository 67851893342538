import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import { BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../utils/StringConstants";
import swal from "sweetalert";
import cogoToast from "cogo-toast";
import { GlobalContext } from "../Context/GlobalState";
import { Stepper as Stepper2 } from "./Stepper";

const BusinessDetails = () => {
  const { refreshWallet, setTransactionLoaderState } =
    useContext(GlobalContext);
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const [rejected, setRejected] = useState(false);
  const [formValues, setFormValues] = useState({
    ShopName: "",
    Address: "",
    Pincode: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [State_Code, setStateCode] = useState([]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");

  const steps = localStorage.getItem("steps");
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const kyc_by = sessionStorage.getItem("kyc_by")
  const fetchUserKycData = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          if (kyc_by && kyc_by == "admin") {
          } else {
            history.push("/kyc/user-kyc-view");
          }
        } else if (data.data[0].kyc_status == "R") {
          setRejected(true);
          // swal(
          //   "Reject",
          //   "Your KYC has been rejected please submit kyc again",
          //   "warning"
          // );
        }

        setKycData(data.data[0]);
        setFormValues({
          ...formValues,
          ShopName: data.data[0].shopname,
          Address: data.data[0].shopaddress,
          Pincode: data.data[0].shoppincode,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const redirect = () => {
    history.push("/kyc/personal-details");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("called");
      Business();
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log(values);
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.ShopName) {
      errors.ShopName = "*Shop Name is required!";
    }
    if (!values.Address) {
      errors.Address = "*Address is required!";
    } else if (values.Address.length <= 10) {
      errors.Address = "*Please enter valid address!";
    }
    if (!values.Pincode) {
      errors.Pincode = "*Pincode is required!";
    } else if (values.Pincode.length != 6) {
      errors.Pincode = "*Please enter valid pincode!";
    }
    console.log(errors);

    return errors;
  };
  console.log(userId);

  const Business = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      steps: "1",
      shopname: formValues.ShopName,
      shopaddress: formValues.Address,
      shoppincode: formValues.Pincode,
      shopcity: City,
      shopstate: State,
      fkuserid: userId.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/submitkyc.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            redirect();
            localStorage.setItem("steps", 1);
            cogoToast.info(result.msg);
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const StateCode = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Pincode: formValues.Pincode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/pindata.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            setStateCode(result);
            setState(result[0].state);
            setCity(result[0].city);
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formValues.Pincode.length == 6) {
      StateCode();
    }
  }, [formValues.Pincode]);
  useEffect(() => {
    fetchUserKycData();
  }, []);

  return (
    <div className="p-3" style={{ fontFamily: "auto" }}>
      <div
        className="card"
        style={{ backgroundColor: "aliceblue", textAlign: "center" }}
      >
        {/* <div
          style={{
            backgroundColor: "#0097C5",
            height: "8vh",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        >
          {rejected && (
            <h3 className="text-light mt-2">{rejected ? "Rejected" : ""}</h3>
          )}
        </div> */}
        <Row>
          <Col md={12} lg={12} sm={12} className="p-0">
            {/* <Stepper activeStep={steps} style={{ padding: "15px" }}>
              <Step label="Business Details" />
              <Step label="Personal Details" />
              <Step label="Document Details" />
              <Step label="Document Upload" />
              <Step label="Biometric KYC" />
            </Stepper> */}
            <Stepper2 step={1} />
          </Col>
        </Row>
        <div
          className="card-body m-3 mt-0 pt-0"
          style={{
            borderRadius: "1rem",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        >
          <Row>
            <Col md={12} lg={12} sm={12} className="mb-3">
              <h4>
                <b>Business Details</b>
              </h4>
            </Col>
            <Col md={12} lg={12} sm={12}>
              <Form.Label>
                <b>Shop/Business Name: </b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
            </Col>
            <Col md={12} lg={12} sm={12}>
              <Form.Control
                type="text"
                placeholder="Enter Shop Name"
                name="ShopName"
                value={formValues.ShopName}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.ShopName}</p>
            </Col>

            <Col md={12} lg={12} sm={12}>
              <div className="mt-2">
                <hr />
                <h5>
                  <b>SHOP ADDRESS</b>
                </h5>
                <hr />
              </div>
            </Col>

            <Col md={6} lg={6} sm={12}>
              <Form.Label>
                <b>Address:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Address"
                name="Address"
                value={formValues.Address}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.Address}</p>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <Form.Label>
                <b>Pincode:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                polaceholder="Enter pincode"
                name="Pincode"
                value={formValues.Pincode}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.Pincode}</p>
            </Col>

            <Col md={6} lg={6} sm={12} className="mt-2">
              <Form.Label>
                <b>City:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="City"
                disabled={State_Code.length > 0 ? false : true}
                value={State_Code.length > 0 ? State_Code[0].city : ""}
              />
            </Col>
            <Col md={6} lg={6} sm={12} className="mt-2">
              <Form.Label>
                <b>State:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                name="State"
                disabled={State_Code.length > 0 ? false : true}
                value={State_Code.length > 0 ? State_Code[0].state : ""}
              />
            </Col>

            <Col md={6} lg={6} sm={12}>
              <small>
                <p className="mt-3" style={{ color: "red" }}>
                  All Fields(*) marked are mandatory
                </p>
              </small>
            </Col>
            <Col md={6} lg={6} sm={12} className="mt-4">
              <Form.Group>
                <Button
                  className="rounded-0 btn-block"
                  variant="success"
                  onClick={handleSubmit}
                >
                  {"Next"}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
