import { BASE_URL_API_TRANXT_API } from "./Constants";
import swal from "sweetalert";
import { generateHashKey2 } from "./utilFuctions";

export const validateTPin = async (mobile, tPin, callback, callback2) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    mobileno: mobile,
    reset_type: "TPIN",
    password: tPin,
  });
  const hash_key = await generateHashKey2(mobile);
  var myHeaders = new Headers();
  myHeaders.append("HASH_KEY", hash_key);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
      requestOptions
    );
    const data = await res.json();

    if (data.status == "00") {
      callback();
    } else {
      swal("Transaction Failed", "In Correct T PIN", "error");
      callback2();
    }
  } catch (error) {}
};
