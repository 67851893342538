import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form, Badge } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

const Reviewwfh = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const WFHWorkdata = JSON.parse(sessionStorage.getItem("WFHwork"));
  const { password, username } = billpunchbasicauth;
  const [selectedItem, setSelectedItem] = useState(null);
  const [remark, setRemark] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getwfh = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        homeid: WFHWorkdata.pk_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWorkUpdate`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData1(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        workid: selectedItem.pkid,
        remark: remark,
        reporting_id: user.userid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ApproveWorkUpdate`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        closeModal();
        getwfh();
      } else {
        swal("Success", data.result, "success");
        closeModal();
        getwfh();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getwfh();
  }, []);


  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setRemark(""); 
  };

  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h5 className="">WFH Work Update</h5>
          </div>
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-end">
            <Link to="/retailer/EmployeeLeave">
              <Button
                className="btn btn-info btn-sm"
                style={{ borderRadius: ".4em", border: "None", color: "#fff" }}
              >
                Back
              </Button>
            </Link>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          {data1.length === 0 ? (
            <p>No data found</p>
          ) : (
            <table
              className="PMS-collection-table m-1"
              style={{ textAlign: "center", overflow: "hidden" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    Sr.
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    Start Time
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    End Time
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                      width: "50%",
                    }}
                  >
                    Task
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    Remark
                  </th>
                  <th
                    style={{
                      color: "#0074D9",
                      border: "1px solid #cccccc",
                      padding: "8px",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data1.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {index + 1}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {item.start_time}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {item.end_time}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {item.task_name}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {item.status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "#cce5ff",
                            color: "#007bff",
                            border: "1px solid #b8daff",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Not Reviewed
                        </Badge>
                      ) : (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Reviewed
                        </Badge>
                      )}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      {item.remark || "NA"}
                    </td>
                    <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                      <Button
                        className="btn-sm"
                        variant="success"
                        onClick={() => openModal(item)}
                      >
                        Add Remark
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="remarkForm">
              <Form.Label>Enter Remark:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Form.Group>
            <Modal.Footer>
              <Button className="btn-sm" variant="primary" type="submit">
                Save Remark
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Reviewwfh;
