import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";

const PaymentGatewayResponse = () => {
  let printData = localStorage.getItem("print");
  if (printData) {
    printData = JSON.parse(printData);
  } else {
    printData = {};
  }
  let query = useQuery();
  const orderid = query.get("orderid");
  const status = query.get("status");
  const amount = query.get("amount");

  useEffect(() => {
    const flag = localStorage.getItem(orderid);
    if (!flag) {
      localStorage.setItem(orderid, "true");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="col-6 mx-auto d-flex flex-column text-center">
          <img
            className="mx-auto p-3"
            src={
              status.toString().toLowerCase() == "failure"
                ? require("../../assets/icons/declined.png")
                : require("../../assets/images/thanks.png")
            }
            style={{ height: "100%", width: "auto" }}
          />
          <strong
            className={`${
              status.toString().toLowerCase() == "failure"
                ? "text-danger"
                : "text-success"
            }`}
          >
            Payment {status}
          </strong>
          <div className="d-flex justify-content-center">
            <strong>{`OrderID ${orderid}`}</strong>
          </div>
          <div className="d-flex justify-content-center">
            <strong>{`Status ${status}`}</strong>
          </div>
          <div className="d-flex justify-content-center">
            <strong>{`Amount ${amount}`}</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentGatewayResponse;
