import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Container, Row, Col, Form, Modal } from "react-bootstrap";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import swal from "sweetalert";

const OfficeInventory = () => {
  const [inventory, setInventory] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [newItemDetails, setNewItemDetails] = useState("");
  const colors = [
    "text-primary",
    "text-secondary",
    "text-success",
    "text-warning",
    "text-danger",
    "text-info",
  ];
  const [colorIndex, setColorIndex] = useState(0);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getIconByName = async (name) => {
    const libraries = ["fa", "md", "io"];
    for (const lib of libraries) {
      try {
        const iconName = name
          .toLowerCase()
          .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
          .replace(/\s/g, "");
        const {
          [`${lib === "fa" ? "Fa" : lib === "md" ? "Md" : "Io"}${iconName}`]:
            IconComponent,
        } = await import(`react-icons/${lib}`);
        if (IconComponent) {
          return <IconComponent size="2em" />;
        }
      } catch {
        continue;
      }
    }
    return <IoDocumentTextOutline size="2em" />;
  };

  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getInventoryData = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ firmid: user.firm_id }),
      };
 
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllInventoryProducts`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const inventoryWithIcons = await Promise.all(
          data.data.map(async (item, index) => ({
            id: item.id,
            name: item.item_name,
            description: item.item_desc,
            icon: await getIconByName(item.item_name),
            color: colors[index % colors.length],
          }))
        );
        setInventory(inventoryWithIcons);
        sessionStorage.setItem("inventoryData", JSON.stringify(inventoryWithIcons));
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getInventoryData();
  }, []);

  const handleAddItem = async () => {
    const newItemIcon = await getIconByName(newItemName);
    const newItem = {
      id: inventory.length + 1,
      name: newItemName,
      description: newItemDetails,
      icon: newItemIcon,
      color: colors[colorIndex % colors.length],
    };
    setInventory([...inventory, newItem]);
    setNewItemName("");
    setNewItemDetails("");
    setColorIndex(colorIndex + 1);
    handleSubmit(newItemName, newItemDetails);
    handleCloseModal();
  };

  const handleSubmit = async (itemName, itemDescription) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        item_name: itemName,
        item_description: itemDescription,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddInventory`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Error", data.responseMessage, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const filteredInventory = inventory.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleViewDetails = (item) => {
    sessionStorage.setItem("selectedItem", JSON.stringify(item));
  };
  
  
  return (
    <Container className="mt-4">
      <div className="row">
        <h4 className="col">Office Inventory Management</h4>
        <div className="col d-flex justify-content-end">
          <div className="px-2">
            <Form.Control
              type="text"
              placeholder="Search items..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ height: "2rem" }}
            />
          </div>
          <div>
            <Button size="sm" onClick={handleShowModal}>
              Add Items
            </Button>
          </div>
        </div>
      </div>

      <Row>
        {filteredInventory.map((item) => (
          <Col md={4} lg={3} key={item.id} className="mb-4">
            <Card className="text-center shadow-sm border-light p-2 office-card-hover">
              <Card.Body>
                <div className={`mb-3 ${item.color}`}>{item.icon}</div>
                <Card.Title style={{ color: "#000" }}>{item.name}</Card.Title>
                <Card.Text>
                  <strong>Details:</strong> {item.description}
                </Card.Text>
                <Link to="/retailer/Items_office_Inventory">
                  <Button
                    size="sm"
                    variant="success"
                  onClick={() => handleViewDetails(item)}
                  >
                    View Details
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Additemsofficeinventory
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        newItemName={newItemName}
        setNewItemName={setNewItemName}
        newItemDetails={newItemDetails}
        setNewItemDetails={setNewItemDetails}
        handleAddItem={handleAddItem}
      />
    </Container>
  );
};

export default OfficeInventory;

export const Additemsofficeinventory = ({
  showModal,
  handleCloseModal,
  newItemName,
  setNewItemName,
  newItemDetails,
  setNewItemDetails,
  handleAddItem,
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="itemName">
            <Form.Label>Item Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter item name"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="itemDetails">
            <Form.Label>Item Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter item details"
              value={newItemDetails}
              onChange={(e) => setNewItemDetails(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddItem}>
          Add Item
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
