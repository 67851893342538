import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
const Billpunchprivacypolicy = () => {
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "9");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
         <Helmet>
  <title>{pageData ? pageData.basic_settings.tittle : "Loading..."}</title>
  <meta name="description" content={pageData ? pageData.basic_settings.meta_desc : "Loading..."} />
  <meta name="keywords" content={pageData ? pageData.keyword_settings.map(keyword => keyword.keyword).join(", ") : "Loading..."} />
  <link rel="canonical" href={pageData ? pageData.basic_settings.canonical_tag : "#"} />
  <meta property="og:title" content={pageData ? pageData.og_settings?.og_title || "Loading..." : "Loading..."} />
  <meta property="og:description" content={pageData ? pageData.og_settings?.og_desc || "Loading..." : "Loading..."} />
  <meta property="og:image" content={pageData ? pageData.og_settings?.og_image || "#" : "#"} />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
</Helmet>
      <Navbar />
      <h1 className="text-center mt-3">Privacy Policy</h1>

      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-7 col">
        
          <h5>1. Personal Data</h5>
          <h6>1.1 Personal Data Collected by BillPunch:</h6>
          <ul>
            <li> Company Name</li>
            <li> Contact Name</li>
            <li> Email Address</li>
            <li> Contact Number</li>
            <li> Country</li>
            <li>
               Information about Platform usage, including cookies, domain
              name details, IP addresses, subscription account details, and
              membership details; and
            </li>
            <li>
               Payment-related information, such as bank account information
              and credit history.
            </li>
          </ul>

          <h6>1.2 Data Processed on Behalf of Customers:</h6>
          <p>
            Personal Data such as name, date of birth, gender, contact
            details, emergency contact number, address, nationality, family
            member details, and national security/identity number are collected
            per customer instructions. Face images, location, IP address,
            unique identifiers, leave details, bank account, and tax
            declarations are collected for attendance and payment tracking
            purposes.
          </p>

          <h5>2. Collection of Personal Data</h5>
          <p>
            <strong>2.1 Personal Data is collected:</strong>
            <br />
            - When registering details on the website or with company
            representatives.
            <br />
            - During agreement or documentation submission.
            <br />
            - Through interactions with staff.
            <br />
            - When using or subscribing to products/services.
            <br />
            - During transactions or payments.
            <br />
            - In participation in events or responding to requests.
            <br />
            - From business partners, public agencies, or third parties.
          </p>
          <p>
            <strong>2.2 As a Service Provider:</strong> Personal Data is
            collected on behalf of customers (Employers) when entering employee
            details or payment-related information, or when employees update
            their data.
          </p>

          <h5>
            3. Purposes for Collection, Use, Disclosure, and Processing of
            Personal Data
          </h5>
          <p>See detailed purposes outlined in the policy document.</p>

          <h5>4. Disclosure of Personal Data</h5>
          <p>
            See detailed disclosure entities and parties outlined in the policy
            document.
          </p>

          <h5>5. Accuracy of Personal Data</h5>
          <p>
            We ensure that collected data is accurate, complete, and up-to-date.
          </p>

          <h5>6. Request for Access and Correction of Personal Data</h5>
          <p>
            You have the right to request access or correction of your Personal
            Data.
          </p>

          <h5>7. Request to Withdraw Consent</h5>
          <p>
            You have the right to withdraw consent for Personal Data
            collection, use, and/or disclosure.
          </p>

          <h5>8. Administration and Management of Personal Data</h5>
          <p>
            It's your responsibility to ensure provided data is accurate and
            complete.
          </p>

          <h5>9. Transfers of Personal Data</h5>
          <p>
            Personal Data may be stored in external servers or transferred
            between subsidiaries with appropriate safeguards.
          </p>

          <h5>10. Data Breach Notification</h5>
          <p>
            We promptly report any data breaches to regulatory bodies and
            affected parties.
          </p>

          <h5>
            11. Use of Cookies, Web Beacons, and other Technologies
          </h5>
          <p>
            We use cookies and similar technologies for various purposes. Users
            can manage their preferences.
          </p>

          <h5>12. Third-Party Sites</h5>
          <p>
            We're not responsible for the data protection practices of
            third-party websites linked to ours.
          </p>

          <h5>
            13. Contacting Us - Withdrawal of Consent, Access, and Correction
            of Personal Data
          </h5>
          <p>
            Contact details for inquiries, withdrawal of consent, and data
            access/correction requests.
          </p>

          <h5>14. Data Retention</h5>
          <p>
            We retain Personal Data as necessary for service provision, legal
            compliance, dispute resolution, and business purposes.
          </p>

          <h5>15. Updates to this Policy</h5>
          <p>
            Policy amendments are made periodically to ensure compliance with
            legal/regulatory changes and industry trends.
          </p>

        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpunchprivacypolicy;
