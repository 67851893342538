import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form } from "react-bootstrap";
import { Line, Doughnut } from "react-chartjs-2";
import C3Chart from "react-c3js";
import "c3/c3.css";
import Bbps from "../bbps/Bbps";
import DashboardReport from "../dashoard-reports/DashboardReport";
import Transactions from "../dashboard-transactions/Transactions";
import { Redirect } from "react-router";
import swal from "sweetalert";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import RecentActivity from "../recent-activity/RecentActivity";
import FundTransfer from "../../retailer/fund-transfer/FundTransfer";
import DirectFundTransfer from "../direct-fund-transfer/DirectFundTransfer";
import { DROP_SHADOW } from "../../utils/CommonConfig";

const QuickPay = () => {
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;

  const [moneyTransferState, setMoneyTransferState] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "IMPS",
    emailid: "",
  });
  const [moneyTransferErrors, setMoneyTransferErrors] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "",
    emailid: "",
  });
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const { token, myAccountInfo, user } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  console.log(myAccountInfo);
  // get my account details
  const getDashboardInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Profile/GetDashboardInfo",
        requestOptions
      );
      const data = await res.json();
      if (data.data) {
        setDashboardInfo(data.data);
      }
    } catch (error) {}
  };

  const fundDirectPay = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0wWBysqFJUXY9_KyZSIo52m1Go_ebppTSv50dwkDW_h0k8Vd0zzB5ioe8_IW3RvEeH3WWnDgJfmr5sMuH9V1TZjIsAIZYHz7XbTqmUKZiANcX9if2m6MzKI2earJBZwv_jPtUa1VxDrMC_mK15vCpoAHjcEFc4nGyEAWLeDf2k35z8Lr7ScmnunwYQE29h3lNr_MIx5fhyeiCpSNo4gb9AAfPU2TMlvyj-XL6TBgcbYNNiwKG8pFV_dpgRCIKVA6VNLMejucMHf9mzv6EKDxU6PeoPMF11SBNt60lnv78l53-U0bt0vafsHp-o4niHqo1VVxvabLPQwgwNGg2AekETdQ1qtSppaygelVbKh3NjuuR_k2YMzqtqEkkrjSpAtE60UnVF7iOuIByzFm5gVyhDCODlGmyWfAi8XX_7QISCEpnv_PmoXcYr_DfgOBKxk4Czl4FFJMgtRo-mHcXWpc62pj2r45O4L3uFkQGB6-0SAB5Xm70NjY1vKC-LcxsztN6IicbtvffH1kZZWCp1f0jfsY5bcHMi21EkOr_lFhg4w9Z6mzXfMbWriUQs_uNafIn4sXvx0n2u5Ov3emee9Xm1FcULyyv5vwJ7iGkRHvjMBlEDw1p7iF1L_LE-mlrtiv2acPkuPHQViXnaKaXogkrO"
    );

    const {
      amount,
      bankaccount,
      bankifsccode,
      customerMob,
      customername,
      emailid,
      transactionType,
    } = moneyTransferState;

    var raw = JSON.stringify({
      refrenceid: "string",
      customername: customername,
      customerMob: user.Mobile,
      amount: amount,
      bankaccount: bankaccount,
      bankifsccode: bankifsccode,
      transactionType: transactionType,
      emailid: emailid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Payments/FundInstanttransfer`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMsg ? data.responseMsg : "", "success");
      } else {
        swal(
          "Transaction Failed",
          data.responseMsg ? data.responseMsg : "",
          "error"
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validate = () => {
    let check = true;
    let errors = {};
    if (!moneyTransferState.customername) {
      errors.customername = "Customer number is mandatory";
    }
    if (!moneyTransferState.bankaccount) {
      errors.bankaccount = "Account number is mandatory";
    }
    if (!moneyTransferState.bankifsccode) {
      errors.bankifsccode = "Bank IFSC Code is mandatory";
    }
    if (!moneyTransferState.amount) {
      errors.amount = "Amount is mandatory";
    }

    setMoneyTransferErrors(errors);
    if (Object.keys(errors).length == 0) {
      fundDirectPay();
    }
  };

  const moneyTransfer = () => {
    validate();
  };
  console.log(moneyTransferState);

  useEffect(() => {
    //  getDashboardInfo();
  }, []);
  return (
    <div
      style={{
        background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
        borderRadius: "1rem",
        boxShadow: DROP_SHADOW,
      }}
      className="m-3 rouded-3 p-2"
    >
      <div className="row m-0">
        <h5 className="text-dark m-0">Money Transfer</h5>
      </div>
      <div
        className="row m-0"
        style={{ background: "#FFFFFF", borderRadius: "1rem" }}
      >
        <div className="col py-0 px-1 m-0">
          <Form.Group className="m-0">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`Beneficiary Name`}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Enter Beneficiary Name"
              value={moneyTransferState.customername}
              onChange={(e) => {
                setMoneyTransferState({
                  ...moneyTransferState,
                  customername: e.target.value,
                });
              }}
              style={{}}
            />
            <p style={{ color: "red" }}>{moneyTransferErrors.customername}</p>
          </Form.Group>
        </div>

        <div className="col py-0 px-1 m-0">
          <Form.Group className="m-0">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`Account Number`}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Enter Account Number"
              value={moneyTransferState.bankaccount}
              onChange={(e) => {
                setMoneyTransferState({
                  ...moneyTransferState,
                  bankaccount: e.target.value,
                });
              }}
              style={{}}
            />
            <p style={{ color: "red" }}>{moneyTransferErrors.bankaccount}</p>
          </Form.Group>
        </div>

        <div className="col py-0 px-1 m-0">
          <Form.Group className="m-0">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`IFSC Code`}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Enter IFSC Code"
              value={moneyTransferState.bankifsccode}
              onChange={(e) => {
                setMoneyTransferState({
                  ...moneyTransferState,
                  bankifsccode: e.target.value,
                });
              }}
            />
            <p style={{ color: "red" }}>{moneyTransferErrors.bankifsccode}</p>
          </Form.Group>
        </div>

        <div className="col py-0 px-1 m-0">
          <Form.Group className="m-0">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`Amount`}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Enter Amount"
              value={moneyTransferState.amount}
              onChange={(e) => {
                setMoneyTransferState({
                  ...moneyTransferState,
                  amount: e.target.value,
                });
              }}
            />
            <p style={{ color: "red" }}>{moneyTransferErrors.amount}</p>
          </Form.Group>
        </div>

        <div className="col py-0 px-1 mt-4">
          <button className="btn btn-success" onClick={moneyTransfer}>
            {" "}
            Transfer
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickPay;
