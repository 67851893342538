import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import "bootstrap/dist/css/bootstrap.min.css";

const Solution = () => {
  const [isHovered, setIsHovered] = useState(false);
  const isSmallScreen = useMediaQuery(
    "(max-width: 825px) and (max-height: 715px)"
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const listItems = [
    "Efficient Staff Administration",
    "Effortless Payroll Completion",
    "Convenient Access to Payslips",
  ];
  return (
    <>
      <div
        className="mt-md-5 pt-4 h-md-75 h-50"
        style={{
          backgroundImage: `url(${require("../../../assets/images/forthback.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row m-0 p-0">
          <div className="col-md-1"></div>
          <div className="col-md-6 col-12 p-0">
            <div className="my-md-4 mx-md-0 mx-3 p-md-1 m-1">
              <h3
                className=" text-white"
                style={{
                  // fontSize: isSmallScreen ? "1.4rem" : "",
                  fontWeight: 500,
                }}
              >
                One stop Solution for HR needs
              </h3>
              <h4
                style={{ color: "#fff", fontSize: isSmallScreen ? "1rem" : "" }}
              >
                Simplify your business processes with our cost-effective
                platform.
              </h4>
              <ul
                style={{
                  color: "#fff",
                  paddingLeft: "1.5rem",
                  listStyleType: "disc",
                }}
              >
                {listItems.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>

              <Link to="/Pricing">
                <Button
                  style={{
                    borderRadius: "0",
                    background: isHovered ? "#fff" : "#0085ff",
                    transition: "background 0.3s",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <span style={{ color: isHovered ? "#0085ff" : "#fff" }}>
                    View Pricing &gt;
                  </span>
                </Button>
              </Link>
            </div>
          </div>
          <div
            className="col-md-5 col-12 mt-md-0 mt-5 d-flex justify-content-center"
            style={{ alignItems: "end" }}
          >
            <img
              className="solutionpic"
              alt="HR software"
              style={{ height: "16rem" }}
              src={require("../../../assets/images/Sliderhrdash.jpeg")}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
};

export default Solution;
