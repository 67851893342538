import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FaFlag } from "react-icons/fa";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Button } from "react-bootstrap";
import { AddsubTaskemployeeModal } from "./Modal";

const Subtasks = () => {
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const [status, setstatus] = useState([]);
  const [taskdata, setTaskData] = useState([]);
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        taskid: taskdetails.pk_taskid,
        platformid: "1",
        categoryid: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetSubTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Updatestatus = async (subTaskId) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        sub_taskid: subTaskId,
        status: "C",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ChangeStatusSubtask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setstatus(data.data);
        getTask();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getTask();
  }, []);
  const [showaddsubtaskModal, setshowaddsubtaskModal] = useState(false);

  const openaddsubtaskModal = () => {
    setshowaddsubtaskModal(true);
  };
  const closeaddsubtaskModal = () => {
    setshowaddsubtaskModal(false);
  };
  return (
    <div className="p-3">
        <AddsubTaskemployeeModal
          show={showaddsubtaskModal}
          setshowaddsubtaskModal={setshowaddsubtaskModal}
         //closeModal={closeaddsubtaskModal}
          // getSubsubTask={getTask}
        />
    <div className="row">
    <h5 className="col"> {propertyobject.workspace_name}</h5>
    <div className=" col d-flex justify-content-end ">
      <div className="px-2"><Button variant="success " size="sm" onClick={openaddsubtaskModal}>Add Subtask</Button></div>
        <Link to="/retailer/Subtask" style={{}}>
      <Button variant="info " size="sm">Back</Button>
        </Link>
      </div>
    </div>
     
      <div className="">
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          {" "}
          <thead
            style={{
              position: "sticky",
              top: 0,
              // zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Task Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Assignee
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                  width:"40%"
                }}
              >
                Description
              </th>
            
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Start Date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                End Date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Status
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Mark completed
              </th>
            </tr>
          </thead>
          <tbody>
            {taskdata.length > 0 ? (
              taskdata.map((item) => (
                <React.Fragment key={item.pk_task_id}>
                  <tr style={{ border: "none", cursor: "pointer" }}>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.priority === "High" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "#f4c430" }}
                        />
                      )}
                      {item.priority === "Urgent" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "maroon" }}
                        />
                      )}
                      {item.priority === "Normal" && (
                        <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                      )}
                      {item.priority === "Low" && (
                        <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                      )}
                      {item.priority === "Clear" && (
                        <img
                          src={require("../../../assets/images/flag_1.png")}
                          style={{ marginRight: "5px", width: ".8rem" }}
                          alt="Clear flag"
                        />
                      )}
                      {item.sub_name} &nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.sub_empname} &nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.subtask_desc} &nbsp;
                    </td>
                 
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {new Date(item.sub_startdate).toLocaleDateString()}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {new Date(item.sub_enddate).toLocaleDateString()}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.status === "O" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Overdue
                        </span>
                      )}
                      {item.status === "P" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: " #f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "C" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Completed
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "none",
                        fontSize: "2rem",
                      }}
                    >
                         <span
                          className="p-1"
                        
                        >
                          <IoMdCheckmarkCircleOutline
                          onClick={() => Updatestatus(item.pk_subtaskid)}
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        />
                        </span>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan="8"
                  className="text-center"
                  style={{ border: "none" }}
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Subtasks;
