export const offsetArr = [10, 25, 50, 100, 200, 250, 500, 1000,10000,25000];

export const REGEX_ONLY_ALPHA_SPACE = "[a-zA-Z ]*$";
export const REGEX_ONLY_ALPHA_NUMERIC = "[a-zA-Z0-9]*$";
export const REGEX_ONLY_NUMERIC = "^[0-9]+$";

// export const DROP_SHADOW =
//   "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

export const DROP_SHADOW =
  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)";

export const MONTHS = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
