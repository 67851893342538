import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Line } from "react-chartjs-2";
import { IoReloadCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  FaBuildingShield,
  FaMoneyBillTrendUp,
  FaUserGroup,
} from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { CgPlayTrackNextO } from "react-icons/cg";
import {
  MdAirplanemodeInactive,
  MdDisabledVisible,
  MdEditDocument,
  MdNavigateNext,
  MdNotificationsActive,
} from "react-icons/md";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Button } from "react-bootstrap";
import { Addfirmsmodal, Editmodal } from "./Modal";
import { FaBellSlash, FaBuilding } from "react-icons/fa";
import { CLIENT_BASIC_AUTH } from "../../utils/Client";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { BsThreeDotsVertical } from "react-icons/bs";
import swal from "sweetalert";
const Superadmindashboard = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Firms, setFirms] = useState([]);
  const [Firmdash, setFirmdash] = useState([]);
  const [activeFirms, setActiveFirms] = useState(0);
  const [inactiveFirms, setInactiveFirms] = useState(0);
  const [totalFirms, setTotalFirms] = useState(0);
  const [firmType, setFirmType] = useState(0);
  const [Showfirms, setShowfirms] = useState(false);
  const [Balance, setBalance] = useState({});
  const { username, password } = CLIENT_BASIC_AUTH;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [editmodal, seteditmodal] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState(null);

  const Openeditmodal = (firmData) => {
    setSelectedFirm(firmData);
    seteditmodal(true);
  };

  const Closeeditmodal = () => {
    seteditmodal(false);
  };
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const openfirmsmodal = () => {
    setShowfirms(true);
  };

  const closefirmsmodal = () => {
    setShowfirms(false);
  };
  const Getfirms = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllFirmsSuperAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setFirms(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getfirmdash = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSuperAdminDashboard`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        const dashboardData = data.data[0];
        setActiveFirms(dashboardData.active_firms);
        setInactiveFirms(dashboardData.inactive_firms);
        setTotalFirms(dashboardData.total_firms);
        setFirmType(dashboardData.firmtype);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const ChartsPage = () => {
    const dataLine = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Expense",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "rgba(230, 140,61)",
          pointBorderColor: "rgb(205, 130,158)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 1,
          pointRadius: 0.1,
          pointHitRadius: 10,
          data: [11, 15, 18, 20, 18, 22, 10, 14, 11, 14, 9, 17, 12],
        },
        {
          label: "Saving",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(246, 230, 184)",
          borderColor: "rgba(246, 230, 184)",
          pointBorderColor: "rgb(234, 192, 87)",
          pointBackgroundColor: "rgb(234, 192, 87)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220, 1)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [2, 4, 6, 5, 8, 2, 0, 9, 4, 7, 3, 8, 1],
        },
      ],
    };

    const options = {
      responsive: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    return (
      <div>
        <Line data={dataLine} options={options} />
      </div>
    );
  };

  const login = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
    );

    var raw = JSON.stringify({
      email: username,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      const result = await fetch(
        "https://apitranxt.paynnow.com/api/Account/Login",
        requestOptions
      );
      if (result.ok) {
        const body = await result.text();
        const data = JSON.parse(body);
        if (data.userName != undefined) {
          sessionStorage.setItem("userName", data.userName);
        }
        if (data.role != undefined) {
          sessionStorage.setItem("role", data.role);
        }
        if (data.accessToken != undefined && data.userId != undefined) {
          sessionStorage.setItem("userId", data.userId);
          getWalletBalance(data.accessToken);
        }
        if (data.refreshToken != undefined) {
          sessionStorage.setItem("refreshToken", data.refreshToken);
        }
        sessionStorage.setItem(
          "basic_auth",
          JSON.stringify({ username: username, password })
        );
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log(error);
    }
  };
  const [desable, setdesable] = useState("");
  const Firmdisable = async (pk_firmid) => {
    // Show confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Once disabled, you will not be able to recover this Firm!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          // User confirmed, proceed with API call
          setTransactionLoaderState(true);
          try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              firmid: pk_firmid,
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            return fetch(
              `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/DisableFirmsSuperAdmin`,
              requestOptions
            );
          } catch (error) {
            console.log("error", error);
            swal(
              "Error",
              "An error occurred while disabling the employee.",
              "error"
            );
          } finally {
            setTransactionLoaderState(false);
          }
        } else {
          // User cancelled, do nothing
        }
      })
      .then(async (res) => {
        if (res) {
          const data = await res.json();
          if (data.status === "0") {
            setdesable(data.data);
            swal("Success", data.result, "success");
            Getfirms();
          } else {
            swal("Success", data.result, "success");
            Getfirms();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        swal(
          "Error",
          "An error occurred while disabling the employee.",
          "error"
        );
      });
  };
  useEffect(() => {
    login();
  }, []);
  const getWalletBalance = async (accessToken) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        // removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc, i) => {
            if (i == 0) {
              const ccB = twoDecimalPlaces(
                Number(acc.opening_bal) +
                  Number(acc.today_topup) +
                  Number(acc.today_refund) -
                  Number(acc.payout_today) -
                  Number(acc.payout_fee) -
                  Number(acc.payin_fee) +
                  Number(acc.settlement_today)
              );
              setBalance({ paymentAPIBalance: ccB });
            }
            return;
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };
  const handleReload = () => {
    getWalletBalance();
  };

  useEffect(() => {
    Getfirms();
    Getfirmdash();
  }, []);

  return (
    <>
      <Addfirmsmodal
        show={Showfirms}
        closeModal={closefirmsmodal}
        Getfirms={Getfirms}
      />
      <div className="p-3">
        <h4>Welcome to Super Admin Dashboard!</h4>
        <div className="row">
          <div className="col-md-7 col-12 mb-md-0 mb-2 order-md-1 order-2">
            <ChartsPage />
          </div>
          <div className="col-md-5 col-12 mt-md-0  order-md-2 order-1">
            <div className="card p-2 bg-success">

              <div className="row">
                <div className="col">   <FaMoneyBillTrendUp
                    style={{ color: "#fff", fontSize: "2.5rem" }}
                  /></div>
                  <div className="col d-flex justify-content-end">
                  <IoReloadCircle
                      style={{ color: "#fff", fontSize: "1.3rem" }}
                      onClick={handleReload}
                    />
                  </div>
              </div>
              <div className="row">
                <div className="col">    <h5 className="mt-2" style={{ color: "#fff" }}>
                    Wallet Bal
                  </h5></div>
                  <div className="col">
                  <h2 style={{ color: "#fff" }}>
                    {" "}
                    {Balance
                      ? Balance.paymentAPIBalance !== undefined
                        ? " ₹" +
                          parseFloat(Balance.paymentAPIBalance).toFixed(2)
                        : "Loading..."
                      : "Loading..."}
                  </h2>
                  </div>
              </div>

        
            </div>
            <div className="row ">
              <div className="col p-0 m-0 ">
                <div className="card p-2 bg-warning">
                  <div className="row m-0 p-0">
                    <div className="col m-0 p-0">
                      <MdNotificationsActive
                        style={{ color: "#fff", fontSize: "2.5rem" }}
                      />
                      <h5 style={{ color: "#fff" }}>Active</h5>
                    </div>
                    <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                      {" "}
                      <h style={{ color: "#fff" }}>{activeFirms}</h>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card p-2 bg-danger">
                  <div className="row m-0 p-0">
                    <div className="col m-0 p-0">
                      <FaBellSlash
                        style={{ color: "#fff", fontSize: "2.5rem" }}
                      />
                      <h5 style={{ color: "#fff" }}>In-Active </h5>
                    </div>
                    <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                      {" "}
                      <h style={{ color: "#fff" }}>{inactiveFirms}</h>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col p-0 m-0 ">
                <div className="card p-2 bg-secondary">
                  <div className="row m-0 p-0">
                    <div className="col-8 m-0 p-0">
                      <FaBuildingShield
                        style={{ color: "#fff", fontSize: "2.5rem" }}
                      />
                      <h5 style={{ color: "#fff" }}>Total Firms </h5>
                    </div>
                    <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                      {" "}
                      <h style={{ color: "#fff" }}>{totalFirms}</h>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card p-2 bg-info">
                  <div className="row m-0 p-0">
                    <div className="col-8 m-0 p-0">
                      <FaBuilding
                        style={{ color: "#fff", fontSize: "2.5rem" }}
                      />
                      <h5 style={{ color: "#fff" }}>Firm Type </h5>
                    </div>
                    <div className="col m-0 p-0 d-flex justify-content-center align-items-center">
                      {" "}
                      <h style={{ color: "#fff" }}>{firmType}</h>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <h5 className="col">Manage Firms</h5>
          <div className="col d-flex justify-content-end">
            <Button
              onClick={openfirmsmodal}
              style={{ background: "#481E14" }}
              className="btn-sm"
            >
              Add Firms
            </Button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#fff",
              }}
            >
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Firm Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Owner
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Firm Type / GST
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Address
                </th>

                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Contact
                </th>

                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Status
                </th>

                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Firms.map((item) => (
                <React.Fragment key={item.pk_firm_id}>
                  <tr style={{ border: "none", cursor: "pointer" }}>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_name}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_owner_name}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_type}
                      <br />
                      {item.firm_gst}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_address}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_email}
                      <br /> {item.firm_mobile}
                    </td>
                    <td className="d-flex "
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                        alignItems:"center"
                      }}
                    >
                      {item.firm_status === "N" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",fontSize:".7rem"
                          }}
                        >
                          InActive
                        </span>
                      )}
                      {item.firm_status === "Y" && (
                        <span
                          className=" p-1 "
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",fontSize:".7rem"
                          }}
                        >
                          Active
                        </span>
                      )}{" "}
                     
                    
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        // textAlign: "ce",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      <CiEdit
                        onClick={() => Openeditmodal(item)}
                        style={{ fontSize: "1.5rem", color: "#000" }}
                      />
                      <MdDisabledVisible
                        onClick={() => Firmdisable(item.pk_firmid)}
                        style={{ fontSize: "1.2rem", color: "#000" }}
                      />
                      <Link to="/retailer/Firmdashboard">
                        <MdNavigateNext
                          onClick={() => {
                            sessionStorage.setItem(
                              "Firms",
                              JSON.stringify(item)
                            );
                          }}
                          style={{ fontSize: "1.2rem", color: "#000" }}
                        />
                      </Link>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <Editmodal
          show={editmodal}
          closeModal={Closeeditmodal}
          Getfirms={Getfirms}
          selectedFirm={selectedFirm}
        />
      </div>
    </>
  );
};
export default Superadmindashboard;
