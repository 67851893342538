import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Attandancecalendra from "./AttandanceCalendraHR";
import { HolidayReport, Leaveapproval } from "./SalaryTable";

const AttandanceManagement = () => {
  
  const [activeLink, setActiveLink] = useState("expenses");
  const renderContent = () => {
    switch (activeLink) {
      case "expenses":
        return <Attandancecalendra />;
        case "leaveapproval":
          return <Leaveapproval />;
      case "bills":
        return <HolidayReport/>;
      default:
        return null;
    }
  };

  return (
    <>
    <div className="m-3">
    <nav className=" navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse mx-md-4 mx-sm-2"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "expenses" && "active"
                }`}
                onClick={() => setActiveLink("expenses")}
              >
                Attendance
              </Link>
            </li>
          
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "leaveapproval" && "active"
                }`}
                onClick={() => setActiveLink("leaveapproval")}
              >
                Leave Approval
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "bills" && "active"
                }`}
                onClick={() => setActiveLink("bills")}
              >
                Holidays
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {renderContent()}
    </div>
   
    </>
  );
};

export default AttandanceManagement;
