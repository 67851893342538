import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Card, Accordion, Button } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL_APISERVICES, BASE_URL_PAYNOW } from "../../utils/Constants";
import Cable from "../../../assets/svg/cable.svg";
import Electricity from "../../../assets/svg/electricity.svg";
import Dth from "../../../assets/svg/dth.svg";
import PrepaidRecharge from "../../../assets/svg/prepaid_recharge.svg";
import WaterBill from "../../../assets/svg/water_bill.svg";
import PipedGas from "../../../assets/svg/piped_gas.svg";
import Broadband from "../../../assets/svg/broadband.svg";
import BusTicketIcon from "../../../assets/svg/bus_ticket.svg";
import EducationIcon from "../../../assets/svg/education.svg";
import FastagIcon from "../../../assets/svg/fastag.svg";
import FlightTicketIcon from "../../../assets/svg/flight_ticket.svg";
import GasCylinderIcon from "../../../assets/svg/gas_cylinder.svg";
import HospitalIcon from "../../../assets/svg/hospital.svg";
import HotelIcon from "../../../assets/svg/hotel.svg";
import HousingSocietyIcon from "../../../assets/svg/housing_society.svg";
import InsuranceIcon from "../../../assets/svg/insurance.svg";
import LandlineIcon from "../../../assets/svg/landline.svg";
import LoanIcon from "../../../assets/svg/load_repayment.svg";
import MuncipalTaxIcon from "../../../assets/svg/muncipal_tax.svg";
import TrainTicketIcon from "../../../assets/svg/train_ticket.svg";
import { BillerResponse } from "../../utils/BillerResponse";

const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const categoryInfo = [
  {
    categoryId: 1,
    categoryName: "Broadband Postpaid",
  },
  {
    categoryId: 2,
    categoryName: "Cable TV",
  },
  {
    categoryId: 3,
    categoryName: "DTH",
  },
  {
    categoryId: 4,
    categoryName: "Education Fees",
  },
  {
    categoryId: 5,
    categoryName: "Electricity",
  },
  {
    categoryId: 6,
    categoryName: "Fastag",
  },
  {
    categoryId: 7,
    categoryName: "Gas",
  },
  {
    categoryId: 8,
    categoryName: "Health Insurance",
  },
  {
    categoryId: 9,
    categoryName: "Hospital",
  },
  {
    categoryId: 10,
    categoryName: "Housing Society",
  },
  {
    categoryId: 11,
    categoryName: "Insurance",
  },
  {
    categoryId: 12,
    categoryName: "Landline Postpaid",
  },
  {
    categoryId: 13,
    categoryName: "Life Insurance",
  },
  {
    categoryId: 14,
    categoryName: "Loan Repayment",
  },
  {
    categoryId: 15,
    categoryName: "LPG Gas",
  },
  {
    categoryId: 16,
    categoryName: "Mobile Postpaid",
  },
  {
    categoryId: 17,
    categoryName: "Municipal Services",
  },
  {
    categoryId: 18,
    categoryName: "Municipal Taxes",
  },
  {
    categoryId: 19,
    categoryName: "Subscription",
  },
  {
    categoryId: 20,
    categoryName: "Water",
  },
];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

export const Bbps = () => {
  let basicAuth = JSON.parse(sessionStorage.getItem("basic_auth"));
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [serviceSelected, setServiceSelected] = useState(5);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const { token, myAccountInfo, user } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  if (!basicAuth) {
    basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  }
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  // const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  const [providers, setProviders] = useState([]);
  const [billFetched, setBillFetched] = useState(false);
  // get all operators
  const getAllProviders = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userid: "string",
      categoryId: serviceSelected,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOnlineOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) {
        setProviders(
          data.data.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const payBill = async () => {
    setPayClicked(true);
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }
    if (!billDetails) return;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq7E_u804ASEqPvM_yW_G_4eXqt18Wn9I-n8I3WtQtS1k63fuzYkZ3II2cGaUfAh06-OButEn8YXvZ_OKTOUg7gP0eyR30BQ_8eI347G7RyBVkL94bbcqn4rlloSevdhRK_itdZf7Cy1pikoJc95VBZtpNVRo8GfCCremQfVWnDGdC-uhQO4_iWArZNQ52pD6yFCxKrKQMZoDpLPBgU_RqwND6lpbx4PqGA89-SxvMRcqNeUppHj8jYHVcp1CnK1n1ncVhEtsR0luOqY1IHgz_J-pRI-lUsGJUDsgFNCww0eb7Mlk8LWwiu4qgYXpPYLlBZf0K7b2mTMgNpH25rMS4-VahYcpxG4zXe1vKHCEHeR7MjVXiAwNh_mdR2GXlgtM9FZ3bOSHjwCYuB6J6uzOd4gNgQcUWzkM1TJ_Fcq04jq6pdB2Ne-aN8XkGNAxnrVQ4SPVwVh-NkRSZYXpdS0XG2r658waXxYLVif7Sw40A-M3V1tLMqWwNSu2rMg5NFhDevaKKiJXcuqHXdrjRSOWLNgkPHS5lmjY11bV0QenW9H7Ahv3xbre32MmYrHlkX7HxKXtlS-uTURS1gQT0_bwPBt3webYhgPJMO8wuIP1HCTlsD98IkmuLeiQ5sSuaylpGJuo22sseXh04qbA3aj_8uo"
    );

    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });
    let billAmount;
    if (billDetails) {
      billAmount = billDetails.billerResponse.amount;
    } else {
      billAmount = amount;
    }

    var raw = JSON.stringify({
      amount: billDetails.billerResponse.amount,
      mobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
      refId: billDetails.refId,
      isDirectPay: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPay`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      setPayClicked(false);
      // cogoToast.info(data.status);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(billDetails);

  const fetchBill = async () => {
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      customerMobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
        requestOptions
      );
      const data = await res.json();
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setBillDetails(data.response);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  console.log(billerResponse);
  console.log(billDetails);

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }
    // console.log(CustP);
  }, [state]);

  useEffect(() => {
    getAllProviders();
  }, [serviceSelected]);
  return (
    <div className="bg-white-theme">
      <div
        style={{
          background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
          borderRadius: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="rounded-3 px-2 my-0 mx-3"
      >
        <div className="row m-0">
          <h5 className="text-dark">Bill Pay And Recharge</h5>
        </div>
        {/* Accordian */}

        <Accordion defaultActiveKey="1">
          <div>
            <div className="row rouded-3 justify-content-center m-0 rounded-3">
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(5);
                }}
                image={Electricity}
                title={"Electricity"}
                selected={serviceSelected == 5}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(16);
                }}
                image={PrepaidRecharge}
                title={"Prepaid Recharge"}
                selected={serviceSelected == 16}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(2);
                }}
                image={Dth}
                title={"DTH"}
                selected={serviceSelected == 2}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(3);
                }}
                image={Cable}
                title={"Cable Recharge"}
                selected={serviceSelected == 3}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(20);
                }}
                image={WaterBill}
                title={"Water Bill"}
                selected={serviceSelected == 20}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(7);
                }}
                image={PipedGas}
                title={"Piped Gas"}
                selected={serviceSelected == 7}
              />
            </div>

            <Accordion.Toggle
              variant="link"
              eventKey="0"
              style={{
                borderRadius: "0rem",
                border: "none",
                background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
              }}
              className="m-0 p-0 d-flex justify-content-center w-100"
            >
              More Services{" "}
              <i className="mdi mdi-menu-down d-none d-sm-block text-dark"></i>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="0">
            <div className="row rouded-3 justify-content-center m-0 rounded-3">
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(1);
                }}
                image={Broadband}
                title={"Broadband"}
                selected={serviceSelected == 1}
              />
              {/* <BBPSIconCard
                onClick={() => {
                  setServiceSelected(16);
                }}
                image={BusTicketIcon}
                title={"Bus Ticket"}
                selected={serviceSelected == 16}
              /> */}
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(4);
                }}
                image={EducationIcon}
                title={"Education"}
                selected={serviceSelected == 4}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(6);
                }}
                image={FastagIcon}
                title={"Fastag Recharge"}
                selected={serviceSelected == 6}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(15);
                }}
                image={GasCylinderIcon}
                title={"Gas Cylinder"}
                selected={serviceSelected == 15}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(9);
                }}
                image={HospitalIcon}
                title={"Hospital"}
                selected={serviceSelected == 9}
              />
              {/* <BBPSIconCard
                onClick={() => {
                  setServiceSelected(9);
                }}
                image={HotelIcon}
                title={"Hotel"}
                selected={serviceSelected == 9}
              /> */}
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(10);
                }}
                image={HousingSocietyIcon}
                title={"HousingSociety"}
                selected={serviceSelected == 10}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(11);
                }}
                image={InsuranceIcon}
                title={"Insurance"}
                selected={serviceSelected == 11}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(12);
                }}
                image={LandlineIcon}
                title={"Landline"}
                selected={serviceSelected == 12}
              />
              <BBPSIconCard
                onClick={() => {
                  setServiceSelected(18);
                }}
                image={MuncipalTaxIcon}
                title={"MuncipalTax"}
                selected={serviceSelected == 18}
              />
            </div>
          </Accordion.Collapse>
        </Accordion>

        {/* Accordian */}

        <div
          className="row rouded-3 p-4 m-0"
          style={{ background: "#FFFFFF", borderRadius: "1rem" }}
        >
          <Form.Group className="col-6 p-1 m-0 px-0">
            <Form.Label>{`Select Provider`}</Form.Label>
            <Select
              options={providers}
              placeholder={`Select Provider`}
              onChange={(e) => {
                setState({
                  ...state,
                  provider: e,
                });
              }}
            />
          </Form.Group>
          {CustP.map((cust, i) => (
            <Form.Group key={cust.id} className="col-6 p-1 m-0 px-0">
              {cust.optional == "true" ? (
                <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
              ) : (
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {` ${cust.paramName}`}
                </Form.Label>
              )}
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter ${cust.paramName}`}
                onChange={(e) => {
                  const copy = [...CustP];
                  copy.forEach((ele) => {
                    if (ele.id == cust.id) {
                      ele.value = e.target.value;
                    }
                  });
                  setCustP(copy);
                }}
                value={cust.value}
              />
            </Form.Group>
          ))}

          <div className="col p-0 mr-2 mt-4 pt-1 ml-1 ">
            {state.provider &&
              (state.provider.fetchRequirement === "MANDATORY" ? (
                <React.Fragment>
                  <button className="btn btn-success" onClick={fetchBill}>
                    {" "}
                    Fetch Bill
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form.Group className="col-6 p-1 m-0 px-0">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Amount`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </Form.Group>
                  <button className="btn btn-success" onClick={payBill}>
                    {" "}
                    Pay Bill
                  </button>
                </React.Fragment>
              ))}
          </div>

          {billDetails && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    // openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.amount}
                  </td>
                </tr>

                {/* Additional Info */}
                {billDetails.additionalInfo &&
                  billDetails.additionalInfo.length > 0 &&
                  billDetails.additionalInfo.map((info) => (
                    <tr style={{ background: "none" }}>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.name}
                      </td>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.value}
                      </td>
                    </tr>
                  ))}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billDate}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Due Date.
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.dueDate}
                  </td>
                </tr>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Reference Id
                </td>
                <td
                  style={{
                    paddingLeft: "1rem",
                    border: "none",
                    background: "none",
                    width: "50%",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {billDetails.refId}
                </td>
              </table>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {PayClicked ? (
                  <Button className="rounded-0" variant="success">
                    {" "}
                    Paying ...
                  </Button>
                ) : (
                  <Button
                    className="rounded-0"
                    variant="success"
                    onClick={payBill}
                  >
                    {" "}
                    Pay Bill
                  </Button>
                )}
              </div>
            </Card>
          )}

          {billerResponse.msg && <BillerResponse msg={billerResponse.msg} />}
          {billerResponse.error.length > 0 &&
            billerResponse.error.map((err, key) => (
              <BillerResponse msg={err.errorDtl} />
            ))}
        </div>
      </div>
    </div>
  );
};

const BBPSIconCard = ({ image, title, selected, onClick }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center"
      style={{ background: "transparent" }}
      onClick={onClick}
    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default Bbps;
