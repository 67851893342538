import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "./app/utils/Constants";

const trackPageView = () => {
    console.log('Page view tracking started...');

    const urlParams = new URLSearchParams(window.location.search);
    console.log('URL parameters:', urlParams.toString());

    const referrer = document.referrer === '' ? 'direct' : document.referrer;
    console.log('Referrer:', referrer);

    const trackingData = {
        page_url: window.location.href,
        campaignid:urlParams.get('campaignid'),
        sessionid:urlParams.get('sessionid'),
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign'),
        utm_term: urlParams.get('utm_term'),
        utm_content: urlParams.get('utm_content'),
        referrer: referrer,
        user_agent: navigator.userAgent,
        timestamp: new Date().toISOString(),
        product:"BillPunch"
    };

    console.log('Tracking data to be sent:', trackingData);

    fetch(`${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/insertPageTracking`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(trackingData),
    })
    .then(response => {
        console.log('Response from server:', response);
        return response.json();
    })
    .then(data => console.log('Page view tracked successfully:', data))
    .catch(error => console.error('Error tracking page view:', error));
};

export default trackPageView;

