import React, { useContext, useEffect, useState } from "react";
import { Line,Pie } from "react-chartjs-2";
import {
  Addcategoryadminmodal,
  Addcategoryemployeemodal,
  Adddataemployeemodal,
  Addfixedexpenseemployeemodal,
} from "./Modal";

import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { Badge } from "react-bootstrap";
import { billpunchbasicauth } from "../../utils/Credentials";
import Calculator from "./Calculator";



const TrackingEmployee = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const Category = ["Bills", "Household", "Car", "Grocery", "House Keeping"];
  const [isLoading, setIsLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [showcategorymodal, setshowcategorymodal] = useState(false);
  const [moneydata, setmoneydata] = useState([]);
  const [showdatamodal, setshowdatamodal] = useState(false);
  const [filxedexpensemodal, setfilxedexpensemodal] = useState(false);
  const [fiexedexpensedata, setfiexedexpensedata] = useState([]);
  const [pieChartData, setPieChartData] = useState(null);
  const { password, username } = billpunchbasicauth;

  const openfixedexpensemodal = () => {
    setfilxedexpensemodal(true);
  };

  const closefixedexpensemodal = () => {
    setfilxedexpensemodal(false);
  };
  const opendatamodal = () => {
    setshowdatamodal(true);
  };

  const closedatamodal = () => {
    setshowdatamodal(false);
  };
  const openaddcategorymodal = () => {
    setshowcategorymodal(true);
  };

  const closeaddcategorymodal = () => {
    setshowcategorymodal(false);
  };
  const fetchExpenseCategories = async () => {
    setTransactionLoaderState(true);
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid:user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeDescription
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setExpenseCategories(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
    setIsLoading(false);
  };
  const fetchMoneymanagement = async () => {
    setTransactionLoaderState(true);
    setIsLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeMoneyManagement
          `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setmoneydata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
    setIsLoading(false);
  };
  const fetchFixedExpense = async () => {
    setTransactionLoaderState(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetMonthkyFixedExpense`,
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        setfiexedexpensedata(data.data);

        // // Process data for pie chart
        const expenseTypes = data.data.map((item) => item.expensetype);
        const amounts = data.data.map((item) => parseFloat(item.amount));

        const pieData = {
          labels: expenseTypes,
          datasets: [
            {
              data: amounts,
              backgroundColor: [
                "rgba(255, 99, 132, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(255, 206, 86, 0.6)",
                "rgba(75, 192, 192, 0.6)",
                "rgba(153, 102, 255, 0.6)",
              ],
            },
          ],
        };

        setPieChartData(pieData);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching fixed expenses:", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    fetchExpenseCategories();
    fetchMoneymanagement();
    fetchFixedExpense();
  }, []);
  return (
    <>
    <h4 className="pt-3 px-md-4 px-2">Personal Finance Management</h4>
        
       <div className="row ">
       <div className="col-md-5 col">
    <Calculator/>
          <h5 className="px-md-4 px-2">Check Your Fixed Expense</h5>
          {pieChartData && (
            <Pie
              data={pieChartData}
              options={{
                responsive: true,
                legend: {
                  display: false,
                },
              }}
            />
          )}

   </div>
        <div className="col-md-7 col">
          <div className="row m-0 p-0 pb-md-2 pb-1">
            <div className="col m-0 p-0 ">
              {" "}
              <h6> Money Management </h6>
            </div>
            <div className="col m-0 p-0 d-flex justify-content-end">
              <Badge
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "rgba(43, 193, 85, 0.1)",
                  color: "rgba(43, 193, 85, 1)",
                  bordercolor: "rgba(43, 193, 85, 1)",
                  borderRadius: ".4rem",
                  border: "1px solid",
                  fontSize: ".8rem",
                  width: "3rem",
                  height: "1.5rem",
                }}
                onClick={opendatamodal}
              >
                {" "}
                Add
              </Badge>{" "}
            </div>
          </div>
          <div className="" style={{ height: "", overflow: "auto" }}>
            <table className="table table-bordered m-0 p-0 ">
              <thead>
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Date
                  </th>

                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {moneydata.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  moneydata.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.name || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.date || "NA"}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        {item.amount || "NA"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        <div className="row">
        <div
            className="col card m-0 p-2 mt-2 custom-scrollbaractivity"
            style={{
              background: "#fff",
              background: "rgba(0, 71, 171, 0.1)",
              overflowY: "auto",
              overflowX: "hidden",
              height:"50vh"
            }}
          >
            <div className="row ">
              <h6 className="col"> Fixed Expense</h6>
              <div className="col p-1 d-flex justify-content-end mr-3 ">
                <Link onClick={openfixedexpensemodal}>
                  {" "}
                  <FaPlusCircle style={{ color: "#0047AB" }} />
                </Link>
              </div>
            </div>
            <ul className="list-group">
              {fiexedexpensedata.length > 0 ? (
                fiexedexpensedata.map((Fixed, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    style={{ border: "none" }}
                  >
                    <div className="d-flex justify-content-between">
                      <span>{Fixed.expensetype}</span>
                      <span style={{ color: "red" }}>{Fixed.amount}</span>
                    </div>
                  </li>
                ))
              ) : (
                <li className="list-group-item" style={{ border: "none" }}>
                  <div className="d-flex justify-content-center">
                    No data found
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="col-md-4 col">
          <div className="card m-0 p-md-2 mt-2" style={{ background: "#fff" }}>
            <div className="row ">
              <h6 className="col p-1"> Category</h6>
              <div className="col p-1 d-flex justify-content-end mr-3 ">
                <Link onClick={openaddcategorymodal}>
                  {" "}
                  <FaPlusCircle style={{ color: "#0047AB" }} />
                </Link>
              </div>
            </div>
            <ul className="list-group">
              {expenseCategories.length > 0 ? (
                expenseCategories.map((category, index) => (
                  <li
                    key={index}
                    className="list-group-item p-1"
                    style={{ border: "none" }}
                  >
                    <span>{category.category}</span>
                  </li>
                ))
              ) : (
                <li className="list-group-item p-1" style={{ border: "none" }}>
                  <div className="d-flex justify-content-center">
                    No data found
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        </div>
     
        </div>
       
       
        
      
      </div>
    


   <Addcategoryemployeemodal
          show={showcategorymodal}
          hide={closeaddcategorymodal}
          fetchExpenseCategories={fetchExpenseCategories}
        />
        <Addfixedexpenseemployeemodal
          show={filxedexpensemodal}
          hide={closefixedexpensemodal}
          fetchExpenseCategories={fetchExpenseCategories}
          expenseCategories={expenseCategories}
          fetchFixedExpense={fetchFixedExpense}
        />
        <Adddataemployeemodal
          show={showdatamodal}
          hide={closedatamodal}
          fetchMoneymanagement={fetchMoneymanagement}
        />
    </>
  );
};

export default TrackingEmployee;
