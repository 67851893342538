import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Nav, Tabs, Tab, Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { offsetArr } from "../../utils/CommonConfig";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import {
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const RecentActivity = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const refresh = () => {
    // getLevelsData();
  };
  const updateLevelModal = (level) => {
    //  setSelectedLevel(level);
    // setIsOpen2(true);
  };
  useEffect(() => {
    // getLevelsData();
  }, []);
  return (
    <div
      className="tab-custom-pills-horizontal m-0 p-0"
      style={{ background: "#EDF7FF" }}
    >
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="p-0 m-0">
          <Col xs={12} className="p-0 m-0">
            <Nav
              variant="pills"
              className="nav-pills-custom justify-content-around p-1"
              style={{
                borderRadius: "1rem",
                padding: "1rem",
                border: "1px solid #2F58CD",
                background: "#FFF",
              }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="d-flex align-items-center"
                >
                  DMT
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className="d-flex align-items-center"
                >
                  AEPS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className="d-flex align-items-center"
                >
                  BBPS
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} className="p-0">
            <Tab.Content className="tab-content-custom-pill p-0">
              <Tab.Pane eventKey="first">
                <DMTReports />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <AEPSReports />{" "}
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <BBPSReports />{" "}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export const DMTReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [dmtTransactions, setDmtTransactions] = useState([]);

  const Last5DMT = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setDmtTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Last5DMT();
  }, []);
  return (
    <div className="row w-100 p-0 m-0">
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          {dmtTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export const AEPSReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [AEPSTransactions, setAEPSTransactions] = useState([]);

  const Last5AEPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_aeps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setAEPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Last5AEPS();
  }, []);
  return (
    <div className="row w-100 p-0 m-0">
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          {AEPSTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export const BBPSReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [bbpsTransactions, setBBPSTransactions] = useState([]);

  const Last5BBPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_bbps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setBBPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Last5BBPS();
  }, []);
  return (
    <div className="row w-100 p-0 m-0">
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          {bbpsTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default RecentActivity;
