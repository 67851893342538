import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Form } from "react-bootstrap";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { FaPenAlt, FaRegEdit } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { IoReceipt } from "react-icons/io5";
import { SiMinutemailer } from "react-icons/si";
const Form16admin = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [taxDetails, settaxDetails] = useState([]);
  const { password, username } = billpunchbasicauth;
  const [selectedYear, setSelectedYear] = useState("");

  const Gettaxdetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetTaxDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        settaxDetails(data.data);
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    Gettaxdetails();
  }, []);

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 5; i--) {
      const yearRange = `${i}-${(i + 1).toString().slice(2)}`;
      years.push(yearRange);
    }
    return years;
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <div className="p-3 mt-2">
        <div className="d-flex align-items-center">
          <h5 className="px-2">Form 16</h5>
          <Form.Group controlId="yearDropdown">
            <Form.Control
              as="select"
              value={selectedYear}
              onChange={handleYearChange}
              style={{ height: "2.2rem" }}
            >
              <option value="" disabled>
                Select Year
              </option>
              {getYears().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <hr className="m-2" />
        <p
          className="d-flex justify-content-center"
          style={{ fontSize: "1rem" }}
        >
          It's time to generate Form 16 for the financial year
        </p>
        <hr className="text-center w-25" />
        <h5 className="text-center">
          Verify your tax deductor before you generate Form 16
        </h5>
        <div className="row d-flex justify-content-center">
          <div
            className="card col-md-4 col  m-0 p-2"
            style={{  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="row">
              <div className="col m-0 px-2 ">
                <img
                  src="https://via.placeholder.com/100"
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: "5rem" }}
                />
              </div>
              {taxDetails.map((item, index) => (
                <div key={index} className="col-md-7 m-0 p-1 col-12">
                  <h6>{item.deductor_name ||"NA"}</h6>
                  <p>Son / Daughter of {item.deductor_father_name || "NA"}</p>
                </div>
              ))}

              <div className="col m-0 p-0 text-center">
                <Link to={"/retailer/Tax"} className="">
                  <FaRegEdit />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center">
          <span style={{ color: "red" }}>Note:</span> Remember that once you
          generate Form 16, you cannot change the deductor details
        </p>
        <div className=" mt-4 mb-2 d-flex justify-content-center">
          <Link to="/retailer/Generate_form16_admin">
            <Button className="">Generate Form 16</Button>
          </Link>
        </div>
      </div>
      <div className="p-3" style={{ background: "#FBFCFF" }}>
        <h6 className="text-center m-3">
          How to generate Form 16 for your employees?
        </h6>
        <div className="d-flex justify-content-center">
          <div
            className="card m-0 p-3 "
            style={{
              borderRadius: ".4rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              width: "80%",
            }}
          >
            <p className="m-0" style={{ fontSize: ".8rem", fontWeight: "600" }}>
              STEPS TO GENERATE FORM 16
            </p>
            <div
              className="d-flex align-items-center  mt-3"
              style={{ justifyContent: "space-evenly" }}
            >
              <div className="d-flex flex-column align-items-center mx-2">
                <BsFillCloudArrowUpFill
                  style={{ fontSize: "2rem", color: "rgb(227, 62, 101)" }}
                />
                <p className="mx-2">Upload Form16 Part A</p>
              </div>
              <TbPlayerTrackNextFilled
                style={{ color: "grey", fontSize: "1rem" }}
              />
              <div className="d-flex flex-column align-items-center mx-2">
                <IoReceipt
                  style={{ fontSize: "2rem", color: "rgb(255, 191, 98)" }}
                />
                <p className="mx-2">Generate Form16</p>
              </div>
              <TbPlayerTrackNextFilled
                style={{ color: "grey", fontSize: "1rem" }}
              />
              <div className="d-flex flex-column align-items-center mx-2">
                <FaPenAlt
                  style={{ fontSize: "2rem", color: "rgb(118, 203, 255)" }}
                />
                <p className="mx-2">Sign Form16</p>
              </div>
              <TbPlayerTrackNextFilled
                style={{ color: "grey", fontSize: "1rem" }}
              />
              <div className="d-flex flex-column align-items-center mx-2">
                <SiMinutemailer
                  style={{ fontSize: "2rem", color: "rgb(3, 186, 102)" }}
                />
                <p className="mx-2">Email</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form16admin;
