import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { Doughnut, Pie } from "react-chartjs-2";

import {
  excelMapping,
  ExportToExcel,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {  ProcessExpenseAdminModal,  } from "./Modal";
import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";
export const columns = [
 

  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "exense_name",
    text: "Expense",
  },
  {
    dataField: "bankaccno",
    text: "Bank ",
  },
  {
    dataField: "bankname",
    text: "Acount",
  },
  {
    dataField: "bankifsc",
    text: "IFSC",
  },

  {
    dataField: "month",
    text: "Month",
  },
  {
    dataField: "agent_onboarded",
    text: "Onboarding",
  },
  {
    dataField: "net_amount",
    text: "NetAmount",
  },

  {
    dataField: "expense_amount",
    text: "ExpenseAmount",
  },
];

const Expensepayrollhr = () => {
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "35%",
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };
  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];
const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const [clickedRows, setClickedRows] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState({});
  const { password, username } = billpunchbasicauth;
  const [generatedsalary, setGeneratedSalary] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Expensemodal, setExpensemodal] = useState(false);
  const openExpenseModal = () => {
    setExpensemodal(true);
  };

  const closeExpenseModal = () => {
    setExpensemodal(false);
  };
 
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({  firmid:  user.firm_id,});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleProcessAll = async (
    fk_empid,
    net_amount,
    bankname,
    bankifsc,
    bankaccno,
    name
  ) => {
    let transactionMode = selectedServiceType[fk_empid];
    if (!transactionMode) {
      transactionMode = "IMPS";
    }

    if (clickedRows.includes(fk_empid)) {
      swal("Error", "Button already clicked for this row", "error");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          purpose: "FIXED EXPENSE",
          userid: fk_empid,
          amount: net_amount,
          tpin: "1",
          serviceType: transactionMode,
          accNo: bankname,
          ifsc: bankifsc,
          bankName: bankaccno,
          mobile: "string",
          custName: name,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/SalaryManagement/SalaryPayroll`,

        // "https://salary.shreedevs.com/api/Salary/SalaryManagement/SalaryPayroll",
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        getEmployeegeneratedList();
      } else {
        swal("Error", data.msg, "error");
        getEmployeegeneratedList();
      }
      setClickedRows((prevClickedRows) => [...prevClickedRows, fk_empid]);
    } catch (error) {
      console.error("API call failed", error);
      swal("API call failed", "An error occurred", "error");
    }
  };

  const getEmployeegeneratedList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const raw = JSON.stringify({
        month: selectedMonth,
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFixedExpense`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        setGeneratedSalary(responseData.data);
      } else {
        setGeneratedSalary([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const [dashboard, setDashboard] = useState(null);
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          month: selectedMonth,
          firmid: user.firm_id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFixedExpenseDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData.data[0] || {};
          setDashboard(dashboardData);
        } else {
          setDashboard({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);

  useEffect(() => {
    getEmployeegeneratedList();
  }, [selectedMonth]);
  useEffect(() => {
    getDepartmentList();
  }, []);
  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const pieData = {
    labels: ["Total Generated", "Total Processed", "Total Unprocessed"],
    datasets: [
      {
        data: [
          parseFloat(dashboard?.total_generated) || 0,
          parseFloat(dashboard?.processed) || 0,
          parseFloat(dashboard?.unprocessed) || 0,
        ],
        backgroundColor: ["#FF8E8F", "#6196A6", "#7469B6"],
      },
    ],
  };

  const filteredData = generatedsalary
  .filter((e) =>
    selectedMonth ? e.month === selectedMonth : "January"
  )
  .filter((e) =>
    selectedDepartment ? e.department === selectedDepartment : true
  )
  .filter((e) => {
    const fullName =
      `${e.name} ${e.fk_empid} ${e.exense_name} ${e.epf} ${e.bankname} ${e.bankaccno} ${e.bankifsc} ${e.month} ${e.agent_onboarded}`.toLowerCase();
    return !searchQuery || fullName.includes(searchQuery.toLowerCase());
  });

  const handleSelectChange = (selectedOption, pk_emp_id) => {
    setSelectedServiceType({
      ...selectedServiceType,
      [pk_emp_id]: selectedOption.value,
    });
  };

  console.log(selectedServiceType);
  const { accessData } = useContext(GlobalContext);
 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginationOptions = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];
  const handleItemsPerPageChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const selectOptions = paginationOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  return (
    <div className="m-2">
        <ProcessExpenseAdminModal isOpen={Expensemodal} closeModal={closeExpenseModal} />
          

      <div className="row">
        <h5
          className="col px-3 pb-2"
          style={{
            color: "#4267B2",
          }}
        >
          Process Expense
        </h5>
        <div className="col d-flex mr-2  justify-content-end">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(`salary_ledger${now()}`, excelMapping(generatedsalary, columns));
            }}
          >
            <img
              alt=""
              src={require("../../../assets/images/exportexcel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
      </div>
      <div
        className="row mx-2  "
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div
          className="col-md-2 col-4 card   "
          style={{
            border: "1px solid #FF8E8F",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #FF8E8F",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Generated
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#FF8E8F",
            }}
          >
            {parseFloat(dashboard?.total_generated) || 0}
          </p>
        </div>
        <div
          className="col-md-2 col-4 card   "
          style={{
            border: "1px solid #6196A6",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #6196A6",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />{" "}
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Processed
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#6196A6",
            }}
          >
            {parseFloat(dashboard?.processed) || 0}
          </p>
        </div>
        <div
          className="col-md-2 col-4 card  "
          style={{
            border: "1px solid #7469B6",
            borderRadius: ".4rem",
            height: "fit-content",
            boxShadow: "0 0 6px #7469B6",
          }}
        >
          <img
            alt=""
            style={{
              height: "2rem",
            }}
            src={require("../../../assets/images/pms/salary_report.svg")}
          />{" "}
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: ".9rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Total Unprocessed
          </p>
          <p
            classname="d-flex justify-content-center"
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: "#7469B6",
            }}
          >
            {parseFloat(dashboard?.unprocessed) || 0}
          </p>
        </div>

        <div className="col-md-4 col-5 " style={{ borderRadius: ".4rem" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <Pie
              data={pieData}
              options={{
                ...pieOptions,
                maintainAspectRatio: false,
                responsive: true,
                height: 200,
              }}
            />
          </div>
        </div>
      </div>
      <div className="row  ">
      <div className="col">
  <div className="d-flex align-items-center px-2">
    <div className="px-2">
    <select className="px-2"
      style={{
        width: "5rem",
        height: "2rem",
        borderRadius: ".4rem",
        border: "1px #ced4da solid",
      }}
      value={selectedYear}
      onChange={handleYearChange}
    >
      <option value="2023">2023</option>
      <option value="2024">2024</option>
      <option value="2025">2025</option>
      <option value="2026">2026</option>
    </select>
    </div>
  
    <div className="px-2">
    <select
      style={{
        width: "5rem",
        height: "2rem",
        borderRadius: ".4rem",
        border: "1px #ced4da solid",
      }}
      value={selectedMonth}
      onChange={handleMonthChange}
    >
      {months.map((month) => (
        <option key={month.value} value={month.value}>
          {month.name}
        </option>
      ))}
    </select>
</div>
<div className="px-2">
    <select
      style={{
        width: "9rem",
        height: "2rem",
        borderRadius: ".4rem",
        border: "1px #ced4da solid",
      }}
      value={selectedDepartment}
      onChange={(e) => {
        setSelectedDepartment(e.target.value);
      }}
    >
      <option value="">All Department</option>
      {departmentData.map((dept) => (
        <option key={dept.id} value={dept.cat1}>
          {dept.cat1}
        </option>
      ))}
    </select>
    </div>
  </div>
</div>
    
     
        <div className="col d-flex justify-content-end col">
          <Button
            style={{
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
              border: "1px solid",
              height: "2.5rem",
            }}
            onClick={openExpenseModal}
          >
            Generate Expense
          </Button>
        </div>

        <div style={searchBarStyle} className=" col-2 mr-md-4 ">
          <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
          <input
            type="text"
            className="form-control "
            placeholder="Search"
            aria-label="Search"
            value={searchQuery}
            style={inputStyle}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <hr className="m-0 p-0" />

      <div className="scroll-inner admin-fix-width borderless-table">
        <table
          className="my-table mx-2 transparent-table text-center border-bottom"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <thead
            className="borderless-table text-center border-bottom"
            style={{ background: "white", color: "black" }}
          >
            <tr>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Sr
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Name
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Expense
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Bank
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Month
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Agent Onboarding
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Net Amount
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Amount
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Type
              </th>
              <th
                className="text-center"
                style={{
                  background: "white",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Process Salary
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="10">
                  <div
                    className="m-5 d-flex justify-content-center"
                    style={{ fontSize: "1rem", fontWeight: "400" }}
                  >
                    No Data Available
                  </div>
                </td>
              </tr>
            ) : (
              currentItems.map((e, ind) => (
                <tr key={ind} className="border-bottom">
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {ind + 1}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.name}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.exense_name}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    <div>
                      {e.bankaccno}
                      <br />
                      {e.bankifsc}
                      <br />
                      {e.bankname}
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.month}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.agent_onboarded}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.net_amount}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {e.expense_amount}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    <Select
                      styles={{ width: "9rem" }}
                      className="px-1"
                      placeholder="Select Type"
                      value={{
                        value: selectedServiceType[e.fk_empid] || "IMPS",
                        label: selectedServiceType[e.fk_empid] || "IMPS",
                      }}
                      options={[
                        { value: "IMPS", label: "IMPS" },
                        { value: "NEFT", label: "NEFT" },
                      ]}
                      isClearable
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, e.fk_empid)
                      }
                    />
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem" }}
                  >
                    {accessData &&
                      accessData.map(
                        (module) =>
                          module.data &&
                          module.data.map((role) =>
                            role.role_name === "Proceed Expense" ? (
                              role.access === "Y" ? (
                                <span key={role.pk_role_id}>
                                  {e.status === "Y" ? (
                                    <div>
                                      <Button
                                        className="d-flex align-items-center btn-success"
                                        style={{
                                          height: "2rem",
                                          fontSize: "1rem",
                                          borderRadius: ".4rem",
                                          fontSize: ".8rem",
                                        }}
                                        disabled={true}
                                      >
                                        Processed
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>
                                      {/* <Button
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "2rem",
                                    fontSize: "1rem",
                                    borderRadius: ".4rem",
                                    fontSize: ".7rem",
                                  }}
                                  onClick={() =>
                                    handleProcessAll(
                                      e.fk_empid,
                                      e.net_amount,
                                      e.bankname,
                                      e.bankifsc,
                                      e.bankaccno,
                                      e.name
                                    )
                                  }
                                >
                                  Process
                                </Button> */}
                                    </div>
                                  )}
                                </span>
                              ) : (
                                <span key={role.pk_role_id}>
                                  {e.status === "Y" ? (
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary d-flex justify-content-center"
                                        style={{
                                          color: "#fff",
                                          height: "2rem",
                                          border: "none",
                                          borderRadius: ".4rem",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: ".8rem",
                                          backgroundColor: "gray",
                                          cursor: "not-allowed",
                                        }}
                                        disabled
                                      >
                                        Processed
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary d-flex justify-content-center"
                                        style={{
                                          color: "#fff",
                                          height: "2rem",
                                          border: "none",
                                          borderRadius: ".4rem",
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: ".8rem",
                                          backgroundColor: "gray",
                                          cursor: "not-allowed",
                                        }}
                                        disabled
                                      >
                                        Process
                                      </button>
                                    </div>
                                  )}
                                </span>
                              )
                            ) : null
                          )
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="m-3 ">
                <label>Items per page:</label>
                <div style={{ width: "6rem" }}>
                  <Select
                    options={selectOptions}
                    value={{ value: itemsPerPage, label: `${itemsPerPage}` }}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "4rem" }}
                  />
                </div>
              </div>
    </div>
  );
};

export default Expensepayrollhr;
