import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import { BASE_URL_DOC_KYC } from "../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../utils/StringConstants";

const NomineeDetails = () => {
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const [formValues, setFormValues] = useState({
    NomineeName: "",
    Gender: "",
    NomineeAge: "",
    Relation: "",
    AdharCard: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const steps = localStorage.getItem("steps");
  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_DOC_KYC}/api/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          history.push("/kyc/user-kyc-view");
        }
        setKycData(data.data[0]);
        setFormValues({
          ...formValues,
          NomineeName: data.data[0].nomineename,
          NomineeAge: data.data[0].nomineeage,
          Relation: data.data[0].relation,
          AdharCard: data.data[0].nomineeaadhar,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUserKycData();
  }, []);
  const redirect = () => {
    history.push("/kyc/document-upload");
  };

  const Back = () => {
    history.push("/kyc/document-details");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Nominee();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.NomineeName) {
      errors.NomineeName = "*Nominee Name is required!";
    }
    if (!values.NomineeAge) {
      errors.NomineeAge = "*Nominee Age is required!";
    }
    if (!values.Gender) {
      errors.Gender = "*Gender is required!";
    }
    if (!values.Relation) {
      errors.Relation = "*Relation is required!";
    }
    if (!values.AdharCard) {
      errors.AdharCard = "*Adhar number is required!";
    } else if (values.AdharCard.length != 12) {
      errors.AdharCard = "*Enter valid Adhar number";
    }

    return errors;
  };

  const Nominee = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      steps: "4",
      fkuserid: userId.pk_user_id,
      nomineename: formValues.NomineeName,
      relation: formValues.Relation,
      nomineeaadhar: formValues.AdharCard,
      gender: formValues.Gender,
      nomineeage: formValues.NomineeAge,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(`${BASE_URL_DOC_KYC}/api/submitkycdoc.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            redirect();
            localStorage.setItem("steps", 4);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log();
    }
  };

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      <div
        className="card"
        style={{ backgroundColor: "aliceblue", textAlign: "center" }}
      >
        <Button
          onClick={() => {
            history.push("/");
          }}
          style={{ maxWidth: "10rem", margin: "1rem 0rem" }}
        >
          Back To Home{" "}
        </Button>
        <div
          style={{
            backgroundColor: "#0097C5",
            height: "8vh",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        ></div>
        <Stepper activeStep={steps} style={{ padding: "15px" }}>
          <Step label="Business Details" />
          <Step label="Personal Details" />
          <Step label="Document Details" />
          <Step label="Nominee Details" />
          <Step label="Document Upload" />
        </Stepper>
        <div className="card-body">
          <Row>
            <small>
              <p className="mt-2 mb-2" style={{ color: "#5bdb5b" }}>
                Document details updated successfully
              </p>
            </small>
            <Col md={12} lg={12} sm={12}>
              <div
                className="p-2"
                style={{
                  backgroundColor: "#0097C5",
                  color: "white",
                  boxShadow:
                    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                <b>Nominee Details</b>
              </div>
            </Col>

            <Col md={6} lg={6} sm={12}>
              <Form.Label>
                <b>Nominee Name: </b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Nominee Name Here"
                name="NomineeName"
                value={formValues.NomineeName}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.NomineeName}</p>
            </Col>

            <Col md={3} lg={3} sm={6}>
              <Form.Label>
                <b>Select Gender: </b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>

              <div style={{ justifyContent: "space-evenly", display: "flex" }}>
                <div>
                  <input
                    type="radio"
                    id="Male"
                    name="Gender"
                    value="Male"
                    onChange={handleChange}
                  />
                  <label htmlFor="Male"> Male</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="Female"
                    style={{ marginLeft: "10px" }}
                    name="Gender"
                    value="Female"
                    onChange={handleChange}
                  />
                  <label htmlFor="Female"> Female</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="Other"
                    style={{ marginLeft: "10px" }}
                    name="Gender"
                    value="Other"
                    onChange={handleChange}
                  />
                  <label htmlFor="Other"> Other</label>
                </div>
                <p style={{ color: "red" }}>{formErrors.Gender}</p>
              </div>
            </Col>

            <Col md={3} lg={3} sm={6}>
              <Form.Label>
                <b>Nominee Age:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Nominee Age Here"
                name="NomineeAge"
                value={formValues.NomineeAge}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.NomineeAge}</p>
            </Col>

            <Col md={6} lg={6} sm={12} className="mt-2">
              <Form.Label>
                <b>Relation :</b>
              </Form.Label>
              <Form.Control
                as="select"
                name="Relation"
                value={formValues.Relation}
                onChange={handleChange}
              >
                <option>Select Relation</option>
                <option>Relation</option>
              </Form.Control>
              <p style={{ color: "red" }}>{formErrors.Relation}</p>
            </Col>
            <Col md={6} lg={6} sm={12} className="mt-2">
              <Form.Label>
                <b>Adhar Card Number:</b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Adhar Card Number Here"
                name="AdharCard"
                value={formValues.AdharCard}
                onChange={handleChange}
              />
              <p style={{ color: "red" }}>{formErrors.AdharCard}</p>
            </Col>

            <Col md={6} lg={6} sm={12}>
              <small>
                <p className="mt-2" style={{ color: "red" }}>
                  All Fields(*) marked are mandatory
                </p>
              </small>
            </Col>
            <Col md={3} lg={3} sm={6} className="mt-4">
              <Button
                className="form-control"
                variant="outline-success"
                onClick={handleSubmit}
              >
                Next
              </Button>
            </Col>
            <Col md={3} lg={3} sm={6} className="mt-4">
              <Button
                className="form-control"
                variant="outline-danger"
                onClick={Back}
              >
                Back
              </Button>
            </Col>
          </Row>
        </div>
      </div>
  </div>
  );
};

export default NomineeDetails;
