const createVirtualAccountUrl = "/api/VirtualInfo/CreateVirtualAccount";
const getAllVirtualAccountsUrl = "/api/VirtualInfo/GetAllVirtualAccount";
const getAllVirtualPaymentsUrl = "/api/VirtualInfo/GetAllVirtualPayment";
const GetUserProfileInfoUrl = "/api/Profile/GetUserProfileInfo";
const getCustomersUrl = "/api/Report/GetCustomers";
const getBeneficiaryUrl = "/api/Report/GetBeneficiary";
const getAgentsUrl = "/api/Report/GetAgent";

const loginUrl = "/api/Account/Login";
const updateWebhookUrl = "/api/Webhook/UpdateWebhook";
const createWebhookUrl = "/api/Webhook/CreateWebhook";
const getWebhooksUrl = "/api/Webhook/GetWebhook";
const topupUrl = "/api/Topup/TopUpRequest";
const getTransactionsUrl = "/api/Report/GetTransactions";
const devBaseUrl = "https://apiuat.paynnow.com";
const prodBaseUrl = "https://apitranxt.paynnow.com";
const base = prodBaseUrl;
const urls = {
  createVirtualAccount: `${base}${createVirtualAccountUrl}`,
  getAllVirtualAccounts: `${base}${getAllVirtualAccountsUrl}`,
  getAgents: `${base}${getAgentsUrl}`,
  getAllVirtualPayments: `${base}${getAllVirtualPaymentsUrl}`,
  getBeneficiary: `${base}${getBeneficiaryUrl}`,
  getCustomers: `${base}${getCustomersUrl}`,
  GetUserProfileInfo: `${base}${GetUserProfileInfoUrl}`,
  login: `${base}${loginUrl}`,
  updateWebhook: `${base}${updateWebhookUrl}`,
  createWebhook: `${base}${createWebhookUrl}`,
  getWebhooks: `${base}${getWebhooksUrl}`,
  topup: `${base}${topupUrl}`,
  getTransactions: `${base}${getTransactionsUrl}`
};
export { urls };
