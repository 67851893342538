import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Identity = () => {
    const history = useHistory();
      const login = () => {
        const callbackUrl = `${window.location.origin}/identity`;
        const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
        window.location.href = redirectUrl; 
      };
      const { user, setTransactionLoaderState } = useContext(GlobalContext);
    
      const queryParameters = new URLSearchParams(window.location.search);
      const tokenid1 = queryParameters.get("accessToken");
      const Getssologin = async () => {
        setTransactionLoaderState(true);
        try {
          console.log("Initiating SSO login...");

          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
    
          const raw = JSON.stringify({
            token: tokenid1,
            product: "BillPunch",
          });
          console.log("Request Payload:", raw);

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          console.log("Request Options:", requestOptions);

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/BillPunchSSOLoginV1`,
            requestOptions
          );
          console.log("Response Status:", res.status);
   
          const data = await res.json();
          

          if (data.responseCode == "00") {
            console.log("API Response Data:", data.response);
            const { us_type } = data.response;
            if (us_type === "E") {
              console.log("Redirecting to Employee Dashboard");
              history.push("/retailer/EmployeeDashboard");
            } else if (us_type === "A") {
              console.log("Redirecting to Admin Dashboard");
              history.push("/retailer/AdminDashboard");
            } else if (us_type === "H") {
              console.log("Redirecting to HR Dashboard");
              history.push("/retailer/HRDashboard");
            } else if (us_type === "S") {
              console.log("Redirecting to Superadmin Dashboard");
              history.push("/retailer/Superadmin_dashboard");
            }
          } else if (data.status === "01") {
            console.log("User status 01 - Login required");
            login();
          } else if (data.status === "02") {
            console.log("User status 02 - Redirecting to login");
            history.push("/Login");
          }
    
          setTransactionLoaderState(false);
        } catch (error) {
          console.log("error", error);
          setTransactionLoaderState(false);
        }
      };
    
      useEffect(() => {
        if (tokenid1) {
          Getssologin();
        }
      }, [tokenid1]);
    
  return (
    <>
<div>

</div>
    </>
  );
};

export default Identity;
