import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Tabs, Tab } from "react-bootstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import { ProgressBar } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import Transactions,{Payin,Payout} from "./Transactions";
import { urls } from "./devUrls";
import Topup from "./Topup";
import SmartCollectVirtualAccountTable from "./SmartCollectVirtualAccountTable";
import SmartCollectPaymentsTable from "./SmartCollectPaymentsTable";
import Sidebarnew from "./Sidebarnew";

const MyAccount = () => {
  const [details, setDetails] = useState({});
  let {
    myAccountInfo: info,
    setMyAccountInfo,
    logoutDeveloperLogin,
  } = useContext(GlobalContext);
  const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
  if (info == null) {
    info = {};
  }
  const history = useHistory();
  const amountDueBarData = {
    labels: [
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Day 8",
      "Day 9",
      "Day 10",
    ],
    datasets: [
      {
        label: "Profit",
        data: [39, 19, 25, 16, 31, 39, 12, 18, 33, 24],
        backgroundColor: [
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
        ],
        borderColor: [
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
        ],
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const amountDueBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: false,
          },
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
    stepsize: 10,
  };

  const usersDoughnutChartData = {
    datasets: [
      {
        data: [80, 34, 100],
        backgroundColor: ["#44ce42", "#b66dff", "#d8d8d8"],
        borderColor: ["#44ce42", "#b66dff", "#d8d8d8"],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Pink", "Blue", "Yellow"],
  };

  const usersDoughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    legend: {
      display: false,
    },
  };
  useEffect(() => {
    getAccountInfo();
  }, []);

  const getAccountInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(GetUserProfileInfoUrl, requestOptions);
      const data = await res.json();
      setMyAccountInfo(data.data);
    } catch (error) {}
  };

  if (info == null) {
    history.push("/error-pages/error-404");
    return;
  }

  return (
    <div
      style={{ borderRadius: "22px", boxShadow: DROP_SHADOW }}
      className="card m-4 p-0"
    >
      <div className="row justify-content-between m-0 px-4 py-2">
        <h2
          style={{
            margin: "0",
            fontWeight: "bolder",
          }}
        >
          My Account
        </h2>
        <button className="btn btn-danger mx-4" onClick={logoutDeveloperLogin}>
          Log Out
        </button>
      </div>
      <Sidebarnew details={details} />

      <div className="card-body p-1">
        <Tabs defaultActiveKey="Profile" id="uncontrolled-tab-example">
          <Tab
            eventKey="Profile"
            title="Profile"
            className="test-tab border-neglect"
          >
            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "10px" }} className="card">
                <h5>2 Step Verification</h5>
                <p>
                  Add an extra layer of security to your account by using a
                  one-time verification code in addition to your password each
                  time you log in.
                </p>
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard
                  label={`Merchant Id: ${info.vAccountNumber}`}
                  value={"Change Password"}
                />

                <DetailCard
                  label={"Contact Name"}
                  value={info.name ? info.name : "-"}
                />
                <DetailCard
                  label={"Contact Email"}
                  value={info.email ? info.email : "-"}
                />
                <DetailCard
                  label={"Contact Number"}
                  value={info.phoneNumber ? info.phoneNumber : "-"}
                />
                <DetailCard
                  label={"Business Name"}
                  value={info.businessName ? info.businessName : "-"}
                />
                <DetailCard
                  label={"Business Type"}
                  value={info.businessType ? info.businessType : "-"}
                />
                <DetailCard
                  label={"Registration Date"}
                  value={info.createdOn ? info.createdOn : "-"}
                />
                <DetailCard label={"Registered By"} value={"--"} />
                <DetailCard
                  label={"Account Activated On"}
                  value={" Aug 12 2019, 01:03 pm"}
                />
                <DetailCard label={"Account Access"} value={"Complete"} />
                <DetailCard
                  label={"Business Website/App details"}
                  value={"https://bumppy.com"}
                />
                <DetailCard
                  label={"Additional Business Website/App"}
                  value={
                    info.additionalWebsiteDetail
                      ? info.additionalWebsiteDetail
                      : "-"
                  }
                />
                <DetailCard
                  label={"Limit per transaction"}
                  value={
                    info.limitPerTransaction ? info.limitPerTransaction : "-"
                  }
                />
                {info.contactName ? (
                  <DetailCard label={"Contact Name"} value={info.contactName} />
                ) : null}
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard value={""} label={"Support Details"} />

                <DetailCard
                  label={"Phone No"}
                  value={info.supportPhoneNo ? info.supportPhoneNo : "-"}
                />
                <DetailCard
                  label={"Email id"}
                  value={info.supportEmailId ? info.supportEmailId : "-"}
                />
                <DetailCard
                  label={"Website/Contact us link"}
                  value={
                    info.supportWebsiteLink ? info.supportWebsiteLink : "-"
                  }
                />
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard value={""} label={"GST Details"} />

                <DetailCard
                  label={"GST Details"}
                  value={info.gstDetails ? info.gstDetails : "-"}
                />
                <DetailCard
                  label={"Bumppy's GST Number"}
                  value={info.gstNumber ? info.gstNumber : "-"}
                />
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard value={""} label={"Bank Account"} />

                <DetailCard
                  label={"IFSC Code"}
                  value={info.ifscCode ? info.ifscCode : "-"}
                />
                <DetailCard
                  label={"Account Number"}
                  value={info.accountNumber ? info.accountNumber : "-"}
                />
                <DetailCard
                  label={"Beneficiary"}
                  value={"Bumppy Media Pvt Ltd"}
                />
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailCard
                  label={"Login Email"}
                  value={"sumitvermacr7@gmail.com"}
                />
                <DetailCard
                  label={"Role"}
                  value={info.roleName ? info.roleName : "-"}
                />
                <DetailCard
                  label={"Beneficiary"}
                  value={"Bumppy Media Pvt Ltd"}
                />
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard value={""} label={"Upgrade Merchant"} />

                <div style={{ padding: "0px" }} className="card">
                  <div style={{ padding: "8px" }} className="card-body">
                    <span className="row justify-content-center">
                      You can upgrade your account to a Merchant Account by
                      giving us your business name
                    </span>

                    <div className="row justify-content-center">
                      <label
                        style={{ margin: "5px", padding: "10px" }}
                        htmlFor="exampleInputtext"
                        className="text-center"
                      >
                        Business Name:
                      </label>
                      <Form.Control
                        style={{ margin: "10px" }}
                        type="email"
                        className="col-sm-3 form-control"
                        id="exampleInputtext"
                        placeholder="Acme Inc."
                      />
                      <button
                        style={{ margin: "10px" }}
                        type="button"
                        className="btn btn-primary btn-fw"
                      >
                        Primary
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard
                  value={"View Settlement Schedule"}
                  label={"Settlement Details"}
                />

                <DetailCard label={"Current Balance"} value={"₹ 0.00"} />
              </div>
            </div>

            <div className="col-md-12 grid-margin stretch-card p-0">
              <div style={{ padding: "0px" }} className="card">
                <DetailHeaderCard label={" Purpose Code and FIRC"} />

                <div style={{ padding: "8px" }} className="card">
                  <div style={{ padding: "0px" }} className="card-title">
                    <span
                      style={{ padding: "0px" }}
                      className="text-muted text-center text-sm-left d-block d-sm-inline-block"
                    >
                      Purpose Code
                    </span>
                    <span
                      style={{ padding: "0px" }}
                      className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"
                    >
                      <button type="button" className="btn btn-link btn-fw ">
                        Select Code
                      </button>
                    </span>
                  </div>
                </div>

                <DetailCard label={"FIRC Certificate"} value={"Coming Soon"} />
              </div>
            </div>
          </Tab>

          <Tab eventKey="Credits" title="Credits">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card p-0">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <h4
                          className="card-title font-weight-medium mb-3"
                          style={{ color: "#000" }}
                        >
                          Amount Credit
                        </h4>
                        <h1
                          style={{ color: "#000" }}
                          className="font-weight-medium mb-0"
                        >
                          ₹ 0
                        </h1>

                        <p className="mb-0" style={{ color: "#000" }}>
                          Transactions worth amount credits will be free of any
                          transaction fee.
                        </p>
                      </div>
                      <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                        <Bar
                          data={amountDueBarData}
                          options={amountDueBarOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card p-0">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5 d-flex align-items-center">
                        <Doughnut
                          data={usersDoughnutChartData}
                          options={usersDoughnutChartOptions}
                        />
                      </div>
                      <div className="col-md-7">
                        <h4
                          className="card-title font-weight-medium mb-0 d-none d-md-block"
                          style={{ color: "#000" }}
                        >
                          Active Users
                        </h4>
                        <div className="wrapper mt-4">
                          <div className="d-flex justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <p
                                className="mb-0 font-weight-medium"
                                style={{ color: "#000" }}
                              >
                                67,550
                              </p>
                              <small
                                style={{ color: "#000" }}
                                className="text-muted ml-2"
                              >
                                Email account
                              </small>
                            </div>
                            <p className="mb-0 font-weight-medium">80%</p>
                          </div>
                          <ProgressBar variant="success" now={80} />
                        </div>
                        <div className="wrapper mt-4">
                          <div className="d-flex justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <p
                                className="mb-0 font-weight-medium"
                                style={{ color: "#000" }}
                              >
                                21,435
                              </p>
                              <small
                                style={{ color: "#000" }}
                                className="text-muted ml-2"
                              >
                                Requests
                              </small>
                            </div>
                            <p
                              style={{ color: "#000" }}
                              className="mb-0 font-weight-medium"
                            >
                              34%
                            </p>
                          </div>
                          <ProgressBar variant="primary" now={34} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card p-0">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <h4
                          className="card-title font-weight-medium mb-3"
                          style={{ color: "#000" }}
                        >
                          Commission Credit
                        </h4>
                        <h1
                          style={{ color: "#000" }}
                          className="font-weight-medium mb-0"
                        >
                          ₹ 0.00
                        </h1>
                        <p className="mb-0" style={{ color: "#000" }}>
                          Get your transactions settled in full. Transaction
                          charges will be deducted from Commission credits.
                        </p>
                      </div>

                      <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                        <Bar
                          data={amountDueBarData}
                          options={amountDueBarOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card p-0">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7">
                        <h4
                          className="card-title font-weight-medium mb-3"
                          style={{ color: "#000" }}
                        >
                          Refund Credit
                        </h4>
                        <h1
                          style={{ color: "#000" }}
                          className="font-weight-medium mb-0"
                        >
                          ₹ 0.00
                        </h1>
                        <p className="mb-0" style={{ color: "#000" }}>
                          Do not want to refund from your settled amounts? Use
                          refund credits.
                        </p>
                      </div>

                      <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                        <Bar
                          data={amountDueBarData}
                          options={amountDueBarOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="Balances" title="Balances">
            <BalanceCard
              balanceType="DMT Balance"
              balance={
                typeof info.dmtBalance != "undefined" ? info.dmtBalance : "-"
              }
              info="Add funds to your reserve balance to increase the
                  negative balance limit. Thinking of withdrawing
                  your reserve balance?"
              buttonName="Activate"
            />
            <BalanceCard
              balanceType="AEPS Balance"
              balance={
                typeof info.aepsBalance != "undefined" ? info.aepsBalance : "-"
              }
              info="Add funds to your reserve balance to increase the
                  negative balance limit. Thinking of withdrawing
                  your reserve balance?"
              buttonName="Activate"
            />
            {/* <BalanceCard
                balanceType="Current Wallet Balance"
                balance={
                  typeof info.virtualBalance != "undefined"
                    ? info.virtualBalance
                    : "-"
                }
                info="Add funds to your account to process
                            refunds/transfers when the account balance goes low.
                            Adding large funds to your account?"
                buttonName="Add Funds"
              /> */}
            <BalanceCard
              balanceType="BBPS Wallet Balance"
              balance={
                typeof info.bbpsBalance != "undefined" ? info.bbpsBalance : "-"
              }
              info="Add funds to your account to process
                            refunds/transfers when the account balance goes low.
                            Adding large funds to your account?"
              buttonName="Add Funds"
            />
            <BalanceCard
              balanceType="BBPS Offline Wallet Balance"
              balance={
                typeof info.bbpsOfflineBalance != "undefined"
                  ? info.bbpsOfflineBalance
                  : "-"
              }
              info="Add funds to your account to process
                            refunds/transfers when the account balance goes low.
                            Adding large funds to your account?"
              buttonName="Add Funds"
            />
            <BalanceCard
              balanceType="paymentAPIBalance"
              balance={
                typeof info.paymentAPIBalance != "undefined"
                  ? info.paymentAPIBalance
                  : "-"
              }
              info="Add funds to your account to process
                            refunds/transfers when the account balance goes low.
                            Adding large funds to your account?"
              buttonName="Add Funds"
            />
          </Tab>

          <Tab eventKey="Transactions" title="Transactions">
            <Transactions />
          </Tab>
          <Tab eventKey="payin" title="Payin/Refund">
            <Payin />
          </Tab>
          <Tab eventKey="payout" title="Payout">
            <Payout />
          </Tab>

          <Tab eventKey="topup" title="Topup">
            <Topup />
          </Tab>
          <Tab eventKey="virtaulAccounts" title="Virtual Accounts">
            <SmartCollectVirtualAccountTable
              setDetails={(details) => {
                setDetails({ ...details, sidebarType: "payments" });
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            />
          </Tab>
          <Tab eventKey="virtaulPayments" title="Virtual Payments">
            <SmartCollectPaymentsTable
              setDetails={(details) => {
                setDetails({ ...details, sidebarType: "payments" });
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
const DetailCard = ({ label, value }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }} className="card">
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const BalanceCard = ({ balanceType, balance, info, buttonName }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-12 grid-margin stretch-card">
        <div style={{ padding: "0px" }} className="card">
          <div className="card-body">
            <div className="col">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                <h5
                  style={{ color: "#000" }}
                  className="card-title font-weight-medium mb-3"
                >
                  {balanceType}
                </h5>
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                <button
                  style={{
                    color: "#528ff0",
                    border: "1px solid #528ff0",
                  }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  {buttonName}
                </button>
              </span>
              <h1 style={{ color: "#000" }} className="font-weight-medium mb-0">
                {balance}
              </h1>

              {/* <p style={{ color: "#000" }} className="mb-0">
                {info}
                <button
                  style={{ color: "#528ff0" }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  Contact Us
                </button>{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
