import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form, Badge } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { IoIosRemoveCircle, IoMdRemoveCircleOutline } from "react-icons/io";
import { IoAddCircle } from "react-icons/io5";
import swal from "sweetalert";
import { MdEdit } from "react-icons/md";

const Wfhworkupdate = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const WFHWorkdata = JSON.parse(sessionStorage.getItem("WFHwork"));
  const { password, username } = billpunchbasicauth;

  const getwfh = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        homeid: WFHWorkdata.pk_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWorkUpdate`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData1(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getwfh();
  }, []);

  const [endtime, setendtime] = useState("");
  const [starttime, setstarttime] = useState("");
  const [taskname, settaskname] = useState("");
  const [creatingNewLoan, setCreatingNewLoan] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [editedStartTime, setEditedStartTime] = useState("");
  const [editedEndTime, setEditedEndTime] = useState("");

  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  const isValidTime = (time) => {
    const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/;
    return regex.test(time);
  };

  const isValidTimeRange = (start, end) => {
    if (!isValidTime(start) || !isValidTime(end)) return false;
    const [startHour, startMinute] = convertTo24HourFormat(start).split(":").map(Number);
    const [endHour, endMinute] = convertTo24HourFormat(end).split(":").map(Number);
    return (
      startHour < endHour || (startHour === endHour && startMinute < endMinute)
    );
  };

  const handleSaveNewLoan = async (e) => {
    e.preventDefault();
    if (!isValidTimeRange(starttime, endtime)) {
      swal("Error", "Invalid time range. Ensure start time is before end time and times are in HH:MM AM/PM format.", "error");
      return;
    }
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          homeid: WFHWorkdata.pk_id,
          firmid: user.firm_id,
          empid: user.userid,
          reportingid: WFHWorkdata.reporting_id,
          taskname: taskname,
          starttime: convertTo24HourFormat(starttime),
          endtime: convertTo24HourFormat(endtime),
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddWorkUpdate`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        setstarttime("");
        setendtime("");
        settaskname("");
        getwfh();
        setCreatingNewLoan(false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleEdit = (item) => {
    setEditingRow(item.pkid);
    setEditedStartTime(convertTo12HourFormat(item.start_time));
    setEditedEndTime(convertTo12HourFormat(item.end_time));
  };

  const convertTo12HourFormat = (time) => {
    let [hours, minutes] = time.split(":");
    const modifier = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${modifier}`;
  };

  const handleSaveEdit = async (e, pkid) => {
    e.preventDefault();
    if (!isValidTimeRange(editedStartTime, editedEndTime)) {
      swal("Error", "Invalid time range. Ensure start time is before end time and times are in HH:MM AM/PM format.", "error");
      return;
    }
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          pkid: pkid,
          firmid: user.firm_id,
          empid: user.userid,
          starttime: convertTo24HourFormat(editedStartTime),
          endtime: convertTo24HourFormat(editedEndTime),
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/UpdateTaskTiming`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        getwfh();
        setEditingRow(null);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
    setEditedStartTime("");
    setEditedEndTime("");
  };

  const handleCancelNewLoan = () => {
    setstarttime("");
    setendtime("");
    settaskname("");
    setCreatingNewLoan(false);
  };


  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h5 className="">WFH Work Update</h5>
          </div>
          <div className="col mr-md-3 px-md-1 px-sm-2 d-flex justify-content-end">
            <Link to="/retailer/EmployeeLeave">
              <Button
                className="btn btn-info btn-sm"
                style={{ borderRadius: ".4em", border: "None", color: "#fff" }}
              >
                Back
              </Button>
            </Link>
          </div>
        </div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{ textAlign: "center", overflow: "hidden" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Start Time
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  End Time
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                    width: "50%",
                  }}
                >
                  Task
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Remark
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data1.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {index + 1}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {editingRow === item.pkid ? (
                      <input
                        type="text"
                        className="form-control"
                        value={editedStartTime}
                        onChange={(e) => setEditedStartTime(e.target.value)}
                      />
                    ) : (
                      item.start_time
                    )}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {editingRow === item.pkid ? (
                      <input
                        type="text"
                        className="form-control"
                        value={editedEndTime}
                        onChange={(e) => setEditedEndTime(e.target.value)}
                      />
                    ) : (
                      item.end_time
                    )}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {item.task_name}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {item.status === "N" ? (
                      <Badge
                        className="btn-sm"
                        style={{
                          backgroundColor: "#cce5ff",
                          color: "#007bff",
                          border: "1px solid #b8daff",
                          borderRadius: ".3rem",
                          fontSize: ".8rem",
                        }}
                      >
                        Not Reviewed
                      </Badge>
                    ) : (
                      <Badge
                        className="btn-sm"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                          fontSize: ".8rem",
                        }}
                      >
                        Reviewed
                      </Badge>
                    )}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {item.remark || "NA"}
                  </td>
                  <td style={{ border: "1px solid #cccccc", padding: "8px" }}>
                    {editingRow === item.pkid ? (
                      <>
                        <button
                          className="btn btn-primary btn-sm mx-1"
                          onClick={(e) => handleSaveEdit(e, item.pkid)}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-secondary btn-sm mx-1"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <MdEdit
                        className="edit-icon"
                        onClick={() => handleEdit(item)}
                      />
                    )}
                  </td>

                </tr>
              ))}
              {creatingNewLoan && (
                <tr>
                  <td style={{ border: "none" }} className="align-middle">
                    {data1.length + 1}
                  </td>

                  <td className="align-middle" style={{ border: "none" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Start Time"
                      value={starttime}
                      onChange={(e) => setstarttime(e.target.value)}
                    />
                  </td>
                  <td className="align-middle" style={{ border: "none" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="End Time"
                      value={endtime}
                      onChange={(e) => setendtime(e.target.value)}
                    />
                  </td>
                  <td className="align-middle" style={{ border: "none" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Task Name"
                      value={taskname}
                      onChange={(e) => settaskname(e.target.value)}
                    />
                  </td>
                  <td className="text-center " style={{ border: "none" }}>
                    <div className="d-flex align-items-center">
                      <Button
                        className="btn-sm"
                        variant="success"
                        onClick={handleSaveNewLoan}
                      >
                        Save
                      </Button>{" "}
                      <IoIosRemoveCircle
                        style={{ fontSize: "1.2rem", color: "red" }}
                        onClick={handleCancelNewLoan}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!creatingNewLoan && (
                <tr>
                  <td colSpan="4" style={{ border: "none" }}>
                    <Link onClick={() => setCreatingNewLoan(true)}>
                      + Add New Task
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Wfhworkupdate;
