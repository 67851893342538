import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Spinner from "./shared/Spinner";
import { ADMIN_SESSION_TIMER, checkSession } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import AdminLogin2 from "./user-pages/AdminLogin2";
import Dashboard2 from "./dashboard/Dashboard2";


const Error404 = lazy(() => import("./error-pages/Error404"));

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (user.pkid != '9') {
      removeUserInfo();
      checkSession();
    }
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: ADMIN_SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });


  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      // document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/admin/dashboard" component={Dashboard2} />


























        <Redirect to="/admin" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
