import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel, inWords } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
//import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./TopupView.css";
export const columns = [
  {
    dataField: "user_name",
    text: "User Name",
  },


  {
    dataField: "user_mobile",
    text: "User Mobile No.",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "load_id",
    text: "Load ID",
  },
  {
    dataField: "load_status",
    text: "Status",
  },
  {
    dataField: "load_amount",
    text: "Amount",
  },
  {
    dataField: "load_remark",
    text: "Remark",
  },
  {
    dataField: "load_indate",
    text: "Date",
  },
  {
    dataField: "load_paymode",
    text: " Payment Mode",
  },
  {
    dataField: "load_utrno",
    text: " UTR No.",
  },

];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const tdstyle2 = {
  width: "10%",
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const thstyle = {
  color: 'dark',
  backgroundColor: 'white',
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const LoadRequest = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [slipUrl, setSlipUrl] = useState("");
  const [inputState, setInputState] = useState({ userType: "" });
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [loadDetails, setLoadDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slipModalOpen, setSlipModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  const [clickedImageURL, setClickedImageURL] = useState("");
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };



  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);




  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const openModal = (details) => {
    setLoadDetails(details);
    setModalOpen(true);
  };
  const openSlipModal = (url) => {
    setSlipUrl(url);
    setSlipModalOpen(true);
  };

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };






  const loadRequestReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://apiservices.bumppypay.com/api/Payment/reports/loadRequestReport`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    loadRequestReport();

  }, []);





  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };


  const positionStyle1 = {
    position: "absolute",
    top: -43,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle2 = {
    position: "absolute",
    top: -44,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  return (
    <>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      <div style={{ padding: "1rem", backgroundColor: "#F2F6FC" }} className=" " >
        <div style={{ borderRadius: "10px" }} className="card mt-4 p-0 mx-2 border-light ">
          <div className="card-body m-0 p-2">
            <div className="row m-0 p-0  pt-1 align-items-center">
              <div className="col-3 m-0 p-0">
                <div
                  className="m-0 p-0 pl-2"
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    lineHeight: "48px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize: "28px",
                  }}
                >
                  Load Request , Count : {data.length}
                </div>
              </div>

              <div className="col-9 m-0 p-0 ">
                <div className="row  m-0 p-0  justify-content-end">
                  <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={iconStyle}
                      className="ml-2"
                    />
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Search"
                      aria-label="Search"
                      style={inputStyle}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      value={searchText}
                    />
                  </div>

                  <div
                    className="pr-1 pl-2 p-0 m-0"
                    style={{ width: "18%", height: "20%" }}
                  >
                    <Form.Group className="p-0 m-0">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control rounded-100"
                        placeholderText="Start Date"
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="px-1  p-0 m-0"
                    style={{ width: "18%", height: "20%" }}
                  >
                    <Form.Group className="p-0 m-0">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control rounded-100"
                        placeholderText="End Date"
                      />
                    </Form.Group>
                  </div>

                  <div
                    className="pl-1   p-0 m-0"
                    style={{ width: "14%", height: "20%" }}
                  >
                    <Form.Group className="mt-0 p-0 m-0">
                      <Button
                        className="rounded-50 btn btn-primary btn-lg btn-block "
                        onClick={loadRequestReport}
                      >
                        {"Search"}
                      </Button>
                    </Form.Group>
                  </div>

                  <div
                    className="m-0 p-0  hover-container"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      ExportToExcel(
                        `Load_Requests_Report_${now()}`,
                        excelMapping(data, columns)
                      );
                    }}
                  >
                    <img
                      className="pl-1 p-0"
                      src={require("../../../assets/images/download Icon.png")}
                      style={{ width: `3.5rem`, height: "85%" }}
                    />
                    <div style={positionStyle1} className="hover-text bg-dark ">
                      Export to Excel
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="m-0 p-0" />

            <ViewSlipModal
              details={slipUrl}
              isModalOpen={slipModalOpen}
              setModalOpen={(modalState) => {
                setSlipModalOpen(modalState);
              }}
            />

            <div className=" scroll-inner admin-fix-width transparent-table">
              <table className=" table table-borderless text-dark ">
                <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Sr"}
                  </th>

                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"User Name"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Ref. ID"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Amount"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Date"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Pay Mode"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"UTR No."}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Status"}
                  </th>
                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Remarks"}
                  </th>

                  <th
                    className=" transparent-table text-center border-bottom  text-dark"
                    style={thstyle}
                  >
                    {" "}
                    {"Action"}
                  </th>
                </tr>
                {dataToDisplay.map((e, ind) => (
                  <tr className=" transparent-table border-bottom ">
                    <td className="align-middle" style={tdstyle}>
                      {" "}
                      <div >
                        {ind + 1}
                      </div>
                    </td>
                    <td
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }} className="align-middle"
                    >
                      {" "}
                      <div >
                        <div>{`${e.user_name}`}</div>
                        <div>{`${e.user_mobile}`}</div>
                        <div>{`${e.user_code}`}</div>
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      {" "}
                      <div className="d-flex flex-column align-items-center p-2 m-0">
                        <span>{e.load_id}</span>
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      <div className="d-flex flex-column align-items-center p-2 m-0">
                        <span>{e.load_amount}</span>
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      <div className="d-flex flex-column align-items-center p-2 m-0">
                        <span className="m-0 p-0">{e.load_indate}</span>
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      <div className="d-flex flex-column align-items-center p-2 m-0">
                        <span className="m-0 p-0">{e.load_paymode}</span>
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      <div className="d-flex flex-column align-items-center p-2 m-0">
                        <span className="m-0 p-0">{e.load_utrno}</span>

                      </div>
                    </td>

                    <td style={{
                      width: "15%",
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }} className="align-middle" >
                      <div className="d-flex flex-column align-items-center  m-0" >
                        {e.load_status == "Pending" ? (
                          <span
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(254, 128, 36, 0.1)",
                              color: "rgba(254, 128, 36, 1)",
                              bordercolor: "rgba(254, 128, 36, 1)",
                            }}
                            className="p-2"
                          >
                            {`${e.load_status}`}
                          </span>
                        ) : e.load_status === "Rejected" || e.load_status === "Rejeted" ? (
                          <span
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(255, 0, 0, 0.1)",
                              color: " rgba(255, 0, 0, 1)",
                              bordercolor: " rgba(255, 0, 0, 1)",
                            }} className="p-2"
                          >
                            {`${e.load_status}`}
                          </span>
                        ) : (
                          <span
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(43, 193, 85, 0.1)",
                              color: "rgba(43, 193, 85, 1)",
                              bordercolor: "rgba(43, 193, 85, 1)",
                            }} className="p-2"
                          >
                            {`${e.load_status}`}
                          </span>
                        )}
                      </div>
                    </td>
                    <td style={tdstyle} className="align-middle" >
                      <div className="d-flex flex-column align-items-center text-center  p-2 m-0">

                        <span className="m-0 p-0">{e.load_remark}</span>
                      </div>
                    </td>


                    <td className="align-middle " >
                      {" "}

                      <div className="" >
                        <Button
                          style={{
                            paddingLeft: "3px",
                            padding: "5px",
                            borderRadius: "0px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            bordercolor: "rgba(255, 255, 255, 1)",
                          }}
                          className="  p-1 "
                          onClick={() => {
                            setClickedImageURL(e.pay_slip);
                          }}
                        >
                          {" "}
                          <div className="hover-container " >
                            {/* Your content here */}
                            <img
                              src={require("../../../assets/images/View Slip (1).png")}
                              style={{ width: `100%`, height: "100%", borderRadius: "0px", }}
                            />
                            <div
                              style={positionStyle2}
                              className="hover-text bg-dark "
                            >
                              <div> View Slip</div>

                            </div>
                          </div>

                        </Button>






                      </div>
                    </td>
                  </tr>
                ))}
                {/* {data.length <= 0 && <NoDataFound />} */}
              </table>
            </div>

            <Pagination
              page={currentPage}
              limit={pages}
              callback={(page) => {
                setCurrentPage(page);
              }}
              count={dataToDisplay.length}
              total={total}
              callback2={(offsetValue) => {
                setOffset(offsetValue);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });

  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};


const ViewSlipModal = ({ details: url, isModalOpen, setModalOpen }) => {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ height: "100%", display: "block" }}
        className="account"
      >
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong>View Slip</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        {url && (
          <React.Fragment>
            <div className="row">
              <img src={url} style={{ width: "50%", height: "auto" }} />
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LoadRequest;