import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { AiOutlineClose } from "react-icons/ai";
import { Form, Button } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminReimbursement = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    reimbursementType: "",
    nameInPayslip: "",
    includeAsFlexibleBenefitPlan: false,
    Restrictoverridefbp: false,
    handleUnclaimedReimbursement: "encashAtYearEnd",
    amount: "",
    active: false,
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleChange = (selectedOptionOrEvent) => {
    if (selectedOptionOrEvent && selectedOptionOrEvent.target) {
      const { name, value, type, checked } = selectedOptionOrEvent.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        reimbursementType: selectedOptionOrEvent.value,
      }));
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.reimbursementType) errors.reimbursementType = "*Please select Reimbursement Type";
    if (!formData.nameInPayslip) errors.nameInPayslip = "*Please enter Name in Payslip";
    if (!formData.amount) errors.amount = "*Please enter Amount";
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );

      myHeaders.append("Content-Type", "application/json");
      let endYearValue = "N";
      let monthlyValue = "N";
      if (formData.handleUnclaimedReimbursement === "encashAtYearEnd") {
        endYearValue = "Y";
      } else if (formData.handleUnclaimedReimbursement === "encashMonthly") {
        monthlyValue = "Y";
      }
      var raw = JSON.stringify({
        firmid: user.firm_id,
        rem_type: formData.reimbursementType,
        payslip: formData.nameInPayslip,
        flex_plan: formData.includeAsFlexibleBenefitPlan ? "Y" : "N",
        override_fbp: formData.Restrictoverridefbp ? "Y" : "N",
        end_year: endYearValue,
        monthly: monthlyValue,
        active: formData.active ? "Y" : "N",
        amount: formData.amount,
        type: "Reimbursement",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddReimbrusementFirm`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        history.push("/retailer/Salary_components");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="mt-md-3 mt-2">
        <div className="row mx-3">
          <h5 className="col">Add Reimbursement</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Salary_components">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr />
        <Form className="mx-2 col-md-8 col-12" onSubmit={validateForm}>
          <Form.Group className=" col-md-8 col-10 mb-3">
            <Form.Label>Reimbursement Type</Form.Label>
            <Select
              options={[
                { value: "test1", label: "Test 1" },
                { value: "test2", label: "Test 2" },
              ]}
              onChange={handleChange}
              value={formData.reimbursementType ? { value: formData.reimbursementType, label: formData.reimbursementType } : null}
            />
            {errors.reimbursementType && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.reimbursementType}</div>
            )}
          </Form.Group>
          <Form.Group className="col-md-8 col-10 mb-3">
            <Form.Label>Name in Payslip*</Form.Label>
            <Form.Control
              type="text"
              name="nameInPayslip"
              value={formData.nameInPayslip}
              onChange={handleChange}
            />
            {errors.nameInPayslip && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.nameInPayslip}</div>
            )}
          </Form.Group>
          <Form.Group className="col-12 mb-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  <span style={{ color: "#000" }}>
                    Include this as a Flexible Benefit Plan component
                  </span>
                  <br />
                  <span>
                    FBP allows your employees to personalise their salary
                    structure by choosing how much they want to receive under
                    each FBP component.
                  </span>
                </>
              }
              name="includeAsFlexibleBenefitPlan"
              checked={formData.includeAsFlexibleBenefitPlan}
              onChange={handleChange}
            />
          </Form.Group>

          {formData.includeAsFlexibleBenefitPlan && (
            <Form.Group className="mx-3 col-12 mb-3">
              <Form.Check
                type="checkbox"
                label={
                  <>
                    <span style={{ color: "#000" }}>
                      Restrict employee from overriding the FBP amount
                    </span>
                  </>
                }
                name="Restrictoverridefbp"
                checked={formData.Restrictoverridefbp}
                onChange={handleChange}
              />
            </Form.Group>
          )}

          <Form.Group className=" col-12 mb-3">
            <Form.Label style={{ color: "#000" }}>
              How do you want to handle unclaimed reimbursement?
            </Form.Label>
            <Form.Check
              type="radio"
              label="Encash unclaimed reimbursement at the end of each financial year"
              name="handleUnclaimedReimbursement"
              value="encashAtYearEnd"
              checked={
                formData.handleUnclaimedReimbursement === "encashAtYearEnd"
              }
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              label="Do not carry forward and encash unclaimed reimbursements monthly"
              name="handleUnclaimedReimbursement"
              value="encashMonthly"
              checked={
                formData.handleUnclaimedReimbursement === "encashMonthly"
              }
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
            {errors.amount && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.amount}</div>
            )}
          </Form.Group>
          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  <span style={{ color: "#000" }}>Mark this as Active</span>
                </>
              }
              name="active"
              checked={formData.active}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="col-12 mx-2 px-1 " style={{ background: "#FFF5E3" }}>
            <p className="p-1" style={{ fontSize: ".8rem" }}>
              <b>Note:</b> Once you associate this component with an employee,
              you will only be able to edit the Name in Payslip and Amount. The
              changes you make to Amount will apply only to new employees.
            </p>
          </div>
          <hr />
          <div className="px-2 mb-4">
            {" "}
            <Button variant="primary" type="submit">
              Save
            </Button>
            <Button
              style={{ background: "#fff", border:"none" }}
              disabled
            ></Button>
            <Link to="/retailer/Salary_components">
              <Button
                className="btn-light mx-2"
                style={{ border: "1px solid" }}
              >
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AdminReimbursement;

