import swal from "sweetalert";
export const checkSession = () => {
  swal(
    "Session Time Out",
    "You're being timed out due to inactivity.",
    "warning"
  );
};
export const SESSION_TIMER = 200 * 60 * 1000;
export const ADMIN_SESSION_TIMER = 30 * 60 * 1000;
