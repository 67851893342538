import React, { useState, useEffect, useContext } from "react";
import AllTransactionsTable from "./AllTransactionTable2";
import AllTransactionsTable2 from "./AllTransactionsTable";
import '../user-view/custom-tabs.css';

const DashboardReports = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

            <div className="card m-0 p-0 pt-3">
                <div className="card-body m-0 p-0">

                    <div className="">
                        <div defaultActiveKey="profile" className=" tab-header justify-content-start">
                            {/* <div
                                className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                onClick={() => handleTabClick(0)}
                            >
                                Admin Report
                            </div> */}
                            <div
                                className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                onClick={() => handleTabClick(1)}
                            >
                                Fund Report
                            </div>

                        </div>
                        <div className="tab-content">
                            {/* {activeTab === 0 && <div> <AllTransactionsTable /> </div>} */}
                            {activeTab === 1 && <div><AllTransactionsTable2 /></div>}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default DashboardReports;
