import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalContext } from "../Context/GlobalState";
import { excelMapping, ExportToExcel, now } from "../utils/utilFuctions";
const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const Topup = () => {
  const columns = [
    {
      dataField: "merchantTransactionid",
      text: "Merchant Txnt ID",
      color: "#586275",
    },
    {
      dataField: "refid",
      text: "Ref id",
      color: "#586275",
    },
    {
      dataField: "utnno",
      text: "UTR No",
      color: "#586275",
    },
    {
      dataField: "transactiontype",
      text: "Transaction type",
      color: "#586275",
    },
    {
      dataField: "transactionAmount",
      text: "Amount",
      color: "#586275",
    },
    {
      dataField: "cgstAmount",
      text: "CGST Amount",
      color: "#586275",
    },
    {
      dataField: "sgstAmount",
      text: "SGST Amount",
      color: "#586275",
    },
    {
      dataField: "chargesAmount",
      text: "Charges",
      color: "#586275",
    },
    {
      dataField: "debitAmount",
      text: "Debit",
      color: "#586275",
    },
    {
      dataField: "creditAmount",
      text: "Credit",
      color: "#586275",
    },
    {
      dataField: "balance",
      text: "Balance",
      color: "#586275",
    },
    {
      dataField: "status",
      text: "Status",
      color: "#000",
    },

    {
      dataField: "createdOn",
      text: "Created On",
      color: "#586275",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo } = useContext(GlobalContext);
  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data

            .filter((acc) => {
              return Number(acc.debitAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
    } catch (error) {
      console.log("401 catched", error);
    }
  };
  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [accountCreated, setAccountCreated] = useState(
    localStorage.getItem("va-created") || false
  );
  const [TopupData, setTopupData] = useState({
    msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
    success: false,
  });
  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const fileInputRef = useRef(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    setImagePreview(null);
  };
  const imageUpload = () => {};

  return (
    <div>
      <div style={{ background: "white" }} className=" m-0 p-0">
        <Tabs defaultActiveKey="Topup Request" id="uncontrolled-tab-example">
          <Tab
            eventKey="Topup Request"
            title="Topup Request"
            className="test-tab"
          >
            <div className="row">
              <div className="col-8 px-3">
                <div className="row ">
                  <Form.Group className="col">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Amount`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>

                  <Form.Group className="col">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Transaction ID`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Transaction ID"
                      onChange={(e) => {
                        setTransactionId(e.target.value);
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>

                <div className="row ">
                  <Form.Group className="col">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Select Bank`}
                    </Form.Label>
                    <Select
                      options={banks}
                      onChange={(e) => {
                        setSelectedBank(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Select Type`}
                    </Form.Label>
                    <Select
                      options={paymentModes}
                      onChange={(e) => {
                        setPaymentMode(e);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="row ">
                  <Form.Group className="col">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Select Transaction Date`}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className="form-control"
                      selected={transactionDate}
                      onChange={(date) => setTransactionDate(date)}
                    />
                  </Form.Group>
                  <Form.Group className="col">
                    <Form.Label>{`Remark`}</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Remark"
                      onChange={(e) => {
                        setRemark(e.target.value);
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>
                <Button
                  className=" btn"
                  variant="primary"
                  style={{
                    height: "2.5rem",
                    width: "12rem",
                    borderRadius: ".4rem",
                  }}
                >
                  {" "}
                  Submit
                </Button>
                <Button
                  className=" btn"
                  style={{
                    marginLeft: "1rem",
                    borderRadius: ".4rem",
                    backgroundColor: "white",
                    color: "rgba(0, 123, 255, 1)",
                    border: "1px solid rgba(0, 123, 255, 1)",
                    borderColor: "rgba(0, 123, 255, 1)",
                    fontSize: "0.8rem",
                    height: "2.5rem",
                    width: "12rem",
                  }}
                >
                  {" "}
                  Clear
                </Button>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #ccc",
                  margin: "0 1rem", // Adjust the margin as needed
                }}
              ></div>
              <div className="col">
                <div
                  style={{
                    border: "2px dashed #ccc",
                    padding: "1rem",
                    marginBottom: "1rem",
                    position: "relative",
                    background: "#eef0f1",
                    height: "12rem",
                    width: "100%",
                  }}
                >
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  )}
                  <label
                    htmlFor="fileInput"
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <input
                      ref={fileInputRef}
                      id="fileInput"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                    />
                    {!imagePreview && (
                      <img
                        alt=""
                        src={require("../../assets/images/gallery.png")}
                        style={{ height: "4rem" }}
                      />
                    )}
                  </label>
                
                </div>

                <h5>Slip Upload</h5>
                <Button
                  className="d-flex align-items-center"
                  style={{
                    padding: "8px",
                    border: "1px solid",
                    height: "2rem",
                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                    color: "rgba(43, 193, 85, 1)",
                    borderColor: "rgba(43, 193, 85, 1)",
                    borderRadius: ".4rem",
                    fontSize: "1rem",
                  }}
                  onChange={handleChooseFileClick}
                >
                  Choose File
                </Button>
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="Topup Request List"
            title="Topup Request List"
            className="test-tab"
          >
            <div>
              <div style={{ background: "white" }}>
                <div className="row">
                  <div className="col-7 m-2">
                    <div
                      className="d-flex"
                      style={{
                        cursor: "pointer",
                        border: "1px solid",
                        height: "2.5rem",
                        width: "11rem",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                        borderRadius: ".4rem",
                      }}
                      onClick={() => {
                        ExportToExcel(
                          `DMT_Report_${now()}`,
                          excelMapping(dataToDisplay, columns)
                        );
                      }}
                    >
                      <img
                        alt=""
                        src={require("../../assets/images/excel.png")}
                        style={{ width: "1.6rem", marginLeft: "15px" }}
                      />
                      <strong
                        className="d-flex align-items-center"
                        style={{ marginLeft: "2px" }}
                      >
                        Export to Excel
                      </strong>
                    </div>
                  </div>

                  <div className=" col m-2 daterange-picker">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        className="form-control rounded-0"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        value={searchText}
                      />
                    </Form.Group>
                    <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                        placeholderText="Start Date"
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                        placeholderText="End Date"
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    <div style={{ marginLeft: "10px" }}>
                      {" "}
                      <Button
                        className=""
                        style={{ borderRadius: ".4rem" }}
                        variant="primary"
                        onClick={() => {}}
                      >
                        {"Search"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="m-0 p-0 card mx-3 center-block scroll-inner">
                  <table
                    className="dashboard-table-style"
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <tr>
                      <th
                        className="text-center"
                        style={{
                          fontSize: "0.9rem",
                          background: "#f5f6f8",
                          color: "#586275",
                        }}
                      >
                        {" "}
                        {"Sr"}
                      </th>
                      {columns.map((heading, index) => (
                        <th
                          key={index}
                          className="text-center"
                          style={{
                            fontSize: "0.9rem",
                            background: "#f5f6f8",
                            color: "#586275",
                          }}
                        >
                          {heading.text}
                        </th>
                      ))}
                    </tr>

                    {dataToDisplay.map((e, ind) => (
                      <tr key={ind}>
                        <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
                        {columns.map((cell) => (
                          <td
                            key={cell.dataField}
                            style={{ fontSize: ".8rem", color: cell.color }}
                          >
                            {cell.dataField === "status" && (
                              <>
                                {e[cell.dataField] ===
                                  "SettlementCompleted" && (
                                  <label
                                    style={{
                                      padding: "8px",
                                      border: "1px solid",
                                      height: "2rem",
                                      backgroundColor: "rgba(43, 193, 85, 0.1)",
                                      color: "rgba(43, 193, 85, 1)",
                                      borderColor: "rgba(43, 193, 85, 1)",
                                      borderRadius: ".4rem",
                                      fontSize: ".8rem",
                                    }}
                                    className="badge badge-success"
                                  >
                                    {e[cell.dataField]}
                                  </label>
                                )}
                                {e[cell.dataField] === "0" && (
                                  <label
                                    style={{
                                      padding: "8px",
                                      borderRadius: ".4rem",
                                      backgroundColor: "rgba(0, 123, 255, 0.1)",
                                      color: "rgba(0, 123, 255, 1)",
                                      border: "1px solid rgba(0, 123, 255, 1)",
                                      borderColor: "rgba(0, 123, 255, 1)",
                                      fontSize: "0.8rem",
                                    }}
                                    className="badge badge-warning"
                                  >
                                    Virtual
                                  </label>
                                )}
                              </>
                            )}
                            {cell.dataField !== "status" && e[cell.dataField]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
                <Pagination
                  page={currentPage}
                  limit={pages}
                  callback={(page) => {
                    setCurrentPage(page);
                  }}
                  count={dataToDisplay.length}
                  total={total}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Topup;
const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};
