import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Modal, Button, Overlay, Popover } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { MdMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaBirthdayCake,
  FaLock,
  FaUser,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import {
  BASE_URL_API_TRANXT_SERVICE_API,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import swal from "sweetalert";
import { billpunchbasicauth } from "../../utils/Credentials";
import { CiEdit } from "react-icons/ci";
import { uniqueID2 } from "../../utils/utilFuctions";

const ViewEmployeeprofile = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [data, setData] = useState({
    empid: "",
    employee_name: "",
    mobile: "",
    empcode: "",
    designation: "",
    department: "",
    emptype: "",
    address: "",
    email: "",
    dob: "",
    joiningdate: "",
    bankname: "",
    bankifsc: "",
    bankaccno: "",
    basicpay: "",
    hra: "",
    travel_allowance: "",
    medical_allowance: "",
    totalsalary: "",
    image_url: "",
  });

  const initialpassword = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [passwordData, setPasswordData] = useState(initialpassword);
  const [errors, setErrors] = useState(initialpassword);
  const [showModal, setShowModal] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setPasswordData(initialpassword);
    setShowModal(false);
  };

  const validatePasswordForm = () => {
    console.log("Validating password form...");
    const newErrors = {};

    if (
      !passwordData.currentPassword ||
      passwordData.currentPassword.trim() === ""
    ) {
      newErrors.currentPassword = "Current password is required";
    }

    if (!passwordData.newPassword || passwordData.newPassword.trim() === "") {
      newErrors.newPassword = "New password is required";
    }

    if (
      !passwordData.confirmPassword ||
      passwordData.confirmPassword.trim() === ""
    ) {
      newErrors.confirmPassword = "Confirm password is required";
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      newErrors.confirmPassword =
        "New password and confirm password do not match";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Password validation successful. Calling API...");
      handlePasswordUpdate();
      console.log("Password update API call complete");
    }

    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordUpdate = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        pkid: user.userid,
        username: user.mobile,
        oldpassword: passwordData.currentPassword,
        password: passwordData.newPassword,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateLoginPassword`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
        handleModalClose();
      } else {
        swal("Error", data.msg, "error");
        handleModalClose();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getEmployeeList = async (selectedDepartment) => {
    console.log("getEmployeeList Department Parameter:", selectedDepartment);

    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeProfileDetails`,
        requestOptions
      );
      const apiData = await res.json();
      if (apiData.status === "0") {
        setData(apiData.data[0]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);
  const [showOverlay1, setShowOverlay1] = useState(false);
  const [image, setImage] = useState(null);
  const target = useRef(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!image) {
      return;
    }

    const newImageName = `profile_image_${uniqueID2()}`;

    if (image.size > 2 * 1000000) {
      swal("Invalid", "Image Size should be less than 2 MB", "error");
      return;
    }

    const byteArray = await convertToByteArray(image);

    const raw = JSON.stringify({
      firmid: user.firm_id,
      userid: user.userid,
      image_type: "profile_image",
      file_type: image.type,
      image_data: byteArray,
    });

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ImageUpload`,
        requestOptions
      );
      const result = await response.json();

      console.log("API Response:", result);

      if (result.status === "0") {
        swal("Success", result.msg, "success").then(() => {
          getEmployeeList();
        });
        setTransactionLoaderState(false);
      } else {
        swal("Error", result.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log(error);
      swal("Error", "Failed to upload image", "error");
    }

    setShowOverlay(false);
  };

  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };
  let formattedDate = "Date not available";

  if (data.dob) {
    if (data.dob.includes("T")) {
      // Handle ISO date format
      formattedDate = data.dob.split("T")[0];
    } else if (data.dob.includes(" ")) {
      // Handle date format with space
      formattedDate = data.dob.split(" ")[0];
    }
  }
  return (
    <div className="m-md-5 m-2 " style={{ background: "#fff" }}>
      <div className="row m-3 pt-4">
        <div
          className=" m-1 p-3 col-md-5 col-12 card mx-3"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="row">
            <div className="col d-flex justify-content-center" ref={target}>
              {data.image_url ? (
                <img
                  src={data.image_url}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  onClick={() => setShowOverlay1(true)}
                />
              ) : (
                <FaUser style={{ color: "teal", fontSize: "2.5rem" }} />
              )}
            </div>
            <div className="col-1">
              <CiEdit
                onClick={() => setShowOverlay(!showOverlay)}
                style={{ cursor: "pointer", fontSize: "1.5rem", color: "grey" }}
              />
            </div>
          </div>

          <div className="row m-0 p-0">
            <h4 className=" col-11 m-0 p-0 d-flex justify-content-center mt-2">
              {" "}
              {data.employee_name}
            </h4>
          </div>

          <div className="row m-0 p-0">
            <h6 className=" col-11 m-0 p-0 d-flex justify-content-center mt-2">
              {" "}
              {data.designation}
            </h6>
          </div>
          <hr />
          <p style={{ fontSize: "1rem" }}>Basic Info</p>
          <span className="menu-icon m-2">
            <FaEnvelope style={{ color: "red", marginRight: "5px" }} />
            <p style={{ display: "inline", fontSize: "1rem", color: "black" }}>
              {" "}
              {data.email}
            </p>
          </span>
          <span className="menu-icon m-2">
            <FaBirthdayCake style={{ color: "red", marginRight: "5px" }} />
            <p style={{ display: "inline", fontSize: "1rem", color: "black" }}>
              {" "}
              {formattedDate}</p>

          </span>
          <span className="menu-icon m-2">
            <FaMapMarkerAlt style={{ color: "red", marginRight: "5px" }} />
            <p style={{ display: "inline", fontSize: "1rem", color: "black" }}>
              {" "}
              {data.address}
            </p>
          </span>
        </div>
        <div
          className="col m-1 p-0 card mx-3"
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="row">
            <div className="col">
              <h5>Personal Information</h5>
            </div>
          </div>
          <hr className="m-0" />
          <div className="row p-0 m-0 mt-1 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Employee code{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.empcode}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Employee Mobile{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.mobile}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Department{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.department}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Employee type{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {data.emptype}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Joining Date{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {" "}
              {new Date(data.joiningdate).toLocaleDateString()}
            </p>
          </div>
          <hr className="m-3" />
          <div className="row m-0 p-0 ">
            <div className="col">
              <h5>Bank Information</h5>
            </div>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Bank Name{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {" "}
              {data.bankaccno}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Bank IFSC{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {" "}
              {data.bankifsc}
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <p className="col" style={{ fontSize: "1rem" }}>
              {" "}
              Bank Account{" "}
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {" "}
              {data.bankname}
            </p>
          </div>
        </div>
      </div>

      <Overlay
        target={target.current}
        show={showOverlay}
        placement="bottom"
        rootClose
        onHide={() => setShowOverlay(false)}
      >
        <Popover id="popover-basic" style={{ background: "white" }}>
          <Popover.Title style={{ background: "white" }} as="h3">
            Upload Image
          </Popover.Title>
          <Popover.Content style={{ background: "white" }}>
            <Form>
              <Form.Group>
                <Form.File
                  id="imageUpload"
                  label="Choose image"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <Button
                className="btn-sm"
                variant="primary"
                onClick={handleImageUpload}
              >
                Upload
              </Button>
            </Form>
          </Popover.Content>
        </Popover>
      </Overlay>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title> Update Password </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="currentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Enter current password"
                value={passwordData.currentPassword}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    currentPassword: e.target.value,
                  })
                }
                isInvalid={!!errors.currentPassword}
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.currentPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter new password"
                value={passwordData.newPassword}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    newPassword: e.target.value,
                  })
                }
                isInvalid={!!errors.newPassword}
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm new password"
                value={passwordData.confirmPassword}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    confirmPassword: e.target.value,
                  })
                }
                isInvalid={!!errors.confirmPassword}
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" onClick={validatePasswordForm}>
              Update Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {showOverlay1 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={() => setShowOverlay1(false)}
        >
          <img
            src={data.image_url}
            alt="Profile Overlay"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
            }}
            onLoad={() => console.log("Image loaded")}
            onError={() => console.log("Error loading image")}
          />
        </div>
      )}
    </div>
  );
};

export default ViewEmployeeprofile;
