import React, { useEffect, useState } from "react";
import { Col, Row, Nav, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import FundTransfer from "../../retailer/fund-transfer/FundTransfer";
import { offsetArr } from "../../utils/CommonConfig";
import QuickPay from "../dashboard/QuickPay";
import PendingTransactions from "../pending-transactions/PendingTransactions";
const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const tranxData = [
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
];

const DirectFundTransfer = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const refresh = () => {
    // getLevelsData();
  };
  const updateLevelModal = (level) => {
    //  setSelectedLevel(level);
    // setIsOpen2(true);
  };
  useEffect(() => {
    // getLevelsData();
  }, []);
  return (
    <div
      className="tab-custom-pills-horizontal mt-2"
      style={{ background: "#EDF7FF" }}
    >
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <div className="col-12 col-sm-8 col-md-6">
            <Nav
              variant="pills"
              className="nav-pills-custom justify-content-around p-1 "
              style={{
                borderRadius: "1rem",
                padding: "1rem",
                border: "1px solid #2F58CD",
                background: "#FFF",
              }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="d-flex align-items-center"
                >
                  Fund Transfer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className="d-flex align-items-center"
                >
                  Pending And Refund
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center">
            <Button
              className="btn btn-success m-2"
              onClick={() => {
                history.push("/retailer/topup_request");
              }}
            >
              {" "}
              Add Fund
            </Button>
          </div>
          <Col xs={12} className="p-0">
            <Tab.Content className="tab-content-custom-pill p-0">
              <Tab.Pane eventKey="first">
                <FundTransfer />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <PendingTransactions />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default DirectFundTransfer;
