import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";

const Inactiveemployee = () => {
  const { password, username } = billpunchbasicauth;
  const [activity, setActivity] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getActivity = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllInactiveEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setActivity(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const datePart = dateString.split(",")[0]; 
    return datePart || "N/A"; 
  };
  return (
    <div className="p-3 mt-3">
      <h5>Inactive Employees</h5>
      <table className="table ">
        <thead>
          <tr>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Employee Code</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Name</th>
            {/* <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Designation</th> */}
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Department</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Mobile</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Email</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Joining Date</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>Age</th>
            <th  style={{background:"#FEFAE0", color:"#387F39", border:"1px solid #dee2e6"}}>PAN</th>
          </tr>
        </thead>
        <tbody>
          {activity.length > 0 ? (
            activity.map((emp) => (
              <tr key={emp.empid}>
                <td>{emp.empcode}</td>
                <td>{`${emp.firstname} ${emp.middlename || ""} ${emp.lastname || ""}`}</td>
                {/* <td>{emp.designation}</td> */}
                <td>{emp.department}</td>
                <td>{emp.mobile}</td>
                <td>{emp.email}</td>
                <td>{formatDate(emp.joiningdate)}</td>
                <td>{emp.age}</td>
                <td>{emp.pan}</td>
              </tr>
            ))
          ) : (
            <tr>
            <td colSpan="8" className="text-center">No inactive employees found.</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Inactiveemployee;
