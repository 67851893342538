import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel, now } from "../utils/utilFuctions";
// import { CLIENT } from "../../config";
import { urls } from "./devUrls";

export const columns = [
  {
    dataField: "accountNo",
    text: "Account Number",
    color: "#586275",
  },
  {
    dataField: "ifscCode",
    text: "IFSC Code",
    color: "#586275",
  },
  {
    dataField: "upi",
    text: "UPI",
    color: "#586275",
  },
  {
    dataField: "createdOn",
    text: "Created On",
    color: "#586275",  },
];

const SmartCollectVirtualAccountTable = ({ setDetails }) => {
  const { createVirtualAccount: createVirtualAccountUrl } = urls;
  const { getAllVirtualAccounts: getAllVirtualAccountsUrl } = urls;
  const { getAllVirtualPayments: getAllVirtualPaymentsUrl } = urls;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualAccounts = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );
    console.log(accessToken);

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(getAllVirtualAccountsUrl, requestOptions);
      const data = await res.json();
      console.log(data.data);
      if (data.data && data.data.length > 0) {
        const dataLoad = data.data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.accountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        });
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setLedger(dataLoad);
        // setDataToDisplay(dataLoad);
      }
    } catch (error) {}
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  // useEffect(() => {
  //   let filteredData = ledger.filter((bank) => {
  //     let b = false;
  //     if (ledger.length > 0) {
  //       for (let key in ledger[0]) {
  //         if (
  //           bank[key] &&
  //           bank[key].toString().toLowerCase().includes(searchText.toLowerCase())
  //         ) {
  //           b = true;
  //         }
  //       }
  //     }
  //     return b;
  //   });
  //   console.log(filteredData);
  //   const startIdx = (currentPage - 1) * 10;
  //   const endIdx = currentPage * 10 - 1;
  //   console.log({ startIdx, endIdx });
  //   filteredData = filteredData.filter((bank, idx) => {
  //     console.log(idx, idx <= endIdx && idx >= startIdx);
  //     return idx <= endIdx && idx >= startIdx;
  //   });
  //   setDataToDisplay(filteredData);
  //   console.log({ startIdx, endIdx });
  // }, [currentPage]);

  useEffect(() => {
    getAllVirtualAccounts();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualAccounts();
  }, []);
  console.log(ledger);
  return (
    <div style={{background:"white"}}>
      <div
      
      >
         <div
       className="row"
      >
      <div className="col-7 m-2">  
      <div  className="d-flex"
          style={{ cursor: "pointer",  border: "1px solid",height:"2.5rem",width:"11rem",
                                          backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                          color: 'rgba(43, 193, 85, 1)',
                                          bordercolor: 'rgba(43, 193, 85, 1)',borderRadius:".4rem" }}
          onClick={() => {
            ExportToExcel(
              `DMT_Report_${now()}`,
              excelMapping(dataToDisplay, columns)
            );
          }}
        >
          <img alt=""
            src={require("../../assets/images/excel.png")}
            style={{ width: "1.6rem", marginLeft: "15px" }}
          />
          <strong className="d-flex align-items-center"  style={{ marginLeft: "2px" }}>Export to Excel</strong>
        </div>
        </div>
       
        <div className=" col m-2 daterange-picker">
          <Form.Group >
           
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width:"8rem" }}>
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px",width:"8rem" }}>
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px", }} > <Button className="" style={{borderRadius:".4rem"}} variant="primary" onClick={() => {}}>
              {"Search"}
            </Button></div>
        </div>
        
         
     
      </div>
      </div>
   
      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
      <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
        <tr>
  <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
  {columns.map((heading, index) => (
    <th key={index} className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>{heading.text}</th>
  ))}
</tr>
        {dataToDisplay.map((e, ind) => (
          <tr>
            <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
            {columns.map((cell) =>
              cell.dataField == "status" ? (
                e[cell.dataField] == "success" ? (
                  <td style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-success"
                    >
                      {"Success"}
                    </label>{" "}
                  </td>
                ) : (
                  <td
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-warning"
                    >
                      {e[cell.dataField]}
                    </label>
                  </td>
                )
              ) : (
                <td
                  onClick={() => {
                    if (cell.dataField == "accountNo") {
                      setDetails(e);
                    }
                  }}
                  style={{
                    fontSize: ".8rem",
                    color: cell.color,
                    cursor: cell.dataField == "accountNo" ? "pointer" : "",
                  }}
                >
                  {e[cell.dataField]}
                </td>
              )
            )}
          </tr>
        ))}
      </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          console.log(ledger);
          if (ledger.length > 0) setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default SmartCollectVirtualAccountTable;
