import React from "react";
import { inWords, twoDecimalPlaces } from "../utils/utilFuctions";

const SalarySliphr = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  let printData = JSON.parse(localStorage.getItem("print"));
  if (!printData) {
    printData = {};
  }
  console.log(printData);
  const printReceipt = () => {
    window.print();
  };

  const NOTE =
    "This is Computer Generated receipt and does not require physical signature.";


  return (
    <div >
      <button
        style={{ marginLeft: "auto" }}
        className="hide-on-print btn btn-primary"
        onClick={printReceipt}
      >
        {"Print"}
      </button>
      <div>
        <form onSubmit={handleSubmit}>
          <form>
            <div className="card pt-md-2" style={{ border: "1px solid" }}>
              <div className="d-flex justify-content-end">
                <img
                  alt=""
                  src={require("../../assets/images/pms/salary_logo.png")}
                  style={{ height: "2rem" }}
                />
              </div>
              <div>
                <h5 className="d-flex justify-content-center">
                  Bumppy Media Private Limited
                </h5>
                <h6 className="d-flex justify-content-center">
                  B-218,ITHUM Tower-B,2nd Floor, Sec-62, Noida, U.p-201301
                </h6>
              </div>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <h6 className="d-flex justify-content-center">
                <b>Salary Slip</b>
              </h6>
              <hr className="p-0 m-0" style={{ background: "#000" }} />
              <div className="row">
                <div className="col">
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Emp Code:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.empCode|| "NA"}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Name :
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.name || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Designation:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.designation || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Department:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.department || "NA"}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col px-0" style={{ color: "#000" }}>
                      Bank Account No:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.bankAccNo || "NA"}{" "}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      Month:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.currentMonth || "NA"}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      {" "}
                      ESI No:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.esic || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      UAN:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.uan || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      Salary Mode:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.salaryMode || "NA"}{" "}
                    </p>
                  </div>
                  <div className="row m-0 p-0" style={{ color: "#000" }}>
                    <p className="col " style={{ color: "#000" }}>
                      Bank Name:
                    </p>
                    <p className="col  " style={{ color: "#000" }}>
                      {" "}
                      {printData?.bankName || "NA"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="mt-md-1" style={{ background: "#000" }} />
              <table className="m-0" style={{ border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th>Standard Wages Amount</th>
                    <th></th>
                    <th>Earned Wages Amount</th>
                    <th>Deduction</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      Basic
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.basic || "NA"}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.basic || "NA"}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      EPF
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(printData?.epf||"NA")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      HRA
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.hra || "NA"}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.hra || "NA"}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      ESIC
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.esicDeduction || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      Travel Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.tAllowance || "NA"}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.tAllowance || "NA"}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      TDS
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.tds || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      Med. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.mAllowance || "NA"}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {printData?.mAllowance || "NA"}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      Oth. Allowance
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                         {printData?.oAllowance || "NA"}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                         {printData?.oAllowance || "NA"}              
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    ></td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      Total
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(printData?.totalSalaryWithPF || "NA")}
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                      {twoDecimalPlaces(printData?.totalSalaryWithPF || "NA")}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        verticalAlign: "top",
                        fontSize: ".9rem",
                      }}
                    >
                      Total Deduction
                    </td>
                    <td
                      style={{ border: "1px solid black", fontSize: ".9rem" }}
                    >
                        {twoDecimalPlaces(printData?.totalDeduction || "NA")}
                    
                    </td>
                  </tr>
                </tbody>

                <tfoot className="mb-md-2">
                  <tr>
                    <td colspan="5" style={{ border: "1px solid black" }}>
                      <p style={{ color: "#000" }}>
                        Total Payable Amount : {twoDecimalPlaces(printData?.totalPayableAmount || "NA")}
                      </p>
                      <p style={{ color: "#000" }}>
                        Payable Amount in Words : {inWords(Number(printData?.payableAmountInWords || "NA"))}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <hr className="mt-md-3" style={{ background: "#000" }} />
              <p style={{ color: "#000" }}>
                This is a computer generated pay slip and does not required any
                signature or any company seal.
              </p>
            </div>
          </form>
        </form>
      </div>
    </div>
  );
};

export default SalarySliphr;
