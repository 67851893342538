import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Payrollmanagement = () => {
  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "14");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
        <Helmet>
  <title>{pageData ? pageData.basic_settings.tittle : "Loading..."}</title>
  <meta name="description" content={pageData ? pageData.basic_settings.meta_desc : "Loading..."} />
  <meta name="keywords" content={pageData ? pageData.keyword_settings.map(keyword => keyword.keyword).join(", ") : "Loading..."} />
  <link rel="canonical" href={pageData ? pageData.basic_settings.canonical_tag : "#"} />
  <meta property="og:title" content={pageData ? pageData.og_settings?.og_title || "Loading..." : "Loading..."} />
  <meta property="og:description" content={pageData ? pageData.og_settings?.og_desc || "Loading..." : "Loading..."} />
  <meta property="og:image" content={pageData ? pageData.og_settings?.og_image || "#" : "#"} />
  <meta property="og:image:type" content="image/jpeg" />
  <meta property="og:image:width" content="1200" />
  <meta property="og:image:height" content="630" />
  <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
</Helmet>

      <Navbar />  
      <h1 className="text-center mt-3">Payroll Management </h1>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
          <p>Efficient, Accurate, and Seamless Payroll Processing</p>
          <p>Payroll management is a critical function in any organization, ensuring that employees are compensated accurately and on time. The Payroll Management module in HRMS by BillPunch offers a comprehensive solution to streamline and automate this complex process. With BillPunch, managing payroll is not just about disbursing salaries; it’s about integrating payroll seamlessly with attendance, leave, and other HR functions to enhance overall productivity and compliance.</p>

          <h5>1. Automated Payroll Calculation:</h5>
          <ul>
            <li>Calculate salaries with precision by integrating attendance, leave, and overtime data.</li>
            <li>Support for multiple pay structures to cater to different employee groups and roles.</li>
            <li>Automatic calculation of statutory deductions such as taxes, provident fund, and insurance.</li>
          </ul>

          <h5>2. Compliance and Tax Management:</h5>
          <ul>
            <li>Stay compliant with local, state, and federal tax regulations.</li>
            <li>Generate and file statutory reports easily, including PF, ESI, and TDS.</li>
            <li>Regular updates to ensure compliance with the latest laws and regulations.</li>
          </ul>

          <h5>3. Flexible Pay Schedules:</h5>
          <ul>
            <li>Support for multiple pay cycles (weekly, bi-weekly, monthly).</li>
            <li>Customizable pay periods to fit organizational needs.</li>
          </ul>

          <h5>4. Integration with Time and Attendance:</h5>
          <ul>
            <li>Direct integration with the Time and Attendance module for accurate payroll processing.</li>
            <li>Real-time data syncing to ensure up-to-date attendance and leave records.</li>
          </ul>

          <h5>5. Employee Self-Service Portal:</h5>
          <ul>
            <li>Empower employees with access to their payroll information via a self-service portal.</li>
            <li>View and download payslips, tax forms, and other payroll-related documents.</li>
            <li>Submit and track reimbursement claims online.</li>
          </ul>

          <h5>6. Advanced Reporting and Analytics:</h5>
          <ul>
            <li>Generate detailed payroll reports to gain insights into labor costs and payroll trends.</li>
            <li>Customizable report templates to suit various departmental needs.</li>
            <li>Export reports in multiple formats (PDF, Excel, CSV) for further analysis.</li>
          </ul>

          <h5>7. Direct Deposit and Payroll Distribution:</h5>
          <ul>
            <li>Facilitate direct deposit to employees' bank accounts for quicker and safer payment.</li>
            <li>Options for paycheck printing and electronic fund transfer (EFT).</li>
          </ul>

          <h5>8. Security and Data Privacy:</h5>
          <ul>
            <li>Robust security protocols to ensure the confidentiality and integrity of payroll data.</li>
            <li>Role-based access control to restrict access to sensitive information.</li>
            <li>Regular data backups and secure storage solutions.</li>
          </ul>

          <h5>Why it is important?</h5>
          <ul>
            <li><strong>Increased Efficiency:</strong> Automate routine payroll tasks to save time and reduce errors.</li>
            <li><strong>Improved Accuracy:</strong> Ensure precise calculations and reduce the risk of manual errors.</li>
            <li><strong>Enhanced Compliance:</strong> Stay up-to-date with changing regulations and avoid penalties.</li>
            <li><strong>Greater Transparency:</strong> Provide employees with clear and accessible payroll information.</li>
            <li><strong>Cost Savings:</strong> Reduce administrative costs associated with payroll processing.</li>
          </ul>

          <h5>Implementation and Support:</h5>
          <p>BillPunch offers a smooth implementation process with dedicated support to ensure that the Payroll Management module is tailored to your organization’s needs. Our team of experts will assist you in migrating existing payroll data, configuring the system, and training your HR staff. Ongoing support and regular updates ensure that your payroll system remains efficient and compliant.</p>

          <p>Experience the power of automated, accurate, and compliant payroll processing with HRMS by BillPunch. Transform your payroll management today!</p>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Payrollmanagement;
