import React from "react";
import CreditCardBillPaymentReportTable from "./CreditCardBillPaymentReportTable";

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];
export const dmtReport = [
  {
    mobile: "9168805979",
    beneficiary: "32905756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "800",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "4/9/2022 7:17:31 PM",
  },
  {
    mobile: "9057568979",
    beneficiary: "568464756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "1000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "13/2/2022 7:17:31 PM",
  },
  {
    mobile: "9168057568",
    beneficiary: "568907568464565",
    type: "IMPS",
    utrNo: "0",
    amount: "40000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "3/4/2022 7:17:31 PM",
  },
  {
    mobile: "9105756879",
    beneficiary: "684649075684646",
    type: "IMPS",
    utrNo: "0",
    amount: "2000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "pending",
    datetime: "23/5/2022 7:17:31 PM",
  },
];
const CreditCardBillPaymentReport = () => {

  return (


    <div
         style={{ padding: "1rem", backgroundColor: "#F2F6FC" }}
         className=" "
       >
         <div
           style={{ borderRadius: "10px" }}
           className="card mt-4 p-0 mx-2 border-light "
         >
           <div className="card-body m-0 p-2">
              <div>
              

              <CreditCardBillPaymentReportTable />
              </div>
            </div>
          </div>
        </div>
  
  );
};

export default CreditCardBillPaymentReport;
