import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT, BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { FaFlag } from "react-icons/fa";
import swal from "sweetalert";
import { billpunchbasicauth } from "../../utils/Credentials";

const LoadRequest = () => {
  const [bankOptions, setBankOptions] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [amount, setAmount] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [Priority, setPriority] = useState("");
  const [remark, setRemark] = useState("");
  const [errors, setErrors] = useState({});
  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  
  const options = [
  
    {
      value: "3",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "2",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Medium
        </div>
      ),
    },
    {
      value: "1",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
  
  ];
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const validateForm = (e) => {
    e.preventDefault();
    const errors = {};

    if (!amount) {
      errors.amount = "Amount is required";
    }

    if (!transactionID) {
      errors.transactionID = "Transaction ID is required";
    }

    if (!selectedBank) {
      errors.selectedBank = "Bank selection is required";
    }

    if (!type) {
      errors.type = "Transaction type is required";
    }

    if (!Priority) {
      errors.Priority = "Priority is required";
    }


    if (!remark) {
      errors.remark = "Remark is required";
    }else if (remark.trim() !== remark) {
      errors.remark = "remark should not start with spaces";
     
    }

    setErrors(errors);

    if (Object.keys(errors).length == 0) {
      handleSubmit();
    }
  };
  const { password , username } = billpunchbasicauth;
  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      setTransactionLoaderState(true);
  
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        userid: user.userid,
        type: type.label,
        amount: amount,
        description: remark,
        priority: Priority.value,
        userType: "2",
        bankid: selectedBank ? selectedBank.value : null,
      });
      console.log(raw, "kil");
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddLoadRequestSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result.message, "success");
        setAmount("");
        setTransactionID("");
        setDate("");
        setType("");
        setPriority("");
        setRemark("");
        setSelectedBank(null);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  
  
  const fetchBankList = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://apitranxt.bumppypay.com/recApiFinal/apiget.aspx?method=getloadbank&service=aaa`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      console.log(data);
      const options = data.map((bank) => ({
        value: bank.bankId,
        label: `${bank.bankName} | ${bank.ifscCode}`,
      }));
      
      setBankOptions(options);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);

  const transactionTypeOptions = [
    { value: "3", label: "Cash Deposit" },
    { value: "2", label: "UPI/IMPS" },
    { value: "5", label: "NEFT/RTGS" },
    { value: "9", label: "CDM" },
    { value: "8", label: "Fund Transfer" },
    { value: "10", label: "CHEQUE" },
  ];

  return (
    <>
      <div className="row p-0 p-lg-5">
        <div className="col-3"></div>
        <div
          className="col-12 p-3 p-lg-4 col-lg-6 bg-white shadow"
          style={{ borderRadius: "10px" }}
        >
          <form onSubmit={validateForm} className="row">
            <div className="mb-3 col-12 d-flex align-items-center justify-content-center text-center">
              <div
                className=""
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  fontFamily: "poppins",
                }}
              >
                Add Load Request
              </div>
            </div>

            <div className="mb-3 col-12 col-lg-6">
              <label className="form-label">Amount</label>
              <input
                type="number"
                className="form-control"
                value={amount}
                placeholder="Enter Amount"
                onChange={(e) => setAmount(e.target.value)}
              />{" "}
              {errors.amount && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.amount}
                </div>
              )}
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label className="form-label">Transaction ID</label>
              <input
                type="number"
                className="form-control"
                value={transactionID}
                placeholder="Enter Transaction ID"
                onChange={(e) => setTransactionID(e.target.value)}
              />{" "}
              {errors.transactionID && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.transactionID}
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Select Bank</label>
              <Select
                options={bankOptions}
                value={selectedBank}
                onChange={setSelectedBank}
                placeholder="Select Bank"
                isClearable
              />{" "}
              {errors.selectedBank && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.selectedBank}
                </div>
              )}
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label className="form-label">Type</label>
              <Select
                options={transactionTypeOptions}
                value={transactionTypeOptions.find(
                  (option) => option.value === type
                )}
                onChange={setType}
                placeholder="Select Type"
                isClearable
              />{" "}
              {errors.type && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.type}
                </div>
              )}
            </div>
        
            <div className="mb-3 col-12 col-lg-6">
              <label className="form-label">Priority</label>
              <Select
                options={options}
                onChange={setPriority}
                placeholder="Select Priority"
                isClearable
              />
              {errors.Priority && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.Priority}
                </div>
              )}
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label className="form-label">Remarks</label>
              <input
                type="text"
                className="form-control"
                value={remark}
                placeholder="Enter Remarks"
                onChange={(e) => setRemark(e.target.value)}
              />{" "}
              {errors.remark && (
                <div className="text-danger" style={{ fontSize: ".8rem" }}>
                  {errors.remark}
                </div>
              )}
            </div>

            <div className="mt-4 col-12 d-flex justify-content-center">
              <button
                type="submit"
                className=" btn btn-lg btn-block text-white bg-success"
                style={{
                  width: "30%",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="col-3"></div>
      </div>
    </>
  );
};

export default LoadRequest;

