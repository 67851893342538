import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { SalaryExpenseHRModal, SalaryExpenseModal } from "./Modal";

const Expenses = () => {
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  const openExpenseModal = () => {
    setIsExpenseModalOpen(true);
  };

  const closeExpenseModal = () => {
    setIsExpenseModalOpen(false);
  };

  const doughnutData = {
    labels: ["Category 1", "Category 2", "Category 3"],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ["#40E0D0", "#5D8AA8", "#004F98"],
      },
    ],
  };
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      arc: {
        borderWidth: 0.8,
      },
    },
    cutoutPercentage: 70,
  };
  //   const customLegend = (
  //     <div className="legend">
  //       {doughnutData.labels.map((label, index) => (
  //         <div key={index} style={{ display: "flex", alignItems: "center" }}>
  //           <div
  //             style={{
  //               width: "10px",
  //               height: "10px",
  //               backgroundColor: doughnutData.datasets[0].backgroundColor[index],
  //               marginRight: "5px",
  //             }}
  //           />
  //           <span>{label}</span>
  //         </div>
  //       ))}
  //     </div>
  //   );

  return (
    <>
      <div className="row mt-md-3 mt-sm-3 p-0 mt-sm-3">
        <div className="col-6 mx-md-5 mx-sm-3 mb-sm-3  ">
          <h1>
            <b> All your expenses in one place</b>
          </h1>

          <p style={{ fontSize: "1rem" }}>
            Save hours of work by tracking expenses
          </p>
          <div className="row m-0 p-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp; Add & Manage your expenses here
            </p>
          </div>
          <div className="row p-0 m-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>#</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  Organize your expenses into categories
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex align-items-center col p-0 m-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  See where you're spending your money
            </p>
          </div>
          <div className="mt-md-3 mt-sm-2">
            {" "}
              <button
                type="button"
                className="btn btn-sm d-flex align-items-center justify-content-center "
                style={{
                  background: "#2ca01c",
                  height: "2.5em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openExpenseModal}
              >
                Add expense manually
              </button>
          </div>
          <div className="mt-md-3 mt-sm-2">
            {" "}
            <Link to={`/retailer/ViewSalaryExpenseHR`}>
              <button
                type="button"
                className="btn btn-sm d-flex align-items-center justify-content-center"
                style={{
                  background: "#2ca01c",
                  height: "2.5em",
                  border: "None",
                  color: "#fff",
                }}
              >
                View expense
              </button>
            </Link>
          </div>
        </div>

        <div className="col mr-md-4 mr-sm-3 d-flex justify-content-center">
          
          <div
            className=""
            style={{
              background: "#fff",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h6 style={{ color: "grey" }} className="m-3">
              SPENDING LAST MONTH
            </h6>
            <h5 style={{ color: "grey" }} className="m-3">
              ₹1222
            </h5>
            {/* {customLegend} */}
            
            <Doughnut data={doughnutData} options={doughnutOptions} />

            <SalaryExpenseHRModal isOpen={isExpenseModalOpen} closeModal={closeExpenseModal} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Expenses;
