import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import {
  BASE_URL_AGENT,
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../../utils/StringConstants";
import { basicAuth } from "../../config";

const Navbar = () => {
  const history = useHistory();
  const { user, removeUserInfo } = useContext(GlobalContext);
  const [menus, setMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [myAccountInfo, setMyAccountInfo] = useState(null);
  const { username, password } = basicAuth;

  // get my account details
  const getAccountInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    console.log("called user id", userId);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Profile/GetUserProfileInfo`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      setMyAccountInfo(data.data);
    } catch (error) { }
  };

  //
  const generateToken = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8JsnH411h-hPvOBmxZ5uxqP6rp2ytIifypwWZTazYqnFXDYohzj3JhFUoUEyEcGcfpXoK2-0ijf3BfPQJXY7Nk86kTqeOrMMhiG8TINrH5E2C_nKDsku7O4JS7yDc2tSweTku1oPbP1jYCQ7aXuXI4rWYFu_iikYclB72RfTjJLAF64UAjWZ_va2rZZ_wvLHItEF2zvWTMqKayPDzIc44DwsgDYpPhaj4mDx1kNNk1OiItKIvPzVRU3DVcNiC2PckNQw2lVRod5mMErEu_LUI728kGjTcARb2vYmffdusRK-1f2thw0FOBPimLZTJq16HFGx8wBKNEgVSZJD-IwAym8IFHQgw-_FdhFBBzIdj4ovd8cbLbqBBIF_dJmVvCHL5a08sEcFeJcoa_HSYFlQDMF57fvS_EOVvnAe8SQz5l2BUuPk8Zwq0S1aePx6e0xY09i9l-g5zMPZgvX-cALm-TtrvhVKUkvnM2gXYabjX0po2NmepBzeSwioEcsuOZpTFGfcDwKlIXKclnPYGvVuVlSS1aJRFpKBzJu0SpbFIwKKab7W9nYwU752JuiZFIrZKJCa2G8kRo7P3qPHKAMFNbwfCNEVIbB5A_GwBU2pzhTVUeLiHcPkvFgxcGn1m_nzmxqivZyTey5K7Qvhhv38mESKZytL-ZOmLU-3Bggomr9d"
    );

    var raw = JSON.stringify({
      email: username,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const result = await fetch(
        `${BASE_URL_PAYNOW}/api/Account/Login`,
        requestOptions
      );
      if (result.ok) {
        const body = await result.text();
        const data = JSON.parse(body);
        if (data.userName != undefined) {
          sessionStorage.setItem("userName", data.userName);
        }
        if (data.role != undefined) {
          sessionStorage.setItem("role", data.role);
        }
        if (data.userId != undefined) {
          sessionStorage.setItem("userId", data.userId);
        }
        if (data.accessToken != undefined) {
          // setToken(data.accessToken);
          sessionStorage.setItem("accessToken", data.accessToken);
          getAccountInfo();
        }
        if (data.refreshToken != undefined) {
          sessionStorage.setItem("refreshToken", data.refreshToken);
        }
      } else {
        // setInvalid(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRoleDetails2 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        role_id: user.fk_role_id,
        user_id: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/login/get_user_roledata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0" && Array.isArray(data.data)) {
        setMenus(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getRoleDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/admin/getRoleData",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setMenus(data.menus);
        setSubMenus(data.sub_menus);
        sessionStorage.setItem("sub_menus", JSON.stringify(data.sub_menus));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    generateToken();
    getRoleDetails();
  }, []);
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null ||
          state.user.status == "" ||
          state.user.status == "N" ? (
          <Redirect to="/admin" />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            {/* <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo-mini" to="/">
              <span style={{ fontSize: "2rem", fontWeight: "600" }}>B</span>
            </Link>
          </div> */}

            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              // onClick={() =>
              //   document.body.classList.toggle("sidebar-icon-only")
              // }
              >
                <Link to="/admin/dashboard">
                  <img
                    src={require("../../../assets/images/company/navbar_logo.png")}
                    style={{ height: "2.5rem" }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-left ml-3">
                <strong
                  className="cursor-pointer mx-2"
                  onClick={() => {
                    history.push("/admin/dashboard");
                    // toggleOffcanvas();
                  }}
                >
                  <Trans>Dashboard</Trans>
                </strong>

                {/* Dropdowns */}
                {menus.map((menu) => (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Toggle
                      as="a"
                      className="nav-link cursor-pointer no-caret m-1"
                    >
                      <div className="navbar-profile">
                        <strong> {menu.menu_title}</strong>
                        <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                      </div>
                    </Dropdown.Toggle>
                    {getMenu(menu.pk_menu_id, history, subMenus)}
                  </Dropdown>
                ))}
              </ul>
              <ul className="navbar-nav navbar-nav-right">
                {/* Wallet Balance */}
                {/* {myAccountInfo == null ? null : (
                  <li style={{ display: "flex" }}>
              
                    <label
                      className="mx-2 badge badge-primary d-flex flex-column justify-content-center align-items-center hover"
                      style={{ padding: ".5rem  ", fontSize: ".7rem" }}
                      onClick={getAccountInfo}
                    >
                      <strong>{"BBPS H Bal"}</strong>
                      <strong>{`₹ ${myAccountInfo.bbpsOfflineBalance}`}</strong>
                    </label>
                    <label
                      className="mx-2 badge badge-success d-flex flex-column justify-content-center align-items-center hover"
                      style={{
                        background: "#FC7300",
                        border: "none",
                        padding: ".5rem ",
                        fontSize: ".7rem",
                      }}
                      onClick={getAccountInfo}
                    >
                      <strong>{"AEPS Bal"}</strong>
                      <strong>{`₹ ${myAccountInfo.aepsBalance}`}</strong>
                    </label>
                    <label
                      className="mx-2 badge badge-success d-flex flex-column justify-content-center align-items-center hover"
                      style={{ padding: ".5rem  ", fontSize: ".7rem" }}
                      onClick={getAccountInfo}
                    >
                      <strong>{"DMT Bal"}</strong>
                      <strong>{`₹ ${myAccountInfo.paymentAPIBalance}`}</strong>
                    </label>
                  </li>
                )} */}
                <Dropdown
                  alignRight
                  as="li"
                  className="nav-item navbar-nav-right"
                >
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret m-1"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={userAvtar}
                        alt="profile"
                        style={{ height: "1.5rem", width: "auto" }}
                      />
                      <p className="mb-0 d-none d-sm-block navbar-profile-name">
                        <Trans>
                          {user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-left"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {user.name}
                                </strong>
                              </span>
                              <strong
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                {`Role : ${user.rolename}`}
                              </strong>
                            </div>
                          )}
                        </Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ minWidth: "20rem" }}
                    className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                  >
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        history.push("/admin/my_profile");
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-gear"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>My Profile</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeUserInfo();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-sign-out"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Logout</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

const getMenu = (id, history, subMenus) => {
  const sub = subMenus.filter((sm) => {
    return sm.menu_id == id;
  });
  return (
    <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
      {sub.map((e) => (
        <React.Fragment>
          <Dropdown.Item
            className="dropdown-item preview-item admin-nav-text p-2"
            onClick={(evt) => {
              evt.preventDefault();
              history.push(e.menu_url);
            }}
            href="#"
          >
            <div className="preview-item-content">
              <span className="preview-subject">
                <Trans>{e.menu_title}</Trans>
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
        </React.Fragment>
      ))}
    </Dropdown.Menu>
  );
};

export default Navbar;
