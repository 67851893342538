import React, {
  useState,
  createRef,
  useEffect,
  useRef,
  useContext,
} from "react";
import "./Chat.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";

const ChatContent = ({ Ticket, Status }) => {

  const Month = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Comment, setComment] = useState("");
  const [Chat, setChats] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [ChatResp, setChatResp] = useState([]);

  const TicketResponse = async () => {
    const tid = sessionStorage.getItem("ticketId");
    if (tid && tid.length > 0) {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "ticketId": tid
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_APISERVICES}/api/Payment/support/getAllTicketResponsesList`,
          requestOptions
        );
        const data = await res.json();
        if (data.status == "0") {
          setChatResp(data.data);
          getChatObj(data.data);
          scrollToBottom();
        } else {
          getChatObj([]);
          setChatResp([]);
        }

        setTransactionLoaderState(false);
      } catch (error) {
        setTransactionLoaderState(false);
        console.log("error", error);
      }
    }



  };

  const chatReply = async () => {
    const tid = sessionStorage.getItem("ticketId");
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({

        "userid": user.ReferId,
        "replyBy": "AGENT",
        "ticketId": tid,
        "assignedTo": "1",
        "status": "0",
        "remarks": Comment,
        "replyMessage": Comment,
        "type": "1"

      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/support/ticketChatReplyAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        clearForm()
        TicketResponse();
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }


  };

  const [ChatObj, setChatObj] = useState([]);

  const dateToStr = (d) => {
    const date = new Date(d);
    return `${date.getHours() < 12 ? date.getHours() : date.getHours() - 12}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      } ${date.getHours() < 12 ? "AM" : "PM"} `;
  };

  // 12/28/2022 4:27:33 PM
  const dateToStr2 = (date) =>
    `${date.getDate()}-${Month[date.getMonth() + 1]
    }-${date.getFullYear()}`;

  const getChatObj = (result) => {
    console.log(result);
    const Chats = result.map((Ch, i) => {
      return {
        key: Ch.id,
        msg: Ch.response_message,
        image:
          Ch.reply_on == 'AGENT'
            ? require("../../../assets/images/avatar.png")
            : require("../../../assets/images/company/logo_square.png"),
        type: Ch.reply_on == 'AGENT' ? "" : "other",
        date: dateToStr2(new Date(Ch.response_date)),
        time: dateToStr(new Date(Ch.response_date)),
      };
    });
    setChatObj([
      {
        key: "0",
        msg: `hey ${user.ReferId} how can we help you`,
        image: require("../../../assets/images/company/logo_square.png"),
        type: "other",
      },
      ...Chats,
    ]);

  };

  const messagesEndRef = useRef(null);

  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // const handleChange = event => {
  //     const fileUploaded = event.target.files[0];
  //     handleFile(fileUploaded);
  // };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    console.log("called");
  };

  const clearForm = () => {
    setComment("")
  }

  useEffect(() => {
    if (Ticket.length > 0) {
      sessionStorage.setItem("ticketId", Ticket);
      // setTicketId(Ticket)
    }

  }, [Ticket]);


  useEffect(() => {
    console.log(Ticket);

    const intervalId = setInterval(() => {
      // if (Ticket.length > 2) {
      TicketResponse();
      //   }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="main__chatcontent">
      <div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">
            <Avatar
              // isOnline="active"
              image={require("../../../assets/images/company/logo_square.png")}
            />
            <p>Bumppy Payments</p>
          </div>
        </div>

        <div className="blocks">
          <div className="settings">
            <span
              className="btn-nobg"
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
                sessionStorage.removeItem("ticketId");
              }}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
      <div className="content__body scrollView">
        <div className="chat__items">
          {ChatObj.map((itm, index) => {
            return (
              <ChatItem
                animationDelay={index + 2}
                key={itm.key}
                user={itm.type ? itm.type : "me"}
                msg={itm.msg}
                image={itm.image}
                Date={itm.date}
                Time={itm.time}
              />
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="content__footer">
        <div className="sendNewMessage">
          <button className="addFiles" onClick={handleClick}>
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".jpeg,.jpg,.png"
              onChange={(e) => setComment(e.target.files[0])}
              style={{ display: "none" }}
            />
            <i className="fa fa-paperclip"></i>
          </button>
          <input
            type="text"
            placeholder="Type a message here"
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={Comment}
          />
          <button
            className="btnSendMsg"
            id="sendMsgBtn"
            onClick={chatReply}
          >
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatItem = ({ user, msg, date, time, image }) => {
  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${user ? user : ""}`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">{msg}</div>
        <div className="chat__meta">
          <span>{date} &nbsp;</span>
          <span>{time}</span>
        </div>
      </div>
      <Avatar
        // isOnline="active"
        image={image}
      />
    </div>
  );
};

const Avatar = ({ image, isOnline }) => {
  return (
    <div className="avatar">
      <div className="avatar-img">
        <img src={image} alt="#" />
      </div>
      <span className={`isOnline ${isOnline}`}></span>
    </div>
  );
};

const ChatListItems = (props) => {
  const selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };

  return (
    <div
      style={{ animationDelay: `0.${props.animationDelay}s` }}
      onClick={selectChat}
      className={`chatlist__item ${props.active ? props.active : ""} `}
    >
      <Avatar
        image={props.image ? props.image : "http://placehold.it/80x80"}
        isOnline={props.isOnline}
      />

      <div className="userMeta">
        <p>{props.name}</p>
        <span className="activeTime">32 mins ago</span>
      </div>
    </div>
  );
};

export default ChatContent;
