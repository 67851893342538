import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { FaEye } from "react-icons/fa";
import { billpunchbasicauth } from "../../utils/Credentials";

const Privacypolicyemp = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Policydata, setPolicydata] = useState([]);
  const [Addpolicy, setAddpolicy] = useState(false);
  const { password, username } = billpunchbasicauth;

  const openaddpolicyModal = () => {
    setAddpolicy(true);
  };

  const closeaddpolicyModal = () => {
    setAddpolicy(false);
  };
  const Policy = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmPolicy`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setPolicydata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Policy();
  }, []);

  const openPdfInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <div className="row px-3 pt-3">
        <div className="col">
          <h4>Company Policy</h4>
        </div>
      </div>
      <div className="p-3">
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <colgroup>
            <col style={{ width: "5%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "5%" }} />
          </colgroup>
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                ID
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Policy
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Issue Date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Desc.
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Policydata.length === 0 ? (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            ) : (
              Policydata.map((item) => (
                <React.Fragment key={item.pk_policy_id}>
                  <tr style={{ border: "none", cursor: "pointer" }}>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.pk_policy_id}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.policy_name}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {new Date(item.policy_indate).toLocaleDateString()}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.policy_desc}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: "1.5rem",
                      }}
                    >
                      {item.image_url ? (
                        <FaEye
                          onClick={() => openPdfInNewTab(item.image_url)}
                        />
                      ) : (
                        <FaEye
                          style={{ color: "gray", cursor: "not-allowed" }}
                        />
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Privacypolicyemp;
