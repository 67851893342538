import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Nabvar from "./Navbar";
const Billpunchcancellationpolicy = () => {
  return (
    <>
      <Nabvar />
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col">
          <h1 className="text-center">Cancellation Policy</h1>
          <h5> 1. Overview </h5>
          <p style={{ fontSize: "1rem" }}>
            This Cancellation Policy explains the terms and procedures for
            canceling your subscription of BillPunch. By using the System, you
            agree to comply with this policy.
          </p>

          <h5> 2. Subscription Cancellation</h5>
          <ul>
            <li style={{ fontSize: "1rem" }}>
              <h6>2.1 Cancellation Period:</h6>
              Users have the right to cancel their subscription at any time,
              subject to the terms outlined in this policy.
            </li>
            <li style={{ fontSize: "1rem" }}>
              <h6>2.2 Cancellation Procedure:</h6>
              To cancel your subscription, you must follow the cancellation
              procedure specified in the System or contact our customer support
              team at [support@yourcompany.com].
            </li>
            <li style={{ fontSize: "1rem" }}>
              <h6>2.3 Cancellation Within Trial Period:</h6>
              If you cancel your subscription within the specified trial period,
              you may be eligible for a full refund, as outlined in the Refund
              Policy.
            </li>
          </ul>
          

        
        
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpunchcancellationpolicy;
