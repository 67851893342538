import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { MdDownload } from "react-icons/md";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Admininvestment = () => {
  const [activeTab, setActiveTab] = useState("tab2");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="p-3">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
            style={activeTab === "tab2" ? { border: "none" } : {}}
            onClick={() => handleTabClick("tab2")}
          >
            IT Declaration
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
            style={activeTab === "tab1" ? { border: "" } : {}}
            onClick={() => handleTabClick("tab1")}
          >
            Proof Of Investment
          </button>
        </li>
      </ul>
      <div className="tab-content p-3">
        {activeTab === "tab1" && <Proofofinvestment />}
        {activeTab === "tab2" && <Itdeclaration />}
      </div>
    </div>
  );
};

const Proofofinvestment = () => {
  return <div>Proof of Investment content goes here.</div>;
};

const Itdeclaration = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [itdeclaredata, setItdeclaredata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);

  const GetFirmEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setEmployees(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getitdeclaration = async () => {
    if (!selectedEmployee) return;
    setLoading(true);
    setItdeclaredata(null);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        empid: selectedEmployee.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetDeclarationList`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setItdeclaredata(data.data);
        setApiFailed(false);
      } else {
        setApiFailed(true);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);

  useEffect(() => {
    Getitdeclaration();
  }, [selectedEmployee]);

  const handleDropdownChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  return (
    <div>
      <h4>IT Declaration submission for employees</h4>
      <p>
        You can either allow the employee to submit IT Declaration through the
        portal or submit it on their behalf.
      </p>
      <p>Select Employee to submit IT Declaration on behalf of the employee</p>
      <div className="col-4 m-0 p-0">
        <Select onChange={handleDropdownChange} options={employees} />
      </div>

      {selectedEmployee && loading && <p>Loading...</p>}

      {selectedEmployee && apiFailed && (
        <Link to="/retailer/Admin_Itdeclarationform">
          <div className="mt-3">
            <Button
              style={{
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                borderColor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
                border: "1px solid",
              }}
              onClick={() => {
                sessionStorage.setItem(
                  "selectedEmployee",
                  JSON.stringify(selectedEmployee)
                );
              }}
            >
              Open IT Declaration for {selectedEmployee.label}
            </Button>
          </div>
        </Link>
      )}

      {selectedEmployee && !loading && !apiFailed && itdeclaredata && (
        <>
          {itdeclaredata.length === 0 ? (
            <p>No IT declaration data available for {selectedEmployee.label}</p>
          ) : (
            <Detailsdeclare itdeclaredata={itdeclaredata} />
          )}
        </>
      )}

      {!selectedEmployee && (
        <p>Select an employee to view IT declaration details.</p>
      )}
    </div>
  );
};

const formatPeriod = (fromDate, toDate) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);
  const options = { year: "numeric", month: "long" };

  const formattedFrom = from.toLocaleDateString("en-US", options);
  const formattedTo = to.toLocaleDateString("en-US", options);

  return `${formattedFrom} - ${formattedTo}`;
};

const Detailsdeclare = ({ itdeclaredata }) => {
  const [expanded, setExpanded] = useState(false);
  const [showPdfTable, setShowPdfTable] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleDownloadPDF = () => {
    setShowPdfTable(true); 
    setTimeout(() => {
      const input = document.getElementById("pdf-table");
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("12BBForm.pdf");
        setShowPdfTable(false); 
      });
    }, 500); 
  };
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const firstItem = itdeclaredata[0];
  const taxRegime =
    firstItem?.tax_type === "old" ? "Old Tax Regime" : "New Tax Regime";
  const status = firstItem?.approve_staus === "N" ? "Draft" : "Declared";
  const statusStyles = {
    backgroundColor: status === "Draft" ? "#f0ad4e" : "#5cb85c",
    color: "white",
    padding: "5px 10px",
    borderRadius: "3px",
  };

  return (
    <>
      <div className="mt-4">
        <h6>
          IT Declaration <span style={statusStyles}>{status}</span>
        </h6>
      </div>
      <div className="col">
        Tax Regime: {taxRegime}
        <span style={{ color: "blue" }}>(Change)</span>
      </div>
      <hr className="mb-0" />
      <div className="row">
        <h6 className="col">Particulars</h6>
        <h6 className="col d-flex justify-content-end">Declared Amount</h6>
      </div>
      <hr className="mt-1" />

      {/* Rented House Data */}
      {firstItem?.rented_house === "Y" &&
        firstItem?.rented_housedata.map((rent, index) => (
          <div key={index}>
            <h6 className="col">House Rent Paid details</h6>
            <div className="row mx-2">
              <div className="col p-0 m-0">
                <p>{formatPeriod(rent.from_date, rent.to_date)}</p>
                <p className="m-0 p-0">{rent.address}</p>
                <p className="m-0 p-0">
                  {rent.landlord_name} - {rent.urbanization}
                </p>
                <p className="m-0 p-0">{rent.landlord_pan}</p>
              </div>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{rent.total_amount}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* Home Loan Data */}
      {firstItem?.home_loan === "Y" &&
        firstItem?.homeloandata.map((loan, index) => (
          <div key={index}>
            <h6 className="col">Home Loan Details</h6>
            <div className="row mx-2">
              <p className="col p-0 m-0">Principal Paid on Home Loan</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{loan.principal_home_loan}
              </p>
            </div>
            <div className="row mx-2">
              <p className="col p-0 m-0">Interest Paid on Home Loan</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{loan.intrest_paid_loan}
              </p>
            </div>
            <div className="row mx-2">
              <p className="col p-0 m-0">Lender PAN</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                {loan.lender_pan}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* Let Out Property Data */}
      {firstItem?.letoutprop === "Y" &&
        firstItem?.letoutpropdata.map((prop, index) => (
          <div key={index}>
            <h6 className="col">Net Income / Loss from Let Out Property</h6>
            <div className="row mx-2">
              <p
                className="col p-0 m-0"
                onClick={toggleExpand}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Net Income / Loss from Let Out Property - 1
              </p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{prop.netincomeloss}
              </p>
              {expanded && (
                <div className="col-12 ml-4">
                  <div className="row mx-2">
                    <p className="col p-0 m-0">Annual Rent Received</p>
                    <p className="col p-0 m-0 d-flex justify-content-end">
                      ₹{prop.annual_rent}
                    </p>
                  </div>
                  <div className="row mx-2">
                    <p className="col p-0 m-0">Municipal Taxes Paid</p>
                    <p className="col p-0 m-0 d-flex justify-content-end">
                      ₹{prop.municipal_tax}
                    </p>
                  </div>
                  <div className="row mx-2">
                    <p className="col p-0 m-0">Net Annual Value</p>
                    <p className="col p-0 m-0 d-flex justify-content-end">
                      ₹{prop.net_value}
                    </p>
                  </div>
                  <div className="row mx-2">
                    <p className="col p-0 m-0">
                      Standard Deduction (@ 30% of Net Annual Value)
                    </p>
                    <p className="col p-0 m-0 d-flex justify-content-end">
                      ₹{prop.standard_deduction}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* 80C Investments */}
      {firstItem?.eightyc_investment === "Y" &&
        firstItem?.eightyc_investmentdata.map((invest, index) => (
          <div key={index}>
            <h6 className="col">80C Investment (Max Limit: ₹1,50,000.00)</h6>
            <div className="row mx-2">
              <p className="col p-0 m-0">{invest.invest_name}</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{invest.invest_amount}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* 80D Investments */}
      {firstItem?.eightyd_investment === "Y" &&
        firstItem?.eightyd_investmentdata.map((invest, index) => (
          <div key={index}>
            <h6 className="col">80D Investment (Max Limit: ₹1,00,000.00)</h6>
            <div className="row mx-2">
              <p className="col p-0 m-0">{invest.eightyd_investname}</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{invest.eightyd_investamount}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* Other Investments */}
      {firstItem?.other_investment === "Y" &&
        firstItem?.other_investmentdata.map((invest, index) => (
          <div key={index}>
            <h6 className="col">Other Investment & Exemptions</h6>
            <div className="row mx-2">
              <p className="col p-0 m-0">{invest.other_investname}</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{invest.otheramount}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}

      {/* Other Income source */}
      {firstItem?.other_income_source === "Y" &&
        firstItem?.other_income_sourcedata.map((income, index) => (
          <div key={index}>
            <h6 className="col">Other Income</h6>
            <div className="row mx-2">
              <p className="col p-0 m-0">Income from other sources</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{income.other_source}
              </p>
            </div>
            <div className="row mx-2">
              <p className="col p-0 m-0">
                Interest Earned from Savings Deposit
              </p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{income.saving_deposit}
              </p>
            </div>
            <div className="row mx-2">
              <p className="col p-0 m-0">Interest Earned from Fixed Deposit</p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{income.fixed_deposit}
              </p>
            </div>
            <div className="row mx-2">
              <p className="col p-0 m-0">
                Interest Earned from National Savings Certificates
              </p>
              <p className="col p-0 m-0 d-flex justify-content-end">
                ₹{income.national_certificate}
              </p>
            </div>
            <hr className="m-1" />
          </div>
        ))}
      {/* Previous Data */}
      {firstItem?.previousdata?.map((prev, index) => (
        <div key={index}>
          <h6 className="col">Previous Data</h6>

          <div className="row mx-2">
            <p className="col p-0 m-0">Income After Exemptions:</p>
            <p className="col p-0 m-0 d-flex justify-content-end">
              {" "}
              ₹{prev.incomeafter}
            </p>
          </div>
          <div className="row mx-2">
            <p className="col p-0 m-0">Income Tax:</p>
            <p className="col p-0 m-0 d-flex justify-content-end">
              {" "}
              ₹{prev.income_tax}
            </p>
          </div>
          <div className="row mx-2">
            <p className="col p-0 m-0">Professional Tax:</p>
            <p className="col p-0 m-0 d-flex justify-content-end">
              {" "}
              ₹{prev.prof_tax}
            </p>
          </div>
          <div className="row mx-2">
            <p className="col p-0 m-0">Employee Provident Fund:</p>
            <p className="col p-0 m-0 d-flex justify-content-end">
              {" "}
              ₹{prev.previous_epf}
            </p>
          </div>
          <div className="row mx-2">
            <p className="col p-0 m-0">Leave Encashment Exemptions:</p>
            <p className="col p-0 m-0 d-flex justify-content-end">
              {" "}
              ₹{prev.leave_encash}
            </p>
          </div>

          <hr className="m-1" />
        </div>
      ))}

    
      <p
        className="m-0 p-2 triangle-paragraph"
        style={{ background: "#EEF7FF" }}
      >
        Form 12BB - Employee Investment Declaration{" "}
        <span>
        <Link>
        <span onClick={handleDownloadPDF} style={{color:"ActiveBorder"}}>
            <MdDownload  />
            Download
          </span></Link> 
        </span>
      </p>
      <div style={{ display: showPdfTable ? "block" : "none" }} id="pdf-table">
        <div className="p-5">
          <h6 className="text-center">FORM 12BB</h6>
          <p className="text-center m-0 ">(See Rule 26C)</p>
          <p className="text-center m-0">
            <b>
              Statement showing particulars of claims by an employee for
              deduction of tax under section 192
            </b>
          </p>
          <div className="row">
            <div className="col">
              <p className="m-0">
                <b>Name:</b>
              </p>
              <p className="m-0">
                <b>Address:</b>
              </p>
                <p className="m-0">
                <b>Permanent Account Number:</b>
              </p>
                <p className="m-0">
                <b>Financial Year:</b>
              </p>
            </div>
            <div className="col">
                <p className="m-0">{firstItem.emp_name}</p>
                <p className="m-0">{firstItem.emp_address}</p>
                <p className="m-0">{firstItem.pan}</p>
                <p className="m-0">2024-25</p>
            </div>
          </div>

          {/* Details of claims and evidence */}
          <table className="table">
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="text-center bg-light"
                  style={{ color: "#000" }}
                >
                  Details of claims and evidence thereof
                </th>
              </tr>
              <tr>
                <th
                  className="text-center"
                  style={{ color: "#000", background: "#fff" }}
                >
                  Serial Number
                </th>
                <th
                  className="text-center"
                  style={{ color: "#000", background: "#fff" }}
                >
                  Nature of Claim
                </th>
                <th
                  className="text-center"
                  style={{ color: "#000", background: "#fff" }}
                >
                  Amount
                </th>
                <th
                  className="text-center"
                  style={{ color: "#000", background: "#fff" }}
                >
                  Evidence / Particulars
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Rented House Data */}
              {firstItem?.rented_house === "Y" &&
                firstItem?.rented_housedata.map((rental, index) => (
                  <tr key={`rental_${index}`}>
                    <td>{index + 1}</td>
                    <td>
                      House Rent Allowance
                      <ol type="i">
                        <li> Rent paid to the landlord </li>
                        <li>Name of the landlord</li>
                        <li>Address of the landlord</li>
                        <li>Permanent Account Number of the landlord</li>
                        <p>
                          <b>
                            Note: Permanent Account Number shall be
                            <br />
                            furnished if the aggregate rent paid during
                            <br />
                            the previous year exceeds one lakh rupees
                          </b>
                        </p>
                      </ol>
                    </td>
                    <td>
                      <ul style={{ listStyle: "none" }}>
                        <li className="mt-3"> ₹{rental.total_amount}</li>
                        <li>{rental.landlord_name}</li>
                        <li> {rental.address}</li>
                        <li> {rental.landlord_pan}</li>
                      </ul>
                    </td>
                    <td></td>
                  </tr>
                ))}
              {firstItem?.rented_house !== "Y" && (
                <tr>
                  <td colSpan={4}>Rented House - NA</td>
                </tr>
              )}

              {/* Home Loan Data */}
              {firstItem?.home_loan === "Y" &&
                firstItem?.homeloandata.map((loan, index) => (
                  <tr key={`loan_${index}`}>
                    <td>{firstItem.rented_house.length + 1 + index}</td>
                    <td>
                      Deduction of interest on borrowing:
                      <ol type="i">
                        <li> Interest payable/paid to the lender</li>
                        <li>Name of the lender</li>
                        <li> Address of the lender</li>
                        <li>Permanent Account Number of the lender</li>
                      </ol>
                      <ol type="a">
                        <li> Financial Institutions(if available)</li>
                        <li> Employer(if available)</li>
                        <li> Others</li>
                      </ol>
                    </td>
                    <td>
                      <ul style={{ listStyle: "none" }}>
                        <li className="mt-3"> ₹{loan.intrest_paid_loan}</li>
                        <li>{loan.lender_name}</li>
                        <li>NA</li>
                        <li> {loan.lender_pan}</li>
                      </ul>
                    </td>
                    <td></td>
                  </tr>
                ))}
              {firstItem?.home_loan !== "Y" && (
                <tr>
                  <td colSpan={4}>Home Loan - NA</td>
                </tr>
              )}
              {/* Investments under 80C */}
              {firstItem?.eightyc_investment === "Y" &&
                firstItem?.eightyc_investmentdata.map((investment, index) => (
                  <tr key={`investment_80c_${index}`}>
                    <td>
                      {firstItem.rented_house.length +
                        1 +
                        firstItem.homeloandata.length +
                        index}
                    </td>
                    <td>
                      Deduction under Chapter VI-A
                      <ol type="A">
                        <li>
                          Section 80C,80CCC and 80CCD
                          <ol type="i">
                            <li>
                              Section 80C
                              <ol type="a">
                                <li>
                                  {" "}
                                  Interest on National Savings Certificates
                                </li>
                                <li>
                                  Repayment of Home loan (Principal amount)
                                </li>
                              </ol>
                            </li>
                            <li> Section 80CCC</li>
                            <li>Section 80CCD(1)</li>
                          </ol>
                        </li>
                        <li>
                          Other sections (e.g. 80E, 80G, 80TTA, etc.) under
                          Chapter VI-A
                          <ol type="i">
                            <li>
                              Section 80D
                              <ol type="a">
                                <li>
                                  {" "}
                                  Medi Claim Policy for self, spouse, children
                                  for senior citizen - 80D
                                </li>
                              </ol>
                            </li>
                            <li>
                              Section 80TTA
                              <ol type="a">
                                <li> Interest from Savings Account</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </td>
                    <td>₹</td>
                    <td></td>
                  </tr>
                ))}
              {firstItem?.eightyc_investment !== "Y" && (
                <tr>
                  <td colSpan={4}>Investment - 80C - NA</td>
                </tr>
              )}

              {/* letout property */}
              {firstItem?.letoutprop === "Y" &&
                firstItem?.letoutpropdata.map((letoutProp, index) => (
                  <tr key={`investment_80d_${index}`}>
                    <td>
                      {firstItem.rented_house.length +
                        1 +
                        firstItem.homeloandata.length +
                        firstItem.eightyc_investmentdata.length +
                        index}
                    </td>
                    <td>
                      Deduction under Section 24
                      <ol style={{ listStyle: "none" }}>
                        <li>Income/Loss from Let Out Property - 1</li>
                      </ol>
                    </td>
                    <td>
                      <li className="mt-3" style={{ listStyle: "none" }}>
                        ₹{letoutProp.netincomeloss}
                      </li>
                    </td>
                    <td></td>
                  </tr>
                ))}
              {firstItem?.eightyd_investment !== "Y" && (
                <tr>
                  <td colSpan={4}>Investment - 80D - NA</td>
                </tr>
              )}
              {firstItem?.other_income_source === "Y" &&
                firstItem?.other_income_sourcedata.map((income, index) => (
                  <tr key={`other_income_${index}`}>
                    <td>
                      {firstItem.rented_house.length +
                        1 +
                        firstItem.homeloandata.length +
                        firstItem.eightyc_investmentdata.length +
                        firstItem.letoutpropdata.length +
                        index}
                    </td>
                    <td>
                      Other Income Source
                      <ol style={{ listStyle: "none" }}>
                        <li>Interest Earned from Savings Deposit</li>
                        <li>Interest Earned from Fixed Deposit</li>
                        <li>Income from other sources</li>
                        <li>
                          Interest Earned from National Savings Certificates
                        </li>
                      </ol>
                    </td>
                    <td>
                      {" "}
                      <ul style={{ listStyle: "none" }}>
                        <li className="mt-3"> ₹{income.saving_deposit}</li>
                        <li>₹{income.fixed_deposit}</li>
                        <li> ₹{income.other_source}</li>
                        <li> ₹{income.national_certificate}</li>
                      </ul>
                    </td>
                    <td></td>
                  </tr>
                ))}
              {firstItem?.other_income_source !== "Y" && (
                <tr>
                  <td colSpan={4}>Other Income Source - NA</td>
                </tr>
              )}
            </tbody>
            <tr>
              <td
                colSpan={4}
                className="text-center bg-light"
                style={{ color: "#000" }}
              >
                Verification
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                className="text-center bg-light"
                style={{ color: "#000" }}
              >
                <p className="text-center">
                  I <b>{firstItem.emp_name} </b> of {firstItem.emp_address} do
                  hereby certify tdat the information given above is complete
                  and correct.
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Place:</td>
              <td colSpan={2}>Date: {getCurrentDate()}</td>
            </tr>

            <tr>
              <td colSpan={4}>Signature of the employee:</td>
            </tr>
            <tr>
              <td colSpan={4}>
                Designation: <b>{firstItem.designation}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                Full Name: <b>{firstItem.emp_name}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default Admininvestment;
