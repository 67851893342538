import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { AiOutlineClose } from "react-icons/ai";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";

const Admindeduction = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    deductionType: "",
    Associatededuction: "",
    nameInPayslip: "",
    includeempcontrictc: false,
    prorate: false,
    active: false,
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleChange = (selectedOptionOrEvent, actionMeta) => {
    if (actionMeta && actionMeta.action === 'select-option') {
      setFormData((prevData) => ({
        ...prevData,
        [actionMeta.name]: selectedOptionOrEvent.value
      }));
    } else if (selectedOptionOrEvent && selectedOptionOrEvent.target) {
      const { name, value, type, checked } = selectedOptionOrEvent.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.deductionType) errors.deductionType = "*Please select Deduction Plan";
    if (!formData.Associatededuction) errors.Associatededuction = "*Please select Associated Deduction";
    if (!formData.nameInPayslip) errors.nameInPayslip = "*Please enter Name in Payslip";
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
   
  };

  return (
    <>
      <div className="mt-md-3 mt-2">
        <div className="row mx-3">
          <h5 className="col">Add Deduction</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Salary_components">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr />
        <Form className="mx-2 col-md-8 col-12" onSubmit={validateForm}>
          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Label>Deduction Plan</Form.Label>
            <Select
              options={[
                { value: "test1", label: "Test 1" },
                { value: "test2", label: "Test 2" },
              ]}
              onChange={(option, action) => handleChange(option, { ...action, name: 'deductionType' })}
              value={formData.deductionType ? { value: formData.deductionType, label: formData.deductionType } : null}
            />
            {errors.deductionType && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.deductionType}</div>
            )}
          </Form.Group>
          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Label>Associate this deduction with</Form.Label>
            <Select
              options={[
                { value: "test1", label: "Test 1" },
                { value: "test2", label: "Test 2" },
              ]}
              onChange={(option, action) => handleChange(option, { ...action, name: 'Associatededuction' })}
              value={formData.Associatededuction ? { value: formData.Associatededuction, label: formData.Associatededuction } : null}
            />
            {errors.Associatededuction && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.Associatededuction}</div>
            )}
          </Form.Group>
          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Label>Name in Payslip*</Form.Label>
            <Form.Control
              type="text"
              name="nameInPayslip"
              value={formData.nameInPayslip}
              onChange={handleChange}
            />
            {errors.nameInPayslip && (
              <div className="text-danger" style={{fontSize:".9rem"}}>{errors.nameInPayslip}</div>
            )}
          </Form.Group>
          <Form.Group className="col-12 mb-3">
            <Form.Check
              type="checkbox"
              label={<><span style={{ color: "#000" }}>Include employer's contribution in the CTC</span></>}
              name="includeempcontrictc"
              checked={formData.includeempcontrictc}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="col-12 mb-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  <span style={{ color: "#000" }}>Calculate on pro-rata basis</span>
                  <br />
                  <span>Pay will be adjusted based on employee working days.</span>
                </>
              }
              name="prorate"
              checked={formData.prorate}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="col-md-6 col-10 mb-3">
            <Form.Check
              type="checkbox"
              label={<><span style={{ color: "#000" }}>Mark this as Active</span></>}
              name="active"
              checked={formData.active}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="col-10 mx-2 px-1 " style={{ background: "#FFF5E3" }}>
            <p className="p-1" style={{ fontSize: ".8rem" }}>
              <b>Note:</b>Once you associate this deduction with an employee,
              you will only be able to edit the Name in Payslip. The change will
              be reflected in both new and existing employees.
            </p>
          </div>
          <hr />
          <div className="px-2 mb-4">
            <Button variant="primary" type="submit">
              Save
            </Button>
            <Button style={{ background: "#fff", border: "none" }} disabled></Button>
            <Link to="/retailer/Salary_components">
              <Button className="btn-light mx-2" style={{ border: "1px solid" }}>
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Admindeduction;
