import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { MdOutlineReadMore } from "react-icons/md";
import { format } from "date-fns";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaEye,
  FaRegArrowAltCircleRight,
  FaUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { billpunchbasicauth } from "../../utils/Credentials";
const EmployeeDashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState();
  const [attendanceSummary, setAttendanceSummary] = useState({});

  const [wfhdashboard, setwfhdashboard] = useState([]);
  const [profile, setprofile] = useState([]);
  const [policy, setpolicy] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const today = format(new Date(), "PPPP");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const { password, username } = billpunchbasicauth;
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const Attendancedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          empid: user.userid,
          date: currentDate,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/CheckPresentAbsentStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setAttendanceStatus(data.result);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getleavesdata = async (month) => {
    console.log("Getleavesdata called with month:", month); 
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          month: month,
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetLeaveBalance`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setAttendanceSummary(data.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getwfhdata = async (month) => {
    console.log("Getleavesdata called with month:", month); // Debug log
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWorkFromHomeDashboard`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setwfhdashboard(data.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const TodaysAttendanceCard = () => (
    <div className="card p-2 " style={{ background: "#FFFBDA" }}>
      <h5>Today's Date</h5>
      <p>{today}</p>
      <div className="row m-0 p-0">
        {" "}
        <h5 className="">Attendance Status</h5>
        <div className="col d-flex justify-content-end">
          <p
            className="p-1"
            style={{
              backgroundColor:
                attendanceStatus === "Present"
                  ? "rgba(43, 193, 85, 0.1)"
                  : "rgba(255, 0, 0, 0.1)",
              color: attendanceStatus === "Present" ? "#2bc155" : "#ff0000",
              border:
                attendanceStatus === "Present"
                  ? "1px solid #2bc155"
                  : "1px solid #ff0000",
              borderRadius: ".3rem",
              fontSize: "1rem",
              // width: "5rem",
            }}
          >
            {attendanceStatus}
          </p>
        </div>
      </div>
    </div>
  );
 
  const months = [
    { name: "January", value: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    console.log("Selected month:", selectedMonth); // Debug log
    setSelectedMonth(selectedMonth);
    Getleavesdata(selectedMonth);
  };
  const Policy = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmPolicy`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setpolicy(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const openPdfInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };
  useEffect(() => {
    Policy();
    getLocation();
    Attendancedata();
    Getleavesdata(new Date().getMonth() + 1);
    Getwfhdata();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = async (position) => {
    console.log("Received position data:", position);
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    console.log("Latitude:", position.coords.latitude);
    console.log("Longitude:", position.coords.longitude);

    setTransactionLoaderState(true);

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
      );
      const data = await response.json();
      console.log("Geocoding Response:", data);
      if (data && data.display_name) {
        setPlaceName(data.display_name);
      } else {
        console.log("No display name found in geocoding response");
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const getEmployeeList = async (selectedDepartment) => {
    console.log("getEmployeeList Department Parameter:", selectedDepartment);

    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeProfileDetails`,
        requestOptions
      );
      const apiData = await res.json();
      if (apiData.status === "0") {
        setprofile(apiData.data[0]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    getEmployeeList();
  }, []);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImageUrl, setOverlayImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setOverlayImageUrl(imageUrl);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayImageUrl(null);
  };

  return (
    <div className="p-3">
      {showOverlay && overlayImageUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={overlayImageUrl}
            alt="Profile Overlay"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "10px",
            }}
          />
        </div>
      )}
      <div className="row">
        <div className="col-md-5 col">
          <div
            className="card m-0 p-3"
            style={{
              background:
                "linear-gradient(to bottom right, cornflowerblue 50%, lightblue 50%)",
            }}
          >
            <div className="row">
              <div className="col-8 d-flex flex-column justify-content-center align-items-start">
                <h4 className="mt-1" style={{ color: "#fff" }}>
                  Welcome! {user.username}
                </h4>
                {/* <p style={{ color: "#fff", fontSize: "1rem" }}>Developer</p> */}
                <Link
                  to="/retailer/ViewEmployeeprofile"
                  className=" p-2 d-flex justify-content-center align-items-center"
                  style={{
                    height: "2rem",
                    borderRadius: ".3rem",
                    background: "#fff",
                    color: "#000",
                  }}
                  // href="/retailer/ViewEmployeeprofile"
                >
                  View Profile
                </Link>
              </div>
              <div className="col d-flex justify-content-end align-items-center">
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  {profile.image_url ? (
                    <img
                      src={profile.image_url}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onClick={() => handleImageClick(profile.image_url)}
                    />
                  ) : (
                    <FaUser style={{ color: "teal", fontSize: "1rem" }} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <WeeklyCalendar />
          <div className="card p-3 mt-3 shadow-sm mb-2" style={{background:"#FFFBDA"}}>
  <h5 className="text-center mb-0">Work From Home Status</h5>
    <div className="row">
      <div className="col text-center">
        <p className="text-muted mb-1">Tasks Pending To Review</p>
        <h3 className="text-primary  mb-0">{wfhdashboard.pending_review||"NA"}</h3>
      </div>
      <div className="col text-center">
        <p className="text-muted mb-1">WFH Approval Pending </p>
        <h3 className="text-success  mb-0">{wfhdashboard.pending_wfh||"NA"}</h3>
      </div>
    </div>
</div>

        </div>
        <div className="col-md-4 col-12">
          <div className="card p-2 ">
            <div className="row">
              <h6 className="col-7">Attendance & Leaves</h6>
              <div className="col d-flex justify-content-end">
                <select
                  id="month"
                  className="form-control"
                  value={selectedMonth}
                  onChange={handleMonthChange} style={{height:"2rem"}}
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="p-0 m-0"> <b>Final Paid Leave</b></p>
              </div>
              <div className="col d-flex justify-content-center">
                <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                  {attendanceSummary.final_pl_balance || 0}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="p-0 m-0"> <b>Final Sick Leave</b></p>
              </div>
              <div className="col d-flex justify-content-center">
                <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                  {attendanceSummary.final_sl_balance || 0}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h6 className="m-0 p-0 text-center" style={{ color: "green" }}>
                  {attendanceSummary.present_days || 0}
                </h6>
                <p className="m-0 p-0 text-center">
                  Total Present
                </p>
              </div>
              <div className="col">
                <h6 className="m-0 p-0 text-center" style={{ color: "red" }}>
                  {attendanceSummary.absent || 0}
                </h6>
                <p className="m-0 p-0 text-center">
                  Total Absent
                </p>
              </div>
            
            </div>
          
            <hr className="m-1" />
            <Link to={`/retailer/Employee_Balance_Leave`}>
              <h6 className="px-1">
                View More <FaRegArrowAltCircleRight />
              </h6>
            </Link>
          </div>

          <div className="card p-1 mt-2" style={{ background: "#8DECB4" }}>
            <TodaysAttendanceCard />
          </div>
          <div className="card p-1 mt-2" style={{ background: "#8DECB4" }}>
            <div className="card p-1 " style={{ background: "#FFFBDA" }}>
              <Link to={`/retailer/Salaryslip`}>
                <div className="row m-0 p-0">
                  <h5 className="col-8 d-flex align-items-center">
                    Check Your Salary
                  </h5>

                  <div className="col">
                    {" "}
                    <img
                      src={require("../../../assets/images/arrow1.png")}
                      style={{
                        width: "5rem",
                        height: "4rem",
                      }}
                    />{" "}
                  </div>
                </div>{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12 ">
          <div
            className="card p-3 custom-scrollbaractivity"
            style={{ background: "#fff", height: "50vh", overflowY: "auto" }}
          >
            {" "}
            <div className="row m-0 p-0">
              {" "}
              <h6 className="col-8 m-0 p-0">Company Policy</h6>
              <div className="col m-0 p-0 d-flex justify-content-end ">
                {" "}
                <Link to={`/retailer/Privacy_policy`}>
                  <p>
                    More <MdOutlineReadMore />
                  </p>
                </Link>
              </div>{" "}
            </div>
            {policy.map((policyItem, index) => {
              const backgroundColors = ["#C6EBC5", "#FCE8F7", "#C4E4FF"];

              return (
                <div
                  key={index}
                  className="card p-2 mt-2 "
                  style={{
                    border: "1px dotted grey",
                    background:
                      backgroundColors[index % backgroundColors.length],
                  }}
                >
                  <div
                    className=""
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "2rem",
                        height: "2rem",
                        borderRadius: "50%",
                        backgroundColor: "#41B06E",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "1rem",
                        color: "#fff",
                      }}
                    >
                      {policyItem.policy_name.charAt(0)}
                    </div>
                    {policyItem.policy_name}
                  </div>
                  <div className="row mx-1">
                    <p className="col-10 m-0 p-0 ">
                      Updated on:{" "}
                      {new Date(policyItem.policy_indate).toLocaleDateString()}
                    </p>
                    <div className="col m-0 p-0 d-flex justify-content-end align-items-center">
                      {policyItem.image_url ? (
                        <FaEye
                          onClick={() => openPdfInNewTab(policyItem.image_url)}
                        />
                      ) : (
                        <FaEye
                          style={{ color: "gray", cursor: "not-allowed" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div className="card mt-2 p-3" style={{ background: "#E3FEF7" }}>
              <div className="row m-0 p-0">
                {" "}
                <h6 className="col p-0">
                  User
                  <br /> Location
                </h6>
                <img
                  className="col p-0"
                  src={require("../../../assets/images/location.gif")}
                  style={{
                    width: "4rem",
                    height: "4rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
              </div>

              {latitude && longitude ? (
                <>
                  {/* <p>Latitude: {latitude}</p>
                  <p>Longitude: {longitude}</p> */}
                  <p>
                    <strong>{placeName}</strong>{" "}
                  </p>
                </>
              ) : (
                <p>Location not available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;

const WeeklyCalendar = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const [startDate, setStartDate] = useState(currentDate);

  const startOfWeek = new Date(startDate);
  startOfWeek.setDate(startOfWeek.getDate() - currentDay);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const goToPreviousWeek = () => {
    const previousWeekStartDate = new Date(startDate);
    previousWeekStartDate.setDate(previousWeekStartDate.getDate() - 7);
    setStartDate(previousWeekStartDate);
  };
  const goToNextWeek = () => {
    const nextWeekStartDate = new Date(startDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
    setStartDate(nextWeekStartDate);
  };

  return (
    <div className="weekly-calendar mt-2 p-2">
      <div className="row ">
        <div className="col">
          <h4>Weekly calendar</h4>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <button
            className="weekly-calendar-nav-button"
            onClick={goToPreviousWeek}
          >
            <FaArrowAltCircleLeft style={{ fontSize: "1.5rem" }} />
          </button>
          <button className="weekly-calendar-nav-button" onClick={goToNextWeek}>
            <FaArrowAltCircleRight style={{ fontSize: "1.5rem" }} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className=" col weekly-calendar-days">
          {weekdays.map((day, index) => {
            const dayDate = new Date(startOfWeek);
            dayDate.setDate(dayDate.getDate() + index);
            return (
              <div
                key={index}
                className={`weekly-calendar-day ${
                  index === currentDay ? "weekly-calendar-current-day" : ""
                }`}
              >
                <div className="weekly-calendar-day-label">{day}</div>
                <div className="weekly-calendar-day-number">
                  {dayDate.getDate()}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col p-0">
        <div className="card p-2" style={{ background: "#e3f2fd" }}>
          <h5>Office Hours</h5>
          <p className="text-muted mb-2">Daily office and lunch timings</p>
          <hr className="p-0 mb-1 mt-1" />
          <p>
            <strong>Office Hours:</strong> 9:30 AM - 6:30 PM
          </p>
          <p>
            <strong>Lunch Break:</strong> 1:00 PM - 1:45 PM
          </p>
        </div>
      </div>
    </div>
  );
};
