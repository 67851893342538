import React, { useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
const ReviewCard = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="service_laptop">
        <h2
          className="mt-5 d-flex justify-content-center"
          style={{ color: "black", fontWeight: "500" }}
        >
          Their Success,
          <span style={{ color: "#0085ff" }}> Our Pride</span>{" "}
        </h2>

        <p
          className="px-3 d-flex justify-content-center"
          style={{ color: "#252525" , fontSize:"1.1rem"}}
        >
          Empowering Your Team's Success: Redefining HR Management with
          Cutting-Edge Innovation and Technology
        </p>
        <div className="row ">
          <div className="col-1"></div>
          <div className="col ">
            <div className="row p-0 d-flex justify-content-center">
              <div className="col-md-4 large-screen-col mt-md-0 mt-2 col-12 ">
                <div
                  className=" card m-0 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    It's easy to use, speeds up payroll, tracks taxes and
                    follows all the rules. The reports and tools help us manage
                    work and time off. It integrates well with our finances. The
                    support team is super helpful. In short, it makes paying our
                    team easy, and ensures we're doing things right.
                  </p>
                  <h6 className="mx-3 "> Aman</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </div>
              <div className=" col-md-4 large-screen-col mt-md-0 mt-2 col-12">
                <div
                  className=" card m-0 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    The reports are easy to understand and help us manage our
                    workforce effectively. Plus, it syncs seamlessly with our
                    finances. Whenever we need help, the support team is
                    friendly and quick to respond. In short, it's made paying
                    our team a simple task.
                  </p>
                  <h6 className="mx-3 "> Divya</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </div>
              <div className="col-md-4 large-screen-col mt-md-0 mt-2 col-12 ">
                <div
                  className=" card m-0 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    It's user-friendly and speeds up our payroll process
                    significantly. The reports are clear and easy to interpret,
                    helping us stay on top of our workforce management.
                    Integration with our financial tools is seamless, making our
                    operations smoother.
                  </p>
                  <h6 className="mx-3 "> Aditya</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
</div>
        <div className="" style={{ background: "" }}>
          <div
            className="dot-click-slider-container service_phone"
            style={{ background: "#" }}
          >
             <h6
          className="mt-5 d-flex justify-content-center"
        >
          Their Success,Our Pride
        </h6>

        <p
          className="px-3 text-center d-flex justify-content-center"
          style={{ fontSize: ".9rem", color: "#252525" }}
        >
          Empowering Your Team's Success: Redefining HR Management with
          Cutting-Edge Innovation and Technology
        </p>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
            >
              <Carousel.Item>
                <div
                  className=" card m-3 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    It's easy to use, speeds up payroll, tracks taxes and
                    follows all the rules. The reports and tools help us manage
                    work and time off. It integrates well with our finances. The
                    support team is super helpful. In short, it makes paying our
                    team easy, and ensures we're doing things right.
                  </p>
                  <h6 className="mx-3 "> Aman</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className=" card m-3 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    The reports are easy to understand and help us manage our
                    workforce effectively. Plus, it syncs seamlessly with our
                    finances. Whenever we need help, the support team is
                    friendly and quick to respond. In short, it's made paying
                    our team a simple task.
                  </p>
                  <h6 className="mx-3 "> Divya</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className=" card m-3 p-2 "
                  style={{ border: "1px solid #C8DEFF", borderRadius: ".5rem" }}
                >
                  <div className="row m-0 p-0 ">
                    <p className="col">IT &SaaS</p>
                    <div className="col d-flex justify-content-end">
                      {" "}
                      <img
                       alt="Billpunch"
                        style={{ height: "1rem" }}
                        src={require("../../../assets/images/salartstar.png")}
                      />
                    </div>
                  </div>
                  <p className="mx-3 p-0">
                    {" "}
                    It's user-friendly and speeds up our payroll process
                    significantly. The reports are clear and easy to interpret,
                    helping us stay on top of our workforce management.
                    Integration with our financial tools is seamless, making our
                    operations smoother.
                  </p>
                  <h6 className="mx-3 "> Aditya</h6>
                  <p className="mx-3 "> HR Head </p>
                </div>
              </Carousel.Item>
            </Carousel>
             <div className="dot-indicators ">
        {[...Array(3).keys()].map((i) => (
          <span
            key={i}
            onClick={() => setIndex(i)}
            className={i === index ? "active" : ""}
          ></span>
        ))}
      </div>
          </div>
        </div>
     
    </>
  );
};

export default ReviewCard;
