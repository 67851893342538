import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import swal from "sweetalert";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const Approval = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Leave Approval
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
              style={activeTab === "tab2" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab2")}
            >
              WFH Approval
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === "tab1" && <LeaveApprovalAdmin />}
          {activeTab === "tab2" && <Wfhapproval />}
        </div>
      </div>
    </>
  );
};

const LeaveApprovalAdmin = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Employeeid, setEmployeeid] = useState(-1);

  const { password, username } = billpunchbasicauth;
  const GetAllEmployeeLeave = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllEmployeeLeave`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
        if (data1.data.length > 0) {
          setEmployeeid(data.data[0].id);
        }
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const ApproveRejectLeave = async (leaveId, status,empId, employeeName) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: empId,
        name: employeeName,
        leaveid: leaveId,
        status: status,
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ApproveRejectLeave`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        GetAllEmployeeLeave();
      } else {
        swal("Success", data.result, "success");
        GetAllEmployeeLeave();
      }
      setTransactionLoaderState(false);
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
    setTransactionLoaderState(false);
  };
  const handleApprove = async (id) => {
    try {
      const selectedLeave = data1.find((item) => item.pk_leave_id === id);
      if (selectedLeave) {
        await ApproveRejectLeave(
          selectedLeave.pk_leave_id,
          "A",
          selectedLeave.empid,
          selectedLeave.employee_name
        );
      }
    } catch (error) {
      console.error("Error approving leave:", error);
    }
  };
  
  const handleReject = async (id) => {
    try {
      const selectedLeave = data1.find((item) => item.pk_leave_id === id);
      if (selectedLeave) {
        await ApproveRejectLeave(
          selectedLeave.pk_leave_id,
          "R",
          selectedLeave.empid,
          selectedLeave.employee_name
        );
      }
    } catch (error) {
      console.error("Error rejecting leave:", error);
    }
  };

  useEffect(() => {
    GetAllEmployeeLeave();
  }, [Employeeid]);
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <>
      <div
      // className="d-flex justify-content-center align-items-center"
      // style={{ minHeight: "30px" }}
      >
        <table
          className="PMS-collection-table "
          style={{
            textAlign: "center",
            overflow: "hidden",
            borderRadius: ".4rem",
          }}
        >
          <thead>
            <tr>
              <th style={{ color: "#0074D9" }}>Name</th>
              <th style={{ color: "#0074D9" }}>Category</th>
              <th style={{ color: "#0074D9" }}>Reason</th>
              <th style={{ color: "#0074D9" }}>From</th>
              <th style={{ color: "#0074D9" }}> ToDate </th>
              <th style={{ color: "#0074D9" }}>Status</th>
              <th style={{ color: "#0074D9" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data1.length === 0 ? (
              <tr>
                <td
                  className="px-2"
                  colSpan="7"
                  style={{ textAlign: "center" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              data1.map((item) => (
                <tr key={item.empid}>
                  <td>{item.employee_name}</td>
                  <td>{item.leave_category}</td>
                  <td>{item.leave_description}</td>
                  <td>{new Date(item.form_date).toLocaleDateString()}</td>
                  <td>{new Date(item.to_date).toLocaleDateString()}</td>
                  <td>
                    {item.status === "R" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Rejected
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "A" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Approved
                      </span>
                    )}
                  </td>

                  <td>
                    {accessData &&
                      accessData.map(
                        (module) =>
                          module.data &&
                          module.data.map((role) =>
                            role.role_name === "Approve/Reject Leave" ? (
                              <span key={role.pk_role_id}>
                                {role.access === "Y" ? (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_leave_id)
                                          }
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_leave_id)
                                          }
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.status === "P" ? (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleApprove(item.pk_leave_id)
                                          }
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                          }}
                                          onClick={() =>
                                            handleReject(item.pk_leave_id)
                                          }
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-around align-items-center">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-success d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Approve
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "2rem",
                                            borderRadius: ".3rem",
                                            filter: "grayscale(100%)",
                                          }}
                                          disabled
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}
                              </span>
                            ) : null
                          )
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
const Wfhapproval = () => {
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);

  const [data1, setData1] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const { password, username } = billpunchbasicauth;
  const getwfh = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: "string",
        firmid: user.firm_id,
        reporting_id: user.userid,
        type: "2",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetWfhV1`,
        requestOptions
      );
      const data1 = await res.json();
      if (data1.status === "0") {
        setData1(data1.data);
      }
      console.log(data, "hiiiii");
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleAction = async (item, action) => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          homeid: item.pk_id,
          status: action,
          empid:item.empid,
          name:item.fullname
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ApproveWorkFromHomeV1`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        getwfh();
      } else {
        swal("Success", data.result, "success");
        getwfh();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getwfh();
  }, []);
  return (
    <>
      <div className="">
     

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "30px" }}
        >
          <table
            className="PMS-collection-table m-1"
            style={{
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  {" "}
                  Employee{" "}
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  {" "}
                  Reason{" "}
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Date
                </th>

                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Reporting Person
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Login status
                </th>
                <th
                  style={{
                    color: "#0074D9",
                    border: "1px solid #cccccc",
                    padding: "8px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {data1.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", border: "none" }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                data1.map((item, index) => (
                  <tr key={item.empid}>
                    <td
                      style={{
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.fullname}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reason}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.date}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.reporting_name}
                    </td>

                    <td style={{ padding: "8px" }}>
                      {item.status === "R" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Rejected
                        </span>
                      )}
                      {item.status === "N" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: "#f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "Y" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Approved
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        color: "#0074D9",
                        border: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      {item.login_status === "N" &&
                      item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Yet to Login
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "N" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Logged In
                        </Badge>
                      ) : item.login_status === "Y" &&
                        item.logout_status === "Y" ? (
                        <Badge
                          className="btn-sm"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                            fontSize: ".8rem",
                          }}
                        >
                          Approval Pending
                        </Badge>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid #cccccc",
                        padding: "8px",
                      }}
                    >
                      <Button
                        variant="success"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleAction(item, "Y")}
                        disabled={item.status === "Y" || item.status === "R"}
                      >
                        Approve
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleAction(item, "R")}
                        disabled={item.status === "Y" || item.status === "R"}
                      >
                        Reject
                      </Button>
                      &nbsp;&nbsp;
                      <Link to="/retailer/Review_Wfh_Work">
                        <FaArrowRight
                          variant="info"
                          onClick={() => {
                            sessionStorage.setItem(
                              "WFHwork",
                              JSON.stringify(item)
                            );
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Approval;
