import React from "react";
import swal2 from "@sweetalert/with-react";
import swal from "sweetalert";

export const balanceEnquirySuccessPopup = (
  aadhar,
  balance,
  bank,
  tranxtID,
  mobile,
  callback
) => {
  swal2(
    <React.Fragment>
      <table
        style={{
          width: "100%",
          height: "40vh",
          overflowY: "scroll",
          border: "0px solid #979797",
        }}
      >
        <tr
          style={{
            width: "100%",
            border: "0px solid #000",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "center",
              border: "0px solid #979797",
            }}
          >
            <img
              style={{ height: "2rem", width: "2rem" }}
              src={require("../../assets/images/success_tick.jpg")}
            />
            <span>Transaction Success</span>
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
              width: "50%",
              background: "none",
            }}
          >
            <div onClick={callback} className="btn fw-bold btn-sm btn-success">
              <img
                src={require("./../../assets/images/download.png")}
                style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
              />
              {"Print Receipt"}
            </div>
          </td>
        </tr>

        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {" Aadhar Number :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {`XXXXXXXX${aadhar.substr(8, 12)}`}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Transaction ID :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {tranxtID}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Bank Name :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {bank}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {" Mobile Number :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {mobile}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Balance :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {`Rs ${balance}`}
          </td>
        </tr>
      </table>
    </React.Fragment>
  );
};

export const cashWithdrawlSuccessPopup = (
  aadhar,
  amount,
  bank,
  tranxtID,
  mobile,
  balance,
  callback,
  callback2
) => {
  console.table({
    aadhar,
    amount,
    bank,
    tranxtID,
    mobile,
    balance,
    callback,
    callback2,
  });
  console.log(callback2);
  swal2(
    <React.Fragment>
      <div>
        <table
          style={{
            width: "100%",
            height: "40vh",
            overflowY: "scroll",
            border: "0px solid #979797",
          }}
        >
          <tr
            style={{
              width: "100%",
              border: "0px solid #000",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "center",
                border: "0px solid #979797",
              }}
            >
              <img
                style={{ height: "2rem", width: "2rem" }}
                src={require("../../assets/images/success_tick.jpg")}
              />
              <span>Transaction Success</span>
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
                width: "50%",
                background: "none",
              }}
            >
              <div
                onClick={callback}
                className="btn fw-bold btn-sm btn-success"
              >
                <img
                  src={require("./../../assets/images/download.png")}
                  style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
                />
                {"Print Receipt"}
              </div>
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Aadhar Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`XXXXXXXX${aadhar.substr(8, 12)}`}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction ID :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {tranxtID}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Bank Name :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {bank}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Mobile Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {mobile}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction Amount :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`Rs ${amount}`}
            </td>
          </tr>
        </table>

        <button className="btn btn-success" onClick={callback2}>
          {" "}
          Mini Statement
        </button>
      </div>
    </React.Fragment>
  );
};

export const dmtTransactionSuccessPopup2 = (tranxtID, amount, message) => {
  swal2(
    <React.Fragment>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <img
            style={{ height: "3rem", width: "auto" }}
            src={require("../../assets/images/success_tick.jpg")}
          />
          <h3>{message}</h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>{`Transaction ID: ${tranxtID}`}</h4>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>{`Transaction Amount: ${amount}`}</h4>
        </div>
      </div>
    </React.Fragment>
  );
};

export const dmtTransactionSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Transaction Amount ${amount}`, "success");
};

export const dmtTransactionFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Transaction Amount ${amount}`, "error");
};

export const customerKYCSuccessPopup = (tranxtID, data, message) => {
  swal(message, data, "success");
};

export const customerKYCFailedPopup = (tranxtID, data, message) => {
  swal(message, data, "error");
};

export const bumppyKYCWarning = (callback) => {
  swal({
    title: "Warning",
    text: "Do KYC First for Enable DMT",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      callback();
    }
  });
};

export const bbpsRechargeSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Recharge Amount ${amount}`, "success");
};

export const bbpsRechargeFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Recharge Amount ${amount}`, "error");
};

export const bbpsBillSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Bill Amount ${amount}`, "success");
};

export const bbpsBillFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Bill Amount ${amount}`, "error");
};

export const successPopup = (message, message2) => {
  swal(message, message2, "success");
};

export const failedPopup = (message, message2) => {
  swal(message, message2, "error");
};

export const dmtSuccessPopup = (amount, message, callback) => {
  swal2(
    <React.Fragment>
      <div>
        <div className="row">
          {/* <img
            style={{ height: "2rem", width: "2rem" }}
            src={require("../../assets/images/success_tick.jpg")}
          /> */}
          {/* <span>Transaction Success</span> */}
          <div
            onClick={callback}
            className="btn ml-auto fw-bold btn-sm btn-success"
          >
            <img
              src={require("./../../assets/images/download.png")}
              style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
            />
            {"Print Receipt"}
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <img
            className="mx-auto p-3"
            src={require("../../assets/images/thanks.png")}
            style={{ height: "auto", width: "40%" }}
          />
        </div>

        <div className="row text-center justify-content-center">
          <span style={{ fontSize: "1.5rem" }}> {`${message}`}</span>
        </div>
        <div className="row text-center justify-content-center">
          <span style={{ fontSize: "1.5rem" }}>
            {" "}
            {`Transaction Amount : Rs ${amount}`}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
