// import React, { useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
// import { Form, Modal } from "react-bootstrap";
// import * as XLSX from "xlsx";
// import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
// import swal from "sweetalert";
// import { GlobalContext } from "../../Context/GlobalState";

// const ExcelUpdate = ({ isOpen, closeModal }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [expenseCategories, setExpenseCategories] = useState([]);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [excelData, setExcelData] = useState(null);

//   const [isDragging, setIsDragging] = useState(false);
//   const [formData, setFormData] = useState({
//     category: "",
//     paymentAccount: "",
//     paymentDate: "",
//     paymentMethod: "",
//     refNo: "",
//     tags: "",
//     Amount: "",
//   });
//   const [errors, setErrors] = useState({
//     category: "",
//     paymentAccount: "",
//     paymentDate: "",
//     paymentMethod: "",
//     refNo: "",
//     tags: "",
//     Amount: "",
//   });
//   const { setTransactionLoaderState } = useContext(GlobalContext);

//   const validate = (e) => {
//     e.preventDefault();
//     console.log(" validate function");
//     console.log(formData);
//     let errors = {};
//     if (formData.category.trim() === "") {
//       errors.category = "*Please select category";
//     }
//     if (formData.paymentMethod.trim() === "") {
//       errors.paymentMethod = "*Please select payment method";
//     }
//     if (formData.paymentDate.trim() === "") {
//       errors.paymentDate = "*Please select payment Date";
//     }
//     if (formData.Amount.trim() === "") {
//       errors.Amount = "*Please enter amount";
//     }
//     if (formData.tags.trim() === "") {
//       errors.tags = "*Please enter description";
//     }
//     setErrors(errors);
//     console.log(errors);
//     if (Object.keys(errors).length === 0) {
//       handleSubmit();
//     }
//   };
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value.trim(),
//     }));
//   };

//   // upload excel
//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };
//   const handleUploadExcel = async () => {
//     if (selectedFile) {
//         const allowedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
//         if (!allowedFileTypes.includes(selectedFile.type)) {
//           // Display sweet alert for unsupported file type
//           swal("Error", "Only Excel files (.xlsx, .xls) are supported.", "error");
//           return;
//         }
//       try {
//         const reader = new FileReader();
//         reader.readAsArrayBuffer(selectedFile);

//         reader.onload = (e) => {
//           const data = new Uint8Array(e.target.result);
//           const workbook = XLSX.read(data, { type: "array" });
//           const sheetName = workbook.SheetNames[0];
//           const worksheet = workbook.Sheets[sheetName];

//           // Extract header names from the first row
//           const headers = [];
//           const range = XLSX.utils.decode_range(worksheet["!ref"]);
//           for (let C = range.s.c; C <= range.e.c; ++C) {
//             const address = XLSX.utils.encode_cell({ r: 0, c: C });
//             headers.push(worksheet[address].w);
//           }

//           // Convert the data to JSON using the extracted header names
//           const convertedData = XLSX.utils.sheet_to_json(worksheet, {
//             header: headers,
//             range: 1, // start from the second row to skip the header
//             raw: false, // preserve the original data types
//           });

//           console.log("Formatted JSON Data:", convertedData);

//           setExcelData(convertedData);
//         };
//       } catch (error) {
//         console.error("Error uploading Excel file:", error);
//       }
//     } else {
//       swal("Error", "Please select an Excel file for upload.", "warning");
//     }
//   };
//   const handleDownloadExcel = () => {
//     const wb = XLSX.utils.book_new();

//     const wsData = [formData];
//     const ws = XLSX.utils.json_to_sheet(wsData);

//     XLSX.utils.book_append_sheet(wb, ws, "ExpenseData");

//     XLSX.writeFile(wb, "expense_data.xlsx");
//   };
//   const fetchExpenseCategories = async () => {
//     setIsLoading(true);
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({});

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       const res = await fetch(
//         `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllFirmExpenseCategories
//             `,
//         requestOptions
//       );
//       const data = await res.json();
//       if (data.status === "0") {
//         setExpenseCategories(data.data);
//       }
//     } catch (error) {
//       console.log("error", error);
//     }
//     setIsLoading(false);
//   };

//   const handleSubmit = async (e) => {
//     try {
//       const requestBody = {
//         e_name: formData.category,
//         e_designation: formData.paymentAccount,
//         e_category: formData.category,
//         amount: formData.Amount,
//         e_description: formData.tags,
//         e_status: "string",
//         bill_image: "string",
//       };

//       var myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify(requestBody);

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       const res = await fetch(
//         `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/addEmployeeExpense`,
//         requestOptions
//       );

//       const data = await res.json();
//       if (data.status === "1") {
//         swal("Error", data.msg, "error");
//         closeModal();
//       } else {
//         swal("Success", data.msg, "success");
//         closeModal();
//       }
//       console.log("Form submission completed.");
//     } catch (error) {
//       console.log("Error:", error);
//     }
//   };

//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     setIsDragging(true);
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//     const files = e.dataTransfer.files;
//     if (files.length > 0) {
//       setSelectedFile(files[0]);
//     }
//   };

//   useEffect(() => {
//     fetchExpenseCategories();
//   }, []);

//   return (
//     <>
//       <div className="m-5">
//       <Form className="mx-md-3 mx-sm-2" onDrop={handleDrop} onDragOver={handleDragOver}>
//           <div className="row ">
//             {" "}
//             <Form.Group className="col">
//               <Form.Label>category:</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="category"
//                 value={formData.category}
//                 onChange={handleInputChange}
//               >
//                 <option value="">Category</option>
//                 {isLoading ? (
//                   <option>Loading...</option>
//                 ) : (
//                   expenseCategories.map((category) => (
//                     <option
//                       key={category.pk_expense_category_id}
//                       value={category.category_name}
//                     >
//                       {category.category_name}
//                     </option>
//                   ))
//                 )}
//               </Form.Control>

//               {errors.category && (
//                 <p className="text-danger">{errors.category}</p>
//               )}
//             </Form.Group>
//             <Form.Group className="col">
//               <Form.Label>Payment Account:</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="paymentAccount"
//                 value={formData.paymentAccount}
//                 onChange={handleInputChange}
//                 placeholder="Customer prepayments"
//               />
//               {errors.paymentAccount && (
//                 <p className="text-danger">{errors.paymentAccount}</p>
//               )}
//             </Form.Group>
//             <Form.Group className="col">
//               <Form.Label> Amount:</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="Amount"
//                 value={formData.Amount}
//                 onChange={handleInputChange}
//                 placeholder="Amount"
//               />
//               {errors.Amount && <p className="text-danger">{errors.Amount}</p>}
//             </Form.Group>
//           </div>
//           <div className="row">
//             {" "}
//             <Form.Group className="col ">
//               <Form.Label>Payment Date:</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="paymentDate"
//                 value={formData.paymentDate}
//                 onChange={handleInputChange}
//                 placeholder=""
//               />
//               {errors.paymentDate && (
//                 <p className="text-danger">{errors.paymentDate}</p>
//               )}
//             </Form.Group>
//             <Form.Group className="col">
//               <Form.Label>Payment Method:</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="paymentMethod"
//                 value={formData.paymentMethod}
//                 onChange={handleInputChange}
//                 placeholder="What did you pay with?"
//               />
//               {errors.paymentMethod && (
//                 <p className="text-danger">{errors.paymentMethod}</p>
//               )}
//             </Form.Group>
//             <Form.Group className="col">
//               <Form.Label>Reference Number:</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="refNo"
//                 value={formData.refNo}
//                 onChange={handleInputChange}
//                 placeholder=""
//               />
//               {errors.refNo && <p className="text-danger">{errors.refNo}</p>}
//             </Form.Group>
//           </div>

//           <div
//             className={`file-drop-area ${isDragging ? "dragging" : ""}`}
//             onDragEnter={handleDragEnter}
//             onDragLeave={handleDragLeave}
//           >
//             <div className="d-flex flex-column align-items-center">
//               <label className="file-input-label">
//                 Select Excel or drop Excel here
//                 <input
//                   type="file"
//                   accept=".xlsx, .xls"
//                   onChange={handleFileChange}
//                   className="file-input"
//                 />
//               </label>
//               {selectedFile ? (
//                 <p>
//                   File: {selectedFile.name}{" "}
//                   <span
//                     onClick={() => setSelectedFile(null)}
//                     style={{ cursor: "pointer", color: "red" }}
//                   >
//                     (Remove)
//                   </span>
//                 </p>
//               ) : (
//                 <p>No file chosen</p>
//               )}
//             </div>
//           </div>
//           <div className="row mt-md-4 ">

//             <div>
//               <button
//                 className="btn btn-primary d-flex align-items-center"
//                 type="button"
//                 style={{
//                   height: "2rem",
//                   borderRadius: ".3rem",
//                   fontSize: ".8rem",
//                 }}
//                 onClick={handleUploadExcel}
//               >
//                 Upload Excel
//               </button>
//             </div>

//             <div className="col">
//               {" "}
//               <button
//                 className="btn btn-primary d-flex align-items-center px-4"
//                 type="button"
//                 style={{
//                   height: "2rem",
//                   borderRadius: ".3rem",
//                   fontSize: ".8rem",
//                 }}
//                 onClick={handleDownloadExcel}
//               >
//                 Download Excel
//               </button>
//             </div>
//           </div>
//           <Form.Group className="col-9">
//             <Form.Label>Description</Form.Label>
//             <Form.Control
//               type="text"
//               name="tags"
//               value={formData.tags}
//               onChange={handleInputChange}
//               placeholder="Description"
//             />
//             {errors.tags && <p className="text-danger">{errors.tags}</p>}
//           </Form.Group>

//           <div className="row ">
//             <div className="col-3">
//               <button
//                 className="btn btn-success d-flex align-items-center"
//                 type="button"
//                 style={{
//                   height: "2rem",
//                   borderRadius: ".3rem",
//                   fontSize: ".8rem",
//                 }}
//                 onClick={validate}
//               >
//                 Submit
//               </button>
//             </div>
//           </div>
//         </Form>

//         {excelData && (
//           <div>
//             <h3>Formatted JSON Data:</h3>
//             <pre>{JSON.stringify(excelData, null, 2)}</pre>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };
// export default ExcelUpdate;

import React, { useState } from "react";
import * as XLSX from "xlsx";
import swal from "sweetalert";

const ExcelUpdate = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelData, setExcelData] = useState(null);

  const [isDragging, setIsDragging] = useState(false);

  // upload excel
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUploadExcel = async () => {
    if (selectedFile) {
      const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (!allowedFileTypes.includes(selectedFile.type)) {
        swal("Error", "Only Excel files (.xlsx, .xls) are supported.", "error");
        return;
      }
      swal({
        title: "Confirmation",
        text: "Are you sure you want to proceed?",
        icon: "warning",
        buttons: ["Cancel", "Proceed"],
        dangerMode: true,
      }).then((proceed) => {
        if (proceed) {
          try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);

            reader.onload = (e) => {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];

              const headers = [];
              const range = XLSX.utils.decode_range(worksheet["!ref"]);
              for (let C = range.s.c; C <= range.e.c; ++C) {
                const address = XLSX.utils.encode_cell({ r: 0, c: C });
                headers.push(worksheet[address].w);
              }
              const convertedData = XLSX.utils.sheet_to_json(worksheet, {
                header: headers,
                range: 1,
                raw: false,
              });

              // Prompt for additional information
              swal({
                title: "Input Required",
                text: "Please enter additional information:",
                content: "input",
                button: {
                  text: "OK",
                  closeModal: false,
                },
              }).then((inputValue) => {
                if (inputValue) {
                  // Process the additional input as needed
                  console.log("Additional Input:", inputValue);
                  setExcelData(convertedData);
                  swal("Success", "Data processed successfully.", "success");
                } else {
                  swal("Error", "Additional input is required.", "error");
                }
              });
            };
          } catch (error) {
            console.error("Error uploading Excel file:", error);
          }
        } else {
          swal("Info", "Operation canceled.", "info");
        }
      });
    } else {
      swal("Error", "Please select an Excel file for upload.", "warning");
    }
  };

  // const handleDownloadExcel = () => {
  //   const wb = XLSX.utils.book_new();

  //   const wsData = [formData];
  //   const ws = XLSX.utils.json_to_sheet(wsData);

  //   XLSX.utils.book_append_sheet(wb, ws, "ExpenseData");

  //   XLSX.writeFile(wb, "expense_data.xlsx");
  // };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <>
      <div className="m-5">
      
        <div
          className="mx-md-3 mx-sm-2"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div
            className={`file-drop-area ${isDragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <div className="d-flex flex-column align-items-center">
              <label className="file-input-label">
                Select Excel or drop Excel here
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  className="file-input"
                />
              </label>
              {selectedFile ? (
                <p>
                  File: {selectedFile.name}{" "}
                  <span
                    onClick={() => setSelectedFile(null)}
                    style={{ cursor: "pointer", color: "red" }}
                  >
                    (Remove)
                  </span>
                </p>
              ) : (
                <p>No file chosen</p>
              )}
            </div>
          </div>
          <div className="row mt-md-4 ">
            <div>
              <button
                className="btn btn-primary d-flex align-items-center"
                type="button"
                style={{
                  height: "2rem",
                  borderRadius: ".3rem",
                  fontSize: ".8rem",
                }}
                onClick={handleUploadExcel}
              >
                Upload Excel
              </button>
            </div>

            {/* <div className="col">
              {" "}
              <button
                className="btn btn-primary d-flex align-items-center px-4"
                type="button"
                style={{
                  height: "2rem",
                  borderRadius: ".3rem",
                  fontSize: ".8rem",
                }}
                onClick={handleDownloadExcel}
              >
                Download Excel
              </button>
            </div> */}
          </div>
        </div>

        {excelData && (
          <div>
            <h3>Formatted JSON Data:</h3>
            <pre>{JSON.stringify(excelData, null, 2)}</pre>
          </div>
        )}
      </div>
    </>
  );
};
export default ExcelUpdate;
