import React, { useContext, useState, useEffect } from "react";
import { Form, Button, InputGroup, Card } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import swal from "sweetalert";
import { FaUser } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import { IoOpenOutline } from "react-icons/io5";

const Superadminsetting = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <h4 className="pt-2 pb-2 px-2">Settings</h4>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link p-2 ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              <b>Domain's</b>
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === "tab1" && <Domain />}
        </div>
      </div>
    </>
  );
};

const Domain = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [domainName, setDomainName] = useState("");
  const [description, setDescription] = useState("");
  const [branch, setBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [subdomains, setSubdomains] = useState([]);
  const [domains, setDomains] = useState([]);


  const fetchBranches = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({});

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllFirmsSuperAdmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        const mappedBranches = data.data.map((branch) => ({
          value: branch.firm_name,
          label: branch.firm_name,
        }));
        setBranches(mappedBranches);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const fetchdomains = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({});

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Subdomain/GetDomains
        `,
        requestOptions
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setDomains(data); 
      } else {
        swal("Error", "Invalid response format from server", "error");
      }
  

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const fetchsubdomains = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({});

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Subdomain/GetSubDomains
        `,
        requestOptions
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setSubdomains(data); 
      } else {
        swal("Error", "Invalid response format from server", "error");
      }
  

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleCreateDomain = async () => {
    setTransactionLoaderState(true);
    try {
      const subdomain = domainName.toLowerCase().replace(/\s+/g, "");
      const rootDomain = "billpunch.com";
      const documentRoot = `/public_html/${subdomain}`;
      const requestBody = {
        subdomain: subdomain,
        rootDomain: rootDomain,
        documentRoot: documentRoot,
      };
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Subdomain/create`,
        requestOptions
      );
      const data = await response.json();
      if (data.status == "0") {
        swal("Success!", `Domain '${domainName}' ${data.result} created successfully. Domain will start working after 24hrs.`, "success");
        fetchBranches();
        fetchsubdomains();
    } else {
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const renderSubdomains = () => {
    return subdomains.map((subdomain, index) => (
      <tr key={index}>
      <td>
        <a href={`http://${subdomain}`} target="_blank" rel="noopener noreferrer">
          {subdomain} <IoOpenOutline  style={{color:"blue"}}/>
        </a>
      </td>

      </tr>
    ));
  };
  const renderdomains = () => {
    return domains.map((domain, index) => (
      <tr key={index}>
      <td>
        <a href={`http://${domain}`} target="_blank" rel="noopener noreferrer">
          {domain} <IoOpenOutline  style={{color:"blue"}}/>
        </a> 
      </td>

      </tr>
    ));
  };
  useEffect(() => {
    fetchBranches();
    fetchsubdomains();
    fetchdomains();
  }, []);
  return (
    <>
      <div className=" d-flex justify-content-center">
        <Card className="col-md-5 col-10 shadow-sm p-3 mb-4 bg-white rounded ">
          <p className="p-1 mb-0 bg-primary text-white text-center rounded">
            <h5 className="m-0">Create Personalized Domain for firms</h5>
          </p>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Select Branch</Form.Label>
                <Select
                  options={branches}
                  value={branches.filter((opt) => opt.value === branch)}
                  onChange={(selectedOption) => setBranch(selectedOption.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Domain Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaUser />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter domain name"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <CiEdit />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Button
                variant="primary"
                onClick={handleCreateDomain}
                className="w-40 btn-sm"
              >
                Create Domain
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>

      <Card className="shadow-sm p-3 mb-5 bg-white rounded">
     
        <Card.Body className="p-0">
        <div className="table-responsive">
            <table className="table">
              <thead
                // style={{
                //   position: "sticky",
                //   top: 0,
                //   zIndex: 1,
                //   background: "#fff",
                // }}
              >
                <tr
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    textAlign: "center",
                  }}
                >
               
                  <th
                    style={{
                      borderBottom: "1px solid #f2f4f2",
                      color: "#000",
                      padding: "8px",
                      textAlign: "left",
                      background: "#E1F7F5",
                    }}
                  >
                  Main Domain Name
                  </th>
              
                </tr>
              </thead>
              <tbody>
              {renderdomains()}

              </tbody>
            </table>
          </div>
          <p
          className="p-1 mb-2 text-black text-center rounded "
          style={{ background: "#EEF7FF" }}
        >
          <h5>Existing Sub Domains</h5>
        </p>
          <div className="table-responsive">
            <table className="table">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    textAlign: "center",
                  }}
                >
               
                  <th
                    style={{
                      borderBottom: "1px solid #f2f4f2",
                      color: "#000",
                      padding: "8px",
                      textAlign: "left",
                      background: "#E1F7F5",
                    }}
                  >
                  Sub Domain Name
                  </th>
              
                </tr>
              </thead>
              <tbody>
              {renderSubdomains()}

              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Superadminsetting;


