import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";

const Adminearning = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [formData, setFormData] = useState({
    earningType: "",
    earningName: "",
    nameInPayslip: "",
    calculationType: "Flat Amount",
    amount: "",
    includeEmployerContribution: false,
    partOfSalaryStructure: false,
    taxableEarning: false,
    proRataBasis: false,
    epfContribution: false,
    epfCondition: "",
    esiContribution: false,
    showInPayslip: false,
    flexibleBenefitPlan: false,
  });
  const [errors, setErrors] = useState({});

  const validateForm = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.earningType) errors.earningType = "*Please select Earning Type";
    if (formData.earningType) {
      if (!formData.earningName) errors.earningName = "*Please enter Earning Name";
      if (!formData.nameInPayslip) errors.nameInPayslip = "*Please enter Name in Payslip";
      if (!formData.amount && formData.calculationType === "Flat Amount")
        errors.amount = "*Please enter Amount";
      if (!formData.percentage && formData.calculationType === "Percentage of CTC")
        errors.percentage = "*Please enter Percentage";
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      handleSubmit();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      earningType: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      const { calculationType, amount, percentage } = formData;

      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmid: user.firm_id,
        earning_type: formData.earningType,
        earning_name: formData.earningName,
        name_in_playslip: formData.nameInPayslip,
        calculation_type: calculationType,
        percentage: calculationType === "Flat Amount" ? amount : percentage,
        active: formData.includeEmployerContribution ? "Y" : "N",
        part_of_salary_struct: formData.partOfSalaryStructure ? "Y" : "N",
        taxable_earning: formData.taxableEarning ? "Y" : "N",
        pro_rata_basis: formData.proRataBasis ? "Y" : "N",
        flxible_benifit_plan: formData.flexibleBenefitPlan ? "Y" : "N",
        epf_contri: formData.epfContribution ? "Y" : "N",
        esi_contri: formData.esiContribution ? "Y" : "N",
        show_in_playslip: formData.showInPayslip ? "Y" : "N",
        type: "Earning",
        flex_amount: calculationType === "Flat Amount" ? amount : percentage,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddSalaryComponents`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        history.push("/retailer/Salary_components");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const earningTypes = [
    { value: "Basic", label: "Basic" },
    { value: "House Allowance", label: "House Allowance" },
    { value: "Dearness Allowance", label: "Dearness Allowance" },
    { value: "Conveyance Allowance", label: "Conveyance Allowance" },
  ];

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .triangle-paragraph::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0; /* Creates a right-facing triangle */
        border-color: transparent #EEF7FF transparent transparent;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const isFlexibleBenefitPlanEligible = 
    formData.earningType === "House Allowance" || 
    formData.earningType === "Conveyance Allowance";
  
  const [Earningdata, setEarningdata] = useState([]);

  const Getearningsalarycomponent = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        salary_id: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSalaryComponents`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEarningdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getearningsalarycomponent();
  }, []);

  return (
    <>
      <div className=" mt-md-3 mt-2 ">
        <div className="row  mx-3 ">
          <h5 className="col ">New Earning</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Salary_components">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr className="m-1" />
        <Form className="px-md-4 px-2 pt-md-3 pt-2" onSubmit={validateForm}>
          <div className="row">
            <Form.Group className="col-5" controlId="earningType">
              <Form.Label style={{ color: "#000" }}>Earning Type</Form.Label>
              <Select
                options={earningTypes}
                name="earningType"
                value={earningTypes.find(
                  (option) => option.value === formData.earningType
                )}
                onChange={handleSelectChange}
                placeholder="Select Earning Type"
              />
              {errors.earningType && (
                <div className="text-danger" style={{fontSize:".9rem"}}>{errors.earningType}</div>
              )}
            </Form.Group>
            <div
              className="col d-flex align-items-center"
              style={{ position: "relative" }}
            >
              <p
                className="m-0 p-2 triangle-paragraph"
                style={{ background: "#EEF7FF" }}
              >
                {" "}
                <IoIosInformationCircleOutline /> Fixed amount paid at the end
                of every month.
              </p>
            </div>
          </div>
          {formData.earningType && (
            <>
              <div className="col-10">
                <hr className="m-0" />
              </div>
              <div className="row mt-3">
                <div
                  className="col m-0 p-0"
                  style={{ borderRight: "1px solid #000" }}
                >
                  <Form.Group className="col-9" controlId="earningName">
                    <Form.Label style={{ color: "#000" }}>
                      Earning Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="earningName"
                      value={formData.earningName}
                      onChange={handleInputChange}
                      placeholder="Enter Earning Name"
                    />
                    {errors.earningName && (
                      <div className="text-danger" style={{fontSize:".9rem"}}>{errors.earningName}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="col-9" controlId="nameInPayslip">
                    <Form.Label style={{ color: "#000" }}>
                      Name in Payslip
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="nameInPayslip"
                      value={formData.nameInPayslip}
                      onChange={handleInputChange}
                      placeholder="Enter Name in Payslip"
                    />
                    {errors.nameInPayslip && (
                      <div className="text-danger" style={{fontSize:".9rem"}}>{errors.nameInPayslip}</div>
                    )}
                  </Form.Group>

                  <Form.Group className="col-9" controlId="calculationType">
                    <Form.Label style={{ color: "#000" }}>
                      Calculation Type
                    </Form.Label>
                    <div>
                      <Form.Check
                        type="radio"
                        name="calculationType"
                        value="Flat Amount"
                        checked={formData.calculationType === "Flat Amount"}
                        onChange={handleInputChange}
                        label="Flat Amount"
                      />
                      <Form.Check
                        type="radio"
                        name="calculationType"
                        value="Percentage of CTC"
                        checked={
                          formData.calculationType === "Percentage of CTC"
                        }
                        onChange={handleInputChange}
                        label="Percentage of CTC"
                      />
                    </div>
                  </Form.Group>

                  {formData.calculationType === "Flat Amount" && (
                    <Form.Group className="col-9" controlId="amount">
                      <Form.Label style={{ color: "#000" }}>
                        Enter Amount
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="amount"
                        value={formData.amount}
                        onChange={handleInputChange}
                        placeholder="Enter Amount"
                      />
                      {errors.amount && (
                        <div className="text-danger" style={{fontSize:".9rem"}}>{errors.amount}</div>
                      )}
                    </Form.Group>
                  )}

                  {formData.calculationType === "Percentage of CTC" && (
                    <Form.Group className="col-9" controlId="percentage">
                      <Form.Label style={{ color: "#000" }}>
                        Enter Percentage
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="percentage"
                        value={formData.percentage}
                        onChange={handleInputChange}
                        placeholder="Enter Percentage"
                      />
                      {errors.percentage && (
                        <div className="text-danger" style={{fontSize:".9rem"}}>{errors.percentage}</div>
                      )}
                    </Form.Group>
                  )}
                  <div className="row m-1 p-0">
                    <div className="col-8 form-group">
                      <Form.Check
                        type="checkbox"
                        id="includeEmployerContribution"
                        name="includeEmployerContribution"
                        label={
                          <span style={{ color: "black" }}>
                            Mark this as Active
                          </span>
                        }
                        checked={formData.includeEmployerContribution}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6>Other Configuration</h6>
                  <Form.Group controlId="partOfSalaryStructure">
                    <Form.Check
                      type="checkbox"
                      name="partOfSalaryStructure"
                      label={
                        <span style={{ color: "#000" }}>
                          Make this earning a part of the employee’s salary
                          structure
                        </span>
                      }
                      checked={formData.partOfSalaryStructure}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="taxableEarning">
                    <Form.Check
                      type="checkbox"
                      name="taxableEarning"
                      label={
                        <>
                          <span style={{ color: "#000" }}>
                            This is a taxable earning.
                          </span>
                          <br />
                          <span>
                            {" "}
                            The income tax amount will be divided equally and
                            deducted every month across the financial year.
                          </span>
                        </>
                      }
                      checked={formData.taxableEarning}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="proRataBasis">
                    <Form.Check
                      type="checkbox"
                      name="proRataBasis"
                      label={
                        <>
                          <span style={{ color: "#000" }}>
                            Calculate on pro-rata basis.
                          </span>
                          <br />
                          <span>
                            {" "}
                            Pay will be adjusted based on employee working days
                          </span>
                        </>
                      }
                      checked={formData.proRataBasis}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  {isFlexibleBenefitPlanEligible && (
                    <Form.Group controlId="flexibleBenefitPlan">
                      <Form.Check
                        type="checkbox"
                        name="flexibleBenefitPlan"
                        label={
                         <><span style={{ color: "#000" }}>
                         Include this as a Flexible Benefit Plan component.
                       </span><br/>
                       <span>    FBP allows your employees to personalize their salary structure by choosing how much they want to receive under each FBP component.
                       </span></> 
                        }
                        checked={formData.flexibleBenefitPlan}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId="epfContribution">
                    <Form.Check
                      type="checkbox"
                      name="epfContribution"
                      label={
                        <span style={{ color: "#000" }}>
                          Consider for EPF Contribution
                        </span>
                      }
                      checked={formData.epfContribution}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  {formData.epfContribution && (
                    <div className="ml-4">
                      <Form.Check
                        type="radio"
                        name="epfCondition"
                        value="Always"
                        checked={formData.epfCondition === "Always"}
                        onChange={handleInputChange}
                        label="Always"
                      />
                      <Form.Check
                        type="radio"
                        name="epfCondition"
                        value="LessThan15000"
                        checked={formData.epfCondition === "LessThan15000"}
                        onChange={handleInputChange}
                        label="Only when PF Wage is less than ₹ 15,000"
                      />
                    </div>
                  )}
                  <Form.Group controlId="esiContribution">
                    <Form.Check
                      type="checkbox"
                      name="esiContribution"
                      label={
                        <span style={{ color: "#000" }}>
                          Consider for ESI Contribution
                        </span>
                      }
                      checked={formData.esiContribution}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="showInPayslip">
                    <Form.Check
                      type="checkbox"
                      name="showInPayslip"
                      label={
                        <span style={{ color: "#000" }}>
                          Show this component in payslip
                        </span>
                      }
                      checked={formData.showInPayslip}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="col-10" style={{ background: "#FFF5E3" }}>
                <p className="p-2">
                  <b>Note:</b> Once you associate this component with an
                  employee, you will only be able to edit the Name and
                  Amount/Percentage. The changes you make to Amount/Percentage
                  will apply only to new employees.
                </p>
              </div>
            </>
          )}
          <hr />
          <div className="px-2 mb-4">
            {" "}
            <Button onClick={validateForm} variant="primary" type="submit">
              Save
            </Button>
            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Link to="/retailer/Salary_components">
              <Button
                className="btn-light mx-2"
                style={{ border: "1px solid" }}
              >
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Adminearning;
