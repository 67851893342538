import React, { useContext, useEffect, useState } from "react";
import { format, addDays, isSunday } from "date-fns";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";

import { Doughnut } from "react-chartjs-2";
import swal from "sweetalert";
import { BulkAttendanceHr } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import { FaHome } from "react-icons/fa";
import { Button } from "react-bootstrap";

const AttandancecalendraHR = () => {
  const data = [
    {
      title: "Total Absent",
      count: 25,
      borderColor: "red",
      textColor: "red",
      imageSrc: require("../../../assets/images/pms/salary_report.svg"),
    },
    {
      title: "Total Leave",
      count: 25,
      borderColor: "blue",
      textColor: "blue",
      imageSrc: require("../../../assets/images/pms/salary_report.svg"),
    },
    {
      title: "Total Present",
      count: 25,
      borderColor: "green",
      textColor: "green",
      imageSrc: require("../../../assets/images/pms/salary_report.svg"),
    },
  ];

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const { password, username } = billpunchbasicauth;
  const [showcheckmodal, setshowcheckmodal] = useState(false);
  const [dates, setDates] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState(currentYear.toString());
  const currentMonth = new Date().getMonth() + 1;
  const [months, setMonths] = useState(currentMonth.toString());
  const [department, setDepartment] = useState("");
  const generateMonthDates = (year, month) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }

    return dates;
  };

  useEffect(() => {
    const year = parseInt(years);
    const month = parseInt(months);
    const generatedDates = generateMonthDates(year, month);
    setDates(generatedDates);
  }, [years, months]);

  const generateMonthHeaders = (year, month) => {
    const dates = generateMonthDates(year, month);

    const date = new Date(years, months - 1, 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const datess = dates.length;

    return dates.map((date) => (
      <th key={format(date, "yyyy-MM-dd")}>{format(date, "dd")}</th>
    ));
  };

  useEffect(() => {
    const updatedHeaders = generateMonthHeaders(
      parseInt(years),
      parseInt(months)
    );
    setTableHeaders(updatedHeaders);
  }, [months, years]);

  const [employees, setEmployees] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [attendanceChanges, setAttendanceChanges] = useState([]);

  const handleCheckboxChange = (employeeId, day, isChecked) => {
    const updatedChanges = [...attendanceChanges];
    const updatedEmployees = [...employees];
    const dateStr = format(new Date(day), "yyyy-MM-dd");

    const changeIndex = updatedChanges.findIndex(
      (change) =>
        change.id === employeeId &&
        change.attendance_date === dateStr
    );

    if (isChecked) {
      if (changeIndex === -1) {
        updatedChanges.push({
          id: employeeId,
          firmid: user.firm_id,
          attendance_date: dateStr,
          attendance_status: "Y",
          type: "Attendance",
        });
      } else {
        updatedChanges[changeIndex].attendance_status = "Y";
      }
    } else {
      if (changeIndex === -1) {
        updatedChanges.push({
          id: employeeId,
          firmid: user.firm_id,
          attendance_date: dateStr,
          attendance_status: "N",
          type: "Attendance",
        });
      } else {
        updatedChanges[changeIndex].attendance_status = "N";
      }
    }

    const employeeIndex = updatedEmployees.findIndex(
      (employee) => employee.employee_code === employeeId
    );
    if (employeeIndex !== -1) {
      const dayIndex = updatedEmployees[employeeIndex].data.findIndex(
        (entry) => format(new Date(entry.attendance_date), "yyyy-MM-dd") === dateStr
      );
      if (dayIndex !== -1) {
        updatedEmployees[employeeIndex].data[dayIndex].attendance_status = isChecked ? "Y" : "N";
      } else {
        updatedEmployees[employeeIndex].data.push({
          attendance_date: dateStr,
          attendance_status: isChecked ? "Y" : "N",
        });
      }
    }

    setAttendanceChanges(updatedChanges);
    setEmployees(updatedEmployees);
  };

  const handleMarkAttendance = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = JSON.stringify({ requests: attendanceChanges });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/MarkAttendance`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "0") {
        getAttandancedata();
        setAttendanceChanges([]);
      } else {
        swal("Error marking attendance:", data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Error marking attendance:", error);
      setTransactionLoaderState(false);
    }
  };



  const getAttandancedata = async (employeeIds) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: user.firm_id,
        month: months,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetMarkAttendance`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "0" || data.status == "1") {
        const updatedAttendance = {};

        const employeesList = data.data;
        const employeesListNew = [];
        const dates = generateMonthDates(years, months);
        const datess = dates.length;
        console.log(datess + "meeeee");

        employeesList.forEach((ee) => {
          const datesNew = [];
          for (let i = 1; i <= datess; i++) {
            let found2 = null;
            ee.data.map((entry) => {
              if (new Date(entry.attendance_date).getDate() == i) {
                found2 = entry;
              }
            });
            if (found2) {
              datesNew.push(found2);
            } else {
              let d2 = new Date();
              d2.setDate(i + 1);
              d2.setMonth(months - 1);
              datesNew.push({
                attendance_date: d2,
                attendance_status: "N",
                month: months,
                year: "2023",
              });
            }
          }
          //  console.log(datesNew);
          ee.data = datesNew;
          console.log(ee);
          employeesListNew.push(ee);
        });

        console.log(employeesListNew);
        setAttendance(updatedAttendance);
        setEmployees(data.data);
      } else {
        swal("Error fetching attendance data:", data.msg);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const doughnutData = {
    labels: ["Total Present", "Total Leave", "Total Absent"],
    datasets: [
      {
        data: [33, 33, 33],
        backgroundColor: ["green", "blue", "red"],
      },
    ],
  };

  const doughnutOptions = {
    cutoutPercentage: 70,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: "Employee",
            font: {
              size: "20",
            },
          },
        ],
      },
      datalabels: {
        color: "#fff",
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: "16",
          weight: "bold",
        },
      },
    },
    legend: {
      display: false,
    },
  };

  const openModal = () => {
    setshowcheckmodal(true);
  };

  const closeModal = () => {
    setshowcheckmodal(false);
  };
  useEffect(() => {
    getDepartmentList();
    getAttandancedata();
  }, [months]);

  useEffect(() => {
    if (employees.length > 0) {
    }
  }, [employees]);
  console.log(employees);

  useEffect(() => {
    if (department !== "") {
      const filtered = employees.filter(
        (employee) => employee.employee_department === department
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [department, employees]);

  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <>
      <BulkAttendanceHr
        show={showcheckmodal}
        closeModal={closeModal}
        months={months}
      />

      <div style={{ background: "white" }}>
        <h5 className="d-flex align-items-center pt-3 px-3">
          Attendance Management
        </h5>
        <div className="row m-2">
          {data.map((item, index) => (
            <Card
              key={index}
              borderColor={item.borderColor}
              imageSrc={item.imageSrc}
              title={item.title}
              count={item.count}
              textColor={item.textColor}
            />
          ))}

          <div
            className="col-md-3 col-7 card mr-3 m-0 p-0 "
            style={{ borderRadius: ".4rem", border: "none" }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "100%" }}
            >
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </div>
          </div>
          <div
            className="col-md-2 col p-md-3 card mr-3"
            style={{ border: "none" }}
          >
            <div className="d-flex align-items-center">
              <div
                className="color-box mr-2"
                style={{
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                }}
              ></div>
              <div className="color-name" style={{ fontSize: "1rem" }}>
                Total Absent
              </div>
            </div>

            <div className="d-flex align-items-center mt-2">
              <div
                className="color-box mr-2"
                style={{
                  backgroundColor: "green",
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                }}
              ></div>
              <div className="color-name" style={{ fontSize: "1rem" }}>
                Total Present
              </div>
            </div>

            <div className="d-flex align-items-center mt-2">
              <div
                className="color-box mr-2"
                style={{
                  backgroundColor: "blue",
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                }}
              ></div>
              <div className="color-name" style={{ fontSize: "1rem" }}>
                Total Leave
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-2" style={{ background: "white" }}>
        <div className="row mx-1">
          <div className="col-md-8 col-12 p-0 d-flex align-items-center">
            <div className="px-md-2">
              <select
                value={years}
                style={{
                  width: "7rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                onChange={(e) => setYears(e.target.value)}
              >
                <option value={"2023"}>Year</option>
                <option value={"2023"}>2023</option>
                <option value={"2024"}>2024</option>
                <option value={"2025"}>2025</option>
                <option value={"2026"}>2026</option>
              </select>
            </div>
            <div className="px-md-2">
              <select
                value={months}
                style={{
                  width: "7rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                onChange={(e) => setMonths(e.target.value)}
              >
                <option value={2023}>Month</option>
                <option value={1}>January</option>
                <option value={2}>February</option>
                <option value={3}>March</option>
                <option value={4}>April</option>
                <option value={5}>May</option>
                <option value={6}>June</option>
                <option value={7}>July</option>
                <option value={8}>August</option>
                <option value={9}>September</option>
                <option value={10}>October</option>
                <option value={11}>November</option>
                <option value={12}>December</option>
              </select>
            </div>
            <div className=" px-md-2">
              <select
                style={{
                  width: "9rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </select>
            </div>
            <div className=" px-md-2 ">
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Attendance Check" ? (
                        role.access === "Y" ? (
                          <span key={role.pk_role_id}>
                            <button
                              style={{
                                height: "2rem",
                                borderRadius: ".4rem",
                                border: "1px #0074D9 solid",
                                fontSize: ".9rem",
                                background: "#fff",
                                color: "#0074D9",
                              }}
                              onClick={openModal}
                            >
                              Check Attendance
                            </button>
                          </span>
                        ) : (
                          <span key={role.pk_role_id}>
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-start"
                              style={{
                                color: "#fff",
                                height: "2rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                fontSize: ".9rem",
                                backgroundColor: "gray",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              Check Attendance
                            </button>
                          </span>
                        )
                      ) : null
                    )
                )}
            </div>

            <div
              className="mx-2"
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <div
                className="color-box mr-2"
                style={{
                  backgroundColor: "yellow",
                  width: "20px",
                  height: "20px",
                  borderRadius: "4px",
                }}
              ></div>
              <div
                className="color-name d-flex align-items-center"
                style={{ fontSize: ".8rem" }}
              >
                Sunday
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-end   ">
            <div>
              <Button
                className="btn-sm btn btn-success"
                onClick={handleMarkAttendance}
              >
                Save Attendance
              </Button>
            </div>
          </div>
        </div>

        <div style={{ overflowX: "auto", width: "" }}>
          <div style={{ width: "9rem" }}>
            <table className="m-3">
              <thead>
                <tr>
                  <th>Employee </th>

                  {tableHeaders}
                </tr>
              </thead>
              <tbody>
  {filteredEmployees.map((employee) => (
    <tr key={employee.id}>
      <td style={{ fontSize: ".8rem" }}>{employee.employee_name}</td>
      {dates.map((date, i) => (
        <td
          key={i}
          style={{
            backgroundColor: isSunday(date) ? "#fff8a0" : "white",
            border: "1px solid #ced4da",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {accessData &&
              accessData.map(
                (module) =>
                  module.data &&
                  module.data.map((role) =>
                    role.role_name === "Add Attendance" && role.access === "Y" ? (
                      <span
                        key={role.pk_role_id}
                        style={{ marginLeft: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{ margin: 0, display: 'inline-block' }}
                          checked={employee.data.some(
                            (entry) =>
                              format(
                                new Date(entry.attendance_date),
                                "yyyy-MM-dd"
                              ) === format(date, "yyyy-MM-dd") &&
                              entry.attendance_status === "Y"
                          )}
                          onChange={(e) => {
                            handleCheckboxChange(
                              employee.employee_code,
                              date,
                              e.target.checked
                            );
                          }}
                        />
                      </span>
                    ) : null
                  )
              )}
            {employee.data.some(
              (entry) =>
                format(
                  new Date(entry.attendance_date),
                  "yyyy-MM-dd"
                ) === format(date, "yyyy-MM-dd") &&
                entry.type === "WFH"
            ) && (
              <span
                style={{ marginLeft: "10px", color: "green" }}
              >
                <FaHome />
              </span>
            )}
          </div>
        </td>
      ))}
    </tr>
  ))}
</tbody>

            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const Card = ({ borderColor, imageSrc, title, count, textColor }) => (
  <div
    className="col-md-2 col-5 card mr-3"
    style={{
      border: `1px solid ${borderColor}`,
      borderRadius: ".4rem",
      height: "fit-content",
    }}
  >
    <img
      alt=""
      style={{
        height: "2rem",
      }}
      src={imageSrc}
    />
    <p
      className="d-flex justify-content-center"
      style={{
        fontSize: ".9rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {title}
    </p>
    <p
      className="d-flex justify-content-center"
      style={{
        fontSize: "1rem",
        display: "flex",
        justifyContent: "center",
        color: textColor,
        fontWeight: "bold",
      }}
    >
      {count}
    </p>
  </div>
);

export default AttandancecalendraHR;
