import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import TableWithPagination from "../../TableWithPagination/TableWithPagination";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";
import BankDetailsTable from "./BankDetailsTable";

const columns = [
  {
    dataField: "bankImage",
    text: "IMG",
    sort: false,
  },
  {
    dataField: "bankName",
    text: "Bank",
    sort: false,
  },
  {
    dataField: "accountNo",
    text: "Account Number",
    sort: false,
  },
  {
    dataField: "ifscCode",
    text: "IFSC Code",
    sort: false,
  },
  {
    dataField: "transType",
    text: "Transaction Type",
    sort: false,
  },
  {
    dataField: "charges",
    text: "Charge",
    sort: false,
  },
];
const BankDetails = () => {

  const [banks,setBanks] = useState([]);

  const getBankDetails = async ()=>{
    try {
      const raw = "";
      const hash_key = await generateHashKey2('9168805979');
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      
    const res = await  fetch(`${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`, requestOptions);
    const raw2 = await res.text();
    const data = JSON.parse(raw2);
    console.log(data);
    setBanks(data);
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(()=>{
    getBankDetails();
  },[])
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                background: "#30363e",
                color: "#FFF",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <strong>Bank List</strong>
            </div>
            <BankDetailsTable data={banks} columns={columns} />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default BankDetails;
