import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Button } from "react-bootstrap";
import { billpunchbasicauth } from "../../utils/Credentials";
const Reacentqueries = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Firms, setFirms] = useState([]);
  const { password , username } = billpunchbasicauth;
 
  const Getfirms = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetContactUsQuery`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setFirms(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getfirms();
  }, []);

  return (
    <>
  
      <div className="p-3">
      <div className="row">
    <h5 className="col">Recent Queries</h5>
    </div>
        <div class="table-responsive">
          <table class="table">
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#fff",
              }}
            >
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                   Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                 Mobile
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Firm Name
                </th>

                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Firm type
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                 Firm Age
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Firm GST
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Address
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Telephone
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #f2f4f2",
                    color: "#000",
                    padding: "8px",
                    textAlign: "left",
                    background: "#E1F7F5",
                  }}
                >
                  Team Size
                </th>

             

              </tr>
            </thead>
            <tbody>
              {Firms.map((item) => (
                <React.Fragment key={item.pk_firm_id}>
                  <tr style={{ border: "none", cursor: "pointer" }}>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.email}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.mobile}
                  
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_name}
                  
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_type}
                  
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_age}
                    </td>

                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_gst}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_location}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_mobile}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "600",
                      }}
                    >
                      {item.firm_size}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

      
      </div>
    </>
  );
};
export default Reacentqueries;
