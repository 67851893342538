import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AdminReceive, AdminSend, } from "./SalaryTable";
import { ComposeMailAdmin } from "./Modal";

const AdminMail = () => {
  const [activeLink, setActiveLink] = useState("Receive");
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const renderContent = () => {
    switch (activeLink) {
      case "Receive":
        return <AdminReceive />;
      case "Send":
        return <AdminSend />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="m-3" style={{ background: "#fff" }}>
        <div className="row">

          <h5 className=" col"> Mail Box</h5>
          <Link className="col d-flex justify-content-end align-items-center">
            {" "}
            <button
              type="button"
              className="btn btn-sm d-flex justify-content-center align-items-center"
              style={{
                background: "aliceblue",
                borderRadius: ".4em",
                height: "2rem",
                border: "1px solid #0074D9",
                color: "#0074D9",
              }}
              onClick={openModal}
            >
              Compose
            </button>
          </Link>
        </div>

        <nav className=" navbar-expand-lg navbar-light bg-light">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse mx-md-4 mx-sm-2"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className={`nav-link green-link ${
                    activeLink === "Receive" && "active"
                  }`}
                  onClick={() => setActiveLink("Receive")}
                >
                  Receive
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link green-link ${
                    activeLink === "Send" && "active"
                  }`}
                  onClick={() => setActiveLink("Send")}
                >
                  Send
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {renderContent()}
        <ComposeMailAdmin
        showModal={showModal}
        closeModal={closeModal}
      />
      </div>
    </>
  );
};

export default AdminMail;
