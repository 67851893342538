import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import Slider from "react-slick";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Link } from "react-router-dom";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import {
  AddEmployeeworspaceModal,
  EmployeeworspaceModal,
  ViewEmployeeworkspaceModal,
} from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import WorkDashboardslider from "./Workdashboardslider";
import { FaSearch } from "react-icons/fa";

const WorkDashboardnew = () => {
  const chartContainer = useRef(null);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedProject, setSelectedProject] = useState(null);
  const [workspacedata, setworkspacedata] = useState([]);
  const [completed, setcompleted] = useState([]);
  const [overdue, setoverdue] = useState([]);
  const [pending, setpending] = useState([]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const getWorkspacedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          ownerid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetYourWorkspace`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setworkspacedata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getcompletedtask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          type: "C",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setcompleted(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getoverduetask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          type: "O",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setoverdue(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getpendingtask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          type: "P",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setpending(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getWorkspacedata();
    Getcompletedtask();
    Getoverduetask();
    getpendingtask();
  }, []);
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [75, 25],
              backgroundColor: ["green", "grey"],
              borderWidth: 0,
            },
          ],
        },
        options: {
          cutoutPercentage: 70,
          circumference: Math.PI,
          rotation: -Math.PI,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }, []);

  const [popoverPosition, setPopoverPosition] = useState({});
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const handleAddEmployeeModalOpen = (workspaceId, projectId) => {
    setSelectedWorkspaceId(workspaceId);
    setSelectedProject(projectId);
    setShowAddEmployeeModal(true);
  };

  const handleAddEmployeeModalClose = () => {
    setShowAddEmployeeModal(false);
  };

  const filteredWorkspaceData = workspacedata.filter((workspace) => {
    // Check if workspace fields match search query
    const workspaceMatches =
      workspace.workspace_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      workspace.workspce_desc
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      workspace.project_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      workspace.project_ownership
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      workspace.ownername.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if any employee in lettersdata matches search query
    const employeeMatches = workspace.lettersdata.some((employee) =>
      employee.emp_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return workspaceMatches || employeeMatches;
  });

  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImageUrl, setOverlayImageUrl] = useState(null);
  const handleImageClick = (imageUrl) => {
    setOverlayImageUrl(imageUrl);
    setShowOverlay(true);
  };
  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayImageUrl(null);
  };
  return (
    <div className="p-3" style={{ background: "#fff" }}>
      <div className="row m-0 p-0">
        <h4 className="col">Work Dashboard</h4>
        <div className="input-group  col-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="search-addon">
              <FaSearch />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            aria-label="Search"
            aria-describedby="search-addon"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="row mt-md-2 pt-md-0">
        <div className=" col">
          <div
            className="card  m-0 p-2 mt-2"
            style={{
              background: "#fff",
              overflowY: "auto",
              height: "33vh",
              overflowX: "hidden",
            }}
          >
            <h5
              className="d-flex justify-content-center"
              style={{ color: "green" }}
            >
              {" "}
              Completed Task
            </h5>
            <table className="table  m-0 p-0 ">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Due{" "}
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Task
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Deadline{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {completed.length === 0 ? (
                  <tr>
                    <td
                      className="text-center align-middle"
                      colSpan={3}
                      style={{ border: "none", background: "#fff" }}
                    >
                      No data found
                    </td>
                  </tr>
                ) : (
                  completed.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.taskname}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.task_assignee}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {new Date(item.task_startdate).toLocaleDateString() ||
                          "NA"}
                        <br />
                        {new Date(item.task_enddate).toLocaleDateString() ||
                          "NA"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" col m-0 p-0">
          <div
            className="card  m-0 p-2 mt-2"
            style={{
              background: "#fff",
              overflowY: "auto",
              height: "33vh",
              overflowX: "hidden",
            }}
          >
            <h5
              className="d-flex justify-content-center"
              style={{ color: "red" }}
            >
              {" "}
              Overdue Task
            </h5>
            <table className="table  m-0 p-0 ">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Due{" "}
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Task
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Deadline{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {overdue.length === 0 ? (
                  <tr>
                    <td
                      className="text-center align-middle"
                      colSpan={3}
                      style={{ border: "none", background: "#fff" }}
                    >
                      No data found
                    </td>
                  </tr>
                ) : (
                  overdue.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.taskname}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.task_assignee}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {new Date(item.task_startdate).toLocaleDateString() ||
                          "NA"}
                        <br />
                        {new Date(item.task_enddate).toLocaleDateString() ||
                          "NA"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" col">
          <div
            className="card  m-0 p-2 mt-2"
            style={{
              background: "#fff",
              overflowY: "auto",
              height: "33vh",
              overflowX: "hidden",
            }}
          >
            <h5
              className="d-flex justify-content-center"
              style={{ color: "#FFA62F" }}
            >
              {" "}
              Pending Task
            </h5>
            <table className="table  m-0 p-0 ">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Due{" "}
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Task
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Deadline{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {pending.length === 0 ? (
                  <tr>
                    <td
                      className="text-center align-middle"
                      colSpan={3}
                      style={{ border: "none", background: "#fff" }}
                    >
                      No data found
                    </td>
                  </tr>
                ) : (
                  pending.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.taskname}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {item.task_assignee}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        {new Date(item.task_startdate).toLocaleDateString() ||
                          "NA"}
                        <br />
                        {new Date(item.task_enddate).toLocaleDateString() ||
                          "NA"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col">
          <div
            className="table-responsive"
            style={{
              borderRadius: ".4em",
              border: "1px solid lightgrey",
            }}
          >
            <table className="table table-bordered m-0 p-0">
              <thead
                style={{
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
              >
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Work Space
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Description
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Project
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Team
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Team Size
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#0074D9",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredWorkspaceData.length === 0 ? (
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        textAlign: "center",
                        padding: "8px",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      No data found
                    </td>
                  </tr>
                ) : (
                  filteredWorkspaceData.map((item, index) => (
                    <tr
                      style={{ borderBottom: "1px solid lightgrey" }}
                      key={index}
                    >
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          background: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {item.workspace_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          background: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {item.workspce_desc}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          background: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {item.project_name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          display: "flex",
                          background: "#fff",
                          justifyContent: "center",
                        }}
                      >
                        <div className="p-2 align-start initial-container">
                          {item.lettersdata.map((employee, index) => (
                            <div
                              key={index}
                              className="initial"
                              style={{
                                marginLeft: "0.1rem",
                                background: item.colourcode,
                              }}
                            >
                              {employee.image_url ? (
                                <img
                                  src={employee.image_url}
                                  alt={employee.emp_name}
                                  onClick={() =>
                                    handleImageClick(employee.image_url)
                                  }
                                />
                              ) : (
                                <span>{employee.employee_first_letter}</span>
                              )}
                              <span className="employee-name m-0 p-1">
                                {employee.emp_name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </td>

                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          background: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {item.teamsize}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{ border: "none", background: "#fff" }}
                      >
                        <Link
                          style={{ fontSize: ".8rem" }}
                          onClick={() =>
                            handleAddEmployeeModalOpen(
                              item.pk_work_id,
                              item.pk_project_id
                            )
                          }
                        >
                          Assign Employee
                        </Link>
                        &nbsp;{" "}
                        <Link to="/retailer/Employeeworkspace">
                          <img
                            onClick={() => {
                              sessionStorage.setItem(
                                "property",
                                JSON.stringify(item)
                              );
                            }}
                            src={require("../../../assets/images/arrow.png")}
                          />
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showOverlay && overlayImageUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={overlayImageUrl}
            alt="Profile Overlay"
            style={{
              width: "40vmin",
              height: "40vmin",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
      )}
      <EmployeeworspaceModal
        isOpen={showAddEmployeeModal}
        onClose={handleAddEmployeeModalClose}
        selectedWorkspaceId={selectedWorkspaceId}
        getWorkspacedata={getWorkspacedata}
        selectedProject={selectedProject}
      />
    </div>
  );
};

export default WorkDashboardnew;
