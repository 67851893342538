import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Container, Accordion, Card, Button } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import swal from "sweetalert";
import { billpunchbasicauth } from "../../utils/Credentials";

const Firmdaccess = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [accessData, setAccessData] = useState([]);
  const Firms = JSON.parse(sessionStorage.getItem("Firms"));
  const { password, username } = billpunchbasicauth;

  const getAccessData = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: Firms.pk_firmid,
          empid:user.userid
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAccessManagementSuperAdmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setAccessData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching access data:", error);
      setTransactionLoaderState(false);
    }
  };

  const updateAccess = async (role_id, module_id, access) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: Firms.pk_firmid,
          moduleid: module_id,
          role_id: role_id,
          access: access,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/UpdateAccessSuperAdmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
        getAccessData();
      } else {
        swal("Error", data.msg, "warning");
        getAccessData();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error updating access data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getAccessData();
  }, []);

  return (
    <>
      <div className="p-2">
        <div className="row mt-2">
          <h5 className="col">{Firms.firm_name}</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Firmdashboard">Back</Link>
          </div>
        </div>
        <Container className="">
          <Accordion>
            {accessData.map((module, index) => (
              <Card className="m-0 p-2" key={index}>
                <Card.Header
                  as={Accordion.Toggle}
                  eventKey={index.toString()}
                  className="m-0 p-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: ".2rem",
                    border: "1px solid rgb(170, 215, 189)",
                    background: "rgb(236, 246, 240)",
                  }}
                >
                  <div>{module.module_name}</div>
                  <div>
                    <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                  </div>
                </Card.Header>

                <Accordion.Collapse eventKey={index.toString()}>
                  <Card.Body
                    className="mt-2"
                    style={{
                      background: "#FEFAF6",
                      border: "1px solid rgb(170, 215, 189)",
                    }}
                  >
                    <div className="mt-2">
                    {module.data.map((role) => (
                        <div
                          key={role.pk_role_id}
                          className="d-flex align-items-center justify-content-between mb-2"
                        >
                          <div style={{ fontWeight: "600", fontSize: ".8rem" }}>
                            {role.role_name}
                          </div>
                          <BootstrapSwitchButton
                            checked={role.access === "Y"}
                            onstyle="success"
                            offstyle="secondary"
                            size="sm"
                            onChange={(checked) => {
                              const newAccess = checked ? "Y" : "N";
                              updateAccess(
                                role.pk_role_id,
                                module.module_id,
                                newAccess
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Container>
      </div>
    </>
  );
};

export default Firmdaccess;
