import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";
import { GlobalContext } from "../../Context/GlobalState";
import { ViewExpenseTableEmployee } from "./SalaryTable";
import { billpunchbasicauth } from "../../utils/Credentials";
const arr = [{ label: "Name", value: "Name" }];
const Viewexpanseemployee = () => {
  const query = useQuery();
  const id = query.get("id");

  const { user,setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const { password , username } = billpunchbasicauth;
 
  const getPropertyExpense = async (e) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
         empid: user.userid,
         firmid:user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmExpense`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
 
  useEffect(() => {
    getPropertyExpense();
  }, []);

  return (
    <>
      <div>
        <div className=" row mt-md-4 mt-sm-4 p-0 mt-sm-3">
          <h4 className="col mx-md-4 mx-sm-3 mb-sm-4 d-flex align-items-center ">
            {" "}
             Expenses List
          </h4>

          <div className=" col-2 mr-md-4 mr-sm-3 d-flex justify-content-end">
            {" "}
            <Link to={"/retailer/ExpenseDashboardemployee"}>
              <button
                type="button"
                className="btn btn-sm align-items-center justify-content-center"
                style={{
                  background: "#2ca01c",
                  borderRadius: ".4em",
                  height: "2.5em",
                  // width: "8rem",
                  border: "None",
                  color: "#fff",
                }}
              >
                Back
              </button>
            </Link>
          </div>
        </div>

        <div
          className=" p-0 mx-md-3"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <ViewExpenseTableEmployee data={data} />
        </div>
      </div>
    </>
  );
};

export default Viewexpanseemployee;
