import React from "react";
import BusinessDetailsIcon from "../../assets/svg/kyc_business_details.svg";
import PersonalDetailsIcon from "../../assets/svg/kyc_personal_details.svg";
import DocumentDetailsIcon from "../../assets/svg/kyc_document_details.svg";
import DocumentUploadIcon from "../../assets/svg/kyc_document_upload.svg";
import BiometricKycIcon from "../../assets/svg/kyc_fingerprint.svg";
import KycBackIcon from "../../assets/svg/left_carrot.svg";

import { DROP_SHADOW } from "../utils/CommonConfig";
import { useHistory } from "react-router";

export const dropShadow = DROP_SHADOW;

export const Stepper = ({ step }) => {
  const history = useHistory();

  const navigateBack = () => {
    const kyc_by = sessionStorage.getItem("kyc_by")
    if (step == 1) {
      if (kyc_by && kyc_by == "admin") {
        history.push("/admin/all_user_kyc");
      } else {
        history.push("/");
      }
    } else if (step == 2) {
      history.push("/kyc/bussiness-details");
    } else if (step == 3) {
      history.push("/kyc/personal-details");
    } else if (step == 4) {
      history.push("/kyc/document-details");
    }
  };
  return (
    <div
      style={{
        // background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
        borderRadius: "1rem",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      }}
      className="m-3 rouded-3 p-4"
    >
      <div
        className="row rouded-3 justify-content-center m-0 rounded-3"
        style={{ background: "#EAF0F7" }}
      >
        <Step
          onClick={() => {
            navigateBack();
          }}
          redirect={"/user/topup_request"}
          image={KycBackIcon}
          title={"Back"}
          selected={step == -1}
        />
        <Step
          onClick={() => {
            // setServiceSelected(1);
          }}
          redirect={"/user/topup_request"}
          image={BusinessDetailsIcon}
          title={"Business Details"}
          selected={step == 1}
        />
        <Step
          onClick={() => {
            // setServiceSelected(16);
          }}
          redirect={"/user/bbps"}
          image={PersonalDetailsIcon}
          title={"Personal Details"}
          selected={step == 2}
        />
        <Step
          onClick={() => {
            // setServiceSelected(3);
          }}
          redirect={"/user/money-transfer"}
          image={DocumentDetailsIcon}
          title={"Document Details"}
          selected={step == 3}
        />
        <Step
          onClick={() => {
            //  setServiceSelected(3);
          }}
          image={DocumentUploadIcon}
          title={"Document Upload"}
          selected={step == 4}
        />
        <Step
          onClick={() => {
            // setServiceSelected(4);
          }}
          image={BiometricKycIcon}
          title={"Biometric"}
          selected={step == 5}
        />
      </div>
    </div>
  );
};

const Step = ({ image, title, selected, onClick, redirect }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
      onClick={() => {
        // redirectTo();
        onClick();
      }}
    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};
