import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";
import { SiAdblock } from "react-icons/si";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import {
  FaMoneyBill,
  FaUser,
  FaEllipsisV,
  FaFlag,
  FaBan,
  FaIcons,
  FaTimes,
} from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import {
  AddsubTask1employeeModal,
  Addtaskadmin,
  DependencyModal,
  Dependencytable,
} from "./Modal";
import "react-quill/dist/quill.snow.css";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { IoIosSend } from "react-icons/io";
import { billpunchbasicauth } from "../../utils/Credentials";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
const Taskmanagementadmin = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [taskdata, setTaskData] = useState([]);
  const [PriorityFilter, setPriorityFilter] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [platformFilter, setPlatformFilter] = useState(null);
  const { password, username } = billpunchbasicauth;

  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [showaddsubtaskModal, setshowaddsubtaskModal] = useState(false);
  const [showdependency, setshowdependency] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [Dependencymodal, setDependencymodal] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [dependencyTaskId, setDependencyTaskId] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const togglePopover = (taskId) => {
    setOpenPopoverId(openPopoverId === taskId ? null : taskId);
  };
  const opendependency = () => {
    setDependencymodal(true);
  };

  const closedependency = () => {
    setDependencymodal(false);
  };
  const openaddsubtaskModal = () => {
    setshowaddsubtaskModal(true);
  };
  const closeaddsubtaskModal = () => {
    setshowaddsubtaskModal(false);
  };
  const opendependencymodal = (taskId) => {
    setDependencyTaskId(taskId);
    setshowdependency(true);
    setCurrentTaskId(taskId);
  };

  const closedependencymodal = () => {
    setshowdependency(false);
  };
  const filteredTaskData = taskdata.filter((item) => {
    const priorityMatch =
      !PriorityFilter ||
      PriorityFilter.value === "Clear" ||
      item.task_priority === PriorityFilter.value;
    const platformMatch =
      !platformFilter ||
      platformFilter.value === "clear" ||
      item.platform === platformFilter.label;
    const categoryMatch =
      !categoryFilter ||
      categoryFilter.value === "clear" ||
      item.category === categoryFilter.label;
      
    const searchMatch =
      searchQuery === "" ||
      item.taskname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.task_description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.task_startdate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.task_enddate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.platform.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.status.toLowerCase().includes(searchQuery.toLowerCase());
    return priorityMatch && platformMatch && searchMatch && categoryMatch;
  });

  const taskdataobject = JSON.parse(sessionStorage.getItem("taskdata"));
  console.log(taskdataobject, "taskdataobject");
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
  const fetchCategories = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ taskid: "string" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetCatTask`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedCategories = data.data.map((cat) => ({
          value: cat.pk_catid,
          label: cat.catname,
        }));
        setCategories(fetchedCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setCategoryFilter(selectedOption);
  };
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        spaceid: propertyobject.pk_work_id,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const [status, setstatus] = useState();
  const Updatestatus = async (spaceID, taskId) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        spaceid: spaceID,
        projectid: propertyobject.pk_project_id,
        taskid: taskId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ChangeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setstatus(data.data);
        getTask();
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [editableTaskId, setEditableTaskId] = useState(null);
  const [editedTaskName, setEditedTaskName] = useState("");

  const handleTaskNameEdit = (taskId, taskName) => {
    setEditableTaskId(taskId);
    setEditedTaskName(taskName);
  };

  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const handleTaskNameUpdate = async (e, pk_taskid) => {
    e.preventDefault();
    try {
      console.log("Inside handleSubmit");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        empid: user.userid,
        taskid: pk_taskid,
        taskname: editedTaskName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/RenameTaskname`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        setEditableTaskId(null);
        getTask();
      } else {
        swal("Success", data.msg, "success");
        setEditableTaskId(null);
        getTask();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const fetchPlatforms = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ taskid: "string" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskPlatform`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const fetchedPlatforms = data.data.map((device) => ({
          value: device.pk_platformid,
          label: device.platform_name,
        }));
        setPlatforms(fetchedPlatforms);
      }
    } catch (error) {
      console.error("Error fetching platforms:", error);
    }
  };
  const handlePlatformChange = (selectedOption) => {
    setPlatformFilter(selectedOption);
  };

  useEffect(() => {
    getTask();
    fetchPlatforms();
    fetchCategories();
  }, []);

  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);
  const popoverStyle = {
    background: "#fff",
    width: "15vw",
    position: "absolute",
    zIndex: 1,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  };
  const linkStyle = {
    fontSize: ".8rem",
  };
  const calculatePopoverPosition = (iconRef) => {
    if (!iconRef) return {};

    const iconPosition = iconRef.getBoundingClientRect();
    const popoverTop = iconPosition.top + iconPosition.height + 10; // Adjust 10px for spacing
    const popoverLeft = iconPosition.left + iconPosition.width / 2;

    return {
      ...popoverStyle,
      top: `${popoverTop}px`,
      left: `${popoverLeft}px`,
      transform: "translateX(-50%)",
    };
  };
  const iconRefs = useRef({});
  return (
    <div className="p-3" style={{ background: "white" }}>
      <Addtaskadmin
        show={showaddsubtaskModal}
        closeModal={closeaddsubtaskModal}
        getSubsubTask={getTask}
        pk_work_id={propertyobject.pk_work_id}
        pk_project_id={propertyobject.pk_project_id}
      />
      <DependencyModal
        show={Dependencymodal}
        closeModal={closedependency}
        getTask={getTask}
      />
      <Dependencytable
        taskId={currentTaskId}
        show={showdependency}
        closeModal={closedependencymodal}
        dependencyTaskId={dependencyTaskId}
      />
      <div className="row m-0 p-0">
        <h5 className="col m-0 p-0">Task List</h5>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/Create_workspace_admin" style={{}}>
            Back
          </Link>
        </div>
      </div>

      <div className="row">
        <Select
          placeholder="Priority"
          className="px-1"
          options={options}
          value={PriorityFilter}
          onChange={(selectedOption) => setPriorityFilter(selectedOption)}
        />
        <Select
          className="px-1"
          placeholder="Platform"
          options={[{ value: "clear", label: "Clear" }, ...platforms]}
          value={platformFilter}
          onChange={handlePlatformChange}
        />

        <Select
          className="px-1"
          placeholder="Category"
          options={[{ value: "clear", label: "Clear" }, ...categories]}
          value={categoryFilter}
          onChange={handleCategoryChange}
        />

        <div className="col">
          <input
            className="form-control"
            type="text"
            placeholder="Search Task..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="col-md-5 col-3 d-flex justify-content-end">
          {" "}
          {accessData &&
            accessData.map(
              (module) =>
                module.data &&
                module.data.map((role) =>
                  role.role_name === "Add Tasks" ? (
                    role.access === "Y" ? (
                      <span key={role.pk_role_id}>
                        <Badge
                          className="d-flex  align-items-center"
                          style={{
                            border: "1px solid blue",
                            background: "azure",
                            height: "2rem",
                          }}
                          onClick={openaddsubtaskModal}
                        >
                          Add Task
                        </Badge>
                      </span>
                    ) : (
                      <span key={role.pk_role_id}>
                        <button
                          className="btn btn-sm btn-primary d-flex justify-content-center"
                          style={{
                            color: "#fff",
                            height: "2.5rem",
                            border: "none",
                            borderRadius: ".4rem",
                            display: "flex",
                            alignItems: "center",
                            fontSize: ".8rem",
                            backgroundColor: "gray",
                            cursor: "not-allowed",
                          }}
                          disabled
                        >
                          Add Task
                        </button>
                      </span>
                    )
                  ) : null
                )
            )}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, background: "#fff" }}>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Task Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                  width: "40%",
                }}
              >
                Description
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Platform
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Assignee
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Status
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  background: "#fff",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTaskData.length === 0 ? (
              <tr>
                <td
                  colSpan="6"
                  className="text-center"
                  style={{ padding: "8px" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              filteredTaskData.map((item) => (
                <React.Fragment key={item.pk_task_id}>
                  <tr
                    style={{
                      border: "none",
                      cursor: "pointer",
                      background: "#fff",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "left",
                        border: "none",
                        fontSize: ".8rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontWeight: "700",
                      }}
                    >
                      {item.task_priority === "High" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "#f4c430" }}
                        />
                      )}
                      {item.task_priority === "Urgent" && (
                        <FaFlag
                          style={{ marginRight: "5px", color: "maroon" }}
                        />
                      )}
                      {item.task_priority === "Normal" && (
                        <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                      )}
                      {item.task_priority === "Low" && (
                        <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                      )}
                      {item.task_priority === "Clear" && (
                        <img
                          src={require("../../../assets/images/flag_1.png")}
                          style={{ marginRight: "5px", width: ".8rem" }}
                          alt="Clear Flag"
                        />
                      )}
                      {editableTaskId === item.pk_taskid ? (
                        <Form.Group
                          controlId="sendActivityField"
                          style={{ position: "relative" }}
                        >
                          <Form.Control
                            type="text"
                            value={editedTaskName}
                            onChange={(e) => setEditedTaskName(e.target.value)}
                          />
                          <IoIosSend
                            style={{
                              color: "#0096FF",
                              position: "absolute",
                              top: "50%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              handleTaskNameUpdate(e, item.pk_taskid)
                            }
                          />
                        </Form.Group>
                      ) : (
                        <>{item.taskname} &nbsp;</>
                      )}
                      {item.dependency_status === "Y" && (
                        <span
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            border: "none",
                            fontSize: ".9rem",
                            borderBottom: "1px solid #f2f4f2",
                            borderRight: "1px solid #f2f4f2",
                          }}
                        >
                          <SiAdblock
                            style={{ fontSize: "1.4rem" }}
                            onClick={() => opendependencymodal(item.pk_taskid)}
                          />
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      <Link
                        to="/retailer/Subtask_management"
                        onClick={() =>
                          sessionStorage.setItem(
                            "taskdetails",
                            JSON.stringify(item)
                          )
                        }
                      >
                        {item.task_description}
                      </Link>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {new Date(item.task_startdate).getDate()}{" "}
                      {new Date(item.task_startdate).toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      - {new Date(item.task_enddate).getDate()}{" "}
                      {new Date(item.task_enddate).toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      {new Date(item.task_enddate).getFullYear()}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {item.platform}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "none",
                        fontSize: ".9rem",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                      }}
                    >
                      {item.task_assignee}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontSize: ".9rem",
                      }}
                    >
                      {item.status === "O" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                            color: "#ff2e2e",
                            border: "1px solid #ff2e2e",
                            borderRadius: ".3rem",
                          }}
                        >
                          Overdue
                        </span>
                      )}
                      {item.status === "P" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(255, 255, 0, 0.1)",
                            color: "#f7b12a",
                            border: "1px solid #f7b12a",
                            borderRadius: ".3rem",
                          }}
                        >
                          Pending
                        </span>
                      )}
                      {item.status === "C" && (
                        <span
                          className="p-1"
                          style={{
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "#2bc155",
                            border: "1px solid #2bc155",
                            borderRadius: ".3rem",
                          }}
                        >
                          Complete
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #f2f4f2",
                        borderRight: "1px solid #f2f4f2",
                        fontSize: ".9rem",
                        position: "relative",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              id={`tooltip-${item.fk_spaceid}-${item.pk_taskid}`}
                            >
                              Mark as completed
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              marginRight: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IoCheckmarkDoneCircle
                              style={{
                                color: "green",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                Updatestatus(item.fk_spaceid, item.pk_taskid)
                              }
                            />
                          </div>
                        </OverlayTrigger> */}
                        <div
                          key={item.pk_taskid}
                          style={{ position: "relative" }}
                        >
                          <PiDotsThreeOutlineVerticalBold
                            ref={(ref) => (iconRefs[item.pk_taskid] = ref)}
                            style={{ cursor: "pointer" }}
                            onClick={() => togglePopover(item.pk_taskid)}
                          />
                          {openPopoverId === item.pk_taskid && (
                            <div
                              style={calculatePopoverPosition(
                                iconRefs[item.pk_taskid]
                              )}
                            >
                              <div
                                className="btn btn-light m-0 p-0"
                                style={{ width: "9vw" }}
                              >
                                <div className="d-flex justify-content-center">

                                 
                          <div
                            style={{
                              marginRight: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              style={{
                                color: "green",
                                fontSize: ".8rem",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                Updatestatus(item.fk_spaceid, item.pk_taskid)
                              }
                            >Mark As Completed</Link>
                          </div>
                        </div>
                                <div>
                                  <Link
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "dependencytaskid",
                                        JSON.stringify(item)
                                      );
                                      opendependency();
                                    }}
                                    style={linkStyle}
                                  >
                                    Add Dependency
                                  </Link>
                                </div>
                                <div>
                                  <Link
                                    onClick={() =>
                                      opendependencymodal(item.pk_taskid)
                                    }
                                    style={linkStyle}
                                  >
                                    View Dependency
                                  </Link>
                                </div>
                                <div>
                                  <Link
                                    onClick={() =>
                                      handleTaskNameEdit(
                                        item.pk_taskid,
                                        item.taskname
                                      )
                                    }
                                    style={linkStyle}
                                  >
                                    Rename
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Taskmanagementadmin;
