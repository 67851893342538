import React, { useEffect, useState } from "react";
import { Col, Row, Nav, Tabs, Tab, Form, Button } from "react-bootstrap";
import { offsetArr } from "../../utils/CommonConfig";
const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const DashboardReport = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const refresh = () => {
    // getLevelsData();
  };
  const updateLevelModal = (level) => {
    //  setSelectedLevel(level);
    // setIsOpen2(true);
  };
  useEffect(() => {
    // getLevelsData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 stretch-card">
        <div className="card m-0 p-0">
          <div className="card-body">
            <div className="tab-custom-pills-horizontal">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col xs={12}>
                    <Nav
                      variant="pills"
                      className="nav-pills-custom justify-content-around"
                      style={{ boxShadow: dropShadow,padding:'1rem' }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className="d-flex align-items-center"
                        >
                          DMT
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          className="d-flex align-items-center"
                        >
                          AEPS
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          className="d-flex align-items-center"
                        >
                          BBPS
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <div className="d-flex w-100 row justify-content-between">
                    <div>
                      <h3> Reports</h3>
                    </div>
                    <div>
                      <Form.Group
                        style={{ maxWidth: "12rem", margin: "10px 0px" }}
                      >
                        <Form.Control
                          type="text"
                          className="form-control rounded-0"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                          value={searchText}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <Col xs={12}>
                    <Tab.Content className="tab-content-custom-pill">
                      <Tab.Pane eventKey="first">
                        <DMTRepors />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <DMTRepors />{" "}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <DMTRepors />{" "}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DMTRepors = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    // getLevelsData();
  }, []);
  return (
    <div className="row w-100 p-0">
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          <tr>
            <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Ref"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Amount"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Balance"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Status"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Date and Time"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{e.title}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.description}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.ageLimit}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.thumbnail}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.thumbnail}</span>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardReport;
