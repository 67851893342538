import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { PRIVACY_POLICY_COMPANY_NAME } from "../utils/StringConstants";

const PrivacyPolicy = () => {
  const {setTransactionLoaderState} = useContext(GlobalContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTransactionLoaderState(false)
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
   setTransactionLoaderState(true)
  }, [])
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-8 col-sm-10 mx-auto">
          <h1 className="text-center">Privacy Policy</h1>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>1. Introduction</h5>
            <span>
              This policy explains how we, {PRIVACY_POLICY_COMPANY_NAME}.
              (hereinafter referred to as “ {PRIVACY_POLICY_COMPANY_NAME} ”),
              use the personal information which you provide to us when using
              our service, including but not limited to our website and mobile
              applications (jointly referred as “Website”).Please read this
              Privacy Policy and understand it before using our services. By
              visiting and/or ordering services on this Website, you agree and,
              where required, consent to the collection, use and transfer of
              your information as set out in this policy.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>
              2. What information do we collect about you?
            </h5>
            <span>
              We collect personal information from you when you order goods or
              services from us or use our Website. We also collect information
              when you complete any customer survey. Website usage information
              may also be collected using cookies (as defined below).{" "}
              {PRIVACY_POLICY_COMPANY_NAME}
              will collect information that personally identifies you and/or
              your location, where required (this may include your name, email
              address, home address, telephone number, geolocation, etc.), but
              only when you voluntarily give it to us. We collect this
              information exclusively to carry out the functions offered on the
              Website and to provide you with offers and information about
              {PRIVACY_POLICY_COMPANY_NAME} and other services we think you may
              be interested in. We might collect this personal information
              through: online food ordering; entry into competitions;
              subscribing to our newsletter; creating a user account; sending
              ‘contact us’ messages or other correspondence through the Website;
              or through advertising, research and direct marketing.We do not
              collect sensitive information about you.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>3. Cookies</h5>
            <span>
              Some of the information collected will not personally identify you
              but will instead track your use of the Website so that we can
              better understand how the Website is used by customers and in turn
              enhance and improve your experience in ordering food. We may
              obtain this information by use of cookies. Cookies are a small
              data file transferred to your device that recognises and
              identifies your device and allows your device to ‘remember’
              information from the Website for future use. Cookies do not
              contain any information that personally identifies you and we do
              not use cookies in order to obtain such information – your
              personal information can only be obtained by{" "}
              {PRIVACY_POLICY_COMPANY_NAME} if you actively provide it to us. We
              may collect technical information from your mobile device or your
              use of our services through a mobile device, for example, location
              data and certain characteristics of, and performance data about
              your device, carrier/operating system including device and
              connection type, IP address, mobile payment methods, interaction
              with other retail technology such as use of NFC Tags, QR Codes or
              use of mobile vouchers. Your device and/or the web browser should
              allow you to refuse cookies if you wish by changing the
              settings.To find out more about cookies, including how to see what
              cookies have been set and how to manage and delete them, visit
              www.allaboutcookies.org.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>
              4. Storage and security of your personal information
            </h5>
            <span>
              {PRIVACY_POLICY_COMPANY_NAME} will use all reasonable endeavours
              to maintain the security of your personal information and to
              protect your personal information from misuse, interference and
              loss and against unauthorised access, modification or disclosure.{" "}
              {PRIVACY_POLICY_COMPANY_NAME} will destroy any personal
              information it holds about you which it no longer requires under
              the terms of this Privacy Policy.Where you have chosen a password
              to access certain services of the Website, you are responsible for
              keeping your password confidential. We recommend not sharing your
              password with anyone.Due to the nature of the internet,{" "}
              {PRIVACY_POLICY_COMPANY_NAME} does not provide any guarantee or
              warranty regarding the security of your personal information
              during transmission to us or storage by us and you acknowledge
              that you disclose your personal information to us at your own
              risk. Please contact us immediately if you become aware or have
              reason to believe there has been any unauthorised use of your
              personal information in connection with the Website.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>
              5. How will we use the information we collect from you?
            </h5>
            <span>
              You consent to {PRIVACY_POLICY_COMPANY_NAME} collecting and using
              your personal information for processing and delivering the
              order/s placed on the Website and any other service provided
              through the Website. In particular you expressly consent that{" "}
              {PRIVACY_POLICY_COMPANY_NAME} may disclose your personal
              information, including your name, email address, physical address
              and telephone number to riders delivering your order, either
              employed by {PRIVACY_POLICY_COMPANY_NAME} or by third parties
              providing delivery services to {PRIVACY_POLICY_COMPANY_NAME} .You
              consent to {PRIVACY_POLICY_COMPANY_NAME} using your personal
              information for advertising and direct marketing purposes in order
              to inform you about the Website and the goods and services it
              provides, or other matters that it believes will be of interest to
              you. {PRIVACY_POLICY_COMPANY_NAME} may disclose your personal
              information, and you consent to the disclosure of your personal
              information, to: other entities within the{" "}
              {PRIVACY_POLICY_COMPANY_NAME} group of companies; and third
              parties engaged by {PRIVACY_POLICY_COMPANY_NAME} or other members
              of the {PRIVACY_POLICY_COMPANY_NAME} group to perform functions or
              provide products and services on our behalf such as processing
              payments, mail outs, debt collection, research, statistical
              information, marketing and direct or indirect advertising. If you
              would like to unsubscribe from receiving direct marketing
              communications from {PRIVACY_POLICY_COMPANY_NAME} or do not want
              us to share your personal information with other parties, please
              e-mail us at support@{PRIVACY_POLICY_COMPANY_NAME}.co.in or follow
              the steps to unsubscribe which are presented in every
              communication you receive from us, requesting your personal
              information be removed from our mailing list. We will use
              reasonable endeavours to comply with your request within a
              reasonable period of receipt of your request.Please note that the
              withdrawal of any authorisations for processing of your personal
              information by third parties may result in us not being able to
              provide you with any services.
              {PRIVACY_POLICY_COMPANY_NAME} reserves the right to disclose your
              personal information if it is required or authorised to do so by
              law, or, if it is reasonably necessary in its opinion to protect
              the rights or property of {PRIVACY_POLICY_COMPANY_NAME} or any
              third party, or to avoid injury to any person.If the{" "}
              {PRIVACY_POLICY_COMPANY_NAME} business is sold or merges with
              another entity then some or all of your personal information may
              be passed to a third party.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>6. Access to your information</h5>
            <span>
              {PRIVACY_POLICY_COMPANY_NAME} will use all reasonable endeavours
              to keep personal information it holds accurate, complete,
              up-to-date, relevant and not misleading. Please contact us if you
              would like to access the personal information{" "}
              {PRIVACY_POLICY_COMPANY_NAME} holds about you. We will use
              reasonable endeavours to provide a complete list of your personal
              information within a reasonable period of receipt of your request.
              Please email us if you would like to receive a copy of this
              information – support@{PRIVACY_POLICY_COMPANY_NAME}.co.in.{" "}
              {PRIVACY_POLICY_COMPANY_NAME} reserves the right to charge a
              nominal fee for the processing of this request in accordance with
              local legislation.You may contact us to correct any of your
              personal information that is inaccurate, incomplete or
              out-of-date, or to request that your personal information be
              deleted. We will use reasonable endeavours to correct or delete
              your personal information as requested within a reasonable period
              of receipt of your request. Deletion of your account with{" "}
              {PRIVACY_POLICY_COMPANY_NAME}
              will not automatically delete the personal information held about
              you. If you would like {PRIVACY_POLICY_COMPANY_NAME} to delete all
              personal information together with the deletion of your account,
              please follow the steps mentioned-above. Please note that the
              deletion of your personal information from our database will
              result in us not being able to provide you with any services. If
              applicable, any legal requirement on us to maintain certain
              records of your personal information shall prevail over any of
              your requests. We may require you to prove your identity before
              providing you with copies of your personal information.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>
              7. Changes to our Privacy Policy
            </h5>
            <span>
              {PRIVACY_POLICY_COMPANY_NAME} reserves the right to alter all or
              any part of this Privacy Policy. Any changes thereto will be
              notified via the Website and, where appropriate, through e-mail
              notification.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>8. Other Websites</h5>
            <span>
              Our Website may have links to other websites. This privacy policy
              only applies to the Website. You should therefore read the privacy
              policies of the other websites when you are using those sites.
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>9. Contact</h5>
            <span>
              All comments, queries and requests relating to our use of your
              information are welcomed and should be addressed to: suport@
              {PRIVACY_POLICY_COMPANY_NAME}.co.in
            </span>
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#010147" }}>
              10. Background Location Tracking in {PRIVACY_POLICY_COMPANY_NAME}{" "}
              Sales Team APP or in
              {PRIVACY_POLICY_COMPANY_NAME} main APP
            </h5>
            <span>
              {PRIVACY_POLICY_COMPANY_NAME} Sales Manager app collect location
              data to improve location accuracy when finding nearby bluetooth
              printer . Your location can be accessed at any time even when the
              app is closed or not in use. {PRIVACY_POLICY_COMPANY_NAME} Sales
              Team app collects location data to enabled tracking your trips to
              work or calculated distance travelled even when the app is closed
              or not in use.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
