import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import swal from "sweetalert";

const Salaryarrearhr = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Member, setMember] = useState([]);
  const [Arrears, setArrears] = useState([]);
  const [Employeeid, setEmployeeid] = useState(-1);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [clickedRows, setClickedRows] = useState([]);
  const [Modalemployeeid, setModalemployeeid] = useState(-1);
  const [modalselectedEmployee, setmodalselectedEmployee] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    empid: "",
    month: "",
    day: 1,
    year: 2024,
  });
  const { password, username } = billpunchbasicauth;
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFormChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const GetFirmEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ firmId: user.firm_id });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const mappedEmployees = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setMember(mappedEmployees);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const handleEmployeeSelection = (selectedOption) => {
    setselectedEmployee(selectedOption);
    const selectedEmployeeId = selectedOption.value;
    setEmployeeid(selectedEmployeeId);
  };

  const handlemodalEmployeeSelection = (selectedOption) => {
    setmodalselectedEmployee(selectedOption);
    const selectedEmployeempdalId = selectedOption.value;
    setModalemployeeid(selectedEmployeempdalId);
  };
  const Getarrear = async (empid) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ empid });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllArrearsEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setArrears(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetFirmEmployee();
  }, []);

  const handleSearch = () => {
    if (selectedEmployee && selectedEmployee.value !== "") {
      Getarrear(selectedEmployee.value);
    } else {
      console.log("Please select an employee before searching.");
    }
  };

  const handleProcessAll = async (
    pk_arrear_id,
    empid,
    balanace_amount,
    bank_name,
    bank_ifsc,
    bank_accno,
    mobile,
    empname,
    due_month
  ) => {
    setTransactionLoaderState(true);
    let transactionMode = "IMPS";

    if (clickedRows.includes(pk_arrear_id)) {
      swal("Error", "Button already clicked for this row", "error");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: pk_arrear_id,
          firmid: user.firm_id,
          purpose: "Arrear",
          userid: empid,
          amount: balanace_amount,
          tpin: "1",
          serviceType: transactionMode,
          accNo: bank_name,
          ifsc: bank_ifsc,
          bankName: bank_accno,
          mobile: mobile,
          custName: empname,
          month: due_month,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/SalaryManagement/SalaryPayroll`,
        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        Getarrear();
      } else {
        swal("Error", data.msg, "error");
        Getarrear();
      }
      setTransactionLoaderState(false);
      setClickedRows((prevClickedRows) => [...prevClickedRows, pk_arrear_id]);
    } catch (error) {
      console.error("API call failed", error);
      swal("API call failed", "An error occurred", "error");
    }
  };
  const handleSubmit = async (e) => {
    try {
      if (
        !modalselectedEmployee ||
        !formData.month ||
        !formData.day ||
        !formData.year
      ) {
        swal("Error", "Please fill all required fields.", "error");
        return;
      }
      const requestBody = {
        empid: modalselectedEmployee.value,
        month: formData.month,
        day: formData.day,
        year: formData.year,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(requestBody);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GenrateDayWiseArrear`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
      } else {
        const result =
          data.result && data.result.length > 0 ? data.result[0] : {};
        if (result.result === "Y") {
          swal("Success", result.message, "success");
        } else {
          swal("Error", result.message || data.msg, "error");
        }
      }

      handleCloseModal();
      Getarrear();
      console.log("Form submission completed.");

      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <div className="p-3">
      <h5 className="mx-2">Generate Arrears</h5>
      <div className="row ">
        <Form.Group className="col-md-3 col-7" controlId="Employeename">
          <Form.Label>Select Employee</Form.Label>
          <Select
            options={[
              { value: "", label: "Please Select Employee" },
              ...Member,
            ]}
            value={selectedEmployee}
            onChange={handleEmployeeSelection}
          />
        </Form.Group>

        <div className="col-md-4 col-7 mt-md-4">
          <Button variant="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <div className="col d-flex justify-content-end mt-md-4">
          <Button
            variant="primary"
            style={{ height: "2.5rem" }}
            onClick={handleShowModal}
          >
            Generate Arrears
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead
            style={{
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th style={tableHeaderStyle}>Employee Name</th>
              <th style={tableHeaderStyle}>Month</th>
              <th style={tableHeaderStyle}>Paid Amount</th>
              <th style={tableHeaderStyle}>Total Amount</th>
              <th style={tableHeaderStyle}>Balance Amount</th>
              <th style={tableHeaderStyle}>Status</th>
              <th style={tableHeaderStyle}>Process</th>
            </tr>
          </thead>
          <tbody>
            {Arrears.length === 0 ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            ) : (
              Arrears.map((item) => (
                <tr
                  key={item.pk_arrear_id}
                  style={{ border: "none", cursor: "pointer" }}
                >
                  <td style={tableDataStyle}>{item.empname}</td>
                  <td style={tableDataStyle}>{item.due_month}</td>
                  <td style={tableDataStyle}>
                    {twoDecimalPlaces(item.paid_amount)}
                  </td>
                  <td style={tableDataStyle}>
                    {twoDecimalPlaces(item.total_amount)}
                  </td>
                  <td style={tableDataStyle}>
                    {twoDecimalPlaces(item.balanace_amount)}
                  </td>
                  <td style={tableDataStyle}>
                    {item.status === "N" ? (
                      <Button className="btn-sm btn-primary">
                        Unprocessed
                      </Button>
                    ) : (
                      <Button className="btn-sm btn-success">Processed</Button>
                    )}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={tableDataStyle}
                  >
                    {item.status === "Y" ? (
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() =>
                          handleProcessAll(
                            item.pk_arrear_id,
                            item.empid,
                            item.balanace_amount,
                            item.bank_name,
                            item.bank_ifsc,
                            item.bank_accno,
                            item.mobile,
                            item.empname,
                            item.due_month
                          )
                        }
                        disabled
                      >
                        Processed
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() =>
                          handleProcessAll(
                            item.pk_arrear_id,
                            item.empid,
                            item.balanace_amount,
                            item.bank_name,
                            item.bank_ifsc,
                            item.bank_accno,
                            item.mobile,
                            item.empname,
                            item.due_month
                          )
                        }
                      >
                        Process
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Arrears</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col" controlId="formEmployee">
                <Form.Label>Employee</Form.Label>
                <Select
                  options={Member}
                  value={modalselectedEmployee}
                  onChange={handlemodalEmployeeSelection}
                  placeholder="Select Employee"
                />
              </Form.Group>

              <Form.Group className="col" controlId="month">
                <Form.Label>Month</Form.Label>
                <Select
                  options={monthOptions}
                  value={monthOptions.find(
                    (option) => option.value === formData.month
                  )}
                  onChange={(selectedOption) =>
                    handleFormChange("month", selectedOption.value)
                  }
                />
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col" controlId="year">
                <Form.Label>Year</Form.Label>
                <div className="d-flex align-items-center">
                  <Button
                    variant="outline-primary"
                    onClick={() => handleFormChange("year", formData.year - 1)}
                  >
                    -
                  </Button>
                  <Form.Control
                    type="number"
                    value={formData.year}
                    onChange={(e) => handleFormChange("year", e.target.value)}
                    className="mx-2"
                    style={{ width: "80px" }}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() => handleFormChange("year", formData.year + 1)}
                  >
                    +
                  </Button>
                </div>
              </Form.Group>
              <Form.Group className="col" controlId="day">
                <Form.Label>Day</Form.Label>
                <div className="d-flex align-items-center">
                  <Button
                    variant="outline-primary"
                    onClick={() =>
                      handleFormChange("day", Math.max(1, formData.day - 1))
                    }
                  >
                    -
                  </Button>
                  <Form.Control
                    type="number"
                    value={formData.day}
                    onChange={(e) => handleFormChange("day", e.target.value)}
                    className="mx-2"
                    style={{ width: "80px" }}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() =>
                      handleFormChange("day", Math.min(31, formData.day + 1))
                    }
                  >
                    +
                  </Button>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const tableHeaderStyle = {
  borderBottom: "1px solid #f2f4f2",
  color: "#000",
  padding: "8px",
  textAlign: "center",
  background: "#E1F7F5",
};

const tableDataStyle = {
  padding: "8px",
  textAlign: "center",
  border: "none",
  fontSize: ".8rem",
  borderBottom: "1px solid #f2f4f2",
  borderRight: "1px solid #f2f4f2",
  fontWeight: "600",
};

export default Salaryarrearhr;
