import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./app/retailer/App";
import AdminApp from "./app/admin/App";
import "./i18n";
import "./index.css";
import "./custom.scss";
import { GlobalProvider, GlobalContext } from "./app/Context/GlobalState";
import PrintSlip from "./app/print/PrintSlip";
import BusinessDetails from "./app/KYC/BusinessDetails";
import PersonalDetails from "./app/KYC/PersonalDetails";
import DocumentDetails from "./app/KYC/DocumentDetails";
import NomineeDetails from "./app/KYC/NomineeDetails";
import DocumentUpload from "./app/KYC/DocumentUpload";
import UserKycView from "./app/KYC/UserKycView";
import PrivacyPolicy from "./app/company/PrivacyPolicy";
import TermsAndCondition from "./app/company/TermsAndCondition";
import AccountDeletionRequest from "./app/company/AccountDeletionRequest";
import BBPSPrintSlip from "./app/print/BBPSPrint";
import QRPrint from "./app/print/QRPrint";
import CustomerKyc from "./app/retailer/CKYC/CustomerKyc";
import EKYC from "./app/retailer/Agent EKYC/EKYC";
import AEPSPrint from "./app/print/AEPSPrint";
import BBPSPrint3 from "./app/print/BBPSPrint3";
import BBPSPrint2 from "./app/print/BBPSPrint2";
import PrintSlip2 from "./app/print/PrintSlip2";
import AEPSReconcilePrint from "./app/print/AEPSReconcilePrint";
import CertificatePrint from "./app/print/CertificatePrint";
import IndemnityBond from "./app/retailer/virtual report/IdentityBond";
import TransactionLoader from "./app/utils/TransactionLoader";
import BiometricKYC from "./app/KYC/BiometricKYC";
import PaymentGatewayResponse from "./app/print/PaymentGatewayResponse";
import SendOtp from "./app/utils/firebase/SendOtp";
import Login5 from "./app/user-pages/Login5";
import Register2 from "./app/user-pages/Register2";
import ForgetPassword2 from "./app/user-pages/ForgetPassword2";
import Test from "./app/user-pages/Test";
import PGSlipPrint from "./app/print/PGSlipPrint";
import Developer from "./app/developer_partner/Developer2";
import LandingPage from "./app/retailer/SalaryWeb/landingpage";
import Loginpayroll from "./app/retailer/SalaryManagement/Loginpayroll";
import Signup from "./app/retailer/SalaryWeb/Signup";
import Billpunchprivacypolicy from "./app/retailer/SalaryWeb/Billpunch_privacypolicy";
import Billpunchrefundpolicy from "./app/retailer/SalaryWeb/Billpunch_refundpolicy";
import Billpunchcancellationpolicy from "./app/retailer/SalaryWeb/Billpunchancellation";
import BillpunchTermsAndConditions from "./app/retailer/SalaryWeb/Billpunchterms_condition";
import Billpunchcontact from "./app/retailer/SalaryWeb/Billpunch_Contactus";
import SalarySlip from "./app/print/Salaryslip";
import Forgetpasswordbillpuch from "./app/retailer/SalaryManagement/Forget_passwordbillpunch";
import SalarySliphr from "./app/print/SalaryslipHr";
import Pricing from "./app/retailer/SalaryWeb/Pricing";
import Billpuncfaq from "./app/retailer/SalaryWeb/Billpunch_faq";
import Signlesignon from "./app/retailer/SalaryWeb/Login";
import Attendance from "./app/retailer/SalaryWeb/Attendance";
import Employeemanagment from "./app/retailer/SalaryWeb/Employeemanagement";
import Expensemanagement from "./app/retailer/SalaryWeb/Expensemanagement";
import Leavemanagement from "./app/retailer/SalaryWeb/Leave";
import Payrollmanagement from "./app/retailer/SalaryWeb/Payroll";
import Appidsecret from "./app/retailer/SalaryWeb/IdSecret";
import Billpunchaboutus from "./app/retailer/SalaryWeb/BillpunchAboutus";
import Blogs from "./app/retailer/SalaryWeb/Blogs";
import Slug from "./app/retailer/SalaryWeb/Slug";
import Author from "./app/retailer/SalaryWeb/Author";
import Identity from "./app/retailer/SalaryWeb/Identity";

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <GlobalContext.Consumer>
        {(context) => (
          <TransactionLoader
            text="Please Wait..."
            isActive={context.transactionLoader}
          >
            <Switch>
              <Route exact path="/developer" component={Developer} />
              <Route
                path={`/pages/payment_gateway_response`}
                component={PaymentGatewayResponse}
              />
              <Route path={`/pages/indemnity_bond`} component={IndemnityBond} />
              <Route path={`/pages/certificate`} component={CertificatePrint} />
              <Route path={`/pages/print`} component={PrintSlip} />
              <Route path={`/pages/aeps_print`} component={PrintSlip2} />
              <Route
                path={`/pages/aeps_reconcile_print`}
                component={AEPSReconcilePrint}
              />
              <Route path={`/pages/recharge_print`} component={BBPSPrint2} />
              <Route path={`/pages/bill_print`} component={BBPSPrint3} />
              <Route path={`/pages/qr`} component={QRPrint} />
              <Route path={`/pages/bbps/print`} component={BBPSPrintSlip} />
              <Route path={`/pages/aeps/print`} component={AEPSPrint} />
              <Route path={`/pages/pg/Print`} component={PGSlipPrint} />
              <Route
                path={`/pages/SalarySliphr/Print`}
                component={SalarySliphr}
              />
              <Route path={`/pages/Salaryslip/Print`} component={SalarySlip} />
              <Route path="/admin" component={AdminApp} />
              <Route path="/retailer" component={App} />
              <Route path="/customer-kyc" component={CustomerKyc} />
              <Route path="/agent-kyc" component={EKYC} />
              <Route
                path="/kyc/bussiness-details"
                component={BusinessDetails}
              />
              <Route path="/kyc/personal-details" component={PersonalDetails} />
              <Route path="/kyc/document-details" component={DocumentDetails} />
              <Route path="/kyc/nominee-details" component={NomineeDetails} />
              <Route path="/kyc/document-upload" component={DocumentUpload} />
              <Route path="/kyc/biometric" component={BiometricKYC} />
              <Route path="/kyc/user-kyc-view" component={UserKycView} />
              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route
                path="/account-deletion"
                component={AccountDeletionRequest}
              />
              <Route
                path="/terms-and-conditions"
                component={TermsAndCondition}
              />
              <Route path="/contact_us" exact component={Billpunchcontact} />
              <Route path="/faq" exact component={Billpuncfaq} />
              <Route
                path="/terms_condition"
                exact
                component={BillpunchTermsAndConditions}
              />
              <Route
                path="/cancellation_policy"
                exact
                component={Billpunchcancellationpolicy}
              />
              <Route path="/blog/:post_slug" exact component={Slug} />
              <Route path="/author/:Author" exact component={Author} />

              <Route path="/Blogs" exact component={Blogs} />
             

              <Route
                path="/refund_policy"
                exact
                component={Billpunchrefundpolicy}
              />
              <Route path="/New_login" exact component={Signlesignon} />
              <Route path="/Generate_ID_Secret" exact component={Appidsecret} />
              <Route
                path="/privacy_policy"
                exact
                component={Billpunchprivacypolicy}
              />
              <Route
                path="/Attendance_Management"
                exact
                component={Attendance}
              />
              <Route
                path="/EmployeeManagment"
                exact
                component={Employeemanagment}
              />
              <Route
                path="/Expense_Management"
                exact
                component={Expensemanagement}
              />
              <Route
                path="/Leave_Management"
                exact
                component={Leavemanagement}
              />
              <Route
                path="/Payroll_Management"
                exact
                component={Payrollmanagement}
              />
              <Route path="/Identity" component={Identity} />
              <Route path="/About_us" component={Billpunchaboutus} />
              <Route path="/Signup" exact component={Signup} />
              <Route path="/Loginpayroll" component={Loginpayroll} />
              <Route path="/Pricing" component={Pricing} />
              <Route
                path="/Forget_password"
                component={Forgetpasswordbillpuch}
              />
              <Route path="/" component={LandingPage} />
              <Route
                exact
                path="/forget-password"
                component={ForgetPassword2}
              />
              <Route path="/register" component={Register2} />
              <Route path="/otp" component={SendOtp} />

              <Route path="/test" component={Test} />
              {/* <Route path="/" component={Login5} /> */}
            </Switch>
          </TransactionLoader>
        )}
      </GlobalContext.Consumer>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
