import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Row, Col } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { RiArrowDropDownLine } from "react-icons/ri";

const Employeebalanceleave = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [employee, setEmployee] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [activeAccordion, setActiveAccordion] = useState("0");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthClick = async (month, index) => {
    setSelectedMonth(month);
    setActiveAccordion(index.toString());
    await getEmployeebalanceleave(month);
  };

  const getEmployeebalanceleave = async (month) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          month: month,
          firmid: user.firm_id,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetLeaveBalance`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        const employeeData = data.data[0];
        setEmployee({
          ...employeeData,
          current_month: month,
        });
      } else {
        setEmployee(null);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getEmployeebalanceleave(selectedMonth);
  }, [selectedMonth]);

  const finalPlBalance = Number(employee?.final_pl_balance || 0);
  const finalSlBalance = Number(employee?.final_sl_balance || 0);
  const totalBalanceLeave = finalPlBalance + finalSlBalance;

  return (
    <>
      <div className="">
        <h4 className="mt-3 mx-4">Balance Leave</h4>
        <Row className="m-2">
          {months.map((month, index) => (
            <Col className="p-0" md={6} sm={12} key={index}>
              <Accordion activeKey={activeAccordion}>
                <Card className="p-2">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index.toString()}
                    onClick={() => handleMonthClick(month, index)}
                    className="m-0 p-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: ".2rem",
                      border: "1px solid rgb(170, 215, 189)",
                      background: "rgb(236, 246, 240)",
                    }}
                  >
                    <div> {month}</div>
                    <div>
                      {" "}
                      <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />{" "}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body
                      className="p-2"
                      style={{
                        background: "#FEFAF6",
                        border: "1px solid rgb(170, 215, 189)",
                      }}
                    >
                      <Card.Text>
                        <Row className="m-1 p-1">
                          <Col className="m-0 p-0">
                            <p className="p-0 m-0">
                              <b> Total Balance Leave </b>{" "}
                            </p>
                          </Col>
                          <Col className="d-flex justify-content-center">
                            <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                              <b> {totalBalanceLeave} </b>
                            </h6>
                          </Col>
                        </Row>
                        <hr className="m-0 p-0" />
                        <Row className="m-1 p-1">
                          <Col className="m-0 p-0">
                            <p className="p-0 m-0">
                              <b> Final Paid Leave </b>{" "}
                            </p>
                          </Col>
                          <Col className="d-flex justify-content-center">
                            <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                              <b> {employee?.final_pl_balance || 0} </b>
                            </h6>
                          </Col>
                        </Row>
                        <Row className="m-1 p-1">
                          <Col className="m-0 p-0">
                            <p className="p-0 m-0">
                              <b> Final Sick Leave </b>{" "}
                            </p>
                          </Col>
                          <Col className="d-flex justify-content-center">
                            <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                              <b> {employee?.final_sl_balance || 0} </b>
                            </h6>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "" }}>
                              {employee?.present_days || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Total
                              <br /> Present
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#C65BCF" }}>
                              {employee?.absent || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Total
                              <br /> Absent
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#41B06E" }}>
                              {employee?.earn_sl || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Earned
                              <br /> Sick Leave
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#DD5746" }}>
                              {employee?.earn_pl || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Earned
                              <br /> Paid Leave
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#DD5746" }}>
                              {employee?.pl_availed || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Availed
                              <br /> Paid Leave
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#DD5746" }}>
                              {employee?.sl_availed || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Availed
                              <br /> Sick Leave
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#8576FF" }}>
                              {employee?.sl_balance || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Balance
                              <br /> Sick Leave
                            </p>
                          </Col>
                          <Col>
                            <h6 className="m-0 p-0 " style={{ color: "#FA7070" }}>
                              {employee?.pl_balance || 0}
                            </h6>
                            <p className="m-0 p-0">
                              Balance
                              <br /> Paid Leave
                            </p>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Employeebalanceleave;
