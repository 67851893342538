import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";
import { twoDecimalPlaces } from "../utils/utilFuctions";

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function inWords(num) {
  let n;
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return capitalizeFirstLetter(str);
}

const PGSlipPrint = () => {
  let data = JSON.parse(localStorage.getItem("print"));
  const [txnData, setTxnData] = useState([]);

  let query = useQuery();
  const receiptNo = query.get("id");
  const Mode = query.get("type");
  const printReceipt = () => {
    window.print();
  };
  const NOTE =
    "This is Computer Generated receipt & does not require physical signature.";

  return (
    <>
      <div
        style={{
          margin: "5vw",
          border: "2px solid #000",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="m-3">
            <table style={{ fontSize: "1rem" }}>
              <tr className="p-1"
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className="font-weight-bold p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                 Service :
                </td>
                <td className="font-weight-bold p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                { data.service}
                </td>
              </tr>
              
              <tr className="p-1"
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Date :
                </td>
                <td className=" p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.rch_recharge_date}
                </td>
              </tr>
              <tr className="p-1"
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className=" p-2"
                  style={{ 
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  User Code :
                </td>
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.user_code}
                </td>
              </tr>
              <tr 
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  User Name :
                </td>
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.user_name}
                </td>
              </tr>
            
               
             
              <tr className="p-1"
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Amount :
                </td>
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {twoDecimalPlaces(data.rch_amount)}
                </td>
              </tr>
              <tr 
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                 Status :
                </td>
                <td className="p-2"
                  style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.status}
                </td>
              </tr>



            </table>
          </div>
          <div className="m-3">
            {" "}
            <img
              src={require("../../assets/images/company/logo_square.png")}
              style={{ width: "10rem", margin: "1rem 0px" }}
            />{" "}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}></div>
        <div className="m-3">
        <table className="table  " style={{width:"100%",  border: '2px solid rgba(0, 116, 217, 1)',}}>
        <tbody>
          <tr>
            <td className="font-weight-bold text-center"  style={{border: '2px solid rgba(0, 116, 217, 1)',}}>Transaction Number</td>
            <td  className="font-weight-bold text-center"  style={{border: '2px solid rgba(0, 116, 217, 1)',}}>Account Number</td>
            <td  className="font-weight-bold text-center"  style={{border: '2px solid rgba(0, 116, 217, 1)',}}>IFSC Code</td>
            <td  className="font-weight-bold text-center"  style={{border: '2px solid rgba(0, 116, 217, 1)',}}>Amount</td>
          </tr>
          <tr>
            <td className="text-center" style={{border: '2px solid rgba(0, 116, 217, 1)',  fontSize: "1rem",}}>{data.rch_transaction_id}</td>
            <td  className="text-center" style={{border: '2px solid rgba(0, 116, 217, 1)',  fontSize: "1rem",}}>{data.rch_account_no}</td>
            <td  className="text-center" style={{border: '2px solid rgba(0, 116, 217, 1)',  fontSize: "1rem",}}>{data.rch_ifsc_code}</td>
            <td  className="text-center" style={{border: '2px solid rgba(0, 116, 217, 1)',  fontSize: "1rem",}}>{twoDecimalPlaces(data.rch_amount)}</td>
          </tr>
        </tbody>
      </table>
        </div>

        {/* <div style={{ display: "flex" }}>
          <table style={{ width: "100%", fontSize: "01rem" }}>
            <tr style={{ padding: "0px", border: "none", background: "none" }}>
              <td style={{ fontSize: "1rem", border: "2px solid #05569A" }}>
                {" "}
                Order No.
              </td>
              <td style={{ fontSize: "1rem", border: "2px solid #05569A" }}>
                {" "}
                UTR No.
              </td>
              <td style={{ fontSize: "1rem", border: "2px solid #05569A" }}>
                {" "}
                Status
              </td>
              <td style={{ fontSize: "1rem", border: "2px solid #05569A" }}>
                {" "}
                Amount Paid (₹)
              </td>
            </tr>
            {txnData.map((e, i) => (
              <tr
                style={{
                  padding: "0px",
                  border: "2px solid #05569A",
                  background: "none",
                }}
              >
                <td style={{ fontSize: "1rem", border: "2px solid #05569A" , fontWeight:'600' }}>
                  {i + 1}{" "}
                </td>
                <td style={{ fontSize: "1rem", border: "2px solid #05569A" , fontWeight:'600' }}>
                  {" "}
                  {e.dmt_utr_no}
                </td>
                <td style={{ fontSize: "1rem", border: "2px solid #05569A" , fontWeight:'600' }}>
                  {e.Status}
                </td>
                <td style={{ fontSize: "1rem", border: "2px solid #05569A" , fontWeight:'600' }}>
                  {twoDecimalPlaces(e.rch_amount)}
                </td>
              </tr>
            ))}
    
          </table>
        </div> */}
        <div className="m-3" style={{
                    fontSize: "1rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}>
          {`Amount In words : `}

          <strong>{" "}{inWords(Number(data.rch_amount ))}</strong>
        </div>
        <div className="m-3" style={{ display: "flex", fontSize: ".8rem" }}>
          <strong> {`NOTE : `}</strong>
          {NOTE}
        </div>
        <div style={{ display: "flex", padding: "1rem 0rem" }}>
          <div style={{ flex: "1" }} />
          <button
            style={{ marginLeft: "auto" }}
            className="hide-on-print"
            onClick={printReceipt}
          >
            {"Print"}
          </button>
        </div>
      </div>
    </>
  );
};

export default PGSlipPrint;
