import React from "react";
import LoadingOverlay from "react-loading-overlay";
const TransactionLoader = ({ children, isActive, text }) => {
  return (
    <LoadingOverlay active={isActive} spinner text={text}>
      {children}
    </LoadingOverlay>
  );
};

export default TransactionLoader;
