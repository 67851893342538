import React, { useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
const Successcard = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div id="successCard" className="mb-4" style={{}}>
        <div className="p-2 service_laptop" style={{ background: "#F3F7FF" }}>
          <div className="" style={{ marginBottom: "-7rem" }}>
            <img
              className="d-flex m-5"
             alt="Billpunch"
              style={{ height: "rem" }}
              src={require("../../../assets/images/2ndelement2.svg")}
            />
          </div>

          <h4
            className=" d-flex px-md-0 px-3 justify-content-center"
            style={{ fontWeight: "500", color: "#000" }}
          >
            Empowering Success Across Industries
          </h4>
          <h5
            className="d-flex px-md-0 px-3  justify-content-center"
            style={{  color: "#000",fontWeight:"300" }}
          >
         Effortlessly handle employee data, boost productivity with billpunch, and streamline operations for hassle-free productivity.
          </h5>
          <div>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-md-8 col-12 ">
                <div className="row p-md-2 p-0 d-flex justify-content-center">
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2 ">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{  color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon1.png")}
                          />
                        </span>{" "}
                        &nbsp; Dashboard
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Get hands on a personalized dashboard, where you can store all your employee information.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{ color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon2.png")}
                          />
                        </span>{" "}
                        &nbsp; Project Management
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Manage projects effortlessly with our HR cloud software keeping tasks organised and teamwork smooth.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{ color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon5.png")}
                          />
                        </span>{" "}
                        &nbsp; Ledgers/Transaction History
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Effortlessly track transactions with our ledger feature, offering clear summaries of dates, amounts, for each transaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row p-md-2 p-0 d-flex justify-content-center">
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{ color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon3.png")}
                          />
                        </span>{" "}
                        &nbsp;Leave Management
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Streamline absence management with our intuitive tool, ensuring smooth operations and precise record-keeping for all employee leave types.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{ color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon4.png")}
                          />
                        </span>{" "}
                        &nbsp; Attendance Management
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Easily track and manage actual employee presence at work  through our software covering in/out time , breaks , overtime etc.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 large-screen-col col-12 mt-md-0 mt-2 mb-1">
                    <div
                      className=" card m-0 p-2 "
                      style={{
                        border: "1px solid #C8DEFF",
                        borderRadius: ".5rem",
                      }}
                    >
                      <h6
                        className=""
                        style={{  color: "#000" }}
                      >
                        <span>
                          {" "}
                          <img
                           alt="Billpunch"
                            style={{ height: "2rem" }}
                            src={require("../../../assets/images/cardicon6.png")}
                          />
                        </span>{" "}
                        &nbsp;Expense Management
                      </h6>
                      <p style={{ fontSize: "", color: "#000" }}>
                      Effortlessly track, approve, and reimburse employee expenses while keeping track of spending with our streamlined expense management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="col-2"></div>
            </div>
          </div>
          <div
            className="d-flex mr-5 mb-3 justify-content-end"
            style={{ marginTop: "-8rem" }}
          >
            <img
              className="d-flex m-5"
             alt="Billpunch"
              style={{ height: "rem" }}
              src={require("../../../assets/images/dot.svg")}
            />
          </div>
        </div>





        <div className="service_phone mt-4" >
       
        <div className="dot-click-slider p-3" style={{background:"#F3F7FF"}}>
         
          <h2
            className=" d-flex px-md-0 text-center px-3 justify-content-center"
          >
            Empowering Success Across Industries
          </h2>
          <p
            className="d-flex px-5 text-center justify-content-center"
            style={{ fontSize: ".9rem", color: "#000" }}
          >
            Your paycheck is our priority: seamless payroll management for your
            financial peace
          </p>
       
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item>
          <div
            className=" card mx-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                 alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon1.png")}
                />
              </span>{" "}
              &nbsp; Centralized Platform
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Simplifies by centralizing all functions into one platform. With
              interactive dashboards and self-service options, it streamlines
              processes, saving time and costs for businesses.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className=" card mx-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                 alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon2.png")}
                />
              </span>{" "}
              &nbsp; User Experience
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Enhances your experience by providing intuitive interfaces and
              seamless navigation. With user-centric design and interactive
              features, we ensures efficient experience for all users.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="  card mx-5 p-2  "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                 alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon5.png")}
                />
              </span>{" "}
              &nbsp; Data Security
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              We ensures data security with robust measures . Our platform
              maintains strict access controls, guaranteeing confidentiality and
              compliance with privacy regulations.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="  card mx-5 p-2 "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                 alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon3.png")}
                />
              </span>{" "}
              &nbsp; Time Efficiency
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              This maximizes time efficiency through streamlined processes and
              Effortless interfaces. With user-friendly features and optimizes
              productivity, saving time for businesses and employees alike.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          {" "}
          <div
            className="  card mx-5 p-2  "
            style={{
              border: "1px solid #C8DEFF",
              borderRadius: ".5rem",
            }}
          >
            <p className="" style={{ fontSize: "1rem", color: "#000" }}>
              <span>
                {" "}
                <img
                 alt="Billpunch"
                  style={{ height: "2rem" }}
                  src={require("../../../assets/images/cardicon4.png")}
                />
              </span>{" "}
              &nbsp; Reporting and Analytics
            </p>
            <p style={{ fontSize: "", color: "#000" }}>
              Our comprehensive reporting empowers businesses with valuable
              insights and customizable, real-time data visualization for
              enhanced strategic planning and performance.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
        {" "}
        <div
          className="  card mx-5 p-2  "
          style={{
            border: "1px solid #C8DEFF",
            borderRadius: ".5rem",
          }}
        >
          <p className="" style={{ fontSize: "1rem", color: "#000" }}>
            <span>
              {" "}
              <img
               alt="Billpunch"
                style={{ height: "2rem" }}
                src={require("../../../assets/images/cardicon6.png")}
              />
            </span>{" "}
            &nbsp; Efficient Record-Keeping
          </p>
          <p style={{ fontSize: "", color: "#000" }}>
            We ensures streamlined processes for easy access and management of
            essential data. With user-friendly interfaces, we optimize
            efficiency, facilitating seamless operations.
          </p>
        </div>
      </Carousel.Item>
      </Carousel>

    
      <div className="dot-indicators" style={{marginBottom:"-1.5rem"}}>
        {[...Array(6).keys()].map((i) => (
          <span
            key={i}
            onClick={() => setIndex(i)}
            className={i === index ? "active" : ""}
          ></span>
        ))}
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default Successcard;
