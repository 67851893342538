import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { COMPANY_NAME_NAVBAR } from "../../utils/StringConstants";
const style = {
  color: "#FFF",
};
const activeStyle = {
  color: "#FFF",
  fontWeight: "bold",
};

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(this.state).forEach((i) => {
    //   this.setState({ [i]: false });
    // });

    const dropdownPaths = [
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/editors", state: "editorsMenuOpen" },
      { path: "/Manage Distributor", state: "ManageDistributorsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav
        style={{
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong>{COMPANY_NAME_NAVBAR}</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../../assets/images/company/logo_square.png")}
              alt="logo"
            />
          </a>
        </div>
      
        <ul className="nav" style={{ overflowY: "scroll", height: "100vh" }}>
          <li
            style={
              (this.isPathActive("/master-distributor/dashboard")
                ? activeStyle
                : style,
              { marginTop: "10px" })
            }
            className={
              this.isPathActive("/master-distributor/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/master-distributor/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>

          
          <li
            style={
              (this.isPathActive("/master-distributor/dashboard")
                ? activeStyle
                : style,
              { marginTop: "10px" })
            }
            className={
              this.isPathActive("/master-distributor/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/master-distributor/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>
          {/* Manage Distributor */}

          <li
            className={
              this.isPathActive("/master-distributor/Manage Distributor")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.DistributorsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("DistributorsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <img
                  style={{ height: "1.2rem" }}
                  src={require("../../../assets/icons/Master Distributor.png")}
                />
              </span>
              <span className="menu-title">
                <Trans>Manage Distributor</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.DistributorsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Manage Distributor/CreateDistributor"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link
                    className="nav-link"
                    to="/master-distributor/Manage Distributor/CreateDistributor"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/create distributor.png")}
                      />
                    </span>
                    <Trans>Create Distributor</Trans>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Manage Distributor/ViewDistributor"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Manage Distributor/ViewDistributor"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/view distributor.png")}
                      />
                    </span>
                    <Trans>View Distributor</Trans>
                  </Link>
                </li>
                {/* <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Manage Distributor/TopupHistory"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Manage Distributor/TopupHistory"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/ic_recharge_history.png")}
                      />
                    </span>
                    <Trans>Topup History</Trans>
                  </Link>
                </li> */}
              </ul>
            </Collapse>
          </li>

          {/* Funds */}

          <li
            className={
              this.isPathActive("/master-distributor/Funds")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.FundsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("FundsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <img
                  style={{ height: "1.2rem" }}
                  src={require("../../../assets/icons/Funds.png")}
                />
              </span>
              <span className="menu-title">
                <Trans>Funds</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.FundsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li
                    className={
                      this.isPathActive("/master-distributor/Funds/FundReports")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/master-distributor/Funds/FundReports"
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={require("../../../assets/icons/Fund Report.png")}
                        />
                      </span>
                      <Trans>Fund Reports</Trans>
                    </Link>
                  </li>
                  <li
                    className={
                      this.isPathActive(
                        "/master-distributor/Funds/FundStatement"
                      )
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/master-distributor/Funds/FundStatement"
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={require("../../../assets/icons/Fund Statement.png")}
                        />
                      </span>
                      <Trans>Fund Statement</Trans>
                    </Link>
                  </li>
                  <li
                    className={
                      this.isPathActive(
                        "/master-distributor/Wallet Reports/TopupRequest"
                      )
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/master-distributor/Wallet Reports/TopupRequest"
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={require("../../../assets/icons/ic_topup_request.png")}
                        />
                      </span>
                      <span className="menu-title">Topup Request</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.isPathActive(
                        "/master-distributor/Wallet Reports/TopupView"
                      )
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/master-distributor/Wallet Reports/TopupView"
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={require("../../../assets/icons/ic_topup_view.png")}
                        />
                      </span>
                      <span className="menu-title">Topup View</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.isPathActive(
                        "/master-distributor/Wallet Reports/BankDetails"
                      )
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/master-distributor/Wallet Reports/BankDetails"
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={require("../../../assets/icons/ic_bank_details.png")}
                        />
                      </span>
                      <span className="menu-title">Bank Details</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/* Wallet */}
          {/* Wallet to wallet transfer Report */}

          <li
            style={
              (this.isPathActive(
                "/master-distributor/Wallet to wallet Transfer"
              )
                ? activeStyle
                : style,
              { marginTop: "10px" })
            }
            className={
              this.isPathActive("/master-distributor/Wallet to wallet Transfer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className="nav-link"
              to="/master-distributor/Wallet to wallet Transfer/WalletToWalletTransfer"
            >
              <span className="menu-icon">
                <img
                  style={{ height: "1.2rem" }}
                  src={require("../../../assets/icons/ic_wallet_to_wallet_transfer.png")}
                />
              </span>
              <span className="menu-title">Wallet to Wallet Transfer</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/master-distributor/Report")
                ? "nav-item menu-items active "
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.ReportMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("ReportMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <img
                  style={{ height: "1.2rem" }}
                  src={require("../../../assets/icons/ic_aeps_report.png")}
                />
              </span>
              <span className="menu-title">
                <Trans>Reports</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse
              in={
                this.isPathActive("/master-distributor/Report")
                  ? true
                  : this.state.ReportMenuOpen
              }
            >
              <ul className="nav flex-column sub-menu">
                <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Report/TransactionReport"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Report/TransactionReport"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/Transaction report.png")}
                      />
                    </span>
                    <Trans>Transaction Report</Trans>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive("/master-distributor/Report/ProfitReport")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Report/ProfitReport"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/profit-report.png")}
                      />
                    </span>
                    <Trans>Profit Report</Trans>
                  </Link>
                </li>
                <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Manage Distributor/DistributorReport"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Manage Distributor/DistributorReport"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/Distributor report.png")}
                      />
                    </span>
                    <Trans>Distributor Use Report</Trans>
                  </Link>
                </li>
                {/* <li
                  className={
                    this.isPathActive(
                      "/master-distributor/Report/OperatorWiseReport"
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Report/OperatorWiseReport"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/Operator report.png")}
                      />
                    </span>
                    <Trans>Operator Wise Report</Trans>
                  </Link>
                </li> */}
                <li
                  className={
                    this.isPathActive("/master-distributor/Report/Ledger")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  {" "}
                  <Link
                    className="nav-link"
                    to="/master-distributor/Report/Ledger"
                  >
                    <span className="menu-icon">
                      <img
                        style={{ height: "1.2rem" }}
                        src={require("../../../assets/icons/ic_ledger_report.png")}
                      />
                    </span>
                    <span className="menu-title">Ledger</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
