import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const Topup = () => {
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [accountCreated, setAccountCreated] = useState(
    localStorage.getItem("va-created") || false
  );
  const [TopupData, setTopupData] = useState({
    msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
    success: false,
  });
  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);


  const imageUpload =()=>{

  }

  return (
    <div style={{ padding: "2rem" }}>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          Topup Request
        </h2>
        <div className="card-body">
          <Tabs defaultActiveKey="Topup Request" id="uncontrolled-tab-example">
            <Tab
              eventKey="Topup Request"
              title="Topup Request"
              className="test-tab"
            >
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Topup Request
                </div>

                <div>
                  <div className="row p-3">
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Amount`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Amount"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Transaction ID`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Transaction ID"
                          onChange={(e) => {
                            setTransactionId(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row p-3">
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Bank`}
                        </Form.Label>
                        <Select
                          options={banks}
                          onChange={(e) => {
                            setSelectedBank(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Type`}
                        </Form.Label>
                        <Select
                          options={paymentModes}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row p-3">
                    <div className="col p-0">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Transaction Date`}
                        </Form.Label>
                        <DatePicker
                        className='form-control'
                          selected={transactionDate}
                          onChange={(date) => setTransactionDate(date)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>{`Remark`}</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Remark"
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col">
                      <Form.Group>
                        <Form.Label>
                          <b>Slip Upload</b>
                          <span>(Optional)</span>
                        </Form.Label>
                        <Form.Control
                          accept=".jpeg"
                          type="file"
                          name="slip"
                          onChange={imageUpload}
                          style={{ borderRadius: "0px" }}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row p-3">
                    <div className="col px-0">
                      <Button className="rounded-0 btn-block" variant="success">
                        {" "}
                        Submit
                      </Button>
                    </div>
                    <div className="col">
                      <Button className="rounded-0 btn-block" variant="danger">
                        {" "}
                        Clear
                      </Button>
                    </div>
                    <div className="col p-0"></div>
                    <div className="col p-0"></div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab
              eventKey="Topup Request List"
              title="Topup Request List"
              className="test-tab"
            >
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  View Topup Request
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Topup;
