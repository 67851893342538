import React, { useContext, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import {
  ExportToExcel,
  excelMapping,
  now,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { Link } from "react-router-dom";
import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import Swal from "sweetalert2";

export const columns = [
  {
    dataField: "beni_name",
    text: "Name",
  },
  {
    dataField: "purpose",
    text: "Purpose",
  },
  {
    dataField: "beni_account",
    text: "Account",
  },
  {
    dataField: "beni_ifsc",
    text: "IFSC",
  },
  {
    dataField: "wl_before_balance",
    text: "Before Balance",
  },
  {
    dataField: "pay_amount",
    text: "Pay Amount",
  },
  {
    dataField: "wl_after_balance",
    text: "After Balance",
  },
  {
    dataField: "wl_indate",
    text: "Date",
  },
];

const Payouthistoryone = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50); // Initial items per page

  useEffect(() => {
    getEmployee();
  }, []);

  const getEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllUtr`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };


  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Options for items per page dropdown
  const options = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];

  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <div className="col-9">
                <h5
                  className="d-flex ml-md-3 align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Transaction History
                </h5>
              </div>

              <div className="col d-flex justify-content-end">
              
                <Link to="/retailer/Payments">
                  <Button className="btn-sm">Back</Button>
                </Link>
              </div>
            </div>

            <hr className="p-0 mx-md-1" />
            <table
              className="my-table mx-2 transparent-table text-center border-bottom"
              style={{
                width: "100%",
                overflowX: "auto",
                border: "0px",
                overflow: "auto",
              }}
            >
              <thead className="mx-md-2">
                <tr
                  className="borderless-table text-center border-bottom"
                  style={{
                    background: "white",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {"Sr"}
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                 Name
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Ref.Id
                  </th>
               
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Instruction Id
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                   RRN No.
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    className=" text-center  "
                    style={{
                      background: "white",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    API msg
                  </th>
              
                
               
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No data found
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item, index) => (
                    <tr className="border-bottom" key={index}>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.ref_id}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.instruction_identification}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.api_end_to_end}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {twoDecimalPlaces(item.amount)}
                      </td>
                      <td
                        className="text-center align-middle"
                        style={{
                          border: "none",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                      >
                        {item.api_msg}
                      </td>
                  
                     
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Pagination Dropdown */}
            <div className="m-3 d-flex justify-content-start">
              <Select
                options={options}
                defaultValue={options.find((opt) => opt.value === itemsPerPage)}
                onChange={(opt) => {
                  setItemsPerPage(opt.value);
                  setCurrentPage(1); 
                }}
                className="mr-2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payouthistoryone;
