import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import swal from "sweetalert";

const Esign = () => {
  const [formData, setFormData] = useState({
    signerName: "",
    signerEmail: "",
    signerCity: "",
    purpose: "",
    pdfFile: null,
    numPages: null,
  });

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
      numPages: null,
    }));

    if (type === "file" && files[0]) {
      countPages(files[0]);
    }
  };

  const countPages = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      const pdfData = new Uint8Array(uint8Array);

      if (
        pdfData[0] === 0x25 &&
        pdfData[1] === 0x50 &&
        pdfData[2] === 0x44 &&
        pdfData[3] === 0x46
      ) {
        const pageCountMatch = /\/Type\s*\/Page[^s]/g;
        const matches = String.fromCharCode
          .apply(null, pdfData)
          .match(pageCountMatch);

        const numPages = matches ? matches.length : 0;

        setFormData((prev) => ({ ...prev, numPages }));
      } else {
        console.error("Invalid PDF file format");
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: "BR1004",
        type: "A",
        signer_name: formData.signerName,
        signer_email: formData.signerEmail,
        signer_city: formData.signerCity,
        purpose: formData.purpose,
        redirect: "",
        pages: "2",
        organization: "BUMPPY MEDIA PVT LTD",
        doc_data: "",
      });

      console.log("Request sending:", raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `https://bumppy.in/api_integration/agent_concern/v1/uploadDocForEsign.php`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Error", data.msg, "error");
      } else {
        swal("Success", data.msg, "success");
      }
    } catch (error) {}
  };

  return (
    <div className="m-5">
      <Form >
        <Form.Group controlId="signerName">
          <Form.Label>Signer Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter signer name"
            name="signerName"
            value={formData.signerName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="signerEmail">
          <Form.Label>Signer Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter signer email"
            name="signerEmail"
            value={formData.signerEmail}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="signerCity">
          <Form.Label>Signer City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter signer city"
            name="signerCity"
            value={formData.signerCity}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="purpose">
          <Form.Label>Purpose</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="pdfFile">
          <Form.Label>Upload PDF</Form.Label>
          <Form.Control
            type="file"
            accept=".pdf"
            name="pdfFile"
            onChange={handleChange}
          />
        </Form.Group>

        {formData.numPages !== null && (
          <p className="mb-2">Number of Pages in PDF: {formData.numPages}</p>
        )}

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Esign;
