import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router";
import OTPInput from "otp-input-react";
import { basicAuth } from "../../config";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
const convert = require("xml-js");

const EKYC = () => {
  const { transactionLoader, setTransactionLoaderState, user } =
    useContext(GlobalContext);
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();

  const Datehandler = (date) => {
    const inputDate = new Date(date);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      setFormErrors({
        ...formErrors,
        DOB: "*Please enter Date before 18 years",
      });
    }
    setStartDate(date);
  };

  const { password, username } = basicAuth;
  const [AccessT, setAccessT] = useState("");
  const [OtpToken, setOtpToken] = useState("");
  const [KycToken, setKycToken] = useState("");
  const [kycData, setKycData] = useState({});
  const InitialValues = {
    FullName: "",
    Gender: "",
    Mobile: "",
    Email: "",
    Title: "",
    Pincode: "",
    PAN: "",
    State: "",
    Adhar: "",
    AgentrefId: "",
    ShopName: "",
  };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [States, setStates] = useState([]);
  const [Otp, setOtp] = useState("");
  const [Wadh, setWadh] = useState("");
  const [ChooseFinger, setChooseFinger] = useState("");
  const [devicePort, setDevicePort] = useState("11100");
  const [device, setDevice] = useState(-1);
  const [pidData, setPidData] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    error: "",
    success: "",
  });

  const mantraPorts = [
    "11100",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
  ];

  const Login = () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: password,
      email: username,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch("https://apitranxt.paynnow.com/api/Account/Login", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setAccessT(result.accessToken);
          setTransactionLoaderState(false);
        })
        .catch((error) => {
          setTransactionLoaderState(false);
        });
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const dateToStr = (date) =>
    `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()}`;

  const getNextAgentId = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ=="
      );
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0D3Zb9gk-jHZ1vx1Ww0oaLjUUp-KVbqPCuoXJmPrivyyQxEIZyXwjTGn0jmaAXhzcpSpxEFxPUDcF0vFA4AebQclvcDRhYi-ogCHqv5WOZnVVFKMKybMyqOnVEZI_1JnSpc3fCvN9F9sYOj3okqBOlF7YvJfGQhK2w-QNRpofYJAWxewGyoagqCM721GBLANYIh8gEMjt_dD2nY6077Joo6L4BKuE4a5wqo8bJgKOiv125-_Z22njtt3SPxJrYBIJTYvUyjwxeGFWXlaIAOZjXgeFbTzyYuhew19JHIReBAGXMYKymQ6NHBRHUqivbyseG7xX5exUUCvnPFfECwLobehlWwgk9rUVKU4n-9AWjYod2JEXaW9gxH2xLnCV9s2h4pYflY4A_ZFNDjc4ljbYSOOb-XSE8bb855KueJaRX5tM5ldA3c6PoUNJv5sflg_uiDLDj9QQs09L0vJp4Pa1JzgycXU-1xo8PmcMYQfqt_6Ti89Zbi0PBRDSwAxZfsd2_GwWSjdzO0BYfiMjaR8_PhAfhGJUfaeiEyy0LnEU3XvX-EH46cAGRl5VmDvYeF-Ata6Is5f9ua3uHV4MTd7tPYWju-RtnREFVp3AsD5qeBR7ySoKiQR9IZief6XLBhnGfVw6C11bTyxIT4FRa-BW1"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const res = await fetch(
        `https://apitranxt.paynnow.com/api/kyc/GetlastAgentid`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode == "00" || data.responseCode == "01") {
        setFormValues({ ...formValues, AgentrefId: data.responseMsg });
        fetchUserKycData(data.responseMsg);
      }
    } catch (error) {}
  };

  const CreatCustEKyc = () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile.trim(),
      p2: formValues.FullName.trim(),
      p3: formValues.PAN.trim(),
      p4: dateToStr(startDate),
      p5: formValues.Gender.trim(),
      p6: formValues.Email.trim(),
      p7: formValues.AgentrefId.trim(),
      p8: formValues.ShopName.trim(),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch("https://apitranxt.paynnow.com/api/kyc/CreateAgent", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setOtpToken(result.data?.otpToken);
          setTransactionLoaderState(false);
          if (result.responseCode == "00") {
            swal(
              result.responseMsg ? result.responseMsg : "Success",
              result.data?.responseMessage,
              "success"
            );
          } else {
            swal(
              result.responseMsg ? result.responseMsg : "Failed",
              result.responseMsg,
              "error"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setTransactionLoaderState(false);
        });
    } catch (error) {}
  };

  const OtpVal = () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: OtpToken,
      p3: Otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        "https://apitranxt.paynnow.com/api/kyc/OTPValidation",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.data.kycToken) {
            GetAdharWadh(result.data.kycToken);
          }
          setTransactionLoaderState(false);
          if (result.responseCode == "00") {
            swal(
              result.responseMsg ? result.responseMsg : "Success",
              result.data?.responseMessage,
              "success"
            );
          } else {
            swal(
              result.responseMsg ? result.responseMsg : "Failed",
              result.responseMsg,
              "error"
            );
          }
        })
        .catch((error) => {
          setTransactionLoaderState(false);
        });
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      CreatCustEKyc();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.FullName) {
      errors.FullName = "*Full Name is required!";
    }
    if (!values.Title) {
      errors.Title = "*Title is required!";
    }
    if (!values.Gender) {
      errors.Gender = "*Gender is required!";
    }
    if (!values.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (values.Mobile.length != 10) {
      errors.Mobile = "*Enter valid Mobile number";
    }
    if (!values.Email) {
      errors.Email = "*Email is required!";
    } else if (!regex.test(values.Email)) {
      errors.Email = "*This is not a valid email format!";
    }
    if (!values.Pincode) {
      errors.Pincode = "*Pincode is required!";
    } else if (values.Pincode.length != 6) {
      errors.Pincode = "*Please enter valid pincode!";
    }
    if (!values.State) {
      errors.State = "*Please select a state!";
    }
    if (!values.State) {
      errors.State = "*Please select a state!";
    }
    if (!values.AgentrefId) {
      errors.AgentrefId = "*Agent Reference Id is required!";
    }
    if (!values.ShopName) {
      errors.ShopName = "*Shop Name is required!";
    }
    if (!values.Adhar) {
      errors.Adhar = "*Adhar is required!";
    } else if (values.Adhar.length != 12) {
      errors.Adhar = "*Please enter valid Adhar no.!";
    }

    if (!values.PAN) {
      errors.PAN = "*PAN is required!";
    } else if (values.PAN.length != 10) {
      errors.PAN = "*Please enter valid PAN no.!";
    }
    const inputDate = new Date(startDate);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      errors.DOB = "*Please enter Date before 18 years";
    }

    return errors;
  };

  const GetAdharWadh = (KycToken) => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: KycToken,
      p3: formValues.AgentrefId,
    });

    setKycToken(KycToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://apitranxt.paynnow.com/api/kyc/GetAadhaarWadh",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWadh(result.data.wadh);
        setTransactionLoaderState(false);
        if (result.responseCode == "00") {
          swal(
            result.responseMsg ? result.responseMsg : "Success",
            result.data?.responseMessage,
            "success"
          );
        } else {
          swal(
            result.responseMsg ? result.responseMsg : "Failed",
            result.responseMsg,
            "error"
          );
        }
      })
      .catch((error) => {
        setTransactionLoaderState(false);
      });
  };

  const discoverDevice = () => {
    let found = false;
    for (let i = 0; i < mantraPorts.length && !found; i++) {
      const currentPort = mantraPorts[i];
      try {
        discoverDeviceReq(currentPort, () => {
          found = true;
          setDevicePort(currentPort);
          getDeviceInfo(currentPort);
        });
      } catch (error) {}
    }
  };

  function discoverDeviceReq(port, callback) {
    let url;
    if (device == 0) {
      url = `https://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `https://localhost:${port}/getDeviceInfo`;
    }
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo.additional_info.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            // alert('device is ready to use');
            callback();
          }
          // setDeviceInfo(data1);
        } else {
        }
      }
    };

    xhr.send();
  }

  function getDeviceInfo(port) {
    let url;
    if (device == 0) {
      url = `https://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `https://localhost:${port}/getDeviceInfo`;
    }
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo.additional_info.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            alert("device is ready to use");
          }
          setDeviceInfo(data1);
        } else {
        }
      }
    };

    xhr.send();
  }

  useEffect(() => {
    discoverDevice();
  }, [device]);

  function morphoCapture(isKyc) {
    var url = `https://localhost:${devicePort}/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        `<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="${Wadh}" posh=""/>` +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        `<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="${Wadh}" posh=""/>` +
        "</PidOptions>";
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion

          if (test5 > 0) {
            // alert(xhr.responseText);
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            setPidData(test1);

            // var convert = Buffer.from(test1).toString("base64")
            // const ReplacePid = convert.replaceAll('/n', '')
            // const FinalPid = ReplacePid.replaceAll('/r', '')
            // console.log(FinalPid);
            // alert("Captured Successfully");
            // var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            //  setPidData(JSON.parse(result1));
            // callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  function mantraCapture(isKyc) {
    var url = `https://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="${Wadh}" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>`;
    } else {
      PIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="${Wadh}" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>`;
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion
          var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
          setPidData(JSON.parse(result1));
          if (test5 > 0) {
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            alert(xhr.responseText);
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            setPidData(test1);
            // alert("Captured Successfully");
            //var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            //setPidData(JSON.parse(result1));
            // callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  const capture = () => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    } else if (
      device == 0 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      mantraCapture();
    } else if (
      device == 1 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      morphoCapture();
    } else {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    }
  };

  const Biometric = () => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0 ||
      pidData == null
    ) {
      // alert("Please choose device or check device is ready or not");
      return;
    }
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    const PID = Buffer.from(pidData).toString("base64");
    const ReplacePid = PID.replaceAll("/n", "");
    const FinalPid = ReplacePid.replaceAll("/r", "");

    var raw = JSON.stringify({
      p1: formValues.Mobile.trim(),
      p2: KycToken,
      p3: formValues.AgentrefId.trim(),
      p4: formValues.Adhar.trim(),
      p5: "0",
      p6: "",
      p7: FinalPid,
      p8: ChooseFinger,
      p9: deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
      p10: "",
      p11: "",
      p12: "6",
      p13: "",
      p14: "Y",
      p15: "EKYC",
      p16: "null",
      p17: "Authentication",
      p18: Wadh,
      aname: formValues.FullName.trim(),
      aemail: formValues.Email.trim(),
      adob: dateToStr(startDate),
      agender: formValues.Gender.trim(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://apitranxt.paynnow.com/api/kyc/AadhaarBiometricKYCAgent",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSnackbarState({
          ...snackbarState,
          error: "",
          success: result.responsemsg,
        });
        if (result.responseCode == "00") {
          cogoToast.success(result.responseMsg);
          eKycUpdateOnServer();
        } else {
          cogoToast.info(result.responseMsg);
        }
        setTransactionLoaderState(false);
        if (result.responseCode == "00") {
          swal(
            result.responseMsg ? result.responseMsg : "Success",
            result.responseMsg,
            "success"
          );
        } else {
          swal(
            result.responseMsg ? result.responseMsg : "Failed",
            result.responseMsg,
            "error"
          );
        }
      })
      .catch((error) => {
        swal("Failed", "Server Failure", "error");
        setTransactionLoaderState(false);
      });
  };

  const eKycUpdateOnServer = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Bumppyagentcode: formValues.AgentrefId,
        mobileno: formValues.Mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/Ekyccodeupdate.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const StateList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic cmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ=="
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userName: "string",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://apitranxt.paynnow.com/api/operator/Getstate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setStates(result.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    Login();
    StateList();
    discoverDevice();
  }, []);

  const Finger = [
    {
      label: "Right Little",
      value: "10~RightLittle",
    },
    {
      label: "Right Ring",
      value: "9~RightRing",
    },
    {
      label: "Right Middle",
      value: "8~RightMiddle",
    },
    {
      label: "Right Index",
      value: "7~RightIndex",
    },
    {
      label: "Right Thumb",
      value: "6~RightThumb",
    },
    {
      label: "Left Thumb",
      value: "5~LeftThumb",
    },
    {
      label: "Left Index",
      value: "4~LeftIndex",
    },
    {
      label: "Left Middle",
      value: "3~LeftMiddle",
    },
    {
      label: "Left Ring",
      value: "2~LeftRing",
    },
    {
      label: "Left Little",
      value: "1~LeftLittle",
    },
  ];

  const fetchUserKycData = async (agentId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        setKycData(data.data[0]);
        if (data.data[0].dateofbirth == "") {
          setStartDate(new Date());
        } else {
          setStartDate(new Date(data.data[0].dateofbirth));
        }

        fillInitialData(data.data[0], agentId);
      }
    } catch (error) {}
  };

  
  const fillInitialData = async (data, agentId) => {
    const gender = data.gender.toString().toLowerCase();

    setFormValues({
      ...formValues,
      FullName: data.userfname + " " + data.userlname,
      Gender: gender == "male" ? "M" : gender == "female" ? "F" : "O",
      Mobile: data.usermobile,
      Email: data.emailid,
      Title: "",
      Pincode: "",
      PAN: data.pancardno,
      State: "",
      Adhar: data.aadharcardno,
      ShopName: data.shopname,
      AgentrefId: agentId,
    });
  };

  useEffect(() => {
    // fetchUserKycData();
    getNextAgentId();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div
            className="p-3"
            style={{ fontFamily: "auto", minHeight: "100vh" }}
          >
            <div
              className="card p-0"
              style={{ backgroundColor: "aliceblue", textAlign: "center" }}
            >
              <Button
                onClick={() => {
                  setOtpToken("");
                  history.push("/");
                }}
                style={{ maxWidth: "10rem", margin: "1rem 0rem" }}
              >
                Back To Home{" "}
              </Button>
              <div
                className="p-2 bg-primary"
                style={{
                  boxShadow:
                    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                <strong className="text-light">AGENT E-KYC</strong>
              </div>
              {!OtpToken ? (
                <div className="card-body p-0">
                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Agent ID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Agent Reference Id"
                        name="AgentrefId"
                        value={formValues.AgentrefId}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <p style={{ color: "red" }}>{formErrors.AgentrefId}</p>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile No"
                        name="Mobile"
                        value={formValues.Mobile}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.Mobile}</p>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Aadhaar Number
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter your Adhar no."
                        name="Adhar"
                        value={formValues.Adhar}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.Adhar}</p>
                    </Col>

                    <Col md={2} lg={2} sm={6}>
                      <Form.Label className="text-left w-100">Title</Form.Label>
                      <Form.Control
                        as="select"
                        name="Title"
                        value={formValues.Title}
                        onChange={handleChange}
                      >
                        <option selected>Title</option>
                        <option>Mr</option>
                        <option>Mrs</option>
                        <option>Miss</option>
                      </Form.Control>
                      <p style={{ color: "red" }}>{formErrors.Title}</p>
                    </Col>
                    <Col md={4} lg={4} sm={4}>
                      <Form.Label className="text-left w-100">
                        Full Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your Full name here"
                        name="FullName"
                        value={formValues.FullName}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.FullName}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        PAN Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your PAN no."
                        name="PAN"
                        value={formValues.PAN}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.PAN}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Email Id"
                        name="Email"
                        value={formValues.Email}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.Email}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Gender
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="Gender"
                        value={formValues.Gender}
                        onChange={handleChange}
                      >
                        <option selected>Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </Form.Control>
                      <p style={{ color: "red" }}>{formErrors.Gender}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Date of Birth
                      </Form.Label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        value={startDate}
                        selected={startDate}
                        todayButton="Today"
                        dateFormat="dd/MM/yyyy"
                        onChange={Datehandler}
                        className="form-control"
                        name="DOB"
                      />
                      <p style={{ color: "red" }}>{formErrors.DOB}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12} className="mt-2">
                      <Form.Label className="text-left w-100">
                        Pincode
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Postal pincode"
                        name="Pincode"
                        value={formValues.Pincode}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.Pincode}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12} className="mt-2">
                      <Form.Label className="text-left w-100">State</Form.Label>
                      <Form.Control
                        as="select"
                        name="State"
                        value={formValues.State}
                        onChange={handleChange}
                      >
                        <option selected>Select State</option>
                        {States?.map((st) => (
                          <option>{st.stateName}</option>
                        ))}
                      </Form.Control>
                      <p style={{ color: "red" }}>{formErrors.State}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <Form.Label className="text-left w-100">
                        Shop Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Shop Name"
                        name="ShopName"
                        value={formValues.ShopName}
                        onChange={handleChange}
                      />
                      <p style={{ color: "red" }}>{formErrors.ShopName}</p>
                    </Col>

                    <Col md={6} lg={6} sm={12} className="mt-4">
                      <Button
                        className="btn-block"
                        variant="info"
                        onClick={handleSubmit}
                      >
                        Start Ekyc Process
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : !Wadh ? (
                <div className="card-body">
                  <div className="row">
                    <div className="col text-center">
                      <h5 className="mt-4 mb-3">Enter the OTP sent to you</h5>
                      <OTPInput
                        style={{ display: "dflex" }}
                        value={Otp}
                        onChange={setOtp}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                      />
                      <div className="align-items-center">
                        <Button
                          variant="info"
                          className="font-weight-bold mb-2 mt-3"
                          onClick={OtpVal}
                        >
                          Verify OTP
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <Form.Control
                        as="select"
                        className="border"
                        onChange={(e) => setChooseFinger(e.target.value)}
                      >
                        {Finger.map((F) => (
                          <option value={F.value}>{F.label}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      <Form.Control
                        as="select"
                        className="border"
                        onChange={(e) => {
                          setDevice(e.target.value);
                        }}
                      >
                        <option>--Please Select--</option>
                        <option value={1}>Morpho MSO 1300</option>
                        <option value={0}>Mantra MFS 100</option>
                        <option disabled={true}>Startek FM220U</option>
                        <option disabled={true}>Precision</option>
                      </Form.Control>
                    </Col>
                    <Col md={6} lg={6} sm={12} className="mt-4">
                      <Button
                        variant="outline-success"
                        className="form-control"
                        onClick={capture}
                      >
                        Capture Biometric Data
                      </Button>
                    </Col>
                    <Col md={6} lg={6} sm={12} className="mt-4">
                      <Button
                        variant="outline-success"
                        className="form-control"
                        onClick={Biometric}
                      >
                        Submit
                      </Button>
                    </Col>

                    <Col sm={12}>
                      {snackbarState.success != "" && (
                        <div className="alert alert-success" role="alert">
                          {snackbarState.success}
                        </div>
                      )}
                      {snackbarState.error != "" && (
                        <div className="alert alert-danger" role="alert">
                          {snackbarState.error}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default EKYC;
