import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import {
  AddSalary,
  AddSalaryAdmin,
  DocumentModal,
  DocumentModalAdmin,
  EditEmployeeModal,
  EditEmployeeModalAdmin,
  UpdateSalaryModal,
  UpdateSalaryModalAdmin,
} from "./Modal";
import swal from "sweetalert";
import { Badge } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { billpunchbasicauth } from "../../utils/Credentials";

const arr = [{ label: "Name", value: "Name" }];

const AddEmployee = () => {
  const history = useHistory();
  const { user,setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [department, setDepartment] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const { password , username } = billpunchbasicauth;
  const [departmentData, setDepartmentData] = useState([]);
  const [AddSalaryModalOpen, setAddSalaryModalOpen] = useState(false);
  const [updateSalaryModalOpen, setUpdateSalaryModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [docmodal, setdocmodal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    email: "",
    address: "",
    designation: "",
    wphone: "",
    salary: "",
    date: "",
    aadharno: "",
    department: "",
    dob: "",
    panno: "",
    epf_no: "",
    joiningdate: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    mobile: "",
    email: "",
    address: "",
    designation: "",
    salary: "",
    date: "",
    aadharno: "",
    department: "",
    dob: "",
    panno: "",
    epf_no: "",
    joiningdate: "",
  });
  const validateForm = () => {
    console.log("Validating form...");
    const newErrors = {};

    if (formData.firstname.trim() === "") {
      newErrors.firstname = "First Name is required";
    }

    if (!formData.lastname || formData.lastname.trim() === "") {
      newErrors.lastname = "Last Name is required";
    }

    if (!formData.mobile || formData.mobile.trim() === "") {
      newErrors.mobile = "Mobile number is required";
    }

    if (!formData.email || formData.email.trim() === "") {
      newErrors.email = "Email is required";
    }

    if (formData.address.trim() === "") {
      newErrors.address = "Address is required";
    }

    if (formData.designation.trim() === "") {
      newErrors.designation = "Designation is required";
    }

    if (formData.dob.trim() === "") {
      newErrors.dob = "Date of Birth is required";
    }

    if (formData.panno === "") {
      newErrors.panno = "PAN Number is required";
    }

    if (formData.department === "") {
      newErrors.department = "Department is required";
    }

    if (formData.aadharno === "") {
      newErrors.aadharno = "Aadhar Number is required";
    }

    if (formData.epf_no === "") {
      newErrors.epf_no = "EPF Number is required";
    }

    // if (!formData.joiningdate) {
    // newErrors.joiningdate = "Joining Date is required";
    // }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Calling API...");
      handleEditSubmit(selectedEmployee.empid);
      console.log("API call complete");
    }

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const getEmployeeList = async (selectedDepartment) => {
    console.log("getEmployeeList Department Parameter:", selectedDepartment);

    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({  firmid:  user.firm_id,});

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        // Set the department data received from the API in the state
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const openDocumentUploadModal = (id ,firstname,lastname ) => {
    sessionStorage.setItem('employeeName', `${firstname} ${lastname}`);

    setSelectedEmployeeId(id);
    setdocmodal(true);
  };

  const closeDocumentUploadModal = () => {
    setdocmodal(false);
  };
  const openEditModal = (id, empid) => {
    setSelectedEmployee(id);
    setSelectedEmployeeId(empid);
    console.log(empid, "abhijeet");

    setIsModalOpen(true);
  };
  const openAddSalaryModal = () => {
    setAddSalaryModalOpen(true);
  };
  const closeAddSalaryModal = () => {
    setAddSalaryModalOpen(false);
  };
  const openUpdateSalaryModal = (empid ,firstname, lastname) => {
   
    setSelectedEmployeeId(empid);
    sessionStorage.setItem('employeeName', `${firstname} ${lastname}`);

    setUpdateSalaryModalOpen(true);
  };

  const closeUpdateSalaryModal = () => {
    setUpdateSalaryModalOpen(false);
  };
  const closeEditModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleEditSubmit = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        empid: selectedEmployeeId,

        firm_id: user.firm_id,
        firstname: formData.firstname,
        middlename: formData.middlename,
        lastname: formData.lastname,
        address: formData.address,
        mobile: formData.mobile,
        email: formData.email,
        designation: formData.designation,
        epf_no: formData.epf_no,
        department: formData.department,
        joiningdate: formData.joiningdate,
        emp_type: formData.emp_type,
        dob: formData.dob,
        aadharno: formData.aadharno,
        panno: formData.panno,
      });
      console.log(formData.id, "adiii");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/editEmployee `,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        getEmployeeList();
        swal("Success", data.result, "success");
        closeEditModal();
      } else {
        getEmployeeList();
        closeEditModal();
        swal("Error", data.result, "warning");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteEmployee = (empid) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Employee Details!",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        handleDelete(empid);
      }
    });
  };
  const handleDelete = async (empid) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        empId: empid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/deleteEmployee `,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        getEmployeeList();
        swal("Success", data.result, "success");
      } else {
        getEmployeeList();
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "An error occurred while deleting the tower.", "error");
    }
  };
  // edit

  useEffect(() => {
    getEmployeeList();
    getDepartmentList();
  }, []);
  const filteredData = data.filter((item) => {
    if (department === "") {
      return true;
    } else {
      return item.department === department;
    }
  });

  return (
    <>
      <div
        style={{
          borderTopLeftRadius: ".4rem",
          borderTopRightRadius: ".4rem",
        }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
          <div
            className="card p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row mt-md-3 mt-sm-3 mx-sm-2 mb-md-1 mb-sm-1">
              <div className="col-md-5 col-sm-4">
                <h5
                  className="d-flex align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Employee List
                </h5>
              </div>
              <div className="col-md-7 col-sm-8 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    width: "30% ",
                    border: "1px #ced4da solid",
                    paddingLeft: "10px",
                    height: "2rem",
                  }}
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <div className="col">
                  <select
                    style={{
                      width: "9rem",
                      height: "2rem",
                      borderRadius: ".4rem",
                      border: "1px #ced4da solid",
                    }}
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}

                    // onChange={(e) => {
                    // setDepartment(e.target.value);
                    // console.log("Selected Department:", e.target.value); // Add this line to debug
                    // getEmployeeList(e.target.value);
                    // }}
                  >
                    <option value="">All Department</option>
                    {departmentData.map((dept) => (
                      <option key={dept.id} value={dept.cat1}>
                        {dept.cat1}
                      </option>
                    ))}
                  </select>
                </div>
                <span style={{ marginLeft: "10px" }}>
                  <Link
                    to="/retailer/AddEmployee"
                    type="button"
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "9rem",
                      fontSize: ".8rem",
                    }}
                  >
                    Add Employee
                  </Link>
                </span>
                <span style={{ marginLeft: "10px" }}>
                  <Link
                    type="button"
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "7rem",
                      fontSize: ".8rem",
                    }}
                    onClick={openAddSalaryModal}
                  >
                    Add Salary
                  </Link>
                </span>
              </div>
            </div>
            <hr className="p-0 mx-md-1 m-0 p-0" />
            <table
              className="m-md-2"
              style={{
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              <thead className="mx-md-2">
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Emp Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Designation
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Department
                  </th>
                  {/* <th
 className="text-center"
 style={{
 color: "#000",
 background: "#fff",
 border: "none",
 }}
 >
 Employee Type
 </th> */}
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Mobile
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Salary
                  </th>
                  <th
                    className="text-center"
                    style={{
                      color: "#000",
                      background: "#fff",
                      border: "none",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="mx-md-2">
                {filteredData.map((item) => {
                  const fullName =
                    `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} }`.toLowerCase();
                  if (
                    !searchQuery ||
                    fullName.includes(searchQuery.toLowerCase())
                  ) {
                    return (
                      <tr
                        key={item.id}
                        style={{ borderBottom: "1px solid #ced4da" }}
                      >
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.empcode}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.firstname} {item.lastname}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.designation}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.department}
                        </td>
                        {/* <td
 className="text-center align-middle"
 style={{ border: "none", fontSize: "1rem" }}
 >
 {item.emptype}
 </td> */}
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          {item.mobile}
                        </td>{" "}
                        <td
                          className="text-center"
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <Badge
                              type="button"
                              className="btn btn-sm "
                              style={{
                                background: "white",
                                color: "#0074D9",
                                border: " 1px solid #0074D9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "2.5em",
                                width: "8em",
                              }}
                              onClick={() => openUpdateSalaryModal(item.empid, item.firstname, item.lastname)}
                            >
                              Update Salary
                            </Badge>
                          </div>
                        </td>
                        <td
                          className="text-center align-middle "
                          style={{ border: "none", fontSize: "1rem" }}
                        >
                          <Link style={{ marginRight: "5px" }}>
                            <img
                              alt=""
                              src={require("../../../assets/images/pms/document_upload.svg")}
                              onClick={() =>
                                openDocumentUploadModal(item.empid,  item.firstname, item.lastname)}
                            />
                          </Link>
                          <Link style={{ marginRight: "5px" }}>
                            <img
                              alt=""
                              src={require("../../../assets/images/pms/Edit_icon.svg")}
                              onClick={() => openEditModal(item, item.empid)}
                            />
                          </Link>
                          <Link>
                            <img
                              alt=""
                              src={require("../../../assets/images/pms/Delete_icon.svg")}
                              onClick={() => deleteEmployee(item.empid)}
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
            <DocumentModalAdmin
              show={docmodal}
              onHide={closeDocumentUploadModal}
              selectedEmployeeId={selectedEmployeeId}
              getEmployeeList={getEmployeeList}
              data={setData}
            />

            <EditEmployeeModalAdmin
              show={isModalOpen}
              onHide={() => setIsModalOpen(false)}
              employeeData={selectedEmployee}
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              validateForm={validateForm}
              handleEditSubmit={handleEditSubmit}
              empid={selectedEmployeeId}
              getEmployeeList={getEmployeeList}
            />
            <UpdateSalaryModalAdmin
              show={updateSalaryModalOpen}
              onHide={closeUpdateSalaryModal}
              empid={selectedEmployeeId}
              getEmployeeList={getEmployeeList}
            />
            <AddSalaryAdmin
              show={AddSalaryModalOpen}
              onHide={closeAddSalaryModal}
              getEmployeeList={getEmployeeList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
