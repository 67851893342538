import { DOMAIN } from "./Client";
import ClientConfig from "./ClientConfig";

const config = ClientConfig(DOMAIN)

export const COMPANY_NAME = config.COMPANY_NAME;
export const COMPANY_NAME_NAVBAR = config.COMPANY_NAME_NAVBAR;
export const COMPANY_LOGIN_NAME = config.COMPANY_LOGIN_NAME;
export const PRIVACY_POLICY_COMPANY_NAME = config.PRIVACY_POLICY_COMPANY_NAME;
export const QR_TITLE_COMPANY_NAME = config.QR_TITLE_COMPANY_NAME;
export const CERTIFICATE_COMPANY_NAME = config.CERTIFICATE_COMPANY_NAME;
export const OFFLINE_TEXT = config.OFFLINE_TEXT
export const ONLINE_TEXT = config.ONLINE_TEXT;
export const LOGIN_COMPANY_NAME = config.LOGIN_COMPANY_NAME;
export const CERTIFICATE_ADDRESS = config.CERTIFICATE_ADDRESS;
export const CERTIFICATE_WEBSITE = config.CERTIFICATE_WEBSITE;

export const SUPPORT_MOBILE_NUMBER = config.SUPPORT_MOBILE_NUMBER;
export const SUPPORT_EMAIL = config.SUPPORT_EMAIL;
