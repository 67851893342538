import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";

const Epan = () => {
  const [token, setToken] = useState("");
  const { user } = useContext(GlobalContext);

  const getAccessToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        retailerID: user.ReferId,
        firmName: "BUMPPY",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Services/EPAN/authenticate",
        requestOptions
      );
      const data = await res.json();
      if (data.statusCode == 1) {
        setToken(data.data.token);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);
  console.log(token);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-5">
        <div
          style={{
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">E-PAN Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                E-PAN offers the convenience of easy and secure access to your
                PAN details online, making it simpler to carry out financial
                transactions and tax-related activities.
              </p>
              <h6>Apply now for E-PAN</h6>
              <form
                action="https://digipaydashboard.religaredigital.in/Login/Authenticate"
                method="Post"
              >
                <input type="hidden" name="authentication" value={token} />{" "}
                <button
                  className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                  type="submit"
                >
                  {" "}
                  Apply for E PAN
                </button>
              </form>
            </div>
            <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "15rem" }}
                src={require("../../../assets/images/epan.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Epan2 = () => {
  const [token, setToken] = useState("");
  const { user } = useContext(GlobalContext);

  const getAccessToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        retailerID: user.ReferId,
        firmName: "BUMPPY",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Services/EPAN/authenticate",
        requestOptions
      );
      const data = await res.json();
      if (data.statusCode == 1) {
        setToken(data.data.token);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);
  console.log(token);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-4">
        <div
          style={{
            width: "100%",
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">E-PAN Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                E-PAN offers the convenience of easy and secure access to your
                PAN details online, making it simpler to carry out financial
                transactions and tax-related activities.
              </p>
              <h6>Apply now for E-PAN</h6>
              <form
                action="https://digipaydashboard.religaredigital.in/Login/Authenticate"
                method="Post"
              >
                <input type="hidden" name="authentication" value={token} />{" "}
                <button
                  className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                  type="submit"
                  style={{
                    borderRadius: ".2rem",
                    fontWeight: "bold",
                    width: "10rem",
                    border: "none",
                    color: "#ffff",
                    fontSize: "1rem",
                    height: "2em",
                    display: "flex",
                  }}
                >
                  {" "}
                  Apply
                </button>
              </form>
            </div>
            <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "20rem" }}
                src={require("../../../assets/images/epan.png")}
              />
            </div>
          </div>
          <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
            <h5>Information</h5>
            <p style={{ color: "#000", fontSize: "1rem" }}>
              <b> E-PAN:</b> it simplifies the process of obtaining and managing
              their Permanent Account Number (PAN), eliminating the need for
              physical cards and paperwork. This digital version provides easy
              access to PAN details, allowing customers to conveniently verify
              their identity for various financial and tax-related transactions.
            </p>
            <p style={{ color: "#000", fontSize: "1rem" }}>
              <b> Physical PAN: </b> Physical PAN (Permanent Account Number)
              card serves as an essential government-issued identification
              document in India. It plays a pivotal role in various financial
              and tax-related transactions. Customers appreciate the tangible
              nature of the card, as it provides a physical proof of their
              identity and PAN details.
            </p>
            <p style={{ color: "#000", fontSize: "1rem" }}>
              <b> Verify with OTP:</b> Receive a one-time password on your
              registered mobile number and enter it for verification. If you
              select OTP, proceed with OTP only.
            </p>
            <p style={{ color: "#000", fontSize: "1rem" }}>
              <b> Verify with Biometric:</b> Use biometric authentication to
              verify your identity. If you select biometric, proceed with
              biometric verification only.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Epan2;
