import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import PGSettlementReportTable from "./ProceessalaryTable";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import { ExportToExcel, excelMapping, twoDecimalPlaces } from "../../utils/utilFuctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { billpunchbasicauth } from "../../utils/Credentials";
export const columns = [
  {
    dataField: "ind + 1",
    text: "Sr.",
  },

  {
    dataField: "employeename",
    text: "Name",
  },
  {
    dataField: "category",
    text: "Category",
  },
  {
    dataField: "salary_amount",
    text: "Amount",
  },
  {
    dataField: "pf",
    text: "EPF",
  },
  {
    dataField: "month",
    text: "Month",
  },
  {
    dataField: "indate",
    text: "Date",
  },

  {
    dataField: "status",
    text: "Status",
  },
];
const PayrollAdmin = () => {
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const { password , username } = billpunchbasicauth;
 
  const [showModal, setShowModal] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showExcelUploadModal, setShowExcelUploadModal] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    employeeId: "",
    month: "",
    category: "",
    amount: "",
    pf:""
  });
  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const categories = ["Salary", "Bonus", "Expenses"];
  const openExcelUploadModal = () => setShowExcelUploadModal(true);
  const openModal = () => setShowModal(true);
  const openBulkModal = () => setShowBulkModal(true);
  const { user,setTransactionLoaderState } = useContext(GlobalContext);

  
 const handleChange = (e) => {
    if (e.target.name === "employeeId") {
      const selectedEmployee = employeesData.find((employee) => employee.empid === e.target.value);
      
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        category: selectedEmployee ? "Bonus" : "", 
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  
  const ADDSALARY = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        empid: formData.employeeId,
        salaryamount: formData.amount,
        category: formData.category,
        pf: formData.pf,
        month: formData.month,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/insertSalaryData`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setShowModal(false);
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const GetAllEmployee = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
       var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeesData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedIds = [...selectedIds];
    const index = updatedSelectedIds.indexOf(id);

    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }

    setSelectedIds(updatedSelectedIds);
  };
  const handleSelectAllChange = () => {
    const allIds = data.map((e) => e.id);
    const updatedSelectedIds =
      selectedIds.length === allIds.length ? [] : allIds;
    setSelectedIds(updatedSelectedIds);
  };
  const handleProcessAllClick = () => {
    if (selectedIds.length === 0) {
      swal("Please select at least one item to process.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };

  // upload excel
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const mapExcelDataToApiFormat = (excelEntry) => {
    const monthMap = {
      jan: "01",
      feb: "02",
      mar: "03",
      apr: "04",
      may: "05",
      jun: "06",
      jul: "07",
      aug: "08",
      sep: "09",
      oct: "10",
      nov: "11",
      dec: "12",
    };

    return {
      empid: excelEntry["Emp code"],
      total_present: excelEntry["Total Present "],
      total_absent: excelEntry["Total Absent"],
      month: monthMap[excelEntry["Month"].toLowerCase()],
      employee_code: excelEntry["Matching Factor"],
    };
  };

  const handleUploadExcel = async () => {
    if (selectedFile) {
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const convertedData = XLSX.utils.sheet_to_json(worksheet, {
            raw: false,
          });

          console.log("Formatted JSON Data:", convertedData);

          setExcelData(convertedData);

          // Send each entry to ADDSALARYEXCEL API
          convertedData.forEach(async (excelEntry) => {
            const apiData = mapExcelDataToApiFormat(excelEntry);

            try {
              var myHeaders = new Headers();
              myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
              );
              myHeaders.append("Content-Type", "application/json");
             
              var raw = JSON.stringify(apiData);

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              const res = await fetch(
                `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/insertAttendanceData`,
                requestOptions
              );
              const data = await res.json();
              if (data.status === "0") {
                swal("Success", data.msg, "success");
              } else {
                swal("Failed", data.msg, "error");
              }
              setExcelData(convertedData);
            } catch (error) {
              console.log("error", error);
            }
          });
        };
      } catch (error) {
        console.error("Error uploading Excel file:", error);
      }
    } else {
      swal("Error", "Please select an Excel file for upload.", "warning");
    }
  };

  const handleCloseModal = () => {
    setShowExcelUploadModal(false);
    setSelectedFile(null);
    setExcelData(null);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };
  const handleGenerateSalary = async (id) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     var raw = JSON.stringify({
        empid: "string",
        month: "string",
        year: "string",
        category: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/generateSalaryRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Salary Generated Successfully", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log(error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetAllEmployee();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "0.5rem" }} className="bg-light">
          <div
            style={{ borderRadius: "10px" }}
            className="card  m-0 mt-2 ml-1 mr-1 p-0"
          >
            <div className="card-body m-0 p-2">
              <div className="row">
                <h5
                  className="col px-3 pb-2"
                  style={{
                    color: "#4267B2",
                  }}
                >
                  Process Salary
                </h5>
                <div className="col d-flex  justify-content-end">
                  <div className="mx-1">
                    {/* <Link>
                      <Button
                        className="d-flex  pl-2 align-items-center"
                        style={{
                          height: "2rem",
                          fontSize: "1rem",
                          borderRadius: ".4rem",
                        }}
                        onClick={openExcelUploadModal}
                      >
                        Excel Upload
                      </Button>
                    </Link> */}
                    
                  </div>
                  <div className="col d-flex justify-content-end">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `salary_ledger${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../../assets/images/exportexcel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>
                  {/* <Button
                    className="d-flex align-items-center"
                    style={{
                      height: "2rem",
                      fontSize: "1rem",
                      borderRadius: ".4rem",
                    }}
                    onClick={openModal}
                  >
                    Add Salary
                  </Button> */}
                  <div className="mx-1">
                    {/* <Link to="/retailer/BulkSalaryProcess">
                      <Button
                        className="d-flex align-items-center"
                        style={{
                          height: "2rem",
                          fontSize: "1rem",
                          borderRadius: ".4rem",
                        }}
                      >
                        Bulk Salary Proceed
                      </Button>
                    </Link> */}
                  </div>
                  {/* <Button className="d-flex align-items-center" style={{ height: "2rem", fontSize:"1rem"  }}  onClick={openBulkModal}>
                   Bulk Add Salary
                  </Button> */}
                </div>
              </div>

              <PGSettlementReportTable />
            </div>
          </div>

          <Modal show={showBulkModal} onHide={() => setShowBulkModal(false)}>
            <Modal.Header className="row m-0 " closeButton>
              <Modal.Title className="col">Add Bulk Salary</Modal.Title>
              <div className="col d-flex justify-content-end">
                <Button onClick={handleProcessAllClick}>Process All</Button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <table
                className="my-table transparent-table text-center border-bottom"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  border: "0px",
                  overflow: "auto",
                }}
              >
                <tr className="borderless-table text-center border-bottom">
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleSelectAllChange}
                      checked={selectedIds.length === data.length}
                    />
                  </th>
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {"Sr"}
                  </th>
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    Name
                  </th>

                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    Month
                  </th>
                </tr>
                {data.map((e, ind) => (
                  <tr className="border-bottom">
                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(e.id)}
                        checked={selectedIds.includes(e.id)}
                      />
                    </td>
                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {ind + 1}
                    </td>
                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      <div>{e.employee_name}</div>
                    </td>

                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      {twoDecimalPlaces(e.amount)}
                    </td>

                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      <div>{e.month}</div>
                    </td>
                  </tr>
                ))}
              </table>
            </Modal.Body>
          </Modal>

          <Modal
            show={showProcessAllModal}
            onHide={() => setShowProcessAllModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="col" controlId="monthDropdown">
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {" Select Month"}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="month"
                    value={formData.month}
                    onChange={handleChange}
                  >
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="col" controlId="categoryDropdown">
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {` Select category`}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Salary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="row">
                  <Form.Group className="col" controlId="employeeDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Select Employee`}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="employeeId"
                      value={formData.employeeId}
                      onChange={handleChange}
                    >
                      {employeesData.map((employee) => (
                        <option key={employee.empid} value={employee.empid}>
                          {employee.firstname}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col" controlId="monthDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {" Select Month"}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="month"
                      value={formData.month}
                      onChange={handleChange}
                    >
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col" controlId="categoryDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Select category`}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col" controlId="amountInput">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="text"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                </div>
  <div className="row">
                 
                  <Form.Group className="col" controlId="amountInput">
                    <Form.Label>EPF</Form.Label>
                    <Form.Control
                      type="text"
                      name="pf"
                      value={formData.pf}
                      onChange={handleChange}
                      placeholder="Enter EPF Amount"
                    />
                  </Form.Group>
                </div>
                <Button variant="primary" onClick={ADDSALARY}>
                  Save
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={showExcelUploadModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Excel Upload Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="m-2">
                <div
                  className="mx-md-3 mx-sm-2"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div
                    className={`file-drop-area ${isDragging ? "dragging" : ""}`}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <label className="file-input-label">
                        Select Excel or drop Excel here
                        <input
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileChange}
                          className="file-input"
                        />
                      </label>
                      {selectedFile ? (
                        <p>
                          File: {selectedFile.name}{" "}
                          <span
                            onClick={() => setSelectedFile(null)}
                            style={{ cursor: "pointer", color: "red" }}
                          >
                            (Remove)
                          </span>
                        </p>
                      ) : (
                        <p>No file chosen</p>
                      )}
                    </div>
                  </div>
                  <div className="mt-3" style={{ display: "flex" }}>
                    <button
                      className="btn btn-primary d-flex align-items-center"
                      type="button"
                      style={{
                        height: "2rem",
                        borderRadius: ".3rem",
                        fontSize: ".8rem",
                        marginRight: "10px",
                      }}
                      onClick={handleUploadExcel}
                    >
                      Upload Excel
                    </button>
                    <button
                      className="btn btn-primary d-flex align-items-center"
                      type="button"
                      style={{
                        height: "2rem",
                        borderRadius: ".3rem",
                        fontSize: ".8rem",
                      }}
                      // onClick={handleGenerateSalary}
                    >
                      Generate Salary
                    </button>
                  </div>
                </div>

                {/* {excelData && (
                  <div>
                    <h3>Formatted JSON Data:</h3>
                    <pre>{JSON.stringify(excelData, null, 2)}</pre>
                  </div>
                )} */}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default PayrollAdmin;
