import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { FaFlag } from "react-icons/fa";

const WorkDashboardslider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [taskData, setTaskData] = useState([]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? taskData.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === taskData.length - 1 ? 0 : prevIndex + 1));
  };

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  
  const getTaskData = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
          type: "string",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeTaskStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getTaskData();
  }, []);

  const styles = {
    container: {
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden'
    },
    slideContainer: {
      display: 'flex',
      transition: 'transform 0.5s ease-in-out',
    },
    slide: {
      flex: '0 0 100%',
      padding: "1rem",
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      overflowX: 'auto', 
    },
    controls: {
      marginTop: '20px',
      marginBottom: '10px',
    },
    table: {
      width: '100%', 
      tableLayout: 'fixed',
    },
    tableheader:{
        color: "#0074D9",
        background: "#fff",
        border: "none",
        borderBottom: "1px solid lightgrey",
    },
    tabledata:{
        border: "none", background: "#fff" ,
        fontSize: ".9rem",
    }
  };

  return (
    <div style={styles.container}>
      <div className="row">
        <h4 className="col">Work Dashboard</h4>
        {taskData.length > 0 && (
          <div className="col d-flex justify-content-end">
            <div className="px-2">
              <Button className="btn btn-warning btn-sm" onClick={handlePrevClick}>Prev.</Button>
            </div>
            <div>
              <Button className="btn btn-warning btn-sm" onClick={handleNextClick}>Next</Button>
            </div>
          </div>
        )}
      </div>
      
      <div style={{ ...styles.slideContainer, transform: `translateX(-${currentIndex * 100}%)` }}>
        {taskData.map((task, index) => (
          <div className="table-responsive" key={index} style={styles.slide}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableheader}>Name</th>
                  <th style={styles.tableheader}>Description</th>
                  <th style={styles.tableheader}>Priority</th>
                  <th style={styles.tableheader}>Assignee</th>
                  <th style={styles.tableheader}>Start Date</th>
                  <th style={styles.tableheader}>End Date</th>
                  <th style={styles.tableheader}>Status</th>
                </tr>
              </thead>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td style={styles.tabledata}>{task.taskname}</td>
                  <td style={styles.tabledata}>{task.task_description}</td>
                  <td style={styles.tabledata}>
                    {task.task_priority === "High" && (
                      <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
                    )}
                    {task.task_priority === "Urgent" && (
                      <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
                    )}
                    {task.task_priority === "Normal" && (
                      <FaFlag style={{ marginRight: "5px", color: "blue" }} />
                    )}
                    {task.task_priority === "Low" && (
                      <FaFlag style={{ marginRight: "5px", color: "grey" }} />
                    )}
                    {task.task_priority === "Clear" && (
                      <img
                        src={require("../../../assets/images/flag_1.png")}
                        style={{ marginRight: "5px", width: ".8rem" }}
                      />
                    )}
                    {task.task_priority}
                  </td>
                  <td style={styles.tabledata}>{task.task_assignee}</td>
                  <td style={styles.tabledata}>
                    {new Date(task.task_startdate).toLocaleDateString() || "NA"}
                  </td>
                  <td style={styles.tabledata}>
                    {new Date(task.task_enddate).toLocaleDateString() || "NA"}
                  </td>
                  <td style={styles.tabledata}>
                    {task.status === "O" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Overdue
                      </span>
                    )}
                    {task.status === "P" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: "#f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {task.status === "C" && (
                      <span
                        className="p-1"
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkDashboardslider;
