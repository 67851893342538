import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Button, InputGroup, Overlay, Popover } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { uniqueID2 } from "../../utils/utilFuctions";
import { FaBirthdayCake, FaEnvelope, FaMapMarkerAlt, FaUser } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

const Employeesetting = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <h4 className="pt-2 pb-2 px-2">Settings</h4>
        <ul className="nav nav-tabs">
      
          <li className="nav-item">
            <button
              className={`nav-link  ${activeTab === 'tab1' ? 'active' : ''}`}
              style={activeTab === 'tab1' ? { border: '' } : {}}
              onClick={() => handleTabClick('tab1')}
            >
             <b>Key</b> 
            </button>
          </li>
       

        </ul>
        <div className="tab-content p-3">
          {activeTab === 'tab1' && <Key/>}
        </div>
      </div>
    </>
  );
};
 const Key = () => {
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const { password, username } = billpunchbasicauth;
    const location = useLocation();
    const [keyId, setKeyId] = useState("");
    const [keySecret, setKeySecret] = useState("");
    const [showKeySecret, setShowKeySecret] = useState(false); 

  
    const Getkey = async () => {
      setTransactionLoaderState(true);
      try {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
  
        const raw = JSON.stringify({
          firmid: user.firm_id,
          empid:user.userid,
        });
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetGeneratedKeys`,
          requestOptions
        );
        const data = await res.json();
        if (data.status === "0" && data.data.length > 0) {
          const { keyid, keysecret } = data.data[0];
          setKeyId(keyid);
          setKeySecret(keysecret);
        }
        setTransactionLoaderState(false);
      } catch (error) {
        console.log("error", error);
        setTransactionLoaderState(false);
      }
    };
  
    useEffect(() => {
      Getkey();
    }, []);
  
    const downloadCSV = () => {
      const csvContent = `data:text/csv;charset=utf-8,Key ID,Key Secret\n${keyId},${keySecret}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "key_id_and_key_secret.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const login = () => {
      const callbackUrl = `${window.location.origin}/identity`;
      const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
      window.location.href = redirectUrl; 
    };
    return (
     <>
     <div
        className="card p-2 col-md-5 col-10"
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", height: "63%" }}
      >
        <div style={{ width: "100%", textAlign: "center", paddingTop: "20px" }}>
          <h5 className="" style={{ color: "#000" }}>
            Key ID & Key Secret
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-md-3 mt-4">
          <div>
         
        

              <Form className="mt-4">
                <Form.Group controlId="formKeyId">
                  <Form.Label>Key ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Key ID"
                    value={keyId}
                    onChange={(e) => setKeyId(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formKeySecret">
                  <Form.Label>Key Secret</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showKeySecret ? "text" : "password"}
                      placeholder="Enter Key Secret"
                      value={keySecret}
                      onChange={(e) => setKeySecret(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowKeySecret(!showKeySecret)}
                    >
                      {showKeySecret ? "Hide" : "Show"}
                    </Button>
                  </InputGroup>
                  <Link
                  variant="secondary"
                  onClick={downloadCSV}
                  className="mt-2"
                >
                  Download ID & Secret
                </Link>
                </Form.Group>
             

               
              </Form>
           
          </div>
        </div>
      </div>
  
     </>
    );
  };
export default Employeesetting;
