import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Tabs, Tab, Button } from "react-bootstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import { ProgressBar } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { DROP_SHADOW } from "../utils/CommonConfig";
import Transactions, { Payin, Payout } from "./Transactions";
import { urls } from "./devUrls";
import Topup from "./Topup";
import SmartCollectVirtualAccountTable from "./SmartCollectVirtualAccountTable";
import SmartCollectPaymentsTable from "./SmartCollectPaymentsTable";
import Sidebarnew from "./Sidebarnew";
import { Input } from "semantic-ui-react";
import Chart from "chart.js";

const MyAccount = () => {
  useEffect(() => {
    // Bar Chart
  
    const amountDueBarData = {
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      datasets: [
        {
          label: "Profit",
          data: [39, 19, 25, 16, 31, 39, 12],
          backgroundColor: [
            "#663dff",
            "#663dff",
            "#663dff",
            "#663dff",
            "#663dff",
            "#663dff",
            "#663dff",
          ],

          borderWidth: 2,
          fill: true,
          barPercentage: 0.5,
        },
      ],
    };

    const amountDueBarOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: false,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: "black",
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      stepsize: 10,
    };
    const amountDueBarContext = document
      .getElementById("amountDueBarChart")
      .getContext("2d");
    const amountDueBarChart = new Chart(amountDueBarContext, {
      type: "bar",
      data: amountDueBarData,
      options: amountDueBarOptions,
    });
    
//commisiongraph

const commissionCreditBarData ={
  labels: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  datasets: [
    {
      label: "Profit",
      data: [39, 19, 25, 16, 31, 39, 12],
      backgroundColor: [
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
      ],

      borderWidth: 2,
      fill: true,
      barPercentage: 0.5,
    },
  ],
};
const commissionCreditBaroption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: false,
        },
      },
    ],
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: "black",
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0,
    },
  },
  stepsize: 10,
};
const commissionCreditBarcontext = document
  .getElementById("commissionCreditBarChart")
  .getContext("2d");
const commissionCreditBarChart = new Chart(commissionCreditBarcontext, {
  type: "bar",
  data: commissionCreditBarData,
  options: commissionCreditBaroption,
});


const RefundData = {
  labels: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  datasets: [
    {
      label: "Profit",
      data: [39, 19, 25, 16, 31, 39, 12],
      backgroundColor: [
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
        "#663dff",
      ],

      borderWidth: 2,
      fill: true,
      barPercentage: 0.5,
    },
  ],
};
const Refundoption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: false,
        },
      },
    ],
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: "black",
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0,
    },
  },
  stepsize: 10,
};
const Refundcontext = document
.getElementById("Refund")
.getContext("2d");
const refundchart = new Chart(Refundcontext, {
type: "bar",
data: RefundData,
options: Refundoption,
});


    // Doughnut Chart
    const usersDoughnutChartData = {
      datasets: [
        {
          data: [80, 34, 100],
          backgroundColor: ["#2B4DED", "#FF6A00", "#FFD1A7"],
          borderColor: ["#2B4DED", "#FF6A00", "#FFD1A7"],
        },
      ],
      labels: ["Pink", "Blue", "Yellow"],
    };

    const usersDoughnutChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      legend: {
        display: false,
      },
    };

    const usersDoughnutChartContext = document
      .getElementById("usersDoughnutChart")
      .getContext("2d");
    new Chart(usersDoughnutChartContext, {
      type: "doughnut",
      data: usersDoughnutChartData,
      options: usersDoughnutChartOptions,
    });
  }, []);

  const [details, setDetails] = useState({});
  let {
    myAccountInfo: info,
    setMyAccountInfo,
    logoutDeveloperLogin,
  } = useContext(GlobalContext);
  const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
  if (info == null) {
    info = {};
  }
  const history = useHistory();
  // const amountDueBarData = {
  //   labels: [
  //     "Day 1",
  //     "Day 2",
  //     "Day 3",
  //     "Day 4",
  //     "Day 5",
  //     "Day 6",
  //     "Day 7",
  //     "Day 8",
  //     "Day 9",
  //     "Day 10",
  //   ],
  //   datasets: [
  //     {
  //       label: "Profit",
  //       data: [39, 19, 25, 16, 31, 39, 12, 18, 33, 24],
  //       backgroundColor: [
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //       ],
  //       borderColor: [
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //         "#198ae3",
  //       ],
  //       borderWidth: 2,
  //       fill: true,
  //     },
  //   ],
  // };

  // const amountDueBarOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   scales: {
  //     yAxes: [
  //       {
  //         display: false,
  //         ticks: {
  //           beginAtZero: false,
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         display: false,
  //       },
  //     ],
  //   },
  //   legend: {
  //     display: false,
  //   },
  //   elements: {
  //     point: {
  //       radius: 0,
  //     },
  //     line: {
  //       tension: 0,
  //     },
  //   },
  //   stepsize: 10,
  // };

  // const usersDoughnutChartData = {
  //   datasets: [
  //     {
  //       data: [80, 34, 100],
  //       backgroundColor: ["#44ce42", "#b66dff", "#d8d8d8"],
  //       borderColor: ["#44ce42", "#b66dff", "#d8d8d8"],
  //     },
  //   ],

  //   // These labels appear in the legend and in the tooltips when hovering different arcs
  //   labels: ["Pink", "Blue", "Yellow"],
  // };

  // const usersDoughnutChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   animation: {
  //     animateScale: true,
  //     animateRotate: true,
  //   },
  //   legend: {
  //     display: false,
  //   },
  // };
  useEffect(() => {
    getAccountInfo();
  }, []);

  const getAccountInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(GetUserProfileInfoUrl, requestOptions);
      const data = await res.json();
      setMyAccountInfo(data.data);
    } catch (error) {}
  };

  if (info == null) {
    history.push("/error-pages/error-404");
    return;
  }

  return (
    <div style={{ background: "#f2f6fc" }}>
      <div
        // style={{ borderRadius: "22px", boxShadow: DROP_SHADOW }}
        className=" m-4 p-0"
      >
        <div style={{ background: "#fff" }}>
          {" "}
          <div
            className="row justify-content-between m-0 px-4 py-2"
            style={{ background: "" }}
          >
            <h2
              style={{
                margin: "0",
                fontWeight: "bolder",
              }}
            >
              My Account
            </h2>
            <button
              className="btn btn-primary mx-4"
              onClick={logoutDeveloperLogin}
              style={{ width: "7rem" }}
            >
              Log Out
            </button>

            <Sidebarnew details={details} />
          </div>
        </div>
        <hr className="p-0 m-0" />

        <div className="" style={{ background: "#f2f6fc" }}>
          <Tabs
            defaultActiveKey="Profile"
            id="uncontrolled-tab-example"
            style={{ background: "white" }}
          >
            <Tab
              eventKey="Profile"
              title="Profile"
              className="test-tab border-neglect"
            >
              <div
                style={{
                  padding: "10px",
                  background: "#E4EFFC",
                  border: "2px solid #3568FF",
                }}
                className="card m-1"
              >
                <h5 style={{ color: "#3568FF" }}>2 Step Verification</h5>
                <p style={{ color: "#3568FF" }}>
                  Add an extra layer of security to your account by using a
                  one-time verification code in addition to your password each
                  time you log in.
                </p>
              </div>
              <div className=" m-1 p-0">
                <div className="row p-1 mt-3" style={{ background: "white" }}>
                  <div className="col-7 ">
                    <div className="row m-0 pt-2">
                      <div className="col p-0 d-flex align-items-center">
                        <h5>
                          <b>Merchant ID:{info?.userId}</b>
                        </h5>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <Button
                          style={{
                            border: "1px solid",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            bordercolor: "rgba(43, 193, 85, 1)",
                          }}
                        >
                          Change Password
                        </Button>
                      </div>
                    </div>
                    <hr className="p-0 m-0" />
                    <div className="row p-0 m-0 mt-1 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Contact Name{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.name}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Contact Email{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.email}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Contact Number{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.phoneNumber}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Business Name{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.businessName}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Business Type{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.businessType}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Registration Date{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.createdOn}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Registration By{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.createdBy}
                      </p>
                    </div>
                    {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Account Activated On  </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                    {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Account Access  </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                    {/* <div className="row p-0 m-0 ">
          <p className="col" style={{fontSize:"1rem"}}> Business Website/App details </p>
          <p className="col d-flex justify-content-end" style={{fontSize:"1rem" , color:" rgba(0, 0, 0, 0.5)"}}>{info.department}</p>
        </div> */}
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Additional Business Website/App{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.additionalWebsiteDetail}
                      </p>
                    </div>
                    <div className="row p-0 m-0 ">
                      <p className="col" style={{ fontSize: "1rem" }}>
                        {" "}
                        Limit Per Transaction{" "}
                      </p>
                      <p
                        className="col d-flex justify-content-end"
                        style={{
                          fontSize: "1rem",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {info.limitPerTransaction}
                      </p>
                    </div>
                  </div>
                  <div className="col ">
                    <div
                      className="card p-0 "
                      style={{ background: "#F9FAFB" }}
                    >
                      <div className="row m-0 pt-2">
                        <div className="col p-0 d-flex align-items-center">
                          <h5>
                            <b>Settlement Detail</b>
                          </h5>
                        </div>
                        <div className="p-0 col d-flex justify-content-end">
                          <p
                            style={{
                              color: "rgba(43, 193, 85, 1)",fontSize:"1.2rem",fontWeight:"500"
                            }}
                          >
                            View Settlement Schedule
                          </p>
                        </div>
                      </div>
                      <hr className="p-1 m-0" />
                      <div className="row p-0 m-0 mt-1 ">
                        <h5 className="mx-2">Current Balance</h5>

                        <p
                          className="col d-flex justify-content-end"
                          style={{
                            fontSize: "1rem",
                            color: " rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          ₹2100
                        </p>
                      </div>
                    </div>
                    <div
                      className="card mt-3 p-2"
                      style={{ background: "#F9FAFB" }}
                    >
                      <h5>
                        <b>Upgrade Merchant</b>
                      </h5>
                      <p style={{ fontSize: ".9rem" }}>
                        You can upgrade your account to a Merchant Account by
                        giving us your business name
                      </p>
                      <p>
                        <b>Business Name:</b>
                      </p>
                      <Form.Control></Form.Control>
                      <div className="mt-3">
                        {" "}
                        <Button style={{ width: "8rem" }}>Primary</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Support Details</h5>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Phone Number{" "}
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.supportPhoneNo}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Email ID
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.supportEmailId}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Website/Contact us link
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.supportWebsiteLink}
                  </p>
                </div>
              </div>

              <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">GST Details</h5>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    GST Detail{" "}
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.gstDetails}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Bumppy's GST Number
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.gstNumber}
                  </p>
                </div>
              </div>

              <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Bank Account</h5>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    IFSC Code
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.ifscCode}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Account Number
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.accountNumber}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Beneficiary
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.beneficiaryName}
                  </p>
                </div>
              </div>

              <div className="card p-2 m-1 mt-3">
                <h5 className="mx-3">Access Management</h5>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Login Email
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.email}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Role
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.roleName}
                  </p>
                </div>
                <div className="row p-0 m-0 mt-1 ">
                  <p className="col" style={{ fontSize: "1rem" }}>
                    {" "}
                    Beneficiary
                  </p>
                  <p
                    className="col d-flex "
                    style={{
                      fontSize: "1rem",
                      color: " rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {info.beneficiaryName}
                  </p>
                </div>
              </div>

              {/* <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard
                label={`Merchant Id: ${info.vAccountNumber}`}
                value={"Change Password"}
              />

              <DetailCard
                label={"Contact Name"}
                value={info.name ? info.name : "-"}
              />
              <DetailCard
                label={"Contact Email"}
                value={info.email ? info.email : "-"}
              />
              <DetailCard
                label={"Contact Number"}
                value={info.phoneNumber ? info.phoneNumber : "-"}
              />
              <DetailCard
                label={"Business Name"}
                value={info.businessName ? info.businessName : "-"}
              />
              <DetailCard
                label={"Business Type"}
                value={info.businessType ? info.businessType : "-"}
              />
              <DetailCard
                label={"Registration Date"}
                value={info.createdOn ? info.createdOn : "-"}
              />
              <DetailCard label={"Registered By"} value={"--"} />
              <DetailCard
                label={"Account Activated On"}
                value={" Aug 12 2019, 01:03 pm"}
              />
              <DetailCard label={"Account Access"} value={"Complete"} />
              <DetailCard
                label={"Business Website/App details"}
                value={"https://bumppy.com"}
              />
              <DetailCard
                label={"Additional Business Website/App"}
                value={
                  info.additionalWebsiteDetail
                    ? info.additionalWebsiteDetail
                    : "-"
                }
              />
              <DetailCard
                label={"Limit per transaction"}
                value={
                  info.limitPerTransaction ? info.limitPerTransaction : "-"
                }
              />
              {info.contactName ? (
                <DetailCard label={"Contact Name"} value={info.contactName} />
              ) : null}
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Support Details"} />

              <DetailCard
                label={"Phone No"}
                value={info.supportPhoneNo ? info.supportPhoneNo : "-"}
              />
              <DetailCard
                label={"Email id"}
                value={info.supportEmailId ? info.supportEmailId : "-"}
              />
              <DetailCard
                label={"Website/Contact us link"}
                value={
                  info.supportWebsiteLink ? info.supportWebsiteLink : "-"
                }
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"GST Details"} />

              <DetailCard
                label={"GST Details"}
                value={info.gstDetails ? info.gstDetails : "-"}
              />
              <DetailCard
                label={"Bumppy's GST Number"}
                value={info.gstNumber ? info.gstNumber : "-"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Bank Account"} />

              <DetailCard
                label={"IFSC Code"}
                value={info.ifscCode ? info.ifscCode : "-"}
              />
              <DetailCard
                label={"Account Number"}
                value={info.accountNumber ? info.accountNumber : "-"}
              />
              <DetailCard
                label={"Beneficiary"}
                value={"Bumppy Media Pvt Ltd"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailCard
                label={"Login Email"}
                value={"sumitvermacr7@gmail.com"}
              />
              <DetailCard
                label={"Role"}
                value={info.roleName ? info.roleName : "-"}
              />
              <DetailCard
                label={"Beneficiary"}
                value={"Bumppy Media Pvt Ltd"}
              />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard value={""} label={"Upgrade Merchant"} />

              <div style={{ padding: "0px" }} className="card">
                <div style={{ padding: "8px" }} className="card-body">
                  <span className="row justify-content-center">
                    You can upgrade your account to a Merchant Account by
                    giving us your business name
                  </span>

                  <div className="row justify-content-center">
                    <label
                      style={{ margin: "5px", padding: "10px" }}
                      htmlFor="exampleInputtext"
                      className="text-center"
                    >
                      Business Name:
                    </label>
                    <Form.Control
                      style={{ margin: "10px" }}
                      type="email"
                      className="col-sm-3 form-control"
                      id="exampleInputtext"
                      placeholder="Acme Inc."
                    />
                    <button
                      style={{ margin: "10px" }}
                      type="button"
                      className="btn btn-primary btn-fw"
                    >
                      Primary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard
                value={"View Settlement Schedule"}
                label={"Settlement Details"}
              />

              <DetailCard label={"Current Balance"} value={"₹ 0.00"} />
            </div>
          </div>

          <div className="col-md-12 grid-margin stretch-card p-0">
            <div style={{ padding: "0px" }} className="card">
              <DetailHeaderCard label={" Purpose Code and FIRC"} />

              <div style={{ padding: "8px" }} className="card">
                <div style={{ padding: "0px" }} className="card-title">
                  <span
                    style={{ padding: "0px" }}
                    className="text-muted text-center text-sm-left d-block d-sm-inline-block"
                  >
                    Purpose Code
                  </span>
                  <span
                    style={{ padding: "0px" }}
                    className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"
                  >
                    <button type="button" className="btn btn-link btn-fw ">
                      Select Code
                    </button>
                  </span>
                </div>
              </div>

              <DetailCard label={"FIRC Certificate"} value={"Coming Soon"} />
            </div>
          </div> */}
            </Tab>

            <Tab eventKey="Credits" title="Credits">
              <div className="row">
                <div className="col">
                <div className="row" style={{ background: "white" }}>
  <div className="col-4 mt-2 d-flex align-items-center">
  <div style={{ borderRight: "1px solid #ccc", paddingBottom: "10px" }}>
      <h6 style={{ color: "#a0a0a0" }}>Amount Credit</h6>
      <h6> ₹00</h6>
     <p className="pr-2" style={{ color: "#a0a0a0", fontSize: "1rem" }}>
        Transactions worth amount credits will be free of any transaction fee.
      </p>
    </div>
  </div>{" "}
  <div className="col d-flex align-items-center">
    <canvas id="amountDueBarChart" width="400" height="200" />
  </div>
</div>
<div className="row mt-2" style={{ background: "white" }}>
  <div className="col-4 mt-2 d-flex align-items-center">
  <div style={{ borderRight: "1px solid #ccc", paddingBottom: "10px" }}>
      <h6 style={{ color: "#a0a0a0" }}>Commission Credit</h6>
      <h6> ₹00</h6>
     <p className="pr-2" style={{ color: "#a0a0a0", fontSize: "1rem" }}>
        Get your transactions settled in full. Transaction charges will be deducted from Commission credits.
      </p>
    </div>
  </div>{" "}
  <div className="col d-flex align-items-center">
    <canvas id="commissionCreditBarChart" width="400" height="200" />
  </div>
</div>

<div className="row mt-2" style={{ background: "white" }}>
  <div className="col-4 mt-2">
    <div style={{ borderRight: "1px solid #ccc", paddingBottom: "10px" }}>
      <h6 style={{ color: "#a0a0a0" }}>Refund Credit</h6>
      <h6> ₹00</h6>
      <p className="pr-2" style={{ color: "#a0a0a0", fontSize: "1rem" }}>
        Do not want to refund from your settled amounts? Use refund credits.
      </p>
    </div>
  </div>{" "}
  <div className="col">
    <canvas id="Refund" width="400" height="200" />
  </div>
</div>


                </div>
                <div className="col" style={{ background: "#", display: "flex", height:"12rem" }}>
                <div style={{ width: "12rem", height: "12rem" }}>
    {/* Doughnut Chart */}
    <canvas id="usersDoughnutChart"  />
  </div>
  <div style={{ flex: 1, paddingLeft: "10px" }}>
    {/* Progress Bar */}
    <div className="row">
    <h6 className="col " style={{ color: "#a0a0a0" }}>Active Users </h6>
    <h6 className="col  d-flex justify-content-end">₹2100</h6>
    </div>
    <div className="row m-0 p-0">
    <p className="col m-0 p-0 ">6776 Email Account</p>
    <p className="col m-0 p-0  d-flex justify-content-end">70%</p>
    </div>
    <div className="progress" style={{ height: "25px", borderRadius:"1rem" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: "70%", backgroundColor: "#2B4DED" }}
        aria-valuenow="70"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        70%
      </div>
    </div>
    <div className="row m-0 mt-3 p-0">
    <p className="col m-0 p-0 " >7687 Request</p>
    <p className="col m-0 p-0  d-flex justify-content-end">50%</p>
    </div>
    <div className="progress" style={{ height: "25px",borderRadius:"1rem" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: "50%", backgroundColor: "#FF6A00" }}
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        50%
      </div>
    </div>
  </div>
</div>

              </div>

           
            </Tab>

            <Tab eventKey="Balances" title="Balances">
              <div className="row mt-3">
                <div className="col-7">
                  <div className="row m-0 pt-2" style={{ background: "#fff" }}>
                    <div className="col mx-2 p-0 d-flex flex-column ">
                      <h6 style={{ color: "#a0a0a0" }}>DMT Balance</h6>
                      <h5>{info.dmtBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        style={{
                          border: "1px solid",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          bordercolor: "rgba(43, 193, 85, 1)",
                          height: "2.5rem",
                        }}
                      >
                        Activate
                      </Button>
                    </div>
                  </div>
                  <div
                    className="row m-0 mt-3 pt-2"
                    style={{ background: "#fff" }}
                  >
                    <div className="col mx-2 p-0 d-flex flex-column ">
                      <h6 style={{ color: "#a0a0a0" }}>AEPS Balance</h6>
                      <h5>{info.aepsBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        style={{
                          border: "1px solid",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          bordercolor: "rgba(43, 193, 85, 1)",
                          height: "2.5rem",
                        }}
                      >
                        Activate
                      </Button>
                    </div>
                  </div>
                  <div
                    className="row m-0 mt-3 pt-2"
                    style={{ background: "#fff" }}
                  >
                    <div className="col mx-2 p-0 d-flex flex-column ">
                      <h6 style={{ color: "#a0a0a0" }}>BBPS Wallet Balance</h6>
                      <h5>{info.bbpsBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        style={{
                          border: "1px solid",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          bordercolor: "rgba(43, 193, 85, 1)",
                          height: "2.5rem",
                        }}
                      >
                        Activate
                      </Button>
                    </div>
                  </div>
                  <div
                    className="row m-0 mt-3 pt-2"
                    style={{ background: "#fff" }}
                  >
                    <div className="col mx-2 p-0 d-flex flex-column ">
                      <h6 style={{ color: "#a0a0a0" }}>
                        BBPS Offline Wallet Balance
                      </h6>
                      <h5>{info.bbpsOfflineBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        style={{
                          border: "1px solid",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          bordercolor: "rgba(43, 193, 85, 1)",
                          height: "2.5rem",
                        }}
                      >
                        Activate
                      </Button>
                    </div>
                  </div>
                  <div
                    className="row m-0 mt-3 pt-2"
                    style={{ background: "#fff" }}
                  >
                    <div className="col mx-2 p-0 d-flex flex-column ">
                      <h6 style={{ color: "#a0a0a0" }}>Payment Api Balance</h6>
                      <h5>{info.paymentAPIBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        style={{
                          border: "1px solid",
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "rgba(43, 193, 85, 1)",
                          bordercolor: "rgba(43, 193, 85, 1)",
                          height: "2.5rem",
                        }}
                      >
                        Activate
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col mt-3 d-flex flex-column align-items-center text-center">
                  <img
                    alt=""
                    src={require("../../assets/images/develpoer_balance.png")}
                    style={{ height: "20rem" }}
                  />
                  <div className="d-flex flex-column align-items-start">
                    <h3>Earn</h3>
                    <h3>Predict & Prosper</h3>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Transactions" title="Transactions">
              <Transactions />
            </Tab>
            <Tab eventKey="payin" title="Payin/Refund">
              <Payin />
            </Tab>
            <Tab eventKey="payout" title="Payout">
              <Payout />
            </Tab>

            <Tab eventKey="topup" title="Topup">
              <Topup />
            </Tab>
            <Tab eventKey="virtaulAccounts" title="Virtual Accounts">
              <SmartCollectVirtualAccountTable
                setDetails={(details) => {
                  setDetails({ ...details, sidebarType: "payments" });
                  document
                    .querySelector(".content-wrapper")
                    .classList.toggle("sidebar-icon-only");
                }}
              />
            </Tab>
            <Tab eventKey="virtaulPayments" title="Virtual Payments">
              <SmartCollectPaymentsTable
                setDetails={(details) => {
                  setDetails({ ...details, sidebarType: "payments" });
                  document
                    .querySelector(".content-wrapper")
                    .classList.toggle("sidebar-icon-only");
                }}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const DetailCard = ({ label, value }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }} className="card">
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const BalanceCard = ({ balanceType, balance, info, buttonName }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-12 grid-margin stretch-card">
        <div style={{ padding: "0px" }} className="card">
          <div className="card-body">
            <div className="col">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                <h5
                  style={{ color: "#000" }}
                  className="card-title font-weight-medium mb-3"
                >
                  {balanceType}
                </h5>
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                <button
                  style={{
                    color: "#528ff0",
                    border: "1px solid #528ff0",
                  }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  {buttonName}
                </button>
              </span>
              <h1 style={{ color: "#000" }} className="font-weight-medium mb-0">
                {balance}
              </h1>

              {/* <p style={{ color: "#000" }} className="mb-0">
                {info}
                <button
                  style={{ color: "#528ff0" }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  Contact Us
                </button>{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
