import React, { useEffect, useState } from "react";
import { Col, Row, Nav, Tabs, Tab, Form, Button } from "react-bootstrap";
import { offsetArr } from "../../utils/CommonConfig";
import { BASE_URL_PAYNOW } from "../../utils/Constants";

const Transactions = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Payments/GetTransactionsall`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data && data.length > 0) {
        const dataLoad = data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.beneAccountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        });
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setData(dataLoad);
        setDataToDisplay(dataLoad);
      }
      // setPayments(JSON.parse(body).data);
    } catch (error) {}
  };

  const getStatusBadge = (status) => {
    if (status && status.toString().toLowerCase() == "failed") {
      return (
        <span className="badge badge-danger badge-pill p-2">{status}</span>
      );
    } else if (
      status &&
      status.toString().toLowerCase() == "settlement completed"
    ) {
      return (
        <span className="badge badge-success badge-pill p-2"> {status}</span>
      );
    } else if (
      status &&
      (status.toString().toLowerCase() == "accepted" ||
        (status && status.toString().toLowerCase() == "received"))
    ) {
      return (
        <span className="badge badge-warning badge-pill p-2"> {status}</span>
      );
    } else {
     return  <span className="badge badge-info badge-pill p-2"> {status}</span>;
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 stretch-card">
        <div className="card m-0 p-0">
          <div className="card-body">
            <div className="tab-custom-pills-horizontal">
              <div className="d-flex w-100 row justify-content-between">
                <div>
                  <h3> Transactions</h3>
                </div>
                <div>
                  <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      value={searchText}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row w-100 p-0">
                <div className="scroll-inner dashboard-fix-width">
                  <table
                    style={{ width: "100%" }}
                    className="dashboard-table-style"
                  >
                    <tr>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Sr"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Ref"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Amount"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Balance"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Status"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Date and Time"}
                      </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                      <tr>
                        <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
                        <td style={{ fontSize: "0.8rem" }}>
                          {" "}
                          <div className="d-flex flex-column align-items-center text-center">
                            <span className="text-center">
                              {e.merchantTransactionid}
                            </span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.transactionAmount}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.balance}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            {getStatusBadge(e.status)}
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.createdOn}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <Pagination
                  page={currentPage}
                  limit={pages}
                  callback={(page) => {
                    setCurrentPage(page);
                  }}
                  count={dataToDisplay.length}
                  total={total}
                  callback2={(offsetValue) => {
                    setOffset(offsetValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default Transactions;
