import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "@react-hook/media-query";
import { IoMdMail } from "react-icons/io";

const TalkExpert = () => {
  const [index, setIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width: 825px)");

  const handleHover = (event) => {
    event.target.style.background = "#fff";
    event.target.style.color = "#0085ff";
  };

  const handleHoverExit = (event) => {
    event.target.style.background = "#0085ff";
    event.target.style.color = "#fff";
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="my-md-5 ">
        <div className="row">
          <div className="col-1"></div>
          <div className="col">
            <div className="row p-0">
              <div className="col my-5">
                <h2 style={{ fontSize: isSmallScreen ? "1.5rem" : "", fontWeight: "500", color: "#000" }}>
                  Elevate Your People’s Experience
                </h2>
                <p style={{ color: "#000", fontWeight: "400" }}>
                  Effortlessly handle employee data, boost productivity with BillPunch, and streamline operations for hassle-free productivity.
                </p>
                
                <a href="mailto:support@billpunch.com" style={{ textDecoration: "none" }}>
                  <Button
                    style={{
                      borderRadius: "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onMouseOver={handleHover}
                    onMouseOut={handleHoverExit}
                  >
                    <IoMdMail style={{ marginRight: "8px" }} />
                    <h6 className="m-0 p-0">Talk to an Expert</h6>
                  </Button>
                </a>
              </div>
              <div className="col-md-6 col-12">
                <div className="service_laptop">
                  <div className="row m-0 p-0 d-flex">
                    <div className="col">
                      <div
                        className="card m-0 p-2 hover-cardplan expertcard"
                        style={{
                          background: "#E6EBFF",
                          border: "1px solid #C8DEFF",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon1.png")} />
                          &nbsp; All-in-One Platform
                        </p>
                        <p style={{ color: "#000" }}>
                          Say hello to simplicity by combining all your HR systems into a single, user-friendly platform.
                        </p>
                      </div>
                    </div>
                    <div className="col p-0 mt-md-3">
                      <div
                        className="card m-0 p-2 hover-cardplan expertcard"
                        style={{
                          background: "#E5FAEB",
                          border: "1px solid #C7EAED",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon2.png")} />
                          &nbsp; Optimise Workforce
                        </p>
                        <p style={{ color: "#000" }}>
                          Connect people and financial data to prioritize what matters most to your company and employees.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-md-0 mt-3 m-0 p-0">
                    <div className="col">
                      <div
                        className="card m-0 p-2 hover-cardplan expertcard"
                        style={{
                          background: "#FEF8DE",
                          border: "1px solid #DDE9E2",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon3.png")} />
                          &nbsp; Unlock Growth
                        </p>
                        <p style={{ color: "#000" }}>
                          Discover how connected, cloud-powered technology can drive better business outcomes.
                        </p>
                      </div>
                    </div>
                    <div className="col p-0 mt-md-3">
                      <div
                        className="card m-0 p-2 hover-cardplan expertcard"
                        style={{
                          background: "#FBECE7",
                          border: "1px solid #DBDFEA",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon4.png")} />
                          &nbsp; Foundation for Growth
                        </p>
                        <p style={{ color: "#000" }}>
                          Lay the groundwork for success with connected systems and streamlined processes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dot-click-slider-container service_phone">
                  <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                    <Carousel.Item>
                      <div
                        className="card m-0 p-2 expertcard"
                        style={{
                          background: "#E6EBFF",
                          border: "1px solid #C8DEFF",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon1.png")} />
                          &nbsp; All-in-One Platform
                        </p>
                        <p style={{ color: "#000" }}>
                          Say hello to simplicity by combining all your HR systems into a single, user-friendly platform.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="card m-0 p-2 expertcard"
                        style={{
                          background: "#E5FAEB",
                          border: "1px solid #C7EAED",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon2.png")} />
                          &nbsp; Optimise Workforce
                        </p>
                        <p style={{ color: "#000" }}>
                          Connect people and financial data to prioritize what matters most to your company and employees.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="card m-0 p-2 expertcard"
                        style={{
                          background: "#FEF8DE",
                          border: "1px solid #DDE9E2",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon3.png")} />
                          &nbsp; Unlock Growth
                        </p>
                        <p style={{ color: "#000" }}>
                          Discover how connected, cloud-powered technology can drive better business outcomes.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="card m-0 p-2 expertcard"
                        style={{
                          background: "#FBECE7",
                          border: "1px solid #DBDFEA",
                          borderRadius: ".4rem",
                        }}
                      >
                        <p style={{ fontSize: "1rem", color: "#000" }}>
                          <img alt="Billpunch" style={{ height: "2rem" }} src={require("../../../assets/images/cardicon4.png")} />
                          &nbsp; Foundation for Growth
                        </p>
                        <p style={{ color: "#000" }}>
                          Lay the groundwork for success with connected systems and streamlined processes.
                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                  <div className="dot-indicators" style={{ marginBottom: "-1.5rem" }}>
                    {[...Array(4).keys()].map((i) => (
                      <span key={i} onClick={() => setIndex(i)} className={i === index ? "active" : ""}></span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    </>
  );
};

export default TalkExpert;
