import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
const Forgetpasswordbillpuch = () => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isMobileEditable, setIsMobileEditable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory("");
  const { password , username } = billpunchbasicauth;
 
  const validateMobile = () => {
    if (!mobile.trim()) {
      setErrors({ mobile: "Mobile number is required" });
      return false;
    }
    return true;
  };

  const validateOtp = () => {
    if (!otp.trim()) {
      setErrors({ otp: "OTP is required" });
      return false;
    }
    return true;
  };

  const validatePasswords = () => {
    if (!newPassword.trim()) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }
    if (!confirmPassword.trim()) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }
    if (newPassword !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (step === 1) {
      if (validateMobile()) {
        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");
         
          const response = await fetch(  `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ForgotPassword` , {
            method: "POST",
            headers:myHeaders,
            body: JSON.stringify({
              us_loginid: mobile,
              us_password: "string",
              step: "1",
              otp: "string",
            }),
          });
  
          const data = await response.json();
          if (response.ok) {
            sendOtp();
            setIsMobileEditable(false);
            setStep(2);
          } else {
            console.error("API error:", data);
            setErrors({ mobile: "Error sending OTP. Please try again." });
          }
        } catch (error) {
          console.error("Error sending OTP:", error);
        }
      }
    } else if (step === 2) {
      if (validateOtp()) {
        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");
         
          const response = await fetch(  `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ForgotPassword` , {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              us_loginid: mobile,
              us_password: "string",
              step: "2",
              otp: otp,
            }),
          });
  
          const data = await response.json();
          if (response.ok) {
            verifyOtp();
            setStep(3);
          } else {
            console.error("API error:", data);
            setErrors({ otp: "Invalid OTP. Please try again." });
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
        }
      }
    } else if (step === 3) {
      if (validatePasswords()) {
        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
          myHeaders.append("Content-Type", "application/json");
         
          const response = await fetch(  `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ForgotPassword` , {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              us_loginid: mobile,
              us_password: newPassword,
              step: "2",
              otp: otp,
            }),
          });
  
          const data = await response.json();
          if (response.ok) {
            cogoToast.success(data.msg);
            history.push("/Loginpayroll");
            console.log("Password updated successfully!");
          } else {
            cogoToast.success(data.msg);

            console.error("API error:", data);
            setErrors({ confirmPassword: "Error updating password. Please try again." });
          }
        } catch (error) {
          console.error("Error updating password:", error);
        }
      }
    }
  };
  

  const sendOtp = () => {
    console.log("OTP sent to", mobile);
  };

  const verifyOtp = () => {
    // Placeholder function for verifying OTP
    console.log("OTP verified successfully");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const rightColumnStyle = {
    flex: 1,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const inputStyle = {
    width: "100%", 
  };
  return (
    <>
      <div className="row m-0 p-0" >
        <div className="col" style={rightColumnStyle}>
          <div className="mt-md-5 pt-md-5 mt-4">
            <div className="d-flex justify-content-center">
            <Link  to="/">
               <img
                alt=""
                src={require("../../../assets/images/logoblack.png")}
                style={{ height: "2rem" }}
              /></Link>
            </div>

            <div className="m-2">
              <h5 className="mx-md-4 mb-4 d-flex justify-content-center">
                {step === 1 && "Enter Your Mobile Number"}
                {step === 2 && "Enter OTP"}
                {step === 3 && "Set New Password"}
              </h5>
              <form className=" " onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile Number</label>
                  <input
                    type="text"
                    id="mobile"
                    className="form-control"
                    value={mobile}
                    style={inputStyle}
                    onChange={(e) => {
                      setMobile(e.target.value);
                      setErrors({});
                    }}
                    disabled={!isMobileEditable}
                  />
                  {errors.mobile && (
                    <p className="text-danger">{errors.mobile}</p>
                  )}
                </div>

                {step === 2 && (
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="text"
                      id="otp"
                      className="form-control"
                      value={otp}
                      style={inputStyle}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setErrors({});
                      }}
                    />
                    {errors.otp && <p className="text-danger">{errors.otp}</p>}
                  </div>
                )}

                {step === 3 && (
                  <>
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="newPassword"
                        className="form-control"
                        value={newPassword}
                        style={inputStyle}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          setErrors({});
                        }}
                      />
                      {errors.newPassword && (
                        <p className="text-danger">{errors.newPassword}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        className="form-control"
                        value={confirmPassword}
                        style={inputStyle}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setErrors({});
                        }}
                      />
                      {errors.confirmPassword && (
                        <p className="text-danger">
                          {errors.confirmPassword}
                        </p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          onChange={togglePasswordVisibility}
                        /> &nbsp;
                        Show Password
                      </label>
                    </div>
                  </>
                )}

                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    {step === 1 ? "Submit" : "Next"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpasswordbillpuch;
