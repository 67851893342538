import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Form,
  FormControl,
  FormLabel,
  Modal,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_SERVICE_API,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import { inWords, twoDecimalPlaces, uniqueID2 } from "../../utils/utilFuctions";
import {
  FaAngleDown,
  FaCaretDown,
  FaCaretRight,
  FaFlag,
  FaTimes,
} from "react-icons/fa";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
export const Addfirmsmodal = ({ show, closeModal, Getfirms }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const initialState = {
    Firmname: "",
    Firmowner: "",
    Firmstrdate: "",
    Firmgst: "",
    FirmAddress: "",
    Firmmember: "",
    Firmtype: "",
    Firmmail: "",
    Firmmobile: "",
  };
  const { password , username } = billpunchbasicauth;
 
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = {};
  
    // Validation rules
    if (!formData.Firmname) {
      errors.Firmname = "Firm name is required";
      isValid = false;
    } else if (formData.Firmname.trim() !== formData.Firmname) {
      errors.Firmname = "Firm name should not start with spaces";
      isValid = false;
    }
  
    if (!formData.Firmowner) {
      errors.Firmowner = "Firm owner is required";
      isValid = false;
    } else if (formData.Firmowner.trim() !== formData.Firmowner) {
      errors.Firmowner = "Firm owner should not start with spaces";
      isValid = false;
    }
  
    if (!formData.Firmstrdate) {
      errors.Firmstrdate = "Firm start date is required";
      isValid = false;
    }
  
    if (!formData.Firmgst) {
      errors.Firmgst = "Firm GST is required";
      isValid = false;
    }
  
    if (!formData.FirmAddress) {
      errors.FirmAddress = "Firm address is required";
      isValid = false;
    }
  
    if (!formData.Firmmember) {
      errors.Firmmember = "Firm members are required";
      isValid = false;
    }
  
    if (!formData.Firmtype) {
      errors.Firmtype = "Firm type is required";
      isValid = false;
    }
  
    if (!formData.Firmmail) {
      errors.Firmmail = "Firm email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Firmmail)) {
      errors.Firmmail = "Invalid email address";
      isValid = false;
    }
    if (!formData.Firmmobile) {
      errors.Firmmobile = "Firm mobile is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.Firmmobile)) {
      errors.Firmmobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }
    
  
    setErrors(errors);
    if (Object.keys(errors).length === 0 && isValid) {
      handleSubmit();
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        firm_name: formData.Firmname,
        firm_owner_name: formData.Firmowner,
        firm_startdate: formData.Firmstrdate,
        firm_gst: formData.Firmgst,
        firm_address: formData.FirmAddress,
        firm_status: "Y",
        firm_members: formData.Firmmember,
        firm_type: formData.Firmtype,
        firm_email: formData.Firmmail,
        firm_mobile: formData.Firmmobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddFirmsSuperAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "00") {
        swal("Success", data.result, "success");
        closeModal();
        Getfirms();
      } else {
        closeModal();
        swal("Error", data.result, "warning");
        Getfirms();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const handleModalClose = () => {
    setFormData(initialState);
    closeModal();
  };
  return (
    <>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Firm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={validateForm}>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="Firmname">
                <Form.Label>Firm Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter firm name"
                  name="Firmname"
                  value={formData.Firmname}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmname}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col" controlId="Firmowner">
                <Form.Label>Firm Owner</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter firm owner"
                  name="Firmowner"
                  value={formData.Firmowner}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmowner}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmowner}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="Firmstrdate">
                <Form.Label>Firm Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="Firmstrdate"
                  value={formData.Firmstrdate}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmstrdate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmstrdate}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col" controlId="Firmgst">
                <Form.Label>Firm GST</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter firm GST"
                  name="Firmgst"
                  value={formData.Firmgst}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmgst}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmgst}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="Firmtype">
                <Form.Label>Firm Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter firm type"
                  name="Firmtype"
                  value={formData.Firmtype}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmtype}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmtype}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col" controlId="Firmmember">
                <Form.Label>Firm Members</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter firm members"
                  name="Firmmember"
                  value={formData.Firmmember}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmmember}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmmember}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row m-0 p-0">
              <Form.Group className="col" controlId="Firmmail">
                <Form.Label>Firm Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter firm email"
                  name="Firmmail"
                  value={formData.Firmmail}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmmail}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col" controlId="Firmmobile">
                <Form.Label>Firm Mobile</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter firm mobile"
                  name="Firmmobile"
                  value={formData.Firmmobile}
                  onChange={handleChange}
                  isInvalid={!!errors.Firmmobile}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Firmmobile}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className="col" controlId="FirmAddress">
              <Form.Label>Firm Address</Form.Label>
              <Form.Control
                as="textarea"
                row
                m-0
                p-0s={3}
                placeholder="Enter firm address"
                name="FirmAddress"
                value={formData.FirmAddress}
                onChange={handleChange}
                isInvalid={!!errors.FirmAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirmAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="px-3">
              {" "}
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const Editmodal = ({ show, closeModal, selectedFirm, Getfirms }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password , username } = billpunchbasicauth;
 
  const [formData, setFormData] = useState({
    firm_name: "",
    firm_owner_name: "",
    firm_gst: "",
    firm_address: "",
    firm_members: "",
    firm_type: "",
    firm_email: "",
    firm_mobile: "",
    firm_status: "",
  });
  useEffect(() => {
    if (show) {
      if (selectedFirm) {
        setFormData({
          ...selectedFirm,
          firmid: selectedFirm.pk_firm_id, 
        });
      }
    }
  }, [show, selectedFirm]);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    closeModal();

    setFormData({
      firm_name: "",
      firm_owner_name: "",
      firm_gst: "",
      firm_address: "",
      firm_members: "",
      firm_type: "",
      firm_email: "",
      firm_mobile: "",
      firm_status: "",
    });
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;
  
    // Firm Name validation
    if (!formData.firm_name.trim()) {
      errors.firm_name = "Firm name is required";
      isValid = false;
    }
  
    // Firm Owner Name validation
    if (!formData.firm_owner_name.trim()) {
      errors.firm_owner_name = "Firm owner name is required";
      isValid = false;
    }
  
    // Firm GST validation
    if (!formData.firm_gst.trim()) {
      errors.firm_gst = "Firm GST is required";
      isValid = false;
    }
  
    // Firm Address validation
    if (!formData.firm_address.trim()) {
      errors.firm_address = "Firm address is required";
      isValid = false;
    }
  
    // Firm Members validation
    if (!formData.firm_members.trim()) {
      errors.firm_members = "Firm members are required";
      isValid = false;
    }
  
    // Firm Type validation
    if (!formData.firm_type.trim()) {
      errors.firm_type = "Firm type is required";
      isValid = false;
    }
  
    // Firm Email validation
    if (!formData.firm_email.trim()) {
      errors.firm_email = "Firm email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.firm_email)) {
      errors.firm_email = "Invalid email address";
      isValid = false;
    }
  
    // Firm Mobile validation
    if (!formData.firm_mobile.trim()) {
      errors.firm_mobile = "Firm mobile is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.firm_mobile)) {
      errors.firm_mobile = "Invalid mobile number";
      isValid = false;
    }
  
    setErrors(errors);
    if (Object.keys(errors).length === 0 && isValid) {
      handleSubmit();
    }
  };
  
  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        firmid: formData.firm_id,
        firm_name: formData.firm_name,
        firm_owner_name: formData.firm_owner_name,
        startdate: "string",
        gst: formData.firm_gst,
        address: formData.firm_address,
        members: formData.firm_members,
        type: formData.firm_type,
        email: formData.firm_email,
        mobile: formData.firm_mobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/EditFirmsSuperAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        closeModal();
        Getfirms();
      } else {
        closeModal();
        swal("Error", data.result, "warning");
        Getfirms();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Firm</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <div className="row m-0 p-0">
          <Form.Group className="col" controlId="firmName">
            <Form.Label>Firm Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_name}
              onChange={(e) =>
                setFormData({ ...formData, firm_name: e.target.value })
              }
              isInvalid={!!errors.firm_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col" controlId="firmOwnerName">
            <Form.Label>Firm Owner Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_owner_name}
              onChange={(e) =>
                setFormData({ ...formData, firm_owner_name: e.target.value })
              }
              isInvalid={!!errors.firm_owner_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_owner_name}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="row m-0 p-0">
          <Form.Group className="col" controlId="firmGst">
            <Form.Label>Firm GST</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_gst}
              onChange={(e) =>
                setFormData({ ...formData, firm_gst: e.target.value })
              }
              isInvalid={!!errors.firm_gst}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_gst}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col" controlId="firmAddress">
            <Form.Label>Firm Address</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_address}
              onChange={(e) =>
                setFormData({ ...formData, firm_address: e.target.value })
              }
              isInvalid={!!errors.firm_address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_address}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="row m-0 p-0">
          <Form.Group className="col" controlId="firmMembers">
            <Form.Label>Firm Members</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_members}
              onChange={(e) =>
                setFormData({ ...formData, firm_members: e.target.value })
              }
              isInvalid={!!errors.firm_members}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_members}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col" controlId="firmType">
            <Form.Label>Firm Type</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_type}
              onChange={(e) =>
                setFormData({ ...formData, firm_type: e.target.value })
              }
              isInvalid={!!errors.firm_type}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_type}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="row m-0 p-0">
          <Form.Group className="col" controlId="firmEmail">
            <Form.Label>Firm Email</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_email}
              onChange={(e) =>
                setFormData({ ...formData, firm_email: e.target.value })
              }
              isInvalid={!!errors.firm_email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col" controlId="firmMobile">
            <Form.Label>Firm Mobile</Form.Label>
            <Form.Control
              type="text"
              value={formData.firm_mobile}
              onChange={(e) =>
                setFormData({ ...formData, firm_mobile: e.target.value })
              }
              isInvalid={!!errors.firm_mobile}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firm_mobile}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={validateForm}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
  
  );
};

