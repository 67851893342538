import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { Authorblogmodal } from "../SalaryManagement/Modal";
import { Link } from "react-router-dom";
const Blogauthor = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [authors, setAuthors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [Authormodal, setAuthormodal] = useState(false);
  const GetAuthorsBlog = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          slug: "all",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(data.data);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const filteredAuthors = authors.filter((author) =>
    author.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <h4 className="">Authors</h4>
        </div>
        <div className="col d-flex justify-content-end">
          <div className="mr-2">
            <input
              className="p-2"
              type="text"
              placeholder="Search authors..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "100%",
                borderRadius: ".4rem",
                border: "1px solid #ccc",
                height: "2.2rem",
              }}
            />
          </div>
          <Button
            style={{ height: "2rem" }}
            variant="success"
            size="sm"
            onClick={() => setAuthormodal(true)}
          >
            Add Author
          </Button>
        </div>
      </div>
      <Row className="g-4">
        {filteredAuthors.map((author) => (
          <Col key={author.id} md={2} sm={4}>
            <Link
              to={`/author/${author.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              onClick={() => {
                const authorData = JSON.stringify(author);
                console.log("click hua");
                localStorage.setItem("author", authorData);
                console.log(
                  "SessionStorage Author:",
                  sessionStorage.getItem("author")
                );
              }}
            >
              <Card className="p-2">
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {author.profile_image ? (
                    <Card.Img
                      variant="top"
                      src={author.profile_image}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#888",
                      }}
                    >
                      <FaUserCircle size={100} />
                    </div>
                  )}
                </div>
                <p
                  className="m-1 d-flex justify-content-center"
                  style={{ color: "#000" }}
                >
                  <b>{author.fullname}</b>
                </p>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <Authorblogmodal
        Authormodal={Authormodal}
        setAuthormodal={setAuthormodal}
        GetAuthorsBlog={GetAuthorsBlog}
      />
    </>
  );
};

export default Blogauthor;
