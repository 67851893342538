import React, { useEffect, useState } from "react";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { SalaryGenerateHR, SalaryProceedHR } from "./SalaryTable";

const PayrollHR = () => {
  const [filterState, setFilterState] = useState(0);
  useEffect(() => {}, []);

  return (
    <div className="m-2">
      <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
        <div className="   p-0 mt-0" style={{ borderRadius: ".4rem" }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="p-0 m-0">
              <Col
                className="p-0 mt-md-2 mt-sm-1 "
                style={{ borderRadius: ".4rem" , background:"#fff"}}
                sm={12}
              >
                <Nav
                  variant=""
                  className="nav-pills-custom align-items-center mx-md-2 mx-sm-1 mt-md-2 mt-sm-1 mb-md-2 mb-sm-1"
                  style={{ background: "#f1f2f4", borderRadius: ".4rem", width:'20rem' }}
                >
                  <Nav.Item className="mx-md-3 mx-sm-2 mt-md-2 mt-sm-1 mb-md-2 mb-sm-1 pr-1">
                    <Nav.Link
                      eventKey="first"
                      className={`btn ${
                        filterState === 0
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      onClick={() => {
                        setFilterState(0);
                      }}
                      style={{
                        fontSize: ".9rem",
                       border:'none',
                        padding: "10px",
                       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", 
                      }}
                    >
                      <div>Salary Generate</div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-1 pr-1">
                    <Nav.Link
                      eventKey="second"
                      className={`btn ${
                        filterState === 1
                          ? "btn-primary"
                          : "btn-outline-primary"
                      }`}
                      onClick={() => {
                        setFilterState(1);
                      }}
                      style={{
                        fontSize: ".9rem",
                       border:'none',
                        padding: "10px",
                       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", 
                      }}
                    >
                      <div>Salary Proceed</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xs={12} className="m-0 p-0 ">
                <Tab.Content className="mt-md-2 mt-sm-1 card tab-content-custom-pill pb-0">
                  {/* <Tab.Pane eventKey="first">
                    <SalaryGenerate />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <SalaryProceed />
                  </Tab.Pane> */}
                  {filterState === 0 && <SalaryGenerateHR />}
                  {filterState === 1 && <SalaryProceedHR />}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default PayrollHR;
