import React, { useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger,Tooltip } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Manageloanmodaladmin } from "./Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineFilterAltOff } from "react-icons/md";

const Loanadmin = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();

  const loanStatusOptions = [
    { value: "all_loans", label: "All Loans" },
    { value: "open_loans", label: "Open Loans" },
    { value: "paused_loans", label: "Paused Loans" },
    { value: "closed_loans", label: "Closed Loans" },
  ];
  const [employeesData, setEmployeesData] = useState([]);
  const [selectedLoanStatus, setSelectedLoanStatus] = useState(
    loanStatusOptions[0]
  );
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showMonthDropdown, setShowMonthDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loandata, setLoandata] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleLoanStatusChange = (option) => {
    setSelectedLoanStatus(option);
    console.log(`Selected Loan Status: ${option.label}`);
  };

  const handleEmployeeChange = (option) => {
    setSelectedEmployee(option);
    console.log(`Selected Employee: ${option.label}`);
  };

  const toggleMonthDropdown = () => {
    setShowMonthDropdown(!showMonthDropdown);
  };

  const closeMonthDropdown = () => {
    setShowMonthDropdown(false);
  };

  const GetAllEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const employeeOptions = data.data.map((employee) => ({
          value: employee.empid,
          label: `${employee.firstname} ${employee.lastname}`,
        }));
        setEmployeesData(employeeOptions);
        setTransactionLoaderState(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Employee = JSON.parse(sessionStorage.getItem("Employee"));
  const GetLoanRecord = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          type: "0",
          empid: "String",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetLoanRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setLoandata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetAllEmployee();
    GetLoanRecord();
  }, []);

  const getStatusLabel = (status) => {
    switch (status) {
      case "P":
        return "Open";
      case "C":
        return "Closed";
      case "O":
        return "Overdue";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <div
        className="p-md-2 p-1"
        style={{ background: "#fff", position: "relative" }}
      >
        <div className="row">
          <div className="col">
            <Select
              value={selectedLoanStatus}
              onChange={handleLoanStatusChange}
              options={loanStatusOptions}
              placeholder="Select Loan Status"
              styles={{
                container: (base) => ({
                  ...base,
                  width: "12rem",
                }),
                control: (base) => ({
                  ...base,
                  border: "none",
                  boxShadow: "none",
                  fontSize: "1.3rem",
                }),
              }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-end">
            <input
              style={{
                background: "#fff",
                borderRadius: ".4rem",
                width: "30%",
                border: "1px #ced4da solid",
                paddingLeft: "10px",
                height: "2rem",
              }}
              type="text"
              placeholder="Search"
            />
            <span style={{ marginLeft: "10px" }}>
              <Button
                onClick={handleShowModal}
                type="button"
                className="btn btn-sm btn-primary d-flex justify-content-center"
                style={{
                  background: "#fff",
                  color: "#000",
                  border: " 1px solid #000",
                }}
              >
                Manage Loan
              </Button>
            </span>
            <span style={{ marginLeft: "10px" }}>
              <Link
                to="/retailer/Record_loan_admin"
                type="button"
                className="btn btn-sm  btn-primary d-flex justify-content-center"
              >
                Record Loan
              </Link>
            </span>
            <span
              style={{
                marginLeft: "10px",
                border: "1px solid grey",
                borderRadius: ".2rem",
                height: "2rem",
              }}
              onClick={toggleMonthDropdown}
            >
              <CiFilter style={{ fontSize: "1.5rem", cursor: "pointer" }} />
            </span>
          </div>
        </div>
        {showMonthDropdown && (
          <div
            className="filter-dropdown"
            style={{
              position: "absolute",
              top: "4rem",
              left: "0",
              right: "0",
              background: "#fff",
              border: "1px solid #ced4da",
              zIndex: 1000,
              width: "100%",
            }}
          >
            <div className="row ">
              <div
                className="col px-md-4 px-sm-2"
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <span>Filter By:</span>
                <Select
  value={selectedEmployee}
  onChange={(option) => {
    if (option && option.value === "clear") {
      setSelectedEmployee(null); 
    } else {
      handleEmployeeChange(option);
    }
  }}
  options={[
    { value: "clear", label: <><MdOutlineFilterAltOff /> Clear Filter</> } ,
    ...employeesData,
    
  ]}
  placeholder="Select Employee"
  styles={{
    container: (base) => ({
      ...base,
      width: "12rem",
    }),
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
    }),
  }}
/>

              </div>
              <div className="col d-flex justify-content-end">
                <AiOutlineClose
                  style={{ cursor: "pointer" }}
                  onClick={closeMonthDropdown}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="table-responsive p-2"
        style={{
          marginTop: showMonthDropdown ? "3rem" : 0,
        }}
      >
        <table className="table table-bordered ">
          <thead>
            <tr style={{ borderBottom: "1px solid lightgrey" }}>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Loan Number
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Employee
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Loan Name
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Loan Amount
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Amount Paid
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Remaining Amount
              </th>
              <th
                className="text-center"
                style={{ color: "#0074D9", background: "#fff", border: "none" }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
  {loandata.length === 0 || 
   (selectedEmployee && 
    !loandata.some((item) => item.emp_name === selectedEmployee.label)) ? (
    <tr>
      <td
        colSpan="7"
        style={{
          textAlign: "center",
          color: "#000",
          background: "#fff",
          border: "none",
        }}
      >
        No Data found.
      </td>
    </tr>
  ) : (
    loandata
      .filter((item) =>
        selectedEmployee ? item.emp_name === selectedEmployee.label : true
      )
      .map((item, index) => (
        <OverlayTrigger
          key={index}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${index}`}>
              Click to get more details about this loan
            </Tooltip>
          }
        >
          <tr
            key={index}
            style={{ borderBottom: "1px solid lightgrey" }}
            onClick={() => {
              sessionStorage.setItem("Employee", JSON.stringify(item));
              history.push("/retailer/Load_detail_admin");
            }}
          >
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.loan_number}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.emp_name}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.loan_name}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.loan_amount}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.paid_amount}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              {item.remaining_amount}
            </td>
            <td
              className="text-center"
              style={{
                color: "#000",
                background: "#fff",
                border: "none",
              }}
            >
              <span style={{ color: "cornflowerblue" }}>
                {getStatusLabel(item.status)}
              </span>
            </td>
          </tr>
        </OverlayTrigger>
      ))
  )}
</tbody>

        </table>
      </div>
      <Manageloanmodaladmin show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default Loanadmin;
