import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { List } from "semantic-ui-react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { generateHashKey2, onlyNumeric } from "../../utils/utilFuctions";
import swal from "sweetalert";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Invalid } from "../topup/Topup4";
import { set } from "react-hook-form";

const MyProfile = () => {
    const [currentPasswordCheck, setCurrentPasswordCheck] = useState(false);
    const [currentTPinCheck, setCurrentTPinCheck] = useState(false);
    const [currentMPinCheck, setCurrentMPinCheck] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [userConfig, setUserConfig] = useState({});
    const [newUserConfig, setNewUserConfig] = useState({});
    const [tpinShow, setTpinShow] = useState(false);
    const [fundRequest, setFunRequest] = useState([]);
    const [otpText, setOtpText] = useState("");
    const { Profileinfo, user, setTransactionLoaderState } = useContext(GlobalContext);
    const [inputState, setInputState] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        currentTPin: "",
        newTPin: "",
        confirmTPin: "",
        currentMPin: "",
        newMPin: "",
        confirmMPin: "",
    });
    const [formErrors, setFormErrors] = useState({
        newTpin: "",
        confirmTpin: "",
    });

    const submitCurrentPassword = async () => {
        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "PASSWORD",
            password: inputState.currentPassword,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
            requestOptions
        );
        const result = await res.json();
        if (result.status == "00") {
            setCurrentPasswordCheck(true);
        } else {
            cogoToast.info("In Correct Password");
        }
    };
    const changePassword = async () => {
        if (inputState.newPassword !== inputState.confirmPassword) {
            cogoToast.error("New Password and Confirm Password should same");
            return;
        }

        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "PASSWORD",
            password: inputState.newPassword,
            currenttpin: inputState.currentPassword,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
            requestOptions
        );
        const data = await res.json();
        if (data.status == "00") {
            cogoToast.success("Password Changed Successfully");
        } else {
            cogoToast.info("Password Changed Failed");
        }
        reset();
        setCurrentPasswordCheck(false);
        setInputState({ ...inputState, currentPassword: "" });
    };

    const submitCurrentTPin = async () => {
        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "TPIN",
            password: inputState.currentTPin,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
            requestOptions
        );
        const data = await res.json();
        if (data.status == "00") {
            setCurrentTPinCheck(true);
        } else {
            cogoToast.info("In Correct T PIN");
        }
    };

    const changeTPin = async () => {
        if (inputState.newTPin.length !== 6) {
            setFormErrors({
                ...formErrors,
                newTpin: "T Pin should be 6 Digit long",
            });
            cogoToast.error("T Pin should be 6 Digit long");
            return;
        }
        if (inputState.newTPin !== inputState.confirmTPin) {
            setFormErrors({
                ...formErrors,
                confirmTpin: "New T Pin and Confirm T Pin should same",
            });
            cogoToast.error("New T Pin and Confirm T Pin should same");
            return;
        }

        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "TPIN",
            password: inputState.newTPin,
            currenttpin: inputState.currentTPin,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
            requestOptions
        );
        const data = await res.json();
        if (data.status == "00") {
            cogoToast.success("T PIN Changed Successfully");
        } else {
            cogoToast.info("T PIN Changed Failed");
        }
        reset();
        setCurrentTPinCheck(false);
        setInputState({ ...inputState, currentTPin: "" });
    };

    const requestTPin = async () => {
        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "TPIN",
            password: inputState.newTPin,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
            requestOptions
        );
        const data = await res.json();
        if (data.status == "00") {
            cogoToast.success("T PIN Requested Successfully");
        } else {
            cogoToast.info("T PIN Requested Failed");
        }
    };

    const requestMPin = async () => {
        var raw = JSON.stringify({
            mobileno: Profileinfo?.mobile,
            reset_type: "MPIN",
            password: inputState.newMPin,
        });

        const hash_key = await generateHashKey2(user.ReferId);
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash_key);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
            requestOptions
        );
        const data = await res.json();
        if (data.status == "00") {
            cogoToast.success("M PIN Requested Successfully");
        } else {
            cogoToast.info("M PIN Requested Failed");
        }
    };
    const reset = () => {
        setInputState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentTPin: "",
            newTPin: "",
            confirmTPin: "",
            currentMPin: "",
            newMPin: "",
            confirmMPin: "",
        });
    };

    const checkFundRequest = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: Profileinfo?.uscode,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/user/parent_fund_request_list.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setFunRequest(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const acceptFunRequest = async (reqId) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: Profileinfo.uscode,
                requestId: reqId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/user/parent_fund_request_accept.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", data.msg, "success");
                checkFundRequest();
            } else {
                swal("Failed", data.msg, "error");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const disAllowFundRequest = async (parentId) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                sender: user.ReferId,
                receiver: parentId,
                type: "2",
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/user/fund_request_submit.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", data.msg, "success");
            } else {
                swal("Failed", data.msg, "error");
            }
        } catch (error) {
            console.log("error", error);
        }
    };



    const getUserConfigSettings = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/getUserConfigSettings`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setUserConfig(data.data);
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const updateUserConfigSettings = async (configObj) => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "addBalanceOtp": configObj.add_balance_otp,
                "loginOtp": configObj.login_otp,
                "reverseBalanceOtp": configObj.reverse_balance_otp,
                "dmtLimit": configObj.dmt_limit_from + "|" + configObj.dmt_limit_to,
                "dmtExpressLimit": configObj.dmt_express_limit_from + "|" + configObj.dmt_express_limit_to,
                "settlementLimit": configObj.settlement_limit_from + "|" + configObj.settlement_limit_to,
                "aepsCwLimit": configObj.aeps_cw_limit_from + "|" + configObj.aeps_cw_limit_to,
                "aepsApLimit": configObj.aeps_ap_limit_from + "|" + configObj.aeps_ap_limit_to,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/userConfigSettingsUpdate`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", data.msg, "success");
                getUserConfigSettings();
                setOtpSent(false);
                setOtpText("");
                setFormErrors({})
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const sendOtp = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
                mobile: Profileinfo.mobile,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setOtpSent(true);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const validateOtp = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
                mobile: Profileinfo.mobile,
                otp: otpText,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                updateUserConfigSettings(userConfig);
            } else {
                setFormErrors({ ...formErrors, otp: "Invalid OTP" });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const showTPinClick = () => {
        setTpinShow(!tpinShow);
    };

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const positionStyle = {
        position: 'absolute',
        top: -40,
        left: 0,
        "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
        borderRadius: "5px",
    };

    useEffect(() => {
        checkFundRequest();
        getUserConfigSettings();
    }, [Profileinfo]);

    console.log(formErrors);
    return (
        <GlobalContext.Consumer>
            {(state) =>
                state.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="m-4">

                        <div className="card m-0 p-0 ">
                            <div className="col-5">
                                <div className="above-tabs-text">
                                    <div className="m-1 p-0 pl-4 py-3"
                                        style={{
                                            "fontFamily": "Poppins", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                            , padding: "0px",
                                            background: "#FFFFFF",
                                            color: "#4267B2",
                                            fontWeight: "bolder",
                                            fontfamily: "Poppins !impotent",
                                            fontSize: "24px",
                                        }} >
                                        Profile Settings
                                    </div>
                                </div>
                                <div defaultActiveKey="profile" className=" tab-header justify-content-betweenccol-6">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        My Profile
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Settings
                                    </div>


                                </div>
                            </div>

                            <div className="tab-content col-12">
                                {activeTab === 0 &&
                                    <div>

                                        <Col md={4} lg={12} sm={12} className="">
                                            <div className="py-3" style={{ "fontFamily": "Poppins", }} >
                                                <b>Personal Details</b>
                                            </div>

                                            <div className="row m-0 p-0">
                                                <div className="col-2  m-0 p-0">
                                                    <List style={{ "fontFamily": "Poppins", }} >
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                User ID:
                                                            </div>

                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                Mobile:
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                Firm:
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                E-Mail:
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                                <div className="col-4  m-0 p-0">
                                                    <List style={{ "fontFamily": "Poppins", }} >
                                                        <List.Item >

                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.uscode}
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.mobile}
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.name}
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.email}
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                            </div>






                                            <div className=" py-3 m-0 p-0" style={{ "fontFamily": "Poppins", }} >
                                                <b>Parent Details</b>
                                            </div>
                                            <div className="row  m-0 p-0">
                                                <div className="col-2  m-0 p-0">
                                                    <List style={{ "fontFamily": "Poppins", }} >
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                Firm:
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                Mobile:
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "black" }} className=" py-1 ">
                                                                Email:
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                                <div className="col-4  m-0 p-0">
                                                    <List style={{ "fontFamily": "Poppins", }} >
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.parent_name}
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.parent_mobile}
                                                            </div>
                                                        </List.Item>
                                                        <List.Item >
                                                            <div style={{ color: "grey" }} className=" py-1 ">
                                                                {state.Profileinfo?.parent_email}
                                                            </div>
                                                        </List.Item>
                                                    </List>

                                                </div>
                                            </div>



                                        </Col>


                                    </div>}

                                {activeTab === 1 &&
                                    <div style={{ "fontFamily": "Poppins", }}>

                                        <Col md={8} lg={12} sm={12} className="p-0 m-0">
                                            <div >
                                                <div className="py-4" style={{ fontSize: "24px", }}>
                                                    <div >
                                                        <strong>Change Password</strong>
                                                    </div>

                                                </div>

                                                <Row className="m-0 p-0">



                                                    <div className="col-5 p-0 m-0 pr-4 ">
                                                        <Form.Group>
                                                            <div className="pb-2">
                                                                <strong>Current Password</strong>
                                                            </div>


                                                            <Form.Control
                                                                placeholder="current password"
                                                                type="password"
                                                                className=" rounded-100 p-2"
                                                                onChange={(e) =>
                                                                    setInputState({
                                                                        ...inputState,
                                                                        currentPassword: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            {currentPasswordCheck && (
                                                                <div>
                                                                    <Form.Control
                                                                        placeholder="new password"
                                                                        type="password"
                                                                        className="m-1 rounded-0 form-control form-control-sm"
                                                                        onChange={(e) =>
                                                                            setInputState({
                                                                                ...inputState,
                                                                                newPassword: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                    <Form.Control
                                                                        placeholder="confirm password"
                                                                        type="password"
                                                                        className="m-1 rounded-0 form-control form-control-sm"
                                                                        onChange={(e) =>
                                                                            setInputState({
                                                                                ...inputState,
                                                                                confirmPassword: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                    <Button

                                                                        className="rounded-100 mt-4 btn btn-lg btn-block"
                                                                        onClick={changePassword}
                                                                    >
                                                                        Submit New Password
                                                                    </Button>
                                                                </div>
                                                            )}

                                                            {!currentPasswordCheck && (
                                                                <div className="col-5 py-2 p-0 m-0">
                                                                    <Button

                                                                        className="rounded-100 btn btn-sm px-3"
                                                                        onClick={submitCurrentPassword}
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                    {/* 
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentMPin}
                            >
                              Request Password
                            </Button> */}
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                    </div>





                                                    <div className="col-5 p-0 m-0 ">
                                                        <div className="pb-2">
                                                            <strong>T-PIN</strong>
                                                        </div>
                                                        <Form.Group>
                                                            <div className="input-group ">
                                                                <div className="input-group-prepend bg-transparent  ">
                                                                    <span className="input-group-text bg-transparent border-right-0 no-glow pl-2 m-0 p-0">
                                                                        <i className="mdi mdi-lock-outline text-primary"></i>
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type={tpinShow ? "text" : "password"}
                                                                    className="form-control  border-left-0 border-right-0 no-glow"
                                                                    id="exampleInputPassword"
                                                                    placeholder="Current T Pin"
                                                                    onChange={(e) =>
                                                                        setInputState({
                                                                            ...inputState,
                                                                            currentTPin: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                <div className="input-group-prepend bg-transparent">
                                                                    <span
                                                                        className="input-group-text bg-transparent border-left-0 no-glow m-0 p-0 pr-2"
                                                                        onClick={showTPinClick}
                                                                        style={{ borderRadius: "" }}
                                                                    >
                                                                        <i
                                                                            className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                                                                                }`}
                                                                        ></i>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {currentTPinCheck && (
                                                                <div>
                                                                    <Form.Control
                                                                        placeholder="new T Pin"
                                                                        type="password"
                                                                        autocomplete="new-password"
                                                                        autoCorrect="off"
                                                                        autoSave="new-password"
                                                                        className="m-1 rounded-0 form-control form-control-sm"
                                                                        value={inputState.newTPin}
                                                                        onChange={(e) => {
                                                                            if (
                                                                                e.target.value != "" &&
                                                                                !onlyNumeric(e.target.value)
                                                                            ) {
                                                                                setFormErrors({
                                                                                    ...formErrors,
                                                                                    newTpin: "T Pin should contain only digit",
                                                                                });
                                                                            } else if (e.target.value.length > 6) {
                                                                                setFormErrors({
                                                                                    ...formErrors,
                                                                                    newTpin:
                                                                                        "T Pin doesn't contains more than 6 digit",
                                                                                });
                                                                            } else {
                                                                                setInputState({
                                                                                    ...inputState,
                                                                                    newTPin: e.target.value,
                                                                                });
                                                                                setFormErrors({
                                                                                    ...formErrors,
                                                                                    newTpin: "",
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p style={{ color: "red" }}>{formErrors.newTpin}</p>

                                                                    <Form.Control

                                                                        placeholder="confirm T Pin"
                                                                        type="password"
                                                                        autocomplete="new-password"
                                                                        autoCorrect="off"
                                                                        autoSave="new-password"
                                                                        className="m-1 rounded-0 form-control form-control-sm"
                                                                        value={inputState.confirmTPin}
                                                                        onChange={(e) =>
                                                                            setInputState({
                                                                                ...inputState,
                                                                                confirmTPin: e.target.value,
                                                                            })
                                                                        }
                                                                    />
                                                                    <Button
                                                                        variant="success"
                                                                        className="m-1 rounded-0 btn btn-sm"
                                                                        onClick={changeTPin}
                                                                    >
                                                                        Change TPIN
                                                                    </Button>
                                                                </div>
                                                            )}
                                                            {!currentTPinCheck && (
                                                                <div className="py-2">
                                                                    <span className="pr-1">
                                                                        <Button

                                                                            className=" rounded-100 btn btn-sm px-3"
                                                                            onClick={submitCurrentTPin}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </span>
                                                                    <span className="pl-1">
                                                                        <Button

                                                                            className=" rounded-100 btn btn-sm px-3"
                                                                            onClick={requestTPin}
                                                                        >
                                                                            Request T PIN
                                                                        </Button>
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </Form.Group>
                                                    </div>



                                                    <div className="col-6 p-0 m-0">
                                                        <div className="pb-2">
                                                            <strong>M-PIN</strong>
                                                        </div>
                                                        <Form.Group>
                                                            <Button

                                                                className=" rounded-100 btn btn-sm px-3"
                                                                onClick={requestMPin}
                                                            >
                                                                Request M PIN
                                                            </Button>
                                                        </Form.Group>
                                                    </div>

                                                </Row>

                                                <Row className="p-2">

                                                    <Col className="border p-2 col-12">
                                                        <strong className="mx-2" >Login OTP </strong>

                                                        <BootstrapSwitchButton
                                                            width={100}
                                                            checked={userConfig.login_otp == "Y"}
                                                            onstyle="primary"
                                                            onChange={(checked) => {
                                                                setUserConfig({ ...userConfig, login_otp: checked ? "Y" : "N" })
                                                            }}
                                                        />

                                                    </Col>
                                                    <Col className="p-0 col-12">
                                                        <Invalid
                                                            text={"Disabling OTP (One-Time Password) may reduce security. Users are responsible for any unauthorized transactions if OTP is deactivated."}
                                                            style={"card-inverse-warning"
                                                            }
                                                        />

                                                    </Col>

                                                    <Col className="border p-2 col-12">
                                                        {otpSent && (
                                                            <React.Fragment>
                                                                <div className="form-group">
                                                                    <label> OTP</label>
                                                                    <div className="input-group">
                                                                        <input
                                                                            type="password"
                                                                            maxLength={6}
                                                                            className="form-control form-control-lg no-glow"
                                                                            id="exampleInputPassword"
                                                                            placeholder="Enter OTP"
                                                                            onChange={(e) => {
                                                                                //  setError("");
                                                                                setOtpText(e.target.value);
                                                                            }}
                                                                            value={otpText}
                                                                        />
                                                                    </div>
                                                                    <p className="text-danger text-left p-0">{formErrors.otp}</p>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </Col>

                                                    <Col className="p-0 my-4 col-12">
                                                        {otpSent && (
                                                            <button className="btn btn-primary" onClick={validateOtp}>Validate OTP</button>

                                                        )}

                                                        {!otpSent && (
                                                            <button className="btn btn-primary" onClick={sendOtp}>Update Setting</button>

                                                        )}

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                    </div>}

                            </div>
                        </div>






































                        {/* <Row className="p-4">
              <Col md={4} lg={4} sm={12} className="border">
                <div className="p-2" style={{ textAlign: "center" }}>
                  <strong>Personal Details</strong>
                </div>
                <hr />
                <List as="ul">
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      User ID: {state.Profileinfo?.uscode}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Mobile: {state.Profileinfo?.mobile}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Firm: {state.Profileinfo?.name}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      E-Mail: {state.Profileinfo?.email}
                    </b>
                  </List.Item>
                </List>

                <hr />
                <div className="p-2" style={{ textAlign: "center" }}>
                  <strong>Parent Details</strong>
                </div>
                <hr />
                <List as="ul">
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Firm: {state.Profileinfo?.parent_name}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Mobile: {state.Profileinfo?.parent_mobile}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Email: {state.Profileinfo?.parent_email}
                    </b>
                  </List.Item>
                </List>
              </Col>

              <Col md={8} lg={8} sm={12} className="p-3 border">
                <div>
                  <b>Personal details and Change T-PIN</b>
                  <hr />

                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <strong>Profile</strong>
                      </div>

                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.name}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.email}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Shop Name</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.shopname}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <strong>Password</strong>
                      </div>

                      <Form.Group>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          placeholder="current password"
                          type="password"
                          className="m-1 rounded-0 form-control form-control-sm"
                          onChange={(e) =>
                            setInputState({
                              ...inputState,
                              currentPassword: e.target.value,
                            })
                          }
                        />
                        {currentPasswordCheck && (
                          <div>
                            <Form.Control
                              placeholder="new password"
                              type="password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  newPassword: e.target.value,
                                })
                              }
                            />
                            <Form.Control
                              placeholder="confirm password"
                              type="password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  confirmPassword: e.target.value,
                                })
                              }
                            />
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={changePassword}
                            >
                              Submit New Password
                            </Button>
                          </div>
                        )}

                        {!currentPasswordCheck && (
                          <div>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentPassword}
                            >
                              Submit
                            </Button>
                           
                          </div>
                        )}
                      </Form.Group>

                      <div style={{ textAlign: "center" }}>
                        <strong>T-PIN</strong>
                      </div>
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-prepend bg-transparent">
                            <span className="input-group-text bg-transparent border-right-0 no-glow">
                              <i className="mdi mdi-lock-outline text-primary"></i>
                            </span>
                          </div>
                          <input
                            type={tpinShow ? "text" : "password"}
                            className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                            id="exampleInputPassword"
                            placeholder="Current T Pin"
                            onChange={(e) =>
                              setInputState({
                                ...inputState,
                                currentTPin: e.target.value,
                              })
                            }
                          />
                          <div className="input-group-prepend bg-transparent">
                            <span
                              className="input-group-text bg-transparent border-left-0 no-glow"
                              onClick={showTPinClick}
                              style={{ borderRadius: "4px" }}
                            >
                              <i
                                className={`mdi text-primary ${
                                  tpinShow ? "mdi-eye" : "mdi-eye-off"
                                }`}
                              ></i>
                            </span>
                          </div>
                        </div>

                        {currentTPinCheck && (
                          <div>
                            <Form.Control
                              placeholder="new T Pin"
                              type="password"
                              autocomplete="new-password"
              autoCorrect="off"
              autoSave="new-password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              value={inputState.newTPin}
                              onChange={(e) => {
                                if (
                                  e.target.value != "" &&
                                  !onlyNumeric(e.target.value)
                                ) {
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin: "T Pin should contain only digit",
                                  });
                                } else if (e.target.value.length > 6) {
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin:
                                      "T Pin doesn't contains more than 6 digit",
                                  });
                                } else {
                                  setInputState({
                                    ...inputState,
                                    newTPin: e.target.value,
                                  });
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin: "",
                                  });
                                }
                              }}
                            />
                            <p style={{ color: "red" }}>{formErrors.newTpin}</p>

                            <Form.Control
                              placeholder="confirm T Pin"
                              type="password"
                              autocomplete="new-password"
                              autoCorrect="off"
                              autoSave="new-password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              value={inputState.confirmTPin}
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  confirmTPin: e.target.value,
                                })
                              }
                            />
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={changeTPin}
                            >
                              Change TPIN
                            </Button>
                          </div>
                        )}
                        {!currentTPinCheck && (
                          <div>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentTPin}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={requestTPin}
                            >
                              Request T PIN
                            </Button>
                          </div>
                        )}
                      </Form.Group>

                      <div style={{ textAlign: "center" }}>
                        <strong>M-PIN</strong>
                      </div>
                      <Form.Group>
                        <Button
                          variant="success"
                          className="m-1 rounded-0 btn btn-sm"
                          onClick={requestMPin}
                        >
                          Request M PIN
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};
export default MyProfile;
