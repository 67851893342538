import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import Select from "react-select";
import { GiReceiveMoney } from "react-icons/gi";
import { SlCalender } from "react-icons/sl";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaCalendarCheck, FaRegUserCircle } from "react-icons/fa";
import { CiBookmarkCheck } from "react-icons/ci";
import { Button } from "react-bootstrap";
import { Recordrepaymentadmin } from "./Modal";

const Loaddetailadmin = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const loanStatusOptions = [
    { value: "all_loans", label: "All Loans" },
    { value: "open_loans", label: "Open Loans" },
    { value: "paused_loans", label: "Paused Loans" },
    { value: "closed_loans", label: "Closed Loans" },
  ];
  const [currentLoanNumber, setCurrentLoanNumber] = useState("");

  const [activeTab, setActiveTab] = useState(loanStatusOptions[0].value);
  const [selectedLoanStatus, setSelectedLoanStatus] = useState(
    loanStatusOptions[0]
  );
  const [activeLoan, setActiveLoan] = useState(null);

  const [repaymentdata, setrepaymentdata] = useState([]);
  const [loandata, setLoandata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleLoanStatusChange = (option) => {
    setSelectedLoanStatus(option);
    setActiveTab(option.value);
  };
  const Employee = JSON.parse(sessionStorage.getItem("Employee"));

  console.log(Employee, "fillll");
  const GetLoanRecord = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          type: "1",
          empid: Employee.empid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetLoanRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setLoandata(data.data);
        if (data.data.length > 0) {
          setActiveTab(data.data[0].pk_loan_id);
          setCurrentLoanNumber(data.data[0].loan_number);
          setActiveLoan(data.data[0]);
          Getrepaymentrecord(data.data[0].pk_loan_id);

        }
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getrepaymentrecord = async (loanid) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
          loanid: loanid,
          empid: Employee.empid,
         
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetLoanRepaymentRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setrepaymentdata(data.data);
    
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    GetLoanRecord();
    Getrepaymentrecord();
  }, []);
  const getStatusLabel = (status) => {
    switch (status) {
      case "P":
        return "Open";
      case "C":
        return "Closed";
      case "O":
        return "Overdue";
      default:
        return "Unknown";
    }
  };
  const handleTabChange = (loan) => {
    setActiveTab(loan.pk_loan_id);
    setCurrentLoanNumber(loan.loan_number);
    setActiveLoan(loan);
    Getrepaymentrecord(loan.pk_loan_id);
  };
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
       <Recordrepaymentadmin show={showModal} handleClose={handleCloseModal} activeLoan={activeLoan} Getrepaymentrecord={Getrepaymentrecord}
   />
   
      <div
        className="p-md-2 p-1"
        style={{ background: "#fff", position: "relative" }}
      >
        <div className="row">
          <div className="col-3" style={{ borderRight: "1px solid #e0e0e0" }}>
            <Select
              value={selectedLoanStatus}
              onChange={handleLoanStatusChange}
              options={loanStatusOptions}
              placeholder="Select Loan Status"
              styles={{
                container: (base) => ({
                  ...base,
                  width: "12rem",
                }),
                control: (base) => ({
                  ...base,
                  border: "none",
                  boxShadow: "none",
                  fontSize: "1.3rem",
                }),
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <p
              className="m-0"
              style={{ fontSize: "1.3rem", fontWeight: "400" }}
            >
              {currentLoanNumber}
            </p>
          </div>
          <div className="col  d-flex align-items-center justify-content-end">
            <Button
              className="btn btn-sm btn-success d-flex justify-content-center"
              onClick={handleShowModal}
            >
              Record Repayment
            </Button>
            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Link to="/retailer/Loan">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr className="m-0" />
        <div className="row">
          <div className="col-3" style={{ borderRight: "1px solid #e0e0e0" }}>
            <div
              className="nav  p-0 flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {loandata.map((loan) => (
                <a
                  className={`nav-link m-0 p-2 ${
                    activeTab === loan.pk_loan_id ? "active" : ""
                  }`}
                  id={`v-pills-${loan.pk_loan_id}-tab`}
                  data-toggle="pill"
                  href={`#v-pills-${loan.pk_loan_id}`}
                  role="tab"
                  aria-controls={`v-pills-${loan.pk_loan_id}`}
                  aria-selected={
                    activeTab === loan.pk_loan_id ? "true" : "false"
                  }
                  onClick={() => handleTabChange(loan)}
                  key={loan.pk_loan_id}

                  style={{
                    backgroundColor:
                      activeTab === loan.pk_loan_id ? "#EEEEEE" : "",
                  }}
                >
                  <div className="row m-0 p-0">
                    <div className="col-8 m-0 p-0">
                      <p style={{ color: "#000" }} className="m-0">
                        {loan.emp_name}
                      </p>
                      <p style={{ color: "grey" }} className="m-0">
                        {loan.loan_name}
                      </p>
                    </div>
                    <div className="col m-0 p-0 ">
                      <p style={{ color: "#000" }} className="m-0">
                        <b>{"₹" + loan.loan_amount}</b>
                      </p>
                      <p className="m-0" style={{ color: "cornflowerblue" }}>
                        {getStatusLabel(loan.status)}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="col-9">
            <div className="tab-content" id="v-pills-tabContent">
              {loandata.map((loan) => (
                <div
                  className={`tab-pane fade ${
                    activeTab === loan.pk_loan_id ? "show active" : ""
                  }`}
                  id={`v-pills-${loan.pk_loan_id}`}
                  role="tabpanel"
                  aria-labelledby={`v-pills-${loan.pk_loan_id}-tab`}
                  key={loan.pk_loan_id}
                >
                  <div className="row ">
                    <div className="col-1">
                      <GiReceiveMoney
                        style={{ fontSize: "2rem", color: "cornflowerblue" }}
                      />
                    </div>
                    <div className="col">
                      <p className="m-0 "> {loan.loan_name}</p>
                      <p className="m-0 " style={{ fontSize: "1rem" }}>
                        {" "}
                        <b>{"₹" + loan.loan_amount}</b>
                      </p>
                    </div>
                    <div className="col">
                      <p className="m-0 ">Availed by</p>
                      <p
                        className="m-0 d-flex align-items-center"
                        style={{ fontSize: "1rem", fontWeight: "500" }}
                      >
                        <FaRegUserCircle />
                      {loan.emp_name}
                      </p>
                    </div>
                    <div className="col">
                      <p className="m-0 ">Instalment Amount</p>
                      <p className="m-0 " style={{ fontSize: "1rem" }}>
                        <b>{loan.installment_amount}</b>
                      </p>
                    </div>
                    <div className="col">
                      <p className="m-0 ">Status</p>
                      <p className="m-0 ">0%</p>
                    </div>
                  </div>
                  <hr className="mb-1" />
                  <div className="row">
                    <div className="col">
                      <p className="m-0">
                        {" "}
                        <SlCalender /> &nbsp;Next Instalment Date: 25/06/2024
                      </p>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <p className="m-0">1 more Loan(s) in progress</p>
                    </div>
                  </div>
                  <hr className="mt-1 " />
                  <h6 className="d-flex align-items-center">
                    <IoIosInformationCircleOutline />
                    &nbsp; Other Details
                  </h6>
                  <div className="row  m-0 p-0">
                    <div className="col">
                      <div className="row">
                        <p className="col m-0 ">Disbursement Date: </p>
                        <p className="col m-0 ">
                          {" "}
                          <b>
                            {new Date(
                              loan.disbursment_date
                            ).toLocaleDateString()}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <p className="col m-0 ">Loan Closing Date: </p>
                        <p className="col m-0 ">
                          {" "}
                          <b>
                            {" "}
                            {new Date(
                              loan.completeion_date
                            ).toLocaleDateString()}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row  m-0 p-0">
                    <div className="col">
                      <div className="row">
                        <p className="col">Perquisite Rate: </p>
                        <p className="col">
                          <b> {loan.perquiste_rate}%</b>
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <p className="col">Reason : </p>
                        <p className="col">
                          {" "}
                          <b>{loan.reason}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-1" />
                  <h6 className="d-flex align-items-center">
                    <CiBookmarkCheck />
                    &nbsp; Loan Repayment Summary
                  </h6>
                  <div
  className="card p-2 m-4"
  style={{
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    background: "#FFFAF5",
  }}
>
<div style={{ background: "#FFFAF5" }}>
  {repaymentdata.length === 0 ? (
    <p>No data found</p>
  ) : (
    <>
      <div className="row ">
        <div className="col">
          <p className="m-0">Repay Amount:</p>
        </div>
        <div className="col">
          <p className="m-0">Repay Date:</p>
        </div>
        <div className="col">
          <p className="m-0">Pay Mode:</p>
        </div>
        <div className="col">
          <p className="m-0">Remaining Amount:</p>
        </div>
      </div>
      {repaymentdata.map((record) => (
        <div key={record.pk_record_id} className="row ">
          <div className="col">
            <p className="m-0" style={{color:"green"}}>₹{record.repay_amount}</p>
          </div>
          <div className="col">
            <p className="m-0">{record.repay_date}</p>
          </div>
          <div className="col">
            <p className="m-0" style={{fontWeight:"500"}}>{record.pay_mode}</p>
          </div>
          <div className="col">
            <p className="m-0" style={{color:"red"}}>₹{record.remainig_amount}</p>
          </div>
        </div>
      ))}
    </>
  )}
</div>


</div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loaddetailadmin;
