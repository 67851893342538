import React, { useState, useEffect,useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import OTPInput, { ResendOTP } from "otp-input-react";
import { BASE_URL_PAYNOW, COMPANY_CODE } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import { GlobalContext } from "../../Context/GlobalState";
import swal from "sweetalert";
import {
  CLIENT_PAN_NUMBER,
  COMPANY_RETAILER_COMPANY_ID,
} from "../../utils/Client";
import { basicAuth } from "../../config";
import { COLOR_LOGIN_SCREEN_STRIPE } from "../../utils/Themer";
const convert = require("xml-js");

const CustomerKyc = () => {
  const { transactionLoader, setTransactionLoaderState, user } =
    useContext(GlobalContext);
  const { username, password } = basicAuth;
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();

  const Datehandler = (date) => {
    const inputDate = new Date(date);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      setFormErrors({
        ...formErrors,
        DOB: "*Please enter Date before 18 years",
      });
    }
    setStartDate(date);
  };

  const [AccessT, setAccessT] = useState("");
  const [OtpToken, setOtpToken] = useState("");
  const [KycToken, setKycToken] = useState("");
  const InitialValues = {
    FullName: "",
    Gender: "",
    Mobile: "",
    Email: "",
    Title: "",
    Pincode: "",
    PAN: "",
    State: "",
    Adhar: "",
  };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [States, setStates] = useState([]);
  const [Otp, setOtp] = useState("");
  const [Wadh, setWadh] = useState("");
  const [ChooseFinger, setChooseFinger] = useState("");
  const [devicePort, setDevicePort] = useState("11100");
  const [device, setDevice] = useState(1);
  const [pidData, setPidData] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    error: "",
    success: "",
  });

  const mantraPorts = [
    "11100",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
  ];

  const Login = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: password,
      email: username,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(`${BASE_URL_PAYNOW}/api/Account/Login`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setAccessT(result.accessToken);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const dateToStr = (date) =>
    `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()}`;

  const CreatCustEKyc = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: formValues.Title,
      p3: formValues.FullName,
      p4: formValues.PAN,
      p5: "No",
      p6: "200000",
      p7: "200000",
      p8: dateToStr(startDate),
      p9: formValues.Gender,
      p10: formValues.Email,
      p11: COMPANY_CODE,
      p12: formValues.State,
      p13: CLIENT_PAN_NUMBER,
    });

    console.log(AccessT);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_PAYNOW}/api/kyc/CreateCustomer`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setOtpToken(result.data.otpToken);
          if (result.responseCode == "00") {
            cogoToast.success(result.responseMsg);
          } else {
            swal(
              result.responseMsg ? result.responseMsg : "Failed",
              JSON.stringify(result.data),
              "error"
            );
          }
          setTransactionLoaderState(false);
        })
        .catch((error) => {
          setTransactionLoaderState(false);
          console.log("error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const OtpVal = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: OtpToken,
      p3: Otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_PAYNOW}/api/kyc/OTPValidation`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setKycToken(result.data.kycToken);
          if (result.data.kycToken) {
            GetAdharWadh(result.data.kycToken);
          }
          if (result.responseCode == "00") {
            cogoToast.success(result.responseMsg);
          } else {
            swal(
              result.responseMsg ? result.responseMsg : "Failed",
              JSON.stringify(result.data),
              "error"
            );
          }
          setTransactionLoaderState(false);
        })
        .catch((error) => {
          setTransactionLoaderState(false);
          console.log("error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      CreatCustEKyc();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.FullName) {
      errors.FullName = "*Full Name is required!";
    }
    if (!values.Title) {
      errors.Title = "*Title is required!";
    }
    if (!values.Gender) {
      errors.Gender = "*Gender is required!";
    }
    if (!values.PAN) {
      errors.PAN = "*Pan is required!";
    }
    if (!values.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (values.Mobile.length != 10) {
      errors.Mobile = "*Enter valid Mobile number";
    }
    if (!values.Email) {
      errors.Email = "*Email is required!";
    } else if (!regex.test(values.Email)) {
      errors.Email = "*This is not a valid email format!";
    }

    if (!values.State) {
      errors.State = "*Please select a state!";
    }
    if (!values.Adhar) {
      errors.Adhar = "*Adhar is required!";
    } else if (values.Adhar.length != 12) {
      errors.Adhar = "*Please enter valid Adhar no.!";
    }

    const inputDate = new Date(startDate);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      errors.DOB = "*Please enter Date before 18 years";
    }

    return errors;
  };

  const GetAdharWadh = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: token,
      p3: COMPANY_CODE,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setTransactionLoaderState(true);
    fetch(`${BASE_URL_PAYNOW}/api/kyc/GetAadhaarWadh`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setWadh(result.data.wadh);
        cogoToast.info(result.responseMsg);
        setTransactionLoaderState(false);
      })
      .catch((error) => {
        setTransactionLoaderState(false);
        console.log("error", error);
      });
  };

  const discoverDevice = () => {
    let found = false;
    for (let i = 0; i < mantraPorts.length && !found; i++) {
      const currentPort = mantraPorts[i];
      try {
        discoverDeviceReq(currentPort, () => {
          found = true;
          setDevicePort(currentPort);
          getDeviceInfo(currentPort);
        });
      } catch (error) {}
    }
  };

  function discoverDeviceReq(port, callback) {
    let url;
    if (device == 0) {
      url = `https://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `https://localhost:${port}/getDeviceInfo`;
    }
    console.log(url);
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo.additional_info.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            // alert('device is ready to use');
            callback();
          }
          // setDeviceInfo(data1);
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send();
  }

  function getDeviceInfo(port) {
    let url;
    if (device == 0) {
      url = `https://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `https://localhost:${port}/getDeviceInfo`;
    }
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo.additional_info.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            // alert('device is ready to use');
          }
          setDeviceInfo(data1);
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send();
  }

  useEffect(() => {
    discoverDevice();
  }, [device]);

  function morphoCapture(isKyc) {
    console.log("called");

    var url = `https://localhost:${devicePort}/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        `<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="${Wadh}" posh=""/>` +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        `<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="${Wadh}" posh=""/>` +
        "</PidOptions>";
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion

          if (test5 > 0) {
            // alert(xhr.responseText);
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            setPidData(test1);

            // var convert = Buffer.from(test1).toString("base64")
            // const ReplacePid = convert.replaceAll('/n', '')
            // const FinalPid = ReplacePid.replaceAll('/r', '')
            // console.log(FinalPid);
            // alert("Captured Successfully");
            // var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            //  setPidData(JSON.parse(result1));
            // callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  function mantraCapture(isKyc) {
    var url = `https://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="${Wadh}" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>`;
    } else {
      PIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="${Wadh}" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>`;
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //  fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion
          var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
          setPidData(JSON.parse(result1));
          if (test5 > 0) {
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            alert(xhr.responseText);
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            setPidData(test1);
            // alert("Captured Successfully");
            //var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            //setPidData(JSON.parse(result1));
            // callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send(PIDOPTS);
  }
  console.log(pidData);

  const capture = () => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    } else if (
      device == 0 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      mantraCapture();
    } else if (
      device == 1 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      morphoCapture();
    } else {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    }
  };

  const Biometric = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${AccessT}`);
    myHeaders.append("Content-Type", "application/json");

    const PID = Buffer.from(pidData).toString("base64");
    const ReplacePid = PID.replaceAll("/n", "");
    const FinalPid = ReplacePid.replaceAll("/r", "");

    var raw = JSON.stringify({
      p1: formValues.Mobile,
      p2: KycToken,
      p3: COMPANY_CODE,
      p4: formValues.Adhar,
      p5: "0",
      p6: "",
      p7: FinalPid,
      p8: ChooseFinger,
      p9: deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
      p10: "",
      p11: "",
      p12: COMPANY_RETAILER_COMPANY_ID,
      p13: "",
      p14: "Y",
      p15: "EKYC",
      p16: "null",
      p17: "Authentication",
      p18: Wadh,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setTransactionLoaderState(true);

    fetch(
      `${BASE_URL_PAYNOW}/api/kyc/AadhaarBiometricKYCCustomer`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.responseCode == "00") {
          swal(
            result.responseMsg ? result.responseMsg : "Success",
            JSON.stringify(result.data),
            "success"
          );
        } else {
          swal(
            result.responseMsg ? result.responseMsg : "Failed",
            JSON.stringify(result.data),
            "error"
          );
        }
        setTransactionLoaderState(false);
      })
      .catch((error) => {
        setTransactionLoaderState(false)
        console.log("error", error);
      });
  };

  const StateList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic cmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ=="
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userName: "string",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL_PAYNOW}/api/operator/Getstate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setStates(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    discoverDevice();
    Login();
    StateList();
  }, []);

  const Finger = [
    {
      label: "Right Little",
      value: "10~RightLittle",
    },
    {
      label: "Right Ring",
      value: "9~RightRing",
    },
    {
      label: "Right Middle",
      value: "8~RightMiddle",
    },
    {
      label: "Right Index",
      value: "7~RightIndex",
    },
    {
      label: "Right Thumb",
      value: "6~RightThumb",
    },
    {
      label: "Left Thumb",
      value: "5~LeftThumb",
    },
    {
      label: "Left Index",
      value: "4~LeftIndex",
    },
    {
      label: "Left Middle",
      value: "3~LeftMiddle",
    },
    {
      label: "Left Ring",
      value: "2~LeftRing",
    },
    {
      label: "Left Little",
      value: "1~LeftLittle",
    },
  ];

  return (
    <div className="p-3 h-100" style={{ fontFamily: "auto", height: "100vh" }}>
      <div
        className="card h-100"
        style={{ textAlign: "center", height: "100vh" }}
      >
        <div
          className="p-2"
          style={{
            backgroundColor: COLOR_LOGIN_SCREEN_STRIPE,
            color: "black",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        >
          <strong style={{ color: "white" }}>CUSTOMER E-KYC</strong>
        </div>
        {!OtpToken ? (
          <div className="card-body h-100" style={{ height: "100vh" }}>
            <Row>
              <Col md={6} lg={6} sm={12} className="text-left">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile No"
                  name="Mobile"
                  value={formValues.Mobile}
                  onChange={handleChange}
                />
                <p style={{ color: "red" }}>{formErrors.Mobile}</p>
              </Col>

              <Col md={2} lg={2} sm={3} className="text-left">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="Title"
                  className="border"
                  value={formValues.Title}
                  onChange={handleChange}
                >
                  <option selected>Title</option>
                  <option>Mr</option>
                  <option>Mrs</option>
                </Form.Control>

                <p style={{ color: "red" }}>{formErrors.Title}</p>
              </Col>
              <Col md={4} lg={4} sm={9} className="text-left ">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Full name here"
                  name="FullName"
                  value={formValues.FullName}
                  onChange={handleChange}
                />
                <p style={{ color: "red" }}>{formErrors.FullName}</p>
              </Col>

              <Col md={6} lg={6} sm={12} className="mt-3 text-left">
                <Form.Label>PAN Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your PAN no."
                  name="PAN"
                  value={formValues.PAN}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      PAN: e.target.value.toUpperCase(),
                    });
                  }}
                  style={{ textTransform: "uppercase" }}
                  maxLength={10}
                />
                <p style={{ color: "red" }}>{formErrors.PAN}</p>
              </Col>
              <Col md={6} lg={6} sm={12} className="mt-3 text-left">
                <Form.Label>Aadhaar Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Adhar no."
                  name="Adhar"
                  value={formValues.Adhar}
                  onChange={(e) => {
                    if (e.target.value.length <= 12) {
                      setFormValues({
                        ...formValues,
                        Adhar: e.target.value,
                      });
                    }
                  }}
                  maxLength={12}
                />
                <p style={{ color: "red" }}>{formErrors.Adhar}</p>
              </Col>
              <Col md={6} lg={6} sm={12} className="mt-3">
                <Form.Group className="text-left">
                  <Form.Label className="text-left">Select Gender</Form.Label>
                  <Form.Control
                    as="select"
                    name="Gender"
                    value={formValues.Gender}
                    onChange={handleChange}
                    className="border"
                  >
                    <option selected>Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </Form.Control>
                  <p style={{ color: "red" }}>{formErrors.Gender}</p>
                </Form.Group>
              </Col>

              <Col md={6} lg={6} sm={12} className="mt-3">
                <Form.Group className="text-left">
                  <Form.Label>Date of Birth</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    value={startDate}
                    selected={startDate}
                    todayButton="Today"
                    dateFormat="dd/MM/yyyy"
                    onChange={Datehandler}
                    className="form-control"
                    name="DOB"
                  />
                  <p style={{ color: "red" }}>{formErrors.DOB}</p>
                </Form.Group>
              </Col>

              <Col md={6} lg={6} sm={12} className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Enter email id"
                  name="Email"
                  value={formValues.Email}
                  onChange={handleChange}
                />
                <p style={{ color: "red" }}>{formErrors.Email}</p>
              </Col>

              {/* <Col md={6} lg={6} sm={12} className='mt-2'>
                                <Form.Control type="text" placeholder='Flat No./House No.' />
                            </Col>

                            <Col md={6} lg={6} sm={12} className='mt-2'>
                                <Form.Control type="text" placeholder='Street/Road' />
                            </Col> */}

              {/* <Col md={6} lg={6} sm={12} className='mt-2'>
                                <Form.Control type="text" placeholder='District' />
                            </Col>

                            <Col md={6} lg={6} sm={12} className='mt-2'>
                                <Form.Control type="number" placeholder="Postal pincode" name="Pincode" value={formValues.Pincode} onChange={handleChange} />
                                <p style={{ color: 'red' }}>{formErrors.Pincode}</p>
                            </Col> */}

              <Col md={6} lg={6} sm={12} className="mt-2">
                <Form.Group>
                  <Form.Control
                    className="border"
                    as="select"
                    name="State"
                    value={formValues.State}
                    onChange={handleChange}
                  >
                    <option selected>Select State</option>
                    {States?.map((st) => (
                      <option>{st.stateName}</option>
                    ))}
                  </Form.Control>{" "}
                  <p style={{ color: "red" }}>{formErrors.State}</p>
                </Form.Group>
              </Col>

              <Col md={6} lg={6} sm={12} className="mt-2">
                <button
                  className="btn btn-success btn-block"
                  onClick={handleSubmit}
                >
                  Proceed
                </button>
              </Col>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Row>
          </div>
        ) : !Wadh ? (
          <div className="card-body">
            <div className="row">
              <div className="col text-center">
                <h5 className="mt-4 mb-3">Enter the OTP sent to you</h5>
                <OTPInput
                  style={{ display: "dflex" }}
                  value={Otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
                <div className="align-items-center">
                  <Button
                    variant="info"
                    className="font-weight-bold mb-2 mt-3"
                    onClick={OtpVal}
                  >
                    Verify OTP
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-body">
            <Row>
              <Col md={6} lg={6} sm={12}>
                <Form.Control
                  onChange={(e) => setChooseFinger(e.target.value)}
                  as="select"
                >
                  {Finger.map((F) => (
                    <option value={F.value}>{F.label}</option>
                  ))}
                </Form.Control>
              </Col>
              <Col md={6} lg={6} sm={12}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setDevice(e.target.value);
                  }}
                >
                  <option>--Please Select--</option>
                  <option value={1}>Morpho MSO 1300</option>
                  <option value={0}>Mantra MFS 100</option>
                  <option disabled={true}>Startek FM220U</option>
                  <option disabled={true}>Precision</option>
                </Form.Control>
              </Col>
              <Col md={6} lg={6} sm={12}>
                <button onClick={capture} className="btn btn-primary btn-block">
                  Capture Biometric Data
                </button>
              </Col>
              <Col md={6} lg={6} sm={12}>
                <button
                  onClick={Biometric}
                  className="btn btn-success btn-block"
                >
                  Submit
                </button>
              </Col>

              <Col sm={12}>
                {snackbarState.success != "" && (
                  <div className="alert alert-success" role="alert">
                    {snackbarState.success}
                  </div>
                )}
                {snackbarState.error != "" && (
                  <div className="alert alert-danger" role="alert">
                    {snackbarState.error}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerKyc;
