import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { MD5 } from "crypto-js";
import crypto from "crypto";

import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleCaptcha from "../SalaryWeb/captha";
import {
  BASE_URL_API_TRANXT_SERVICE_API,
  BASE_URL_API_TRANXT_UAT_API,
} from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
const Loginpayroll = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const { setUserInfo } = useContext(GlobalContext);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [username1, setusername1] = useState("");
  const [password1, setpassword1] = useState("");
  const [showpassword1, setShowpassword1] = useState(false);
  const [username1Error, setusername1Error] = useState("");
  const [password1Error, setpassword1Error] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({});
  const [otpError, setOtpError] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);
  const handleCaptchaSuccess = () => {
    setCaptchaValid(true);
    setError("");
  };

  const handleStepChange = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    }
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  // const createHash = () => {
  //   return MD5(password1).toString();
  // };
  const handleusername1Change = (event) => {
    setusername1(event.target.value);
  };

  const handlepassword1Change = (event) => {
    setpassword1(event.target.value);
  };

  const handleTogglepassword1 = () => {
    setShowpassword1(!showpassword1);
  };

  const history = useHistory("");

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const createHash = (password1) => {
    return crypto.createHash("md5").update(password1).digest("hex");
  };

  const { password, username } = billpunchbasicauth;
  const handleSubmit = async (e) => {
    console.log(handleSubmit, "lol");
    e.preventDefault();
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        us_loginid: username1,
        us_password: createHash(password1),
        step: currentStep.toString(),
        otp: currentStep === 1 ? "string" : otp,
        device: "Web",
        latitude: position.latitude,
        longitude: position.longitude,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,

        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/LoginSalaryV1`,
        requestOptions
      );
      console.log(requestOptions);
      const data = await res.json();

      if (data.status === "0" && currentStep === 1) {
        cogoToast.success(data.msg);
        setCurrentStep(2);
      } else if (data.status === "0" && currentStep === 2) {
        cogoToast.success(data.msg);

        setUserInfo(data.data);
      } else {
        cogoToast.error(data?.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const containerStyle = {
    display: "flex",
    height: "100vh",
    position: "relative",
  };

  const leftColumnStyle = {
    flex: 1,
    backgroundColor: "#1354FE",
  };

  const rightColumnStyle = {
    flex: 1,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      <GlobalContext.Consumer>
        {(state) =>
          state.user == null || state.user.us_type == "" ? (
            <div className="row m-0 p-0" style={containerStyle}>
              <div className="col" style={leftColumnStyle}>
                <div
                  className="d-flex justify-content-center m-md-5 m-2 pt-1"
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <h3 style={{ color: "#fff" }}>The easiest way to </h3>
                  <h3 style={{ color: "#fff" }}>manage company</h3>
                </div>
                <div className="d-flex justify-content-center align-item-center">
                  {" "}
                  <img
                    alt=""
                    className=""
                    src={require("../../../assets/images/pms/SalaryLoginadmindashboard.png")}
                    style={{
                      height: "25rem",
                    }}
                  />
                </div>
              </div>
              <div className="col" style={rightColumnStyle}>
                <div className="mt-md-5 pt-md-5 mt-4">
                  <div className="  d-flex justify-content-center">
                    <img
                      alt=""
                      src={require("../../../assets/images/logoblack.png")}
                      style={{ height: "2rem" }}
                    />
                  </div>

                  <div className="m-2">
                    <h5 className=" mx-md-4 mb-4 d-flex justify-content-center">
                      Login to your account
                    </h5>
                    <form className="mt-md-5 px-md-3" onSubmit={handleSubmit}>
                      <div className="form-group col-12 ">
                        <label htmlFor="username1">Username</label>
                        <input
                          maxLength={10}
                          type="text"
                          id="mobile"
                          className="form-control"
                          value={username1}
                          onChange={handleusername1Change}
                        />
                        {username1Error && (
                          <p className="text-danger">{username1Error}</p>
                        )}
                      </div>
                      <div className="form-group col-12">
                        <label htmlFor="password1">Password</label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-right-0 no-glow">
                            <i className="mdi mdi-lock-outline text-primary"></i>
                          </span>
                          <input
                            type={showpassword1 ? "text" : "password1"}
                            id="password1"
                            className="form-control"
                            value={password1}
                            onChange={handlepassword1Change}
                          />

                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={handleTogglepassword1}
                              style={{ height: "2.4rem", border: "1px solid" }}
                            >
                              <FontAwesomeIcon
                                icon={showpassword1 ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                        </div>
                        {password1Error && (
                          <p className="text-danger">{password1Error}</p>
                        )}
                      </div>
                      {/* <div className="form-group col-12 ">
                    <SimpleCaptcha onCaptchaSuccess={handleCaptchaSuccess} />{" "}
                  </div>
                 */}
                      <div className="form-group col-12">
                        {currentStep === 2 && (
                          <>
                            <label htmlFor="otp">
                              One-Time password (OTP)
                            </label>
                            <input
                              type="text"
                              id="otp"
                              className="form-control mb-md-3"
                              value={otp}
                              onChange={handleOtpChange}
                            />
                            {otpError && (
                              <p className="text-danger">{otpError}</p>
                            )}
                          </>
                        )}
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={handleSubmit}
                          >
                            {currentStep === 1 ? "Submit" : "Submit OTP"}
                          </button>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <Link to="/Forget_password">Forgot password1?</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : state.user.user_type == "A" ? (
            <Redirect to="/retailer/AdminDashboard" />
          ) : state.user.user_type == "S" ? (
            <Redirect to="/retailer/Superadmin_dashboard" />
          ) : state.user.user_type == "H" ? (
            <Redirect to="/retailer/HRDashboard" />
          ) : state.user.user_type == "E" ? (
            <Redirect to="/retailer/EmployeeDashboard" />
          ) : (
            <Redirect to="/" />
          )
        }
      </GlobalContext.Consumer>
    </>
  );
};

export default Loginpayroll;
