import React, { useState, useContext, useEffect } from "react";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";
import AllFundHoldReportTable from "./AllFundHoldReportTable";

const FundHoldReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div >
         
            < AllFundHoldReportTable  />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default FundHoldReport;
