import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import PymentsGetwayReportTable from "./PaymentGatewayReportTable";
import { GlobalContext } from "../../Context/GlobalState";

const PGReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
            <div
         style={{ padding: "1rem", backgroundColor: "#F2F6FC" }}
         className=" "
       >
         <div
           style={{ borderRadius: "10px" }}
           className="card mt-4 p-0 mx-2 border-light "
         >
           <div className="card-body m-0 p-2">
              <div>
              

              <PymentsGetwayReportTable />
              </div>
            </div>
          </div>
        </div>
          
        )
      }
    </GlobalContext.Consumer>
  );
};

export default PGReport;
