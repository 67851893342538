import React, { useEffect } from "react";
import { generateHashKey, generateHashKey2 } from "../utils/utilFuctions";

// import fs from "fs";

// BP1182

const Test = () => {
  const callMe = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "HASH_KEY",
      "JbGyLbVNF4FAjqQ9/hLwGMNXu1PnpAiqbap9P4IQyPWnA+r93klU74VNwvqql8N7"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userid: "R1008",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://apiservices.bumppypay.com/api/Payment/bbps/billPayOnline",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };
  const testt = async ()=>{
    const hash = await generateHashKey2("R1008")
    console.log(hash);
  }
  useEffect(() => {
    testt();
  }, []);
  return (
    <div>
      <form
        action="https://digipaydashboarduat.religaredigital.in/Login/Authenticate"
        method="Post"
      >
        <input
          type="text"
          name="authentication"
          value="038bvcHI8UH7BHPiU8cUdqWxTaYKS7wIWMkhE05Y8PWe%2fLxvKNyo4BehcnsE84xbLAKWRHDCLul9AukxnGUhVZ6U%2b7zWY%2f0cDPKYBQePVxxmk2y%2f5M%2b4Yg4Bg9PvEwCI43P%2b55YM2dPgeUcZIKktB73jN%2bJAuT7LcCM0JNbQDHTkUkJl0n9yyjjJyURPw5KQLWDVOcjO4BUH5CCMNNQVkgbQpW9eueHzd9n2fQN5hBYCOFeqzYwN3EEwlTiVHWQJzkLmf%2ftQbONB%2bdU00%2f7Zw%3d%3d"
        />{" "}
        <button type="submit"> Japnaam</button>
      </form>
      <button onClick={callMe}> call me</button>
    </div>
  );
};

export default Test;
