import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { Redirect } from "react-router";
import {
  CERTIFICATE_ADDRESS,
  CERTIFICATE_COMPANY_NAME,
  CERTIFICATE_WEBSITE,
  SUPPORT_EMAIL,
  SUPPORT_MOBILE_NUMBER,
} from "../utils/StringConstants";

const AgentCertificate = () => {
  const openInNewTab = (data) => {
    sessionStorage.setItem("certificate", JSON.stringify(data));
    const newWindow = window.open(
      `/pages/certificate`,
      "",
      "height=700,width=1100'"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ||
        context.Profileinfo == null ||
        context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <Container style={{ position: "relative", marginBottom: "5rem" }}>
            <section>
              <Row className="p-5">
                <img
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                  src={require("../../assets/images/company/certificate.png")}
                />

                <Col lg={12} md={12} sm={12} className="p-4 m-3">
                  <div style={{ textAlign: "center", marginTop: "150px" }}>
                    <h3>
                      <strong>CERTIFICATE</strong>
                    </h3>

                    <div className="mt-1 d-flex justify-content-center align-items-center">
                      <span style={{ fontSize: "1.2rem" }} className="">
                        <strong>This is to certify that</strong>
                      </span>
                      <div className="mt-1 ml-4 d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span style={{ fontSize: "1rem" }}>
                            {context.Profileinfo.name},
                          </span>
                          <span style={{ fontSize: "1rem" }}>
                            {context.Profileinfo.shopname}
                          </span>
                          <span style={{ fontSize: "1rem" }} className="ml-2">
                            {`[${context.Profileinfo.uscode}]`}
                          </span>
                          <span style={{ fontSize: "1rem" }} className="ml-2">
                            {`+91 ${context.Profileinfo.mobile}`}
                          </span>
                        </div>
                        <hr
                          style={{
                            height: ".1rem",
                            backgroundColor: "#1662AA",
                            width: "100%",
                            margin: "0rem",
                          }}
                        />
                      </div>
                    </div>

                    <h6 className="mt-4">
                      <strong>
                        is an authorized Business Correspondent Agent of the
                        Business Correspondent of {CERTIFICATE_COMPANY_NAME}
                      </strong>
                    </h6>
                    {/* <div style={{ textAlign: "center" }}>
                      <hr
                        style={{
                          height: ".1rem",
                          backgroundColor: "#1662AA",
                          width: "40vw",
                        }}
                      />
                    </div> */}
                    {/* <h5 className="mt-1 mb-1">
                      <strong>
                        is an authorised Agent of {CERTIFICATE_COMPANY_NAME}
                      </strong>
                    </h5> */}

                    {/* <div className="mt-3">
                      <h6>{COMPANY_NAME} Limited</h6>
                    </div> */}
                  </div>
                </Col>

                <Col
                  className="mt-1 p-2"
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <div style={{ textAlign: "center" }} className="ml-5">
                    <div style={{ textAlign: "center" }}>
                      <hr
                        style={{
                          width: "10vw",
                          backgroundColor: "black",
                          height: ".1vh",
                        }}
                      />
                      <small style={{ fontSize: 10 }}>
                        Authorised Signature
                      </small>
                      <div className="d-flex flex-column">
                        <h6 className="m-0">
                          <span>{`${context.Profileinfo.name} [${context.Profileinfo.uscode}]`}</span>
                        </h6>
                        {/* <strong>Distributor</strong> */}
                      </div>
                    </div>
                  </div>

                  <div style={{ textAlign: "center" }} className="mr-5">
                    <div className="text-center">
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={require("../../assets/images/company/certificate_signature.png")}
                          style={{ height: "3rem", width: "auto" }}
                        />
                        <hr
                          style={{
                            width: "15vw",
                            backgroundColor: "black",
                            height: ".1vh",
                          }}
                          className="m-0"
                        />
                      </div>

                      <small style={{ fontSize: 10 }}>
                        Authorised Signature
                      </small>
                      <h6>
                        <strong>{CERTIFICATE_COMPANY_NAME} Solutions</strong>
                      </h6>
                    </div>
                  </div>
                </Col>

                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "0px",
                    left: "48%",
                  }}
                  className="mb-3"
                >
                  <button
                    onClick={() => {
                      openInNewTab(context.Profileinfo);
                    }}
                  >
                    Print
                  </button>
                </div>
                <div className="col-11 mx-auto m-4 p-4">
                  <div style={{ textAlign: "center" }}>
                    <hr
                      style={{
                        height: ".1rem",
                        backgroundColor: "#BBBBBB",
                        width: "60vw",
                      }}
                    />
                  </div>
                  Regd: Office: {CERTIFICATE_ADDRESS} Tel:{" "}
                  {SUPPORT_MOBILE_NUMBER} | Website:
                  {CERTIFICATE_WEBSITE} | Email Id: {SUPPORT_EMAIL} |{" "}
                  <b>VALID TILL: 31 MARCH 2024</b> Terms & Conditions: *Valid
                  date is subject to subsistence of the agreement with{" "}
                  {CERTIFICATE_COMPANY_NAME}. as applicable to Business
                  Correspondent Agent and compliance with all the terms and
                  conditions thereof.*
                </div>
              </Row>
            </section>
          </Container>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default AgentCertificate;
