import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Container, Form, Button } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai"; // Import the close icon
import { billpunchbasicauth } from "../../utils/Credentials";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";

const Newsalarytemplateadmin = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;

  const [formData, setFormData] = useState({
    templateName: "",
    description: "",
    annualCTC: "",
  });

  const [earningData, setEarningData] = useState([]);
  const [reimbursementData, setReimbursementData] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [addedSections, setAddedSections] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with data:", formData);
    setFormData({
      templateName: "",
      description: "",
      annualCTC: "",
    });
  };

  const addComponent = (component, category) => {
    const newComponent = { ...component, category, id: new Date().getTime() };
    setSelectedComponents([...selectedComponents, newComponent]);
    if (!addedSections.includes(category)) {
      setAddedSections([...addedSections, category]);
    }
  };

  const removeComponent = (id) => {
    const updatedComponents = selectedComponents.filter((item) => item.id !== id);
    setSelectedComponents(updatedComponents);
    updateAddedSections(updatedComponents);
  };

  const updateAddedSections = (components) => {
    const updatedSections = [];
    accordionData.forEach((module) => {
      if (components.some((component) => component.category === module.category)) {
        updatedSections.push(module.category);
      }
    });
    setAddedSections(updatedSections);
  };

  const Getearningsalarycomponent = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        salary_id: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetSalaryComponents`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEarningData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const Getreimbursementcomponents = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFirmReimburement`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setReimbursementData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getearningsalarycomponent();
    Getreimbursementcomponents();
  }, []);

  const accordionData = [
    {
      category: "Earnings",
      subcategories: earningData.map((subitem) => ({
        name: subitem.earning_name,
        value: subitem.flxible_benifit_plan,
      })),
    },
    {
      category: "Deductions",
      subcategories: [
        { name: "PF", value: "Y" },
        { name: "Professional Tax", value: "Y" },
      ],
    },
    {
      category: "Reimbursements",
      subcategories: reimbursementData.map((subitem) => ({
        name: subitem.payslip_name,
        value: subitem.flexible_plan,
      })),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-3" style={{ borderRight: "1px solid grey" }}>
          <h5 className="col">Build your Template</h5>
          <p className="col">Include active salary components</p>
          <Container className="m-0 p-0">
            <Accordion>
              {accordionData.map((module, index) => (
                <Card className="m-0 p-2" key={index}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index.toString()}
                    className="m-0 p-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: ".2rem",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ color: "#000" }}>{module.category}</div>
                    <div>
                      <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                    </div>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body className="p-2">
                      {module.subcategories.map((subcat, subIndex) => (
                        <div
                          className="row"
                          key={subIndex}
                          onClick={() => addComponent(subcat, module.category)}
                        >
                          <div className="col-10">{subcat.name}</div>
                          <div className="col justify-content-end d-flex">
                            <div variant="link">+</div>
                          </div>
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Container>
        </div>

        <div className="col">
          <h5>New Salary Template</h5>
          <hr />
          <Form onSubmit={handleFormSubmit}>
            <div className="row">
              <Form.Group className="col-md-4 col-6" controlId="templateName">
                <Form.Label style={{ color: "#000" }}>Template Name</Form.Label>
                <Form.Control
                  type="text"
                  name="templateName"
                  placeholder="Enter template name"
                  value={formData.templateName}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="col-md-5" controlId="description">
                <Form.Label style={{ color: "#000" }}>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            <div
              className="card p-3"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            >
              <Form.Group
                controlId="annualCTC"
                className="d-flex align-items-center"
              >
                <Form.Label
                  style={{ color: "#000" }}
                  className="col-4 mr-3 mb-0"
                >
                  Annual CTC
                </Form.Label>
                <Form.Control
                  className="col-3"
                  type="text"
                  name="annualCTC"
                  placeholder="Enter annual CTC"
                  value={formData.annualCTC}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <div
                className="table-responsive"
                style={{
                  borderRadius: ".4em",
                  overflowX: "auto",
                  height: "100%",
                }}
              >
                <table className="table m-0 p-0" style={{ border: "none" }}>
                  <thead>
                    <tr style={{ borderBottom: "1px solid lightgrey" }}>
                      <th
                        style={{
                          color: "#000",
                          background: "#fff",
                          border: "none",
                        }}
                      >
                        SALARY COMPONENTS
                      </th>
                      <th
                        style={{
                          color: "#000",
                          background: "#fff",
                          border: "none",
                        }}
                      >
                        CALCULATION TYPE
                      </th>
                      <th
                        style={{
                          color: "#000",
                          background: "#fff",
                          border: "none",
                        }}
                      >
                        MONTHLY AMOUNT
                      </th>
                      <th
                        style={{
                          color: "#000",
                          background: "#fff",
                          border: "none",
                        }}
                      >
                        ANNUAL AMOUNT
                      </th>
                      <th
                        style={{
                          color: "#000",
                          background: "#fff",
                          border: "none",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedSections.map((section, sectionIndex) => (
                      <React.Fragment key={sectionIndex}>
                        <tr>
                          <td
                            colSpan="5"
                            style={{
                              color: "green",
                              background: "#fff",
                              border: "none",
                            }}
                          >
                            {section}
                          </td>
                        </tr>
                        {selectedComponents
                          .filter(
                            (component) => component.category === section
                          )
                          .map((component) => (
                            <tr
                              key={component.id}
                              style={{
                                borderBottom: "1px solid lightgrey",
                              }}
                            >
                              <td
                                style={{
                                  color: "#000",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                {component.name}
                              </td>
                              <td
                                style={{
                                  color: "#000",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                {component.value}
                              </td>
                              <td
                                style={{
                                  color: "#000",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                --
                              </td>
                              <td
                                style={{
                                  color: "#000",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                --
                              </td>
                              <td
                                style={{
                                  color: "#000",
                                  background: "#fff",
                                  border: "none",
                                }}
                              >
                                <Button
                                  variant="link"
                                  onClick={() =>
                                    removeComponent(component.id)
                                  }
                                >
                                  <AiOutlineClose style={{ color: "red" }} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Newsalarytemplateadmin;
