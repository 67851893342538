import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CiEdit } from "react-icons/ci";
import { FaAngleDown, FaAngleUp, FaEye } from "react-icons/fa";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Viewprviewepfcalcilation, Viewsepfspilit } from "./Modal";
import { AiOutlineClose } from "react-icons/ai";

const Epfformadmin = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [formData, setFormData] = useState({
    epfNumber: "",
    deductionCycle: "",
    employeeContributionRate: "",
    employerContributionRate: "",
    includeEmployerContribution: false,
    includeEmployerEDLIContribution: false,
    includeAdminCharges: false,
    overridePF: false,
    proRatePF: false,
    pfWageLess15000: false,
    abryEligible: false,
    abryOption: "",
  });

  const employeeContributionOptions = [
    { value: "12% of Actual PF Wage", label: "12% of Actual PF Wage" },
    {
      value: "Restrict Contribution to ₹15,000 of PF Wage",
      label: "Restrict Contribution to ₹15,000 of PF Wage",
    },
  ];

  const employerContributionOptions = [
    { value: "12% of Actual PF Wage", label: "12% of Actual PF Wage" },
    {
      value: "Restrict Contribution to ₹15,000 of PF Wage",
      label: "Restrict Contribution to ₹15,000 of PF Wage",
    },
  ];

  const [epfNumber, setEpfNumber] = useState("");
  const [epfdata, setepfdata] = useState("");

  const handleInputChange = (e) => {
    let { value } = e.target;

    value = value.replace(/[^A-Za-z0-9]/g, "");

    if (value.length > 2 && value.charAt(2) !== "/") {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 6 && value.charAt(6) !== "/") {
      value = value.slice(0, 6) + "/" + value.slice(6);
    }
    if (value.length > 13 && value.charAt(13) !== "/") {
      value = value.slice(0, 13) + "/" + value.slice(13);
    }

    setEpfNumber(value.toUpperCase());
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      console.log("shivani ", formData);
      var raw = JSON.stringify({
        firmid: user.firm_id,
        epf_no: epfNumber,
        deduction_cycle: "Monthly",
        employee_contribution_rate: formData.employeeContributionRate,
        employer_contribution_rate: formData.employerContributionRate,
        include_employer_ctc: formData.includeEmployerContribution ? "Y" : "N",
        include_employer_edli: formData.includeEmployerEDLIContribution
          ? "Y"
          : "N",
        include_admin_charges_ctc: formData.includeAdminCharges ? "Y" : "N",
        override_pf: formData.overridePF ? "Y" : "N",
        pro_rate_pf: formData.proRatePF ? "Y" : "N",
        pf_wage_less_15000: formData.pfWageLess15000 ? "Y" : "N",
        arby_scheme: formData.abryEligible ? "Y" : "N",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddEpfDetails`,
        requestOptions
      );
      console.log("Api url");
      const data = await res.json();
      console.log("nishant", data);
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const Getepf = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        firmid: user.firm_id,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEpfDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const epfDataFromApi = data.data[0];
        if (epfDataFromApi.status === "N") {
          setFormData({
            ...formData,
            epfNumber: "", 
            deductionCycle: "",
            employeeContributionRate: "",
            employerContributionRate: "",
            includeEmployerContribution: false,
            includeEmployerEDLIContribution: false,
            includeAdminCharges: false,
            overridePF: false,
            proRatePF: false,
            pfWageLess15000: false,
            abryEligible: false,
          });
          setEpfNumber(""); 
        } else {
          setFormData({
            ...formData,
            epfNumber: epfDataFromApi.epf_no, 
            deductionCycle: epfDataFromApi.deduction_cycle,
            employeeContributionRate: epfDataFromApi.employee_contribution_rate,
            employerContributionRate: epfDataFromApi.employer_contribution_rate,
            includeEmployerContribution: epfDataFromApi.include_employer_ctc === "Y",
            includeEmployerEDLIContribution: epfDataFromApi.include_employer_edli === "Y",
            includeAdminCharges: epfDataFromApi.include_admin_charges_ctc === "Y",
            overridePF: epfDataFromApi.override_pf === "Y",
            proRatePF: epfDataFromApi.pro_rate_pf === "Y",
            pfWageLess15000: epfDataFromApi.pf_wage_less_15000 === "Y",
            abryEligible: epfDataFromApi.arby_scheme === "Y",
          });
          setEpfNumber(epfDataFromApi.epf_no); // Update epfNumber state
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  

  useEffect(() => {
    Getepf();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
 
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowprevModal = () => setShowPreviewModal(true);
  const handleCloseprevModal = () => setShowPreviewModal(false);

  return (
    <>
  
      <div className="mx-md-4 mx-2 mt-md-3 mt-2  p-2">
      <div className=" row m-0 p-0">
        <h5 className="px-2">Employees' Provident Fund </h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Statutory_components">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr className="m-2"/>
        <div className="row">
          <div className="col-md-7 col-12 p-0">
            <form onSubmit={handleSubmit}>
              <div className="row m-1 p-0">
                <div className="col-6 form-group">
                  <label htmlFor="epfNumber">EPF Number</label>
                  <input
                    type="text"
                    id="epfNumber"
                    name="epfNumber"
                    className="form-control"
                    value={epfNumber}
                    onChange={handleInputChange}
                    placeholder="AA/AAA/0000000/XXX"
                    maxLength="18"
                  />
                </div>
                <div className="col-4 form-group">
                  <label htmlFor="deductionCycle">
                    Deduction Cycle
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          Provident Fund(PF) contribution for each month should
                          be deposited to the Employee Provident Fund
                          Organization (EPFO) within the 15th of the following
                          month.
                        </Tooltip>
                      }
                    >
                      <IoInformationCircleOutline
                        style={{ fontSize: "1rem" }}
                      />
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    id="deductionCycle"
                    name="deductionCycle"
                    className="form-control"
                    value={formData.deductionCycle}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>
              <div className="row m-1 p-0">
                <div className="col-8 form-group">
                  <label htmlFor="employeeContributionRate">
                    Employee Contribution Rate
                  </label>
                  <Select
                    id="employeeContributionRate"
                    name="employeeContributionRate"
                    options={employeeContributionOptions}
                    value={employeeContributionOptions.find(
                      (option) =>
                        option.value === formData.employeeContributionRate
                    )}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>

              <div className="row m-1 p-0">
                <div className="col-8 form-group">
                  <div className="row">
                    <div className="col-8">
                      <label htmlFor="employerContributionRate">
                        Employer Contribution Rate
                      </label>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <Link onClick={handleShowModal}>View split</Link>
                    </div>
                  </div>

                  <Select
                    id="employerContributionRate"
                    name="employerContributionRate"
                    options={employerContributionOptions}
                    value={employerContributionOptions.find(
                      (option) =>
                        option.value === formData.employerContributionRate
                    )}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className="row m-1 p-0">
                <div className="mx-md-3 mx-1 mt-0 form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="includeEmployerContribution"
                    name="includeEmployerContribution"
                    checked={formData.includeEmployerContribution}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    style={{ color: "#000" }}
                    className="form-check-label"
                    htmlFor="includeEmployerContribution"
                  >
                    Include employer's contribution in the CTC
                  </label>
                </div>

                {formData.includeEmployerContribution && (
                  <>
                    <div className="px-md-5 px-3">
                      <div class="form-check m-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="includeEmployerEDLIContribution"
                          name="includeEmployerEDLIContribution"
                          checked={formData.includeEmployerEDLIContribution}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          style={{ color: "#000" }}
                          class="form-check-label"
                          for="flexCheckDefault"
                        >
                          Include employer's EDLI contribution in the CTC
                        </label>
                      </div>

                      <div class="form-check m-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="includeAdminCharges"
                          name="includeAdminCharges"
                          checked={formData.includeAdminCharges}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          style={{ color: "#000" }}
                          class="form-check-label"
                          for="flexCheckDefault"
                        >
                          Include admin charges in the CTC
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="mx-3">
                <div className="form-check m-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={formData.overridePF}
                    onChange={handleCheckboxChange}
                    name="overridePF"
                  />
                  <label
                    style={{ color: "#000" }}
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Override PF contribution rate at employee level
                  </label>
                </div>
                <h6 className="mt-3">PF Configuration when LOP Applied</h6>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="proRatePF"
                    checked={formData.proRatePF}
                    onChange={handleCheckboxChange}
                    name="proRatePF"
                  />
                  <label
                    style={{ color: "#000" }}
                    className="form-check-label"
                    htmlFor="proRatePF"
                  >
                    Pro-rate Restricted PF Wage
                    <br />
                    PF contribution will be pro-rated based on the number of
                    days worked by the employee.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="pfWageLess15000"
                    checked={formData.pfWageLess15000}
                    onChange={handleCheckboxChange}
                    name="pfWageLess15000"
                  />
                  <label
                    style={{ color: "#000" }}
                    className="form-check-label"
                    htmlFor="pfWageLess15000"
                  >
                    Consider all applicable salary components if PF wage is less
                    than ₹15,000 after Loss of Pay
                    <br />
                    PF wage will be computed using the salary earned in that
                    particular month (based on LOP) rather than the actual
                    amount mentioned in the salary structure.
                  </label>
                </div>
                <h6>ABRY eligibility</h6>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="abryEligible"
                    checked={formData.abryEligible}
                    onChange={handleCheckboxChange}
                    name="abryEligible"
                  />
                  <label
                    style={{ color: "#000" }}
                    className="form-check-label"
                    htmlFor="abryEligible"
                  >
                    Eligible for ABRY Scheme
                    <br />
                    The EPF contribution of both the employee and the employer
                    (with a few exceptions) will be paid by the Government for
                    eligible employees who receive up to ₹ 15,000 in monthly
                    wages.
                  </label>
                </div>
                {formData.abryEligible && (
                  <div className="mt-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="abryOption1"
                        name="abryOption"
                        value="option1"
                        checked={formData.abryOption === "option1"}
                        onChange={handleRadioChange}
                      />
                      <label
                        style={{ color: "#000", marginRight: "10px" }}
                        className="form-check-label"
                        htmlFor="abryOption1"
                      >
                        Only Employee
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="abryOption2"
                        name="abryOption"
                        value="option2"
                        checked={formData.abryOption === "option2"}
                        onChange={handleRadioChange}
                      />
                      <label
                        style={{ color: "#000" }}
                        className="form-check-label"
                        htmlFor="abryOption2"
                      >
                        Both Employee and Employer
                      </label>
                    </div>
                  </div>
                )}

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
                <Link to="/retailer/Statutory_components">
                  <button type="submit" className="btn btn-light">
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>

          <div className="col-md-5 col-12">
            <div
              className="card p-3"
              style={{
                background: "rgba(255, 248, 230, .6)",
                borderTop: "2px solid #FF973B",
              }}
            >
              <h6>Sample EPF Calculation</h6>
              <p>
                Let's assume the PF wage is ₹ 20,000. The breakup of
                contribution will be:
              </p>
              <div className="card p-3">
                <p>
                  <b>Employee's Contribution</b>
                </p>
                {formData.employeeContributionRate ===
                "12% of Actual PF Wage" ? (
                  <>
                    <div className="row p-0">
                      <div className="col">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 20000)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹2400</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row p-0">
                      <div className="col">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 15000)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1800</p>
                      </div>
                    </div>
                  </>
                )}
                <hr className="mt-2 mb-2" />
                <p>
                  <b>Employer Contribution</b>
                </p>
                {formData.employerContributionRate ===
                "12% of Actual PF Wage" ? (
                  <>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPS (8.33% of 20000 (Max of ₹ 15,000))
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1250</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 20000 - EPS)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1150</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPS (8.33% of 15000 (Max of ₹ 15,000))
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹1250</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-9">
                        <div style={{ fontSize: ".8rem" }}>
                          EPF (12% of 15000 - EPS)
                        </div>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <p>₹550</p>
                      </div>
                    </div>
                  </>
                )}

                <hr className="m-1" />
                <div className="row p-0">
                  <div className="col-9">
                    <div style={{ fontSize: "1rem" }}>
                      <b>Total</b>{" "}
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <p>
                      {(() => {
                        let total = 0;
                        if (
                          formData.employeeContributionRate ===
                          "12% of Actual PF Wage"
                        ) {
                          total += 2400;
                        } else {
                          total += 1800;
                        }

                        if (
                          formData.employerContributionRate ===
                          "12% of Actual PF Wage"
                        ) {
                          total += 2400;
                        } else {
                          total += 1800;
                        }
                        return `₹${total}`;
                      })()}
                    </p>
                  </div>
                </div>
              </div>
              <p className="pt-2 pb-2">
                Do you want to preview EPF calculation for multiple cases, based
                on the preferences you have configured ?
              </p>
              <Link onClick={handleShowprevModal}>
                <FaEye /> &nbsp;Preview EPF Calculation
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Viewsepfspilit
    show={showModal}
    handleClose={handleCloseModal}
    />
    <Viewprviewepfcalcilation
    show={showPreviewModal}
    handleClose={handleCloseprevModal}
    />

    </>
  );
};

export default Epfformadmin;
