import React, { Component, useEffect, useState } from "react";
import { twoDecimalPlaces } from "../../utils/utilFuctions";

const IncomeCalculator = () => {
  const [inputState, setInputState] = useState({
    recharge: 5000,
    pan: 5,
    dmt: 50000,
    irctc: 5,
    airline: 5,
    bus: 5,
    aeps: 10000,
    atm: 10000,
  });
  const [calculate, setCalculate] = useState({
    recharge: 0.0,
    pan: 0.0,
    dmt: 0.0,
    irctc: 0.0,
    airline: 0.0,
    bus: 0.0,
    aeps: 0.0,
    atm: 0.0,
    daily: 0.0,
    monthly: 0.0,
  });

  const aepsCalculate = (value) => {
    if (100 <= value && value <= 499) {
      return 0.25;
    } else if (500 <= value && value <= 999) {
      return 1;
    } else if (1000 <= value && value <= 1999) {
      return 3;
    } else if (2000 <= value && value <= 2999) {
      return 4;
    } else if (3000 <= value && value <= 4999) {
      return 7;
    } else if (5000 <= value && value <= 9999) {
      return 10;
    } else if (10000 <= value) {
      var mul = Math.floor(value / 10000);

      return mul * 10 + aepsCalculate(value % 10000);
    }
    return 0;
  };

  const mAtmCalculate = (value) => {
    if (100 <= value && value <= 499) {
      return 0.25;
    } else if (500 <= value && value <= 999) {
      return 1;
    } else if (1000 <= value && value <= 1999) {
      return 3;
    } else if (2000 <= value && value <= 2999) {
      return 4;
    } else if (3000 <= value && value <= 4999) {
      return 7;
    } else if (5000 <= value && value <= 10000) {
      return 10;
    } else if (10001 <= value) {
      var mul = Math.floor(value / 10000);

      return mul * 10 + mAtmCalculate(value % 10000);
    }
    return 0;
  };

  const rechargeCalculate = (value) => {
    return value / 33.33;
  };

  const dmtCalculate = (value) => {
    if (value == 0) {
      return 0;
    } else if (value <= 1000) {
      return 10;
    } else if (1000 <= value && value <= 5000) {
      return value / 200;
    } else if (5000 <= value) {
      return 25 * (value / 5000);
    }
  };

  const panCalculate = (value) => {
    return value * 7;
  };

  const calculateEarning = () => {
    let income =
      rechargeCalculate(Number(inputState.recharge)) +
      panCalculate(Number(inputState.pan)) +
      dmtCalculate(Number(inputState.dmt)) +
      Number(inputState.irctc) * 30 +
      Number(inputState.airline) * 200 +
      Number(inputState.bus) * 1.04 +
      aepsCalculate(Number(inputState.aeps)) +
      mAtmCalculate(Number(inputState.atm));
    setCalculate({ ...calculate, daily: income, monthly: income * 30 });
  };

  useEffect(() => {
    let income =
      rechargeCalculate(Number(inputState.recharge)) +
      panCalculate(Number(inputState.pan)) +
      dmtCalculate(Number(inputState.dmt)) +
      Number(inputState.irctc) * 30 +
      Number(inputState.airline) * 200 +
      Number(inputState.bus) * 1.04 +
      aepsCalculate(Number(inputState.aeps)) +
      mAtmCalculate(Number(inputState.atm));
    setCalculate({
      recharge: rechargeCalculate(Number(inputState.recharge)),
      pan: panCalculate(Number(inputState.pan)),
      dmt: dmtCalculate(Number(inputState.dmt)),
      irctc: Number(inputState.irctc) * 30,
      airline: Number(inputState.airline) * 200,
      bus: Number(inputState.bus) * 0.04,
      aeps: aepsCalculate(Number(inputState.aeps)),
      atm: mAtmCalculate(Number(inputState.atm)),
      daily: income,
      monthly: income * 30,
    });
  }, [inputState]);
  const clear = () => {
    setCalculate({
      recharge: 0.0,
      pan: 0.0,
      dmt: 0.0,
      irctc: 0.0,
      airline: 0.0,
      bus: 0.0,
      aeps: 0.0,
      atm: 0.0,
    });
    setInputState({
      recharge: "",
      pan: "",
      dmt: "",
      irctc: "",
      airline: "",
      bus: "",
      aeps: "",
      atm: "",
      daily: 0.0,
      monthly: 0.0,
    });
  };

  useEffect(() => {
    let numberInput = document.querySelector('input[type="number"]');
    numberInput.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }, [])
  return (
    <>
      <div className="row justify-content-center">
        <div className="card col-12 col-sm-10 col-md-8">
          <table className="dashboard-table-style" style={{}}>
            <tr>
              <th style={{ width: "35%", padding: "1%" }}> Services</th>
              <th style={{ width: "15%", padding: "1%" }}> Turnover </th>
              <th style={{ width: "35%", padding: "1%" }}> Frequency</th>
              <th style={{ width: "15%", padding: "1%" }}> Income</th>
            </tr>

            <tr>
              <td>Mobile & DTH Recharge</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.recharge}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, recharge: e.target.value });
                  }}
                ></input>
              </td>
              <td>Volume</td>
              <td>{twoDecimalPlaces(calculate.recharge)}</td>
            </tr>
            <tr>
              <td>Pan Card</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.pan}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, pan: e.target.value });
                  }}
                ></input>
              </td>
              <td>No of PAN</td>
              <td>{twoDecimalPlaces(calculate.pan)}</td>
            </tr>
            <tr>
              <td> Domestic Money Transfer</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.dmt}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, dmt: e.target.value });
                  }}
                ></input>
              </td>
              <td>Volume </td>
              <td>{twoDecimalPlaces(calculate.dmt)}</td>
            </tr>
            <tr>
              <td>IRCTC Tickets</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.irctc}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, irctc: e.target.value });
                  }}
                ></input>
              </td>
              <td>No of Tickets</td>
              <td>{twoDecimalPlaces(calculate.irctc)}</td>
            </tr>
            <tr>
              <td> Airline Tickets</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.airline}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, airline: e.target.value });
                  }}
                ></input>
              </td>
              <td>No of PNR</td>
              <td>{twoDecimalPlaces(calculate.airline)}</td>
            </tr>
            <tr>
              <td> Bus Tickets</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.bus}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, bus: e.target.value });
                  }}
                ></input>
              </td>
              <td>No of Booking</td>
              <td>{twoDecimalPlaces(calculate.bus)}</td>
            </tr>
            <tr>
              <td> AEPS</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.aeps}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, aeps: e.target.value });
                  }}
                ></input>
              </td>
              <td>Volume</td>
              <td>{twoDecimalPlaces(calculate.aeps)}</td>
            </tr>
            <tr>
              <td>Micro ATM</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  style={{
                    padding: "0px",
                    height: "6vh",
                    borderColor: "rgb(25, 167, 206)",
                    padding: "1%",
                  }}
                  value={inputState.atm}
                  onChange={(e) => {
                    if(Number(e.target.value)<0){
                      return;
                    }
                    setInputState({ ...inputState, atm: e.target.value });
                  }}
                ></input>
              </td>
              <td>Volume</td>
              <td>{twoDecimalPlaces(calculate.atm)}</td>
            </tr>
          </table>
          <div>
            <h5
              style={{
                margin: "6px",
              }}
            >
              Daily Income ₹{twoDecimalPlaces(calculate.daily)}{" "}
            </h5>
            <h5
              style={{
                margin: "6px",
              }}
            >
              Monthly Income ₹{twoDecimalPlaces(calculate.monthly)}
            </h5>
            <h5
              style={{
                margin: "6px",
              }}
            >
              This is approx calculation, can vary as per any changes in pricing
            </h5>
            {/* <p
            style={{
              margin: '9px'
            }}
            >
              
              Term & Condition Apply
            </p> */}
          </div>
          <div style={{}}>
            <button
              className="px-2 mr-2"
              style={{
                background: "#19A7CE",
                color: "white",
                border: "none",
                width: " auto",
                margin: " 11px",
                borderRadius: "11px",
                fontSize: "1rem",
              }}
              onClick={calculateEarning}
            >
              CALCULATE INCOME
            </button>
            <button
              className="px-2 mr-2 mt-0"
              style={{
                background: "#EA5455",
                color: "white",
                border: "none",
                width: " auto",
                marginLeft: "1px",
                borderRadius: "11px",
                fontSize: "1rem",
              }}
              onClick={clear}
            >
              RESET
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeCalculator;
