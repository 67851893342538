import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import cogoToast from "cogo-toast";
import { Button } from "react-bootstrap";
import { HiOutlineLightBulb } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
const Generateform16admin = () => {
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile.size <= 52428800) {
      setFile(uploadedFile);
    } else {
      cogoToast.error("File size exceeds 50MB limit");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDownloadPartA = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/TracesRedirectLink`,
        requestOptions
      );

      const data = await res.json();

      if (data.status === "0") {
        window.open(data.data[0].link, "_blank");
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="p-3 mt-2">
        <div className="d-flex align-items-center">
          <h5 className="px-2">Generate Form 16</h5>
        </div>
        <hr className="m-2" />

        <div className="row mt-md-4 mt-2">
          <div className="col-md-8 col-12" style={{ borderRight: "1px solid" }}>
            <p className="" style={{ fontSize: "1rem", fontWeight: "500" }}>
              Upload Form 16 - Part A ZIP File that you've downloaded from
              TRACES utility application
            </p>
            <div
              className="col-7 mt-4 p-5"
              {...getRootProps()}
              style={dropzoneStyle}
            >
              <input {...getInputProps()} />
              {file ? (
                <div>
                  <p>Uploaded File: {file.name}</p>
                  <p>File Size: {file.size} bytes</p>
                </div>
              ) : isDragActive ? (
                <>
                  <BsFillCloudArrowUpFill style={{ fontSize: "2rem" }} />
                  <p>Drop the files here ...</p>
                </>
              ) : (
                <>
                  <BsFillCloudArrowUpFill style={{ fontSize: "2rem" }} />
                  <p className="m-0 p-1">Drop your file here </p>
                  <p className="m-0 p-1">
                    Drag and drop to upload or click here{" "}
                    <span style={{ color: "blue" }}>Browser</span>
                  </p>
                </>
              )}
            </div>
            <p className="col-10 mt-3" style={{ fontSize: ".8rem" }}>
              <span style={{ color: "red" }}>Note: </span> The ZIP file you
              uploaded should contain Form 16 - Part A files including the
              employee's PAN and its file size should not exceed 50MB.
            </p>
            <hr className="mt-md-4 mt-2 mb-md-4 mb-2" />
            <Button className="mx-2" disabled={!file}>
              Upload and Generate
            </Button>
            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Button className="" onClick={handleDownloadPartA}>
              Download Part A
            </Button>

            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Link to="/retailer/Form16">
              <Button
                className="btn-light mx-2"
                style={{ border: "1px solid" }}
              >
                Cancel
              </Button>
            </Link>
          </div>

          <div className="col" style={{ maxHeight: "75vh", overflowY: "auto" }}>
            <h5>
              <HiOutlineLightBulb
                style={{ fontSize: "2rem", color: "orange" }}
              />{" "}
              Things to Note
            </h5>
            <div className="p-3 mt-2">
              <div className="d-flex align-items-center">
                <h5 className="px-2">Form 16 Guidelines</h5>
              </div>
              <hr className="m-2" />
              <ul>
                <li>
                  Correct PAN Details: Ensure accurate PAN information for
                  seamless form matching.
                </li>
                <li>
                  TRACES Utility Access: Head to the TRACES utility app for
                  hassle-free Form 16 - Part A downloads.
                </li>
                <li>
                  PDF Conversion Ease: Convert TXT files to PDF effortlessly
                  using the TRACES PDF generation tool.
                </li>
                <li>
                  File Naming Convention: Each ZIP file's contents should bear
                  only the PAN of the corresponding employees.
                </li>
                <li>
                  Digital Signature Exclusion: Avoid digitally signing Form 16 -
                  Part A during uploads.
                </li>
                <li>
                  Streamlined Document Merge: Merge Form 16 - Part A with
                  system-generated Part B for individual PDF access via the
                  employee portal using PAN.
                </li>
                <li>
                  Additional Merge for Eligible Employees: For eligible
                  employees, merge Form 12BA along with Form 16 to ensure
                  comprehensive documentation.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
{
}
const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: "5px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default Generateform16admin;
