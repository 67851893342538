
import {theme} from './Client'

const {colorDashboardBallenceCard,colorDashboardTableHeader,colorLoginScreenStripe,colorQrPlaceHolder,colorQrPlaceHolderSecondary,colorBackgroundBody,colorDashboardTableHeadingBackground} = theme

export const COLOR_DASHBOARD_BALLENCE_CARD = colorDashboardBallenceCard;

export const COLOR_QR_PLACEHOLDER = colorQrPlaceHolder;

export const COLOR_LOGIN_SCREEN_STRIPE = colorLoginScreenStripe;

export const COLOR_QR_PLACEHOLDER_SECONDARY = colorQrPlaceHolderSecondary;

export const COLOR_DASHBOARD_TABLE_HEADER = colorDashboardTableHeader;

export const COLOR_BACKGROUND_BODY = colorBackgroundBody;

export const COLOR_DASHBOARD_TABLE_HEADING = colorDashboardTableHeadingBackground;

// Navbar & Sidebar bgcolor is in _variables.scss line no. 18 as $white-smoke
