import React, { useContext, useState } from "react";
import { Form, Button, Row, Col, InputGroup, Modal } from "react-bootstrap";
import { HiOutlineDocumentText } from "react-icons/hi";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { GlobalContext } from "../../Context/GlobalState";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
const ProofInvestmentForm = () => {
  const [isRented, setIsRented] = useState(false);
  const { password, username } = billpunchbasicauth;

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [isRepayingHomeLoan, setIsRepayingHomeLoan] = useState(false);
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    amount: "",
    address: "",
    landlordName: "",
    urbanization: "",
    landlordPan: "",
    principalPaid: "",
    interestPaid: "",
    lenderName: "",
    lenderPan: "",
    providentFund: "",
    investmentType: "",
    investmentAmount: "",
    investmentType2: "",
    investmentAmount2: "",
    investmentType3: "",
    investmentAmount3: "",
    other_source: "",
    saving_deposit: "",
    fixed_deposit: "",
    national: "",
  });

  const handleSwitchChange = (name) => {
    if (name === "rentedHouse") {
      setIsRented(!isRented);
    } else if (name === "repayingHomeLoan") {
      setIsRepayingHomeLoan(!isRepayingHomeLoan);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
        tax_type: "string",
        rented_house: isRented ? "Yes" : "No",
        repaying_home: isRepayingHomeLoan ? "Yes" : "No",
        invest_80c: formValues.investmentType !== "" ? "Yes" : "No",
        invest_80d: formValues.investmentType2 !== "" ? "Yes" : "No",
        other_invest: formValues.investmentType3 !== "" ? "Yes" : "No",
        other_income: formValues.other_source !== "" ? "Yes" : "No",
        status: "string",
        houseRentDetails: isRented
          ? [
              {
                fromDate: formValues.fromDate,
                toDate: formValues.toDate,
                perMonthAmount: formValues.amount,
                totalAmount: "string",
                address: formValues.address,
                landlordName: formValues.landlordName,
                urbanization: formValues.urbanization,
                landlordPan: formValues.landlordPan,
              },
            ]
          : [],
        homeLoanDetails: isRepayingHomeLoan
          ? [
              {
                principal: formValues.principalPaid,
                intrestPaid: formValues.interestPaid,
                lenderName: formValues.lenderName,
                lenderPan: formValues.lenderPan,
                letoutProperty: "string",
              },
            ]
          : [],
        invest80CDetails: formValues.investmentType
          ? [
              {
                investName: formValues.investmentType,
                investAmount: formValues.investmentAmount,
                type: "string",
              },
            ]
          : [],
        invest80DDetails: formValues.investmentType2
          ? [
              {
                investName: formValues.investmentType2,
                investAmount: formValues.investmentAmount2,
                type: "string",
              },
            ]
          : [],
        otherInvestDetails: formValues.investmentType3
          ? [
              {
                investName: formValues.investmentType3,
                investAmount: formValues.investmentAmount3,
                type: "string",
              },
            ]
          : [],
        otherIncomeDetails: formValues.other_source
          ? [
              {
                other_source: formValues.other_source,
                saving_deposit: formValues.saving_deposit,
                fixed_deposit: formValues.fixed_deposit,
                national: formValues.national,
              },
            ]
          : [],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddProofOfInvestment`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const [expandedSection1, setExpandedSection1] = useState(false);
  const [expandedSection2, setExpandedSection2] = useState(false);
  const [expandedSection3, setExpandedSection3] = useState(false);
  const [expandedSection4, setExpandedSection4] = useState(false);

  const toggleSection = (sectionNumber) => {
    switch (sectionNumber) {
      case 1:
        setExpandedSection1(!expandedSection1);
        break;
      case 2:
        setExpandedSection2(!expandedSection2);
        break;
      case 3:
        setExpandedSection3(!expandedSection3);
        break;
      case 4:
        setExpandedSection4(!expandedSection4);
        break;
      default:
        break;
    }
  };
  const [tdsmodal, settdsmodal] = useState(false);

  const Opentdsmodal = () => {
    settdsmodal(true);
  };

  const closetdsmodal = () => {
    settdsmodal(false);
  };
  return (
    <>
      <div className="p-3">
        <div className=" row ">
          <h5 className="col px-3">New Proof of Investment</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Investment">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <hr />

        <Form className="col-md-8 col-12" onSubmit={handleSubmit}>
          <Form.Group
            controlId="rentedHouseSwitch"
            style={{ background: "#EEF7FF" }}
          >
            <Row className="align-items-center">
              <Col className="align-items-center d-flex">
                <Form.Label className="m-0" style={{ color: "#000" }}>
                  Are you staying in a rented house?
                </Form.Label>
              </Col>
              <Col className="d-flex justify-content-end">
                <Form.Check
                  type="switch"
                  id="custom-switch-rented"
                  label={isRented ? "Yes" : "No"}
                  checked={isRented}
                  onChange={() => handleSwitchChange("rentedHouse")}
                />
              </Col>
            </Row>
          </Form.Group>

          {isRented && (
            <>
              <div style={{ background: "#fff" }}>
                <div className="row m-0 p-0">
                  <Form.Group className="col-8" controlId="rentalPeriod">
                    <Form.Label>Rental Period</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="date"
                        name="fromDate"
                        value={formValues.fromDate}
                        onChange={handleInputChange}
                        placeholder="From Date"
                      />
                      <Form.Control
                        type="date"
                        name="toDate"
                        value={formValues.toDate}
                        onChange={handleInputChange}
                        placeholder="To Date"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="col" controlId="amount">
                    <Form.Label>Amount / Month</Form.Label>
                    <Form.Control
                      type="text"
                      name="amount"
                      value={formValues.amount}
                      onChange={handleInputChange}
                      placeholder="Enter amount"
                    />
                  </Form.Group>
                </div>

                <div className="row m-0 p-0">
                  <Form.Group className="col-8" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="address"
                      value={formValues.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </Form.Group>
                </div>

                <div className="row m-0 p-0">
                  <Form.Group className="col-8" controlId="landlordName">
                    <Form.Label>Landlord Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="landlordName"
                      value={formValues.landlordName}
                      onChange={handleInputChange}
                      placeholder=" Landlord's name"
                    />
                  </Form.Group>
                </div>
                <div className="row m-0 p-0">
                  <Form.Group className="col-4" controlId="urbanization">
                    <Form.Label>Urbanization</Form.Label>
                    <Form.Control
                      type="text"
                      name="urbanization"
                      value={formValues.urbanization}
                      onChange={handleInputChange}
                      placeholder=" Urbanization"
                    />
                  </Form.Group>
                  <Form.Group className="col-4" controlId="landlordPan">
                    <Form.Label>Landlord PAN</Form.Label>
                    <Form.Control
                      type="text"
                      name="landlordPan"
                      value={formValues.landlordPan}
                      onChange={handleInputChange}
                      placeholder=" Landlord's PAN"
                    />
                  </Form.Group>
                </div>
              </div>{" "}
            </>
          )}

          <Form.Group
            controlId="repayingHomeLoanSwitch"
            style={{ background: "#EEF7FF" }}
          >
            <Row className="align-items-center">
              <Col className="align-items-center d-flex col-8">
                <Form.Label className="m-0" style={{ color: "#000" }}>
                  Are you repaying home for self-occupied house property?
                </Form.Label>
              </Col>
              <Col className="d-flex justify-content-end col-4">
                <Form.Check
                  type="switch"
                  id="custom-switch-home-loan"
                  label={isRepayingHomeLoan ? "Yes" : "No"}
                  checked={isRepayingHomeLoan}
                  onChange={() => handleSwitchChange("repayingHomeLoan")}
                />
              </Col>
            </Row>
          </Form.Group>

          {isRepayingHomeLoan && (
            <>
              <div style={{ background: "#fff" }}>
                <Form.Group as={Row} className="mb-0" controlId="principalPaid">
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Principal Paid on Home Loan
                    <br />
                    <span style={{ color: "grey" }}>
                      This will be automatically included in the 80C section
                    </span>
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="principalPaid"
                      value={formValues.principalPaid}
                      onChange={handleInputChange}
                      placeholder="Enter principal amount paid"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-0" controlId="interestPaid">
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Interest Paid on Home Loan
                    <br />{" "}
                    <span style={{ color: "grey" }}>
                      This Will be automatically included in Section 24
                    </span>
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="interestPaid"
                      value={formValues.interestPaid}
                      onChange={handleInputChange}
                      placeholder="Enter interest amount paid"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-0" controlId="lenderName">
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Name of Lender
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="lenderName"
                      value={formValues.lenderName}
                      onChange={handleInputChange}
                      placeholder="Enter name of lender"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-0" controlId="lenderPan">
                  <Form.Label
                    style={{ color: "#000" }}
                    column
                    className="col-8"
                  >
                    Lender PAN
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="lenderPan"
                      value={formValues.lenderPan}
                      onChange={handleInputChange}
                      placeholder="Enter lender PAN"
                    />
                  </Col>
                </Form.Group>
              </div>
            </>
          )}
          {/* Section 1 */}
          <div
            onClick={() => toggleSection(1)}
            className="p-3 mt-2 d-flex justify-content-between align-items-center"
            style={{ background: "#EEF7FF", cursor: "pointer" }}
          >
            <div>
              <HiOutlineDocumentText />{" "}
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                80C Investment
              </span>
            </div>
            {expandedSection1 ? <FaAngleUp /> : <FaAngleDown />}
          </div>

          {expandedSection1 && (
            <>
              <p className="p-1" style={{ fontSize: ".8rem" }}>
                <b>Note:</b> Declare investments such as LIC premiums, mutual
                funds and PPF under this section. The maximum tax saving
                investment you can make under 80C is ₹150,000
              </p>
              <Form.Group as={Row} className="mb-0" controlId="providentFund">
                <Form.Label column className="col-8">
                  Employee Provident Fund (Pre-tax deduction)
                </Form.Label>
                <Col className="d-flex justify-content-end">
                  <Form.Control
                    type="text"
                    name="providentFund"
                    value={formValues.providentFund}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    style={{ width: "12rem" }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="" controlId="investmentType">
                <Col className="col-6">
                  <Form.Control
                    as="select"
                    name="investmentType"
                    value={formValues.investmentType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Investment Type</option>
                    <option value="LIC Premiums">LIC Premiums</option>
                    <option value="Mutual Funds">Mutual Funds</option>
                    <option value="PPF">PPF</option>
                  </Form.Control>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Form.Control
                    type="text"
                    name="investmentAmount"
                    value={formValues.investmentAmount}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    style={{ width: "12rem" }}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {/* Section 2 */}
          <div
            onClick={() => toggleSection(2)}
            className="p-3 mt-2 d-flex justify-content-between align-items-center"
            style={{ background: "#EEF7FF", cursor: "pointer" }}
          >
            <div>
              <HiOutlineDocumentText />{" "}
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                80D Investments
              </span>
            </div>
            {expandedSection2 ? <FaAngleUp /> : <FaAngleDown />}
          </div>

          {expandedSection2 && (
            <>
              <p className="p-1" style={{ fontSize: ".8rem" }}>
                <b>Note:</b> Declare the mediclaim insurance policies for
                yourself, spouse, childer and parents. The maximum tax saving
                investment you can make under 80D is ₹150,000
              </p>
              <Form.Group as={Row} className="" controlId="investmentType2">
                <Col className="col-6">
                  <Form.Control
                    as="select"
                    name="investmentType2"
                    value={formValues.investmentType2}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Investment Type</option>
                    <option value="Health Insurance">Health Insurance</option>
                    <option value="Mediclaim Policy">Mediclaim Policy</option>
                    <option value="Critical Illness Policy">
                      Critical Illness Policy
                    </option>
                  </Form.Control>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Form.Control
                    type="text"
                    name="investmentAmount2"
                    value={formValues.investmentAmount2}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    style={{ width: "12rem" }}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {/* Section 3 */}
          <div
            onClick={() => toggleSection(3)}
            className="p-3 mt-2 d-flex justify-content-between align-items-center"
            style={{ background: "#EEF7FF", cursor: "pointer" }}
          >
            <div>
              <HiOutlineDocumentText />{" "}
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                Other Investment & Exemptions
              </span>
            </div>
            {expandedSection3 ? <FaAngleUp /> : <FaAngleDown />}
          </div>

          {expandedSection3 && (
            <>
              <p className="p-1" style={{ fontSize: ".8rem" }}>
                <b>Note:</b> Declare Other investment & exemptions such as
                Voluntary NPS, Interest paid on Education Loan and Medical
                Expenditure under this section
              </p>
              <Form.Group as={Row} className="" controlId="investmentType3">
                <Col className="col-6">
                  <Form.Control
                    as="select"
                    name="investmentType3"
                    value={formValues.investmentType3}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Investment Type</option>
                    <option value="Voluntary NPS">Voluntary NPS</option>
                    <option value="Interest on Education Loan">
                      Interest on Education Loan
                    </option>
                    <option value="Medical Expenditure">
                      Medical Expenditure
                    </option>
                  </Form.Control>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Form.Control
                    type="text"
                    name="investmentAmount3"
                    value={formValues.investmentAmount3}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    style={{ width: "12rem" }}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {/* Section 4 */}
          <div
            onClick={() => toggleSection(4)}
            className="p-3 mt-2 d-flex justify-content-between align-items-center"
            style={{ background: "#EEF7FF", cursor: "pointer" }}
          >
            <div>
              <HiOutlineDocumentText />{" "}
              <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                Other Sources of Income
              </span>
            </div>
            {expandedSection4 ? <FaAngleUp /> : <FaAngleDown />}
          </div>

          {expandedSection4 && (
            <>
              <Form.Group as={Row} className="mb-0" controlId="other_source">
                <Form.Label style={{ color: "#000" }} column className="col-8">
                  Other Source of Income
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="other_source"
                    value={formValues.other_source}
                    onChange={handleInputChange}
                    placeholder="Enter source"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="saving_deposit">
                <Form.Label style={{ color: "#000" }} column className="col-8">
                  Interest Earned from Savings Deposit
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="saving_deposit"
                    value={formValues.saving_deposit}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="fixed_deposit">
                <Form.Label style={{ color: "#000" }} column className="col-8">
                  Interest Earned from Fixed Deposit
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="fixed_deposit"
                    value={formValues.fixed_deposit}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-0" controlId="national">
                <Form.Label style={{ color: "#000" }} column className="col-8">
                  Interest Earned from National Savings Certificate (NSC)
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="national"
                    value={formValues.national}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                  />
                </Col>
              </Form.Group>
            </>
          )}
          <p
            className="m-0 mt-2 p-2 triangle-paragraph"
            style={{ background: "#EEF7FF" }}
          >
            {" "}
            <IoIosInformationCircleOutline /> Chcek How the tax is calculated
            oon the based on <b>Old Tax Regime</b>{" "}
            <span>
              <Link onClick={Opentdsmodal}>Compute Tax</Link>
            </span>
          </p>
          <div className="mt-3">
            {" "}
            <Button className="btn-sm" variant="primary" type="submit">
              Submit
            </Button>{" "}
            <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
            <Link to="/retailer/Investment">
              <Button className="btn-sm" variant="light" type="submit">
                Cancel
              </Button>
            </Link>{" "}
          </div>
          <Modal show={tdsmodal} onHide={closetdsmodal}>
            <Modal.Header closeButton>
              <Modal.Title>TDS Details </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row" style={{ background: "#EEF7FF" }}>
                <div className="col">
                  <p className="m-0">Tax Regime</p>
                  <p className="m-0"><b>Old tax Regime</b></p>
                </div>
                <div className="col">
                  <p className="m-0">Total Tax</p>
                  <p className="m-0"><b>₹32,345</b></p>
                </div>
                <div className="col">
                  <p className="m-0">Total Tax Payable</p>
                  <p className="m-0"><b>₹32,345</b></p>
                </div>
              </div>

            </Modal.Body>
          </Modal>
        </Form>
      </div>
    </>
  );
};

export default ProofInvestmentForm;
