import React, { useEffect, useState, useContext } from "react";
import Faq from "react-faq-component";
import {
    Row,
    Col,
    Form,
    Button,
    Modal,
    Tabs,
    Tab,
    Container,
    Nav,
} from "react-bootstrap";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import TicketSidebar from "./TicketSidebar";
import { Redirect } from "react-router";
import swal from "sweetalert";
import { dateFormatter, formatDateDDMMYYHHMM, generateHashKey2, uniqueID } from "../../utils/utilFuctions";
import { TextArea } from "semantic-ui-react";
import cogoToast from "cogo-toast";
const data2 = {
    title: "FAQ (How it works)",

    rows: [
        {
            title: "What is AEPS?",
            content: `Aadhaar Enabled Payment System (AEPS) is a payment service that allows a bank customer
 to use Aadhaar as his/her identity to access his/her Aadhaar enabled bank account and perform
 basic banking transactions like balance enquiry, cash withdrawal, remittances through a Business Correspondent.`,
        },
        {
            title: "What is DMT?",
            content: `Domestic Money Transfer (DMT) is a unique product that can be
 used to send money instantly to any Bank's account holder within India.`,
        },
        {
            title: "What is BBPS?",
            content: `What is BBPS and how it works?
 Bharat Bill Payment System (BBPS) is a RBI mandated system which offers integrated
 and interoperable bill payment services to customers across geographies with certainty,
 reliability and safety of transactions. `,
        },
    ],
};

const styles = {
    // bgColor: "white",
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowBorderColor:'black',
    // marginBottom:'12px',
    // marginTop:'12px',
    // borderRadius:'10px'
    // rowContentColor: 'grey',
    // arrowColor: "red",
    // bgColor: 'aliceblue',
    // titleTextColor: 'blue',
    // titleTextSize: '48px',
    // rowTitleColor: 'blue',
    // rowTitleTextSize: 'medium',
    // rowContentColor: 'grey',
    // rowContentTextSize: '16px',
    // rowContentPaddingTop: '10px',
    // rowContentPaddingBottom: '10px',
    // rowContentPaddingLeft: '50px',
    // rowContentPaddingRight: '150px',
    arrowColor: "rgb(0, 63, 125)",
    // transitionDuration: "1s",
    // timingFunc: "ease"
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

const columns = [
    {
        dataField: "tkt_trans_id",
        text: "Ticket Id ",
        color: "green",
    },
    {
        dataField: "tkt_type",
        text: "Transaction Type",
        color: "#000",
    },
    // {
    // dataField: "Category",
    // text: "Category",
    // color: "black",
    // },
    {
        dataField: "tkt_title",
        text: "Ticket Title",
        color: "black",
    },
    {
        dataField: "tkt_id",
        text: "Transaction Id",
        color: "black",
    },
    {
        dataField: "tkt_desc",
        text: "Description",
        color: "red",
    },

    {
        dataField: "tkt_status",
        text: "Status",
        color: "#000",
    },
    {
        dataField: "tkt_open_date",
        text: "Open Date",
        color: "black",
    },
    {
        dataField: "tkt_close_date",
        text: "Close Date",
        color: "black",
    },

    // {
    // dataField: "Accept/Rejected",
    // text: "Accept/Rejected",
    // color: "#000",
    // },
];



const HelpAndSupport = () => {
    const [Search, setSearch] = useState("");
    const [SearchData, setSearchData] = useState(data2);
    const [rows, setRows] = useState([]);
    const [TID, setTID] = useState("");
    const [TicketSts, setTicketSts] = useState("");
    const [offset, setOffset] = useState(100);

    const [showHelpFormmodal, setShowHelpFormmodal] = useState(false);
    const hideHelpFormmodal = () => setShowHelpFormmodal(false);

    const SearchFilter = () => {
        const SearchFilteredData = SearchData.rows.filter((Sdata) => {
            return (
                Sdata.content.toLowerCase().includes(Search.toLowerCase()) ||
                Sdata.title.toLowerCase().includes(Search.toLowerCase())
            );
        });
        if (SearchFilteredData.length > 0) {
            setRows({
                title: "FAQ (How it works)",
                rows: SearchFilteredData,
            });
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [data, setData] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [WalletRepo, setWalletRepo] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [fqSection, setFqSection] = useState({
        title: "FAQ (How it works)",
        rows: [],
    });

    const [startDate, setStartDate] = useState(getDatePreviousMonth());
    const [endDate, setEndDate] = useState(new Date());

    const { user, setTransactionLoaderState } = useContext(GlobalContext);




    // const getTicketList = async () => {
    //     const raw = "";
    //     const hash_key = await generateHashKey2(user.ReferId);
    //     var myHeaders = new Headers();
    //     myHeaders.append("HASH_KEY", hash_key);
    //     myHeaders.append("Content-Type", "application/json");
    //     var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow",
    //     };

    //     try {
    //         const res = await fetch(
    //             `${BASE_URL_API_TRANXT}/apiget.aspx?method=getticketlist&userid=${user.ReferId}`,
    //             requestOptions
    //         );
    //         const data = await res.json();
    //         // setDataToDisplay(data);
    //     } catch (error) {
    //         console.log("error", error)
    //     }


    // };

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }

    const getTicketList = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "adminid": "1",
                "fromdate": dateFormatter(startDate),
                "todate": dateFormatter(endDate)
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/support/getAllTicketListAdmin`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setData(data.data);
            } else {
                setData([]);
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const getFqSection = () => {
        try {
            var requestOptions = {
                method: "POST",
                redirect: "follow",
            };

            fetch(
                `${BASE_URL_API_TRANXT_API}/api/admin/users/get_all_faq_list.aspx`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status == "0") {
                        const modified = result.data.map((e) => {
                            return { ...e, title: e.question, content: e.answer };
                        });
                        setFqSection({
                            title: "FAQ (How it works)",
                            rows: modified,
                        });
                    }
                })

                .catch((error) => console.log("error", error));
        } catch (error) { }
    };

    useEffect(() => {
        let filteredData = WalletRepo.filter((bank) => {
            let b = false;
            if (WalletRepo.length > 0) {
                for (let key in WalletRepo[0]) {
                    if (
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = WalletRepo.filter((bank) => {
            let b = false;
            if (WalletRepo.length > 0) {
                for (let key in WalletRepo[0]) {
                    if (
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, [data]);


    useEffect(() => {
        SearchFilter();
    }, [Search]);


    useEffect(() => {
        getFqSection();
        getTicketList();
    }, []);


    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "35%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };
    const positionStyle = {
        position: "absolute",
        top: -40,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };
    const positionStyle1 = {
        position: "absolute",
        top: -28,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };
    const positionStyle2 = {
        position: "absolute",
        top: -54,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };



    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null || context.user.UserType !== "2" ? (
                    <Redirect to="/" />
                ) : (
                    <div>
                        <div
                            style={{ backgroundColor: "rgb(0,63,125)", textAlign: "center" }}
                            className="p-2"
                        >
                            <h4 style={{ color: "white" }}>
                                <strong>Help Center</strong>
                            </h4>
                        </div>
                        <TicketSidebar TicketId={TID} TicketSts={TicketSts} />
                        <IssueModal
                            show={showHelpFormmodal}
                            onHide={hideHelpFormmodal}
                            refresh={getTicketList}
                            user={context.user}
                        />

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className="m-0 p-0 m-4">
                                <div className="col-12 col-sm-8">
                                    <Nav
                                        variant="pills"
                                        className="nav-pills-custom justify-content-around p-1 "
                                        style={{
                                            borderRadius: "1rem",
                                            padding: "1rem",
                                            border: "1px solid #2F58CD",
                                            background: "#FFF",
                                        }}
                                    >
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="first"
                                                className="d-flex align-items-center"
                                            >
                                                FAQ's
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="second"
                                                className="d-flex align-items-center"
                                            >
                                                Ticket
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <Col className="col-12 col-sm-4">
                                    <Button
                                        onClick={() => setShowHelpFormmodal(true)}
                                        className="mb-2"
                                    >
                                        Raise Ticket
                                    </Button>
                                </Col>

                                <Col xs={12} className="p-0">
                                    <Tab.Content className="tab-content-custom-pill p-0">
                                        <Tab.Pane eventKey="first">
                                            <Row>
                                                <Col md={12} sm={12}>
                                                    <div className="mt-4 p-1">
                                                        <Form.Control
                                                            className="mb-2"
                                                            placeholder="Search FAQ's"
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                        <Faq
                                                            data={fqSection}
                                                            styles={styles}
                                                            config={config}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Col lg={12} md={12} sm={12}>
                                                <div
                                                    className="center-block scroll-inner"
                                                    style={{
                                                        width: "100% !important",
                                                        overflowX: "scroll",
                                                    }}
                                                >
                                                    <table
                                                        className="table dashboard-table-style"
                                                        style={{ width: "100%", overflowX: "auto" }}
                                                    >
                                                        <tr>
                                                            {columns.map((heading) => (
                                                                <th
                                                                    className="text-center"
                                                                    style={{ fontSize: "0.9rem" }}
                                                                >
                                                                    {heading.text}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                        {dataToDisplay.length > 0 ? (
                                                            dataToDisplay.map((e, ind) => (
                                                                <tr>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{
                                                                            fontSize: "0.8rem",
                                                                            color: "#03C988",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    // onClick={() => {
                                                                    //     setTID(e.id);
                                                                    //     document
                                                                    //         .querySelector(".content-wrapper")
                                                                    //         .classList.toggle("sidebar-icon-only");
                                                                    // }}
                                                                    >
                                                                        {e.tkt_trans_id}
                                                                    </td>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{ fontSize: "0.8rem" }}
                                                                    >
                                                                        {e.tkt_type}
                                                                    </td>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{ fontSize: "0.8rem" }}
                                                                    >
                                                                        {e.tkt_title}
                                                                    </td>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{ fontSize: "0.8rem" }}
                                                                    >
                                                                        {e.tkt_trans_id}
                                                                    </td>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{
                                                                            fontSize: "0.8rem",
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        {e.tkt_desc}
                                                                    </td>
                                                                    <td
                                                                        className="text-center"
                                                                        style={{ fontSize: "0.8rem" }}
                                                                    >
                                                                        <div className="row m-0 p-0">
                                                                            {e.tkt_status == 0 ? (
                                                                                <span className="flex-fill badge rounded-pill bg-danger p-2 text-light">
                                                                                    Open
                                                                                </span>
                                                                            ) : (
                                                                                <span class="badge rounded-pill bg-success text-light">
                                                                                    close
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ fontSize: "0.8rem" }}>
                                                                        {e.tkt_open_date}
                                                                    </td>
                                                                    <td style={{ fontSize: "0.8rem" }}>
                                                                        {e.tkt_close_date}
                                                                    </td>

                                                                    <td
                                                                        className="text-center m-0 p-0 "
                                                                        style={{
                                                                            width: "12rem",
                                                                            fontFamily: "Poppins",
                                                                            fontSize: "0.8rem",
                                                                            fontWeight: "400",
                                                                            lineHeight: "24px",
                                                                            letterSpacing: "0em",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <div className="d-flex flex-column align-items-center  p-0 m-0">
                                                                            <div className="row  block">

                                                                                <Button
                                                                                    style={{
                                                                                        // paddingLeft:"3px",
                                                                                        // padding: "5px",
                                                                                        borderRadius: "5px",
                                                                                        border: "1px none",
                                                                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                                                                        color: "rgba(255, 164, 27, 1)",
                                                                                        bordercolor: "rgba(255, 255, 255, 1)",
                                                                                    }}
                                                                                    className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                                                                                >
                                                                                    {" "}
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setTID(e.id);
                                                                                            document
                                                                                                .querySelector(".content-wrapper")
                                                                                                .classList.toggle("sidebar-icon-only");
                                                                                        }}
                                                                                        className="hover-container"
                                                                                    >
                                                                                        {/* Your content here */}
                                                                                        <img
                                                                                            src={require("../../../assets/images/AEPS OnBoarding.png")}
                                                                                            style={{ width: "" }}
                                                                                        />
                                                                                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  >AEPS Onboarding</div></div> */}

                                                                                        <div
                                                                                            style={positionStyle}
                                                                                            className=" hover-text  bg-dark "
                                                                                        >
                                                                                            AEPS On Boarding
                                                                                        </div>
                                                                                    </div>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={10}>No data available</td>
                                                            </tr>
                                                        )}
                                                    </table>
                                                </div>
                                            </Col>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

function IssueModal(props) {
    const [Id, setId] = useState("");
    const [Issue, setIssue] = useState("");
    const [Category, setCategory] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [services, setServices] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [img, setImg] = useState("");

    const [FormValues, setFormValues] = useState({

        refrence: "",
        service: "",
        attachment: "",
        remarks: "",

    });


    const [selecteDate, setSelecteDate] = useState({});
    const { setProfileinfo } = useContext(GlobalContext);
    const [FormErrors, setFormErrors] = useState({});

    const [uploadedDocs, setUploadedDoc] = useState({
        "imagename": "",
        "imagebytedata": ""
    });


    const { user } = useContext(GlobalContext);



    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(FormValues));
        //  setIsSubmit(true);


        if (FormValues.refrence && FormValues.service && FormValues.remarks && img) {
            submitRaiseTicket();

        }


    };

    // useEffect(() => {
    //     if (Object.keys(FormErrors).length === 0 && isSubmit) {
    //         Ticket();
    //     }
    // }, [FormErrors]);



    const validate = () => {
        const errors = {};

        if (!FormValues.refrence) {
            errors.refrence = "*Reference No. is required!";
        }
        if (!FormValues.service) {
            errors.service = "*Please Select Service";
        }
        if (!FormValues.remarks) {
            errors.remarks = "*Please give remarks";
        }
        if (!img) {
            errors.attachment = "*Please upload image";
        }


        return errors;
    };

    const submitRaiseTicket = async () => {
        try {
            //setTransactionLoaderState(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({

                userid: props.user.ReferId,
                refrence: FormValues.refrence,
                service: FormValues.service,
                date: currentDate,
                attachment: img,
                remarks: FormValues.remarks,
                device: "web"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Payment/support/submitRaiseTicket`,
                requestOptions
            );
            const data = await res.json();
            if (data.data.id === "Y") {
                swal("Success", "Raise Ticket", "success");
                props.refresh();
                props.onHide();
                setFormValues({

                    refrence: "",
                    service: "",
                    attachment: "",
                    remarks: "",

                })
            } else {
                swal("Failed", "Raise Ticket", "error");

            }
            // setTransactionLoaderState(false)
        } catch (error) {

            //  setTransactionLoaderState(false)
            console.log("error", error);
            swal("Failed", "Internal Server Error", "error");

        }
    };


    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        const fileByteArray = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };



    const ImageUpload = (e) => {
        if (FormValues.attachment.value == "") {
            swal("Invalid", "Testing Please Select User First", "error");
            return;
        }
        const { name, value } = e.target;
        //  setFormValues({ ...FormValues, [name]: value });
        // const type = e.target.id;

        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                var imageName = `TICKET_${user.ReferId}_${uniqueID()}`;
                setImg(imageName);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    imagename: imageName,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        "https://apiservices.bumppypay.com/api/Payment/imageUpload",
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {

                                cogoToast.success(result.msg);
                                const file = e.target.files[0]; // Get the first selected file
                                setSelectedFile(file);


                            } else {
                                cogoToast.error(result.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };


    const getServices = () => {
        try {
            var requestOptions = {
                method: "POST",
                redirect: "follow",
            };
            fetch(
                `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status == "0") {
                        setServices(result.data);
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (error) { }
    };
    useEffect(() => {
        getServices();
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);


    };




    useEffect(() => {
        getCurrentDate()
    }, []);





    function getCurrentDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's 0-based
        const day = String(now.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const currentDate = getCurrentDate();
    console.log(currentDate);





    return (
        <Modal show={props.show} onHide={props.onHide} centered size="md">
            <Modal.Header closeButton={true}>
                <Modal.Title as="h5" id="delete-address">
                    Raise Ticket
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <React.Fragment>
                    <Col>





                        <Form.Control
                            placeholder="Enter your transaction ID"
                            className="mt-2"

                            value={FormValues.refrence}
                            onChange={(e) => {
                                setFormValues({ ...FormValues, refrence: e.target.value });
                            }}
                        />
                        <p className="text-danger text-left p-0">{FormErrors.refrence}</p>
                        <Form.Control
                            as="select"
                            placeholder="Enter the category of issue"
                            className="mt-2 border-secondary text-secondary"
                            // onChange={(e) => setCategory(e.target.value)}


                            onChange={(e) => {
                                setFormValues({ ...FormValues, service: e.target.value });
                            }}
                        >
                            <option defaultValue>Select Transaction Category</option>
                            {services.map((e) => (
                                <option value={e.st_name}>{e.st_name}</option>
                            ))}


                        </Form.Control>
                        <p className="text-danger text-left p-0">{FormErrors.service}</p>
                        {/* <Form.Control as="select" className='mt-2'>
 <option defaultValue>Select Your Reason</option>
 <option>asdfghj</option>
 <option>dfgm</option>
 <option>asdfg</option>
 </Form.Control> */}


                        <Form.Control
                            as={TextArea}
                            placeholder="Remarks"
                            className="mt-2"
                            // onChange={(e) => setId(e.target.value)}
                            value={FormValues.remarks}
                            onChange={(e) => {
                                setFormValues({ ...FormValues, remarks: e.target.value });

                            }}
                        />
                        <p className="text-danger text-left p-0">{FormErrors.remarks}</p>


                        <Form.Label className="pt-2 mr-4">Select an Image:</Form.Label>

                        <input
                            type="file"
                            accept="image/*" // Set the accept attribute to allow only image files
                            onChange={ImageUpload}
                        />

                        <p className="text-danger text-left p-0">{FormErrors.attachment}</p>

                        <Button
                            variant="outline-info"
                            className="form-control m-0 mt-2"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Col>
                </React.Fragment>
            </Modal.Body>
        </Modal>
    );
}

export default HelpAndSupport;