import React, { useState } from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Epfadmin, Esiadmin, Labourwelfarefund, Professionaltaxadmin, Statutorybonus } from "./SalaryTable";

const Statutorycomponentsadmin = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="p-3">
        <h4 className="pt-2 pb-2 px-2">Statutory Components</h4>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
              style={activeTab === 'tab1' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab1')}
            >
              EPF
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
              style={activeTab === 'tab2' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab2')}
            >
              ESI
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
              style={activeTab === 'tab3' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab3')}
            >
              Professional Tax
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`}
              style={activeTab === 'tab4' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab4')}
            >
              Labour Welfare Fund
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'tab5' ? 'active' : ''}`}
              style={activeTab === 'tab5' ? { border: 'none' } : {}}
              onClick={() => handleTabClick('tab5')}
            >
              Statutory Bonus
            </button>
          </li>
        </ul>
        <div className="tab-content p-3">
          {activeTab === 'tab1' && <Epfadmin/>}
          {activeTab === 'tab2' && <Esiadmin/>}
          {activeTab === 'tab3' && <Professionaltaxadmin/>}
          {activeTab === 'tab4' && <Labourwelfarefund/>}
          {activeTab === 'tab5' && <Statutorybonus/>}
        </div>
      </div>
    </>
  );
};

export default Statutorycomponentsadmin;
