import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { billpunchbasicauth } from "../../utils/Credentials";

export const columns = [
  {
    dataField: "beni_name",
    text: "Name",
  },
  {
    dataField: "purpose",
    text: "Purpose",
  },
  {
    dataField: "beni_account",
    text: "Account",
  },
  {
    dataField: "beni_ifsc",
    text: "IFSC",
  },
  {
    dataField: "wl_before_balance",
    text: "Before Balance",
  },
  {
    dataField: "pay_amount",
    text: "Pay Amount",
  },
  {
    dataField: "wl_after_balance",
    text: "After Balance",
  },
  {
    dataField: "wl_indate",
    text: "Date",
  },
];

const AccountledgerHR = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  useEffect(() => {
    getEmployee();
  }, []);

  const handleMonthChange = (selectedMonth) => {
    setSelectedDate(selectedMonth);
  };

  const getEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmWalletHistory`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployees(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
      setTransactionLoaderState(false);
    }
  };

  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];

  const filteredEmployees = employees.filter((employee) => {
    const searchString = searchQuery.toLowerCase().trim();
    const selectedMonth = months.find((month) => month.name === selectedDate);
    const employeeMonth = employee?.wl_indate
      ? new Date(employee.wl_indate).getMonth()
      : null;
    const dateFilter =
      !selectedDate ||
      (employeeMonth !== null &&
        employeeMonth === months.indexOf(selectedMonth));

    const wlBeforeBalance =
      twoDecimalPlaces(employee?.wl_before_balance)?.toString().toLowerCase() ||
      "";
    const payAmount = employee?.pay_amount?.toString().toLowerCase() || "";
    const wlAfterBalance =
      employee?.wl_after_balance?.toString().toLowerCase() || "";
    const transactionDate = employee?.wl_indate
      ? new Date(employee.wl_indate).toLocaleDateString()
      : "";

    return (
      dateFilter &&
      (employee?.beni_name?.toLowerCase().includes(searchString) ||
        employee?.purpose?.toLowerCase().includes(searchString) ||
        employee?.beni_account?.toLowerCase().includes(searchString) ||
        employee?.beni_ifsc?.toLowerCase().includes(searchString) ||
        wlBeforeBalance.includes(searchString) ||
        payAmount.includes(searchString) ||
        wlAfterBalance.includes(searchString) ||
        transactionDate.includes(searchString))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  function getMonthName(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString("default", { month: "long" });
  }
  const paginationOptions = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "250", value: 250 },
  ];
  const handleItemsPerPageChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const selectOptions = paginationOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));
  function getMonthName(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString("default", { month: "long" });
  }

  return (
    <>
      <div
        style={{ borderTopLeftRadius: ".4rem", borderTopRightRadius: ".4rem" }}
      >
        <div className="tab-custom-pills-horizontal m-0 p-0 ">
          <div
            className=" p-0 mt-md-2 mt-sm-2"
            style={{ borderRadius: ".4rem" }}
          >
            <div className="row">
              <div className="col-6">
                <h5
                  className="d-flex ml-md-3 align-items-center"
                  style={{ color: "#0074D9" }}
                >
                  Transaction History
                </h5>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <select
                  style={{
                    width: "6rem",
                    height: "2.5rem",
                    borderRadius: ".4rem",
                    border: "1px #ced4da solid",
                  }}
                  value={selectedDate}
                  onChange={(e) => handleMonthChange(e.target.value)}
                >
                  <option value="">Month</option>
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-2 d-flex justify-content-end">
                <input
                  style={{
                    background: "#fff",
                    borderRadius: ".4rem",
                    height: "2.4rem",
                    border: "1px #ced4da solid",
                  }}
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className=" px-4 ">
              <hr className="p-0 mx-md-1" />
              <table
                className="my-table mx-2 transparent-table text-center border-bottom"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  border: "0px",
                  overflow: "auto",
                }}
              >
                <thead className="mx-md-2">
                  <tr
                    className="borderless-table text-center border-bottom"
                    style={{ background: "white" }}
                  >
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      {"Sr"}
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Name
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Purpose
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Bank
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Before Balance
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Paid Amount
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      After Balance
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Month
                    </th>
                    <th
                      className=" text-center  "
                      style={{ background: "white", color: "black" }}
                    >
                      Transaction Date
                    </th>
                  </tr>
                </thead>

                <tbody className="mx-md-2">
                  {currentItems.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    currentItems.map((item, index) => (
                      <tr className="border-bottom" key={index}>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {indexOfFirstItem + index + 1}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {item.beni_name}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {item.purpose}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {item.beni_account} <br /> {item.beni_ifsc}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {twoDecimalPlaces(item.wl_before_balance)}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {twoDecimalPlaces(item.pay_amount)}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {item.wl_after_balance}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {getMonthName(item.wl_indate)}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{ border: "none", fontSize: ".8rem" }}
                        >
                          {new Date(item.wl_indate).toLocaleDateString()}
                        </td>{" "}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Pagination */}
              <div className="mt-3 mb-3 ">
                <label>Items per page:</label>
                <div style={{ width: "6rem" }}>
                  <Select
                    options={selectOptions}
                    value={{ value: itemsPerPage, label: `${itemsPerPage}` }}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "4rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountledgerHR;
