import React, { useEffect, useState } from "react";
import { Col, Row, Nav, Tab, Container, Button, Navbar } from "react-bootstrap";
import AddEmployee from "./EmployeListHR";
import AddBill from "./ExpenseDashboardHR";
import "react-toastify/dist/ReactToastify.css";
import Payroll from "./PayrollHR";
import Attandance from "./AttandanceManagement";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Firmdata = () => {
  const CompanyName = sessionStorage.getItem("CompanyName");
  const [filterState, setFilterState] = useState(1);
  const history = useHistory();

  const handleButtonClick = () => {
    history.push("/retailer/SalaryManagement");
  };
  useEffect(() => {}, []);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="row">
          <div
            className="col d-flex mx-md-4 mt-md-1 mt-sm-1"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          >
            {CompanyName || "Default Company Name"}
          </div>
          <div
            className=" col d-flex justify-content-end mr-3 mt-md-1 mt-sm-1"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          >
            <button
              type="button"
              className="mt-md-2 mr-md-5 btn d-flex justify-content-center align-items-center btn-sm btn-primary"
              style={{
                color: "#fff",
                border: "none",
                height: "2rem",
                borderRadius: ".4rem",
              }}
              onClick={handleButtonClick}
            >
              Back
            </button>
          </div>
        </div>
        {/* <NavSalary /> */}
        <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            {" "}
            <Row className="p-0 m-0">
              {" "}
              <Col className="p-0 m-0 mx-md-2 px-md-2" sm={12}>
                <Col
                  className="card p-0 m-0"
                  style={{ borderRadius: ".4rem", background: "#f1f2f4" }}
                  sm={12}
                >
                  <Nav
                    variant=""
                    className="nav-pills-custom  p-1 w-100 "
                    style={{
                      justifyContent: "space-evenly",
                      background: "#fff",
                      borderRadius: ".4rem",
                      height: "8.5rem",
                    }}
                  >
                    <Nav.Item className="mt-md-3  pl-2 pr-2">
                      <Nav.Link
                        eventKey="first"
                        to="/retailer/EmployeeList"
                        className="PMS-community-head d-flex align-items-center"
                        style={{
                          fontSize: ".9rem",
                          // color: "grey",
                          // marginLeft: "-3em",
                        }}
                      >
                        <div
                          className="col-8 col-sm-6 col-md-3 col-lg-2 p-2 d-flex flex-column align-items-center"
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            alt=""
                            className={`PMS-community-head d-flex align-items-center ${
                              filterState === 1 ? "active-tab" : ""
                            }`}
                          
                            onClick={() => {
                              setFilterState(1);
                            }}
                            style={{
                              height: "3rem",
                              // borderRadius: "100%",
                              padding: "7px",
                              backgroundColor: "white",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2",
                            }}
                            src={require("../../../assets/images/pms/Employee_management.svg")}
                          />
                        </div>
                        <div
                          onClick={() => setFilterState(1)}
                          className={`PMS_text ${
                            filterState === 1 ? "bold-text" : ""
                          }`}
                          style={{ marginBottom: "-6em", marginLeft: "-5.5em" , color:"grey"}}
                        >
                          Employee Management
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="mt-md-3  pl-2 pr-2">
                      <Nav.Link
                        eventKey="first"
                        to="/retailer/EmployeeList"
                        className="PMS-community-head d-flex align-items-center"
                        style={{
                          fontSize: ".9rem",
                          // color: "grey",
                          // marginLeft: "-3em",
                        }}
                      >
                        <div
                          className="col-8 col-sm-6 col-md-3 col-lg-2 p-2 d-flex flex-column align-items-center"
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            alt=""
                            className={`PMS-community-head d-flex align-items-center ${
                              filterState === 2 ? "active-tab" : ""
                            }`}
                          
                            onClick={() => {
                              setFilterState(2);
                            }}
                            style={{
                              height: "3rem",
                              // borderRadius: "100%",
                              padding: "7px",
                              backgroundColor: "white",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2",
                            }}
                            src={require("../../../assets/images/pms/Attandance.svg")}
                          />
                        </div>
                        <div
                         onClick={() => setFilterState(2)}
                         className={`PMS_text ${
                          filterState === 2 ? "bold-text" : ""
                        }`}
                          style={{ marginBottom: "-6em", marginLeft: "-5.5em" ,color:"grey"}}
                        >
                          Attendance Management
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="m-md-3 pl-2 pr-2">
                      <Nav.Link
                        eventKey="second"
                        to="/retailer/Payroll"
                        className="PMS-community-head d-flex align-items-center"
                        style={{
                          fontSize: ".9rem",
                          // color: "grey",
                          marginLeft: "-4em",
                        }}
                      >
                        <div className="col-8 col-sm-6 col-md-3 col-lg-2 p-2 d-flex flex-column align-items-center">
                          <img
                            alt=""
                            // className="Collection-gateway-icons"
                            className={`PMS-community-head d-flex align-items-center ${
                              filterState === 3 ? "active-tab" : ""
                            }`}
                          
                            onClick={() => {
                              setFilterState(3);
                            }}
                            style={{
                              height: "3rem",
                              // borderRadius: "100%",
                              padding: "7px",
                              backgroundColor: "white",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2",
                            }}
                            src={require("../../../assets/images/pms/Payroll_Processing.svg")}
                          />
                        </div>
                        <div
                         onClick={() => setFilterState(3)}
                         className={`PMS_text ${
                          filterState === 3 ? "bold-text" : ""
                        }`}
                          style={{ marginBottom: "-6em", marginLeft: "-4.5em" ,color:"grey"}}
                        >
                          Payroll Processing
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="mt-md-3  pl-2 pr-2">
                      <Nav.Link
                        eventKey="third"
                        to="/retailer/AddBills"
                        className="PMS-community-head d-flex align-items-center"
                        style={{
                          fontSize: ".9rem",
                          // color: "grey",
                          marginLeft: "-1.3em",
                        }}
                      >
                        <div className="col-8 col-sm-6 col-md-3 col-lg-2 p-2 d-flex flex-column align-items-center">
                          <img
                            alt=""
                            className={`PMS-community-head d-flex align-items-center ${
                              filterState === 4 ? "active-tab" : ""
                            }`}
                          
                            onClick={() => {
                              setFilterState(4);
                            }}
                            style={{
                              height: "3rem",
                              // borderRadius: "100%",
                              padding: "7px",
                              backgroundColor: "white",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2",
                            }}
                            src={require("../../../assets/images/pms/salary_report.svg")}
                          />
                        </div>
                        <div
                         onClick={() => setFilterState(4)}
                         className={`PMS_text ${
                          filterState === 4 ? "bold-text" : ""
                        }`}
                          style={{
                            marginBottom: "-6em",
                            marginLeft: "-5.6em",color:"grey"
                          }}
                        >
                          Expenses Management
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Col>
              <Col xs={12}>
                <Tab.Content className="tab-content-custom-pill pb-0">
                  {filterState === 1 && <AddEmployee />}
                  {filterState === 2 && <Attandance />}
                  {filterState === 3 && <Payroll />}
                  {filterState === 4 && <AddBill />}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Firmdata;