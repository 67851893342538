import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
const Test = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [content, setContent] = useState([]);
  const [Loaddata, setLoaddata] = useState([]);
  const [Editblogdata, setEditblogdata] = useState([]);
  const Editblogid = JSON.parse(sessionStorage.getItem("Editblogid"));
  const [postTempId, setPostTempId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalType, setModalType] = useState("");
  const [selectedStyle, setSelectedStyle] = useState({ fontSize: "1em", fontWeight: "normal", color: "black", fontFamily: "Arial" });
  const renderToolbar = () => (
    <div className="modal-toolbar">
      <Button onClick={() => applyStyle({ ...selectedStyle, fontWeight: "bold" })}>Bold</Button>
      <Button onClick={() => applyStyle({ ...selectedStyle, fontStyle: "italic" })}>Italic</Button>
      <Button onClick={() => applyStyle({ ...selectedStyle, textDecoration: "underline" })}>Underline</Button>
      <Button onClick={() => applyStyle({ ...selectedStyle, color: "red" })}>Red</Button>
      <Button onClick={() => applyStyle({ ...selectedStyle, fontSize: "1.5em" })}>Increase Font Size</Button>
      <Button onClick={() => applyStyle({ ...selectedStyle, fontSize: "1em" })}>Normal Font Size</Button>
    </div>
  );
  const renderModalContent = () => (
    <div>
      {renderToolbar()}
      <textarea
        value={modalContent}
        onChange={(e) => setModalContent(e.target.value)}
        style={selectedStyle}
      />
    </div>
  );

  const applyStyle = (style) => {
    setSelectedStyle(style);
  };

  const handleSave = () => {
    const updatedContent = [...content];
    updatedContent[currentIndex] = {
      ...updatedContent[currentIndex],
      block_content: modalContent
    };
    setContent(updatedContent);
    setShowModal(false);
  };
  const handleOpenModal = (content, type, index) => {
    setModalContent(content);
    setModalType(type);
    setCurrentIndex(index);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getStyle = (type) => {
    switch (type) {
      case "h1":
        return { fontSize: "2em" };
      case "h2":
        return { fontSize: "1.5em" };
      case "h3":
        return { fontSize: "1.17em" };
      case "h4":
        return { fontSize: "1em" };
      case "h5":
        return { fontSize: "0.83em" };
      case "h6":
        return { fontSize: "0.67em" };
      case "paragraph":
        return { fontSize: "1em" };
      case "orderedList":
      case "unorderedList":
        return { fontSize: "1em" };
      case "quote":
        return { fontSize: "1em", fontStyle: "italic" };
      case "checklist":
        return { fontSize: "1em" };
      case "code":
        return { fontSize: "1em" };
      case "link":
        return { fontSize: "1em", color: "blue", textDecoration: "underline" };

      default:
        return { fontSize: "1em" };
    }
  };
  const [editableContent, setEditableContent] = useState([
    ...content,
    ...Editblogdata,
  ]);

  useEffect(() => {
    setEditableContent([...content, ...Editblogdata]);
  }, [content, Editblogdata]);
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedContent = [...editableContent];
    updatedContent[index] = { ...updatedContent[index], [name]: value };
    setEditableContent(updatedContent);
  };
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const renderContent = () => {
    return editableContent.map((item, index) => {
      let contentElement;
      const strippedContent = stripHtmlTags(item.block_content || item.text);

      switch (item.block_type || item.type) {
        case "h1":
          contentElement = (
            <div key={index} className="mt-2">
                           <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle(item.block_type)}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />

              {/* <h1 style={getStyle("h1")}>{item.block_content || item.text}</h1> */}
            </div>
          );
          break;

        case "h2":
          contentElement = (
            <div key={index} className="mt-2">
              <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("h2")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <h2 style={getStyle("h2")}>{item.block_content || item.text}</h2> */}
            </div>
          );
          break;

        case "h3":
          contentElement = (
            <div key={index} className="mt-2">
              <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("h3")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <h3 style={getStyle("h3")}>{item.block_content || item.text}</h3> */}
            </div>
          );
          break;

        case "h4":
          contentElement = (
            <div key={index} className="mt-2">
              <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("h4")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <h4 style={getStyle("h4")}>{item.block_content || item.text}</h4> */}
            </div>
          );
          break;

        case "h5":
          contentElement = (
            <div key={index} className="mt-2">
              <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("h5")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <h5 style={getStyle("h5")}>{item.block_content || item.text}</h5> */}
            </div>
          );
          break;

        case "h6":
          contentElement = (
            <div key={index} className="mt-2">
              <input
                className="form-control"
                type="text"
                name="block_content"
                value={strippedContent}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("h6")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <h6 style={getStyle("h6")}>{item.block_content || item.text}</h6> */}
            </div>
          );
          break;

        case "paragraph":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                className="form-control"
                name="block_content"
                rows={10}
                value={item.block_content || item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("paragraph")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <p style={getStyle("paragraph")}>{item.block_content || item.text}</p> */}
            </div>
          );
          break;

        case "unorderedList":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                className="form-control"
                name="block_content"
                value={item.block_content || item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("unorderedList")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <ul style={getStyle("unorderedList")}>
                <li>{item.block_content || item.text}</li>
              </ul> */}
            </div>
          );
          break;
        case "numberedList":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                className="form-control"
                name="block_content"
                value={item.block_content || item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("numberedList")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <ol style={getStyle("numberedList")}>
                <li>{item.block_content || item.text}</li>
              </ol> */}
            </div>
          );
          break;
        case "quote":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                className="form-control"
                name="block_content"
                value={item.block_content || item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("quote")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />
              {/* <blockquote style={getStyle("quote")}>{item.block_content || item.text}</blockquote> */}
            </div>
          );
          break;
        case "checklist":
          contentElement = (
            <div key={index} style={getStyle("checklist")}>
              <input
                className="form-control"
                type="checkbox"
                name="text"
                checked={item.text === "checked"} // Adjust based on your data
                onChange={(e) => handleInputChange(index, e)}
              />
              <input
                className="form-control"
                type="text"
                name="text"
                value={item.text}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          );
          break;
        case "code":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                name="text"
                value={item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("code")}
              />
              <SyntaxHighlighter
                customStyle={getStyle("code")}
                language={item.language}
                style={vs}
              >
                {item.text}
              </SyntaxHighlighter>
            </div>
          );
          break;
        case "link":
          contentElement = (
            <div key={index} className="mt-2">
              <textarea
                className="form-control"
                name="block_content"
                rows={3}
                value={item.block_content || item.text}
                onChange={(e) => handleInputChange(index, e)}
                style={getStyle("link")}
                onClick={() => handleOpenModal(item.block_content || item.text, item.block_type, index)}
              />

              {/* <a href={item.block_content || item.text} style={getStyle("link")} target="_blank" rel="noopener noreferrer">
                {item.block_content || item.text}
              </a> */}
            </div>
          );
          break;
        case "uploadedImage":
          contentElement = (
            <div key={index} className="mt-2">
              {/* <input
                type="text"
                name="block_image"
                value={item.block_image || item.src}
                onChange={(e) => handleInputChange(index, e)}
              /> */}
              <img
                src={item.block_image || item.src}
                alt="Uploaded Image"
                style={{ width: "30vw" }}
              />
            </div>
          );
          break;
        case "uploadedVideo":
          contentElement = (
            <div key={index} className="mt-2">
              {/* <input
                type="text"
                name="block_image"
                value={item.block_image || item.src}
                onChange={(e) => handleInputChange(index, e)}
              /> */}
              <video
                controls
                src={item.block_image || item.src}
                style={{ width: "40vw" }}
              />
            </div>
          );
          break;
        default:
          contentElement = null;
      }

      return contentElement;
    });
  };
  useEffect(() => {
    setEditableContent([...content, ...Editblogdata]);
  }, [content, Editblogdata]);

  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;

        try {
          const response = await fetch(
            `https://goseo.in/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            // Set the uploaded image URL in the formState
            setFormState((prevState) => ({
              ...prevState,
              imageUrl,
            }));

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const Geteditloadblog = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          p_post_id: Editblogid.post_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/LoadBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setLoaddata(data.data || []);
        setPostTempId(data.result[0].post_tempid);
        console.log(data.result[0].post_tempid);
        Geteditblog();
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const Geteditblog = async () => {
    if (!postTempId) return;
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          tempid: postTempId,
          postid:""
        }),
        redirect: "follow",
      };
      console.log(postTempId);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setEditblogdata(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    Geteditloadblog();
  }, []);
  useEffect(() => {
    if (postTempId) {
      Geteditblog();
    }
  }, [postTempId]);

  
  console.log(modalContent);
  return (
    <div className="m-3">
      <div className="row mt-0 pt-0 pb-0">
        <div className="col">
          <h5>Edit Blogs</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/Blogs">
            <Button size="sm" variant="info">
              Back
            </Button>
          </Link>
        </div>
      </div>
      <Form className="row pb-0">
        <Form.Group className="col-md-3" controlId="formTitle">
          <Form.Control
            type="text"
            placeholder="Enter title"
            name="title"
            value={formState.title}
            onChange={handleChange}
          />{" "}
        </Form.Group>

        <Form.Group className="col-md-3" controlId="formExcerpt">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Add excerpt"
            name="excerpt"
            value={formState.excerpt}
            onChange={handleChange}
          />{" "}
        </Form.Group>

        <Form.Group
          className=" col d-flex align-items-center"
          controlId="formImage"
        >
          <Form.Label className="mb-0 mr-2">Select feature image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handlefeatureImageUpload}
            className="flex-grow-1"
          />{" "}
        </Form.Group>
      </Form>
      <div className=" col-12 px-md-2">
        <div className="">{renderContent()}</div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Content Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Modal.Body>
          {renderModalContent()}
        </Modal.Body>
        <div style={getStyle(modalType)}>{modalContent}</div>
        </Modal.Body>
       
      </Modal>
    </div>
  );
};

export default Test;
