import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
const Loginqueries = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Firms, setFirms] = useState([]);
  const { password, username } = billpunchbasicauth;

  const Getfirms = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mobile: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetRecentNewFirm`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setFirms(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getfirms();
  }, []);

  const handleApprove = async (firm_id) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        _new_firmid: firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/ApproveFirm`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setFirms((prevFirms) =>
          prevFirms.map((firm) =>
            firm.pk_new_firm_id === firm_id ? { ...firm, status: "N" } : firm
          )
        );
      }
      swal("Success", data.msg, "success");
      Getfirms();
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  return (
    <div className="p-3">
      <div className="row">
        <h5 className="col">Login Queries</h5>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th style={tableHeaderStyle}>Firm Name</th>
              <th style={tableHeaderStyle}>Owner</th>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>GST</th>
              <th style={tableHeaderStyle}>Firm type</th>
              <th style={tableHeaderStyle}>Firm Size</th>
              <th style={tableHeaderStyle}>
                Email
                <br />
                Mobile
              </th>
              <th style={tableHeaderStyle}>
                PAN
                <br />
                Aadhaar
              </th>
              <th style={tableHeaderStyle}>Status</th>
            </tr>
          </thead>
          <tbody>
            {Firms.map((item) => (
              <tr
                key={item.pk_new_firm_id}
                style={{ border: "none", cursor: "pointer" }}
              >
                <td style={tableDataStyle}>{item.firm_name}</td>
                <td style={tableDataStyle}>{item.firm_owner_name}</td>
                <td style={tableDataStyle}>
                  {" "}
                  {new Date(item.firm_startdate).toLocaleDateString() || "NA"}
                </td>

                <td style={tableDataStyle}>{item.firm_gst}</td>
                <td style={tableDataStyle}>{item.firm_type}</td>
                <td style={tableDataStyle}>{item.firm_size}</td>
                <td style={tableDataStyle}>
                  {item.firm_email}
                  <br />
                  {item.firm_mobile}
                </td>
                <td style={tableDataStyle}>
                  {item.owner_pan}
                  <br />
                  {item.owner_aadhar}
                </td>

                <td style={tableDataStyle}>
                  <Button
                    className={`p-1 badge ${
                      item.status === "Y" ? "btn-warning" : "btn-success"
                    }`}
                    style={{
                      cursor: "pointer",
                      fontSize: ".8rem",
                    }}
                    onClick={() => handleApprove(item.pk_new_firm_id)}
                  >
                    {item.status === "Y" ? "Click to add" : "Added"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  borderBottom: "1px solid #f2f4f2",
  color: "#000",
  padding: "8px",
  textAlign: "center",
  background: "#E1F7F5",
};

const tableDataStyle = {
  padding: "8px",
  textAlign: "center",
  border: "none",
  fontSize: ".8rem",
  borderBottom: "1px solid #f2f4f2",
  borderRight: "1px solid #f2f4f2",
  fontWeight: "600",
};

export default Loginqueries;
