import React, { useState, useEffect, useRef } from "react";

const Calculator = () => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const calculatorRef = useRef(null);

  const handleButtonClick = (value) => {
    setInput(input + value);
  };

  const handleClear = () => {
    setInput("");
    setResult("");
  };

  const handleCalculate = () => {
    try {
      setResult(eval(input).toString());
    } catch (error) {
      setResult("Error");
    }
  };

  const handleBackspace = () => {
    setInput(input.slice(0, -1));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isFocused) {
        const { key } = event;
        if (/[0-9+\-*/.=]|Enter|Escape|Backspace/.test(key)) {
          event.preventDefault();
          if (key === "Enter") {
            handleCalculate();
          } else if (key === "Escape") {
            handleClear();
          } else if (key === "Backspace") {
            handleBackspace();
          } else {
            handleButtonClick(key);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isFocused, handleButtonClick, handleCalculate, handleClear, handleBackspace]);

  return (
    <div
      ref={calculatorRef}
      style={calculatorStyle}
      tabIndex={0}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div style={headerStyle}>
        Calculator
      </div>
      <div style={displayStyle}>
        <input type="text" value={input} readOnly style={inputStyle} />
        <span style={resultStyle}>{result}</span>
      </div>
      <div style={buttonGridStyle}>
        <button onClick={() => handleButtonClick("7")} style={buttonStyle}>7</button>
        <button onClick={() => handleButtonClick("8")} style={buttonStyle}>8</button>
        <button onClick={() => handleButtonClick("9")} style={buttonStyle}>9</button>
        <button onClick={() => handleButtonClick("/")} style={operatorStyle}>/</button>
        <button onClick={() => handleButtonClick("4")} style={buttonStyle}>4</button>
        <button onClick={() => handleButtonClick("5")} style={buttonStyle}>5</button>
        <button onClick={() => handleButtonClick("6")} style={buttonStyle}>6</button>
        <button onClick={() => handleButtonClick("*")} style={operatorStyle}>X</button>
        <button onClick={() => handleButtonClick("1")} style={buttonStyle}>1</button>
        <button onClick={() => handleButtonClick("2")} style={buttonStyle}>2</button>
        <button onClick={() => handleButtonClick("3")} style={buttonStyle}>3</button>
        <button onClick={() => handleButtonClick("-")} style={operatorStyle}>-</button>
        <button onClick={() => handleButtonClick("0")} style={buttonStyle}>0</button>
        <button onClick={() => handleButtonClick(".")} style={buttonStyle}>.</button>
        <button onClick={handleClear} style={{ ...buttonStyle, backgroundColor: "#e74c3c", gridColumn: "span 2" }}>C</button>
        <button onClick={() => handleButtonClick("+")} style={operatorStyle}>+</button>
        <button onClick={handleCalculate} style={{ ...buttonStyle, backgroundColor: "#2ecc71", gridColumn: "span 2" }}>=</button>
        <button onClick={handleBackspace} style={{ ...buttonStyle, backgroundColor: "#f39c12" }}>←</button>
      </div>
    </div>
  );
};

const calculatorStyle = {
  width: "300px",
  margin: "20px auto",
  fontFamily: "Arial, sans-serif",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  overflow: "hidden",
  outline: "none", // For focus styles
};

const headerStyle = {
  backgroundColor: "#2c3e50",
  color: "#fff",
  padding: "10px",
  borderRadius: "10px 10px 0 0",
};

const displayStyle = {
  backgroundColor: "#f1f1f1",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "0 0 10px 10px",
};

const inputStyle = {
  width: "calc(100% - 20px)",
  marginBottom: "5px",
  padding: "8px",
  fontSize: "16px",
  border: "none",
  borderRadius: "3px",
  boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)",
  boxSizing: "border-box",
};

const resultStyle = {
  fontSize: "18px",
  display: "block",
  textAlign: "right",
};

const buttonGridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridGap: "5px",
  overflow: "hidden",
};

const buttonStyle = {
  padding: "15px",
  fontSize: "18px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  backgroundColor: "#3498db",
  color: "#fff",
  borderRadius: "5px",
  transition: "background-color 0.3s",
};

const operatorStyle = {
  ...buttonStyle,
  backgroundColor: "#f39c12",
};

export default Calculator;
