import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import "react-quill/dist/quill.snow.css";
import {
  FaFlag,
} from "react-icons/fa";
import {  AddsubTask1employeeModal } from "./Modal";
import { Badge } from "react-bootstrap";
import { billpunchbasicauth } from "../../utils/Credentials";
export const Dummy = () => {
  const {user, setTransactionLoaderState } = useContext(GlobalContext);
  const [taskdata, setTaskData] = useState([]);
  const [showaddsubtaskModal, setshowaddsubtaskModal] = useState(false);
  const openaddsubtaskModal = () => {
    setshowaddsubtaskModal(true);
  };
  const closeaddsubtaskModal = () => {
    setshowaddsubtaskModal(false);
  };
  const { password , username } = billpunchbasicauth;
  const taskdataobject = JSON.parse(sessionStorage.getItem("taskdata"));
  console.log(taskdataobject, "taskdataobject");
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));
 
  const getTask = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
   
      var raw = JSON.stringify({
        spaceid: propertyobject.pk_work_id,
        firmid:user.firm_id
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setTaskData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  const options = [
    {
      value: "Urgent",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
          Urgent
        </div>
      ),
    },
    {
      value: "High",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
          High
        </div>
      ),
    },
    {
      value: "Normal",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
          Normal
        </div>
      ),
    },
    {
      value: "Low",
      label: (
        <div>
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
          Low
        </div>
      ),
    },
    {
      value: "Clear",
      label: (
        <div>
          {" "}
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
          Clear
        </div>
      ),
    },
  ];
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredTasks = taskdata.filter((item) => {
      const fullName = `${item.firstname} ${item.lastname} ${item.designation} ${item.mobile} ${item.emptype} ${item.department}`.toLowerCase();
      return !searchQuery || fullName.includes(searchQuery);
    });
    setFilteredTaskData(filteredTasks);
  };
  
  return (
    <div className="p-4" style={{ background: "white" }}>
      <AddsubTask1employeeModal
        show={showaddsubtaskModal}
        closeModal={closeaddsubtaskModal}
        getSubsubTask={getTask}
      />

      <div className="row">
        <h4 className="col-md-8 col">Task</h4>
        <div className="col">
        <input className="form-control"
            type="text"
            placeholder="Search Task..."
            onChange={handleSearch}
          /> 
        </div>
        <div className="col-1 d-flex justify-content-end">
          {" "}
          <Badge
            className="d-flex  align-items-center"
            style={{ border: "1px solid blue", background: "azure" }}
            onClick={openaddsubtaskModal}
          >
            Add Task
          </Badge>
        </div>
      </div>

      <div className="d-flex">
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Task Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Assign
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Start date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                End date
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Description
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Platform
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Category
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "left",
                  background: "#fff",
                }}
              >
                Priority
              </th>
              <th
                style={{
                  borderBottom: "1px solid #f2f4f2",
                  color: "#000",
                  padding: "8px",
                  textAlign: "center",
                  background: "#fff",
                }}
              >
                Status
              </th>
          
            </tr>
          </thead>
          <tbody>
            {taskdata.map((item) => (
              <React.Fragment key={item.pk_task_id}>
                <tr style={{ border: "none", cursor: "pointer" }}>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.taskname}
                  </td>

                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.task_assignee}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {new Date(item.task_startdate).toLocaleDateString()}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {new Date(item.task_enddate).toLocaleDateString()}
                  </td>

                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.task_description}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.platform}
                  </td>
                  <td
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {item.category}
                  </td>
                  <td
                    style={{
                      // padding: "8px",
                      textAlign: "center",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                   {item.task_priority === "High" && (
          <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />
        )}
        {item.task_priority === "Urgent" && (
          <FaFlag style={{ marginRight: "5px", color: "maroon" }} />
        )}
        {item.task_priority === "Normal" && (
          <FaFlag style={{ marginRight: "5px", color: "blue" }} />
        )}
        {item.task_priority === "Low" && (
          <FaFlag style={{ marginRight: "5px", color: "grey" }} />
        )}
        {item.task_priority === "Clear" && (
          <img
            src={require("../../../assets/images/flag_1.png")}
            style={{ marginRight: "5px", width: ".8rem" }}
          />
        )}
        {/* {item.task_priority}  */}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      border: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {" "}
                    {item.status === "O" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 46, 46, 0.1)",
                          color: "#ff2e2e",
                          border: "1px solid #ff2e2e",
                          borderRadius: ".3rem",
                        }}
                      >
                        Overdue
                      </span>
                    )}
                    {item.status === "P" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(255, 255, 0, 0.1)",
                          color: " #f7b12a",
                          border: "1px solid #f7b12a",
                          borderRadius: ".3rem",
                        }}
                      >
                        Pending
                      </span>
                    )}
                    {item.status === "C" && (
                      <span
                        className=" p-1 "
                        style={{
                          backgroundColor: "rgba(43, 193, 85, 0.1)",
                          color: "#2bc155",
                          border: "1px solid #2bc155",
                          borderRadius: ".3rem",
                        }}
                      >
                        Complete
                      </span>
                    )}
                  </td>
               
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
