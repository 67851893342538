import React, { useContext, useEffect, useState } from "react";
import {
  FaMoneyBill,
  FaProjectDiagram,
  FaTasks,
  FaUserCircle,
} from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import { IoIosPersonAdd, IoMdMail } from "react-icons/io";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { ViewProjectadmindash } from "./SalaryTable";
import { FcNext, FcPrevious } from "react-icons/fc";
import { billpunchbasicauth } from "../../utils/Credentials";
const AdminDashboard = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [absent, setabsent] = useState([]);
  const [BarData, setBarData] = useState([]);
  const [employeeCounts, setEmployeeCounts] = useState([]);
  const [employeebirth, setemployeebirth] = useState([]);
  const [projectcount, setprojectcount] = useState([]);
  const [interns, setInterns] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const { password, username } = billpunchbasicauth;
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic" + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      console.log(myHeaders);
      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartment(data?.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getEmployeebirthday = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmId: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setemployeebirth(data.data);
        const internsData = data.data.filter(
          (employee) => employee?.emp_type === "Intern"
        );
        const employeesData = data.data.filter(
          (employee) => employee?.emp_type !== "Intern"
        );

        setInterns(internsData);
        setEmployees(employeesData);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const Getprojectcount = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectCount`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setprojectcount(data?.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getEmployeeCount = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,

        body: JSON.stringify({
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetEmployeeCountDash`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeeCounts(data?.data[0]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const labelsMap = {
    it_employees: "IT",
    sales_employees: "Sales",
    digital_employees: "Digital",
    customer_support: "Customer Support",
    accounts: "Accounts",
    other: "Other",
  };

  const chartLabels = Object.keys(employeeCounts).map((key) => labelsMap[key]);
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: Object.values(employeeCounts),
        backgroundColor: [
          "#9AD0C2",
          "#41C9E2",
          "#FEC7B4",
          "#B0C5A4",
          "#BFEA7C",
          "#FFF67E",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false, // Hide legend labels
    },
    tooltips: {
      enabled: true, // Enable tooltips
    },
  };
  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const currentMonth = new Date()?.getMonth() + 1;
  const filteredBirthdays = employeebirth.filter((birth) => {
    const birthMonth = new Date(birth?.dob).getMonth() + 1;
    return birthMonth === currentMonth;
  });
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const GetPayoutMonthly = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic" + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        month: "0",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetPayoutDashboard`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        const formattedData = {
          labels: [],
          datasets: [
            {
              label: "Total Intern Salary",
              data: [],
              backgroundColor: "rgba(54, 162, 235, 1)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
            {
              label: "Total Full Time Salary",
              data: [],
              backgroundColor: "rgba(54, 233, 235, 19)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        };

        responseData.data.forEach((monthData) => {
          formattedData.labels.push(monthData.month);
          formattedData.datasets[0].data.push(
            parseFloat(monthData?.totalInternSalary)
          );
          formattedData.datasets[1].data.push(
            parseFloat(monthData?.totalFullTimeSalary)
          );
        });

        setBarData(formattedData);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const GetAllEmployeeLeave = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic" + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetAllEmployeeLeave`,
        requestOptions
      );
      const leave = await res.json();
      if (leave.status === "0") {
        setabsent(leave?.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getEmployeebirthday();
    getEmployeeCount();
    GetPayoutMonthly();
    GetAllEmployeeLeave();
    Getprojectcount();
    getDepartmentList();
    // getAccessData();
  }, []);
  const months = [
    { value: "January", name: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
  ];

  const [selectedMonth, setSelectedMonth] = useState("January");
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  useEffect(() => {
    const getEmployeegenerateddashboard = async () => {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic" + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          month: selectedMonth,
          firmid: user.firm_id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFixedExpenseDashboard`,
          requestOptions
        );
        const responseData = await res.json();

        if (responseData.status === "0") {
          const dashboardData = responseData?.data[0] || {};
          setDashboard(dashboardData);
        } else {
          setDashboard({ yourDataProperty: 0 });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setTransactionLoaderState(false);
      }
    };

    getEmployeegenerateddashboard();
  }, [selectedMonth]);
  const formatDateWithoutYear = (date) => {
    return new Date(date)?.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
    });
  };
  return (
    <>
      <div style={{ background: "#fff" }}>
        <h5 className="px-md-4  pt-4">Welcome Admin Dashboard!</h5>
        <div className="tagline-marquee">
          <p>Your Tagline Goes Here — Bringing You the Best Campaigns!</p>
        </div>{" "}
        <div className="tagline-marquee mt-1">
          <p>Your Tagline Goes Here — Bringing You the Best Campaigns!</p>
        </div>
        <div className="row p-md-3 p-2">
          <div className="col">
            <div
              className="card m-0 p-md-2 p-2"
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="row m-0 p-0">
                <div className="col d-flex align-items-center">
                  {" "}
                  <FaProjectDiagram
                    style={{ fontSize: "1.5rem", color: "#7EA1FF" }}
                  />
                </div>
                <div className="col">
                  <h6 className="m-0 p-0">Projects</h6>
                  <h5 className="m-0 p-0">
                    {projectcount?.length > 0
                      ? projectcount[0]?.project_count
                      : "Loading..."}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card m-0 p-md-2 p-2"
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="row m-0 p-0">
                <div className="col d-flex align-items-center">
                  <IoIosPersonAdd
                    style={{ fontSize: "1.5rem", color: "#7EA1FF" }}
                  />
                </div>
                <div className="col">
                  <h6 className="m-0 p-0">Intern</h6>
                  <h5 className="m-0 p-0">{interns?.length}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card m-0 p-md-2 p-2"
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="row m-0 p-0">
                <div className="col d-flex align-items-center">
                  <MdGroup style={{ fontSize: "1.5rem", color: "#7EA1FF" }} />
                </div>
                <div className="col">
                  <h6 className="m-0 p-0">Employee</h6>
                  <h5 className="m-0 p-0">{employees?.length}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card m-0 p-md-2 p-2"
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="row m-0 p-0">
                <div className="col d-flex align-items-center">
                  <FaTasks style={{ fontSize: "1.5rem", color: "#7EA1FF" }} />
                </div>
                <div className="col">
                  <h6 className="m-0 p-0">Department</h6>
                  <h5 className="m-0 p-0">{Department?.length}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-md-0 pt-2 px-md-3 p-2">
          <div className="col-md-3 col-6">
            <div
              className="card m-0 p-2 text-center"
              style={{
                border: "1px dotted grey",
                height: "55vh",
                overflow: "hidden",
              }}
            >
              <h6 className="text-center">Expense Payout</h6>
              <div
                className="scrollable-content"
                style={{
                  height: "100%",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "transparent transparent",
                }}
              >
                <div className="row p-0 mb-3">
                  <h6 className="col">{selectedMonth}</h6>
                  <div className="col">
                    <select
                      style={{
                        width: "5rem",
                        height: "1.5rem",
                        borderRadius: ".4rem",
                        border: "1px #ced4da solid",
                      }}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {months.map((month) => (
                        <option key={month?.value} value={month?.value}>
                          {month?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
                </div>
                <div>Total Generated</div>
                <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {parseFloat(dashboard?.total_generated) || 0}
                </span>
                <hr className="m-1" />
                <div>
                  <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
                </div>
                <div>Total Processed</div>
                <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {parseFloat(dashboard?.processed) || 0}
                </span>
                <hr className="m-1" />
                <div>
                  <FaMoneyBill style={{ color: "teal", fontSize: "1.5rem" }} />
                </div>
                <div>Total Unprocessed</div>
                <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {parseFloat(dashboard?.unprocessed) || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 d-flex flex-column align-items-center">
            <div className="m-0 p-2 mb-1">
              <h5 className="text-center">Employees</h5>
            </div>
            <div className="d-flex justify-content-center">
              <div style={{ width: "200px", height: "200px" }}>
                <Pie data={chartData} options={options} />
              </div>
            </div>
          </div>

          <div className="col-md-6 pt-md-0 pt-3 col">
            <div
              className="card m-0 p-0  d-flex flex-column"
              style={{ border: "1px dotted grey", height: "50vh" }}
            >
              <h6 className="text-center pt-1">Salary Payout</h6>
              <div style={{ flex: 1, position: "relative" }}>
                {BarData &&
                BarData.datasets &&
                BarData.datasets.length > 0 &&
                BarData.datasets[0].data.length > 0 ? (
                  <Bar data={BarData} options={barOptions} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    No data found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row px-md-3 p-2">
          <div className="col-md-3 col-6">
            <div className="card m-0 p-2" style={{ background: "#fff" }}>
              <div className="row m-0 p-0">
                <div className="col">
                  <img
                    alt=""
                    style={{
                      height: "3rem",
                    }}
                    src={require("../../../assets/images/birth5.gif")}
                  />{" "}
                </div>
                <h6 className="col-8 ">
                  {" "}
                  <img
                    alt=""
                    style={{
                      height: "8vh",
                    }}
                    src={require("../../../assets/images/birth4.png")}
                  />{" "}
                </h6>
              </div>
              <div
                className="card m-0 p-1"
                style={{ background: "#FFFDCB", border: "1px solid #FFC700" }}
              >
                <p
                  className="m-0 p-0 d-flex justify-content-center"
                  style={{ color: "#124076" }}
                >
                  Take a minute and congratulate them on their special day!
                  <img
                    alt=""
                    style={{
                      height: "1rem",
                    }}
                    src={require("../../../assets/images/birth1.png")}
                  />{" "}
                </p>
              </div>
              <div>
                {filteredBirthdays.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#673F69",
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                  >
                    No data found
                  </div>
                ) : (
                  filteredBirthdays.map((birth, index) => (
                    <div key={index}>
                      <div className="row">
                        <div
                          className="col-5 d-flex justify-content-center"
                          style={{
                            color: "#FBA834",
                            fontSize: "1rem",
                            fontWeight: "600",
                          }}
                        >
                          {birth?.firstname}
                        </div>
                        <div
                          className="col-5 d-flex justify-content-center"
                          style={{
                            color: "#673F69",
                            fontSize: ".9rem",
                            fontWeight: "600",
                          }}
                        >
                          <span>{formatDateWithoutYear(birth?.dob)}</span>
                        </div>
                        <div className="col-2 m-0 p-0 d-flex justify-content-center">
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                              marginRight: "5px",
                            }}
                            className="adminemp-tooltip-container"
                          >
                            <a
                              href={`mailto:${birth.work_email}?subject=Warm%20Birthday%20Wishes%20to%20${birth?.firstname}&body=Dear%20${birth?.firstname},%0D%0A%0D%0AHappy%20birthday!%20May%20your%20special%20day%20be%20filled%20with%20joy%20and%20happiness.%20Wishing%20you%20all%20the%20best%20today%20and%20always.%0D%0A%0D%0AWarm%20regards,%0D%0A[Your%20Name]%0D%0A[Your%20Position]%0D%0A[Company%20Name]`}
                              className=""
                            >
                              <IoMdMail style={{ color: "red" }} />
                            </a>
                            <span
                              className="adminemp-tooltip-text p-1"
                              style={{ fontSize: ".8rem" }}
                            >
                              {birth?.work_email}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-md-0 pt-3 col">
            <div className="card m-0 p-2">
              {" "}
              <ViewProjectadmindash />
            </div>
          </div>
          <div className="col-md-3 col-8" style={{ background: "#fff" }}>
            <div className="card p-2">
              <h5 className="text-center"> Leaves</h5>
              {absent && absent.length > 0 ? (
                absent.slice(0, 3).map((leave, index) => (
                  <div
                    key={index}
                    style={{ filter: index === 4 ? "blur(2px)" : "none" }}
                  >
                    <div className="row">
                      <div className="col-2 m-0 p-1">
                        <FaUserCircle
                          style={{ fontSize: "2rem", color: "#41B06E" }}
                        />
                      </div>
                      <div
                        className="col"
                        style={{ fontSize: ".8rem", fontWeight: "600" }}
                      >
                        {leave?.employee_name}
                      </div>
                      <div
                        className="col"
                        style={{ filter: index === 4 ? "blur(2px)" : "none" }}
                      >
                        {leave?.status === "R" && (
                          <div
                            className=" p-1 "
                            style={{
                              backgroundColor: "rgba(255, 46, 46, 0.1)",
                              color: "#ff2e2e",
                              border: "1px solid #ff2e2e",
                              borderRadius: ".3rem",
                            }}
                          >
                            Rejected
                          </div>
                        )}
                        {leave?.status === "P" && (
                          <span
                            className=" p-1 "
                            style={{
                              backgroundColor: "rgba(255, 255, 0, 0.1)",
                              color: " #f7b12a",
                              border: "1px solid #f7b12a",
                              borderRadius: ".3rem",
                            }}
                          >
                            Pending
                          </span>
                        )}
                        {leave?.status === "A" && (
                          <span
                            className=" p-1 "
                            style={{
                              backgroundColor: "rgba(43, 193, 85, 0.1)",
                              color: "#2bc155",
                              border: "1px solid #2bc155",
                              borderRadius: ".3rem",
                            }}
                          >
                            Approved
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: "#673F69",
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >
                  No data found
                </div>
              )}
              <div className="d-flex justify-content-center">
                <Link to="/retailer/LeaveApprovalAdmin">
                  <FcPrevious />
                  View More
                  <FcNext />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default AdminDashboard;
