import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import { COMPANY_NAME_NAVBAR } from "../utils/StringConstants";
import { BASE_URL_API_TRANXT_API, BASE_URL_PAYNOW } from "../utils/Constants";
import { basicAuth } from "../config";
import swal from "sweetalert";
import { GlobalContext } from "../Context/GlobalState";
import { ContextMenuTrigger } from "react-contextmenu";
import { validatePanCard } from "../utils/utilFuctions";
import { Stepper as Stepper2 } from "./Stepper";

const DocumentDetails = () => {
  const { username, password } = basicAuth;
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const [formValues, setFormValues] = useState({
    PAN: "",
    ADHAR: "",
    GST: "",
    OTP: "",
  });
  const [verification, setVerification] = useState({
    PAN: false,
    ADHAR: false,
    panDetails: null,
    adhaarDetails: null,
    otpSent: false,
    aadhaar_request_id: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const steps = localStorage.getItem("steps");
  const kyc_by = sessionStorage.getItem("kyc_by")
  const fetchUserKycData = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          if (kyc_by && kyc_by == "admin") {
          } else {
            history.push("/kyc/user-kyc-view");
          }
        }
        setKycData(data.data[0]);
        setFormValues({
          ...formValues,
          PAN: data.data[0].pancardno,
          ADHAR: data.data[0].aadharcardno,
          GST: data.data[0].gstnumber,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const insertVerifiedKycData = async (
    pan_verified,
    aadhaar_verified,
    pan_verified_name,
    aadhaar_verified_dob,
    aadhaar_verified_address,
    shop_and_home_address_check
  ) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
        pan_verified,
        aadhaar_verified,
        pan_verified_name,
        aadhaar_verified_dob,
        aadhaar_verified_address,
        shop_and_home_address_check,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/insert_verified_kyc_data.aspx`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  const verificationData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setVerifiedKycData(data.data);
        setVerification({
          ...verification,
          PAN: data.data.pan_verified == "Y",
          panDetails: data.data.pan_verified_name,
          ADHAR: data.data.aadhaar_verified == "Y",
          adhaarDetails: data.data,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const panVerification = async () => {
    if (formValues.PAN.length !== 10 || !validatePanCard(formValues.PAN)) {
      setFormErrors({ ...formErrors, PAN: "please enter valid PAN Number" });
      return;
    }
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0Yaww-WYCOCvJffuiZpPitu_WiQA91ynv8SWBcvw62PODh9iQzXLtlFCOo8mOqIJX6Q705C-IdJyxyoQ_rP9RGjHOn-x7kWGvf_K9dKW9nJuCc-_7hgUrb0JFxKGTUfoahed7EjRN3ao-NP30pRPDIpjMuZV6z-OnzJXDXTzVJGDzriVmFjhvUUiun3QtWGLPp0dTn3FnntGxLgvVyYttkZ02mmswBa5jZdO5J8nNnNtRu1P5kQ5yy6FtH5GSRI4636WrIRXCunIJIHO1t460LCL7cLMUE5Zeh4CAOhyFtlwShOYGcOX0tcGKJkHPtSFiyvyLxE0DxWYHdbDMM2dL14zsrW4q6bF8-vBOWDLsk08DqyjSIk-xho8ckrJapc7AxXnBnr6h4w5C5DdBkpT5nDPkLKONOikYNoxyFHeOemS4kWF-ftawBtPquDL6Rn-PI6glkbJWBA527ph1xAotqFGOY9xO0y1qKL_UrZgFswOpuXf2e6oThqL9U77B1Kh6Cmh-Jp-ZYIMEvd6Z3tKoqy1NMb6pAI2nMWldcw6wz3dOu0l8b60fxuIz5yViOLD6ug_yVdbz0ZgCwuYdVdilG_ssDWurFzyBpWqH-kRukFrCnm7HyttZLFKN5TrFRz5VVgq0vzwAjd6FlOjsv3ol5"
      );

      var raw = JSON.stringify({
        verifydatavalue: formValues.PAN,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/panlite`,
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100") {
          setVerification({
            ...verification,
            PAN: true,
            panDetails: data.result,
          });
          insertVerifiedKycData(
            "Y",
            verifiedKycData.aadhaar_verified,
            data.result.user_full_name,
            verifiedKycData.aadhaar_verified_dob,
            verifiedKycData.aadhaar_verified_address,
            verifiedKycData.shop_and_home_address_check
          );
        } else {
          setVerification({
            ...verification,
            PAN: false,
            panDetails: data.response_message,
          });
          setFormErrors({ ...formErrors, PAN: data.response_message });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aadhaarVerificationSendOtp = async () => {
    if (formValues.ADHAR.length !== 12) {
      setFormErrors({
        ...formErrors,
        ADHAR: "please enter valid Aadhaar Number",
      });
      return;
    }
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
      );

      var raw = JSON.stringify({
        verifydatavalue: formValues.ADHAR,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/aadharotpsend`,
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100" && data.result) {
          setVerification({
            ...verification,
            otpSent: data.result.is_otp_sent,
            aadhaar_request_id: data.request_id,
          });
        } else {
          setVerification({
            ...verification,
            ADHAR: false,
            adhaarDetails: data.response_message,
          });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aadhaarVerification = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        request_id: verification.aadhaar_request_id,
        otp: formValues.OTP,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Verify/aadharotpverify",
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100") {
          setVerification({
            ...verification,
            ADHAR: true,
            adhaarDetails: data.result,
          });
          insertVerifiedKycData(
            verifiedKycData.pan_verified,
            "Y",
            verifiedKycData.pan_verified_name,
            verifiedKycData.data.result.user_dob,
            JSON.stringify(data.result.user_address),
            verifiedKycData.shop_and_home_address_check
          );
        } else {
          setVerification({
            ...verification,
            PAN: false,
            panDetails: data.response_message,
          });
          setFormErrors({ ...formErrors, PAN: data.response_message });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const redirect = () => {
    history.push("/kyc/document-upload");
  };

  const Back = () => {
    history.push("/kyc/personal-details");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Document();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.PAN) {
      errors.PAN = "*Pan number is required!";
    } else if (values.PAN.length != 10) {
      errors.PAN = "*Enter valid pan number";
    }
    if (!values.ADHAR) {
      errors.ADHAR = "*Adhar number is required!";
    } else if (values.ADHAR.length != 12) {
      errors.ADHAR = "*Enter valid Adhar number";
    }

    return errors;
  };

  const Document = () => {
    if (verification.PAN) {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        steps: "3",
        fkuserid: userId.pk_user_id,
        pancardno: formValues.PAN,
        aadharcardno: formValues.ADHAR,
        gstnumber: formValues.GST,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        fetch(
          `${BASE_URL_API_TRANXT_API}/api/kyc/submitkycdoc.aspx`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status == 0) {
              redirect();
              localStorage.setItem("steps", 3);
            }
            setTransactionLoaderState(false);
          })
          .catch((err) => {
            setTransactionLoaderState(false);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      swal("Warning", "Verify your Pan Card", "warning");
    }
  };

  useEffect(() => {
    verificationData();
    fetchUserKycData();
  }, []);

  return (
    <div className="p-3" style={{ fontFamily: "auto" }}>
      <div
        className="card"
        style={{ backgroundColor: "aliceblue", textAlign: "center" }}
      >
        {/* <Stepper activeStep={steps} style={{ padding: "15px" }}>
          <Step label="Business Details" />
          <Step label="Personal Details" />
          <Step label="Document Details" />
          <Step label="Document Upload" />
          <Step label="Biometric KYC" />
        </Stepper> */}
        <Stepper2 step={3} />
        <div
          className="card-body m-3 mt-0 pt-0"
          style={{
            borderRadius: "1rem",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        >
          <Row>
            <small>
              <p className="mb-2" style={{ color: "#5bdb5b" }}>
                Personal details inserted successfully
              </p>
            </small>
            <Col md={12} lg={12} sm={12}>
              <h4>
                <b>Document Details</b>
              </h4>
            </Col>
          </Row>

          <Row>
            <Col md={4} lg={4} sm={12}>
              <Form.Group>
                <Form.Label>
                  <b>Pan Card Number: </b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your pan here"
                  name="PAN"
                  value={formValues.PAN}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      PAN: e.target.value.toUpperCase(),
                    });
                  }}
                  style={{ textTransform: "uppercase" }}
                />
                <p style={{ color: "red" }}>{formErrors.PAN}</p>
              </Form.Group>
            </Col>
            <Col
              md={4}
              lg={4}
              sm={12}
              className="row justify-content-start align-items-center"
            >
              {verification.PAN ? (
                <label
                  style={{
                    padding: "4px",
                    //  borderRadius: "4px",
                    minWidth: "4rem",
                  }}
                  className="badge"
                >
                  <img
                    src={require("../../assets/images/success_tick.jpg")}
                    style={{
                      height: "1rem",
                      width: "auto",
                      borderRadius: "1rem",
                      marginRight: ".2rem",
                    }}
                  />
                  {"Verified"}
                </label>
              ) : (
                <Button className="mt-3" onClick={panVerification}>
                  Verify
                </Button>
              )}
            </Col>
          </Row>
          {verification.PAN && verification.panDetails.pan_number && (
            <div className="card-inverse-success">
              <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body" style={{ padding: "10px" }}>
                  <p className="card-text">{`PAN Number : ${verification.panDetails.pan_number}`}</p>
                  <p className="card-text">{`PAN Status : ${verification.panDetails.pan_status}`}</p>
                  <p className="card-text">{`Full Name : ${verification.panDetails.user_full_name}`}</p>
                  <p className="card-text">{`PAN Type : ${verification.panDetails.pan_type}`}</p>
                </div>
              </ContextMenuTrigger>
            </div>
          )}

          <Row>
            <Col md={4} lg={4} sm={12}>
              <Form.Label>
                <b>Adhar Card Number: </b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your adhar here"
                name="ADHAR"
                value={formValues.ADHAR}
                onChange={handleChange}
              />
              {formErrors.ADHAR && (
                <p style={{ color: "red" }}>{formErrors.ADHAR}</p>
              )}
            </Col>

            {verification.otpSent && !verification.ADHAR && (
              <Col
                md={4}
                lg={4}
                sm={12}
                className="row justify-content-start align-items-center"
              >
                <div style={{ flex: "1" }}>
                  <Form.Group>
                    <Form.Label>
                      <b>OTP: </b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      name="OTP"
                      value={formValues.OTP}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.OTP}</p>
                  </Form.Group>
                </div>
                <Button className="m-0 mt-2 ml-4" onClick={aadhaarVerification}>
                  {" "}
                  Submit OTP
                </Button>
              </Col>
            )}
            <Col
              md={4}
              lg={4}
              sm={12}
              className="row justify-content-start align-items-center mt-4 px-4 py-0"
            >
              {verification.ADHAR ? (
                <label
                  style={{
                    padding: "4px",
                    //  borderRadius: "4px",
                    minWidth: "4rem",
                  }}
                  className="badge"
                >
                  <img
                    src={require("../../assets/images/success_tick.jpg")}
                    style={{
                      height: "1rem",
                      width: "auto",
                      borderRadius: "1rem",
                      marginRight: ".2rem",
                    }}
                  />
                  {"Verified"}
                </label>
              ) : !verification.otpSent ? (
                <Button className="" onClick={aadhaarVerificationSendOtp}>
                  Send OTP
                </Button>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col md={4} lg={4} sm={12}>
              <Form.Label>
                <b>GST Number(Optional): </b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your GST Number here"
                name="GST"
                value={formValues.GST}
                onChange={handleChange}
              />
            </Col>
            <Col md={12} lg={12} sm={12}>
              <small>
                <p className="mt-2" style={{ color: "red" }}>
                  All Fields(*) marked are mandatory
                </p>
              </small>
            </Col>
          </Row>
        
        
          <Row>
            <Col md={4} lg={4} sm={12} className="mt-4">
              <Form.Group>
                <Button className="btn-block" variant="info" onClick={Back}>
                  Back
                </Button>
              </Form.Group>
            </Col>

            <Col md={4} lg={4} sm={12} className="mt-4">
              <Form.Group>
                <Button
                  className="btn-block"
                  variant="success"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetails;
