import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { validateTPin } from "../../utils/Validations";
import { failedPopup, successPopup } from "../../utils/Models";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DashSidebar = ({ details }) => {
  const [type, setType] = useState("KYC");
  const { user } = useContext(GlobalContext);

  useEffect(() => {
    setType(details);
  }, [details]);
  console.log(details);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : details.type == "ADD" ? (
          <ADD details={details.user} />
        ) : (
          <REVERSE details={details.user} />
        )
      }
    </GlobalContext.Consumer>
  );
};

const ADD = ({ details }) => {
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } =
    useContext(GlobalContext);
  const InitialValues = { Ptype: "", Amount: "", Tpin: "" };

  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [comment, setComment] = useState("");
  const [Addbalance, setAddBalance] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      AddBalance();
    }
  }, [formErrors]);

  const clearForm = () => {
    setFormValues(InitialValues);
    setComment("");
  };

  const tpinValidation = async (e) => {
    validateTPin(
      Profileinfo.mobile,
      formValues.Tpin,
      () => {
        handleSubmit(e);
      },
      () => {
        // setPayClicked(false);
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const AddBalance = () => {
    var raw = JSON.stringify({
      paytype: formValues.Ptype,
      retpkid: details.pk_user_id,
      distripkid: user.pk_user_id,
      amount: formValues.Amount,
      comment: comment,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_add_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setAddBalance(result.data);
            cogoToast.success(result.msg);
            refreshWallet();
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
          } else {
            cogoToast.error(result.msg);
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Ptype) {
      errors.Ptype = "*Payment type is required!";
    }
    if (!values.Amount) {
      errors.Amount = "*Amount is required!";
    } else if (values.Amount === NaN) {
      errors.Amount = "Enter valid Amount";
    }
    return errors;
  };

  if (details) {
    return (
      <nav
        className="side-sheet sidebar Sidebar23"
        id="sidebar2"
        style={Sidebar22}
      >
        <div
          style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
          className="row"
        >
          <div
            style={{ padding: "6px", display: "flex", alignItems: "center" }}
            className="col"
          >
            <strong style={{ color: "#1b68ca" }}>Amount(ADD)</strong>
          </div>
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <Card style={{ margin: "10px", padding: "10px" }}>
          <Form.Group style={{ flex: "1" }}>
            <Form.Label>Payment Type:</Form.Label>
            <Form.Control
              as="select"
              name="Ptype"
              value={formValues.Ptype}
              onChange={handleChange}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Advance Payment`}
              style={{ borderRadius: "0px" }}
            >
              <option selected>Payment type</option>
              <option value={1}>IMPS</option>
              <option value={2}>NEFT</option>
            </Form.Control>
            <p style={{ color: "red" }}>{formErrors.Ptype}</p>
            <br></br>
            <Form.Label>Distributor ID:</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              value={details.us_code}
              style={{ cursor: "not-allowed", borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>User/Shop Name:</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              value={details.UserData}
              style={{ cursor: "not-allowed", borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Old Balance:</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              value={details.userbalance}
              style={{ cursor: "not-allowed", borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Add Balance:</Form.Label>
            <Form.Control
              name="Amount"
              value={formValues.Amount}
              onChange={handleChange}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter New Amount`}
              style={{ borderRadius: "0px" }}
            />
            <p style={{ color: "red" }}>{formErrors.Amount}</p>
            <br></br>
            <Form.Label>Remarks:</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setComment(e.target.value);
              }}
              style={{ borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Enter Pin:</Form.Label>
            <Form.Control
              name="Tpin"
              value={formValues.Tpin}
              type="password"
              autocomplete="new-password"
              autoCorrect="off"
              autoSave="new-password"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter T Pin`}
              onChange={handleChange}
              style={{ borderRadius: "0px" }}
            />
            <p style={{ color: "red" }}>{formErrors.Tpin}</p>
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="rounded-0"
              style={{
                margin: "0px 10px",
                minWidth: "11rem",
                borderRadius: "0px",
              }}
              variant="success"
              onClick={tpinValidation}
            >
              {"Submit"}
            </Button>
          </div>
        </Card>
      </nav>
    );
  }
  return null;
};

const REVERSE = ({ details }) => {
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } =
    useContext(GlobalContext);
  const InitialValues = { Amount: "", Tpin: "" };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [comment, setComment] = useState("");
  const [Revbalance, setRevBalance] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      RevBalance();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.Tpin) {
      errors.Tpin = "*pin is required!";
    } else if (values.Tpin === NaN) {
      errors.Tpin = "Enter valid pin";
    }
    if (!values.Amount) {
      errors.Amount = "*Amount is required!";
    } else if (values.Amount === NaN) {
      errors.Amount = "Enter valid Amount";
    }
    return errors;
  };

  const clearForm = () => {
    setFormValues(InitialValues);
    setComment("");
  };

  const tpinValidation = async (e) => {
    validateTPin(
      Profileinfo.mobile,
      formValues.Tpin,
      () => {
        handleSubmit(e);
      },
      () => {
        // setPayClicked(false);
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const RevBalance = () => {
    var raw = JSON.stringify({
      Tpin: formValues.Tpin,
      retpkid: details.pk_user_id,
      distripkid: user.pk_user_id,
      amount: formValues.Amount,
      comment: comment,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_reverse_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setRevBalance(result);
            cogoToast.success(result.msg);
            refreshWallet();
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
            successPopup("Successfull Transaction", result.msg);
          } else {
            cogoToast.error(result.msg);
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
            failedPopup("Failed Transaction", result.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  if (details) {
    return (
      <nav
        className="side-sheet sidebar Sidebar23"
        id="sidebar2"
        style={Sidebar22}
      >
        <div
          style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
          className="row"
        >
          <div
            style={{ padding: "6px", display: "flex", alignItems: "center" }}
            className="col"
          >
            <strong style={{ color: "#1b68ca" }}>Amount(REVERSE)</strong>
          </div>
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <Card style={{ margin: "10px", padding: "10px" }}>
          <Form.Group style={{ flex: "1" }}>
            <Form.Label>Distributor ID:</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              value={details.us_code}
              style={{ borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Old Balance:</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              value={details.userbalance}
              style={{ borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Reverse Amount:</Form.Label>
            <Form.Control
              name="Amount"
              value={formValues.Amount}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter New Amount`}
              onChange={handleChange}
              style={{ borderRadius: "0px" }}
            />
            <p style={{ color: "red" }}>{formErrors.Amount}</p>
            <br></br>
            <Form.Label>Remarks:</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter Remarks`}
              onChange={(e) => setComment(e.target.value)}
              style={{ borderRadius: "0px" }}
            />
            <br></br>
            <Form.Label>Enter Pin:</Form.Label>
            <Form.Control
              name="Tpin"
              value={formValues.Tpin}
              type="password"
              autocomplete="new-password"
              autoCorrect="off"
              autoSave="new-password"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter T Pin`}
              onChange={handleChange}
              style={{ borderRadius: "0px" }}
            />
            <p style={{ color: "red" }}>{formErrors.Tpin}</p>
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="rounded-0"
              style={{
                margin: "0px 10px",
                minWidth: "11rem",
                backgroundColor: "#e9573f",
                borderRadius: "0px",
              }}
              onClick={tpinValidation}
            >
              {"Less"}
            </Button>
          </div>
        </Card>
      </nav>
    );
  } else return null;
};
export default withRouter(DashSidebar);
