import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { Button, Modal, FormControl, Badge } from "react-bootstrap";
import swal from "sweetalert";
import { AddSalaryAdmin, AddSalaryHR } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";

const Salarydatahr = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [salaryData, setSalaryData] = useState(null);
  const [active, setactive] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [formData, setFormData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    totalSalary: "",
  });
  const [upgradeData, setupgradeData] = useState({
    basicPay: "",
    hra: "",
    travelAllowance: "",
    medicalAllowance: "",
    otherAllowance: "",
    totalSalary: "",
    esic: "",
    tds: "",
    date: "",
    pf: "",
  });
  const { password , username } = billpunchbasicauth;
 
  const empId = sessionStorage.getItem("currentEmpId");
  const [AddSalaryModalOpen, setAddSalaryModalOpen] = useState(false);
  const getSalaryDetails = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var raw = JSON.stringify({
        empid: empId,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getSalaryDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const activeSalary = data.data.find((item) => item.active === "Y");
        if (activeSalary) {
          setFormData({
            basicPay: activeSalary.basicpay,
            hra: activeSalary.hra,
            travelAllowance: activeSalary.travel_allowance,
            medicalAllowance: activeSalary.medical_allowance,
            otherAllowance: activeSalary.other_allowance,
            totalSalary: activeSalary.totalsalary,
          });
          setupgradeData({
            basicPay: activeSalary.basicpay,
            hra: activeSalary.hra,
            travelAllowance: activeSalary.travel_allowance,
            medicalAllowance: activeSalary.medical_allowance,
            otherAllowance: activeSalary.other_allowance,
            totalSalary: activeSalary.totalsalary,
          });
          setPkSId(activeSalary.pk_s_id);
          console.log(setPkSId, "kl");
        } else {
          console.log("No active salary found");
        }
        setSalaryData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };
  const openAddSalaryModal = () => {
    setAddSalaryModalOpen(true);
  };
  const closeAddSalaryModal = () => {
    setAddSalaryModalOpen(false);
  };
  const handleUpdateModalOpen = () => {
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpgradeModalOpen = () => {
    setShowUpgradeModal(true);
  };
  const handleUpgradeModalClose = () => {
    setShowUpgradeModal(false);
  };
  useEffect(() => {
    const parsedBasicPay = parseFloat(formData.basicPay) || 0;
    const parsedHRA = parseFloat(formData.hra) || 0;
    const parsedTravelAllowance = parseFloat(formData.travelAllowance) || 0;
    const parsedMedicalAllowance = parseFloat(formData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(formData.otherAllowance) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance;
    setFormData((prevData) => ({
      ...prevData,
      totalSalary: totalSalary.toString(),
    }));
  }, [
    formData.basicPay,
    formData.hra,
    formData.travelAllowance,
    formData.medicalAllowance,
    formData.otherAllowance,
  ]);
  useEffect(() => {
    const parsedBasicPay = parseFloat(upgradeData.basicPay) || 0;
    const parsedHRA = parseFloat(upgradeData.hra) || 0;
    const parsedTravelAllowance = parseFloat(upgradeData.travelAllowance) || 0;
    const parsedMedicalAllowance =
      parseFloat(upgradeData.medicalAllowance) || 0;
    const parsedotherAllowance = parseFloat(upgradeData.otherAllowance) || 0;
    const parsedesic = parseFloat(upgradeData.esic) || 0;
    const parsedtds = parseFloat(upgradeData.tds) || 0;

    const totalSalary =
      parsedBasicPay +
      parsedHRA +
      parsedTravelAllowance +
      parsedMedicalAllowance +
      parsedotherAllowance +
      parsedesic +
      parsedtds;
    setupgradeData((prevData) => ({
      ...prevData,
      totalSalary: totalSalary.toString(),
    }));
  }, [
    upgradeData.basicPay,
    upgradeData.hra,
    upgradeData.travelAllowance,
    upgradeData.medicalAllowance,
    upgradeData.otherAllowance,
    upgradeData.esic,
    upgradeData.tds,
  ]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleInputupgradeChange = (e) => {
    const { name, value } = e.target;
    setupgradeData({
      ...upgradeData,
      [name]: value,
    });
  };
  const Activeinactive = async (pk_s_id, newStatus) => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        s_id: pk_s_id,
        empid: empId,
        status: newStatus,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/ActivateSalaryEmployee`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setactive();
        getSalaryDetails();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  const [pkSId, setPkSId] = useState("");
  const handleSubmit = async () => {
    setTransactionLoaderState(true);
    try {
      const requestBody = {
        pk_s_id: pkSId,
        fk_emp_id: empId,
        basicpay: formData.basicPay,
        hra: formData.hra,
        travel_allowance: formData.travelAllowance,
        medical_allowance: formData.medicalAllowance,
        other_allowance: formData.otherAllowance,
        totalsalary: formData.totalSalary,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/updateSalaryDetails`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        handleUpdateModalClose();
        getSalaryDetails();
      } else {
        swal("Error", data.result, "error");
      }
      getSalaryDetails();
      handleUpdateModalClose();
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const handleSubmitupgrade = async () => {
    setTransactionLoaderState(true);
    try {
      const date = new Date(upgradeData.date);
      const effectiveMonth =
        date instanceof Date && !isNaN(date)
          ? `${date.toLocaleString("default", {
              month: "long",
            })} ${date.getFullYear()}`
          : "";

      const requestBody = {
        fk_emp_id: empId,
        basicpay: upgradeData.basicPay,
        hra: upgradeData.hra,
        travel_allowance: upgradeData.travelAllowance,
        medical_allowance: upgradeData.medicalAllowance,
        pf: upgradeData.pf,
        other_allowance: upgradeData.otherAllowance,
        esic: upgradeData.esic,
        tds: upgradeData.tds,
        totalsalary: upgradeData.totalSalary,
        effective_month: effectiveMonth,
        effective_date_time: upgradeData.date,
      };

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
     
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/UpgradeSalaryEmployees`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        handleUpgradeModalClose();
        getSalaryDetails();
      } else {
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "Failed to update salary", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getSalaryDetails();
    Activeinactive();
  }, []);
  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);
  return (
    <div className="p-2">
      <AddSalaryHR
        show={AddSalaryModalOpen}
        onHide={closeAddSalaryModal}
        // getEmployeeList={getEmployeeList}
      />
      <div className="col d-flex justify-content-end">
        <Link to="/retailer/SalaryManagement">Back</Link>
      </div>
      <div className="row">
        <h5 className="col-8 " >Salary Management</h5>
        <div className="col" >
  <div className="row" style={{justifyContent:"space-around"}}>
    <div className="">
      {accessData &&
        accessData.map((module) =>
          module.data &&
          module.data.map((role) =>
            role.role_name === "Add Salary" ? (
              role.access === "Y" ? (
                <span key={role.pk_role_id} >
                  <Link
                    type="button"
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "7rem",
                      fontSize: ".8rem",
                    }}
                    onClick={openAddSalaryModal}
                  >
                    Add Salary
                  </Link>
                </span>
              ) : (
                <span key={role.pk_role_id} style={{ marginLeft: "10px" }}>
                  <button
                    className="btn btn-sm btn-primary d-flex justify-content-center"
                    style={{
                      color: "#fff",
                      height: "2rem",
                      border: "none",
                      borderRadius: ".4rem",
                      display: "flex",
                      alignItems: "center",
                      width: "7rem",
                      fontSize: ".8rem",
                      backgroundColor: "gray",
                      cursor: "not-allowed",
                    }}
                    disabled
                  >
                    Add Salary
                  </button>
                </span>
              )
            ) : null
          )
        )}
    </div>
      <Button className="me-2"
        style={{
          color: "#fff",
          height: "2rem",
          border: "none",
          borderRadius: ".4rem",
          display: "flex",
          alignItems: "center",
          fontSize: ".8rem",
        }}
        onClick={handleUpdateModalOpen}
      >
        Update salary
      </Button>
    <Button
        style={{
          color: "#fff",
          height: "2rem",
          border: "none",
          borderRadius: ".4rem",
          display: "flex",
          alignItems: "center",
          fontSize: ".8rem",
        }}
        onClick={handleUpgradeModalOpen}
      >
        Upgrade salary
      </Button>
  </div>
</div>

      </div>

      <table className="table">
        <thead>
          <tr>
            <th className="text-center">Basic Pay</th>
            <th className="text-center">HRA</th>
            <th className="text-center">Travel </th>
            <th className="text-center">Medical </th>
            <th className="text-center">Other </th>
            <th className="text-center">Total </th>
            <th className="text-center">Month </th>
            <th className="text-center">Status </th>
            <th className="text-center">Action </th>
          </tr>
        </thead>
        <tbody>
          {salaryData &&
            salaryData.map((salaryItem, index) => (
              <tr key={index}>
                <td className="text-center">{salaryItem.basicpay}</td>
                <td className="text-center">{salaryItem.hra}</td>
                <td className="text-center">{salaryItem.travel_allowance}</td>
                <td className="text-center">{salaryItem.medical_allowance}</td>
                <td className="text-center">{salaryItem.other_allowance}</td>
                <td className="text-center">{salaryItem.totalsalary}</td>
                <td className="text-center">
                  {salaryItem.effective_date_time}
                  <br />
                  {salaryItem.effective_month}
                </td>
                <td className="text-center">
                  {salaryItem.active === "Y" ? (
                    <Badge className="btn btn-sm btn-success">Activated</Badge>
                  ) : (
                    <Badge className="btn btn-sm btn-danger">Inactivated</Badge>
                  )}
                </td>
                <td className="text-center">
                  {salaryItem.active === "Y" ? (
                    <button
                      onClick={() => Activeinactive(salaryItem.pk_s_id, "N")}
                      className="btn btn-sm btn-danger"
                    >
                      Inactive
                    </button>
                  ) : (
                    <button
                      onClick={() => Activeinactive(salaryItem.pk_s_id, "Y")}
                      className="btn btn-sm btn-success"
                    >
                      Active
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal show={showUpdateModal} onHide={handleUpdateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0 p-0">
            {" "}
            <div className="col form-group">
              <label htmlFor="totalSalary">Total Salary</label>
              <input
                readOnly
                type="text"
                className="form-control"
                id="totalSalary"
                name="totalSalary"
                value={formData.totalSalary}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row m-0 p-0">
            {" "}
            <div className="form-group col ">
              <label htmlFor="basicPay">Basic Pay</label>
              <input
                type="text"
                className="form-control"
                id="basicPay"
                name="basicPay"
                value={formData.basicPay}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col">
              <label htmlFor="hra">HRA</label>
              <input
                type="text"
                className="form-control"
                id="hra"
                name="hra"
                value={formData.hra}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col">
              <label htmlFor="travelAllowance">Travel Allowance</label>
              <input
                type="text"
                className="form-control"
                id="travelAllowance"
                name="travelAllowance"
                value={formData.travelAllowance}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col form-group">
              <label htmlFor="medicalAllowance">Medical Allowance</label>
              <input
                type="text"
                className="form-control"
                id="medicalAllowance"
                name="medicalAllowance"
                value={formData.medicalAllowance}
                onChange={handleInputChange}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="otherAllowance">Other Allowance</label>
              <input
                type="text"
                className="form-control"
                id="otherAllowance"
                name="otherAllowance"
                value={formData.otherAllowance}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpdateModalClose}>
            Close
          </Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUpgradeModal} onHide={handleUpgradeModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0 p-0">
            <div className="form-group col">
              <label htmlFor="totalSalary">Total Salary</label>
              <input
                readOnly
                type="text"
                className="form-control"
                id="totalSalary"
                name="totalSalary"
                value={upgradeData.totalSalary}
                onChange={handleInputupgradeChange}
              />
            </div>
          </div>

          <div className="row m-0 p-0">
            {" "}
            <div className="col form-group">
              <label htmlFor="basicPay">Basic Pay</label>
              <input
                type="text"
                className="form-control"
                id="basicPay"
                name="basicPay"
                value={upgradeData.basicPay}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="hra">HRA</label>
              <input
                type="text"
                className="form-control"
                id="hra"
                name="hra"
                value={upgradeData.hra}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className=" col form-group">
              <label htmlFor="travelAllowance">Travel Allowance</label>
              <input
                type="text"
                className="form-control"
                id="travelAllowance"
                name="travelAllowance"
                value={upgradeData.travelAllowance}
                onChange={handleInputupgradeChange}
              />
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col form-group">
              <label htmlFor="medicalAllowance">Medical Allowance</label>
              <input
                type="text"
                className="form-control"
                id="medicalAllowance"
                name="medicalAllowance"
                value={upgradeData.medicalAllowance}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="otherAllowance">Other Allowance</label>
              <input
                type="text"
                className="form-control"
                id="otherAllowance"
                name="otherAllowance"
                value={upgradeData.otherAllowance}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className=" col form-group">
              <label htmlFor="esic">ESIC</label>
              <input
                type="text"
                className="form-control"
                id="esic"
                name="esic"
                value={upgradeData.esic}
                onChange={handleInputupgradeChange}
              />
            </div>
          </div>
          <div className="row m-0 p-0">
            <div className="col form-group">
              <label htmlFor="tds">TDS</label>
              <input
                type="text"
                className="form-control"
                id="tds"
                name="tds"
                value={upgradeData.tds}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="tds">EPF</label>
              <input
                type="text"
                className="form-control"
                id="pf"
                name="pf"
                value={upgradeData.pf}
                onChange={handleInputupgradeChange}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={upgradeData.date}
                onChange={handleInputupgradeChange}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpgradeModalClose}>
            Close
          </Button>
          <Button onClick={handleSubmitupgrade}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Salarydatahr;
