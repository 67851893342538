import { Card } from "@material-ui/core";
import React from "react";

export const BillerResponse = ({ msg }) => {
  return (
    <div
      className="card-inverse-warning"
      style={{padding: "10px", display: "flex",alignItems:'center' }}
    >
      <img
        src={require("../../assets/icons/error_icon.jpg")}
        style={{ height: "1rem", width: "auto",marginRight:'1rem' }}
      />
      <span> {msg}</span>
    </div>
  );
};
