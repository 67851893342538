import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Col, Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import {
  BASE_URL_AEPS,
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import { basicAuth } from "../../config";
import {
  generateHashKey2,
  onlyAlpha,
  onlyNumeric,
  validateIFSCCode2,
} from "../../utils/utilFuctions";
import { validateTPin } from "../../utils/Validations";

const FundTransfer = () => {
  const style = {
    backgroundColor: "#002970",
    color: "white",
  };
  const { username, password } = basicAuth;

  const [UPIValuesErrors, setUPIValuesErrors] = useState({
    UPI: "",
    Amount: "",
    TPIN: "",
  });

  const [upiModalValues, setUpiModalValues] = useState({
    upiid: "",
    amount: "",
    tpin: "",
  });

  const [aepsTopupModalErrors, setAepsTopupModalErrors] = useState({
    tpin: "",
    upiid: "",
    amount: "",
    upiName: "",
    upiMobile: "",
  });
  const [aepsTopupModalValues, setAepsTopupModalValues] = useState({
    tpin: "",
    upiid: "",
    amount: "",
    upiName: "",
    upiMobile: "",
  });
  const [balanceToShow, setBalanceToShow] = useState("");

  const [BankList, setBankList] = useState([]);
  const [UserAcList, setUserAcList] = useState([]);
  const [BankObject, setBankObject] = useState("");
  const [mdShow, setMdShow] = useState(false);
  const [mdShow2, setMdShow2] = useState(false);
  const [mdShow3, setMdShow3] = useState(false);
  const [isBankVerified, setIsBankVerified] = useState(false);
  const [bankVerificationResponse, setBankVerificationResponse] =
    useState(null);

  const {
    user,
    refreshWallet,
    wallet,
    setTransactionLoaderState,
    Profileinfo,
  } = useContext(GlobalContext);
  const [tpinShow, setTpinShow] = useState(false);

  const GetBal = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${user.ReferId}`,
        requestOptions
      );
      const result = await res.json();
      if (Number(result.AEPSBalance) >= Number(result.WalletBalance)) {
        setBalanceToShow(result.WalletBalance);
      } else {
        setBalanceToShow(result.AEPSBalance);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upiSettlement = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        accNo: upiModalValues.upiid,
        ifsc: "Y",
        transactionAmount: upiModalValues.amount,
        refid: "A",
        custName: Profileinfo.shopname,
        custMobile: Profileinfo.mobile,
        custEmail: Profileinfo.email,
        txntMode: "UPI",
        tpin: upiModalValues.tpin,
        device: "WEB",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/aeps/upiSettlementAeps`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setMdShow2(false);
        refreshWallet();
      } else {
        swal("Failed", data.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const [upiListData, setUpiListData] = useState([]);
  const UPIList = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: "BR1050",
        service: "UPI",
        amount: "0",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/aeps/getAllUpiBeneficiary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setUpiListData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const aepsWalletTopup = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        amount: aepsTopupModalValues.amount,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/wallet/topupAepsLimit`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal(
          "Success",
          "Balance Topup into AEPS Wallet Successfully",
          "success"
        );
        setMdShow3(false);
        refreshWallet();
        clearAepsTopupModalForm();
      } else {
        swal("Failed", data.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const clearAepsTopupModalForm = () => {
    setAepsTopupModalValues({
      tpin: "",
      upiid: "",
      amount: "",
      upiName: "",
      upiMobile: "",
    });
  };

  // Final Settlement Start

  const InitialFinalSettlement = {
    Amount: "",
    TPin: "",
    Type: "",
    SelectAccount: "",
    upiid: "",
  };
  const [finalSettlement, setFinalSettlement] = useState(
    InitialFinalSettlement
  );
  const [finalSettlementErrors, setfinalSettlementErrors] = useState({});
  const [IsSubmit, setIsSubmit] = useState(false);
  const [bankname, setbankname] = useState("");
  const [Name, setName] = useState("");

  const FinalSettlement = async () => {
    // if (Number(balanceToShow - 5) < finalSettlement.Amount) {
    //   swal("Failed", `insufficient fund`, "error");
    //   return;
    // }
    setTransactionLoaderState(true);
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=finalsettlementbankaccount&LoginID=${user.ReferId}&Settlementamount=${finalSettlement.Amount}&aepstpin=${finalSettlement.TPin}&ServiceType=${finalSettlement.Type}&BankAccountNo=${BankObject?.usAcc}&BankIFSC=${BankObject?.DtDiff}&BankName=${BankObject?.usName}`,
        requestOptions
      );
      const result = await res.json();
      // cogoToast.info(result.msg);
      if (result.status == "Y") {
      }
      // swal(
      //   "Successfull",
      //   `Amount added successfully`,
      //   "warning"
      // );
      swal(result.msg);
      setBankObject("");
      setFinalSettlement({
        Amount: "",
        TPin: "",
        Type: "",
        SelectAccount: "",
      });
      clearSettlementForm();
      refreshWallet();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log(error);
      swal("Failed", `Something went wrong`, "error");
      cogoToast.error("Something went wrong");
      setFinalSettlement({
        Amount: "",
        TPin: "",
        Type: "",
        SelectAccount: "",
      });
    }
  };

  const finalSettlementTpinValidation = () => {
    validateTPin(
      Profileinfo.mobile,
      finalSettlement.TPin,
      () => {
        FinalSettlement();
      },
      () => {}
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalSettlement({ ...finalSettlement, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setfinalSettlementErrors(validate(finalSettlement));
    console.log(finalSettlementErrors);
    console.log(finalSettlement);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(finalSettlementErrors).length === 0 && IsSubmit) {
      finalSettlementTpinValidation();
    }
  }, [finalSettlementErrors]);

  const validate = (values) => {
    console.log(values);
    const errors = {};
    if (!values.Amount) {
      errors.Amount = "*Amount is required!";
    }
    if (values.Amount && Number(values.Amount) < 10) {
      errors.Amount = "*Amount is required!";
    }
    if (!values.TPin) {
      errors.TPin = "*TPin is required!";
    }
    if (!values.Type) {
      errors.Type = "*Please select transaction type!";
    }
    if (!values.SelectAccount) {
      errors.SelectAccount = "*Please select an account!";
    }

    return errors;
  };

  // Final Settlement End

  // Add Bank Account Start

  const InitialAddBankValues = {
    Mobile: "",
    AccHolderName: "",
    BankAcNumber: "",
    SelectBank: "",
    IFSCcode: "",
  };
  const [AddBankValues, setAddBankValues] = useState(InitialAddBankValues);
  const [AddBankValuesErrors, setAddBankValuesErrors] = useState({});
  const [Submit, setSubmit] = useState(false);

  const AddBank = async () => {
    setTransactionLoaderState(true);
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getuserbankaccount&Loginid=${user.ReferId}&BankAccountNo=${AddBankValues.BankAcNumber}&BankIFSC=${AddBankValues.IFSCcode}&BankName=${AddBankValues.SelectBank}&CustomerName=${AddBankValues.AccHolderName}`,
        requestOptions
      );
      const result = await res.json();
      if (result.Id == "Y") {
        cogoToast.success(result.msg);
        swal("Successfull", `Bank Account added successfully`, "success").then(
          (isPressed) => {
            if (isPressed) {
            } else {
            }
            console.log(isPressed);
            setMdShow(false);
          }
        );
        // login id daldio url me
      } else {
        swal("Failed", result.msg, "error");
      }
      GetBankList();
      GetUserBankAcList();
      GetBal();
      clearAddBankForm();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
      // console.log(error);
    }
  };

  const handleAddBankValues = (e) => {
    const { name, value } = e.target;
    setAddBankValues({ ...AddBankValues, [name]: value });
  };
  const [selectedUpi, setSelectedUpi] = useState(null);
  const handleUpiModalValues = (e) => {
    setUpiModalValues({
      ...upiModalValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpiSelectChange = (selectedOption) => {
    setSelectedUpi(selectedOption);
    setUpiModalValues({
      ...upiModalValues,
      upiid: selectedOption ? selectedOption.value : '', 
    });
  };
  const UPIVerificationValidation = () => {
    const errors = {};

    if (!upiModalValues.upiid) {
      errors.UPI = "UPI ID is required";
    }
    if (!upiModalValues.amount) {
      errors.Amount = "Amount is required";
    } else if (parseFloat(upiModalValues.amount) <= 0) {
      errors.Amount = "Amount must be greater than 0";
    }

    if (!upiModalValues.tpin) {
      errors.TPIN = "TPIN is required";
    }
    if (Object.keys(errors).length > 0) {
      setUPIValuesErrors(errors);
    } else {
      console.log("Transfer initiated");
      setUPIValuesErrors({
        UPI: "",
        Amount: "",
        TPIN: "",
      });
      upiSettlement();
    }
  };

  const TOPUPVerificationValidation = () => {
    const errors = {};

    if (!aepsTopupModalValues.amount) {
      errors.amount = "Amount is required";
    } else if (parseFloat(aepsTopupModalValues.amount) <= 0) {
      errors.amount = "Amount must be greater than 0";
    }

    if (Object.keys(errors).length > 0) {
      setAepsTopupModalErrors(errors);
    } else {
      setAepsTopupModalErrors({
        amount: "",
      });
      aepsWalletTopup();
    }
  };
  const handleAepsTopupModalValues = (e) => {
    const { name, value } = e.target;
    setAepsTopupModalValues({ ...aepsTopupModalValues, [name]: value });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setAddBankValuesErrors(validate1(AddBankValues));
    console.log(AddBankValuesErrors);
    console.log(AddBankValues);
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(AddBankValuesErrors).length === 0 && Submit) {
      AddBank();
    }
  }, [AddBankValuesErrors]);

  const validate1 = (values) => {
    const errors = {};
    if (!values.AccHolderName) {
      errors.AccHolderName = "*Account Holder Name is required!";
    }
    if (!values.BankAcNumber) {
      errors.BankAcNumber = "*Bank Account Number is required!";
    }
    if (!values.SelectBank) {
      errors.SelectBank = "*Name of Bank is required";
    }
    if (!values.IFSCcode) {
      errors.IFSCcode = "*IFSC code is required!";
    } else if (values.IFSCcode.length != 11) {
      errors.IFSCcode = "*Enter valid IFSC code!";
    }
    if (!values.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (values.Mobile.length != 10) {
      errors.Mobile = "*Please enter valid mobile number";
    }

    return errors;
  };

  // Add Bank Account End

  const GetBankList = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BASE_URL_AEPS}/banklist.php`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setBankList(
            result.data.map((bank) => {
              return { ...bank, value: bank.bankName, label: bank.bankName };
            })
          );
        }
      });
  };

  const GetUserBankAcList = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=GetuserBankaccountlist&Loginid=${user.ReferId}`,
        requestOptions
      );
      const result = await res.json();
      if (result) {
        setUserAcList(
          result.map((bank) => {
            return {
              ...bank,
              value: `${bank.usName} | ${bank.usAcc} | ${bank.DtDiff} | ${bank.bank}`,
              label: `${bank.usName} | ${bank.usAcc} | ${bank.DtDiff} | ${bank.bank}`,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const bankAccountVerificationValidation = () => {
    const errors = {};
    if (!AddBankValues.AccHolderName) {
      errors.AccHolderName = "*Account Holder Name is required!";
    }
    if (!AddBankValues.BankAcNumber) {
      errors.BankAcNumber = "*Bank Account Number is required!";
    }
    if (!AddBankValues.SelectBank) {
      errors.SelectBank = "*Name of Bank is required";
    }
    if (!AddBankValues.IFSCcode) {
      errors.IFSCcode = "*IFSC code is required!";
    } else if (AddBankValues.IFSCcode.length != 11) {
      errors.IFSCcode = "*Enter valid IFSC code!";
    }
    if (!AddBankValues.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (AddBankValues.Mobile.length != 10) {
      errors.Mobile = "*Please enter valid mobile number";
    }
    setAddBankValuesErrors(errors);
    if (Object.keys(errors).length === 0) {
      BankAccountVerification();
    }
  };

  const BankAccountVerification = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y3BseX5c2e1_He5DAelsa1n0ZTz4RkJywd-zilehzdosBM7VEEhVjKiDEi97xIhAI5XSsrl1nBtQ6jGMvs2wYBI_CkeFAnkQ9NGlPD462psrNHF8SVYdadk_JYObPPbljkuseI1g22cd6NEcoklLwj1Nq9op2JmcJHcHZiErKvioNEIn11KNglzBry8eAAYUageBP4c934mbr-kjpPyWF8lUPPuki1XIhBKMXTwj0pcUqwPMAIkzCh4l6rGH8BscN-6A_Ge9CzqrqCxhPSz_pOZfsPJHevYk49yC2sDCdOxerLOkFMtfnVFSLldoEue_HHokFJOKhSpvRsU_4wWsTnLOd16bwFte7uEQX3dFxi10njb2aPzTBMKNb1zt3gzRzxT0qvTC81N-5Wa8mIwC9jwD8DYzHPk1y0T0k0s9Sov8MQouCWbgZOJxDU4NuaWbH-CEMNquMq6ug6BoTYKve0ItoSIl-dnt-WKhtnbYj4lMdNBKRWUmYiVu-pxSx_Xg3gcij7mzbWsgFO1rTXgp8KlqNyJqFk_zOVQ5WW4DsPANP9DOKg5Ut8R9Ih0JwIF_tR0TK9pp3iHUIifwiEhiDU72VP6iF2btn6_wLKczsySGW5SfrpqbibuA-6vvYhEi9EpxohhVwo0pRmB-yhYoMAb"
      );

      var raw = JSON.stringify({
        account_number: AddBankValues.BankAcNumber,
        ifsc: AddBankValues.IFSCcode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/BankaccountVerify`,
        requestOptions
      );
      const data = await res.json();
      if (data.success && data.response_code == "100") {
        setIsBankVerified(true);
        setBankVerificationResponse(data.result);
        setAddBankValues({
          ...AddBankValues,
          AccHolderName: data.result?.beneficiary_name,
        });
      } else {
        swal("Failed", `Bank not verified`, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Failed", `Error From Server`, "error");
      setTransactionLoaderState(false);
    }
  };

  const clearAddBankForm = () => {
    setAddBankValues({
      Mobile: "",
      AccHolderName: "",
      BankAcNumber: "",
      SelectBank: "",
      IFSCcode: "",
    });
    setIsBankVerified(false);
  };

  const clearSettlementForm = () => {
    setFinalSettlement(InitialFinalSettlement);
  };
  const clearUpiModalForm = () => {
    setUpiModalValues({
      upiid: "",
      amount: "",
      tpin: "",
    });
    setUPIValuesErrors({
      UPI: "",
      Amount: "",
      TPIN: "",
    });
  };
  const clearTOPUPModalForm = () => {
    aepsTopupModalValues({
      amount: "",
    });
    setAepsTopupModalErrors({
      amount: "",
    });
  };
  useEffect(() => {
    GetBankList();
    GetUserBankAcList();
    GetBal();
    UPIList();
  }, []);

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  console.log(upiModalValues);

  return (
    <>
  
  <div className=" card m-3">
    <div className="row ">
      <h5 className="col-7">AEPS Settlement</h5>
      <Button className="col"
            style={{ borderRadius: ".4rem" , border:"2px solid #00A552 ", background:"#eaf9ee ", color:"#00A552 "}}
            // variant="success"
            onClick={() => {
              setMdShow3(true);
            }}
           
          >
            Topup AEPS Limit
          </Button>
          <div style={{ width: "10px" }}></div>
          <Button className="col"
            style={{ borderRadius: ".4rem" , border:"2px solid #00A552 ", background:"#eaf9ee ", color:"#00A552 "}}
            // variant="success"
            onClick={() => {
              setMdShow2(true);
            }}
            
          >
            Upi Settlement
          </Button>
          <div style={{ width: "10px" }}></div>
          <Button className="col"
           style={{ borderRadius: ".4rem" , border:"2px solid #00A552 ", background:"#eaf9ee  ", color:"#00A552 "}}
            // variant="success"
            onClick={() => {
              setMdShow(true);
            }}
         
          >
            Add Bank Account
          </Button>
    </div>
    <div className="mx-2 mb-4">
     <h6><strong> Settlement Balance ₹{balanceToShow}</strong></h6> 
    </div>
    <div className="row ">
    <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
        <Form.Label>
          <span style={{ color: "red" }}>*</span>
          {` Enter Amount`}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter Amount"
          name="Amount"
          className="rounded-0"
          value={finalSettlement.Amount}
          onChange={handleChange}
        />
        <p style={{ color: "red" }}>{finalSettlementErrors.Amount}</p>
      </Form.Group>

      <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
        <Form.Label>
          <span style={{ color: "red" }}>*</span>
          {` Select Account`}
        </Form.Label>
        <Select
          onChange={(e) => {
            setBankObject(e);
            console.log(e);
            setFinalSettlement({
              ...finalSettlement,
              SelectAccount: e.bank,
            });
          }}
          options={UserAcList}
        />
        <p style={{ color: "red" }}>{finalSettlementErrors.SelectAccount}</p>
      </Form.Group>
    </div>
    <div className="row ">
    <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {` Account Holder Name`}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank Account Name"
              style={{ borderRadius: "0px" }}
              value={BankObject.usName}
              name="Name"
              // disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {` Account Number`}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Account Number"
              style={{ borderRadius: "0px" }}
              value={BankObject.usAcc}
              // disabled={true}
              name="AccNo"
            />
          </Form.Group>
    </div>
    <div className="row  ">
    <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {` Bank Name`}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank Name"
              style={{ borderRadius: "0px" }}
              value={BankObject.bank}
              name="bankname"
              onChange={(e) => {
                setbankname(BankObject.bank);
              }}
            />
          </Form.Group>
          <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {` IFSC Code`}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Bank IFSC Code"
              style={{ borderRadius: "0px" }}
              value={BankObject.DtDiff}
              name="IFSCcode"
            />
          </Form.Group>

    </div>
    <div className="row ">
    
    <div className="mt-4 col-12 col-sm-6 col-md-4 py-0 px-1'">
    
        <input
          type="radio"
          id="IMPS"
          name="Type"
          value="IMPS"
          onChange={handleChange}
        />
        <label htmlFor="IMPS">
          <strong style={{ color: "grey" }}> IMPS</strong>
        </label>
        <input
          style={{ marginLeft: "10px" }}
          type="radio"
          id="NEFT"
          name="Type"
          value="NEFT"
          onChange={handleChange}
        />
        <label htmlFor="NEFT">
          <strong style={{ color: "grey" }}> NEFT</strong>
        </label>
        <input
          style={{ marginLeft: "10px" }}
          type="radio"
          id="RTGS"
          name="Type"
          value="RTGS"
          onChange={handleChange}
        />
        <label htmlFor="RTGS">
          <strong style={{ color: "grey" }}> RTGS</strong>
        </label>
        <p style={{ color: "red" }}>{finalSettlementErrors.Type}</p>
      </div>
      <Form.Group className="col-12 col-sm-6 col-md-4 py-0 px-1'">
        <Form.Label>
          <span style={{ color: "red" }}>*</span>
          {` T Pin`}
        </Form.Label>
        <div className="input-group input-group-sm">
          <div className="input-group-prepend bg-transparent">
            <span className="input-group-text bg-transparent border-right-0 no-glow">
              <i className="mdi mdi-lock-outline text-primary"></i>
            </span>
          </div>

          <input
            autocomplete="new-password"
            autoCorrect="off"
            autoSave="new-password"
            type={tpinShow ? "text" : "password"}
            className="form-control form-control-lg border-left-0 border-right-0 no-glow"
            id="tpin"
            placeholder="Enter T-Pin"
            style={{ borderRadius: "0px" }}
            name="TPin"
            value={finalSettlement.TPin}
            onChange={(e) => {
              if (e.target.value.length > 6) {
                setfinalSettlementErrors({
                  ...finalSettlementErrors,
                  TPin: "TPin Should be 6 digit long",
                });
              } else if (
                onlyNumeric(e.target.value) ||
                e.target.value == ""
              ) {
                // console.log("alpha");
                setfinalSettlementErrors({
                  ...finalSettlementErrors,
                  TPin: "",
                });
              } else {
                setfinalSettlementErrors({
                  ...finalSettlementErrors,
                  TPin: "TPin Only Contains Numeric Values",
                });
                // alert("message");
              }
              setFinalSettlement({
                ...finalSettlement,
                TPin: e.target.value,
              });
            }}
          />
          <div className="input-group-prepend bg-transparent">
            <span
              className="input-group-text bg-transparent border-left-0 no-glow"
              onClick={showTPinClick}
              style={{ borderRadius: "4px" }}
            >
              <i
                className={`mdi text-primary ${
                  tpinShow ? "mdi-eye" : "mdi-eye-off"
                }`}
              ></i>
            </span>
          </div>
        </div>
        <p style={{ color: "red" }}>{finalSettlementErrors.TPin}</p>
      </Form.Group>
    </div>
    <div className="m-2">
        <Button
          style={{ borderRadius: "0px" }}
          variant="success"
          onClick={handleSubmit}
        >
          Transfer
        </Button>
      </div>
  </div>

    {/* UPI Settlement */}

  <Modal
      size="sm"
      show={mdShow3}
      onHide={() => {
        setMdShow3(false);
        clearTOPUPModalForm();
      }}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <Modal.Header closeButton>
        <Modal.Title> Topup AEPS Wallet </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ width: "100%", height: "12rem", overflow: "hidden" }}
        className="account"
      >
        <Col sm={12} className="py-0 px-1">
          <Form.Group>
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {` Enter Amount`}
            </Form.Label>
            <Form.Control
              type="number"
              name="amount"
              placeholder="Enter Amount"
              style={{ borderRadius: "0px" }}
              onChange={handleAepsTopupModalValues}
              value={aepsTopupModalValues.amount}
            />
            <p style={{ color: "red" }}>{aepsTopupModalErrors.amount}</p>
          </Form.Group>

          <div className="mt-2">
            <Button
              className="mr-2 rounded-0"
              variant="success"
              onClick={TOPUPVerificationValidation}
            >
              Topup
            </Button>
          </div>
        </Col>
      </Modal.Body>
    </Modal>

    <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Bank Account</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ width: "100%", height: "35rem", overflow: "hidden" }}
          className="account"
        >
          <Col sm={12} className="py-0 px-1">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter Mobile No`}
              </Form.Label>
              <Form.Control
                type="number"
                name="Mobile"
                placeholder="Enter Mobile No."
                style={{ borderRadius: "0px" }}
                onChange={handleAddBankValues}
                value={AddBankValues.Mobile}
              />
              <p style={{ color: "red" }}>{AddBankValuesErrors.Mobile}</p>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Account Holder Name`}
              </Form.Label>
              <Form.Control
                type="text"
                name="AccHolderName"
                disabled={isBankVerified}
                placeholder="Enter Account Holder Name"
                style={{ borderRadius: "0px" }}
                onChange={(e) => {
                  if (onlyAlpha(e.target.value)) {
                    // console.log("alpha");
                    setAddBankValuesErrors({
                      ...AddBankValuesErrors,
                      AccHolderName: "",
                    });
                  } else {
                    //console.log("numeric");
                    setAddBankValuesErrors({
                      ...AddBankValuesErrors,
                      AccHolderName:
                        "Beneficiary Name Contains only Alphabates",
                    });
                    // alert("message");
                  }
                  setAddBankValues({
                    ...AddBankValues,
                    AccHolderName: e.target.value,
                  });
                }}
                value={AddBankValues.AccHolderName}
              />
              <p style={{ color: "red" }}>
                {AddBankValuesErrors.AccHolderName}
              </p>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter Bank Account No`}
              </Form.Label>

              <Form.Control
                type="number"
                name="BankAcNumber"
                placeholder="Enter Bank Account No"
                style={{ borderRadius: "0px" }}
                onChange={handleAddBankValues}
                value={AddBankValues.BankAcNumber}
              />
              <p style={{ color: "red" }}>
                {AddBankValuesErrors.BankAcNumber}
              </p>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Select Bank`}
              </Form.Label>

              <Select
                options={BankList}
                onChange={(e) => {
                  console.log(e);
                  setAddBankValues({
                    ...AddBankValues,
                    SelectBank: e.bankName,
                  });
                }}
              />
              <p style={{ color: "red" }}>{AddBankValuesErrors.SelectBank}</p>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter Bank IFSC Code`}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank IFSC Code"
                style={{ borderRadius: "0px" }}
                //  onChange={handleAddBankValues}
                onChange={(e) => {
                  console.log(/^[a-zA-Z0-9]+$/.test(e.target.value));
                  if (
                    AddBankValues.IFSCcode == "" ||
                    /^[a-zA-Z0-9]+$/.test(e.target.value)
                  ) {
                    // console.log("alpha");
                    setAddBankValuesErrors({
                      ...AddBankValuesErrors,
                      IFSCcode: "",
                    });
                  } else {
                    // console.log("numeric");
                    setAddBankValuesErrors({
                      ...AddBankValuesErrors,
                      IFSCcode: "IFSC code only Alphanumeric values",
                    });
                    // alert("message");
                  }
                  setAddBankValues({
                    ...AddBankValues,
                    IFSCcode: e.target.value.toString().toUpperCase(),
                  });
                }}
                name="IFSCcode"
                value={AddBankValues.IFSCcode}
                maxLength={11}
              />
              <p style={{ color: "red" }}>{AddBankValuesErrors.IFSCcode}</p>
            </Form.Group>

            {isBankVerified && (
              <Success
                text={`Bank Account Verified \n Account Holder Name : ${bankVerificationResponse?.beneficiary_name}`}
              />
            )}

            <div className="mt-2">
              {isBankVerified && (
                <Button
                  className="mr-2 rounded-0"
                  variant="success"
                  onClick={handleSubmit1}
                >
                  Add Bank Account
                </Button>
              )}
              {!isBankVerified && (
                <Button
                  className="mr-2 rounded-0"
                  variant="success"
                  onClick={bankAccountVerificationValidation}
                >
                  Verify Account
                </Button>
              )}
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        show={mdShow2}
        onHide={() => {
          setMdShow2(false);
          clearUpiModalForm();
        }}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> UPI Transfer </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ width: "100%", height: "30rem", overflow: "hidden" }}
          className="account"
        >
          <Col sm={12} className="py-0 px-1">
          

            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Select UPI`}
              </Form.Label>

              <Select
                options={upiListData.map((item) => ({
                  value: item.acc_no,
                  label: item.acc_no,
                }))}
                onChange={handleUpiSelectChange} 
                value={selectedUpi}
              />
            
            </Form.Group>
            <p className="d-flex justify-content-center m-0 p-0 ">OR</p>
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter UPI ID`}
              </Form.Label>
              <Form.Control
                type="text"
                name="upiid"
                placeholder="Enter UPI ID"
                style={{ borderRadius: "0px" }}
                onChange={handleUpiModalValues}
                value={upiModalValues.upiid}
              />
              <p style={{ color: "red" }}>{UPIValuesErrors.UPI}</p>
            </Form.Group>
            {/* Enter Amount */}
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter Amount`}
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                placeholder="Enter Amount"
                style={{ borderRadius: "0px" }}
                onChange={handleUpiModalValues}
                value={upiModalValues.amount}
              />
              <p style={{ color: "red" }}>{UPIValuesErrors.Amount}</p>
            </Form.Group>

            {/* Enter TPIN */}
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` Enter TPIN`}
              </Form.Label>
              <Form.Control
                type="password"
                name="tpin"
                maxLength={6}
                placeholder="Enter TPIN"
                style={{ borderRadius: "0px" }}
                onChange={handleUpiModalValues}
                value={upiModalValues.tpin}
              />
              <p style={{ color: "red" }}>{UPIValuesErrors.TPIN}</p>
            </Form.Group>

            <div className="mt-2">
              <Button
                className="mr-2 rounded-0"
                variant="success"
                onClick={UPIVerificationValidation}
              >
                Transfer
              </Button>
            </div>
          </Col>
        </Modal.Body>
      </Modal>





  </>
   
  );
};

const Success = ({ text }) => {
  return (
    <div className="card-inverse-success">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};

export default FundTransfer;



  




