export const basicAuth = {
  username: "Rajneesh@example.com",
  password: "Test@12345",
};



export const billpunchbasicauth={
  username: "abhijeet@bumppy",
  password: "password",

}