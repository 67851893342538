import React, { Component } from "react";

const Spinner = () => {
  return (
    <div>
      <div className="spinner-wrapper">
        <div className="donut"></div>
      </div>
    </div>
  );
};
export default Spinner;
