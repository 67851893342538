import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { IoInformationCircleOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";

const Esiadminform = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [formData, setFormData] = useState({
    esinumber: "",
    deductionCycle: "",
    employeeContribution: "0.75%",
    employerContribution: "3.25%",
    includeEmployerContribution: false,
  });

  const [ESINumber, setESINumber] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      if (name === "esinumber") {
        let formattedValue = value.replace(/[^A-Za-z0-9]/g, "");

        if (formattedValue.length > 2) {
          formattedValue =
            formattedValue.slice(0, 2) + "-" + formattedValue.slice(2);
        }
        if (formattedValue.length > 5) {
          formattedValue =
            formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
        }
        if (formattedValue.length > 11) {
          formattedValue =
            formattedValue.slice(0, 11) + "-" + formattedValue.slice(11);
        }
        if (formattedValue.length > 14) {
          formattedValue =
            formattedValue.slice(0, 14) + "-" + formattedValue.slice(14);
        }
        setESINumber(formattedValue.toUpperCase());
        setFormData({ ...formData, [name]: formattedValue.toUpperCase() });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("submit form");
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        esic_number: ESINumber,
        deduction_cycle: formData.deductionCycle,
        employee_contribution: "0.75%",
        employer_contribution: "3.25%",
        other: formData.includeEmployerContribution ? "Y" : "N",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/AddEsicDetail`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        Getepf();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Getepf = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEsicDetail`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const esinumber = data.data[0];
        setFormData({
          esinumber: esinumber.esic_number,
          deductionCycle: esinumber.deduction_cycle,
          //   employeeContribution: esinumber.employee_contribution,
          //   employerContribution: esinumber.employer_contribution,
          includeEmployerContribution: esinumber.include_in_ctc === "Y",
        });
        setESINumber(esinumber.esic_number);
        setIsDataLoaded(true);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getepf();
  }, []);

  return (
    <>
      <div className="mx-md-4 mx-2 mt-md-3 mt-2  p-2">
      <div className=" row m-0 p-0">
      <h5 className="px-2">Employees' State Insurance</h5>
          <div className="col d-flex justify-content-end">
            <Link to="/retailer/Statutory_components">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
       
        <hr className="m-2"/>
        <div className="row">
          <div className="col-md-8 col-12 p-0">
            <form onSubmit={handleSubmit}>
              <div className="row ">
                <div className="col-6 form-group">
                  <label htmlFor="ESINumber">ESI Number</label>
                  <input
                    type="text"
                    id="ESINumber"
                    name="esinumber"
                    className="form-control"
                    value={ESINumber}
                    onChange={handleInputChange}
                    placeholder="00-00-000000-000-0000"
                    // maxLength="18"
                  />
                </div>
                <div className="col-4 form-group">
                  <label htmlFor="deductionCycle">
                    Deduction Cycle
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          ESI contribution for each month should be deposited to
                          the Employees' State Insurance Corporation (ESIC)
                          within the 15th of the following month.
                        </Tooltip>
                      }
                    >
                      <IoInformationCircleOutline
                        style={{ fontSize: "1rem" }}
                      />
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    id="deductionCycle"
                    name="deductionCycle"
                    className="form-control"
                    value={formData.deductionCycle}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-4 form-group">
                  <label htmlFor="employeeContribution">
                    Employee's Contribution
                  </label>
                  <input
                    type="text"
                    id="employeeContribution"
                    name="employeeContribution"
                    className="form-control"
                    value={formData.employeeContribution}
                    onChange={handleInputChange}
                    placeholder="Enter employee's contribution"
                    disabled={isDataLoaded}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="employeeContribution"></label>
                  <input
                    className="form-control"
                    value={"of Gross Pay"}
                    style={{ border: "none" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 mb-0 form-group">
                  <label htmlFor="employerContribution">
                    Employer's Contribution
                  </label>
                  <input
                    type="text"
                    id="employerContribution"
                    name="employerContribution"
                    className="form-control"
                    value={formData.employerContribution}
                    onChange={handleInputChange}
                    placeholder="Enter employer's contribution"
                    disabled={isDataLoaded}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="employeeContribution"></label>
                  <input
                    className="form-control"
                    value={"of Gross Pay"}
                    style={{ border: "none" }}
                  />
                </div>
              </div>

              <div className="row m-1 p-0">
                <div className="col-8 form-group">
                  <Form.Check
                    type="checkbox"
                    id="includeEmployerContribution"
                    name="includeEmployerContribution"
                    label={
                      <span style={{ color: "black" }}>
                        Include employer's contribution in the CTC
                      </span>
                    }
                    checked={formData.includeEmployerContribution}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
                <div className="col-10 p-2" style={{background:"#FFF5E3"}}>
                  <p>
                    Note: ESI deductions will be made only if the employee’s
                    monthly salary is less than or equal to ₹21,000. If the
                    employee gets a salary revision which increases their
                    monthly salary above ₹21,000, they would have to continue
                    making ESI contributions till the end of the contribution
                    period in which the salary was revised (April-September or
                    October-March).
                  </p>
                </div>
                <hr/>
              <div className="row ">
                <div className="col-12 form-group">
                  <Button type="submit" className="btn btn-primary">
                    Submit
                  </Button>
                  <Button
              style={{ background: "#fff", border: "none" }}
              disabled
            ></Button>
                  <Link to="/retailer/Statutory_components">
                  <Button type="submit" className="btn btn-light">
                    Cancel
                  </Button>
                </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Esiadminform;
