import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import "bootstrap/dist/css/bootstrap.min.css";

const Synchronize = () => {
  const isSmallScreen = useMediaQuery("(max-width: 825px)");

  return (
    <>
      <div className="mt-5 h-md-75 h-50">
        <div
          style={{
            backgroundImage: `url(${require("../../../assets/images/sixthback.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row">
            <div className="col-1"></div>
            <div className="col-md-10 col-12">
              <div className="row p-0">
                <div className="col pt-md-3 pt-0 px-md-2 mx-md-1 px-4 my-md-5">
                  <h2
                    className="text-white"
                    style={{
                      fontSize: isSmallScreen ? '1.5rem' : '',
                      fontWeight: 500,
                    }}
                  >
                    Coordinate, <br />
                    Enhance, and Present
                  </h2>
                  <p style={{ color: "#fff", fontWeight: "400" }}>
                    Empowering teamwork and streamlining operations
                    <br /> with perfectly synchronized data.
                  </p>
                </div>
                <div className="col mt-md-5 mt-2 offset-md-1 d-flex flex-md-row flex-column align-items-center justify-content-center">
                  <img
                    className="mr-md-3 mb-md-3"
                    alt="Billpunch"
                    style={{ height: "7rem" }}
                    src={require("../../../assets/images/TotalEmployeesbar.png")}
                  />
                  <img
                    className="mt-2 mt-md-3"
                    alt="Billpunch"
                    style={{ height: "7rem" }}
                    src={require("../../../assets/images/Averagesalarybar.png")}
                  />
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Synchronize;
