import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import QRCode from "qrcode";
import { COMPANY_NAME, QR_TITLE_COMPANY_NAME } from "../utils/StringConstants";
import { COLOR_QR_PLACEHOLDER, COLOR_QR_PLACEHOLDER_SECONDARY } from "../utils/Themer";

const generateURL = ({ amount, name, upi }) => {
  return `upi://pay?pa=${upi}&pn=${name}&cu=INR`;
};

const loadCanvas = async (canvasEl, dataURL, canvasContextRef) => {
  const canvasContext = canvasEl.current.getContext("2d");
  const imageObj = new Image();

  canvasContextRef.current = canvasContext;
  imageObj.onload = function () {
    canvasContext.drawImage(this, 0, 0);
  };
  imageObj.src = dataURL;
};

const getQRImage = async ({ amount, name, upi }) => {
  try {
    const url = generateURL({ amount, name, upi });
    console.log(url);
    const qrCode = await QRCode.toDataURL(url, {
      type: "image/png",
      margin: 1,
      width: 300,
    });
    return qrCode;
  } catch (e) {
    console.log("API exception ", e);
    return "";
  }
};

const paintQR = async (canvasEl, canvasContextRef, { amount, name, upi }) => {
  const dataURL = await getQRImage({ amount, name, upi });
  await loadCanvas(canvasEl, dataURL, canvasContextRef);
};

const QRPrint = () => {
  const [virtualAccount, setVirtualAccount] = useState({});
  const { user } = useContext(GlobalContext);
  const printReceipt = () => {
    window.print();
  };

  // QR Code
  const canvasEl = useRef(null);
  const canvasContextRef = useRef(null);

  useEffect(() => {
    const data = sessionStorage.getItem("print");
    console.log(data);
    if (data) {
      setVirtualAccount(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    paintQR(canvasEl, canvasContextRef, {
      amount: 1,
      name: COMPANY_NAME,
      upi: virtualAccount.va_qrurl,
    });
  }, [virtualAccount]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          backgroundColor: "whitesmoke",
          padding: "1rem",
        }}
      >
        <button
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "#218838",
            color: "#FFF",
          }}
          className="hide-on-print"
          onClick={printReceipt}
        >
          {"Print"}
        </button>
      </div>
      <div
        style={{ padding: "0px", backgroundColor: "whitesmoke" }}
        className="row px-4"
      >
        <Row>
          <Col
            md={5}
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "0px 1rem",
                background: "#FFFFFF",
                padding: "0px",
                borderRadius: "1rem",
              }}
            >
              <div
                style={{
                  backgroundColor: COLOR_QR_PLACEHOLDER,
                  WebkitPrintColorAdjust: "exact",
                  display: "flex",
                  padding: "6px 1rem",
                  borderTopRightRadius: "1rem",
                  borderTopLeftRadius: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "white", fontWeight: "bolder" }}>
                  <h4 style={{ fontWeight: "bolder", margin: "0px" }}>
                    {QR_TITLE_COMPANY_NAME}
                  </h4>
                </div>
                <div style={{ margin: "0px 2rem" }}>
                  <img
                    style={{ height: "3.5rem", width: "auto" }}
                    src={require("../../assets/images/virtual_account/qr_logo_square.png")}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1" }}>
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={require("../../assets/images/virtual_account/virtual_account_app_logo.png")}
                  />
                </div>
                <div
                  style={{
                    flex: "1",
                    background: "#FFFFFF",
                    boxShadow:
                      "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    padding: "6px",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={require("../../assets/images/virtual_account/bhim_upi_logo.png")}
                  />
                  <canvas
                    style={{ width: "100%", height: "auto" }}
                    ref={canvasEl}
                    id="qrCanvas"
                    width="300"
                    height="300"
                    className="mx-auto"
                  />
                  <div>
                    <strong>PAY WITH ANY APP</strong>
                  </div>
                </div>
              </div>

              <div
                style={{
                  background: COLOR_QR_PLACEHOLDER_SECONDARY,
                  WebkitPrintColorAdjust: "exact",
                  color: "#FFFFFF",
                  padding: "6px",
                  borderBottomRightRadius: "1rem",
                  borderBottomLeftRadius: "1rem",
                }}
              >
                <div className="d-flex flex-row justify-content-center">
                  <span>Account Name:</span>
                  <strong className="pl-1">
                    {COMPANY_NAME}
                  </strong>
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <span>Account No:</span>
                  <strong className="pl-1">
                    {" "}
                    {virtualAccount ? virtualAccount.va_account_no : ""}{" "}
                  </strong>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <span>IFSC Code:</span>
                  <strong className="pl-1">
                    {" "}
                    {virtualAccount ? virtualAccount.va_ifsc : ""}{" "}
                  </strong>
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <span>Deposit Type:</span>
                  <strong className="pl-1"> {"IMPS/NEFT/RTGS."} </strong>
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <span>UPI ID:</span>
                  <strong className="pl-1">
                    {" "}
                    {virtualAccount ? virtualAccount.va_upiid : ""}{" "}
                  </strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default QRPrint;
