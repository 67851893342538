import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import swal from "sweetalert";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";

function ServicesRequest() {
  const initialState = {
    name: "",
    userid: "",
    type: "",
    remark: "",
  };
  const [state, setState] = useState(initialState);
  const [userRoles, setUserRoles] = useState([]);
  const [remark, setRemark] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [data, setData] = useState([]);
  const { user, setTransactionLoaderState,Profileinfo } = useContext(GlobalContext);

  const validateForm = () => {
    if (selectedService == null) {
      swal("Invalid", "Please Select service", "error");
      return;
    }
    submitServiceForm();
  };
  const clearForm = () => {
    setSelectedService(null)
    setRemark("");
  };
  const submitServiceForm = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        service_id: selectedService.value,
        service_name: selectedService.label,
        remark: remark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/services/serviceRequestSubmit`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        
        swal("Success", data.data.message, "success");
        clearForm();
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("User Registration Failed", "Error From Server", "error");
    }
  };

  const servicesList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/services/getServicesRequestList`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((role) => {
            return {
              label: `${role.service_name}`,
              value: role.service_id,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    servicesList();
  }, []);

  console.log(selectedService);

  return (
    <div
      style={{
        background: "#F2F6FC",
        height: "100%",
      }}
    >
      <h3 className="ml-5">Request for Services</h3>
      <div className="card ml-5 mr-5" style={{ background: "#fffff" }}>
        <div className="row">
          <div className="col">
            <div>
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`Name`}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter Name"
                  style={{ borderRadius: "0px" }}
                  value={Profileinfo?.name}
                  disabled
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`Service`}
                </Form.Label>
                <Select
                  options={userRoles}
                  value={selectedService}
                  onChange={(e) => {
                    setSelectedService(e);
                  }}
                ></Select>
              </Form.Group>
            </div>
          </div>
          <div className="col">
            <div>
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`User ID`}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter Name"
                  style={{ borderRadius: "0px" }}
                  disabled
                  value={user.ReferId}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`Remarks`}
                </Form.Label>
                <textarea
                  placeholder="Remarks"
                  style={{
                    padding: "5px",
                    width: "100%",
                    borderRadius: "0px",
                  }}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button
              className="btn-block rounded"
              style={{
                background: "rgb(86, 190, 137)",
                color: "white",
                border: "none",
              }}
              onClick={validateForm}
            >
              SUBMIT
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn-block rounded"
              style={{
                background: "red",
                color: "white",
                border: "none",
              }}
              onClick={clearForm}
            >
              CLEAR
            </button>
          </div>
          <div className="col-8"></div>
        </div>
      </div>
    </div>
  );
}

export default ServicesRequest;
