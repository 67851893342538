import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Badge } from "react-bootstrap";
import { FcNext, FcPrevious } from "react-icons/fc";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Link } from "react-router-dom";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaCodeBranch,
  FaDotCircle,
  FaExclamationCircle,
  FaFlag,
  FaHourglassHalf,
  FaPlus,
  FaUser,
} from "react-icons/fa";
import { GlobalContext } from "../../Context/GlobalState";
import { SubTasklist, SubTasklistAdmin } from "./SalaryTable";
import { AddsubTaskemployeeModal } from "./Modal";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { billpunchbasicauth } from "../../utils/Credentials";
const Subtaskadmin = () => {
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const startDate = new Date(taskdetails.task_startdate);
  const endDate = new Date(taskdetails.task_enddate);
  const [showaddsubtaskModal, setshowaddsubtaskModal] = useState(false);

  const { password , username } = billpunchbasicauth;
  const openaddsubtaskModal = () => {
    setshowaddsubtaskModal(true);
  };
  const closeaddsubtaskModal = () => {
    setshowaddsubtaskModal(false);
  };

  const formattedStartDate =
    startDate.getDate() +
    " " +
    startDate.toLocaleString("default", { month: "long" });
  const formattedEndDate =
    endDate.getDate() +
    " " +
    endDate.toLocaleString("default", { month: "long" });
  let statusStyle = {};
  if (taskdetails.status === "O") {
    statusStyle = {
      backgroundColor: "rgba(255, 46, 46, 0.1)",
      color: "#ff2e2e",
      border: "1px solid #ff2e2e",
      borderRadius: ".3rem",
    };
  } else if (taskdetails.status === "P") {
    statusStyle = {
      backgroundColor: "rgba(255, 255, 0, 0.1)",
      color: "#f7b12a",
      border: "1px solid #f7b12a",
      borderRadius: ".3rem",
    };
  } else if (taskdetails.status === "C") {
    statusStyle = {
      backgroundColor: "rgba(43, 193, 85, 0.1)",
      color: "#2bc155",
      border: "1px solid #2bc155",
      borderRadius: ".3rem",
    };
  }
  let priorityIcon;
  if (taskdetails.task_priority === "High") {
    priorityIcon = <FaFlag style={{ marginRight: "5px", color: "#f4c430" }} />;
  } else if (taskdetails.task_priority === "Urgent") {
    priorityIcon = <FaFlag style={{ marginRight: "5px", color: "maroon" }} />;
  } else if (taskdetails.task_priority === "Normal") {
    priorityIcon = <FaFlag style={{ marginRight: "5px", color: "blue" }} />;
  } else if (taskdetails.task_priority === "Low") {
    priorityIcon = <FaFlag style={{ marginRight: "5px", color: "grey" }} />;
  } else if (taskdetails.task_priority === "Clear") {
    priorityIcon = (
      <img
        src={require("../../../assets/images/flag_1.png")}
        style={{ marginRight: "5px", width: ".8rem" }}
      />
    );
  }
  let statusIcon;
  if (taskdetails.status === "O") {
    statusIcon = <FaExclamationCircle style={{ color: "red" }} />;
  } else if (taskdetails.status === "P") {
    statusIcon = <FaHourglassHalf style={{ color: "blue" }} />;
  } else if (taskdetails.status === "C") {
    statusIcon = <FaCheckCircle style={{ color: "green" }} />;
  }
  const [description, setDescription] = useState("");
  const [rows, setRows] = useState(3);

  const handleChange = (event) => {
    setDescription(event.target.value);
    if (event.target.scrollHeight > event.target.clientHeight) {
      setRows((prevRows) => prevRows + 1);
    }
  };

  const handleFocus = () => {
    setRows(7);
  };

  const handleBlur = () => {
    if (!description) {
      setRows(3);
      setShowSubmitButton(true);
    } else {
    }
  };

  const [showAllActivities, setShowAllActivities] = useState(false);

  const [activity, setActivity] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getActivity = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        task_id: taskdetails.pk_taskid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetRecentActivitiesSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setActivity(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getdesc = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        taskid: taskdetails.pk_taskid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetTaskDescripton`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data.length > 0) {
        setDescription(data.data[0].desc); // Set the description from API response
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleDescriptionFocus = () => {
    setShowSubmitButton(true);
  };

  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [sendActivity, setSendActivity] = useState("");

  const handleSubmitdesc = async (e) => {
    e.preventDefault();
    try {
      console.log("Inside handleSubmit");

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
        empid: user.userid,
        taskid: taskdetails.pk_taskid,
        desc: description,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("sending data to abhijeet");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskDescription`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
        setShowSubmitButton(false);
        getdesc();
        setRows(3);
      } else {
        swal("Success", data.result, "success");
        getdesc();
        setShowSubmitButton(false);
        setRows(3);
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    getActivity();
    getdesc();
  }, []);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <AddsubTaskemployeeModal
          show={showaddsubtaskModal}
          closeModal={closeaddsubtaskModal}
          // getSubsubTask={getTask}
        />
        <div className="d-flex justify-content-end px-3 pt-4">
          <Link to="/retailer/Task_management" style={{}}>
            Back
          </Link>
        </div>
        <div className="row px-4">
          <div className="col-md-6 col border-right border-muted">
            <h5>
              {" "}
              <FaCodeBranch /> {taskdetails.taskname}
            </h5>
            <div className="row">
              <div className="col">
                <FaDotCircle /> Status: &nbsp;&nbsp;&nbsp;
                <span className=" p-1 " style={statusStyle}>
                  {statusIcon} &nbsp;
                  {taskdetails.status === "O" && "Overdue"}
                  {taskdetails.status === "P" && "Pending"}
                  {taskdetails.status === "C" && "Complete"}
                </span>
              </div>
              <div className="col">
                <FaUser /> &nbsp;Assign: &nbsp;&nbsp;&nbsp;{" "}
                {taskdetails.task_assignee}
              </div>
            </div>
            <div className="row pt-md-4">
              <div className="col">
                <FaCalendarAlt /> &nbsp;Date : &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  className="p-2"
                  style={{ border: "1px solid grey", borderRadius: ".4rem" }}
                >
                  {formattedStartDate} - {formattedEndDate}
                </span>
              </div>
              <div className="col">
                <FaFlag /> &nbsp; Priority:&nbsp;&nbsp;&nbsp; {priorityIcon}{" "}
                {taskdetails.task_priority}
              </div>
            </div>
            <Form.Group className="pt-md-5 pt-2" controlId="descriptionField">
              <Form.Control
                as="textarea"
                rows={rows}
                value={description}
                onChange={handleChange}
                onFocus={() => {
                  handleFocus();
                  handleDescriptionFocus();
                }}
                onBlur={handleBlur}
                placeholder="📝 Add description"
              />
            </Form.Group>
            {rows === 7 && showSubmitButton && (
              <Button
                className="btn-sm"
                variant="primary"
                type="submit"
                onClick={handleSubmitdesc}
              >
                Submit
              </Button>
            )}
          </div>

          <div className="col-md-6 col " style={{ background: "" }}>
            <div className="row">
              <h5 className="col">Subtask</h5>
              <div className="col d-flex justify-content-end ">
                <FaPlus onClick={openaddsubtaskModal} />
              </div>
            </div>
            <SubTasklistAdmin />
          </div>
        </div>
        <div className="px-4 pb-4">
          <div className="row">
            <h5 className="col"> Recent Activity</h5>
          </div>

          <hr className="mt-0" />
          <div
            className="p-3"
            style={{
              background: "aliceblue",
            }}
          >
            {activity
              .slice(0, showAllActivities ? activity.length : 4)
              .map((item, index) => (
                <p className="p-2 m-0" key={index}>
                  &#8226; {item.namee}{" "}
                  {item.codee && (
                    <SyntaxHighlighter language="jsx" style={dracula}>
                      {item.codee}
                    </SyntaxHighlighter>
                  )}{" "}
                  <b>{item.rec_desc}</b> in {item.taskname} at{" "}
                  <b>{item.datee}</b>
                </p>
              ))}
            <div className="d-flex justify-content-center">
              <Link to="/retailer/Activity_Management">
                <FcPrevious />
                View More
                <FcNext />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subtaskadmin;
