import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import "./MyTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
export const columns = [
  {
    dataField: "merchantTransactionid",
    text: "Merchant",
    text1: " Txnt ID",
    color: "green",
  },
  {
    dataField: "transactiontype",
    text: "Transaction ",
    text1: "Type",
    color: "#000",
  },
  {
    dataField: "transactionAmount",
    text: "Amount",
    color: "#000",
  },
  {
    dataField: "cgstAmount",
    text: "CGST",
    text1: " Amount",
    color: "#000",
  },
  {
    dataField: "sgstAmount",
    text: "SGST ",
    text1: " Amount",
    color: "#000",
  },
  {
    dataField: "chargesAmount",
    text: "Charges",
    color: "#000",
  },
  {
    dataField: "debitAmount",
    text: "Debit",
    color: "red",
  },
  {
    dataField: "creditAmount",
    text: "Credit",
    color: "green",
  },
  {
    dataField: "balance",
    text: "Balance",
    color: "#000",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#000",
  },

  {
    dataField: "createdOn",
    text: "Created On",
    color: "#000",
  },
];

const statusFilterTypes = [
  { label: "Settlement Completed", value: "SettlementCompleted" },
  { label: "Settlement In Process", value: "SettlementInProcess" },
  { label: "Failed", value: "FAILED" },
  { label: "Pending", value: "Pending" },
  { label: "Accepted", value: "Accepted" },
];

const AllTransactionsTable = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [offset, setOffset] = useState(10);
  const [data, setData] = useState([]);
  const [statusType, setStatusType] = useState(null);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: 10,
      pageSize: 1000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Payments/GetTransactionsall`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data && data.length > 0) {
        const dataLoad = data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.beneAccountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        });
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setData(dataLoad);
        setDataToDisplay(dataLoad);
      }
      // setPayments(JSON.parse(body).data);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const filter = () => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        if (bank && statusType && bank.status.toString() == statusType.value) {
          b = true;
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  };

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };
  return (
    <div className="p-2 " style={{ backgroundColor:"#F2F6FC",}}>
      <div className="card m-0 p-0">
        <div className="row m-0 py-4 px-4   align-items-center justify-content-end">
          <div style={searchBarStyle} className="bg-light m-0 p-0 px-1">
            <FontAwesomeIcon
              icon={faSearch}
              style={iconStyle}
              className="ml-2"
            />
            <input
              type="text"
              className="form-control "
              placeholder="Search in Table.."
              aria-label="Search"
              style={inputStyle}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>

          <div
            className="pl-2 pr-1 p-0 m-0"
            style={{ width: "18%", height: "20%" }}
          >
            <Form.Group className="p-0 m-0">
              <Select
                placeholder="Transaction Status"
                options={statusFilterTypes}
                value={statusType}
                onChange={(e) => {
                  setStatusType(e);
                }}
              />
            </Form.Group>
          </div>
          <div
            className="px-1   p-0 m-0"
            style={{ width: "14%", height: "20%" }}
          >
            <Form.Group className="mt-0 p-0 m-0">
              <Button
                className="rounded-100 mt-4 btn btn-lg btn-block"
                onClick={filter}
              >
                {"Search"}
              </Button>
            </Form.Group>
          </div>
          <div
            className="m-0 p-0  hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Report_${dateFormatter()}`,
                excelMapping(dataToDisplay, columns)
              );
            }}
          >
            <img
              className="pl-1 pt-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.0rem`, height: "70%" }}
            />
            <div
              div
              style={{ color: "rgba(255, 255,255, 1)" }}
              className="hover-text bg-dark "
            >
              Export to Excel
            </div>
          </div>
        </div>

        <hr className="m-0 p-0" />

        <div className=" borderless-table">
          <div className="scroll-inner admin-fix-width px-1">
            <table className="my-table transparent-table text-center border-bottom ">
              <tr className="borderless-table text-center border-bottom">
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Sr"}
                </th>
                {columns.map((heading) => (
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{heading.text}</div>
                    <div>{heading.text1}</div>
                  </th>
                ))}
              </tr>
              {dataToDisplay.map((e, ind) => (
                <tr className="border-bottom">
                  <td
                    className="text-center m-0 p-0 pr-2 pt-2"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "0.8rem",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {ind + 1}
                  </td>
                  {columns.map((cell) =>
                    cell.dataField == "status" ? (
                      e[cell.dataField] == "success"  ||
                      e[cell.dataField] == "Received"||
                      e[cell.dataField] == "Accepted" 
                      
                      ? (
                        <td
                          className="text-center m-0 p-0 pr-2 pt-2"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <label
                            style={{
                              width: "80%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(43, 193, 85, 0.1)",
                              color: "rgba(43, 193, 85, 1)",
                              bordercolor: "rgba(43, 193, 85, 1)",
                            }}
                            className="p-2 text-center"
                          >
                            {e[cell.dataField]}
                          </label>{" "}
                        </td>
                      ) : e[cell.dataField] == "FAILED" ||
                        e[cell.dataField] == "Failed" ? (
                        <td
                          className="text-center m-0 p-0 pr-2 pt-2"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <label
                            style={{
                              width: "80%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(255, 46, 46, 0.1)",
                              color: "rgba(255, 46, 46, 1)",
                              bordercolor: "rgba(255, 46, 46, 1)",
                            }}
                            className="p-2"
                          >
                            {e[cell.dataField]}
                          </label>{" "}
                        </td>
                      ) : e[cell.dataField] == "SettlementCompleted" ? (
                        <td
                          className="text-center m-0 p-0 pr-2 pt-2"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              width: "80%",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(0, 116, 217, 0.1)",
                              color: " rgba(0, 116, 217, 1)",
                              bordercolor: "rgba(0, 116, 217, 1)",
                            }}
                            className="p-2"
                          >
                            {e[cell.dataField]}
                          </label>
                        </td>
                      ) : (
                        <td
                          className="text-center m-0 p-0 pr-2 pt-2"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              paddingLeft: "3px",
                              padding: "5px",
                              borderRadius: "5px",
                              border: "1px solid",
                              backgroundColor: "rgba(255, 164, 27, 0.1)",
                              color: " rgba(255, 164, 27, 1)",
                              bordercolor: " rgba(255, 164, 27, 1)",
                              width: "80%",
                            }}
                            className="p-2"
                          >
                            {e[cell.dataField]}
                          </label>
                        </td>
                      )
                    ) : (
                      <td
                        className="text-center m-0 p-0 pr-2 pt-2"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0em",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          if (cell.dataField == "transferUniqueNo") {
                            setDetails(e);
                          }
                        }}
                      >
                        {e[cell.dataField]}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default AllTransactionsTable;
