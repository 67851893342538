import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { CLIENT_BASIC_AUTH } from "../../utils/Client";
import { Newusermodal } from "../SalaryManagement/Modal";
import { IoIosArrowDropdown } from "react-icons/io";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const Navbar = () => {
  const { setUserInfo } = useContext(GlobalContext);
  const { setUserParent } = useContext(GlobalContext);
  const { user, userparent, setTransactionLoaderState, setUser } =
    useContext(GlobalContext);
  const [Wallet, setWallet] = useState(null);
  const [employeeFirms, setEmployeeFirms] = useState();
  const [Employeewallet, setEmployeewallet] = useState({});

  const { password, username } = CLIENT_BASIC_AUTH;

  const handleReload = () => {
    getWallet();
    getemployeeWallet();
  };
  const getWallet = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetFirmWalletBalance`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setWallet(responseData.data[0]);
        console.log(setWallet);
      } else {
        setWallet(null);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const getemployeeWallet = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        empid: user.userid,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetEmployeeWalletBalance`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setEmployeewallet(responseData.data[0]);
        console.log(setEmployeewallet);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (user && user.us_type === "A") {
      getWallet();
    } else if (user && user.us_type === "E") {
      getemployeeWallet();
    } else if (user && user.us_type == "H") {
      getemployeeWallet();
    }
  }, [user]);
  useEffect(() => {
    getWallet();
    getemployeeWallet();
  }, []);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  const Logout = () => {
    const callbackUrl = `${window.location.origin}`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}`;
    window.location.href = redirectUrl;
  };
  console.log(user);
  if (!user) {
    window.location.href = `https://accounts.bumppy.com/?callback=${window.location.origin}`;
  }
  const [newuser, setnewuser] = useState(false);
  const [firmDetails, setFirmDetails] = useState(null);
  const basicdetail = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmid: user.firm_id,
        parentid: userparent.parentAccountId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFirmBasicDetails`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.msg === "Success") {
        setFirmDetails(data.data[0]);
      } else {
        console.log("Error: ", data.msg);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetAllFirmEmployee = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllFirmEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeeFirms(data.data);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const handleFirmSelection = async (selectedFirmId) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        empid: user.userid,
        firmid: selectedFirmId,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllFirmDetailsEmployee`,
        requestOptions
      );
      const data = await response.json();

      if (data.responseCode === "00") {
        setFirmDetails(data.response); 
        setUserInfo(data.response);
      } else {
        console.log("Error: ", data.msg);
      }
    } catch (error) {
      console.error("Error fetching firm details:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    GetAllFirmEmployee();
  }, []);
  useEffect(() => {
    if (user && userparent) {
      basicdetail();
    }
  }, [user, userparent]);
  useEffect(() => {
    if (firmDetails && firmDetails.is_firstLogin === "Y") {
      setnewuser(true);
    }
  }, [firmDetails]);
  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.us_type == "" ? (
          <Redirect to={`/`} />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              >
                <Link>
                  <img
                    src={require("../../../assets/images/logoblack.png")}
                    alt="logo"
                    style={{
                      height: "2rem",
                      paddingRight: "1rem",
                      width: "auto",
                    }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-right">
                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Item className="dropdown-item preview-item">
                    <div className="navbar-profile">
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          flexDirection: "row",
                        }}
                      >
                         
                        <div>
                          {user.us_type === "A" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Firm Bal : ₹
                              </span>
                              {Wallet
                                ? Wallet.wl_balance !== undefined
                                  ? parseFloat(Wallet.wl_balance).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                          {user.us_type === "E" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Emp Bal : ₹
                              </span>
                              {Employeewallet
                                ? Employeewallet.wl_balance !== undefined
                                  ? parseFloat(
                                      Employeewallet.wl_balance
                                    ).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                          {user.us_type === "H" && (
                            <div>
                              <span style={{ paddingRight: "2px" }}>
                                {" "}
                                Emp Bal : ₹
                              </span>
                              {Employeewallet
                                ? Employeewallet.wl_balance !== undefined
                                  ? parseFloat(
                                      Employeewallet.wl_balance
                                    ).toFixed(2)
                                  : "Loading..."
                                : "Loading..."}
                              <span
                                className="menu-icon"
                                onClick={handleReload}
                              >
                                <i className="mdi mdi-reload"></i>
                              </span>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown>

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={state.user.profile_url}
                        alt="profile"
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />

                      <p className="mb-0 d-none d-sm-block navbar-profile-name text-dark">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {state.user.username}
                                </strong>
                              </span>
                              <strong
                                style={{ fontWeight: "800" }}
                                className="text-right"
                              >
                                {`${state.user.organization}`}
                              </strong>
                            </div>
                          )}
                        </Trans>
                      </p>
                    </div>
                    <IoIosArrowDropdown
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="right"
                    style={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      padding: "8px",
                    }}
                  >
                    {employeeFirms?.map((firm) => (
                      <Dropdown.Item
                        as="div"
                        key={firm.firmid}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <Form.Check
                          type="radio"
                          name="firmSelection"
                          label={firm.firm_name}
                          checked={firm.firmid === user.firm_id}
                          onChange={() => handleFirmSelection(firm.firmid)}
                        />
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={Logout}>
                      <Button size="sm" variant="danger">
                        Logout
                      </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
            <Newusermodal showModal={newuser} setShowModal={setnewuser} />
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;
