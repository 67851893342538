import React, { useState ,useContext} from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import LedgerReportTable from "./LedgerReportTable";
const LedgerReport2 = ({ agentId }) => {
  const { user } = useContext(GlobalContext);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (


          <div style={{ padding: "1rem" }} className="">
            <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
              <div className="card-body m-0 p-2">
                <div>

                  <LedgerReportTable agentId={user.ReferId} />
                </div>
              </div>
            </div>
          </div>


        )
      }
    </GlobalContext.Consumer>
  );
};

export default LedgerReport2;
