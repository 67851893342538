// import React, { useEffect, useState } from "react";
// import { Col, Row, Nav, Tab } from "react-bootstrap";
// import SalaryProceed from "./SalaryProceed";
// import "react-toastify/dist/ReactToastify.css";
// import ExpensesManagement from "./ExpensesManagement";
// import Paybills from "./PayBills";

// const Payroll = () => {
//   const [filterState, setFilterState] = useState(1);
//   useEffect(() => {}, []);

//   return (
//     <div>
//       <div className="tab-custom-pills-horizontal m-0 p-0 py-1">
//         <div className="   p-0 mt-0" style={{ borderRadius: ".4rem" }}>
//           <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//             <Row className="p-0 m-0">
//               <Col
//                 className="p-0 mt-md-2 mt-sm-1 "
//                 style={{ borderRadius: ".4rem", background: "#fff" }}
//                 sm={12}
//               >
//                 <Nav
//                   variant=""
//                   className="nav-pills-custom align-items-center mx-md-2 mx-sm-1 mt-md-3 mt-sm-2 mb-md-3 mb-sm-2"
//                   style={{
//                     background: "#f1f2f4",
//                     borderRadius: ".4rem",
//                     width: "18rem",
//                   }}
//                 >
//                   <Nav.Item className="mx-md-3 mx-sm-2 mt-md-3 mt-sm-2 mb-md-3 mb-sm-2 pr-1">
//                     <Nav.Link
//                       eventKey="first"
//                       className={`btn ${
//                         filterState === 1
//                           ? "btn-primary"
//                           : "btn-outline-primary"
//                       }`}
//                       onClick={() => {
//                         setFilterState(1);
//                       }}
//                       style={{
//                         fontSize: ".9rem",
//                         border: "none",
//                         padding: "10px",
//                         width: "7rem",
//                         boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//                       }}
//                     >
//                       <div>Expenses</div>
//                     </Nav.Link>
//                   </Nav.Item>
//                   <Nav.Item className="mx-1 pr-1">
//                     <Nav.Link
//                       eventKey="second"
//                       className={`btn ${
//                         filterState === 2
//                           ? "btn-primary"
//                           : "btn-outline-primary"
//                       }`}
//                       onClick={() => {
//                         setFilterState(2);
//                       }}
//                       style={{
//                         fontSize: ".9rem",
//                         border: "none",
//                         padding: "10px",
//                         width: "7rem",
//                         boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//                       }}
//                     >
//                       <div>Bills</div>
//                     </Nav.Link>
//                   </Nav.Item>
//                 </Nav>
//               </Col>
//               <Col xs={12} className="m-0 p-0 ">
//                 <Tab.Content className="mt-md-2 mt-sm-1 card tab-content-custom-pill pb-0">
//                   {/* <Tab.Pane eventKey="first">

//                     <ExpensesManagement />
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="second">
//                     <Paybills />
//                   </Tab.Pane> */}

//                   {filterState === 1 && <ExpensesManagement />}
//                   {filterState === 2 && <Paybills />}
//                 </Tab.Content>
//               </Col>
//             </Row>
//           </Tab.Container>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Payroll;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../custom-hooks/useQuery";
import Expenses from "./Expense";
import { ReviewBillTable, ReviewBillTableAdmin } from "./SalaryTable";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const ExpenseDashboard = () => {
  const query = useQuery();
  const id = query.get("id");
  const CompanyName = sessionStorage.getItem("CompanyName");
  const [activeLink, setActiveLink] = useState("expenses");

  const renderContent = () => {
    switch (activeLink) {
      case "expenses":
        return <Expenses />;
      case "bills":
        return <ReviewBillTableAdmin />;
      default:
        return null;
    }
  };

  return (
    <>
      <nav className=" navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse mx-md-4 mx-sm-2"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "expenses" && "active"
                }`}
                onClick={() => setActiveLink("expenses")}
              >
                Expenses
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link green-link ${
                  activeLink === "bills" && "active"
                }`}
                onClick={() => setActiveLink("bills")}
              >
                Bills
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {renderContent()}
    </>
  );
};

export default ExpenseDashboard;
