export default (domain) => {
  switch (domain) {
    case "bumppypay.com":
      return {
        CLIENT_CODE: "1002",
        BASE_URL_AEPS : `https://bumppy.com/apibumppypay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "Rajneesh@example.com",
          password: "Test@12345",
        },
        COMPANY_RETAILER_COMPANY_ID: "9",
        AEPS_SUPER_MERCHANT_ID: 1179,
        CLIENT_PAN_NUMBER: "AONPR1320R",
        theme: {
          colorDashboardBallenceCard: "#3A6BEB",
          colorDashboardTableHeadingBackground:"#F0F0F0",

          colorQrPlaceHolder: "#FFF",

          colorLoginScreenStripe: "#3A6BEB",

          colorQrPlaceHolderSecondary: "#000",

          colorDashboardTableHeader: "#04743E",
          colorBackgroundBody: "//#endregionF1F2F4",
        },
        COMPANY_NAME: "Bumppy Pay",
        COMPANY_NAME_NAVBAR: "BUMPPY PAYMENTS",
        COMPANY_LOGIN_NAME: "BUMPPY PAYMENTS",
        PRIVACY_POLICY_COMPANY_NAME: "Bumppy Payments",
        QR_TITLE_COMPANY_NAME: "Bumppy Payments",    
        CERTIFICATE_COMPANY_NAME: "Bumppy Payments",
        CERTIFICATE_ADDRESS: "B-218,Ithum Tower Second Floor, Sector-62,Noida",
        CERTIFICATE_WEBSITE: "https://dashboard.bumppy.com",
        LOGIN_COMPANY_NAME: "Bumppy Payments",
        OFFLINE_TEXT: "Offline",
        ONLINE_TEXT: "Instant",

        SUPPORT_MOBILE_NUMBER: "+91 8130096177",
        SUPPORT_EMAIL: "info@bumppy.com",
      };

    case "finpe.co.in":
      return {
        CLIENT_CODE: "1035",
        BASE_URL_AEPS : `https://bumppy.com/apibumppypay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "info@finpe.co.in",
          password: "Demo1@12345",
        },
        COMPANY_RETAILER_COMPANY_ID: "7",
        AEPS_SUPER_MERCHANT_ID: 1179,
        CLIENT_PAN_NUMBER: "AVDPK7914M",
        theme: {
          colorDashboardBallenceCard: "#003F7D",
          colorDashboardTableHeadingBackground:"#485579",

          colorQrPlaceHolder: "#003F7D",

          colorLoginScreenStripe: "#003F7D",

          colorQrPlaceHolderSecondary: "#003F7D",

          colorDashboardTableHeader: "#04743E",
          colorBackgroundBody: "aliceblue",
        },
        COMPANY_NAME: "FINPE",
        COMPANY_NAME_NAVBAR: "Finpe",
        COMPANY_LOGIN_NAME: "FINPE",
        PRIVACY_POLICY_COMPANY_NAME: "Finpe",
        QR_TITLE_COMPANY_NAME: "Finpe",
        OFFLINE_TEXT: "Offline",
        ONLINE_TEXT: "Instant",

        SUPPORT_MOBILE_NUMBER: "Support Number",
      };

    case "paymin.in":
      return {
        CLIENT_CODE: "1033",
        BASE_URL_AEPS : `https://bumppy.com/apibumppypay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "admin@paymin.in",
          password: "qYM7eCP@12345",
        },
        COMPANY_RETAILER_COMPANY_ID: "11",
        AEPS_SUPER_MERCHANT_ID: 1179,
        CLIENT_PAN_NUMBER: "AVDPK7914M",
        theme: {
          colorDashboardBallenceCard: "#5F9DF7",
          colorDashboardTableHeadingBackground:"#485579",

          colorQrPlaceHolder: "#159BC7",

          colorLoginScreenStripe: "#3D58A7",

          colorQrPlaceHolderSecondary: "#159BC7",

          colorDashboardTableHeader: "#04743E",
          colorBackgroundBody: "aliceblue",
        },
        COMPANY_NAME: "Pay Mine",
        COMPANY_NAME_NAVBAR: "PAYMINE",
        COMPANY_LOGIN_NAME: "PAYMINE",
        PRIVACY_POLICY_COMPANY_NAME: "Pay Mine",
        QR_TITLE_COMPANY_NAME: "Pay Mine",
        OFFLINE_TEXT: "Offline",
        ONLINE_TEXT: "Instant",

        SUPPORT_MOBILE_NUMBER: "Support Number",
      };

    case "lovepaisa.com":
      return {
        CLIENT_CODE: "1034",
        BASE_URL_AEPS : `https://bumppy.com/apibumppypay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "admin@lovepaisa.com",
          password: "cGdHwESWpoKH@12345",
        },
        COMPANY_RETAILER_COMPANY_ID: "12",
        AEPS_SUPER_MERCHANT_ID: 1179,
        CLIENT_PAN_NUMBER: "COKPP3101D",
        theme: {
          colorDashboardBallenceCard: "#EF833A",
          colorDashboardTableHeadingBackground:"#485579",

          colorQrPlaceHolder: "#04743E",

          colorLoginScreenStripe: "#04743E",

          colorQrPlaceHolderSecondary: "#EF833A",

          colorDashboardTableHeader: "#04743E",
          colorBackgroundBody: "aliceblue",
        },
        COMPANY_NAME: "LOVE PAISA",
        COMPANY_NAME_NAVBAR: "Love Paisa",
        COMPANY_LOGIN_NAME: "LOVEPAISA",
        PRIVACY_POLICY_COMPANY_NAME: "Love Paisa",
        QR_TITLE_COMPANY_NAME: "Love Paisa",
        OFFLINE_TEXT: "Hybrid",
        ONLINE_TEXT: "Instant",

        SUPPORT_MOBILE_NUMBER: "Support Number",
      };

    case "pemore.in":
      return {
        CLIENT_CODE: "1016",
        BASE_URL_AEPS : `https://bumppy.com/apibumppypay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "admin@pemore.in",
          password: "Pemore@11221122",
        },
        COMPANY_RETAILER_COMPANY_ID: "6",
        AEPS_SUPER_MERCHANT_ID: 1179,
        CLIENT_PAN_NUMBER: "AKJPG1371K",
        theme: {
          colorDashboardBallenceCard: "#5F9DF7",
          colorDashboardTableHeadingBackground:"#485579",

          colorQrPlaceHolder: "#159BC7",

          colorLoginScreenStripe: "#010147",

          colorQrPlaceHolderSecondary: "#010147",

          colorDashboardTableHeader: "#04743E",

          colorBackgroundBody: "aliceblue",
        },
        COMPANY_NAME: "PE MORE",
        COMPANY_NAME_NAVBAR: "Pe More",
        COMPANY_LOGIN_NAME: "PEMORE",
        PRIVACY_POLICY_COMPANY_NAME: "Pe More",
        QR_TITLE_COMPANY_NAME: "Pe More",
        OFFLINE_TEXT: "Hybrid",
        ONLINE_TEXT: "Instant",

        SUPPORT_MOBILE_NUMBER: "Support Number",
      };

    case "remittpe.com":
      return {
        CLIENT_CODE: "1014",
        BASE_URL_AEPS : `https://bumppy.com/apifingpay/v1/web/fingpay`,
        CLIENT_BASIC_AUTH: {
          username: "admin@royalpe.co.in",
          password: "Royalpe@112211",
        },
        COMPANY_RETAILER_COMPANY_ID: "5",
        AEPS_SUPER_MERCHANT_ID: 1153,
        CLIENT_PAN_NUMBER: "AFYPC2479N",
        theme: {
          colorDashboardBallenceCard: "#2E539F",
          colorDashboardTableHeadingBackground:"#485579",

          colorQrPlaceHolder: "#2E539F",

          colorLoginScreenStripe: "#2E539F",

          colorQrPlaceHolderSecondary: "#2E539F",

          colorDashboardTableHeader: "#04743E",

          colorBackgroundBody: "aliceblue",
        },
        COMPANY_NAME: "ROYALPE",
        COMPANY_NAME_NAVBAR: "Royal Pe",
        COMPANY_LOGIN_NAME: "ROYALPE",
        PRIVACY_POLICY_COMPANY_NAME: "Royal Pe",
        QR_TITLE_COMPANY_NAME: "Royal Pe",
        OFFLINE_TEXT: "Online",
        ONLINE_TEXT: "Offline",

        SUPPORT_MOBILE_NUMBER: "011-49090549",
      };

    default:
      return {};
  }
};
