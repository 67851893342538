import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { SalaryExpenseAdminModal, SalaryExpenseEmployeeModal, SalaryExpenseModal } from "./Modal";
import { useHistory } from "react-router";

import { GlobalContext } from "../../Context/GlobalState";
const ExpensesEmployee = () => {
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  const openExpenseModal = () => {
    setIsExpenseModalOpen(true);
  };

  const closeExpenseModal = () => {
    setIsExpenseModalOpen(false);
  };

  const doughnutData = {
    labels: ["Category 1", "Category 2", "Category 3"],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ["#40E0D0", "#5D8AA8", "#004F98"],
      },
    ],
  };
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    elements: {
      arc: {
        borderWidth: 0.8,
      },
    },
    cutoutPercentage: 70,
  };
  const history = useHistory();
  const Viewexpanse = () => history.push("/retailer/Viewexpanseemployee");


  const { accessDataemp } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessDataemp);
  }, [accessDataemp]);

  return (
    <>
      <div className="row mt-md-3 mt-sm-3 p-0 mt-sm-3">
        <div className="col-md-6 col-12 mx-md-5 mx-sm-3 mb-sm-3  ">
          <h1>
            <b> All your expenses in one place</b>
          </h1>

          <p style={{ fontSize: "1rem" }}>
            Save hours of work by tracking expenses
          </p>
          <div className="row m-0 p-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp; Add & Manage your expenses here
            </p>
          </div>
          <div className="row p-0 m-0">
          <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>#</b>
            </h2>
            <p
              className="d-flex d-flex align-items-center col m-0 p-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  Organize your expenses into categories
            </p>
          </div>
          <div className="row p-0 m-0 ">
            <h2 className="col-md-1 col-sm-2" style={{ color: "#2ca01c" }}>
              <b>@</b>
            </h2>
            <p
              className="d-flex align-items-center col p-0 m-0"
              style={{ fontSize: "1rem", color: "#000" }}
            >
              &nbsp;  See where you're spending your money
            </p>
          </div>
          <div className="mt-md-3 mt-sm-2">
             {accessDataemp &&
                  accessDataemp.map(
                    (module) =>
                      module.data &&
                      module.data.map((role) =>
                        role.role_name === "Add Expense" ? (
                          role.access === "Y" ? (
                            <span
                              key={role.pk_role_id}
                             
                            >
                            <button
                type="button"
                className="btn btn-sm d-flex align-items-center justify-content-center "
                style={{
                  background: "#2ca01c",
                  height: "2.5em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openExpenseModal}
              >
                Add expense manually
              </button>
                            </span>
                          ) : (
                            <span
                              key={role.pk_role_id}
                            >
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  color: "#fff",
                                  height: "2rem",
                                  border: "none",
                                  borderRadius: ".4rem",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: ".8rem",
                                  backgroundColor: "gray",
                                  cursor: "not-allowed",
                                }}
                                disabled
                              >
                           Add expense manually
                              </button>
                            </span>
                          )
                        ) : null
                      )
                  )}
              {/* <button
                type="button"
                className="btn btn-sm d-flex align-items-center justify-content-center "
                style={{
                  background: "#2ca01c",
                  height: "2.5em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={openExpenseModal}
              >
                Add expense manually
              </button> */}
          </div>
          <div className="mt-md-3 mb-2 mt-2 mt-sm-2">
            {" "}
              <button
                type="button" 
                className="btn btn-sm d-flex align-items-center justify-content-center"
                style={{
                  background: "#2ca01c",
                  height: "2.5em",
                  border: "None",
                  color: "#fff",
                }}
                onClick={Viewexpanse}
              >
                View expense
              </button>
          </div>
        </div>

        <div className="col mr-md-4 mr-sm-3 d-flex justify-content-center">
          
          <div
            className=""
            style={{
              background: "#fff",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h6 style={{ color: "grey" }} className="m-3">
              SPENDING LAST MONTH
            </h6>
            <h5 style={{ color: "grey" }} className="m-3">
              ₹1222
            </h5>
            {/* {customLegend} */}
            
            <Doughnut data={doughnutData} options={doughnutOptions} />

            <SalaryExpenseEmployeeModal isOpen={isExpenseModalOpen} closeModal={closeExpenseModal} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesEmployee;
