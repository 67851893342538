import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Form } from 'react-bootstrap';
import { GlobalContext } from '../../Context/GlobalState';
import { BASE_URL_API_TRANXT_UAT_API_GOSEO } from '../../utils/Constants';

const Blogs = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState("tab1");
  const [postTempId, setPostTempId] = useState("");
  const Editblogid = JSON.parse(sessionStorage.getItem("Editblogid"));
  const [editblogdata, setEditblogdata] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const Geteditloadblog = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ p_post_id: Editblogid.post_id }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/LoadBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setPostTempId(data.result[0].post_tempid); 
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Geteditblog = async () => {
    if (!postTempId) return; 
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ tempid: postTempId }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API_GOSEO}/api/Seo/GetBlogForEditing`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        // Sort the data by block_order
        const sortedData = data.data.sort((a, b) => a.block_order - b.block_order);

        // Format the sorted data for Quill
        const formattedContent = formatContentForQuill(sortedData);
        setEditblogdata(formattedContent);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Geteditloadblog();
  }, []);

  useEffect(() => {
    if (postTempId) {
      Geteditblog(); 
    }
  }, [postTempId]);

  return (
    <>
      <div className="p-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
              style={activeTab === "tab1" ? { border: "" } : {}}
              onClick={() => handleTabClick("tab1")}
            >
              Create Blogs
            </button>
          </li>
        </ul>
        <div className="tab-content p-1">
          {activeTab === "tab1" && <AddBlog content={editblogdata} />}
        </div>
      </div>
    </>
  );
};

const AddBlog = ({ content }) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [editorContent, setEditorContent] = useState(content);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    setEditorContent(content);
  }, [content]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditorContent(`<img src="${reader.result}" alt="Uploaded Image" />`);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFilenameChange = (event) => {
    setFilename(event.target.value);
  };

  const handleToolbarButtonClick = (name) => {
    setModalTitle(name);
    setModalShow(true);
  };

  const editorModules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6] }],
        [{ 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }, { 'direction': 'rtl' }],
        ['code-block'],
        ['clean']
      ],
      handlers: {
        'link': () => handleToolbarButtonClick('Link'),
        'image': () => handleToolbarButtonClick('Image'),
        'video': () => handleToolbarButtonClick('Video'),
      }
    },
  };

  const handleEditorChange = (value) => {
    setEditorContent(value); // Store the HTML content
  };

  const handleLogContent = () => {
    console.log(editorContent); // This will log the HTML format
  };

  // Save content as an HTML file
  const handleSaveAsHtml = () => {
    const blob = new Blob([editorContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'blog-content.html';
    a.click();
    URL.revokeObjectURL(url); // Clean up the URL object
  };

  return (
    <>
         <button className='btn btn-primary mt-3' onClick={handleLogContent}>
        Log HTML Content
      </button>

      <button className='btn btn-success mt-3 ml-2' onClick={handleSaveAsHtml}>
        Save as HTML
      </button>
      <div className=''>
      <div className='mt-3'>
        <ReactQuill 
          value={editorContent} 
          modules={editorModules}
          onChange={handleEditorChange}
          placeholder="Write something..."
          style={{height:"70vh"}}
        />
      </div>
     
      <CustomModal 
        show={modalShow} 
        handleClose={() => setModalShow(false)} 
        title={modalTitle}
        filename={filename}
        handleFilenameChange={handleFilenameChange}
        handleFileChange={handleFileChange}
      />
    </div>
    </>

  );
};

const CustomModal = ({ show, handleClose, title, filename, handleFilenameChange, handleFileChange }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <Form.Control className='col' type="text" placeholder="Filename" value={filename} onChange={handleFilenameChange} />
          <Form.Group className='col d-flex align-items-center'>
            <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
const formatContentForQuill = (data) => {
  const sortedData = data.sort((a, b) => parseInt(a.block_order) - parseInt(b.block_order));
  return sortedData.flatMap(block => {
    switch (block.block_type) {
      case 'h1':
        return [
          { insert: block.block_content, attributes: { header: 1 } },
          { insert: '\n' } 
        ];
      case 'h2':
        return [
          { insert: block.block_content, attributes: { header: 2 } },
          { insert: '\n' }
        ];
      case 'h3':
        return [
          { insert: block.block_content, attributes: { header: 3 } },
          { insert: '\n' } 
        ];
      case 'h4':
        return [
          { insert: block.block_content, attributes: { header: 4 } },
          { insert: '\n' } 
        ];
      case 'h5':
        return [
          { insert: block.block_content, attributes: { header: 5 } },
          { insert: '\n' } 
        ];
      case 'h6':
        return [
          { insert: block.block_content, attributes: { header: 6 } },
          { insert: '\n' } 
        ];
      case 'paragraph':
        return [
          { insert: block.block_content },
          { insert: '\n' } 
        ];
      case 'uploadedImage':
        return [
          { insert: { image: block.block_image } },
          { insert: '\n' } 
        ];
      case 'quote':
        return [
          { insert: block.block_content, attributes: { blockquote: true } },
          { insert: '\n' } 
        ];
      case 'link':
        return [
          { insert: block.block_content, attributes: { link: block.block_content } },
          { insert: '\n' } 
        ];
      case 'uploadedVideo':
        return [
          { insert: { video: block.block_image } },
          { insert: '\n' } 
        ];
      case 'unorderedList':
        const itemsUnordered = block.block_content.split('\n').map(item => ({
          insert: item,
          attributes: { list: 'bullet' }
        }));
        return [
          ...itemsUnordered,
          { insert: '\n' } 
        ];
      case 'numberedList':
        const itemsOrdered = block.block_content.split('\n').map(item => ({
          insert: item,
          attributes: { list: 'ordered' }
        }));
        return [
          ...itemsOrdered,
          { insert: '\n' }
        ];
      default:
        return [
          { insert: block.block_content },
          { insert: '\n' } 
        ];
    }
  });
};


export default Blogs;
