import React, { useContext, useState } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import Error404 from "../../error-pages/Error404";
import { ContextMenuTrigger } from "react-contextmenu";
import { GlobalContext } from "../../Context/GlobalState";
import CompanyLogo from "../../../assets/images/payrolllogo.png";
import swal from "sweetalert";
import SimpleCaptcha from "./captha";
const Signup = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [textOtp, setTextOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const { setUserInfo } = useContext(GlobalContext);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
    mobile: "",
    website: "",
    confirmPassword: "",
  });
  const handleCaptchaSuccess = () => {
    setCaptchaValid(true);
    setError("");
  };
  const [errors, setErrors] = useState({});
  const showPasswordClick = () => {
    setPassShow(!passShow);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!state.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!state.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    }
    if (!state.email.trim()) {
      validationErrors.email = "Email is required";
    }
    if (!state.password.trim()) {
      validationErrors.password = "Password is required";
    }

    if (!state.confirmPassword.trim()) {
      validationErrors.confirmPassword = "Confirm Password is required";
    }

    if (state.password.trim() !== state.confirmPassword.trim()) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    swal("Success", "Sign up successfully", "success");
  };
  return (
    <>
      <GlobalContext.Consumer>
        {/* {(context) =>
          true ? ( */}
            <div
              className="m-0 p-0"
              style={{
                height: "100vh",
                // background: "linear-gradient(to right, #EBEEF1, #EBEEF1)",
              }}
            >
              <div className="row justify-content-center">
                <img alt="Billpunch"
                  src={CompanyLogo}
                  style={{ height: "3rem", width: "auto", color: "#EC5665" }}
                  className="mx-1"
                />
              </div>

              <div className="row">
                <div
                  className="col-lg-4 col-md-8 col-sm-10 mx-auto"
                  style={{ maxWidth: "30rem" }}
                >
                  <div
                    className="card text-left p-0"
                    style={{ border: "1px solid #c7c7c7" }}
                  >
                    <h5 className="font-weight-bold mx-4 my-2 text-center">
                      Open your Payroll Account Now
                    </h5>
                    <p className="text-center">
                      Already signed up?{" "}
                      <Link to="/Loginpayroll">
                        <a>Log in</a>
                      </Link>
                    </p>

                    <form onSubmit={handleSubmit} className="px-4 py-2">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Your Full Name"
                          className="form-control"
                          value={state.name}
                          onChange={(e) => {
                            setState({ ...state, name: e.target.value });
                          }}
                        />{" "}
                        {errors.name && (
                          <span
                            style={{ fontSize: ".8rem" }}
                            className="text-danger"
                          >
                            {errors.name}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          maxLength={10}
                          placeholder="Your Enter Mobile Number"
                          className="form-control"
                          value={state.mobile}
                          onChange={(e) => {
                            setState({ ...state, mobile: e.target.value });
                          }}
                        />
                        {errors.mobile && (
                          <span
                            style={{ fontSize: ".8rem" }}
                            className="text-danger"
                          >
                            {errors.mobile}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Your Email address"
                          className="form-control"
                          value={state.email}
                          onChange={(e) => {
                            setState({ ...state, email: e.target.value });
                          }}
                        />
                        {errors.email && (
                          <span
                            style={{ fontSize: ".8rem" }}
                            className="text-danger"
                          >
                            {errors.email}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend bg-transparent">
                            <span className="input-group-text bg-transparent border-right-0 no-glow">
                              <i className="mdi mdi-lock-outline text-primary"></i>
                            </span>
                          </div>
                          <input
                            type={passShow ? "text" : "password"}
                            className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                            id="exampleInputPassword"
                            name="password"
                            placeholder="Create Password"
                            value={state.password}
                            onChange={handleChange}
                          />

                          <div className="input-group-prepend bg-transparent">
                            <span
                              className="input-group-text bg-transparent border-left-0 no-glow"
                              onClick={showPasswordClick}
                            >
                              <i
                                className={`mdi text-primary ${passShow ? "mdi-eye" : "mdi-eye-off"
                                  }`}
                              ></i>
                            </span>
                          </div>
                        </div>
                        {errors.password && (
                          <span
                            style={{ fontSize: ".8rem" }}
                            className="text-danger"
                          >
                            {errors.password}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <input
                          type={passShow ? "text" : "password"}
                          className="form-control "
                          id="exampleInputConfirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={state.confirmPassword}
                          onChange={handleChange}
                        />
                        {errors.confirmPassword && (
                          <span
                            style={{ fontSize: ".8rem" }}
                            className="text-danger"
                          >
                            {errors.confirmPassword}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <SimpleCaptcha
                          onCaptchaSuccess={handleCaptchaSuccess}
                        />{" "}

                      </div>
                      {error && <Invalid text={error} />}
                      {otpSent && (
                        <React.Fragment>
                          <div className="row m-0 p-0">
                            <div className="col p-0 m-0">
                              <div className="form-group m-0">
                                <div className="input-group">
                                  <div className="input-group-prepend bg-transparent">
                                    <span className="input-group-text bg-transparent border-right-0 no-glow">
                                      <i className="mdi mdi-lock-outline text-primary"></i>
                                    </span>
                                  </div>
                                  <input
                                    type=""
                                    className="form-control form-control-lg border-left-0 no-glow"
                                    id="exampleInputPassword"
                                    placeholder="Enter OTP"
                                    onChange={(e) => {
                                      setTextOtp(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col px-2 m-0">
                              <div
                                style={{ backgroundColor: "#00A452" }}
                                className="rounded-0 btn btn-block btn-primary m-0 btn-lg"
                              >
                                Resend OTP
                              </div>
                            </div>
                          </div>

                          <button className="btn btn-primary btn-block">
                            Submit
                          </button>
                        </React.Fragment>
                      )}
                      {!otpSent && (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Submit
                        </button>
                      )}
                    </form>
                    <div
                      className="row justify-content-center p-2 m-0"
                      style={{ background: "#EBEEF1", fontSize: ".7rem" }}
                    >
                      Invisible reCAPTCHA by Google &nbsp;
                      <a href="/terms-and-conditions">
                        Privacy Policy and Terms of Use.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <div className="row justify-content-center">
                <div className="" style={{ fontSize: ".7rem" }}>
                  <a
                    href="https://www.bumppy.com/privacy-policy/"
                    className="mx-1"
                  >
                    Legal
                  </a>
                  <a
                    href="https://www.bumppy.com/privacy-policy/"
                    className="mx-1"
                  >
                    Privacy
                  </a>
                  <a href="/terms-and-conditions" className="mx-1">
                    Security
                  </a>
                </div>
              </div> */}
              <div className="row w-100">
                <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                  {/* <p className="text-center" style={{ fontSize: ".7rem" }}>
                    © 2023 Bumppy. All rights reserved. Bumppy, Payments,
                    Chaafo, Travel, Media are registered trademarks of Bumppy
                    Inc.
                  </p> */}
                  <p className="text-center" style={{ fontSize: ".7rem" }}>
                    Terms and conditions, features, support, pricing, and
                    service options subject to change without notice.
                  </p>
                </div>
              </div>
            </div>
          {/* ) : context.user && context.user.UserType === "2" ? (
            <Redirect to="/retailer/dashboard" />
          ) : context.user && context.user.UserType === "3" ? (
            <Redirect to="/distributor/dashboard" />
          ) : context.user && context.user.UserType === "4" ? (
            <Redirect to="/master-distributor/dashboard" />
          ) : context.user && context.user.UserType === "1" ? (
            <Redirect to="/consumer/dashboard" />
          ) : (
            <Error404 />
          )
        } */}
      </GlobalContext.Consumer>
    </>
  );
};

export default Signup;
const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger my-1">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};
