import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Badge,
  Popover,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";

import { Link } from "react-router-dom";
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckCircle,
  FaCode,
  FaExclamationCircle,
  FaFlag,
  FaHourglassHalf,
  FaSearch,
} from "react-icons/fa";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import swal from "sweetalert";
import { IoIosAttach, IoIosSend } from "react-icons/io";
import { CiImageOn } from "react-icons/ci";
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { billpunchbasicauth } from "../../utils/Credentials";

const Activity = () => {
  const fileInputRef = useRef(null);

  const { password , username } = billpunchbasicauth;
  const handleFileSelection = () => {
    fileInputRef.current.click();
  };

  const toggleCodeModal = () => {
    setShowCodeModal(!showCodeModal);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName("");
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

 
  const popover = (
    <Popover style={{ background: "#fff" }} id="attachment-popover">
      <Popover.Content style={{ background: "#fff" }}>
        <CiImageOn
          onClick={handleFileSelection}
          className="mx-2"
          style={{ fontSize: "1.4rem", cursor: "pointer" }}
        />

        <FaCode onClick={toggleCodeModal} style={{ fontSize: "1.4rem" }} />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Popover.Content>
    </Popover>
  );
  const taskdetails = JSON.parse(sessionStorage.getItem("taskdetails"));
  const propertyobject = JSON.parse(sessionStorage.getItem("property"));

  let statusStyle = {};
  if (taskdetails.status === "O") {
    statusStyle = {
      backgroundColor: "rgba(255, 46, 46, 0.1)",
      color: "#ff2e2e",
      border: "1px solid #ff2e2e",
      borderRadius: ".3rem",
    };
  } else if (taskdetails.status === "P") {
    statusStyle = {
      backgroundColor: "rgba(255, 255, 0, 0.1)",
      color: "#f7b12a",
      border: "1px solid #f7b12a",
      borderRadius: ".3rem",
    };
  } else if (taskdetails.status === "C") {
    statusStyle = {
      backgroundColor: "rgba(43, 193, 85, 0.1)",
      color: "#2bc155",
      border: "1px solid #2bc155",
      borderRadius: ".3rem",
    };
  }

  let statusIcon;
  if (taskdetails.status === "O") {
    statusIcon = <FaExclamationCircle style={{ color: "red" }} />;
  } else if (taskdetails.status === "P") {
    statusIcon = <FaHourglassHalf style={{ color: "blue" }} />;
  } else if (taskdetails.status === "C") {
    statusIcon = <FaCheckCircle style={{ color: "green" }} />;
  }

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const [showAllActivities, setShowAllActivities] = useState(false);

  const [activity, setActivity] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getActivity = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        task_id: taskdetails.pk_taskid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetRecentActivitiesSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setActivity(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  const [sendActivity, setSendActivity] = useState("");
  const convertToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          const binary = [...array]
            .map((byte) => String.fromCharCode(byte))
            .join("");
          const byteArray = window.btoa(binary);
          resolve(byteArray);
        }
      };
      reader.onerror = reject;
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let byteArray = "";
    if (selectedFile) {
      byteArray = await convertToByteArray(selectedFile);
    }
  
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
        empid: user.userid,
        taskid: taskdetails.pk_taskid,
        desc: sendActivity,
        code: userCode,
        image_byte: byteArray,
        image_name: selectedFile ? selectedFile.name : "",
        image_type: selectedFile ? selectedFile.type : "jpeg",
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/AddTaskActivities`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.msg, "error");
        getActivity();
        setSendActivity(""); 
        setSelectedFile(null); 
        setFileName(""); 
        setUserCode(""); 
      } else {
        swal("Success", data.msg, "success");
        getActivity();
        setSendActivity(""); 
        setSelectedFile(null);
        setFileName(""); 
        setUserCode(""); 
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const filteredActivity = activity.filter((item) => {
    return (
      item?.namee?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.codee?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.rec_desc?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.taskname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.datee?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const [userCode, setUserCode] = useState("");
  const [highlightedCode, setHighlightedCode] = useState("");

  const textareaRef = useRef(null);

  useEffect(() => {
    if (userCode) {
      setHighlightedCode(
        <SyntaxHighlighter language="jsx" style={vs}>
          {userCode}
        </SyntaxHighlighter>
      );
    }
  }, [userCode]);

  const handleCodeChange = (event) => {
    setUserCode(event.target.value);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    setUserCode(pastedData);
  };

  useEffect(() => {
    getActivity();
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end px-3 pt-3">
        <Link to="/retailer/Subtask_management" style={{}}>
          Back
        </Link>
      </div>
      <div className="row">
        <h5 className="col"> Recent Activity</h5>

        <div className="col d-flex justify-content-end align-items-center">
          {isSearchOpen ? (
            // <input
            //   className="form-control"
            //   type="text"
            //   placeholder="Search..."
            //   autoFocus
            //   onBlur={() => setIsSearchOpen(false)}
            // />
            <input
            className="form-control"
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            autoFocus
            onBlur={() => setIsSearchOpen(false)}
          />
          ) : (
            <FaSearch onClick={toggleSearch} style={{ cursor: "pointer" }} />
          )}
        </div>
      </div>

      <hr className="mt-0" />
      <div
        className="custom-scrollbaractivity"
        style={{
          height: "50vh",
          overflow: "auto",
          background: "aliceblue",
        }}
      >
        {filteredActivity.length > 2 && (
          <Badge onClick={() => setShowAllActivities(!showAllActivities)}>
            {showAllActivities ? <FaCaretUp /> : <FaCaretDown />}
            &nbsp;{showAllActivities ? "Show Less" : "Show More"}
          </Badge>
        )}
        {filteredActivity
          .slice(0, showAllActivities ? filteredActivity.length : 7)
          .map((item, index) => (
            <>
             
               <p className="p-2 m-0" key={index}>
                &#8226; {item.namee} {item.codee && <SyntaxHighlighter language="jsx" style={dracula}>{item.codee}</SyntaxHighlighter>}{" "}
                <b>{item.rec_desc}</b> in {item.taskname} at <b>{item.datee}</b>
              </p>
            </>
          ))}
      </div>

      <Form style={{ background: "aliceblue" }} className="p-4">
        <Form.Group
          controlId="sendActivityField"
          style={{ position: "relative" }}
        >
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popover}
            rootClose
          >
            <div
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <IoIosAttach style={{ color: "#0096FF", cursor: "pointer" }} />
            </div>
          </OverlayTrigger>
          {fileName && (
            <div style={{ margin: "10px 0" }}>
              <strong>Attached File:</strong> {fileName}
            </div>
          )}
          <IoIosSend
            style={{
              color: "#0096FF",
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            onClick={handleSubmit}
          />
          <Form.Control
            as="textarea"
            placeholder="Write a Comment ..."
            value={sendActivity}
            onChange={(e) => setSendActivity(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            style={{
              paddingLeft: "30px",
              paddingRight: "40px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              background: "#fff",
            }}
          />
        </Form.Group>
      </Form>
      <Modal show={showCodeModal} onHide={toggleCodeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Code Example</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={10}
            value={userCode}
            onChange={handleCodeChange}
            onPaste={handlePaste}
            placeholder="Paste your code here..."
            ref={textareaRef}
          />
          {highlightedCode}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleCodeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Upload Code
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Activity;
