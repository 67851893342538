import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";

const thstyle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
}

const tdstyle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
}

const data_recharge_services = [
  {
    id: 1, service_Name: 'ADTH', operator: 'AIRTEL DTH',
    circle: 'NC',
    retailer: '2.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 2, service_Name: 'AIRTEL', operator: 'AIRTEL',
    circle: 'NC',
    retailer: '1.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 3,
    service_Name: 'DISH',
    operator: 'DISH TV',
    circle: 'NC',
    retailer: '2.3',
    distributor: '0.5',
    master_distributor: '0.2',
  },
  {
    id: 4, service_Name: 'IDEA', operator: 'IDEA',
    circle: 'NC',
    retailer: '2.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 5, service_Name: 'RELAINCE JIO', operator: 'JIO',
    circle: 'NC',
    retailer: '1.0',
    distributor: '0.5',
    master_distributor: '0.1',
  },

  {
    id: 6, service_Name: 'SUN DTH', operator: 'SUN DTH',
    circle: 'NC',
    retailer: '2.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 7, service_Name: 'TATA SKY', operator: 'TATA SKY',
    circle: 'NC',
    retailer: '2.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 8, service_Name: 'VIDEOCON DTH', operator: 'VIDEOCON DTH',
    circle: 'NC',
    retailer: '2.0',
    distributor: '0.5',
    master_distributor: '0.2',
  },

  {
    id: 9, service_Name: 'VODAFONE', operator: 'VODAFONE',
    circle: 'NC',
    retailer: '2.5',
    distributor: '0.5',
    master_distributor: '0.2',
  },
  // Add more data items as needed
];



const data_dmt_commission = [
  {
    id: 1,
    slab: '1000',
    charge: '10 Rs Flat',

    retailer: '5',
    distributor: '1',
    master_distributor: '0',
  },
  {
    id: 2,
    slab: '25000',
    charge: '1%',

    retailer: '0.55%',
    distributor: '0.2%',
    master_distributor: '0.03%',
  },
];



const data_aeps_commission = [
  {
    id: 1,
    from: '100',
    to: '499',
    retailer: '0.25',
    distributor: '0',
    master_distributor: '0',
  },
  {
    id: 2,
    from: '500',
    to: '999',
    retailer: '1',
    distributor: '0.3',
    master_distributor: '0.2',
  },
  {
    id: 3,
    from: '1000',
    to: '1499',
    retailer: '2',
    distributor: '0.5',
    master_distributor: '0.5',
  },
  {
    id: 4,
    from: '1500',
    to: '1999',
    retailer: '3',
    distributor: '1',
    master_distributor: '0.6',
  },
  {
    id: 5,
    from: '2000',
    to: '2499',
    retailer: '4.5',
    distributor: '1',
    master_distributor: '0.6',
  },
  {
    id: 6,
    from: '2500',
    to: '2999',
    retailer: '5',
    distributor: '1',
    master_distributor: '0.6',
  },
  {
    id: 7,
    from: '3000',
    to: '10000',
    retailer: '10',
    distributor: '1',
    master_distributor: '0.5',
  },



];


const data_bbps = [
  {
    id: 1,
    service_name: 'Water and Gas',
    retailer: '0.15%',
    distributor: '0.10%',
    master_distributor: '0.05%',
  },
  {
    id: 2,
    service_name: 'LIC',
    retailer: '0.15%',
    distributor: '0.10%',
    master_distributor: '0.05%',
  },
  {
    id: 3,
    service_name: 'Gas',
    retailer: '0.15%',
    distributor: '0.10%',
    master_distributor: '0.05%',
  },

];

const data_bbps_slab = [
  {
    id: 1,
    service_name: 'Electricity',
    slab: '100-10000',
    retailer: '0.30%',
    distributor: '0.10%',
    master_distributor: '0.05%',
  },
  {
    id: 2,
    service_name: 'Electricity',
    slab: '10001-200000',
    retailer: '0.17%',
    distributor: '0.10%',
    master_distributor: '0.03%',
  },


];


const CommissionChart = () => {
  const { user } = useContext(GlobalContext);
  const [commission, setCommission] = useState([]);

  const Commission = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_commision_chart.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setCommission(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Commission();
  }, []);

  return (
    <div className="p-5  bg-light" style={{ fontFamily: "poppins" }}>


      <div
        className=" text-center p-3  font-weight-bold"
        style={{
          color: "rgba( 127, 0, 255, 1)",
          fontFamily: "Poppins",
          fontSize: "42px",
          fontWeight: "1000",
          lineHeight: "36px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        <strong>Retailer Commission Chart</strong>
      </div>



      <div className="">
        <div
          className=" text-center p-3 font-weight-bold"
          style={{
            color: "rgba( 0, 0, 0, 1)",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "1000",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          <strong>Recharges Services Commission</strong>
        </div>
        <div className="card shadow bg-white m-0 p-0" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div className="table-responsive " >
              <table className="table table-borderless">
                <thead className=" text-center" style={{ backgroundColor: "#27B851" }} >
                  <tr className=" text-center "

                  >

                    <th className=" text-center"
                      style={{
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        backgroundColor: "#27B851",
                        fontSize: "20px"
                      }}
                    >Operator</th>



                    <th className=" text-center" style={{ fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px', backgroundColor: "#27B851" }}>Retailer Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {data_recharge_services.map((item) => (
                    <tr key={item.id}>

                      <td className="text-center font-weight-bold" style={{}}>
                        {item.operator}
                      </td>

                      <td className="text-center font-weight-bold" style={{}}>
                        {item.retailer}
                      </td>


                    </tr>
                  ))}


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>












      <div className="">
        <div
          className=" text-center p-3 font-weight-bold"
          style={{
            color: "rgba( 0, 0, 0, 1)",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "1000",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          <strong>DMT Commission</strong>
        </div>
        <div className="card shadow bg-white m-0 p-0" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div className="table-responsive " >
              <table className="table table-borderless">
                <thead className=" text-center" >
                  <tr className=" text-center " >
                    <th className=" text-center" style={{ fontSize: "20px", backgroundColor: "#FF8801", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >Slab</th>
                    <th className=" text-center" style={{ fontSize: "20px", backgroundColor: "#FF8801", }}>Charge</th>

                    <th className=" text-center" style={{ fontSize: "20px", backgroundColor: "#FF8801", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Retailer Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {data_dmt_commission.map((item) => (
                    <tr key={item.id}>
                      <td className="text-center font-weight-bold" style={{}}>
                        {item.slab}
                      </td>
                      <td className="text-center font-weight-bold" style={{}}>
                        {item.charge}
                      </td>

                      <td className="text-center font-weight-bold" style={{}}>
                        {item.retailer}
                      </td>


                    </tr>
                  ))}


                </tbody>

              </table>
            </div>
          </div>
        </div>

      </div>










      <div className="">
        <div
          className=" text-center p-3 font-weight-bold"
          style={{
            color: "rgba( 0, 0, 0, 1)",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "1000",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          <strong>AEPS Commission</strong>
        </div>



        <div className="card shadow bg-white m-0 p-0" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div className="table-responsive " >
              <table className="table table-borderless ">

                <thead className="bg-primary text-white text-center " >
                  <tr className="bg-primary text-white text-center  "

                  >
                    <th className="bg-primary text-white text-center" style={{ backgroundColor: "#0183F5", fontSize: "20px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >Slab</th>
                    <th className="bg-primary text-white text-center" style={{ backgroundColor: "#0183F5", fontSize: "20px", }}></th>



                    <th className="bg-primary text-white text-center" style={{ backgroundColor: "#0183F5", fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Retailer Commission</th>
                  </tr>
                </thead>
                <div className="py-1"></div>
                <thead className="bg-light text-primary text-center " >
                  <tr className="bg-light text-primary text-center  "

                  >
                    <th className="bg-light text-primary text-center" style={{ backgroundColor: "#EBEBEB", fontSize: "20px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >From</th>
                    <th className="bg-light text-primary text-center" style={{ backgroundColor: "#EBEBEB", fontSize: "20px", }}>To</th>



                    <th className="bg-light text-primary text-center" style={{ backgroundColor: "#EBEBEB", fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Commission</th>
                  </tr>
                </thead>

                {data_aeps_commission.map((item) => (
                  <tr key={item.id}>
                    <td className="text-center font-weight-bold" style={{}}>
                      {item.from}
                    </td>
                    <td className="text-center font-weight-bold" style={{}}>
                      {item.to}
                    </td>

                    <td className="text-center font-weight-bold" style={{}}>
                      {item.retailer}
                    </td>


                  </tr>
                ))}




              </table>

              <hr />
              <div className="text-dark text-center  p-1 font-weight-bold" style={{
                color: "rgba( 0, 0, 0, 1)",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "1000",
                lineHeight: "36px",
                letterSpacing: "0em",
                textAlign: "left",
              }}>1Rs Commission for Ministatement</div>


              <div className="text-primary text-center   font-weight-bold" style={{
                color: "rgba( 0, 0, 0, 1)",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "1000",
                lineHeight: "36px",
                letterSpacing: "0em",
                textAlign: "left",
              }}>Aadhaar pay Charges - 0.26 % Charges for Aadhaar pay </div>

            </div>
          </div>
        </div>

      </div>





      <div className="">
        <div
          className=" text-center p-3 font-weight-bold"
          style={{
            color: "rgba( 0, 0, 0, 1)",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "1000",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          <strong>E-PAN Card Commission</strong>
        </div>
        <div className="card shadow bg-white m-0 p-0" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div className="table-responsive " >
              <table className="table table-borderless">
                <thead className=" text-center" >
                  <tr className=" text-center " >
                    <th className=" text-center" style={{ backgroundColor: "#FE4F67", fontSize: "20px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >Service Name</th>



                    <th className=" text-center" style={{ backgroundColor: "#FE4F67", fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Retailer Commission</th>
                  </tr>
                </thead>
                <tbody>

                  <tr >
                    <td className="text-center font-weight-bold" style={{}}>
                      E-PAN Card
                    </td>
                    <td className="text-center font-weight-bold" style={{}}>
                      8
                    </td>


                  </tr>



                </tbody>

              </table>
            </div>
          </div>
        </div>

      </div>








      <div className="">
        <div
          className=" text-center p-3 font-weight-bold"
          style={{
            color: "rgba( 0, 0, 0, 1)",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "1000",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          <strong>BBPS Offline Commission</strong>
        </div>



        <div className="card shadow bg-white m-0 p-0" style={{ borderRadius: "10px" }}>
          <div className="card-body">
            <div className="table-responsive " >
              <table className="table table-borderless">
                <thead className="bg-primary text-center" >
                  <tr className=" text-center "

                  >
                    <th className=" text-center" style={{ backgroundColor: "#8E4FFE", fontSize: "20px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >Service Name</th>



                    <th className=" text-center" style={{ backgroundColor: "#8E4FFE", fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Retailer Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {data_bbps.map((item) => (
                    <tr key={item.id}>

                      <td className="text-center font-weight-bold" style={{}}>
                        {item.service_name}
                      </td>


                      <td className="text-center font-weight-bold" style={{}}>
                        {item.retailer}
                      </td>


                    </tr>
                  ))}


                </tbody>
              </table>
            </div>

            <hr />

            <div className="table-responsive " >
              <table className="table table-borderless">
                <thead className="bg-primary text-center" >
                  <tr className=" text-center "

                  >
                    <th className=" text-center" style={{ backgroundColor: "#8E4FFE", fontSize: "20px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} >Service Name</th>
                    <th className=" text-center" style={{ backgroundColor: "#8E4FFE", fontSize: "20px", }}>
                      Slab
                    </th>


                    <th className=" text-center" style={{ backgroundColor: "#8E4FFE", fontSize: "20px", borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Retailer Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {data_bbps_slab.map((item) => (
                    <tr key={item.id}>
                      <td className="text-center font-weight-bold" style={{}}>
                        {item.service_name}
                      </td>
                      <td className="text-center font-weight-bold" style={{}}>
                        {item.slab}
                      </td>

                      <td className="text-center font-weight-bold" style={{}}>
                        {item.retailer}
                      </td>


                    </tr>
                  ))}


                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>
    // </div>
  );
};

export default CommissionChart;